<template>
  <page-title title="ToDos" />
  <div class="container mx-auto grow space-y-16">
    <div class="w-full">
      <div class="mb-8 flex flex-row text-sm flex-wrap justify-between">
        <div class="flex w-full">
          <div class="grid grid-cols-1 md:grid-rows-2 md:grid-flow-col gap-2">
            <div class="top-filter">
              <span>Status:</span>

              <inline-select
                  class="select"
                  v-model="status"
                  :items="statusOptions">
                <template #default="{option}">
                  <option :value="option.value">
                    {{ option.label }}
                  </option>
                </template>
              </inline-select>
            </div>
            <div class="top-filter">
              <span>Priority:</span>
              <inline-select
                  class="select"
                  v-model="priority"
                  :items="priorityOptions">
                <template #default="{option}">
                  <option :value="option.value">
                    {{ option.label }}
                  </option>
                </template>
              </inline-select>
            </div>
            <div
                v-if="currentTeam"
                class="top-filter">
              <span>Assignee:</span>
              <inline-select
                  class="select "
                  v-model="selectedItems.user"
                  :items="currentTeam.users">
                <template #default="{option}">
                  <option :value="option.id">
                    {{ option.name }}
                  </option>
                </template>
                <template #empty>
                  <option :value="null">All</option>
                </template>
              </inline-select>

            </div>
            <div
                v-if="currentTeam"
                class="top-filter">
              <span>Assigned Group:</span>
              <inline-select
                  class="select "
                  v-model="selectedItems.group"
                  :items="currentTeam.groups">
                <template #default="{option}">
                  <option :value="option.id">
                    {{ option.name }}
                  </option>
                </template>
                <template #empty>
                  <option :value="null">All</option>
                </template>
              </inline-select>
            </div>
            <div
                v-if="!automationsLoading"
                class="top-filter">
              <span>Automation:</span>
              <inline-select
                  class="select "
                  v-model="selectedItems.automation"
                  :items="automationsToShow">
                <template #default="{option}">
                  <option :value="option.id">
                    {{ option.name }}
                  </option>
                </template>
                <template #empty>
                  <option :value="null">All</option>
                </template>
              </inline-select>
            </div>
            <div
                v-if="tags.length > 0"
                class="top-filter">
              <span>Tag:</span>
              <inline-select
                  class="select "
                  v-model="selectedItems.tag"
                  :items="tags">
                <template #default="{option}">
                  <option :value="option.id">
                    {{ option.name }}
                  </option>
                </template>
                <template #empty>
                  <option :value="null">All</option>
                </template>
              </inline-select>
            </div>
            <div
                class="top-filter">
              <span>Creation Date:</span>
              <inline-datepicker v-model:dates="creationDates" placeholder="Select..." />
            </div>
            <div
                class="top-filter">
              <span>Overdue Date:</span>
              <inline-datepicker v-model:dates="overdueDates" placeholder="Select..." />
            </div>
            <div
                v-if="currentTeam && currentTeam.locations.length > 1"
                class="top-filter ">
              <span>Location:</span>
              <inline-select
                  class="select"
                  v-model="selectedItems.location"
                  :items="currentTeam.locations">
                <template #default="{option}">
                  <option :value="option.id">
                    {{ option.name }}
                  </option>
                </template>
                <template #empty>
                  <option :value="null">All</option>
                </template>
              </inline-select>
            </div>
          </div>
          <div class="text-gray-700 grow">
            <div class="flex flex-col items-end justify-end space-y-2">
              <div class="top-filter ">
                <span>Per page:</span>
                <inline-select
                    class="select text-right"
                    v-model="paginationData.perPage"
                    :items="perPageOptions">
                  <template #default="{option}">
                    <option :value="option.value">
                      {{ option.label }}
                    </option>
                  </template>
                </inline-select>
              </div>
              <div class="top-filter ">
                <span>Order:</span>
                <inline-select
                    class="select text-right"
                    v-model="sortby"
                    :items="sortOptions">
                  <template #default="{option}">
                    <option :value="option.value">
                      {{ option.label }}
                    </option>
                  </template>
                </inline-select>
              </div>
            </div>

          </div>
        </div>

      </div>

      <empty v-if="loadedFirstTime && todos.length === 0">
        <template #title>
          Nothing to show!
        </template>
        <template #subtitle>
          There aren't any ToDos with the current filters
        </template>
      </empty>

      <div
          v-if="!loadedFirstTime"
          class="w-full h-40 px-2 my-2">
        <div class="flex h-full items-center justify-center">
          <div class="w-12 h-12 text-gray-400 opacity-75">
            <loader />
          </div>
        </div>
      </div>

      <div
          v-if="loadedFirstTime && todos.length > 0"
          class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class=" divide-y divide-gray-200 min-w-full">
                <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="flex items-center justify-center py-3 font-normal">
                    <delete-archive-bulk-menu :count="paginatorInfo.total"
                                              v-if="me && (me.policy.is_admin || me.policy.is_owner) && status != StatusOptions.Closed"
                                              class="ml-3"
                                              @click-complete="CompleteAllModalOpen = true"
                                              @click-delete="DeleteAllModalOpen = true"></delete-archive-bulk-menu>
                  </th>
                  <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Client
                  </th>
                  <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-full">
                    Subject
                  </th>
                  <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Assigned
                  </th>
                  <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th
                      scope="col"
                      class="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-right">
                    Creation
                    Date
                  </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <single-todo
                    v-for="todo in filteredTodos"
                    :key="todo.id"
                    :todo="todo">
                  <td class="pl-4">
                    <input
                        type="checkbox"
                        class="form-checkbox"
                        :disabled="bulkSending ? true : undefined"
                        :checked="bulk.selected.indexOf(todo.id) > -1"
                        @change="addRemoveFromBulk(todo.id, $event)">
                  </td>
                </single-todo>
                </tbody>
                <tfoot
                    v-if="filteredTodos && (( bulk.selected.length > 0) || paginatorInfo.total > 0&& paginatorInfo.lastPage > 1) "
                    class="bg-gray-50 divide-y">
                <tr v-if="bulk.selected.length > 0">
                  <td colspan="6">
                    <div
                        class="p-4">
                      <div class="flex justify-between">
                        <div>
                          <v-button
                              class="btn btn-green"
                              :disabled="bulkSending"
                              :loading="bulk.completing"
                              @click="bulkComplete">
                            Mark {{
                              bulk.selected.length
                            }} selected as completed
                          </v-button>
                        </div>
                        <div>
                          <v-button
                              class="btn btn-red"
                              :disabled="bulkSending"
                              :loading="bulk.deleting"
                              @click="bulkDelete">
                            Delete
                            {{
                              bulk.selected.length
                            }}
                            selected
                            ToDos
                          </v-button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="paginatorInfo.total > 0&& paginatorInfo.lastPage > 1">
                  <td
                      colspan="6"
                      class="p-4">
                    <pagination
                        v-if="paginatorInfo.lastPage > 1"
                        :pagination="paginatorInfo"
                        class="flex items-center justify-between text-gray-700 text-sm"
                        @setPage="paginationData.page = $event">
                      <div
                          v-if="loading"
                          class="inline-block w-8 h-8 mr-2 text-gray-400">
                        <loader />
                      </div>
                    </pagination>
                  </td>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal
        v-model:open="DeleteAllModalOpen"
        :loading="bulkUpdateMutation.loading.value"
        type="warning"
        close-text="No, go back"
        :title="'Delete ' + (paginatorInfo.total ?? 0) + ' ToDos?'"
        @close="DeleteAllModalOpen = bulkUpdateMutation.loading.value"
        @clicked="DeleteAllTodos">
      <template #button>
        Delete ALL ({{ paginatorInfo.total }})
      </template>
      <template #text>
        <div class="space-y-4 pt-4"><p>
          You are about to delete all the ToDos that match the filters you selected. <b>{{ paginatorInfo.total }}</b>
          ToDos will be marked as 'Deleted'.
        </p>
          <p>This action is <b>irreversible</b>.</p>
          <p>These <b>{{ paginatorInfo.total }}</b> ToDos will still be visible if the Status filter is set as "Closed",
             retaining all their related information. </p>
          <p>If you understand the implications and want to proceed, click on 'Delete ALL'</p>
          <p>If you want to review your ToDos further, click 'No, go back' to go back to your ToDos list.</p>
        </div>

      </template>
    </confirm-modal>

    <confirm-modal
        v-model:open="CompleteAllModalOpen"
        :loading="bulkUpdateMutation.loading.value"
        type="warning"
        close-text="No, go back"
        :title="'Mark ' + (paginatorInfo.total ?? 0) + ' ToDos as Completed?'"
        @close="CompleteAllModalOpen = bulkUpdateMutation.loading.value"
        @clicked="CompleteAllTodos">
      <template #button>
        Complete ALL ({{ paginatorInfo.total }})
      </template>
      <template #text>
        <div class="space-y-4 pt-4"><p>
          You are about to mark all the ToDos that match the filters you selected as Completed.
          <b>{{ paginatorInfo.total }}</b>
          ToDos will be marked as 'Completed'.
        </p>
          <p>This action is <b>irreversible</b>.</p>
          <p>These <b>{{ paginatorInfo.total }}</b> ToDos will still be visible if the Status filter is set as "Closed",
             retaining all their related information. </p>
          <p>If you understand the implications and want to proceed, click on 'Complete ALL'.</p>
          <p>If you want to review your ToDos further, click 'No, go back' to go back to your ToDos list.</p>
        </div>

      </template>
    </confirm-modal>

  </div>
</template>
<style scoped>
.top-filter {
  @apply text-gray-700 flex items-center space-x-2;
}

.top-filter span {
  @apply whitespace-nowrap;
}

.top-filter .select {
  @apply text-sm font-bold w-full truncate grow;
  max-width:10em;
}

</style>
<script lang="ts" setup>
import meQuery from "@/composables/queries/meQuery";

import PageTitle                                                                                                                                                                                                                from "@/components/navbar/PageTitle.vue";
import Empty                                                                                                                                                                                                                    from "@/components/layout/Empty.vue";
import Pagination                                                                                                                                                                                                               from "@/components/layout/Pagination.vue";
import Loader                                                                                                                                                                                                                   from "@/components/layout/Loader.vue";
import SingleTodo                                                                                                                                                                                                               from "@/components/todos/listing/SingleTodo.vue";
import VButton                                                                                                                                                                                                                  from "@/components/layout/VButton.vue";
import {createPaginationData, paginationWithAlreadyLoaded}                                                                                                                                                                      from "@/composables/paginationWithAlreadyLoaded";
import {computed, reactive, ref, watch}                                                                                                                                                                                         from "vue";
import currentTeamQuery                                                                                                                                                                                                         from "@/composables/queries/currentTeamQuery";
import {QLAutomation, QLAutomationDeliveryTypeEnum, QLSortOrder, QLTodoCompletionTypeEnum, QLTodosQuery, QLTodosQueryVariables, useAutomationsQuickListQuery, useBulkUpdateTodosMutation, useTodosQuery, useUpdateTodoMutation} from "@/graphql/queries/ql/composables";
import {useResult}                                                                                                                                                                                                              from "@vue/apollo-composable";
import {tagsTodosQuery}                                                                                                                                                                                                         from "@/composables/queries/tagsQuery";
import InlineSelect                                                                                                                                                                                                             from "@/components/layout/Forms/InlineSelect.vue";
import {echoListener}                                                                                                                                                                                                           from "@/composables/echoListener";
import {AllEventTypes}                                                                                                                                                                                                          from "@/classes/notifications/EventTypes";
import ConfirmModal                                                                                                                                                                                                             from "@/components/layout/ConfirmModal.vue";
import DeleteArchiveBulkMenu                                                                                                                                                                                                    from "@/views/Todos/Components/DeleteArchiveBulkMenu.vue";

// Dates
import InlineDatepicker from "@/components/layout/Forms/InlineDatepicker.vue";

const creationDates = ref([]);
const overdueDates = ref([]);

//END TEST

const {me} = meQuery("cache-first");

enum StatusOptions {
  "Open", "Overdue", "Scheduled", "Closed"
}

enum PriorityOptions {
  "All", "Urgent", "Medium", "Low"
}

enum SortOptions {
  "updated_at",
  "created_at",
  "overdue_at"
}

const statusOptions = [
  {label: "Open", value: StatusOptions.Open},
  {label: "Overdue", value: StatusOptions.Overdue},
  {label: "Scheduled", value: StatusOptions.Scheduled},
  {label: "Closed", value: StatusOptions.Closed}
];
const priorityOptions = [
  {label: "All", value: PriorityOptions.All},
  {label: "Urgent", value: PriorityOptions.Urgent},
  {label: "Medium", value: PriorityOptions.Medium},
  {label: "Low", value: PriorityOptions.Low}
];

const sortOptions = [
  {label: "Last Updated", value: SortOptions.updated_at},
  {label: "Created Date", value: SortOptions.created_at},
  {label: "Overdue Date", value: SortOptions.overdue_at}
];

const perPageOptions = [
  {label: 10, value: 10},
  {label: 25, value: 25},
  {label: 50, value: 50}
];

const {paginationData} = createPaginationData({
                                                page:     1,
                                                perPage:  10,
                                                perPages: [10, 25, 50]
                                              });

const status = ref<StatusOptions>(StatusOptions.Open);
const priority = ref<PriorityOptions>(PriorityOptions.All);
const sortby = ref<SortOptions>(SortOptions.updated_at);

const selectedItems = reactive({
                                 automation: null,
                                 location:   null,
                                 user:       null,
                                 tag:        null,
                                 group:      null
                               });

const bulk = reactive({
                        selected:   [],
                        completing: false,
                        deleting:   false
                      });
const bulkSending = computed(() => {
  return bulk.completing || bulk.deleting;
});

function resetPage() {
  return () => {
    paginationData.page = 1;
    bulk.selected = [];
  };
}

watch(() => status, resetPage());
watch(() => priority, resetPage());
watch(() => sortby, resetPage());
watch(() => selectedItems, resetPage());
watch(() => creationDates, resetPage());
watch(() => overdueDates, resetPage());

const {currentTeam, loading: currentTeamLoading} = currentTeamQuery();

const {result: automationsResults, loading: automationsLoading} = useAutomationsQuickListQuery();
const automationsRes = useResult(automationsResults, []);
const automationsToShow = computed(() => {
  return automationsRes.value.filter((aut: QLAutomation) => aut.delivery_type == QLAutomationDeliveryTypeEnum.TODO);
});

const {tags} = tagsTodosQuery();

const todoVariables = computed<QLTodosQueryVariables>(() => {
  let q: QLTodosQueryVariables = {
    page:    paginationData.page,
    first:   paginationData.perPage,
    orderBy: [{column: SortOptions[sortby.value], order: QLSortOrder.DESC}]
  };
  if (status.value === StatusOptions.Closed) {
    q.closed = true;
  }
  if (status.value === StatusOptions.Open) {
    q.open = true;
  }
  if (status.value === StatusOptions.Scheduled) {
    q.scheduled = true;
  }
  if (status.value === StatusOptions.Overdue) {
    q.overdue = true;
  }

  if (priority.value == PriorityOptions.Low) {
    q.priority = 0;
  }
  if (priority.value == PriorityOptions.Medium) {
    q.priority = 1;
  }
  if (priority.value == PriorityOptions.Urgent) {
    q.priority = 10;
  }

  if (selectedItems.automation) {
    q.automation_id = selectedItems.automation;
  }

  if (selectedItems.location) {
    q.home_location_id = selectedItems.location;
  }

  if (selectedItems.user) {
    q.assigned_to_id = selectedItems.user;
  }

  if (selectedItems.group) {
    q.team_group_id = selectedItems.group;
  }

  if (selectedItems.tag) {
    q.tag = selectedItems.tag;
  }

  if (creationDates.value.length > 0) {
    q.created_after = creationDates.value[0];
    q.created_before = creationDates.value[1];
  }

  if (overdueDates.value.length > 0) {
    q.overdue_after = overdueDates.value[0];
    q.overdue_before = overdueDates.value[1];
  }

  return q;
});

const todosQ = useTodosQuery(todoVariables, {fetchPolicy: "cache-and-network"});

echoListener([
               AllEventTypes.TodoNew,
               AllEventTypes.TodoUpdated
             ], (ev) => {
  todosQ.refetch();
}, 5000);

const {
  paginatorInfo,
  loadedFirstTime,
  results: todos,
  loading
} = paginationWithAlreadyLoaded<QLTodosQuery["todos"]["data"]>(todosQ);

const addRemoveFromBulk = (id, ev) => {
  if (ev.target.checked) {
    bulk.selected.push(id);
  } else {
    const i = bulk.selected.indexOf(id, 0);
    if (i > -1) {
      bulk.selected.splice(i, 1);
    }
  }
};

const filteredTodos = computed(() => {
  return todos.value.filter(todo => ((status.value === StatusOptions.Closed && todo.is_closed) || !todo.is_closed));
});

const updateTodoQ = useUpdateTodoMutation({});
const reloadTodoList = () => {
  if (paginationData.page > 1) {
    paginationData.page = 1;
  } else {
    todosQ.refetch();
  }
};

const bulkComplete = async () => {
  bulk.completing = true;

  let mutations = bulk.selected.map((id) => {
    return updateTodoQ.mutate({
                                id:                    id,
                                completed_action_type: QLTodoCompletionTypeEnum.OTHER
                              });
  });

  await Promise.all(mutations);

  bulk.completing = false;
  bulk.selected = [];

  reloadTodoList();
};
const bulkDelete = async () => {
  bulk.deleting = true;

  let mutations = bulk.selected.map((id) => {
    return updateTodoQ.mutate({
                                id:      id,
                                deleted: true
                              });
  });

  await Promise.all(mutations);

  bulk.deleting = false;
  bulk.selected = [];

  reloadTodoList();
};

const bulkUpdateMutation = useBulkUpdateTodosMutation({});

const DeleteAllModalOpen = ref(false);
const CompleteAllModalOpen = ref(false);

const BulkAllFn = async (action: "delete" | "complete") => {
  function removeProps(obj: any, props: string[]) {
    let newObj = {...obj};
    props.forEach(prop => {
      if (newObj.hasOwnProperty(prop)) {
        delete newObj[prop];
      }
    });
    return newObj;
  }

  await bulkUpdateMutation.mutate(removeProps({...todoVariables.value, action: action},
                                              ["client_id", "page", "first", "orderBy"]));
};

const DeleteAllTodos = async () => {
  await BulkAllFn("delete");

  DeleteAllModalOpen.value = false;
  reloadTodoList();
};
const CompleteAllTodos = async () => {
  await BulkAllFn("complete");

  CompleteAllModalOpen.value = false;
  reloadTodoList();
};

</script>
