<template>
  <div>
    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Email details
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>Add a subject line to your email and if desired, a custom "reply-to" email address.</p>
          <div>
            <div class="text-base pt-4">
              Subject
            </div>
            <p>
              For best practices on email subject lines and email content, please read the following helpdoc:
              <a
                  class="text-link"
                  href="https://docs.loopspark.com/automations/email_best_practices/"
                  target="_blank">Email Best Practices</a>
            </p>
          </div>

          <div>
            <div class="text-base pt-4">
              Reply-To
            </div>
            <p>
              By default client responses to this email will go to the "Reply To Email" as setup in your
              <a
                  class="text-link"
                  href="https://docs.loopspark.com/settings/studio_settings/"
                  target="_blank">studio settings</a>. If you'd like responses to this specific
              automation to go to another email address, enter a
              "Reply-To" email address above.
            </p>
          </div>
        </div>
      </div>

      <div class="block  flex-1 ">
        <div class="p-4 space-y-6 bg-white overflow-hidden shadow-md rounded-lg">
          <text-input
              v-model="subjectModel"
              :error-message="formErrors.get('subject')"
              :placeholder="'Welcome to {{STUDIO_NAME}}'"
              data-name
              label="Subject"
              required />

          <text-input
              v-model="replyToModel"
              :error-message="formErrors.get('reply_to')"
              :placeholder="replyToPlaceholder"
              data-reply-to
              label="Reply To"
              type="email" />
        </div>
      </div>
    </div>

    <div class="mt-10">
      <div class="shadow-md rounded-lg bg-white p-4">
        <div class="inline-flex space-x-2 mb-2 items-center">
          <label
              class="form-label mb-0"
              for="">Email content</label>
          <button
              class="text-xs text-link"
              @click="resetTemplate">
            (change template)
          </button>
        </div>

        <unlayer v-model="emailContentModel" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SparkFormErrors, {SparkErrors} from "@/classes/utils/SparkFormErrors";
import {computed, useAttrs, useSlots} from "vue";
import {QLEmailTemplateContent}       from "@/graphql/queries/ql/composables";
import TextInput                      from "@/components/layout/Forms/TextInput.vue";
import Unlayer                        from "@/components/automations/Unlayer.vue";
import currentTeamQuery               from "@/composables/queries/currentTeamQuery";

interface Props {
  emailContent: QLEmailTemplateContent;
  replyTo: string;
  subject: string;
  errors: SparkErrors;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:subject", "update:replyTo", "update:emailContent", "update:errors"]);

const subjectModel = computed({
                                get: () => props.subject,
                                set: (val) => emit("update:subject", val)
                              });

const replyToModel = computed({
                                get: () => props.replyTo,
                                set: (val) => emit("update:replyTo", val)
                              });

const emailContentModel = computed({
                                     get: () => props.emailContent,
                                     set: (val) => emit("update:emailContent", val)
                                   });

const errorsModel = computed({
                               get: () => props.errors,
                               set: (val) => emit("update:errors", val)
                             });

const {currentTeam} = currentTeamQuery();

const resetTemplate = () => {
  emailContentModel.value = {
    html: "",
    json: null
  };
};

const formErrors = computed(() => {
  return new SparkFormErrors(props.errors);
});

const replyToPlaceholder = computed(() => {
  if (currentTeam.value && currentTeam.value.email_sender) {
    return "Using global settings: " + currentTeam.value.email_sender;
  }
  return "";
});

</script>
