<template>
  <div class="shadow-md rounded-lg bg-white p-4">
    <h2 class="mb-4 text-gray-800">
      Create a new Quick Response
    </h2>
    <form
        class="space-y-4"
        @submit.prevent="submitForm">
      <text-input
          v-model="newData.title"
          :error-message="errors.get('title')"
          label="Title"
          required
          small-info="Provide an easy name to identify the use of this Quick Response" />
      <text-area-input
          v-model="newData.content"
          :error-message="errors.get('content')"
          :max-length="300"
          :min-length="3"
          :rows="5"
          label="Content"
          replace-utf
          required>
        <template #internal>
          <div class=" flex w-full justify-end justify-items-end items-end">
            <Menu
                as="div"
                class="inline-block relative text-sm w-full">
              <div class="flex items-end justify-end pr-2">
                <MenuButton class="inline-flex items-center justify-center focus:outline-none text-blue-500 text-sm font-medium px-2 py-1 rounded bg-opacity-50 bg-white  hover:bg-green-200 hover:text-green-900">
                  <span class="block">Merge tags</span>
                  <svg-icon
                      class="h-4 w-4 block"
                      name="down" />
                </MenuButton>
              </div>
              <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute right-0 mx-4 mt-2  max-h-[20rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto">
                  <div class="py-1 divide-y divide-gray-100 ">
                    <MenuItem
                        v-for="placeholder in placeholders"
                        :key="placeholder.value"
                        v-slot="{ active }">
                      <div
                          :class="['py-2 text-sm block cursor-pointer block text-gray-900', active ? 'bg-loopspark-50': '']"
                          @click="addPlaceholder(placeholder.value)">
                        <div class="px-4 py-2">
                          <span class="font-medium text-sm">{{ placeholder.name }}</span>
                        </div>
                      </div>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </template>
      </text-area-input>

      <v-button
          :disabled="sending"
          :loading="sending"
          class="btn btn-green"
          type="submit">
        Create!
      </v-button>
    </form>
  </div>
</template>
<script lang="ts" setup>
import SvgIcon                                 from "@/components/SvgIcon.vue";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import TextInput                               from "@/components/layout/Forms/TextInput.vue";
import VButton                                 from "@/components/layout/VButton.vue";
import TextAreaInput                           from "@/components/layout/Forms/TextAreaInput.vue";
import SparkFormErrors, {
  SparkErrors
}                                              from "@/classes/utils/SparkFormErrors";
import currentTeamQuery                        from "@/composables/queries/currentTeamQuery";
import {computed, reactive, ref}               from "vue";
import {
  QLQuickResponsesQuery, QuickResponsesDocument, useQuickResponseCreateMutation
}                                              from "@/graphql/queries/ql/composables";
import $toast                                  from "@/composables/toast";
import fillValidationErrorsOrToast             from "@/composables/queries/mutations/fillValidationErrorsOrToast";

const currentTeamQ = currentTeamQuery();
const {currentTeam} = currentTeamQ;

const newData = reactive({
                           title:   "",
                           content: ""
                         });

const sending = ref(false);

const errorsData = ref<SparkErrors>({});
const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

const addPlaceholder = (placeholder: string) => {
  newData.content += "{{" + placeholder + "}}";
};

const placeholders = [
  {name: "First Name", value: "FIRST_NAME"},
  {name: "Last Name", value: "LAST_NAME"},
  {name: "Club Count", value: "CLUB_COUNT"},
  {name: "Period Count", value: "PERIOD_COUNT"}
];

const quickResponseMutation = useQuickResponseCreateMutation({});

quickResponseMutation.onDone(() => {
  sending.value = false;
  $toast({
           type:         "success",
           message:      "Created!",
           linkRouteObj: null,
           linkText:     null
         });
});

quickResponseMutation.onError((err) => {
  console.log(err);
  sending.value = false;
  fillValidationErrorsOrToast(err,
                              errorsData,
                              "There was an error while creating the Quick Response. Please try again");
});

const submitForm = () => {
  sending.value = true;
  quickResponseMutation.mutate({
                                 team_id: currentTeam.value.id,
                                 title:   newData.title,
                                 content: newData.content
                               }, {
                                 update: (cache, {data}) => {
                                   const otherData: QLQuickResponsesQuery = cache.readQuery({query: QuickResponsesDocument});
                                   if (otherData) {
                                     const d = Object.assign([], otherData.quick_responses);
                                     d.push(data.QuickResponseCreate);
                                     cache.writeQuery({query: QuickResponsesDocument, data: {quick_responses: d}});
                                   }
                                 }
                               });
};

</script>
