import {computed, onMounted, reactive, ref, toRaw, watch} from "vue";
import {QLClient, useUpdateLeadMutation}                  from "@/graphql/queries/ql/composables";
import $toast                                             from "@/composables/toast";

interface Props {
	open: boolean;
	client: QLClient;
}

export default function updateLeadArchiveStatusComposable(props: Props,
                                                          emit: (channel: "update:open", val: any) => void,
                                                          frozen: boolean) {

	const openModel = computed({
		                           get: () => props.open,
		                           set: (val) => emit("update:open", val)
	                           });

	const saving = ref(false);
	const updateLeadData = reactive({
		                                first_name:   "",
		                                last_name:    "",
		                                email:        "",
		                                mobile_phone: "",
		                                referred_by:  "",
		                                frozen:       frozen
	                                });

	function refreshData() {
		updateLeadData.first_name = props.client.first_name;
		updateLeadData.last_name = props.client.last_name;
		updateLeadData.email = props.client.email;
		updateLeadData.mobile_phone = props.client.mobile_phone;
		updateLeadData.referred_by = props.client.referred_by;
		updateLeadData.frozen = frozen;
	}

	watch(openModel, () => {
		refreshData();
	});

	onMounted(() => refreshData());

	const {mutate, onDone, onError} = useUpdateLeadMutation({});

	onDone(() => {
		$toast({
			       type:         "success",
			       message:      "Lead " + (frozen ? "archived!" : "un-archived!"),
			       linkText:     null,
			       linkRouteObj: null
		       });
		saving.value = false;
		openModel.value = false;
		refreshData();
	});

	onError((error) => {
		$toast({
			       type:         "error",
			       message:      "There was a problem while updating the lead. Please try again",
			       linkText:     null,
			       linkRouteObj: null
		       });
		saving.value = false;
	});

	const submitLead = () => {
		saving.value = true;

		mutate({...toRaw(updateLeadData), id: props.client.id});
	};

	return {
		openModel,
		submitLead,
		saving
	};
}
