<template>
  <side-panel
      v-model:open="openModel"
      :closable="!sending">
    <template #header>
      Edit the To-Do for {{ todo.client.first_name }}
    </template>
    <div class="px-4 py-4 h-full">
      <form @submit.prevent="send" ref="form" class="h-full">
        <todo-create-edit-form
            v-model:form-data="formData"
            :errors="errorsData"
            :sending="sending" />
      </form>
    </div>
    <template #footer>
      <div
          v-if="!sending"
          class="flex m-2 justify-between w-full">
        <button
            class="btn btn-white"
            @click="openModel = false">
          Cancel
        </button>
        <button
            class="btn btn-green"
            @click="send">
          Edit!
        </button>
      </div>
      <div
          v-if="sending"
          class="flex m-2 justify-center">
        <div class="h-8 w-8">
          <loader />
        </div>
      </div>
    </template>
  </side-panel>
</template>
<script lang="ts" setup>

import SidePanel                                     from "@/components/layout/SidePanel.vue";
import Loader                                        from "@/components/layout/Loader.vue";
import TodoCreateEditInterface                       from "@/components/client/Todos/TodoCreateEditInterface";
import TodoCreateEditForm                            from "@/components/client/Todos/TodoCreateEditForm.vue";
import {QLTodo, QLTodoPriority, useEditTodoMutation} from "@/graphql/queries/ql/composables";
import {computed, onMounted, ref, toRef, watch}      from "vue";
import SparkFormErrors                               from "@/classes/utils/SparkFormErrors";
import fillValidationErrorsOrToast                   from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import $toast                                        from "@/composables/toast";

interface Props {
  todo: QLTodo;
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:open", "updated"]);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const errorsData = ref({});
const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

const sending = ref(false);

const formData = ref<TodoCreateEditInterface>({
                                                message:        "",
                                                subject:        "",
                                                priority_type:  QLTodoPriority.NONE,
                                                overdue_at:     null,
                                                postponed_to:   null,
                                                assigned_to_id: null,
                                                team_group_id:  null
                                              });
const form = ref(null);

const passedTodo = toRef(props, "todo");

const importTodo = () => {
  formData.value.message = passedTodo.value.message;
  formData.value.subject = passedTodo.value.subject;
  formData.value.priority_type = passedTodo.value.priority;
  formData.value.overdue_at = passedTodo.value.overdue_at;
  formData.value.postponed_to = passedTodo.value.postponed_to;
  formData.value.assigned_to_id = passedTodo.value.assigned_to_id?.toString();
  formData.value.team_group_id = !!passedTodo.value.team_group ? passedTodo.value.team_group.id : null;
};

watch(passedTodo, () => {
  importTodo();
});
onMounted(() => {
  importTodo();
});

const mutation = useEditTodoMutation({});
mutation.onError((err) => {
  fillValidationErrorsOrToast(err, errorsData, "Error while editing the todo. Please try again!");
  sending.value = false;
});

mutation.onDone((res) => {
  $toast({
           type:         "success",
           message:      "To-Do updated!",
           linkText:     null,
           linkRouteObj: null
         });
  sending.value = false;
  openModel.value = false;
  emit("updated");
});

const send = () => {
  errorsData.value = {};
  if (!form.value.reportValidity()) {
    return;
  }
  sending.value = true;
  mutation.mutate({
                    id: passedTodo.value.id,
                    ...formData.value
                  });

};

</script>
