import Filter                         from "../base-filter";
import GraphQlOperator                from "hm-filters/operators/GraphQlOperator";
import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import QLQueriesData                  from "hm-filters/operators/GraphQls/QLQueriesData";
import MultipleTextOperator                   from "hm-filters/operators/MultipleTextOperator";
import {OperatorTypes, OperatorTypesFullName} from "hm-filters/operators/OperatorTypes";
import OperatorGenerator                      from "hm-filters/operatorGenerator";

class ProductType extends Filter {
	smallText = "Product";
	group = "Product";
	path = ["Product"];

	constructor(name: string, options: any) {
		super(name, options);

		this.addOperators([
			                  new ProductTypeQlOperator("=", "at least once").setIsMultipleChoice(),
			                  new ProductTypeQlOperator("only", "only").setIsMultipleChoice().setAloneInFilterGroup(),
			                  new ProductTypeQlOperator("!=", "never").setIsMultipleChoice(),
			                  ...OperatorGenerator.textContainsMultiple(),
			                  new OperatorAsFixedValueOperator("any", "any product"),
			                  new OperatorAsFixedValueOperator("never", "no product")
		                  ]);
	}

	get type(): string {
		return "ProductTypeFilter";
	}
}

export default ProductType;

export class ProductTypeQlOperator extends GraphQlOperator {
	graphQLData = QLQueriesData.ProductTypeQlOperator;

	get type(): string {
		return OperatorTypes.GraphQlMultiple;
	}

	get fullType(): string{
		return OperatorTypesFullName.ProductTypeQlOperator;
	}
}
