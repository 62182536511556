import ClientGroup     from "./partials/client";
import NumericOperator from "../operators/NumericOperator";

class PostalCodeFilter extends ClientGroup {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new NumericOperator("<", "is less than", "miles"),
			new NumericOperator(">", "is more than", "miles")
		];

	}

	get type(): string {
		return "PostalCodeFilter";
	}
}

export default PostalCodeFilter;
