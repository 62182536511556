<template>
  <div class="flex space-x-4 items-center">
    <div
        class="mt-1 text-sm text-gray-900 sm:mt-0">
      <toggle-checkbox v-model="modelValueModel" />
    </div>
    <div>
      <div class="text-sm font-medium text-gray-700">
        {{ title }}
      </div>
      <p class="text-xs text-gray-500">
        <slot />
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import ToggleCheckbox                            from "@/components/layout/Forms/ToggleCheckbox.vue";
import {computed, onMounted, useAttrs, useSlots} from "vue";

interface Props {
  modelValue: boolean | string | null;
  title: string;
  valueForOn?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: true
});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:modelValue"]);

onMounted(() => {
  if (props.modelValue === null) {
    modelValueModel.value = false;
  }
});

const modelValueModel = computed({
                                   get: () => {
                                     if (props.valueForOn) {
                                       return props.modelValue == props.valueForOn;
                                     }
                                     return props.modelValue;
                                   },
                                   set: (val) => {
                                     if (!!props.valueForOn) {
                                       emit("update:modelValue", val ? props.valueForOn : null);
                                     } else {
                                       emit("update:modelValue", val);
                                     }

                                   }
                                 });

</script>
