import OperatorGenerator              from "hm-filters/operatorGenerator";
import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import {BookingEventGroup}            from "hm-filters/filters/partials/BookingEventGroup";

export class BookingEventDateFilter extends BookingEventGroup {

	constructor(name: string, options: any) {
		super(name, options);

		this.setOperators(OperatorGenerator.datesAll(true, true, true, false));

		this.addOperators([
			                  ...OperatorGenerator.dateBefore(false),
			                  ...OperatorGenerator.dateAfter(false),
			                  new OperatorAsFixedValueOperator("past", "in the past"),
			                  new OperatorAsFixedValueOperator("future", "in the future")
		                  ]);
	}

	get type(): string {
		return "BookingEventDate";
	}
}

export class BookingEventDateStartFilter extends BookingEventDateFilter {
	get type(): string {
		return "BookingEventDateStart";
	}
}
