<template>
  <span v-text="inUsd"></span>
</template>
<script lang="ts">
import {computed, defineComponent} from "vue";
import {currency}                  from "@/classes/vue-filters/CurrencyFilter";

export default defineComponent(
    {
      name:       "Currency",
      props:      {
        amount: {
          type:     Number,
          required: true,
          default:  () => 0
        }
      },
      emits:      [],
      components: {},
      setup(props, {emit}) {
        const inUsd = computed(() => {
          return currency(props.amount);
        });
        return {
          inUsd
        };
      }
    });
</script>
