<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
    <studio-setting-billing-single-addon
        v-for="addon in addons"
        :key="addon.addonType"
        :addon-info="addon"
        :current-team="currentTeam"
        :my-subscription="mySubscription"
        @added="emit('updated')"
        @removed="emit('updated')">
      <template #title>
        {{ addon.title }}
      </template>
      <template #description>
        {{ addon.description }}
      </template>
      <template #addButton>
        {{ addon.addButtonText }}
      </template>
    </studio-setting-billing-single-addon>
  </div>
</template>
<script lang="ts" setup>
import {QLFragmentCurrentTeamFragment, QLFragmentTeamSubscriptionFragment} from "@/graphql/queries/ql/composables";
import {toRef, useAttrs, useSlots}                                         from "vue";
import config                                                              from "@/config";
import StudioSettingBillingSingleAddon
                                                                           from "@/views/Studio/Settings/Components/Billing/StudioSettingBillingSingleAddon.vue";

interface Props {
  currentTeam: QLFragmentCurrentTeamFragment;
  mySubscription: QLFragmentTeamSubscriptionFragment;
}

const props = defineProps<Props>();

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["updated"]);

const addons = config.addons;

const mySubscription = toRef(props, "mySubscription");

</script>
