import Communication                  from "hm-filters/filters/partials/communication";
import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import NumericOperator                from "hm-filters/operators/NumericOperator";

export class CommunicationCountFilter extends Communication {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new OperatorAsFixedValueOperator("never", "never before"),
			new NumericOperator(">", "more than", "communications"),
			new NumericOperator("<", "less than", "communications"),
			new NumericOperator("=", "exactly", "communications")
		];
	}

	get type(): string {
		return "CommunicationCountFilter";
	}
}
