<template>
  <div>
    <div
        v-if="loading"
        class="p-4 shadow-md rounded-lg bg-white w-full h-40 flex items-center justify-center">
      <loader class="w-8 h-8 text-gray-300" />
    </div>

    <div
        v-if="!loading && quick_responses.length > 0"
        class="p-4 shadow-md rounded-lg bg-white divide-y">
      <div
          v-for="quickResponse in quick_responses"
          :key="quickResponse.id"
          class="py-4">
        <studio-quick-responses-list-item :quick-response="quickResponse" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import Loader                       from "@/components/layout/Loader.vue";
import StudioQuickResponsesListItem from "@/components/studio/settings/QuickResponses/StudioQuickResponsesListItem.vue";
import {useQuickResponsesQuery}     from "@/graphql/queries/ql/composables";
import {useResult}                  from "@vue/apollo-composable";

const quickResponsesQuery = useQuickResponsesQuery();
const {loading} = quickResponsesQuery;

const quick_responses = useResult(quickResponsesQuery.result, []);

</script>
