import Contract          from "./partials/contract";
import OperatorGenerator from "../operatorGenerator";

class ContractPurchaseDateFilter extends Contract {
	constructor(name: string, options: any) {
		super(name, options);

		this.setOperators(OperatorGenerator.datesPastOnly(true, true, false));

		// this.operators = [
		// 	new Operator('today', 'today').setFixedValue(0).setNoInput(),
		// 	new Operator('yesterday', 'yesterday').setFixedValue(0).setNoInput(),
		// 	new Operator('in_the_last', 'in the last').setAppendText('days').setInputIsNumeric(),
		// 	new Operator('not_in_the_last', 'not in the last').setAppendText('days').setInputIsNumeric(),
		// ];
	}

	get type(): string {
		return "ContractPurchaseDateFilter";
	}
}

export default ContractPurchaseDateFilter;
