import meQuery          from "@/composables/queries/meQuery";
import currentTeamQuery from "@/composables/queries/currentTeamQuery";
import {computed}       from "vue";

export default function useCanAddTags(){

	const {me} = meQuery();
	const {currentTeam} = currentTeamQuery();

	const canAddTags = computed(() => {
		return (currentTeam.value && me.value && me.value.policy.add_tags) ?? false;
	});

	return canAddTags;
}
