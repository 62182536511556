<template>
  <transition
      name="slideup">
    <div
        v-show="updatedVersionAvailable"
        class="fixed bottom-0 right-0 left-0">
      <div class="bg-indigo-600">
        <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div class="flex items-center justify-between flex-wrap">
            <div class="flex-1 flex items-center">
              <span class="flex p-2 rounded-lg bg-indigo-800">
                <svg
                    class="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true">
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                </svg>
              </span>
              <p class="ml-3 font-medium text-white flex-1 truncate">
                <span class="md:hidden">
                  LoopSpark was updated!
                </span>
                <span class="hidden md:inline font-semibold">
                  LoopSpark was updated.
                </span>
                <span class="hidden md:inline">
                  Please refresh the page.
                </span>
              </p>
            </div>
            <div class="mt-2 shrink-0 w-full sm:mt-0 sm:w-auto">
              <div class="relative">
                <button
                    class="flex w-full items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-800 bg-white hover:bg-indigo-800 hover:text-white hover:border-white"
                    @click="refreshPage">
                  Refresh now
                </button>
                <span class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75" />
        <span class="relative inline-flex rounded-full h-3 w-3 bg-green-500" />
      </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" setup>

import {defineComponent, onMounted, onUnmounted, ref} from "vue";
// import compareVersions                                from "compare-versions";
import { compare } from 'compare-versions';

import {echoListener}                                 from "@/composables/echoListener";
import {AllEventTypes}                                from "@/classes/notifications/EventTypes";

const updatedVersionAvailable = ref(false);

const checkForVersionUpdate = (version: string) => {
  const currentVersion = import.meta.env.VITE_APP_VERSION;
  if (compare(version, currentVersion.toString(), ">")) {
    updatedVersionAvailable.value = true;
  }
};

const refreshPage = () => {
  window.location.reload();
};
echoListener([AllEventTypes.WebVersionUpdated], (ev) => {
  checkForVersionUpdate(ev.data.version);
});

</script>
