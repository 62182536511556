import OperatorGenerator from "hm-filters/operatorGenerator";
import ClientTagGroup    from "hm-filters/filters/partials/tagGroup";

export class TagDateFilter extends ClientTagGroup {

	smallText = "Assigned Date";
	isDateFilter = true;

	constructor(name: string, options: any) {
		super(name, options);

		this.setOperators(OperatorGenerator.datesPastOnly(true, true, true));

	}

	get type(): string {
		return "TagDateFilter";
	}
}
