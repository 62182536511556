<template>
  <div>
    <div class="mt-6">
      <automation-show-history-discussions-table :automation="automation">
        <automation-manually-send :automation="automation"></automation-manually-send>
      </automation-show-history-discussions-table>
    </div>
  </div>
</template>
<script lang="ts" setup>
import AutomationShowHistoryDiscussionsTable
                              from "@/components/automations/Show/partials/AutomationShowHistoryDiscussionsTable.vue";
import AutomationManuallySend from "@/components/automations/Show/partials/AutomationManuallySend.vue";

import {toRef, useAttrs, useSlots}        from "vue";
import {QLFragmentFullAutomationFragment} from "@/graphql/queries/ql/composables";

interface Props {
  automation: QLFragmentFullAutomationFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
const automation = toRef(props, "automation");
</script>
