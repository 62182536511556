<template>
  <router-link
      v-slot="{ href, navigate, isExactActive }"
      custom
      :to="route">
    <a
        :href="href"
        :class="[ isExactActive? 'active':'']"
        @click="navigate">
      <svg-icon :name="icon" />
      <span><slot /></span>
      <span
          v-if="badge > 0"
          class="number ">{{ badge }}</span>
    </a>
  </router-link>
</template>
<script lang="ts" setup>
import {RouteLocationRaw} from "vue-router";

import {toRef, useAttrs, useSlots} from "vue";
import {PossibleIcons}             from "@/assets/icons/iconNames";
import SvgIcon                     from "@/components/SvgIcon.vue";

interface Props {
  route: RouteLocationRaw;
  icon: PossibleIcons;
  badge?: number;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);


const route = toRef(props, "route");
const icon = toRef(props, "icon");
const badge = toRef(props, "badge");

</script>
