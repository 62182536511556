<template>
  <form
      ref="form"
      class="text-black"
      @submit.prevent="startSave">
    <modal
        ref="modalCreate"
        v-model:open="openModel"
        :has-close-button="!created && !creating"
        :loading="creating"
        wide>
      <div class="px-4 pt-2 pb-4 w-full">
        <div v-if="!created">
          <div class="text-xl text-green-700 font-medium">
            Create a new Studio
          </div>
          <p class="text-sm pt-2 text-gray-700">
            Integrate a new business with LoopSpark. Following booking software
            integration, new business will be charged monthly by location. Only add
            businesses you personally own or manage.
          </p>

          <div class="pt-8 space-y-4">
            <text-input
                v-model="studioName"
                :error-message="sparkErrors.get('input.name')"
                label="Studio Name"
                placeholder="e.g - YogaVille: NYC - Lower East Side"
                required />

            <div>
              <label
                  class="form-label mb-1"
                  :class="{'has-error': sparkErrors.has('input.timezone')}">
                Timezone
              </label>
              <timezone-selector
                  v-model="timezone"
                  class="form-input-default"
                  required
                  :class="{'has-error': sparkErrors.has('input.timezone')}" />
            </div>
            <div class="grid md:grid-cols-2 gap-4">
              <div>
                <label
                    class="form-label mb-1"
                    for="provider">Provider</label>
                <select
                    id="provider"
                    v-model="provider"
                    class="form-select form-input-default"
                    name="team_provider"
                    :class="{'input-error': sparkErrors.has('input.team_provider')}">
                  <option
                      v-for="option in activeProviders"
                      :key="option"
                      :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div>
                <text-input
                    v-model="zip"
                    :error-message="sparkErrors.get('input.zip')"
                    label="ZIP / Postal Code"
                    placeholder="e.g - 10023"
                    required />
              </div>
            </div>
          </div>
        </div>
        <div
            v-if="created"
            class="relative">
          <div class="h-64 w-full flex items-center justify-center flex-col">
            <div class="">
              <svg-icon
                  name="check"
                  class="w-32 h-32 text-green-500" />
            </div>
            <p class="text-green-800 text-lg pt-2">
              Your new studio was created!
            </p>
            <a
                :href="switchUrl"
                class="mt-4 btn btn-green">Go to
                                           your
                                           new
                                           studio</a>
          </div>
        </div>
      </div>

      <template
          v-if="!created"
          #buttons>
        <v-button
            :disabled="creating"
            :loading="creating"
            class="modal-button modal-button-green"
            type="submit">
          Create studio!
        </v-button>
      </template>
    </modal>
  </form>
</template>
<script lang="ts">

import Modal                                    from "@/components/layout/Modal.vue";
import TextInput                                from "@/components/layout/Forms/TextInput.vue";
import VButton                                  from "@/components/layout/VButton.vue";
import TimezoneSelector                         from "@/components/layout/Forms/TimezoneSelector.vue";
import {computed, ref}                          from "vue";
import SparkFormErrors, {SparkErrors}           from "@/classes/utils/SparkFormErrors";
import {ActiveProviders}                        from "@/classes/state/ActiveProviders";
import {QLProvidersEnum, useCreateTeamMutation} from "@/graphql/queries/ql/composables";
import $toast                                   from "@/composables/toast";
import SvgIcon                                  from "@/components/SvgIcon.vue";
import fillValidationErrorsOrToast              from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import {RootEventTypes} from "@/classes/notifications/EventTypes";
import {EventBus}       from "@/classes/notifications/EventBus";

export default {
  components: {
    SvgIcon,
    Modal,
    TextInput,
    VButton,
    TimezoneSelector
  },
  props:      {
    open: {
      type:     Boolean,
      required: true
    }
  },
  emits:      ["update:open"],
  setup(props, {emit}) {
    const openModel = computed({
                                 get: () => props.open,
                                 set: (val) => emit("update:open", val)
                               });

    const form = ref(null);

    const creating = ref(false);
    const created = ref(false);

    const errors = ref<SparkErrors>({});
    const sparkErrors = computed(() => {
      return new SparkFormErrors(errors);
    });

    const studioName = ref("");

    const timezone = ref("America/New_York");
    const provider = ref(ActiveProviders.MindBody);
    const zip = ref("");

    const createdTeamId = ref(null);

    const activeProviders = computed(() => ActiveProviders);
    const switchUrl = computed(() => import.meta.env.VITE_APP_MAIN_URL + "/studios/" + createdTeamId.value + "/switch");

    const {mutate, onError, onDone} = useCreateTeamMutation({});
    onDone((result) => {
      $toast({
               type:         "success",
               message:      "Studio created!",
               linkText:     null,
               linkRouteObj: null
             });
      creating.value = false;
      createdTeamId.value = result.data.CreateTeam.id;
      created.value = true;
      EventBus.getInstance().emit(RootEventTypes.NewStudioCreated, {data: null});
    });

    onError((error) => {
      fillValidationErrorsOrToast(error, errors, "Error creating your new studio. Please try again!", "input.");
      creating.value = false;
    });


    const startSave = () => {
      sparkErrors.value.forget();
      if (!form.value.reportValidity()) {
        return;
      }
      creating.value = true;

      mutate({
               input: {
                 name:     studioName.value,
                 zip:      zip.value,
                 provider: provider.value as unknown as QLProvidersEnum,
                 timezone: timezone.value
               }
             });

    };

    return {
      openModel,
      form,
      creating,
      created,
      studioName,
      timezone,
      provider,
      zip,
      activeProviders,
      switchUrl,
      sparkErrors,
      startSave
    };
  }

};

</script>
