<template>
  <div>
    <div class="py-6 px-6 w-full">
      <div class="flex items-center">
        <div class="grow">
          <div>{{ segment.name }}</div>

          <div
              v-if="loading"
              class="flex -space-x-1 pt-2">
            <div
                v-for="i in 6"
                :key="i"
                class="w-6 h-6 block">
              <div class="rounded-full border-2 border-white loading-bg w-full h-full animate-loading" />
            </div>
          </div>

          <div
              v-if="!loading"
              class="mt-4">
            <div class="flex -space-x-1">
              <div
                  v-for="client in clientList"
                  :key="client.id"
                  class="w-6 h-6 block rounded-full">
                <router-link :to="{name: routeList.client.show, params: {id: client.id}}">
                  <tooltip>
                    <template #visible>
                      <client-avatar
                          :client="client"
                          class="w-6 h-6 rounded-full ring-2 ring-white" />
                    </template>
                    <template #tooltip>
                      <ClientTooltip :client="client" />
                    </template>
                  </tooltip>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center shrink-0 ml-5">
          <div
              v-if="!loading"
              class=" text-3xl font-semibold">
            {{ total }}
          </div>
          <div
              v-if="loading"
              class=" text-3xl font-semibold animate-loading">
            <loading-skeleton
                class-type="inline"
                text="8" />
          </div>
          <div class="text-xs text-gray-400">
            clients
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Tooltip                        from "@/components/layout/Tooltip.vue";
import {
  QLFragmentSmallSegmentFragment, QLSortOrder, useClientsListDashboardQuery
}                                     from "@/graphql/queries/ql/composables";
import LoadingSkeleton                from "@/components/layout/LoadingSkeleton.vue";
import ClientAvatar                   from "@/components/client/ClientAvatar.vue";
import {
  SegmentDecorator
}                                     from "@/classes/entities/decorators/SegmentDecorator";
import {computed, useAttrs, useSlots} from "vue";
import routesList                     from "@/composables/routesList";
import ClientTooltip                  from "@/components/client/ClientTooltip.vue";

interface Props {
  segment: QLFragmentSmallSegmentFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
const {result, loading} = useClientsListDashboardQuery(() => ({
  first:   20,
  orderBy: [
    {
      column: "last_name",
      order:  QLSortOrder.ASC
    }
  ],
  page:    1,
  segment: (new SegmentDecorator(props.segment)).toPost
}));

const clientList = computed(() => result.value?.clients_list_dashboard.data ?? []);
const total = computed(() => result.value?.clients_list_dashboard.paginatorInfo.total ?? 0);

const {routeList} = routesList();

</script>
