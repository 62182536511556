<template>
  <div>
    <automation-show-info-email
        v-if="automation.delivery_type === 'EMAIL'"
        :automation="automation" />

    <automation-show-info-sms
        v-if="automation.delivery_type === 'SMS'"
        :automation="automation" />

    <automation-show-info-todo
        v-if="automation.delivery_type === 'TODO'"
        :automation="automation" />

    <automation-show-info-tag
        v-if="automation.delivery_type === 'TAG'"
        :automation="automation" />
  </div>
</template>
<script lang="ts" setup>

import AutomationShowInfoEmail from "@/components/automations/Show/Infos/Email/AutomationShowInfoEmail.vue";
import AutomationShowInfoSms   from "@/components/automations/Show/Infos/Sms/AutomationShowInfoSms.vue";
import AutomationShowInfoTodo  from "@/components/automations/Show/Infos/Todos/AutomationShowInfoTodo.vue";
import AutomationShowInfoTag   from "@/components/automations/Show/Infos/Tags/AutomationShowInfoTag.vue";

import {toRef, useAttrs, useSlots}        from "vue";
import {QLFragmentFullAutomationFragment} from "@/graphql/queries/ql/composables";

interface Props {
  automation: QLFragmentFullAutomationFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
const automation = toRef(props, "automation");
</script>
