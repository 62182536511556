<template>
  <div class="">
    <div class="">
      <div class="text-sm font-medium text-gray-700">
        Map field to Mindbody profile
      </div>
      <div
          class="mt-1 text-sm text-gray-900">
        <select
            v-model="mappedModel"
            class="form-input">
          <option
              v-for="opt in mappingsAvailable"
              :key="opt.value"
              :disabled="opt.used" :value="opt.value">
            {{ opt.display }}
          </option>
        </select>
        <p class="text-sm mt-2 text-gray-700">
          Select which client profile field in Mindbody will be updated using the client input for this form field. Form
          fields that are left blank by a client will NOT update Mindbody client profile records.
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import {FormMboMappingWithUsage}      from "@/components/Forms/Fields/fields";
import {computed, useAttrs, useSlots} from "vue";
import InputFieldsFunctions           from "@/components/Forms/Fields/Input/InputFieldsFunctions";

interface Props {
  mapped: string | null;
  mappings: FormMboMappingWithUsage[];
  originalMap: string | null;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:mapped"]);

const mappedModel = computed({
                               get: () => props.mapped,
                               set: (val) => emit("update:mapped", val)
                             });

const mappingsAvailable = computed(() => {
  const toReturn: FormMboMappingWithUsage[] = [
    {
      value: null, display: "-", used: false
    }
  ];

  if (props.mappings) {
    toReturn.push(...props.mappings);
  }

  if(props.originalMap){
    toReturn.find((val) => val.value == props.originalMap).used = false;
  }

  // if (props.mapped == null) {
  return toReturn;
  // }
  // find the current used map and add it to the mappings to let it selct
  const thisMap = InputFieldsFunctions.mappingInformation(props.mapped);


  if (!thisMap) {
    return toReturn;
  }
  toReturn.push(thisMap);
  return toReturn;
});

</script>
