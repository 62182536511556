import {BookingEventGroup} from "hm-filters/filters/partials/BookingEventGroup";
import ExactTimeOperator   from "hm-filters/operators/ExactTimeOperator";

export class BookingClassTimesFilter extends BookingEventGroup {

	constructor(name: string, options: any) {
		super(name, options);

		this.clearOperators();
		this.addOperators([
			                  new ExactTimeOperator("at_time", "at"),
			                  new ExactTimeOperator("before_time", "before"),
			                  new ExactTimeOperator("after_time", "after")
		                  ]);
	}

	get type(): string {
		return "BookingClassTimesFilter";
	}
}


