<template>
  <confirm-modal
      v-model:open="openModel"
      :loading="saving"
      close-text="No, go back"
      title="Clone this form?"
      type="question"
      @clicked="performAction"
      @close="openModel = false">
    <template #button>
      Yes, clone it!
    </template>
    <template #text>
      <p>
        You can duplicate this form to produce a new version that is editable.
      </p>
      <p>The new duplicate will be named <strong>{{ form.name }} - Copy</strong></p>
    </template>
  </confirm-modal>
</template>
<script lang="ts" setup>

import ConfirmModal                                            from "@/components/layout/ConfirmModal.vue";
import {computed, ref, useAttrs, useSlots}                     from "vue";
import {QLFragmentFormSmallFragment, useDuplicateFormMutation} from "@/graphql/queries/ql/composables";
import $toast                                                  from "@/composables/toast";
import routesList                                              from "@/composables/routesList";

interface Props {
  form: QLFragmentFormSmallFragment;
  open: Boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open"]);

const saving = ref(false);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => {
                               emit("update:open", val);
                             }
                           });

const closeIt = () => {
  saving.value = false;
  emit("update:open", false);
};

const mut = useDuplicateFormMutation({});

mut.onDone((data) => {
  $toast({
           type:         "success",
           message:      "Your form was cloned",
           linkRouteObj: {name: routesList().routeList.forms.show.index, params: {id: data.data.DuplicateForm.id}},
           linkText:     "View your cloned form"
         });

  closeIt();
});

mut.onError(() => {
  $toast({
           type:         "error",
           message:      "There was a problem while cloning the form. Try again",
           linkRouteObj: null,
           linkText:     null
         });
  saving.value = false;
});

const performAction = () => {
  saving.value = true;

  mut.mutate({
               name: props.form.name + " - Copy",
               id:   props.form.id
             });
};

</script>
