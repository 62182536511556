import {QLAutomationBulkActionEnum, useBulkUpdateAutomationsMutation} from "@/graphql/queries/ql/composables";
import {ref, watchEffect}                                             from "vue";
import $toast                                                         from "@/composables/toast";
import WebNotificationData
                                                                      from "@/classes/notifications/WebNotificationData";

export function bulkUpdateAutomationComposable() {
	const bulkMutation = useBulkUpdateAutomationsMutation({});
	const bulkOpen = ref(false);
	const bulkSaving = ref(false);

	const bulkIds = ref<string[]>([]);

	bulkMutation.onDone(() => {
		bulkOpen.value = false;
		bulkSaving.value = false;
	});

	bulkMutation.onError(() => {
		bulkSaving.value = false;
		$toast({
			       type:    "error",
			       message: "There was a problem while performing the bulk action. Please try again"
		       } as WebNotificationData);
	});

	const sendBulkRequest = (action: QLAutomationBulkActionEnum) => {
		bulkSaving.value = true;
		bulkMutation.mutate({
			                    ids:    bulkIds.value,
			                    action: action
		                    });
	};


	watchEffect(() => {
		if (bulkOpen.value == false) {
			bulkIds.value = [];
		}
	});

	return {
		bulkOpen,
		bulkSaving,
		bulkIds,
		sendBulkRequest
	};
}
