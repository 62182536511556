<template>
  <router-link
      :to="{name: routeList.client.todos.show, params:{id: todo.client.id, todoId: todo.id}}"
      class="block hover:bg-blue-50">
    <div class="py-4 px-4">
      <div class="flex">
        <div class="grow">
          <div class="pr-4">{{ todo.subject }}</div>
        </div>
        <div class="">
          <div class="flex flex-col items-end space-y-2">
            <div
                v-if="decorator.isOpen && !decorator.isPostponed && !decorator.isOverdueFuture && !decorator.isOverduePast"
                class="badge-tag-yellow">
              Open
            </div>
            <div
                v-if="decorator.isOpen && decorator.isPostponed"
                class="badge-tag-blue">
              Postponed
            </div>
            <div
                v-if="decorator.isOpen && decorator.isOverduePast"
                class="badge-tag-red">
              Overdue
            </div>

            <tag-with-icon
                v-if="todo.priority === priorityTypes.LOW"
                color="gray"
                icon="thermometerLow">
              Low-priority
            </tag-with-icon>
            <tag-with-icon
                v-if="todo.priority === priorityTypes.MEDIUM"
                color="purple"
                icon="thermometerMed">
              Medium priority
            </tag-with-icon>
            <tag-with-icon
                v-if="todo.priority === priorityTypes.URGENT"
                color="loopspark"
                icon="thermometerHigh">
              Urgent
            </tag-with-icon>
          </div>
        </div>
      </div>
      <div class="flex justify-between mt-2">
        <div class="text-sm">
          <div class="flex items-center space-x-2">
            <div class="w-6 h-6 block">
              <client-avatar
                  :client="todo.client"
                  class="w-full h-full" />
            </div>
            <div>{{ todo.client.first_name }} {{ todo.client.last_name }}</div>
          </div>
        </div>
        <div class="text-sm">
          <div class="flex space-x-2 items-center">
            <tag-with-user
                v-if="todo.assigned_to"
                :user="todo.assigned_to" />
            <tag-with-icon
                v-if="decorator.assignedToEveryone"
                color="green"
                icon="users">
              Everyone
            </tag-with-icon>
            <tag-with-icon
                v-if="todo.team_group"
                color="gray"
                icon="users">
              {{ todo.team_group.name }}
            </tag-with-icon>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script lang="ts" setup>

import ClientAvatar                             from "@/components/client/ClientAvatar.vue";
import TagWithIcon                              from "@/components/layout/TagWithIcon.vue";
import TagWithUser                              from "@/components/layout/TagWithUser.vue";
import {toRef, useAttrs, useSlots}              from "vue";
import {QLFragmentTodoFragment, QLTodoPriority} from "@/graphql/queries/ql/composables";
import todoDecorator                            from "@/composables/decorators/TodoDecorator";
import routesList                               from "@/composables/routesList";

interface Props {
  todo: QLFragmentTodoFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
const todo = toRef(props, "todo");
const decorator = todoDecorator(todo).computed;
const priorityTypes = QLTodoPriority;
const {routeList} = routesList();

</script>
