<template>
  <div class="min-h-screen flex">
    <router-view
        v-if="initialLoadCompleted"
        class="flex-1" />
    <div
        v-if="!initialLoadCompleted"
        class="flex-1 flex items-center justify-center">
      <loader class="w-16 h-16 text-loopspark-500" />
    </div>

  </div>
</template>
<script lang="ts" setup>
import Loader                          from "@/components/layout/Loader.vue";
import {computed}                      from "vue";
import meQuery                         from "@/composables/queries/meQuery";
import currentTeamQuery                from "@/composables/queries/currentTeamQuery";
import echoManager                     from "@/classes/notifications/echoManager";
import {echoListener}                  from "@/composables/echoListener";
import {AllEventTypes, RootEventTypes} from "@/classes/notifications/EventTypes";
import {SoundSystem}                   from "@/classes/notifications/SoundSystem";
import {useRouter}                     from "vue-router";
import routesList                      from "@/composables/routesList";

const {me, loading: meLoading, refetch: meRefetch} = meQuery("cache-first", {pollInterval: 120 * 1000});
const {currentTeam, loading: currentTeamLoading, refetch: currentTeamRefetch} = currentTeamQuery();

let internalInitialLoadCompleted = false;

const initialLoadCompleted = computed(() => {
  if (internalInitialLoadCompleted) {
    console.info("initialLoad already done");
    return true;
  }
  internalInitialLoadCompleted = !meLoading.value && !currentTeamLoading.value;
  console.info("initialLoad status: " + (initialLoadCompleted ? "done" : "pending"));
  return internalInitialLoadCompleted;
});
const soundSystem = SoundSystem();
const {init, echo} = echoManager();

const router = useRouter();

init();

echoListener([RootEventTypes.RefreshMe, RootEventTypes.AcceptedInvitation], (ev) => {
  console.info("meRefetch");
  meRefetch();
}, 5000);

echoListener([RootEventTypes.RefreshTeam], (ev) => {
  currentTeamRefetch();
}, 5000);

echoListener([RootEventTypes.UserNotification], (ev) => {
  soundSystem.play();
}, 5000);

echoListener([AllEventTypes.LoggedOut], (ev) => {
  router.replace({name: routesList().routeList.login});
}, 5000);

</script>
