<template>
  <page-title title="Club Count Manager" section="Settings" />
  <div class="container mx-auto grow space-y-16">

    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Exclude events
        </div>
        <div class="mt-1 text-gray-600 text-sm space-y-6">
          <p>
            Select the even names (class types, workshops, childcare, appointment types) that you want to exclude from
            visit count, appointment count, and club count. These event types will also be excluded from other event
            attendance related calculations such as First Visit Date.
          </p>
          <p>
            <span class="font-medium">Example:</span>
            If your studio offers childcare, and your booking platform marks childcare attendance as a "class visit" for
            the parent / guardian, add your "childcare" event to this list to remove attendance from being calculated or
            contributed toward the class count, appointment count and club count attendance for the adult.
          </p>
        </div>
      </div>

      <club-count-manager-event-list class="flex-1" />
    </div>

    <div class="split-view-left" v-if="canChangePeriodCount">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Period count
        </div>
        <div class="mt-1 text-gray-600 text-sm space-y-6">
          <p>
            Club Count is the total number of events a client has ever attended, minus any event types you've chosen to
            exclude from Club Count contributions.
            In addition to Club Count, LoopSpark offers Period Counts! Period Count is similar to Club Count; however,
            the Club Count will reset on a rolling basis of your choice (example - if your business rewards clients for
            the total number of attended events each year, Period Count is your solution!).
          </p>
        </div>
      </div>

      <club-count-period class="flex-1" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import PageTitle                 from "@/components/navbar/PageTitle.vue";
import ClubCountManagerEventList from "@/components/studio/settings/ClubCountManager/ClubCountManagerEventList.vue";
import ClubCountPeriod           from "@/components/studio/settings/ClubCountManager/ClubCountPeriod.vue";
import currentTeamQuery          from "@/composables/queries/currentTeamQuery";
import {QLPeriodCountType}       from "@/graphql/queries/ql/composables";
import {computed}                from "vue";

const {currentTeam} = currentTeamQuery("cache-first");

const canChangePeriodCount = computed(() => {
  return currentTeam.value && currentTeam.value.period_count_type != QLPeriodCountType.API;
});

</script>
