<template>
  <modal
      v-model:open="openModel"
      v-focus
      full-heigth
      has-close-button
      quick-close
      tabindex="0"
      wide
      @close="openModel = false">
    <template #default>
      <div class="block w-full h-full">
        <div
            v-if="viewEmailSetup.loading || currentTeamLoading"
            class="flex items-center justify-center">
          <div class="w-8 h-8">
            <loader />
          </div>
        </div>
        <div
            v-else
            class="flex flex-col h-full">
          <div>
            <h1 class="text-lg">
              Email Preview
            </h1>
            <p class="text-sm text-gray-500">
              Below you can see how this email would look like with generic merge tags.
              You
              can select a specific client
              and see how the email would be generated for them
            </p>
          </div>

          <div
              v-show="!selectedClient"
              class="mt-4">
            <client-search
                :go-on-hit="false"
                @selected="changeSelectedClient" />
          </div>

          <div
              v-if="selectedClient"
              class="mt-4">
            <div class="mb-2 text-sm">
              You selected:
            </div>
            <div class="flex items-center">
              <div class="flex items-center grow">
                <div class="inline-block">
                  <client-avatar
                      :client="selectedClient"
                      class="w-8 h-8" />
                </div>
                <div class="ml-2 text-gray-800">
                  <span class="whitespace-nowrap">{{ selectedClient.first_name }} {{ selectedClient.last_name }}</span>
                  <div class="text-sm text-gray-600">
                    <client-status-text :client="selectedClient" />
                  </div>
                </div>
              </div>
              <div>
                <button
                    class="text-gray-400 hover:text-blue-600 focus:outline-none"
                    @click="selectedClient = null">
                  <svg-icon
                      name="closeCircle"
                      class="h-6 w-6" />
                </button>
              </div>
            </div>
          </div>

          <div class="mt-8 pt-4 mb-6 border-t">
            <strong>Subject:</strong> <span>{{ viewEmailSetup.finalSubject }}</span>
          </div>
          <div class="block w-full overflow-y-scroll border p-1 flex-1">
            <email-iframe
                :html="finalHtml"
                class="w-full block" />
          </div>
        </div>
      </div>
    </template>
    <template #buttons>
      <v-button
          :disabled="meLoading || viewEmailSetup.loading || sending"
          :loading="sending"
          class="modal-button modal-button-loopspark w-auto"
          @click="sendToMe">
        <div class="flex items-center">
          <svg-icon
              name="send"
              class="h-4 w-4" />
          <div class="pl-1">
            Send this email to myself <span v-if="me">({{ me.email }})</span>
          </div>
        </div>
      </v-button>
    </template>
  </modal>
</template>
<script lang="ts">
import viewEmail                               from "@/composables/email/viewEmail";
import ClientAvatar                            from "@/components/client/ClientAvatar.vue";
import VButton                                 from "@/components/layout/VButton.vue";
import EmailIframe                             from "@/components/discussions/show/EmailIframe.vue";
import ClientStatusText                        from "@/components/client/ClientStatusText.vue";
import ClientSearch                            from "@/components/navbar/ClientSearch.vue";
import Modal                                   from "@/components/layout/Modal.vue";
import Loader                                  from "@/components/layout/Loader.vue";
import SvgIcon                                 from "@/components/SvgIcon.vue";
import currentTeamQuery                        from "@/composables/queries/currentTeamQuery";
import meQuery                           from "@/composables/queries/meQuery";
import {computed, onMounted, ref, watch} from "vue";
import EmailTemplateContent              from "@/classes/entities/EmailTemplateContent";
import {QLClient, useEmailPreviewSendMutation} from "@/graphql/queries/ql/composables";
import $toast                                  from "@/composables/toast";


export default {
  name:       "EmailPreview",
  components: {
    EmailIframe,
    Modal,
    Loader,
    ClientSearch,
    ClientAvatar,
    SvgIcon,
    VButton,
    ClientStatusText
  },
  props:      {
    ...viewEmail().props,
    open: {
      type:     Boolean,
      required: true
    }
  },
  emits:      ["update:open"],
  setup(props, {emit}) {
    const selectedClient = ref<QLClient | null>(null);

    const viewEmailSetup = viewEmail().setup(props, selectedClient);

    const {currentTeam, loading: currentTeamLoading} = currentTeamQuery();
    const {me, loading: meLoading} = meQuery();

    const myframe = ref(null);
    const sending = ref(false);

    const openModel = computed({
                                 get: () => props.open,
                                 set: (val) => emit("update:open", val)
                               });

    onMounted(() => {
      if(openModel.value){
        viewEmailSetup.getEmailPreview();
      }
    })

    watch(openModel, () => {
      if (openModel.value === true) {
        viewEmailSetup.getEmailPreview();
      }
    });


    const changeSelectedClient = (item) => {
      selectedClient.value = item;
      viewEmailSetup.getEmailPreview();
    };


    const finalHtml = computed(() => {
      return EmailTemplateContent.replaceColorInString(viewEmailSetup.finalBody,
                                                       currentTeam.value ? currentTeam.value.color : null);
    });

    const sendToMeMutation = useEmailPreviewSendMutation({});
    sendToMeMutation.onDone(() => {
      sending.value = false;
      $toast({
               type:    "success",
               message: "The preview email was sent to your email address",
               sticky:  false
             });
    });

    sendToMeMutation.onError(() => {
      sending.value = false;
      $toast({
               type:    "error",
               message: "Error sending this email",
               sticky:  true
             });
    });

    const sendToMe = () => {
      sending.value = true;
      sendToMeMutation.mutate({
                                subject:   props.subject,
                                body:      props.body,
                                client_id: selectedClient.value ? selectedClient.value.id : null
                              });
    };

    return {
      myframe,
      openModel,
      sending,
      currentTeamLoading,
      viewEmailSetup,
      finalHtml,
      changeSelectedClient,
      sendToMe,
      me,
      meLoading
    };

  }

};
</script>
