import Package                                            from "./partials/package";
import FixedValueOperator, {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";

class PackageCancelled extends Package {
	constructor(name: string, options: any) {
		super(name, options);

		this.addOperators(
			[
				new FixedValueOperator(true, "is cancelled"),
				new FixedValueOperator(false, "is not cancelled")
			]);
	}

	get type(): string {
		return "PackageCancelledFilter";
	}
}

export default PackageCancelled;
