<template>
  <router-link
      :to="{name: routeList.event.show, params:{id: singleEvent.id}}"
      class="py-4 w-full group block hover:opacity-100"
      :class="{'opacity-50': singleEvent.cancelled}">
    <div class="flex justify-between items-center">
      <div class="text-xs text-gray-800">
        <timestamp-to-date
            :has-date="false"
            :timestamp="singleEvent.start_time"
            has-time
            studio />
      </div>
      <div class="text-xs flex items-center space-x-1 text-gray-500">
        <svg-icon
            name="users"
            class="w-3 h-3" />
        <span>{{ attending }}</span>
      </div>
    </div>
    <div class="">
      <router-link
          :to="{name: routeList.event.show, params:{id: singleEvent.id}}"
          class="block font-medium text-sm  group-hover:text-loopspark-500"
          :class="{'text-loopspark-700': !singleEvent.cancelled, 'text-red-700': singleEvent.cancelled}">
        {{ singleEvent.name }}
      </router-link>
      <span class="block text-gray-500 text-xs"><span
          v-if="singleEvent.cancelled"
          class="text-xs text-red-500">Cancelled</span> {{
          classDefinition
                                                        }}</span>
    </div>
  </router-link>
</template>
<script lang="ts" setup>

import {QLCalendarClassFragmentFragment, useCalendarUpdateSingleClassQuery} from "@/graphql/queries/ql/composables";
import TimestampToDate
                                                                            from "@/components/layout/TimestampToDate.vue";
import SvgIcon                                                              from "@/components/SvgIcon.vue";
import {computed, ref, toRef, useAttrs, useSlots}                           from "vue";
import routesList                                                           from "@/composables/routesList";
import {
  EventDecorator
}                                                                           from "@/composables/decorators/EventDecorator";
import {echoListener}                                                       from "@/composables/echoListener";
import {EventTypes}                                                         from "@/classes/notifications/EventTypes";

interface Props {
  singleEvent: QLCalendarClassFragmentFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const {routeList} = routesList();

const emit = defineEmits([]);
const singleEvent = toRef(props, "singleEvent");

const {isGroupClass, classDefinition} = EventDecorator(singleEvent).computed;

const attending = computed(() => {
  return singleEvent.value.visits.filter(visit => !visit.late_cancelled && !visit.early_cancel).length;
});
const enabledRefresh = ref(false);
const singleEventQ = useCalendarUpdateSingleClassQuery(() => ({id: singleEvent.value.id}),
                                                       () => ({enabled: enabledRefresh.value}));

echoListener([EventTypes.VisitWasUpdated], (ev) => {
  if (ev.data.class_model_id == singleEvent.value.id) {
    enabledRefresh.value = true;
    singleEventQ.refetch().finally(() => enabledRefresh.value = false);

  }
}, 5000);

</script>
