<template>
  <filter-group-base-category
      v-model:filter-selected="filterSelected"
      v-model:operator-selected="operatorSelected"
      :category-name="categoryName"
      @step-back="stepBack"
      @added-rule="addedRule($event)"
  >
    <template #title>
      New Filter - Contract
    </template>
    <template #subtitle>
      The Contract filter category contains filters for auto-pay contracts.
    </template>
    <template #side-description>
      <div>
        <span class="italic pb-2 block">Tip:</span>
        <p>
          Adding contract information as a separate rule provides a good safety net to prevent contract holders
          from receiving messages regarding discounted packages, or communications for clients you're trying to up-sell
          to become contract members.
        </p>

        <div class="pt-4">
          <p>
            Examples:
          </p>

          <ol class="filter-side-examples pt-2">
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Contract Status = Active</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that have at least one active
                contract.
              </div>
            </li>
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Contract Purchased = No Contract</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that have never
                purchased any contracts.
              </div>
            </li>
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Contract Purchased</span> +
                <span class="fake-filter">Contract Purchase Date</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that purchased a specific contract
                on a specific date.
              </div>
            </li>
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Contract Purchased</span> +
                <span class="fake-filter">Contract Status</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that have a specific contract that is
                active or inactive.
              </div>
            </li>
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Contract Status = Active</span> +
                <span class="fake-filter">Contract Expiration Date</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that have an active contract
                expiring in a defined timeframe.
              </div>
            </li>
          </ol>
        </div>
      </div>
    </template>
    <template #filters>
      <slot />
    </template>
  </filter-group-base-category>
</template>
<script lang="ts">
import {singleCategoryComposition} from "@/components/Segment/Edit/Categories/SingleCategories/singleCategoryComposition";

const cat = singleCategoryComposition();
export default {
  components: {
    ...cat.components
  },
  props:      {
    ...cat.props
  },
  emits:      cat.emits,
  setup(props, {emit}) {
    return cat.setup(props, emit);
  }
};
</script>
