<template>
  <router-link
      v-if="discussion"
      v-slot="{navigate}"
      custom
      :to="{name: routeList.client.discussions.show, params:{id: discussion.client.id, discussionId: discussion.id}}">
    <tr
        class="hover:bg-gray-50 cursor-pointer"
        @click="navigate">
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="flex items-center">
          <div class="shrink-0 h-10 w-10">
            <client-avatar
                :client="discussion.client"
                class="w-10 h-10" />
          </div>
          <div class="ml-4">
            <div class="text-sm font-medium text-gray-900">
              {{ discussion.client.first_name }} {{ discussion.client.last_name }}
            </div>
            <div class="text-sm text-gray-500">
              <client-status-text :client="discussion.client" />
            </div>
          </div>
        </div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="pr-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-700" v-if="discussion.email">
          <div class="rounded-full bg-gray-400 text-gray-50 h-5 w-5 flex items-center justify-center">
          <svg-icon name="email-solid" class="w-4 h-4"></svg-icon>
          </div>
          <span class="pl-1">Email</span>
        </div>

        <div class="pr-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-700" v-if="!discussion.email">
          <div class="rounded-full bg-gray-400 text-gray-50 h-5 w-5 flex items-center justify-center">
          <svg-icon name="mobile-alt" class="w-4 h-4"></svg-icon>
          </div>
          <span class="pl-1">SMS</span>
        </div>
      </td>
      <td class="px-6 py-4">
        <div class="text-base text-gray-900">
          <span
              v-if="discussion.sms && !discussion.automation_id">Direct discussion</span>
          <span
              v-if="discussion.email && !discussion.automation_id">Subject: {{ discussion.subject }}</span>
          <span v-if="discussion.automation_id">Automation: <router-link
              :to="{ name: routeList.automations.show.index, params: {id: discussion.automation_id}}"
              class="text-loopspark">
                        {{ discussion.automation.name }}
                    </router-link></span>
        </div>
        <div class="text-sm text-gray-500 flex items-center">
          <i>{{ messageSenderName }}:</i>
          <div class="w-auto max-w-sm lg:max-w-md inline-block truncate overflow-hidden pl-1">
            {{ messageText }}
          </div>
        </div>
      </td>
      <td class="px-6 py-4 whitespace-nowrap">
        <div class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
             v-if="!discussion.closed">
          Open
        </div>
        <div class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-600"
             v-if="discussion.closed">
          Closed
        </div>
      </td>

      <td class="px-6 py-4 whitespace-nowrap text-right">
        <div class="text-sm text-gray-500">
          <time-ago :timestamp="discussion.last_contacted_at" />
        </div>
      </td>
    </tr>

  </router-link>
</template>
<script lang="ts" setup>

import ClientAvatar                          from "@/components/client/ClientAvatar.vue";
import TimeAgo                               from "@/components/layout/TimeAgo.vue";
import ClientStatusText                      from "@/components/client/ClientStatusText.vue";
import {computed, toRef, useAttrs, useSlots} from "vue";
import {QLDiscussion}                        from "@/graphql/queries/ql/composables";
import MessageDecorator                      from "@/composables/decorators/MessageDecorator";
import {useRouter}                           from "vue-router";
import routesList                            from "@/composables/routesList";
import SvgIcon                               from "@/components/SvgIcon.vue";

interface Props {
  discussion: QLDiscussion;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const discussion = toRef(props, "discussion");


const decorator = MessageDecorator(discussion.value.last_message, discussion.value);
const messageText = computed(() => {
  return decorator.computed.messageText;
});

const messageSenderName = computed(() => {
  return decorator.computed.senderName;
});

const router = useRouter();
const {routeList} = routesList();

</script>
