<template>
  <input-field-base
      v-if="fieldModel"
      v-model:field="fieldModel"
      v-model:open="openModel"
      :can-save="canSave"
      :name-already-used="nameAlreadyUsed"
      @cancelled="openModel = false"
      @saved="saved">
    <template #fields>
      <div class="">
        <div class="text-sm font-medium text-gray-700">
          Description
        </div>
        <div
            class="mt-1 text-sm text-gray-900">
              <textarea
                  v-model="fieldModel.description"
                  class="form-input" />
        </div>
      </div>
      <form-check-box
          v-model="fieldModel.optional"
          title="Optional" />
      <div class="">
        <div class="text-sm font-medium text-gray-700">
          Options
        </div>
        <div class="mt-3 text-sm text-gray-900">
          <TransitionGroup name="list" tag="div" class="divide-y">
            <select-field-input-single-item :value="opt" v-for="(opt, index) in fieldModel.options"
                                            @deleted="deleteOpt(opt)"
                                            @updated="(n) => fieldModel.options[index] = n"
                                            @moveUp="moveOpt(index, index - 1)"
                                            @moveDown="moveOpt(index, index + 1)"
                                            :can-up="index>0"
                                            :can-down="index<fieldModel.options.length -1"
                                            :key="opt"></select-field-input-single-item>
          </TransitionGroup>
          <div class="mt-0 mb-4 flex items-stretch ">
            <input
                ref="addField"
                v-model="newOption"
                type="text"
                class="form-input rounded-r-none flex-1 border-r-0"
                @keyup.enter.prevent="addOption">
            <button
                class="btn btn-green rounded-l-none"
                @click.prevent="addOption">
              Add
            </button>
          </div>
        </div>
      </div>
    </template>
  </input-field-base>
</template>

<script lang="ts" setup>
import {computed, ref, withDefaults} from "vue";
import FormCheckBox                  from "@/components/Forms/Fields/Input/Partials/FormCheckBox.vue";
import InputFieldBase                from "@/components/Forms/Fields/Input/InputFieldBase.vue";
import InputFieldsFunctions          from "@/components/Forms/Fields/Input/InputFieldsFunctions";
import {QLFormField}                 from "@/graphql/queries/ql/composables";
import SelectFieldInputSingleItem    from "@/components/Forms/Fields/Input/Partials/SelectFieldInputSingleItem.vue";

interface InputFieldProps {
  field: QLFormField;
  open: boolean;
  otherFields: QLFormField[];
  useMappings: boolean;

}

const props = withDefaults(defineProps<InputFieldProps>(), {});
const emit = defineEmits(["deleted", "update:field", "saved", "update:open"]);

const {
  fieldModel,
  openModel,
  otherFields,
  saved,
  toRun,
  nameAlreadyUsed
} = InputFieldsFunctions.bootstrapEditField(emit,
                                            props);
toRun();
const newOption = ref("");
const addField = ref<HTMLInputElement | null>(null);

const addOption = () => {
  const trimmed = newOption.value.trim();
  if (trimmed !== "") {
    if (fieldModel.value.options.indexOf(trimmed) === -1) {
      fieldModel.value.options.push(trimmed);
    }
    newOption.value = "";
    addField.value.focus();
  }
};

const deleteOpt = (opt: string) => {
  fieldModel.value.options.splice(fieldModel.value.options.indexOf(opt), 1);
};
const canSave = computed(() => {
  if (!props.field) {
    return false;
  }
  if (InputFieldsFunctions.nameIsAlreadyUsed(otherFields.value, fieldModel.value)) {
    return false;
  }

  return fieldModel.value.title.trim().length > 0 && fieldModel.value.options.length > 0;
});

const moveOpt = (currentIndex, newIndex) => {
  if (newIndex >= 0 && newIndex < fieldModel.value.options.length) {
    // swap and reassign array
    [fieldModel.value.options[currentIndex], fieldModel.value.options[newIndex]] = [
      fieldModel.value.options[newIndex],
      fieldModel.value.options[currentIndex]
    ];
  }
};

</script>
