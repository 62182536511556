import Operator           from "./operator";
import {HasInput, IsDate} from "hm-filters/operators/Bases/InputBases";
import {OperatorTypes}    from "hm-filters/operators/OperatorTypes";

class ExactDateOperator extends Operator implements HasInput, IsDate {
	fixedValue = undefined;
	hasInput: boolean = true;
	isDate: boolean = true;

	get type(): string {
		return OperatorTypes.ExactDate;
	}
}

export default ExactDateOperator;
