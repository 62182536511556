<template>
  <div>
    <label class="form-label"
           v-bind:class="{'has-error': hasError}"
           v-bind:for="id"
           v-if="label">
      {{ label }} <span class="text-xs opacity-50" v-bind:class="{'is-required': requiredAndEmpty}" v-if="required">(required)</span>
    </label>
    <input :minlength="minLength"
           :maxlength="maxLength"
           :max="max"
           :min="min"
           :placeholder="placeholder"
           class="form-input form-input-default mt-1"
           :type="type"
           v-bind:autocomplete="autocomplete !== null ? autocomplete : undefined"
           v-bind:autofocus="focus"
           v-bind:class="{'has-error': hasError}"
           v-bind:disabled="disabled"
           v-bind:id="id"
           v-bind:required="required"
           ref="htmlinput"
           v-model="inputData">
    <span class="form-error-message" v-if="hasError">{{ errorMessage }}</span>
    <div class="leading-tight form-small-info text-gray-600" v-if="smallInfo.length > 0">{{ smallInfo }}</div>
    <slot></slot>
  </div>
</template>

<script lang="ts">

import {computed, onMounted, ref, toRef} from "vue";

export default {
  props: {
    label:        {
      type:     String,
      required: false,
      default:  () => null
    },
    placeholder:  {
      type:     String,
      required: false,
      default:  () => null
    },
    type:         {
      type:     String,
      required: false,
      default:  () => "text"
    },
    smallInfo:    {
      type:     String,
      required: false,
      default:  () => ""
    },
    errorMessage: {
      type:     String,
      required: false,
      default:  () => null
    },
    required:     {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    disabled:     {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    focus:        {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    min:          {
      type:     Number,
      required: false,
      default: () => null
    },
    minLength:    {
      type:     Boolean,
      required: false,
      default: () => null
    },
    max:          {
      type:     Boolean,
      required: false,
      default: () => null

    },
    maxLength:    {
      type:     Boolean,
      required: false,
      default: () => null
    },
    modelValue:   {
      type:     String,
      required: true,
      default:  () => ""
    },
    autocomplete: {
      type:     String,
      required: false,
      default:  () => null
    }
  },
  emits: ["update:modelValue"],

  setup(props, {emit}) {

    const input = toRef(props, "modelValue");
    const errorMessage = toRef(props, 'errorMessage');
    const required = toRef(props, 'required');
    const focus = toRef(props, 'focus');

    const inputData = computed({
                                 get: () => input.value,
                                 set: val => {
                                   emit("update:modelValue", val);
                                 }
                               });

    const id = ref(null);
    const htmlinput = ref(null);

    const hasError = computed(() => {
      return errorMessage.value && errorMessage.value.length > 0;
    });

    const requiredAndEmpty = computed(() => {
      if (hasError.value) {
        return false;
      }
      if (required.value && (!input.value || input.value.toString().length === 0)) {
        return true;
      }
      return false;
    });

    onMounted(() => {
      id.value = Math.random().toString();
      if (focus.value) {
        htmlinput.value.focus();
      }
    });


    return {
      id,
      inputData,
      htmlinput,
      hasError,
      requiredAndEmpty
    };


  }
};

</script>
