<template>
  <confirm-modal
      v-model:open="openModel"
      :loading="saving"
      type="question"
      close-text="No, go back"
      title="Un-archive this Lead?"
      @close="openModel = false"
      @clicked="submitLead">
    <template #button>
      Yes, un-archive it!
    </template>
    <template #text>
      <p>
        By unarchiving this lead, it will be re-added to the listing and any associated segments, making it more readily
        accessible outside of the search feature again.
      </p>
      <p>Are you sure you want to proceed?
      </p>
    </template>
  </confirm-modal>
</template>
<script lang="ts" setup>

import {QLClient}                        from "@/graphql/queries/ql/composables";
import ConfirmModal                      from "@/components/layout/ConfirmModal.vue";
import updateLeadArchiveStatusComposable from "@/views/ClientShow/Components/Info/updateLeadArchiveStatusComposable";

interface Props {
  open: boolean;
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(["update:open"]);

const {openModel, saving, submitLead} = updateLeadArchiveStatusComposable(props, emit, false);

</script>
