import {MyEcho}                                      from "@/classes/boot/MyEcho";
import {EventBus}                                    from "@/classes/notifications/EventBus";
import meQuery                                       from "@/composables/queries/meQuery";
import {ref, watch}                                  from "vue";
import {AllEventTypes, EventTypes, PublicEventTypes} from "@/classes/notifications/EventTypes";
import currentTeamQuery                              from "@/composables/queries/currentTeamQuery";
import {CurrentUserInstance}                         from "@/classes/state/CurrentUserInstance";

export default function echoManager() {

	const echo = MyEcho.getInstance();
	const bus = EventBus.getInstance();

	const {me} = meQuery();

	const meStarted = ref(false);
	const teamStarted = ref(false);

	const startListeningForUser = () => {
		if (!!me.value && meStarted.value === false) {
			CurrentUserInstance.getInstance().setUser(me.value);
			meStarted.value = true;
			const channel = echo.echo.private("App.User." + me.value.id);
			channel.notification((ev) => bus.emit(AllEventTypes.UserNotification, {data: ev}));
			channel.listen(AllEventTypes.ChangedTeam, (ev) => {
				bus.emit(AllEventTypes.ChangedTeam, {data: {}});
			});
			channel.listen(AllEventTypes.LoggedOut, (ev) => {
				bus.emit(AllEventTypes.LoggedOut, {data: {}});
			});
		}
	};

	watch(me, () => {
		startListeningForUser();
	});

	const {currentTeam} = currentTeamQuery();
	const startListeningForTeam = () => {
		if (!!currentTeam.value && teamStarted.value === false) {
			teamStarted.value = true;
			const channel = echo.echo.private("team." + currentTeam.value.id);
			Object.keys(EventTypes).forEach((key) => {
				channel.listen(EventTypes[key], (ev) => {
					bus.emit(EventTypes[key], {data: ev});
				});
			});
		}
	};
	watch(currentTeam, () => {
		startListeningForTeam();
	});

	const startListeningForPublicNotifications = () => {
		echo.echo.channel("public-channel")
		    .listen(PublicEventTypes.WebVersionUpdated, (ev) => {
			    bus.emit(AllEventTypes.WebVersionUpdated, {data: {version: ev.version}});
		    });
	};

	const init = () => {
		startListeningForPublicNotifications();
		startListeningForUser();
		startListeningForTeam();
	};

	return {
		echo,
		init
	};

}
