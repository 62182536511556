<template>
  <page-title
      title="Edit Form"
      section="Forms" />
  <form
      class="container mx-auto grow"
      @submit.prevent="saveForm">
    <div
        v-if="loading"
        class="w-full flex items-center justify-center h-64">
      <loader class="w-12 h-12 text-gray-500" />
    </div>

    <div
        v-if="!loading"
        class="flex flex-col">
      <div class="mt-6 grow">
        <form-settings-edit
            v-model:form="form"
            v-model:errors="errors"
            :editable="form.editable"
            :saving="saving" />
      </div>
    </div>
    <div
        v-if="!loading"
        class="my-8 flex justify-end">
      <v-button
          type="submit"
          :disabled="saving"
          :loading="saving"
          class="btn btn-green text-sm font-medium">
        Save changes
      </v-button>
    </div>
  </form>
</template>
<script lang="ts" setup>
import Loader                              from "@/components/layout/Loader.vue";
import PageTitle                           from "@/components/navbar/PageTitle.vue";
import {ref, toRaw, toRef, watch}          from "vue";
import FormSettingsEdit                                                from "@/components/Forms/FormSettingsEdit.vue";
import {QLFragmentFormFullFragment, useEditFormMutation, useFormQuery} from "@/graphql/queries/ql/composables";
import {SparkErrors}                                                   from "@/classes/utils/SparkFormErrors";
import routesList                          from "@/composables/routesList";
import $toast                              from "@/composables/toast";
import {fillValidationErrorsAndToast}      from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import {useRouter}                         from "vue-router";
import VButton                             from "@/components/layout/VButton.vue";
import {useResult}                         from "@vue/apollo-composable";
import {deepCopy}                          from "@/classes/helpers/DeepCopy";

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {});
const formId = toRef(props, "id");
const router = useRouter();

const formQuery = useFormQuery({id: formId.value});
const form = ref(null);
const formQl = useResult(formQuery.result, null, data => data.form);

watch(formQl, (data) => {
  if(!saving.value) {
    form.value = deepCopy(data) as QLFragmentFormFullFragment;
  }
});

const {loading} = formQuery;

const errors = ref<SparkErrors>({});
const saving = ref(false);

const mut = useEditFormMutation({});

mut.onDone((d) => {
  saving.value = false;
  const finalRoute = {
    name:   routesList().routeList.forms.show.index,
    params: {id: d.data.EditForm.id}
  };
  $toast({
           type:         "success",
           message:      "The form was updated successfully",
           linkRouteObj: finalRoute,
           linkText:     "View your form"
         });

  router.push(finalRoute);
});

mut.onError((err) => {
  fillValidationErrorsAndToast(err, errors);
  saving.value = false;
});

const saveForm = () => {
  saving.value = true;

  const {editable, id, updated_at, created_at, short_url, ...toPost} = deepCopy(toRaw(form.value));
  const newPost = JSON.parse(JSON.stringify(toPost, (k, v) => (k === "__typename") ? undefined : v));

  mut.mutate({
               id:   formId.value,
               form: newPost
             });
};
</script>
