<template>
  <div class="bg-loopspark-800 w-full min-h-12 border-t border-loopspark-700 text-blue-300 mt-16">
    <div class="container mx-auto">
      <div class="py-4 grid grid-cols-2 md:grid-cols-3 gap-4 leading-loose">
        <div>
          <span class="font-semibold mb-2 block uppercase text-loopspark-200">Support</span>
          <ul>
            <li><a class="hover:text-loopspark-100" href="https://docs.loopspark.com">Documentation</a></li>
            <li><a class="hover:text-loopspark-100" href="https://community.loopspark.com/articles">Articles</a></li>
            <li><a class="hover:text-loopspark-100" href="mailto:support@LoopSpark.com">Email us</a></li>
          </ul>
        </div>
        <div>
          <span class="font-semibold mb-2 block uppercase text-loopspark-200">Company</span>
          <ul>
            <li><a class="hover:text-loopspark-100" href="https://loopspark.com/terms">Terms of service</a></li>
            <li><a class="hover:text-loopspark-100" href="https://loopspark.com/privacy">Privacy</a></li>
          </ul>
        </div>
        <div class="flex flex-row-reverse hidden md:flex">
          <a class="block" href="https://apps.apple.com/us/app/loopspark/id1491780495?ls=1">
            <img alt="Download on the App Store" class="h-10" src="/images/appstores/ios.svg">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.clickviewinteractive.studiogo&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"><img
              alt="Get it on Google Play" class="h-10 mr-2"
              src="/images/appstores/google-play-badge.png"/></a>
        </div>

      </div>
      <hr class="border-loopspark-700">
      <div class="py-4 grid grid-cols-2 gap-4">
        <div class="text-sm text-loopspark-200">&copy; {{currentYear}} - LoopSpark</div>
        <div class="flex flex-row-reverse">
          <div class="ml-2"><a href="https://twitter.com/loopspark" target="_blank">
            <svg-icon name="twitter" class="w-6 h-6 hover:text-loopspark-100"></svg-icon>
          </a></div>
          <div class="ml-2"><a href="https://www.facebook.com/loopspark" target="_blank">
            <svg-icon class="w-6 h-6 hover:text-loopspark-100" name="facebook"></svg-icon>
          </a></div>
          <div class="ml-2">
            <a href="https://www.linkedin.com/company/loopspark" target="_blank">
              <svg-icon class="w-6 h-6 hover:text-loopspark-100" name="linkedin"></svg-icon>
            </a>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import SvgIcon from "@/components/SvgIcon.vue";

const currentYear =  new Date().getFullYear();
</script>
