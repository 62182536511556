import Filter from "../../base-filter";

import config                         from "./config";
import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import NumericOperator                from "hm-filters/operators/NumericOperator";

export class AppointmentCount extends Filter {
	smallText = config.appointments.groups.appointment.group;
	group = config.appointments.groups.appointment.group;
	path = ["Appointments", this.group];

	constructor(name: string, options: any) {
		super(config.appointments.count, options);

		this.operators = [
			new OperatorAsFixedValueOperator("never", "never before"),
			new NumericOperator(">", "more than", "appointments"),
			new NumericOperator("<", "less than", "appointments"),
			new NumericOperator("=", "exactly", "appointments")
		];
	}

	get type(): string {
		return "AppointmentCount";
	}
}

export class AppointmentNoShowCount extends AppointmentCount {
	smallText = config.appointments.groups.noShow.group;
	group = config.appointments.groups.noShow.group;
	path = ["Appointments", this.group];

	get type(): string {
		return "AppointmentNoShowCount";
	}
}

export class AppointmentUpcomingCount extends AppointmentCount {
	smallText = config.appointments.groups.upcoming.group;
	group = config.appointments.groups.upcoming.group;
	path = ["Appointments", this.group];

	constructor(name: string, options: any) {
		super(config.appointments.count, options);

		this.operators = [
			new OperatorAsFixedValueOperator("never", "none"),
			new NumericOperator(">", "more than", "appointments"),
			new NumericOperator("<", "less than", "appointments"),
			new NumericOperator("=", "exactly", "appointments")
		];
	}

	get type(): string {
		return "AppointmentUpcomingCount";
	}
}
