<template>
  <div>
    <div @click="editingModal = true">
      <slot />
    </div>

    <modal
        v-model:open="editingModal"
        :has-close-button="false"
        :loading="saving"
        close-button-text="No, I want to keep it"
        full-heigth
        full-width
        @close="editingModal = false">
      <div class="w-full grow h-full">
        <h2 class="text-xl">
          Change access level for {{ staff.name }}
        </h2>
        <p class="pt-4">
          At the moment, the access level for {{ staff.name }} is
          <span class="lowercase font-semibold">{{ staff.role }}</span>. You can change their level by selecting one of
          the following:
        </p>
        <div class="pt-4">
          <div
              v-if="canAssignAdmin"
              class="box-regular"
              role="button"
              :class="classesForRole(userRolesEnum.ADMIN)"
              @click="selectedOption = userRolesEnum.ADMIN">
            <span class="text-base font-medium block mb-2">Admin <span v-if="staff.role === userRolesEnum.ADMIN" class="current-level-tag">Current level</span></span>
            <p class="text-xs">
              Control over account settings, staff management, automations and report exporting.
            </p>
          </div>

          <div
              class="box-regular "
              role="button"
              :class="classesForRole(userRolesEnum.MANAGER)"
              @click="selectedOption = userRolesEnum.MANAGER">
            <span class="text-base font-medium block mb-2">Manager <span v-if="staff.role === userRolesEnum.MANAGER" class="current-level-tag">Current level</span></span>
            <p class="text-xs">
              Managers <strong>can</strong> create, edit, pause, and un-pause automations. Managers
              <strong>can</strong> export reports.
            </p>
          </div>

          <div
              class="box-regular"
              role="button"
              :class="classesForRole(userRolesEnum.MEMBER)"
              @click="selectedOption = userRolesEnum.MEMBER">
            <span class="text-base font-medium block mb-2">Member <span v-if="staff.role === userRolesEnum.MEMBER" class="current-level-tag">Current level</span></span>
            <p class="text-xs">
              Member is the lowest level of access, and does not allow for any automation control or
              setup, and cannot export any reports. Members will still be able to view all data,
              respond and initiate client discussions, and complete To-Dos.
            </p>
          </div>

          <div
              class="box-regular box-danger"
              role="button"
              :class="classesForRole(null)"
              @click="selectedOption = null">
            <span class="text-base font-medium block mb-2">Remove access</span>
            <p class="text-xs">
              {{ staff.name }} won't have any access to your studio.
            </p>
          </div>
        </div>
      </div>
      <template #buttons>
        <v-button
            v-if="selectedOption !== null && selectedOption !== staff.role"
            :disabled="saving"
            :loading="saving"
            class="modal-button modal-button-green"
            @click="saveRole">
          Change access
        </v-button>

        <v-button
            v-if="selectedOption === null"
            :disabled="saving"
            :loading="saving"
            class="modal-button modal-button-red"
            @click="saveRole">
          Remove access
        </v-button>
        <v-button
            :disabled="saving"
            :loading="saving"
            class="modal-button modal-button-white"
            @click="editingModal = false">
          Keep it as it is
        </v-button>
      </template>
    </modal>
  </div>
</template>
<style scoped>
.current-level-tag{
  @apply font-medium bg-gray-50 px-2 py-1 rounded text-gray-900 border border-gray-200 text-xs
}

.box-regular {
  @apply border-2 rounded-lg py-4 mb-4 px-4;
}

.box-current {
  @apply border-green-200 bg-green-50 text-green-900 cursor-default;
}

.box-selectable {
  @apply hover:border-blue-100 hover:bg-blue-50 hover:text-blue-800;
}

.box-selected {
  @apply border-blue-200 bg-blue-100 text-blue-800;
}

.box-danger {
  @apply text-red-800 hover:border-red-200 hover:bg-red-100 hover:text-red-800;

  &
  .box-selected {
    @apply border-red-200 bg-red-100 text-red-800;
  }

}

</style>
<script lang="ts" setup>

import Modal                                                                                             from "@/components/layout/Modal.vue";
import VButton                                                                                           from "@/components/layout/VButton.vue";
import {QLFragmentUserMeFragment, QLUserRoleEnum, QLUserWithRoleFragmentFragment, useRoleChangeMutation} from "@/graphql/queries/ql/composables";
import $toast                                                                                            from "@/composables/toast";
import {computed, ref, useAttrs, useSlots}                                                               from "vue";

interface Props {
  me: QLFragmentUserMeFragment;
  staff: QLUserWithRoleFragmentFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const saving = ref(false);
const selectedOption = ref(props.staff.role);
const editingModal = ref(false);

const canAssignAdmin = computed(() => {
  return props.me.policy.is_main_owner;
});

const userRolesEnum = QLUserRoleEnum;

const classesForRole = (role: QLUserRoleEnum | null) => {
  return {
    "box-current":    props.staff.role === role,
    "box-selectable": selectedOption.value !== role && props.staff.role !== role,
    "box-selected":   props.staff.role !== role && selectedOption.value === role
  };
};

const {mutate: sendMessage, onDone, onError} = useRoleChangeMutation({});
onDone(() => {
  $toast({
           type:         "success",
           message:      "Access level changed for " + props.staff.name,
           linkRouteObj: null,
           linkText:     null
         });
  editingModal.value = false;
});
onError((error) => {
  $toast({
           type:         "error",
           message:      "There was an error while changing the access level. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const saveRole = () => {
  saving.value = true;
  sendMessage({
                user_id: props.staff.id,
                role:    selectedOption.value
              }).finally(() => saving.value = false);
};

</script>
