<template>
  <div>
    <div
        class="field-width field-width-selector mr-1"
        @mouseenter="isHovering = true"
        @mouseleave="isHovering = false">
      <div class="field-width-label">
        {{ size }}%
      </div>
      <div
          v-for="width in widths"
          :key="width"
          :class="[
                'field-width-notch',
                'notch-' + width,
                { 'filled': selected >= width, 'selected': selected == width }
            ]"
          @mouseenter.stop="hoveringOver = width"
          @click="selectSize( width)" />
    </div>
  </div>
</template>
<style scoped>
.field-width-selector {
  display:flex;
  width:49px;
  height:20px;
  position:relative;
  cursor:pointer;

  .field-width-label {
    @apply absolute text-xs font-bold w-full text-center z-10 text-gray-400;
    top:3px;
    pointer-events:none;
  }

  &:hover .field-width-label {
    opacity:0.5;
  }

  .field-width-notch {
    @apply relative border border-gray-200 border-l-0;
    width:8px;

    &.notch-25 {
      @apply border-l rounded-l-sm;
    }

    &.filled {
      @apply border-gray-200 border-r-0 bg-white;
      margin:-1px 0;
    }

    &.selected {
      @apply border-r rounded-r-sm;
    }
  }

  &.large {
    transform:scale(1.3);
    transform-origin:0 0;
  }
}
</style>
<script lang="ts" setup>
interface Props {
  size: number;
}

const props = withDefaults(defineProps<Props>(), {});

import {computed, ref, toRef, useAttrs, useSlots} from "vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:size"]);

const size = toRef(props, "size");

const isHovering = ref(false);

const hoveringOver = ref(null);
const widths = [25, 33, 50, 66, 75, 100];

const selected = computed(() => {
  if (isHovering.value) {
    return hoveringOver.value;
  }
  return size.value;
});

const selectSize = (size) => {
  emit("update:size", size);
};

</script>
