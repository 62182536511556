<template>
  <div>
    <div
        v-if="loading"
        class="w-full h-40 flex items-center justify-center">
      <loader class="w-8 h-8 text-gray-300" />
    </div>
    <div
        v-if="!loading"
        class="divide-y -my-6">
      <div
          v-for="staff in currentTeam.users"
          :key="staff.id"
          class="py-4">
        <studio-staff-list-item
            :current-team="currentTeam"
            :me="me"
            :staff="staff" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Loader              from "@/components/layout/Loader.vue";
import StudioStaffListItem from "@/components/studio/settings/Staff/StudioStaffListItem.vue";
import meQuery             from "@/composables/queries/meQuery";
import currentTeamQuery    from "@/composables/queries/currentTeamQuery";
import {computed}          from "vue";


const {me, loading: loadingMe} = meQuery();
const {currentTeam, loading: loadingTeam} = currentTeamQuery();


const loading = computed(() => {
  return loadingMe.value || loadingTeam.value;
});

</script>
