import {QLFieldTypeEnum, QLFormField}                from "@/graphql/queries/ql/composables";
import {computed, onMounted, Ref, ref, toRef, watch} from "vue";
import {deepCopy}                                    from "@/classes/helpers/DeepCopy";
import TextBlockFieldInput                           from "@/components/Forms/Fields/Input/TextBlockFieldInput.vue";
import TextInputFieldInput                           from "@/components/Forms/Fields/Input/TextInputFieldInput.vue";
import YesNoFieldInput                               from "@/components/Forms/Fields/Input/YesNoFieldInput.vue";
import SelectFieldInput                              from "@/components/Forms/Fields/Input/SelectFieldInput.vue";
import MultipleFieldInput                            from "@/components/Forms/Fields/Input/MultipleFieldInput.vue";
import DateFieldInput                                from "@/components/Forms/Fields/Input/DateFieldInput.vue";
import EmailFieldInput                               from "@/components/Forms/Fields/Input/EmailFieldInput.vue";
import SignatureFieldInput                           from "@/components/Forms/Fields/Input/SignatureFieldInput.vue";
import RadioFieldInput                               from "@/components/Forms/Fields/Input/RadioFieldInput.vue";
import ImageFieldInput                               from "@/components/Forms/Fields/Input/ImageFieldInput.vue";
import PhoneFieldInput                               from "@/components/Forms/Fields/Input/PhoneFieldInput.vue";
import TextAreaInput                                 from "@/components/Forms/Fields/Input/TextAreaInput.vue";
import StateSelectFieldInput                                 from "@/components/Forms/Fields/Input/StateSelectFieldInput.vue";
import {PossibleIcons}                               from "@/assets/icons/iconNames";
import fieldsData                                    from "@/components/Forms/Fields/fields";

const isATextBlock = (arg: any) => {
	return arg.kind === QLFieldTypeEnum.TEXT_BLOCK;
};

const nameIsAlreadyUsed = (otherFields: QLFormField[], currentField: QLFormField): boolean => {
	return otherFields.filter((i: QLFormField) => {
		return i.id != currentField.id && i.title.toLocaleLowerCase()
		                                   .trim() == currentField.title.toLocaleLowerCase().trim();
	}).length > 0;
};

const isValid = (block: QLFormField): boolean => {
	if (isATextBlock(block)) {
		return block.content.trim().length > 0;
	}
	if (block.title.trim().length == 0) {
		return false;
	}

	if (block.kind === QLFieldTypeEnum.SELECT || block.kind === QLFieldTypeEnum.MULTIPLE) {
		if (block.options.length === 0) {
			return false;
		}
	}

	return true;
};

const bootstrapEditField = (emit: (event: ("deleted" | "update:field" | "saved" | "update:open"),
                                   ...args: any[]) => void, props) => {

	const otherFields = toRef(props, "otherFields");

	const open = toRef(props, "open");

	const fieldModel = ref(null);

	const toRun = () => {
		onMounted(() => {
			fieldModel.value = deepCopy(props.field);
		});

		watch(open, () => {
			if (open.value === true) {
				fieldModel.value = deepCopy(props.field);
			}
		});
	};

	const saved = () => {
		emit("update:field", fieldModel.value);
		emit("update:open", false);
		emit("saved", fieldModel.value);
	};

	const openModel = computed({
		                           get: () => props.open,
		                           set: (val) => emit("update:open", val)
	                           });

	const nameAlreadyUsed = computed(() => {
		if (!fieldModel.value) {
			return false;
		}
		return nameIsAlreadyUsed(otherFields.value, fieldModel.value);
	});

	return {
		nameAlreadyUsed,
		otherFields,
		openModel,
		fieldModel,
		toRun,
		saved
	};

};
const createComponentTypeFunction = (field: Ref<QLFormField>) => {
	const componentType = computed(() => {
		if (!field.value) {
			return TextInputFieldInput;
		}

		switch (field.value.kind) {
			case QLFieldTypeEnum.TEXT_BLOCK:
				return TextBlockFieldInput;
			case QLFieldTypeEnum.TEXT_INPUT:
				return TextInputFieldInput;
			case QLFieldTypeEnum.YES_NO:
				return YesNoFieldInput;
			case QLFieldTypeEnum.SELECT:
				return SelectFieldInput;
			case QLFieldTypeEnum.MULTIPLE:
				return MultipleFieldInput;
			case QLFieldTypeEnum.DATE:
				return DateFieldInput;
			case QLFieldTypeEnum.EMAIL:
				return EmailFieldInput;
			case QLFieldTypeEnum.PHONE:
				return PhoneFieldInput;
			case QLFieldTypeEnum.FIRST_NAME:
				return TextInputFieldInput;
			case QLFieldTypeEnum.LAST_NAME:
				return TextInputFieldInput;
			case QLFieldTypeEnum.SIGNATURE:
				return SignatureFieldInput;
			case QLFieldTypeEnum.RADIO:
				return RadioFieldInput;
			case QLFieldTypeEnum.IMAGE:
				return ImageFieldInput;
			case QLFieldTypeEnum.TEXT_AREA:
				return TextAreaInput;
			case QLFieldTypeEnum.STATE:
				return StateSelectFieldInput;
		}
		return TextInputFieldInput;
	});

	return {componentType};
};

const iconForField = (kind: QLFieldTypeEnum): PossibleIcons => {
	if (!kind) {
		return "field-input";
	}

	switch (kind) {
		case QLFieldTypeEnum.TEXT_BLOCK:
			return "field-text";
		case QLFieldTypeEnum.TEXT_INPUT:
			return "field-input";
		case QLFieldTypeEnum.YES_NO:
			return "field-yes-no";
		case QLFieldTypeEnum.SELECT:
		case QLFieldTypeEnum.STATE:
			return "field-select";
		case QLFieldTypeEnum.MULTIPLE:
			return "field-multiple";
		case QLFieldTypeEnum.DATE:
			return "field-date";
		case QLFieldTypeEnum.EMAIL:
			return "field-email";
		case QLFieldTypeEnum.PHONE:
			return "mobile-alt";
		case QLFieldTypeEnum.SIGNATURE:
			return "field-signature";
		case QLFieldTypeEnum.RADIO:
			return "field-radio";
		case QLFieldTypeEnum.TEXT_AREA:
			return "field-text-area";
		default:
			return "field-input";
	}
};

const availableMappings = (kind: Ref<QLFormField>, otherContent: QLFormField[]) => {
	return computed(() => {
		if (!kind.value) {
			return [];
		}

		return fieldsData[kind.value.kind].mboMapping.map((val) => {
			return {
				...val,
				used: otherContent.find((other) => {
					return (other.mapping == val.value);
				}) != undefined
			};
		});
	});
};

const mappingInformation = (map: string) => {
	for (const fieldsDataKey in fieldsData) {
		const found = fieldsData[fieldsDataKey].mboMapping.find((val) => val.value === map);
		if (found) {
			return found;
		}
	}
	return null;
};

export default {
	nameIsAlreadyUsed,
	isATextBlock,
	isValid,
	bootstrapEditField,
	createComponentTypeFunction,
	iconForField,
	availableMappings,
	mappingInformation
};
