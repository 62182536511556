<template>
  <div>
    <div>
      <h1 class="main-page-title">
        Sales of {{ client.first_name }}
      </h1>
    </div>
    <div class="grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-6 grid-flow-row-dense">
      <div class="flex items-center p-4 bg-white rounded-lg shadow-md">
        <div class="p-3 mr-4 text-loopspark-500 bg-loopspark-100 rounded-full dark:text-loopspark-100">
          <svg-icon
              name="money-duotone"
              class="w-5 h-5" />
        </div>
        <div>
          <p class="text-sm tracking-wide text-gray-600">
            Life Time Value
          </p>
          <p class="text-lg font-semibold text-gray-900">
            $ {{ client.ltv }}
          </p>
        </div>
      </div>

      <div class="lg:row-span-2 col-span-2 lg:col-span-3 p-4 bg-white rounded-lg shadow-md">
        <client-ltv-over-time :client="client" />
      </div>
    </div>

    <div class="mt-12 grid md:grid-cols-2 gap-6">
      <div>
        <h3 class="mb-3 text-lg font-medium">
          Sales
        </h3>
        <client-sales-list :client="client" />
      </div>
      <div>
        <h3 class="mb-3 text-lg font-medium">
          Products
        </h3>
        <client-products-list :client="client" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SvgIcon from "@/components/SvgIcon.vue";

import ClientSalesList    from "@/components/client/Sales/ClientSalesList.vue";
import ClientProductsList from "@/components/client/Sales/ClientProductsList.vue";
import ClientLtvOverTime  from "@/components/client/Sales/ClientLtvOverTime.vue";
import {toRef}            from "vue";
import {QLClient}         from "@/graphql/queries/ql/composables";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");
</script>
