<template>
  <page-title
      title="Create a new Form"
      section="Forms" />
  <form class="container mx-auto grow" @submit.prevent="saveForm">
    <div class="flex flex-col">
      <div class="mt-6 grow">
        <form-settings-edit
            :editable="true"
            v-model:form="form"
            v-model:errors="errors"
            :saving="saving" />
      </div>
    </div>
    <div class="my-8 flex justify-end">
      <v-button type="submit" :disabled="saving" :loading="saving" class="btn btn-green text-sm font-medium">Save</v-button>
    </div>
  </form>
</template>
<script lang="ts" setup>
import PageTitle                                  from "@/components/navbar/PageTitle.vue";
import {ref, toRaw}                               from "vue";
import FormSettingsEdit                           from "@/components/Forms/FormSettingsEdit.vue";
import {QLCreateFormInput, useCreateFormMutation} from "@/graphql/queries/ql/composables";
import {SparkErrors}                              from "@/classes/utils/SparkFormErrors";
import routesList                                 from "@/composables/routesList";
import $toast                                     from "@/composables/toast";
import {fillValidationErrorsAndToast}             from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import {useRouter}                                from "vue-router";
import VButton                                    from "@/components/layout/VButton.vue";

const router = useRouter();

const emptyForm: QLCreateFormInput = {
  is_legal:          false,
  allow_leads:       false,
  description:       "",
  name:              "",
  content:           [],
  thank_you_message: null,
  thank_you_url:     null,
  email_cc:          null,
  category:          null,
  title:             "",
  send_to_mbo:       false,
  mbo_upload_lead:   false,
  mbo_update_client: false
};

const form = ref(emptyForm);
const errors = ref<SparkErrors>({});
const saving = ref(false);

const mut = useCreateFormMutation({});

mut.onDone((d) => {
  saving.value = false;
  const finalRoute = {
    name:   routesList().routeList.forms.show.index,
    params: {id: d.data.CreateForm.id}
  };
  $toast({
           type:         "success",
           message:      "The form was created successfully",
           linkRouteObj: finalRoute,
           linkText:     "View your form"
         });

  router.push(finalRoute);
});

mut.onError((err) => {
  fillValidationErrorsAndToast(err, errors);
  saving.value = false;
});

const saveForm = () => {
  saving.value = true;

  const toPost = toRaw(form.value);

  mut.mutate({form: toPost});
};
</script>
