import Form                          from "./forms";
import GraphQlOperator                        from "hm-filters/operators/GraphQlOperator";
import QLQueriesData                          from "hm-filters/operators/GraphQls/QLQueriesData";
import {OperatorTypes, OperatorTypesFullName} from "hm-filters/operators/OperatorTypes";

class FormCategoryFilter extends Form {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new FormCategoryQlOperator("=", "at least once").setIsMultipleChoice(),
			new FormCategoryQlOperator("only", "only").setAloneInFilterGroup(),
			new FormCategoryQlOperator("!=", "never")
		];
	}

	get type(): string {
		return "FormCategoryFilter";
	}
}

export default FormCategoryFilter;

export class FormCategoryQlOperator extends GraphQlOperator {
	graphQLData = QLQueriesData.FormCategoryQlOperator;

	get type(): string {
		return OperatorTypes.GraphQlMultiple;
	}

	get fullType(): string {
		return OperatorTypesFullName.FormCategoryQlOperator;
	}
}
