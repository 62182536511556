<template>
  <div>
    <v-button
        v-if="me && me.policy.export_clients"
        :disabled="loading"
        :loading="loading"
        class="btn btn-transparent text-blue-800 px-4 py-1"
        @click="exportNow">
      <div class="inline-flex items-center">
        <svg-icon
            name="download"
            class="h-4 w-4" />
        <div class="pl-1">
          Export
        </div>
      </div>
    </v-button>
  </div>
</template>
<script lang="ts" setup>
import VButton                                             from "@/components/layout/VButton.vue";
import SvgIcon                                             from "@/components/SvgIcon.vue";
import {ref, useAttrs, useSlots}                           from "vue";
import {QLFragmentFormFullFragment, useExportFormMutation} from "@/graphql/queries/ql/composables";
import meQuery                                             from "@/composables/queries/meQuery";
import $toast                                              from "@/composables/toast";

interface Props {
  form: QLFragmentFormFullFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const loading = ref(false);

const {me, result, onError} = meQuery();
onError((e) => console.log(e));

const exportMutation = useExportFormMutation({});

exportMutation.onDone((d) => {
  $toast({
           linkRouteObj: {},
           linkText:     undefined,
           sticky:       false,
           type:         "success",
           message:      "The report is being created. You will receive a notification once it's ready to download"
         });
});
exportMutation.onError(() => {
  $toast({
           linkRouteObj: {},
           linkText:     undefined,
           sticky:       false,
           type:         "error",
           message:      "There was a problem with your request. Please try again"
         });
});

const exportNow = () => {
  loading.value = true;
  exportMutation.mutate({
                          id: props.form.id
                        })
                .finally(() => loading.value = false);
};

</script>
