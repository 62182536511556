<template>
  <page-title title="Clients" />
  <div class="container mx-auto grow">
    <div class="rounded shadow-xs mb-8 ">
      <div class="bg-white py-4 px-6 rounded-t border-b border-gray-100">
        <div
            v-if="!changeSegment"
            class="cursor-pointer text-lg semibold truncate "
            @click="changeSegment = true">
          <div class="inline-flex items-center">
            <span class="truncate mr-1">{{ segmentName }}</span>
            <svg-icon
                name="down"
                class="h-5 w-5" />
          </div>
        </div>
        <div
            v-if="changeSegment"
            class="">
          <smart-select
              v-model="selectedSegment"
              :items="segmentsWithCustom"
              class="w-full"
              empty-on-open
              focus-on-show
              label="name"
              @blur="changeSegment = false"
              @change="changeSegment = false">
            <template #default="{item}">
              {{ item.name }}
            </template>
          </smart-select>
        </div>
      </div>
      <div class="bg-gray-50 px-6 py-2 flex items-center justify-between rounded-b">
        <div class="text-sm inline-flex items-center">
          <div>
            <loader
                v-if="foundClients === null || loading"
                class="w-4 h-4" />
            <span
                v-if="foundClients!==null && !loading"
                class="font-medium">{{ foundClients }}</span>
          </div>
          <span class="px-1">clients matched out of</span>
          <div>
            <loader
                v-if="totalClients === null"
                class="w-4 h-4" />
            <span
                v-if="totalClients!==null"
                class="font-medium">{{ totalClients }}</span>
          </div>
        </div>
        <div>
          <div
              v-if="!isCustomSegment"
              class="space-x-2 flex">
            <clients-index-segment-settings
                :segment="selectedSegment"
                @updated="updateSegment">
              <div
                  class="btn btn-xs flex items-center text-gray-600 hover:text-blue-500"
                  role="button">
                <svg-icon
                    name="settings"
                    class="w-4 h-4" />
                <div class="pl-1">
                  Settings
                </div>
              </div>
            </clients-index-segment-settings>
            <clients-index-delete-segment
                :segment="selectedSegment"
                @deleted="deletedSegment">
              <div
                  class="btn btn-xs flex items-center text-red-500 hover:text-red-800"
                  role="button">
                <svg-icon
                    name="trash"
                    class="w-4 h-4" />
                <div class="pl-1">
                  Delete...
                </div>
              </div>
            </clients-index-delete-segment>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="flex justify-between items-center bg-white p-4 px-6 rounded shadow-xs mb-8">-->
<!--      <div>-->
<!--        <div-->
<!--            v-if="!changeSegment"-->
<!--            class="cursor-pointer text-lg semibold truncate h-8"-->
<!--            @click="changeSegment = true">-->
<!--          <div class="inline-flex items-center">-->
<!--            <span class="truncate mr-1">{{ segmentName }}</span>-->
<!--            <svg-icon-->
<!--                name="down"-->
<!--                class="h-5 w-5" />-->
<!--          </div>-->
<!--        </div>-->
<!--        <div-->
<!--            v-if="changeSegment"-->
<!--            class="h-8">-->
<!--          <smart-select-->
<!--              v-model="selectedSegment"-->
<!--              :items="segmentsWithCustom"-->
<!--              class="w-full"-->
<!--              empty-on-open-->
<!--              focus-on-show-->
<!--              label="name"-->
<!--              @blur="changeSegment = false"-->
<!--              @change="changeSegment = false">-->
<!--            <template #default="{item}">-->
<!--              {{ item.name }}-->
<!--            </template>-->
<!--          </smart-select>-->
<!--        </div>-->


<!--      </div>-->
<!--      <div-->
<!--          v-if="!isCustomSegment"-->
<!--          class="ml-6 space-y-2">-->
<!--        <clients-index-segment-settings-->
<!--            :segment="selectedSegment"-->
<!--            @updated="updateSegment">-->
<!--          <div-->
<!--              class="btn btn-xs flex items-center text-gray-600 hover:text-blue-500"-->
<!--              role="button">-->
<!--            <svg-icon-->
<!--                name="settings"-->
<!--                class="w-4 h-4" />-->
<!--            <div class="pl-1">-->
<!--              Settings-->
<!--            </div>-->
<!--          </div>-->
<!--        </clients-index-segment-settings>-->
<!--        <clients-index-delete-segment-->
<!--            :segment="selectedSegment"-->
<!--            @deleted="deletedSegment">-->
<!--          <div-->
<!--              class="btn btn-xs flex items-center text-red-600 hover:text-red-500"-->
<!--              role="button">-->
<!--            <svg-icon-->
<!--                name="trash"-->
<!--                class="w-4 h-4" />-->
<!--            <div class="pl-1">-->
<!--              Delete...-->
<!--            </div>-->
<!--          </div>-->
<!--        </clients-index-delete-segment>-->
<!--      </div>-->
<!--    </div>-->
    <div class="mt-6">
      <div class="md:flex">
        <div class="md:w-1/4 md:pr-4 space-y-2">
          <clients-index-segment class="mt-2" :segment="selectedSegment" />
          <clients-index-save-segment
              v-if="!hasError"
              :original-segment="originalSegment"
              :segment="selectedSegment"
              @saved="savedSegment" />
        </div>
        <div
            v-if="hasError"
            class="md:w-3/4">
          <div class="flex items-center justify-center">
            <div class="bg-red-100 px-8 py-12 rounded-lg border border-red-300 text-red-900">
              <strong>Error</strong>
              <p>The segment you selected has issues. Please check all the rules and make sure that they are valid.</p>
            </div>
          </div>
        </div>
        <div
            v-if="!hasError"
            class="md:w-3/4">
          <div class="flex justify-end">
            <clients-index-export-button :segment="selectedSegment" />
          </div>
          <div class="mt-2 shadow-lg border-b border-gray-200 sm:rounded-lg">
            <div class="table-container">
              <table class="table divide-y">
                <thead>
                <tr>
                  <table-th
                      :is-down="orderBy.column === 'last_name' && !isOrderUp"
                      :is-up="orderBy.column === 'last_name' && isOrderUp"
                      has-sort
                      @down="changeOrder('last_name', false)"
                      @up="changeOrder('last_name', true)">
                    Client
                  </table-th>
                  <table-th>Current<br>Package</table-th>
                  <table-th
                      :is-down="orderBy.column === 'cc_club_counts' && !isOrderUp"
                      :is-up="orderBy.column === 'cc_club_counts' && isOrderUp"
                      class="text-right"
                      has-sort
                      @down="changeOrder('cc_club_counts', false)"
                      @up="changeOrder('cc_club_counts', true)">
                    Club<br>Count
                  </table-th>
                  <table-th
                      v-if="periodCountMatters"
                      :is-down="orderBy.column === 'cc_period_counts' && !isOrderUp"
                      :is-up="orderBy.column === 'cc_period_counts' && isOrderUp"
                      class="text-right"
                      has-sort
                      @down="changeOrder('cc_period_counts', false)"
                      @up="changeOrder('cc_period_counts', true)">
                    Period<br>Count
                  </table-th>
                  <table-th
                      :is-down="orderBy.column === 'last_attendance_at' && !isOrderUp"
                      :is-up="orderBy.column === 'last_attendance_at' && isOrderUp"
                      class="text-right"
                      has-sort
                      @down="changeOrder('last_attendance_at', false)"
                      @up="changeOrder('last_attendance_at', true)">
                    Last<br>Attended
                  </table-th>
                </tr>
                </thead>
                <tbody
                    v-if="!loading && clients && clients.length > 0"
                    class="bg-white divide-y divide-gray-200">
                <clients-index-table-row
                    v-for="client in clients"
                    :key="client.id"
                    :client="client"
                    :period-count="periodCountMatters" />
                </tbody>
                <tbody
                    v-if="!loading && clients && clients.length === 0"
                    class="bg-white divide-y divide-gray-200">
                <tr>
                  <td :colspan="periodCountMatters ? 5 : 4">
                    <div class="p-12 text-center">
                      <p class="text-gray-500">
                        There are no clients matching the selected segment.
                      </p>
                    </div>
                  </td>
                </tr>
                </tbody>

                <tbody
                    v-if="loading"
                    class="bg-white divide-y divide-gray-200">
                <clients-index-table-row-loading
                    v-for="i in 10"
                    :key="i"
                    :period-count="periodCountMatters" />
                </tbody>

                <tfoot class="bg-gray-50" v-if="clients && paginatorInfo.total > 0&& paginatorInfo.lastPage > 1">
                <tr v-if="clients && paginatorInfo.total > 0&& paginatorInfo.lastPage > 1">
                  <td :colspan="periodCountMatters ? 5 : 4">
                    <div class="w-full py-2 text-right px-4 rounded-b">
                      <pagination
                          v-if="paginatorInfo.lastPage > 1"
                          :pagination="paginatorInfo"
                          class="flex items-center justify-between text-gray-700 text-sm"
                          @setPage="paginationData.page = $event">
                        <div
                            v-if="loading"
                            class="inline-block w-8 h-8 mr-2 text-gray-400">
                          <loader />
                        </div>
                      </pagination>
                    </div>
                  </td>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SvgIcon                                             from "@/components/SvgIcon.vue";
import SmartSelect                                         from "@/components/layout/Forms/SmartSelect.vue";
import TableTh                                             from "@/components/layout/Tables/TableTh.vue";
import ClientsIndexTableRow                                from "@/components/Clients/Listing/ClientsIndexTableRow.vue";
import {Segment}                                           from "@/classes/entities/Segment";
import ClientsIndexSegment                                 from "@/components/Clients/Listing/ClientsIndexSegment.vue";
import ClientsIndexSaveSegment
                                                           from "@/components/Clients/Listing/ClientsIndexSaveSegment.vue";
import {deepCopy}                                          from "@/classes/helpers/DeepCopy";
import Loader                                              from "@/components/layout/Loader.vue";
import ClientsIndexTableRowLoading
                                                           from "@/components/Clients/Listing/ClientsIndexTableRowLoading.vue";
import Pagination                                          from "@/components/layout/Pagination.vue";
import ClientsIndexDeleteSegment
                                                           from "@/components/Clients/Listing/ClientsIndexDeleteSegment.vue";
import ClientsIndexSegmentSettings
                                                           from "@/components/Clients/Listing/ClientsIndexSegmentSettings.vue";
import ClientsIndexExportButton
                                                           from "@/components/Clients/Listing/ClientsIndexExportButton.vue";
import {computed, reactive, ref, watch}                    from "vue";
import {createPaginationData, paginationWithAlreadyLoaded} from "@/composables/paginationWithAlreadyLoaded";
import {
  QLClientsListIndexQuery,
  QLClientsListIndexQueryVariables, QLClientVisitsQuery, QLOrderByClause, QLPeriodCountType, QLSortOrder,
  useClientsListIndexQuery, useSegmentsQuery, useStatusQuery
}                                                          from "@/graphql/queries/ql/composables";
import currentTeamQuery                                    from "@/composables/queries/currentTeamQuery";
import {useResult}                                         from "@vue/apollo-composable";
import SegmentDecorator                                    from "@/composables/decorators/SegmentDecorator";
import PageTitle                                           from "@/components/navbar/PageTitle.vue";

const selectedSegmentInt = ref(Segment.createEmptyCustomSegment(true));
const originalSegment = ref(deepCopy(selectedSegmentInt.value));

const changeSegment = ref(false);

const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 10
                                              });

const hasError = ref(false);
const orderBy = reactive<QLOrderByClause>({
                                            column: "last_name",
                                            order:  QLSortOrder.ASC
                                          });

const {currentTeam, loading: currentTeamLoading} = currentTeamQuery();
const statusQ = useStatusQuery({fetchPolicy: "cache-first"});
const totalClients = useResult(statusQ.result, null, data => data.status.total_clients);

const segmentsQ = useSegmentsQuery({is_communication: false, archived: false}, {fetchPolicy: "cache-and-network"});
const segments = useResult(segmentsQ.result, [], data => data.segments);

const clientsVariables = computed<QLClientsListIndexQueryVariables>(() => {
  return {
    segment: SegmentDecorator(selectedSegmentInt).computed.toPost,
    page:    paginationData.page,
    first:   paginationData.perPage,
    orderBy: [
      orderBy
    ]
  };
});

const clientsQ = useClientsListIndexQuery(clientsVariables);
clientsQ.onError(() => {
  hasError.value = true;
});
clientsQ.onResult((result) => {
  if (result.loading) {
    hasError.value = false;
  }
});

const {
  paginatorInfo,
  loadedFirstTime,
  results: clients,
  loading
} = paginationWithAlreadyLoaded<QLClientsListIndexQuery["clients_list_index"]["data"]>(clientsQ);

const foundClients = computed(() => {
  return paginatorInfo.value.total;
});

const selectedSegment = computed({
                                   get: () => selectedSegmentInt.value,
                                   set: (val) => {
                                     if (val === null) {
                                       selectedSegmentInt.value = Segment.createEmptyCustomSegment(true);
                                     } else {
                                       selectedSegmentInt.value = deepCopy(val);
                                     }
                                     originalSegment.value = deepCopy(selectedSegmentInt.value);

                                   }
                                 });

const segmentsWithCustom = computed(() => {
  return [
    Segment.createEmptyCustomSegment(true),
    ...segments.value.map((i) => i).sort((a, b) => a.order > b.order ? 1 : -1)
  ];
});

const isCustomSegment = computed(() => parseInt(selectedSegmentInt.value.id) === 0);
const isOrderUp = computed(() => orderBy.order === QLSortOrder.DESC);

const segmentName = computed(() => {
  return selectedSegment.value === null ? "Custom Segment" : selectedSegment.value.name;
});

const periodCountMatters = computed(() => {
  return currentTeam.value != null && currentTeam.value.period_count_type != QLPeriodCountType.ALL_TIME;
});

const updateSegment = (updated) => {
  selectedSegment.value = updated;
  segmentsQ.refetch();
};

const deletedSegment = () => {
  selectedSegment.value = Segment.createEmptyCustomSegment(true);
  segmentsQ.refetch();
};

const savedSegment = (segment) => {
  selectedSegment.value = deepCopy(segment);
  segmentsQ.refetch();
};
const changeOrder = (field, isUp: boolean) => {
  // this might make two queries, test it
  orderBy.order = isUp ? QLSortOrder.DESC : QLSortOrder.ASC;
  orderBy.column = field;
};
</script>
