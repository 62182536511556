<template>
  <div class="grow container mx-auto">
    <div
        v-if="!automation"
        class="h-64 w-full">
      <div class="flex items-center justify-center h-full">
        <loader class="w-12 h-12 text-gray-500" />
      </div>
    </div>

    <div
        v-if="automation"
        class="">
      <div class="">
        <div
            v-if="automation"
            class="flex flex-wrap">
          <div class="w-full md:w-1/4 mt-6">
            <automation-top-info-box :automation="automation" />

            <div class="mt-4">
              <ul class="side-menu">
                <li>
                  <router-link
                      v-slot="{ href,  navigate, isExactActive }"
                      custom
                      :to="{name: routeList.automations.show.index, params: {id: automation.id}}">
                    <a :href="href" @click="navigate" :class="[ isExactActive? 'active':'']">
                      <svg-icon name="dashboard" class="w-4 h-4 inline-block mr-4" />
                      <span>Info</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link
                      :to="{name: routeList.automations.show.history, params: {id: automation.id}}"
                      custom
                      v-slot="{ href,  navigate,  isExactActive }">
                    <a :href="href" @click="navigate" :class="[ isExactActive? 'active':'']">
                      <svg-icon name="list" class="w-4 h-4 inline-block mr-4" />
                      <span>History</span>
                    </a>
                  </router-link>
                </li>
                <li
                    v-if="canEditAutomations || teamsCopiable.length > 0"
                    class="py-2 px-4">
                  <hr>
                </li>
                <li v-if="canEditAutomations">
                  <router-link :to="{name: routeList.automations.edit, params: {id: automation.id}}">
                    <svg-icon name="edit" class="w-4 h-4 inline-block mr-2" />
                    <span>Edit</span>
                  </router-link>
                </li>
                <li v-if="canEditAutomations">
                  <button
                      class="side-menu-item text-gray-800 hover:bg-gray-50 font-semibold"
                      @click="cloneDialogOpen = true">
                    <svg-icon name="segment-duotone" class="w-4 h-4 inline-block mr-2" />
                    <span>Clone...</span>
                  </button>
                </li>
                <li v-if="teamsCopiable.length > 0">
                  <button
                      class="side-menu-item text-gray-800 hover:bg-gray-50 font-semibold"
                      @click="copyDialogOpen = true">
                    <svg-icon name="copy" class="w-4 h-4 inline-block mr-2" />
                    <span>Copy...</span>
                  </button>
                </li>
                <li v-if="canEditAutomations">
                  <button
                      v-if="!automation.archived"
                      class="side-menu-item text-red-800 hover:bg-red-50"
                      @click="openArchive">
                    <svg-icon name="trash" class="w-4 h-4 inline-block mr-2" />
                    <span class="font-medium">Archive...</span>
                  </button>
                  <button
                      v-if="automation.archived"
                      class="side-menu-item text-green-800 hover:bg-green-50"
                      @click="openUnarchive">
                    <svg-icon name="unarchive" class="w-4 h-4 inline-block mr-2" />
                    <span class="font-medium">Unarchive...</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div class="w-full md:w-3/4 mt-6">
            <div class="ml-2 md:ml-6">
              <router-view :automation="automation" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <automation-archive-dialog
        v-if="automation"
        v-model:open="archiveDialogOpen"
        :automation="automation" />
    <automation-unarchive-dialog
        v-if="automation"
        v-model:open="unArchiveDialogOpen"
        :automation="automation" />

    <automation-copy-dialog v-if="automation" :automation="automation" :teams="teamsCopiable" v-model:open="copyDialogOpen"/>
    <automation-clone-dialog v-if="automation" :automation="automation" v-model:open="cloneDialogOpen"/>

  </div>
</template>
<style scoped>
.list-link {

}
</style>
<script lang="ts" setup>

import AutomationTopInfoBox                       from "@/components/automations/Show/AutomationTopInfoBox.vue";
import Loader                                     from "@/components/layout/Loader.vue";
import AutomationArchiveDialog                    from "@/components/automations/Show/AutomationArchiveDialog.vue";
import AutomationUnarchiveDialog                  from "@/components/automations/Show/AutomationUnarchiveDialog.vue";
import AutomationCopyDialog                  from "@/components/automations/Show/AutomationCopyDialog.vue";
import {computed, ref, toRef, useAttrs, useSlots} from "vue";
import meQuery                                    from "@/composables/queries/meQuery";
import {QLUserRoleEnum, useAutomationQuery}       from "@/graphql/queries/ql/composables";
import {useResult}                                from "@vue/apollo-composable";
import SvgIcon                                    from "@/components/SvgIcon.vue";
import routesList                                 from "@/composables/routesList";
import {echoListener}                             from "@/composables/echoListener";
import {EventTypes}                               from "@/classes/notifications/EventTypes";
import AutomationCloneDialog                      from "@/components/automations/Show/AutomationCloneDialog.vue";

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const archiveDialogOpen = ref(false);
const unArchiveDialogOpen = ref(false);
const copyDialogOpen = ref(false);
const cloneDialogOpen = ref(false);

const {routeList} = routesList();

const {me} = meQuery();
const canEditAutomations = computed(() => {
  if (!me.value) {
    return false;
  }
  return me.value.policy.edit_automations;
});

const openArchive = () => {
  archiveDialogOpen.value = true;
};

const openUnarchive = () => {
  unArchiveDialogOpen.value = true;
};

const automationId = toRef(props, "id");
const automationQueryVariables = computed(() => ({
  id: automationId.value
}));
const automationQuery = useAutomationQuery(automationQueryVariables);

echoListener([EventTypes.CommunicationUpdated], (ev) => {
  if (ev.data.communication_id == automationId.value) {
    automationQuery.refetch();
  }
}, 5000);

const automation = useResult(automationQuery.result);
const {loading} = automationQuery;

const teamsCopiable = computed(() => {
  if(!me.value) return [];

  return me.value.teams.filter(( t ) => {
    return t.can_create_automations && (t.role !== QLUserRoleEnum.MEMBER) && t.id != me.value.current_team_id
  })
});

// if (automation.email_content && automation.email_content.json) {
//   if (typeof automation.email_content.json !== "object") {
//     automation.email_content.json = JSON.parse(automation.email_content.json);
//   }
// }
//
// return automation;

</script>
