import {QLAutomationSegmentInput, QLSegment, QLSegmentInput} from "@/graphql/queries/ql/composables";
import {v4 as uuidv4}                                        from "uuid";

export class SegmentDecorator {
	private entity: QLSegment;

	constructor(entity: QLSegment) {
		this.entity = entity;
	}

	get isCustom() {
		return parseInt(this.entity.id) === 0 && this.entity.filters.length > 0 && this.entity.filters[0].filters.length > 0;
	}

	get isStored() {
		return parseInt(this.entity.id) > 0;
	}

	get isAll() {
		return !this.isStored && this.isCustom == false;
	}

	get canBeSaved() {
		return this.isValid;
	}


	get isValid() {
		if (!this.entity.filters || this.entity.filters.length == 0) {
			return false;
		}

		return this.entity.filters.filter(item => item.filters.length === 0).length === 0;
	}

	get toPost() : Partial<QLSegmentInput>{
		const ret = {
			name:                      this.entity.name?.toString() === "" ? "Custom" : this.entity.name,
			id:                        this.entity.id,
			only_deliverable_by_email: !!this.entity.only_deliverable_by_email,
			only_deliverable_by_sms:   !!this.entity.only_deliverable_by_sms,
			visible_dashboard:         !!this.entity.visible_dashboard,
			filter_groups:             this.entity.filters.map((filterGroup) => {
				return {
					operator_is_and: filterGroup.operator_is_and,
					uid:             filterGroup.uid ? filterGroup.uid : uuidv4(),
					filters:         filterGroup.filters.map((fil) => {
						return {
							uid:     fil.uid ? fil.uid : uuidv4(),
							type:    fil.type,
							options: (fil.options !== null && typeof fil.options === "object")
							         ? JSON.stringify(fil.options)
							         : fil.options
						};
					})
				};
			}),
			order:                     this.entity.order,
			archived:                  this.entity.archived
		};

		return Object.keys(ret).reduce((acc, key) => {
			const _acc = acc;
			if (ret[key] !== undefined) {
				_acc[key] = ret[key];
			}
			return _acc;
		}, {});
	}

	get asAutomationInputPost(): QLAutomationSegmentInput {
		return {
			filter_groups: this.entity.filters.map((filterGroup) => {
				return {
					operator_is_and: filterGroup.operator_is_and,
					uid:             filterGroup.uid ? filterGroup.uid : uuidv4(),
					filters:         filterGroup.filters.map((fil) => {
						return {
							uid:     fil.uid ? fil.uid : uuidv4(),
							type:    fil.type,
							options: (fil.options !== null && typeof fil.options === "object")
							         ? JSON.stringify(fil.options)
							         : fil.options
						};
					})
				};
			}),
			id:            this.entity.id,
			name:          this.entity.name
		};
	}

	refreshIds(): QLSegment {

		this.entity.filters.map((filterGroup) => {
			filterGroup.uid = uuidv4();
			filterGroup.filters.map((filter) => {
				filter.uid = uuidv4();
			});
		});

		return this.entity;
	}

}
