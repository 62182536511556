import {RouteRecordRaw} from "vue-router";
import FormsIndex       from "@/views/Forms/FormsIndex.vue";
import FormsCreate      from "@/views/Forms/FormsCreate.vue";
import FormsEdit        from "@/views/Forms/FormsEdit.vue";
import FormsShow        from "@/views/Forms/FormsShow.vue";
import FormShowOverview from "@/views/Forms/Components/Show/FormShowOverview.vue";
import FormShowHistory  from "@/views/Forms/Components/Show/FormShowHistory.vue";
import Routes           from "@/router/Routes";
import FormCatalogIndex from "@/views/Forms/FormCatalogIndex.vue";
import FormCatalogShow  from "@/views/Forms/FormCatalogShow.vue";

export const FormsRoutes: Array<RouteRecordRaw> = [
	{
		path:      "/forms",
		component: FormsIndex,
		name:      Routes.forms.index,
		meta:      {
			title:      "Forms",
			addonForms: true
		}
	},
	{
		path:      "/forms/create",
		component: FormsCreate,
		name:      Routes.forms.create,
		meta:      {
			title:          "Create a Form",
			requiresAuth:   true,
			canCreateForms: true,
			addonForms:     true
		}
	},
	{
		path:      "/forms/catalog",
		component: FormCatalogIndex,
		name:      Routes.forms.catalog.index,
		meta:      {
			requiresAuth:   true,
			canCreateForms: true,
			addonForms:     true,
			title:          "Forms Catalog"
		}
	},
	{
		path:      "/forms/catalog/:id",
		component: FormCatalogShow,
		name:      Routes.forms.catalog.show,
		props:     true,
		meta:      {
			requiresAuth:   true,
			canCreateForms: true,
			addonForms:     true,
			title:          "Forms Catalog"
		}
	},

	{
		path:      "/forms/:id",
		component: FormsShow,
		props:     true,
		meta:      {
			title:      "Form",
			addonForms: true
		},
		children:  [
			{
				path:      "",
				name:      Routes.forms.show.index,
				component: FormShowOverview,
				meta:      {
					title: "Form Information"
				}
			},
			{
				path:      "history",
				name:      Routes.forms.show.history,
				component: FormShowHistory,
				meta:      {
					title: "Form History"
				}
			}
		]
	},
	{
		path:      "/forms/:id/edit",
		component: FormsEdit,
		name:      Routes.forms.edit,
		props:     true,
		meta:      {
			title:        "Edit Form",
			requiresAuth: true,
			canEditForms: true,
			addonForms: true
		}
	}
];
