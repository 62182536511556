<template>
  <div>
    <v-button
        :disabled="saving"
        :loading="saving"
        class="btn btn-sm border border-red-400 text-red-800 bg-red-100 hover:bg-red-500 hover:text-red-50"
        @click="confirmOpen = true">
      Cancel
      Subscription
    </v-button>
  </div>
  <confirm-modal
      v-model:open="confirmOpen"
      type="warning"
      title="Are you sure?"
      close-text="Close"
      :loading="saving" @clicked="cancelSub">
    <template #button>
      Cancel my subscription
    </template>
    <template #text>
      Cancelling your LoopSpark account will end your subscription at the end of your current billing period. Your
      account will remain active for the duration of the current month you've paid for.
    </template>
  </confirm-modal>
</template>
<script lang="ts" setup>

import {ref}                                                   from "vue";
import {MySubscriptionDocument, useCancelSubscriptionMutation} from "@/graphql/queries/ql/composables";
import VButton                                                 from "@/components/layout/VButton.vue";
import ConfirmModal                                            from "@/components/layout/ConfirmModal.vue";
import $toast                                                  from "@/composables/toast";

const emit = defineEmits(['updated']);

const saving = ref(false);

const confirmOpen = ref(false);


const {mutate, onError, onDone} = useCancelSubscriptionMutation(() => ({
  update: (cache, {data: {cancelSubscription}}) => {
    cache.writeQuery({query: MySubscriptionDocument, data: {my_subscription: cancelSubscription}});
  }
}));
onError((err) => {
  $toast({
           type:         "error",
           message:      "There was an error while cancelling your subscription. Please try again or contact support",
           linkRouteObj: null,
           linkText:     null
         });
});
onDone(() => {
  $toast({
           type:    "success",
           message: "Subscription cancelled"
         });

  emit('updated');
});

const cancelSub = () => {
  saving.value = true;
  mutate().finally(() => saving.value = false);
};

</script>
