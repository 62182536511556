import ClientGroup       from "./partials/client";
import OperatorGenerator from "../operatorGenerator";

class ClientValues extends ClientGroup {
	constructor(name: string, options: any) {
		super(name, options);

		this.clearOperators();
		this.addOperators(OperatorGenerator.textOperators());
		this.addOperators(OperatorGenerator.textContainsMultiple());
	}

	get type(): string {
		return "ClientValues";
	}
}


export default ClientValues;
