<template>
  <div>
    <div>
      <h1 class="main-page-title">
        To-Dos for {{ client.first_name }}
      </h1>
    </div>
    <div class="text-gray-700 mr-2 mb-4">
      Status:
      <inline-muted-select
          v-model="status"
          :options="statusOptions" />
    </div>
    <div
        v-if="!loadedFirstTime"
        class="space-y-6 animate-loading">
      <div
          v-for="i in 5"
          :key="i"
          class="bg-white shadow rounded-lg px-4 py-4">
        <div class="flex">
          <div class="grow">
            <div>
              <div class="text-lg text-loopspark-700 group-hover:text-loopspark-500">
                <loading-skeleton
                    :max="50"
                    :min="30"
                    class-type="inline"
                    random-text />
              </div>
              <div class="text-sm text-gray-600">
                <loading-skeleton
                    class-type="inline"
                    random-text />
              </div>
            </div>
          </div>
          <div class="flex flex-col items-end space-y-1">
            <div class="loading-bg block rounded-full h-8 w-12" />
          </div>
        </div>
        <div class="md:flex justify-between mt-2">
          <div class="text-sm text-gray-700 ">
            <div class="inline-flex items-center">
              <loading-skeleton
                  class="pr-2"
                  class-type="inline"
                  text="Assigned to:" />
              <div class="loading-bg block rounded-full h-5 w-24" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loadedFirstTime">
      <div v-if="filteredTodos.length > 0">
        <div class="space-y-6">
          <client-single-todo-in-list
              v-for="todo in filteredTodos"
              :key="todo.id"
              :todo="todo" />
        </div>
      </div>
      <div v-if="filteredTodos.length === 0">
        <empty>
          <template #title>
            Nothing to show!
          </template>
          <template #subtitle>
            <p
                v-if="status === 'Open'"
                class="text-gray-600">
              {{ client.first_name }} does not have any open To-Dos!
            </p>
            <p
                v-if="status !== 'Open'"
                class="text-gray-600">
              {{ client.first_name }} does not have any To-Dos!
            </p>
          </template>
        </empty>
      </div>

      <div
          v-if="todos && paginatorInfo.total > 0&& paginatorInfo.lastPage > 1"
          class="w-full py-6 text-right ">
        <pagination
            v-if="paginatorInfo.lastPage > 1"
            :pagination="paginatorInfo"
            class="flex items-center justify-between text-gray-700 text-sm"
            @setPage="paginationData.page = $event">
          <div
              v-if="loading"
              class="inline-block w-8 h-8 mr-2 text-gray-400">
            <loader />
          </div>
        </pagination>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import InlineMutedSelect                                                from "@/components/layout/InlineMutedSelect.vue";
import Loader                                                           from "@/components/layout/Loader.vue";
import Pagination                                                       from "@/components/layout/Pagination.vue";
import ClientSingleTodoInList                                           from "@/components/client/Todos/ClientSingleTodoInList.vue";
import LoadingSkeleton                                                  from "@/components/layout/LoadingSkeleton.vue";
import {QLClient, QLClientTodosQuery, QLSortOrder, useClientTodosQuery} from "@/graphql/queries/ql/composables";
import {computed, ref, toRef, watch}                                    from "vue";
import {createPaginationData, paginationWithAlreadyLoaded}              from "@/composables/paginationWithAlreadyLoaded";
import Empty                                                            from "@/components/layout/Empty.vue";
import {echoListener}                                                   from "@/composables/echoListener";
import {EventTypes}                                                     from "@/classes/notifications/EventTypes";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");

const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 10
                                              });

const status = ref("Open");

const statusOptions = [
  {label: "Open", value: "Open"},
  {label: "All", value: "All"}
];

const todosVariables = computed(() => {
  return {
    client_id: client.value.id.toString(),
    open:      status.value === "Open",
    page:      paginationData.page,
    first:     paginationData.perPage,
    orderBy:   [
      {
        column: "created_at",
        order:  QLSortOrder.DESC
      }
    ]
  };
});

const todosQ = useClientTodosQuery(todosVariables);

echoListener([EventTypes.TodoUpdated, EventTypes.TodoNew], (ev) => {
  reloadTodoList();
}, 5000);

const reloadTodoList = () => {
  if (paginationData.page > 1) {
    paginationData.page = 1;
  } else {
    todosQ.refetch();
  }
};

const filteredTodos = computed(() => {
  if (status.value === "All") {
    return todos.value;
  }

  return todos.value.filter(todo => !todo.is_closed);
});

const {
  paginatorInfo,
  loadedFirstTime,
  results: todos,
  loading
} = paginationWithAlreadyLoaded<QLClientTodosQuery["todos"]["data"]>(todosQ);

watch(status, () => {
  paginationData.page = 1;
});
</script>
