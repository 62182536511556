<template>
  <div class="pt-2">
    <div class="shadow-xs rounded text-sm overflow-hidden">
      <div class="bg-gray-50 border-b">
        <div class="px-2 py-1 flex justify-between items-center">
          <div class="text-xs uppercase text-gray-600 ">
            Rule
          </div>
          <div v-if="editable">
            <button @click="openEditModal">
              <svg-icon
                  name="edit"
                  class="w-4 h-4 text-gray-500 hover:text-blue-500"
                  role="button" />
            </button>
          </div>
        </div>
      </div>
      <div class="bg-white px-2 py-2">
        <div v-if="filtersAsObjects.length > 0">
          <div
              v-for="(filter, index) in filtersAsObjects"
              class="small-recap">
            <div class="py-4">
              <filter-and-operator-in-list
                  :filter-and-operator="filter"
                  :catalog-variables="catalogVariables"
                  @replaceWithCatalog="replaceWithCatalog($event, index, filter)" />
            </div>

            <div
                v-if="filtersAsObjects.length > 0 && index != filtersAsObjects.length -1"
                class="flex items-center py-2">
              <hr class="grow">
              <div class="relative inline-block">
                <div
                    class="mx-2 text-gray-500 text-xs inline-flex items-center cursor-pointer hover:text-blue-500"
                    role="button"
                    @click="andOrSelectorOpen = !andOrSelectorOpen">
                  <span v-text="filterGroup.operator_is_and ? 'AND': 'OR'" />
                </div>
              </div>

              <hr class="grow">
            </div>
          </div>
        </div>
        <div
            v-if="!filtersAsObjects.length"
            class="text-gray-500 italic py-2">
          This rule is empty. It will match any client until you
          <button
              class="inline text-blue-700 hover:text-blue-500"
              @click="openEditModal">
            add a new filter
          </button>
        </div>
      </div>
    </div>
    <filter-group-edit
        v-model:open="filterModalOpen"
        v-model="filterGroup"
        @deleted="deleteFilterGroup" />
  </div>
</template>

<script lang="ts" setup>

import FilterAndOperatorInList                                from "@/components/Segment/Edit/Partials/FilterAndOperatorInList.vue";
import {filterAndSelectedOperatorGetter}                      from "hm-filters/filterGetter";
import FilterGroupEdit                                        from "@/components/Segment/FilterGroupEdit.vue";
import SvgIcon                                                from "@/components/SvgIcon.vue";
import {computed, ref, toRef}                                 from "vue";
import {QLCatalogItemVariableFragmentFragment, QLFilterGroup} from "@/graphql/queries/ql/composables";

interface Props {
  editable?: boolean;
  catalogVariables?: QLCatalogItemVariableFragmentFragment[];
  modelValue: QLFilterGroup;
}

const props = withDefaults(defineProps<Props>(), {
  editable:         true,
  catalogVariables: () => []
});

const filtermodal = ref(null);
const filterModalOpen = ref(false);
const andOrSelectorOpen = ref(false);
const emit = defineEmits(["deleted", "update:modelValue"]);

const catalogVariables = toRef(props, "catalogVariables");
const modelValue = toRef(props, "modelValue");


const filterGroup = computed({
                               get: () => modelValue.value,
                               set: (val) => emit("update:modelValue", val)
                             });

const filtersAsObjects = computed(() => {
  return filterGroup.value.filters.map((filterInfo) => {
    return filterAndSelectedOperatorGetter(filterInfo);
  });
});

const chooseAndOrSelector = (isAnd: boolean) => {
  filterGroup.value.operator_is_and = isAnd;
  andOrSelectorOpen.value = false;
};

const openEditModal = () => {
  filterModalOpen.value = true;
};

const deleteFilterGroup = () => {
  filterModalOpen.value = false;
  emit("deleted", filterGroup.value);
};

const replaceWithCatalog = (event, index, filter) => {
  filterGroup.value.filters = filterGroup.value.filters.map((f) => {
    if (f.uid === filter.original.uid) {
      f.options.value = "{{" + event + "}}";
    }
    return f;
  });
};
</script>
