<template>
  <div>
    <div class="flex items-baseline justify-between">
      <div class="text-xl font-semibold">
        History
      </div>
      <div>
        <automation-export-button :automation="automation" />
      </div>
    </div>

    <automation-show-history-email
        v-if="automation.delivery_type === 'EMAIL'"
        :automation="automation" />
    <automation-show-history-sms
        v-if="automation.delivery_type === 'SMS'"
        :automation="automation" />

    <automation-show-history-todo
        v-if="automation.delivery_type === 'TODO'"
        :automation="automation" />

    <automation-show-history-tag
        v-if="automation.delivery_type === 'TAG'"
        :automation="automation" />
  </div>
</template>
<script lang="ts" setup>

import AutomationShowHistoryEmail from "@/components/automations/Show/Infos/Email/AutomationShowHistoryEmail.vue";
import AutomationExportButton     from "@/components/automations/Show/partials/AutomationExportButton.vue";
import AutomationShowHistorySms   from "@/components/automations/Show/Infos/Sms/AutomationShowHistorySms.vue";
import AutomationShowHistoryTodo  from "@/components/automations/Show/Infos/Todos/AutomationShowHistoryTodo.vue";
import AutomationShowHistoryTag   from "@/components/automations/Show/Infos/Tags/AutomationShowHistoryTag.vue";

import {toRef, useAttrs, useSlots}        from "vue";
import {QLFragmentFullAutomationFragment} from "@/graphql/queries/ql/composables";

interface Props {
  automation: QLFragmentFullAutomationFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
const automation = toRef(props, "automation");

</script>
