import Filter from "./base-filter";

export default class FiltersByVisibility {

	static filtersFor(filters: Filter[], providerId: number): Filter[] {
		return filters.filter((filter: Filter) => {
			if (Array.isArray(filter.visible_on)) {
				if (filter.visible_on.indexOf(providerId) > -1) {
					return true;
				} else {
					return false;
				}
			}
			if (filter.hidden_on.indexOf(providerId) > -1) {
				return false;
			}

			return true;
		});
	}
}
