import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import NumericOperator                from "hm-filters/operators/NumericOperator";
import Package                        from "hm-filters/filters/partials/package";

export class PackageCountFilter extends Package {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new OperatorAsFixedValueOperator("never", "never before"),
			new NumericOperator(">", "more than", "packages"),
			new NumericOperator("<", "less than", "packages"),
			new NumericOperator("=", "exactly", "packages")
		];
	}

	get type(): string {
		return "PackageCountFilter";
	}
}
