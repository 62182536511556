<template>
  <div class="relative">
    <h3 class="font-semibold text-gray-600 mb-2">
      Event hourly breakdown
    </h3>
    <div
        v-if="loading"
        class="text-gray-500 flex items-center justify-center h-56"
        style="min-height: 6rem">
      <div class="h-8 w-8 ">
        <loader />
      </div>
    </div>
    <div
        v-else
        class="h-full relative">
      <hourly-chart
          :items="clientClassTimes"
          class="h-56"
          item-name="start_minutes"
          item-value="cc" />
    </div>
  </div>
</template>
<script lang="ts" setup>

import Loader                     from "@/components/layout/Loader.vue";
import HourlyChart                from "@/components/layout/Charts/HourlyChart.vue";
import {toRef}                    from "vue";
import {useClientClassTimesQuery} from "@/graphql/queries/ql/composables";
import {useResult}                from "@vue/apollo-composable";
import {echoListener}             from "@/composables/echoListener";
import {EventTypes}               from "@/classes/notifications/EventTypes";

interface Props {
  clientId: string;
}

const props = withDefaults(defineProps<Props>(), {});

const clientId = toRef(props, "clientId");

const query = useClientClassTimesQuery({client_id: clientId.value});
const {loading, result} = query;

const clientClassTimes = useResult(result, []);

echoListener([EventTypes.VisitWasUpdated], (ev) => {
  if (ev.data.client_id == clientId.value) {
    query.refetch();
  }
}, 2000);

</script>
