export function MinutesFromMidnightToTime(minutes: number): string {
	let h = 0;
	let m = 0;
	const postMeridian: "AM" | "PM" = minutes >= 720 ? "PM" : "AM";

	if (minutes > 0) {
		h = Math.floor(minutes / 60);
		m = minutes % 60;
	}

	if (h === 0 || h === 12) {
		return printDateTime(12, m, postMeridian);
	}

	if (h > 12) {
		h = h - 12;
	}

	return printDateTime(h, m, postMeridian);
}

function printDateTime(h: number, m: number, pm: "AM" | "PM") {
	return String(h) + ":" + String(m).padStart(2, "0") + " " + pm;
}
