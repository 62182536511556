<template>
  <div>
    <div class="uppercase text-gray-500 text-sm">
      Segment
    </div>

    <segment-view-edit
        :editable="editable"
        :model-value="segment" />
  </div>
</template>
<script lang="ts" setup>
import SegmentViewEdit from "@/components/Segment/SegmentViewEdit.vue";
import {QLSegment}     from "@/graphql/queries/ql/composables";
import {toRef}         from "vue";

interface Props {
  segment: QLSegment;
  editable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  editable: true
});

const segment = toRef(props, "segment");

</script>
