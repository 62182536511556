<template>
  <div>
    <div @click="openModel = true">
      <slot />
    </div>

    <side-panel
        v-model:open="openModel"
        :closable="!sending">
      <template #header>
        Change settings for this segment
      </template>
      <div class="px-4 py-4 h-full">
        <div class="h-full">
          <form
              ref="form"
              @submit.prevent="send">
            <div class="mb-1">
              <text-input
                  v-model="segmentName"
                  :disabled="sending"
                  label="Segment name"
                  required
                  small-info="Rename your segment" />
            </div>
            <div class="mt-8">
              <div class="flex items-center mb-2">
                <div class="grow">
                  <label
                      class="form-label"
                      for="visible_dashboard">Visible in dashboard</label>
                </div>
                <div>
                  <toggle-checkbox
                      v-model="visibleDashboard"
                      :disabled="sending"
                      label="visible_dashboard" />
                </div>
              </div>
              <div class="leading-tight form-small-info text-gray-600">
                Your dashboard has space for 10 visible segments.
                Dashboard segments should your most important
                segments - ones that you and your staff want to
                have visible representation at all times.
              </div>
            </div>
          </form>
          <div
              v-if="copiableTeams.length > 0"
              class="pt-8">
            <button
                class="text-link text-loopspark-600 font-medium"
                @click="openCopy = true">
              Copy this segment to another studio...
            </button>
          </div>
        </div>
      </div>
      <template #footer>
        <div
            v-if="!sending"
            class="flex m-2 justify-between w-full">
          <button
              class="btn btn-white"
              @click="openModel = false">
            Cancel
          </button>
          <button
              class="btn btn-green"
              @click="send">
            Update!
          </button>
        </div>
        <div
            v-if="sending"
            class="flex m-2 justify-center">
          <div class="h-8 w-8">
            <loader />
          </div>
        </div>
      </template>
      <copy-segment
          v-model:open="openCopy"
          :segment="segment"
          :teams="teamsCopiable" />
    </side-panel>
  </div>
</template>
<script lang="ts" setup>
import SidePanel      from "@/components/layout/SidePanel.vue";
import Loader         from "@/components/layout/Loader.vue";
import TextInput      from "@/components/layout/Forms/TextInput.vue";
import ToggleCheckbox from "@/components/layout/Forms/ToggleCheckbox.vue";

import {onMounted, ref, toRef, watch}        from "vue";
import {QLSegment, useUpdateSegmentMutation} from "@/graphql/queries/ql/composables";
import $toast                                from "@/composables/toast";
import SegmentDecorator                      from "@/composables/decorators/SegmentDecorator";
import CopySegment                           from "@/components/Segment/CopySegment.vue";
import meQuery                               from "@/composables/queries/meQuery";
import copiableTeams                         from "@/composables/copiableTeams";

interface Props {
  segment: QLSegment;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["updated"]);

const segment = toRef(props, "segment");

const form = ref<HTMLFormElement>(null);
const openModel = ref(false);

const segmentName = ref("");
const sending = ref(false);
const visibleDashboard = ref(false);

const refreshFromProp = () => {
  segmentName.value = segment.value.name;
  visibleDashboard.value = segment.value.visible_dashboard;
  sending.value = false;
};

onMounted(() => {
  refreshFromProp();
});
watch(segment, () => refreshFromProp());

const updateSegment = useUpdateSegmentMutation({});

updateSegment.onDone((data) => {
  sending.value = false;
  $toast({
           type:         "success",
           message:      "Segment updated!",
           linkRouteObj: null,
           linkText:     null
         });
  emit("updated", data.data.UpdateSegment);
  openModel.value = false;
});

updateSegment.onError(() => {
  sending.value = false;
  $toast({
           type:         "error",
           message:      "There was a problem with your request. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const send = () => {
  if (!form.value.reportValidity() || sending.value) {
    return;
  }
  const data = SegmentDecorator(segment).computed.toPost;

  data.name = segmentName.value;
  data.visible_dashboard = visibleDashboard.value;

  sending.value = true;
  updateSegment.mutate({
                         id:   segment.value.id,
                         data: data
                       });
};

const me = meQuery();
const {teamsCopiable} = copiableTeams(me.me);
const openCopy = ref(false);

</script>
