<template>
  <div v-if="operator">
    <label
        for=""
        class="form-label">
      {{ variableInput.field_name }}
    </label>

    <filter-input-with-multiple-text
        v-if="operator.type === QLOperatorTypeEnum.MultipleTextOperator"
        v-model="selectedValue"
        class="catalog-variable-input"
        :operator="operator"
        @is-valid="isValid($event)" />

    <filter-input-with-text
        v-if="operator.type === QLOperatorTypeEnum.TextOperator || operator.type === QLOperatorTypeEnum.NumericOperator"
        v-model="selectedValue"
        class="catalog-variable-input"
        :operator="operator"
        @is-valid="isValid($event)" />

    <filter-input-with-client-tags
        v-if="operator.type === QLOperatorTypeEnum.ClientTagOperator"
        v-model="selectedValue"
        class="catalog-variable-input"
        :operator="operator"
        @is-valid="isValid($event)" />

    <filter-input-with-graph-q-l
        v-if="isQlOperator"
        v-model="selectedValue"
        class="catalog-variable-input"
        :operator="operator"
        @is-valid="isValid($event)" />

    <p class="text-xs pt-1 text-gray-600">
      {{ variableInput.field_description }}
    </p>
  </div>
</template>
<script lang="ts" setup>
import FilterInputWithText               from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithText.vue";
import ClassNameQlOperator               from "hm-filters/operators/GraphQls/ClassNameQlOperator";
import {ContractNameQlOperator}          from "hm-filters/filters/contractNameFilter";
import StaffNameQlOperator               from "hm-filters/operators/GraphQls/StaffNameQlOperator";
import {CommunicationReceivedQlOperator} from "hm-filters/filters/CommunicationReceivedFilter";
import {ProductTypeQlOperator}           from "hm-filters/filters/productTypeFilter";
import TextOperator                      from "hm-filters/operators/TextOperator";
import MultipleTextOperator              from "hm-filters/operators/MultipleTextOperator";
import FilterInputWithMultipleText       from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithMultipleText.vue";
import NumericOperator                   from "hm-filters/operators/NumericOperator";
import ClientTagOperator                 from "hm-filters/operators/ClientTagOperator";
import FilterInputWithClientTags         from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithClientTags.vue";
import FilterInputWithGraphQL            from "@/components/Segment/Edit/Categories/Inputs/FilterInputWithGraphQL.vue";


import {QLCatalogItemVariableFragmentFragment, QLOperatorTypeEnum} from "@/graphql/queries/ql/composables";
import {computed, toRef, useAttrs, useSlots}                       from "vue";
import {PackageNameQlOperator}                                     from "hm-filters/filters/packageNameFilter";

interface Props {
  variableInput: QLCatalogItemVariableFragmentFragment;
  modelValue?: any;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:modelValue", "isValid"]);

const variableInput = toRef(props, "variableInput");

const operator = computed(() => {
  switch (variableInput.value.field_type) {
    case QLOperatorTypeEnum.PackageNameQlOperator:
      return new PackageNameQlOperator("=", "at least once")
          .setIsMultipleChoice()
          .allowsCustomInput();
    case QLOperatorTypeEnum.ClassNameQlOperator:
      return new ClassNameQlOperator("=", "at least once")
          .setIsMultipleChoice()
          .allowsCustomInput();
    case QLOperatorTypeEnum.ContractNameQlOperator:
      return new ContractNameQlOperator("=", "at least once")
          .setIsMultipleChoice()
          .allowsCustomInput();
    case QLOperatorTypeEnum.StaffNameQlOperator:
      return new StaffNameQlOperator("=", "at least once")
          .setIsMultipleChoice()
          .allowsCustomInput();
    case QLOperatorTypeEnum.CommunicationReceivedQlOperator:
      return new CommunicationReceivedQlOperator("=", "at least once")
          .setIsMultipleChoice()
          .allowsCustomInput();
    case QLOperatorTypeEnum.ProductTypeQlOperator:
      return new ProductTypeQlOperator("=", "at least once")
          .setIsMultipleChoice()
          .allowsCustomInput();

    case QLOperatorTypeEnum.TextOperator:
      return new TextOperator("=", "at least once");

    case QLOperatorTypeEnum.MultipleTextOperator:
      return new MultipleTextOperator("=", "at least once");

    case QLOperatorTypeEnum.NumericOperator:
      return new NumericOperator("=", "at least once");
    case QLOperatorTypeEnum.ClientTagOperator:
      return new ClientTagOperator("=", "at least once");
    default:
      return new TextOperator("=", "at least once");
  }
});

const isQlOperator = computed(() => {
  return [
    QLOperatorTypeEnum.PackageNameQlOperator,
    QLOperatorTypeEnum.ClassNameQlOperator,
    QLOperatorTypeEnum.CommunicationReceivedQlOperator,
    QLOperatorTypeEnum.StaffNameQlOperator,
    QLOperatorTypeEnum.ProductTypeQlOperator,
    QLOperatorTypeEnum.ContractNameQlOperator
  ].includes(variableInput.value.field_type);
});

const selectedValue = computed({
                                 get: () => props.modelValue,
                                 set: (val) => emit("update:modelValue", val)
                               });

const isValid = (valid) => {
  emit("isValid", valid);
};

</script>
