<template>
  <div class="grid gap-6 mb-4 md:grid-cols-2 xl:grid-cols-4">
    <div class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
      <div class="p-3 mr-4 text-loopspark-500 bg-loopspark-100 rounded-full dark:text-loopspark-100 dark:bg-loopspark-500">
        <svg
            class="w-5 h-5"
            fill="currentColor"
            version="1.1"
            viewBox="0 0 20 14"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
          <g transform="matrix(1,0,0,1,-376,-467)">
            <g transform="matrix(1,0,0,1,100,100)">
              <g transform="matrix(1,0,0,1,274,362)">
                <path
                    d="M0,0h24v24h-24Z"
                    fill="none"
                    fill-rule="evenodd"
                    stroke="none" />
                <path
                    d="M16.5,12c1.38,0 2.49,-1.12 2.49,-2.5c0,-1.38 -1.11,-2.5 -2.49,-2.5c-1.38,0 -2.5,1.12 -2.5,2.5c0,1.38 1.12,2.5 2.5,2.5Zm-7.5,-1c1.66,0 2.99,-1.34 2.99,-3c0,-1.66 -1.33,-3 -2.99,-3c-1.66,0 -3,1.34 -3,3c0,1.66 1.34,3 3,3Zm7.5,3c-1.83,0 -5.5,0.92 -5.5,2.75v1.25c0,0.55 0.45,1 1,1h9c0.55,0 1,-0.45 1,-1v-1.25c0,-1.83 -3.67,-2.75 -5.5,-2.75Zm-7.5,-1c-2.33,0 -7,1.17 -7,3.5v1.5c0,0.55 0.45,1 1,1h6v-2.25c0,-0.85 0.33,-2.34 2.37,-3.47c-0.87,-0.18 -1.71,-0.28 -2.37,-0.28Z"
                    fill-rule="evenodd"
                    stroke="none" />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div
          class="md:grid md:gap-2"
          :class="{'grid-cols-2': periodCountMatters}">
        <div>
          <p
              class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
              :class="{'md:text-xs': periodCountMatters, 'md:text-sm': !periodCountMatters}">
            Club count
          </p>
          <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
            {{ client.total_club_counts }}
          </p>
        </div>
        <div
            v-show="periodCountMatters"
            class="hidden md:block">
          <p
              class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400"
              :class="{'md:text-xs': periodCountMatters, 'md:text-sm': !periodCountMatters}">
            Period count
          </p>
          <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
            {{ client.total_period_counts }}
          </p>
        </div>
      </div>
    </div>
    <div
        v-if="periodCountMatters"
        class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800 md:hidden">
      <div class="p-3 mr-4 text-loopspark-500 bg-loopspark-100 rounded-full dark:text-loopspark-100 dark:bg-loopspark-500">
        <svg
            class="w-5 h-5"
            fill="currentColor"
            version="1.1"
            viewBox="0 0 20 14"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
          <g transform="matrix(1,0,0,1,-376,-467)">
            <g transform="matrix(1,0,0,1,100,100)">
              <g transform="matrix(1,0,0,1,274,362)">
                <path
                    d="M0,0h24v24h-24Z"
                    fill="none"
                    fill-rule="evenodd"
                    stroke="none" />
                <path
                    d="M16.5,12c1.38,0 2.49,-1.12 2.49,-2.5c0,-1.38 -1.11,-2.5 -2.49,-2.5c-1.38,0 -2.5,1.12 -2.5,2.5c0,1.38 1.12,2.5 2.5,2.5Zm-7.5,-1c1.66,0 2.99,-1.34 2.99,-3c0,-1.66 -1.33,-3 -2.99,-3c-1.66,0 -3,1.34 -3,3c0,1.66 1.34,3 3,3Zm7.5,3c-1.83,0 -5.5,0.92 -5.5,2.75v1.25c0,0.55 0.45,1 1,1h9c0.55,0 1,-0.45 1,-1v-1.25c0,-1.83 -3.67,-2.75 -5.5,-2.75Zm-7.5,-1c-2.33,0 -7,1.17 -7,3.5v1.5c0,0.55 0.45,1 1,1h6v-2.25c0,-0.85 0.33,-2.34 2.37,-3.47c-0.87,-0.18 -1.71,-0.28 -2.37,-0.28Z"
                    fill-rule="evenodd"
                    stroke="none" />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div>
        <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
          Period count
        </p>
        <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
          {{ client.total_period_counts }}
        </p>
      </div>
    </div>
    <div class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
      <div class="p-3 mr-4 text-loopspark-500 bg-loopspark-100 rounded-full dark:text-loopspark-100 dark:bg-loopspark-500">
        <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 384 512"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
          <path
              d="M377,105l-97.9,-98c-4.5,-4.5 -10.6,-7 -17,-7h-6.1v128h128v-6.1c0,-6.3 -2.5,-12.4 -7,-16.9Zm-153,31v-136h-200c-13.3,0 -24,10.7 -24,24v464c0,13.3 10.7,24 24,24h336c13.3,0 24,-10.7 24,-24v-328h-136c-13.2,0 -24,-10.8 -24,-24Zm-160,-64c0,-4.42 3.58,-8 8,-8h80c4.42,0 8,3.58 8,8v16c0,4.42 -3.58,8 -8,8h-80c-4.42,0 -8,-3.58 -8,-8v-16Zm0,80v-16c0,-4.42 3.58,-8 8,-8h80c4.42,0 8,3.58 8,8v16c0,4.42 -3.58,8 -8,8h-80c-4.42,0 -8,-3.58 -8,-8Zm144,263.88v24.12c0,4.42 -3.58,8 -8,8h-16c-4.42,0 -8,-3.58 -8,-8v-24.29c-11.29,-0.58 -22.27,-4.52 -31.37,-11.35c-3.9,-2.93 -4.1,-8.77 -0.57,-12.14l11.75,-11.21c2.77,-2.64 6.89,-2.76 10.13,-0.73c3.87,2.42 8.26,3.72 12.82,3.72h28.11c6.5,0 11.8,-5.92 11.8,-13.19c0,-5.95 -3.61,-11.19 -8.77,-12.73l-45,-13.5c-18.59,-5.58 -31.58,-23.42 -31.58,-43.39c0,-24.52 19.05,-44.44 42.67,-45.07v-24.12c0,-4.42 3.58,-8 8,-8h16c4.42,0 8,3.58 8,8v24.29c11.29,0.58 22.27,4.51 31.37,11.35c3.9,2.93 4.1,8.77 0.57,12.14l-11.75,11.21c-2.77,2.64 -6.89,2.76 -10.13,0.73c-3.87,-2.43 -8.26,-3.72 -12.82,-3.72h-28.11c-6.5,0 -11.8,5.92 -11.8,13.19c0,5.95 3.61,11.19 8.77,12.73l45,13.5c18.59,5.58 31.58,23.42 31.58,43.39c0,24.53 -19.05,44.44 -42.67,45.07Z" />
        </svg>
      </div>
      <div>
        <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
          Life time value
        </p>
        <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
          $ {{ client.ltv }}
        </p>
      </div>
    </div>
    <div class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
      <div class="p-3 mr-4 text-loopspark-500 bg-loopspark-100 rounded-full dark:text-loopspark-100 dark:bg-loopspark-500">
        <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 24 24">

          <path
              d="M11,12h6v6h-6v-6Zm-6,-8h1v-1.99999h2v1.99999h8v-1.99999h2v1.99999h1c1.1046,0 2,0.89543 2,2v14c0,1.1046 -0.8954,2 -2,2h-14c-1.10457,0 -2,-0.8954 -2,-2v-14c0,-1.10457 0.89543,-2 2,-2Zm0,5v11h14v-11h-14Zm8,5v2h2v-2h-2Z" />
        </svg>
      </div>
      <div>
        <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
          Last attendance
        </p>
        <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
          <span v-if="!client.last_attendance_at">-</span>
          <span v-if="client.last_attendance_at > 0"><time-ago :timestamp="client.last_attendance_at" /></span>
        </p>
      </div>
    </div>
    <div class="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
      <div class="p-3 mr-4 text-loopspark-500 bg-loopspark-100 rounded-full dark:text-loopspark-100 dark:bg-loopspark-500">
        <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 24 24">
          <path d="M15,13h1.5v2.823l2.4382,1.4077l-0.75,1.299l-3.1882,-1.8407v-3.689Zm3.9994,-5.00193h-14v11h4.67331c-0.43136,-0.9088 -0.67271,-1.9253 -0.67271,-2.9981c0,-3.866 3.134,-7 7,-7c1.0734,0 2.0903,0.24159 2.9994,0.67335v-1.67528Zm-14,13c-1.106,0 -2,-0.896 -2,-2l0.01001,-14c0,-1.10501 0.88399,-2 1.98999,-2h1v-1.99999h2v1.99999h8.00004v-1.99999h2v1.99999h1c1.103,0 2,0.89499 2,2v6.10232c1.2375,1.2626 2.0006,2.992 2.0006,4.8996c0,3.866 -3.134,7 -7,7c-1.9083,0 -3.6382,-0.7636 -4.9009,-2.0019h-6.09974Zm11.0006,-9.8442c-2.6765,0 -4.8462,2.1697 -4.8462,4.8461c0,2.6765 2.1697,4.8462 4.8462,4.8462c2.6765,0 4.8462,-2.1697 4.8462,-4.8462c0,-2.6764 -2.1697,-4.8461 -4.8462,-4.8461Z" />
        </svg>
      </div>
      <div>
        <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
          Scheduled visit
        </p>
        <p class="text-lg font-semibold text-gray-700 dark:text-gray-200">
          <span v-if="!scheduledAttendance">-</span>
          <span v-if="scheduledAttendance"><time-ago :timestamp="scheduledAttendance" /></span>
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import TimeAgo                               from "@/components/layout/TimeAgo.vue";
import {computed, toRef, useAttrs, useSlots} from "vue";
import currentTeamQuery                      from "@/composables/queries/currentTeamQuery";
import {QLClient, QLPeriodCountType}         from "@/graphql/queries/ql/composables";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const {currentTeam} = currentTeamQuery();

const client = toRef(props, "client");

const scheduledAttendance = computed(() => {
  if (props.client.next_event && props.client.next_event.class && props.client.next_event.class.start_time) {
    return props.client.next_event.class.start_time;
  }
  return null;

});

const periodCountMatters = computed(() => {
  return currentTeam.value && currentTeam.value.period_count_type != QLPeriodCountType.ALL_TIME;
});
</script>
