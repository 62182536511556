import {BookingStatusFilter}                                 from "hm-filters/filters/BookingStatusFilter";
import {BookingPackageUsedFilter}                            from "hm-filters/filters/BookingPackageUsedFilter";
import {BookingCountFilter}                                  from "hm-filters/filters/BookingCountFilter";
import {BookingClassValuesFilter}                            from "hm-filters/filters/BookingClassValuesFilter";
import ClassNameQlOperator                                   from "hm-filters/operators/GraphQls/ClassNameQlOperator";
import FixedValueOperator, {OperatorAsFixedValueOperator}    from "hm-filters/operators/FixedValueOperator";
import {BookingEventDateFilter, BookingEventDateStartFilter} from "hm-filters/filters/BookingEventDateFilter";
import {BookingClassTimesFilter}                             from "hm-filters/filters/BookingClassTimesFilter";
import {BookingClassDayOfWeekFilter}                         from "hm-filters/filters/BookingClassDayOfWeekFilter";
import {BookingEventLocationFilter}                          from "hm-filters/filters/BookingEventLocationFilter";
import {BookingEventStaffValuesFilter}                       from "hm-filters/filters/BookingEventStaffValuesFilter";
import StaffNameQlOperator                                   from "hm-filters/operators/GraphQls/StaffNameQlOperator";
import {BookingFirstFilter}                                  from "hm-filters/filters/BookingFirstFilter";
import OperatorGenerator                                     from "hm-filters/operatorGenerator";

export const BookingFilters = [
	new BookingStatusFilter("Status", {"field": "status"}).setDescription(
		"Booking Status is one of the most commonly used booking filters, and will exist in most of your booking rules"),
	new BookingFirstFilter("First", {"field": "first"}).setDescription(
		"Filters your audience by the first attendance, first absence, or first late cancellation for group classes. First booking filter can only be added in rule that contains Event Start Date or Event End Date."),
	new BookingPackageUsedFilter("Using Package", {"field": "booking_package_used"}).setDescription(
		"Booking Using Package is a common filter used to target clients based on the pricing package used to book an event"),

	new BookingCountFilter("Count", {"field": "booking_count"}).setDescription(
		"Booking Count is one of the most commonly used booking filters, and will exist in most of your booking rules. Keep in mind that alone, booking count does not imply the client attended"),

	// Booking Event
	new BookingClassValuesFilter("Name", {"field": "name"}).setOperators([
		                                                                     new ClassNameQlOperator(
			                                                                     "=",
			                                                                     "is").setIsMultipleChoice(),
		                                                                     new ClassNameQlOperator(
			                                                                     "only",
			                                                                     "only").setAloneInFilterGroup(),
		                                                                     ...OperatorGenerator.textContainsMultiple()
	                                                                     ]).setDescription(
		"Event Name filters your audience based on the name of an event booked by the client. This is a very commonly used filter, and helps target clients that have or have not booked specific event types"),

	new BookingClassValuesFilter("Type", {"field": "type"}).setOperators([
		                                                                     new OperatorAsFixedValueOperator(
			                                                                     "is_group_class",
			                                                                     "group class"),
		                                                                     new OperatorAsFixedValueOperator(
			                                                                     "is_appointment",
			                                                                     "appointment")
	                                                                     ]).setDescription(
		"Event Type is a very commonly used filter, as it will help distinguish a booking as either group class or appointment"),

	new BookingClassValuesFilter("Cancelled", {"field": "cancelled"}).setOperators([
		                                                                               new FixedValueOperator(
			                                                                               "true",
			                                                                               "is cancelled"),
		                                                                               new FixedValueOperator(
			                                                                               "false",
			                                                                               "is not cancelled")
	                                                                               ])
	                                                                 .hideOnClubready().setDescription(
		"Event Cancelled filter can be used to target clients that have signed up for an event that has been cancelled. This filter is not commonly used, but will almost always occur in booking rules along with filter event date and event type."),

	new BookingClassValuesFilter("Is Virtual/Streaming", {"field": "is_virtual"}).setOperators([
		                                                                                           new FixedValueOperator(
			                                                                                           true,
			                                                                                           "yes"),
		                                                                                           new FixedValueOperator(
			                                                                                           false,
			                                                                                           "no")
	                                                                                           ]).setDescription(
		"Events in LoopSpark can be marked as virtual, helping dispatch information for clients to attend your virtual events. The Event: Is virtual/streaming filter targets clients based on a booking being either virtual or not virtual"),

	new BookingEventDateStartFilter("Start Date", {"field": "start_date"}).setDateFilter().setDescription(
		"Filter your audience based on the Event Start Date of client booking. Event Start Date does not imply the booking status is attended or upcoming, so it will almost always occur in a booking rule with booking status, and commonly in booking rules containing booking count and event type. This filter is very useful to trigger automations based on the start time of an event."),
	new BookingEventDateFilter("End Date", {"field": "date"}).setDateFilter().setDescription(
		"Filter your audience based on the Event End Date of client booking. Event End Date does not imply the booking status is attended or upcoming, so it will almost always occur in a booking rule with booking status , and commonly in booking rules containing booking count and event type. This filter is very useful to trigger automations based on the end time of an event."),


	new BookingEventDateFilter("Date", {"field": "date"}).setDateFilter().setDescription(
		"Event Date filters your audience based on the event date of client booking. Event Date does not imply the booking status is attended or upcoming, so it will almost always occur in a booking rule with booking status , and commonly in booking rules containing booking count and event type"),

	new BookingClassTimesFilter("Starts", {"field": "start"}).setDescription(
		"Event Starts filters bookings based on event start time. Start time of an event can be selected using a time filter. This is a very uncommon filter to use, but can be very helpful to target clients that book events that start at specific times"),

	new BookingClassTimesFilter("Ends", {"field": "end"}).setDescription(
		"Event Ends filters bookings based on event ending time. Ending time of an event can be selected using a time filter. This is a very uncommon filter to use, but can be very helpful to target clients that book events that end at specific times"),

	new BookingClassDayOfWeekFilter("Day of Week", {"field": "weekday"}).setDescription(
		"Event Day of Week filters bookings by the day of week in which an event occurs. This filter is not commonly used, but very helpful in targeting clients that have bookings that occur on a specific day of the week."),


	new BookingEventLocationFilter("Location", {"field": "booking_event_location"}).setDescription(
		"Event Location is useful for multi-unit owners that have more than one business location within the same booking software account. Adding Event Location to a booking rule will filter your audience by location in which client booking was made."),

	new BookingEventStaffValuesFilter("Staff", {"field": "name"}).setOperators([
		                                                                           new StaffNameQlOperator(
			                                                                           "=",
			                                                                           "is").setIsMultipleChoice(),
		                                                                           new StaffNameQlOperator(
			                                                                           "!=",
			                                                                           "is not").setIsMultipleChoice()
	                                                                           ]).setDescription(
		"Event Staff is useful for targeting clients that have or have not booked any events with a specific staff member.")
];
