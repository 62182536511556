<template>
  <div>
    <button
        class="btn btn-transparent text-blue-900 px-2 py-1 text-sm"
        @click="openModal">
      <div class="inline-flex items-center">
        <svg-icon
            name="send"
            class="w-3 h-3" />
        <div class="pl-1">
          Manually send one...
        </div>
      </div>
    </button>
    <modal
        v-model:open="modalOpen"
        :has-close-button="!sending"
        :red-button-text="buttonText"
        close-button-text="Nevermind"
        @close="modalOpen = false">
      <div class="w-full">
        <div class="sm:flex sm:items-start">
          <div class="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg-icon
                name="send"
                class="h-5 w-5 text-green-600" />
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left grow">
            <h3
                id="modal-headline"
                class="text-lg leading-6 font-medium text-gray-900">
              Manually send this automation
            </h3>
            <div class="mt-2 w-full">
              <p class="text-gray-600">
                You can send this automation manually to a client. Select the client below
              </p>

              <div
                  v-show="!selectedClient"
                  class="mt-4">
                <client-search
                    :go-on-hit="false"
                    @selected="changeSelectedClient" />
              </div>

              <div
                  v-if="selectedClient"
                  class="mt-4">
                <div class="mb-2 text-sm">
                  You selected:
                </div>
                <div class="flex items-center">
                  <div class="flex items-center grow">
                    <div class="inline-block">
                      <client-avatar
                          :client="selectedClient"
                          class="w-8 h-8" />
                    </div>
                    <div class="ml-2 text-gray-800">
                      <span class="whitespace-nowrap">{{ selectedClient.first_name }} {{
                          selectedClient.last_name
                                                      }}</span>
                      <div class="text-sm text-gray-600">
                        <client-status-text :client="selectedClient" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                        class="text-gray-400 hover:text-blue-600 focus:outline-none"
                        @click="selectedClient = null">
                      <svg-icon
                          name="closeCircle"
                          class="h-6 w-6" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #buttons>
        <v-button
            v-if="selectedClient"
            :loading="sending"
            class="modal-button modal-button-green cursor-pointer"
            @click="sendSingle">
          {{ buttonText }}
        </v-button>
      </template>
    </modal>
  </div>
</template>
<script lang="ts" setup>
import {QLFragmentClientSmallFragment, QLFragmentFullAutomationFragment, useSendAutomationManuallyMutation} from "@/graphql/queries/ql/composables";
import {computed, ref, toRef, useAttrs, useSlots}                                                           from "vue";
import Modal                                                                                                from "@/components/layout/Modal.vue";
import ClientSearch                                                                                         from "@/components/navbar/ClientSearch.vue";
import ClientAvatar                                                                                         from "@/components/client/ClientAvatar.vue";
import ClientStatusText                                                                                     from "@/components/client/ClientStatusText.vue";
import SvgIcon                                                                                              from "@/components/SvgIcon.vue";
import VButton                                                                                              from "@/components/layout/VButton.vue";
import $toast                                                                                               from "@/composables/toast";

interface Props {
  automation: QLFragmentFullAutomationFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const selectedClient = ref<QLFragmentClientSmallFragment | null>(null);

const modalOpen = ref(false);
const sending = ref(false);

const automation = toRef(props, "automation");

const openModal = () => {
  selectedClient.value = null;
  sending.value = false;
  modalOpen.value = true;
};
const changeSelectedClient = (item) => {
  selectedClient.value = item;
};

const buttonText = computed(() => {
  if (!selectedClient.value) {
    return "";
  }
  return "Send to " + selectedClient.value.first_name;
});

const sendSingleMutation = useSendAutomationManuallyMutation({});
sendSingleMutation.onDone(() => {
  sending.value = false;
  $toast({
           type:    "success",
           message: "The automation will be sent to " + selectedClient.value.first_name,
           sticky:  false
         });
});

sendSingleMutation.onError(() => {
  sending.value = false;
  $toast({
           type:    "error",
           message: "There was an error while sending this automation. Please try again",
           sticky:  true
         });
});

const sendSingle = () => {
  sending.value = true;
  sendSingleMutation.mutate({
                              client_id:     selectedClient.value.id.toString(),
                              automation_id: automation.value.id
                            });
};

</script>
