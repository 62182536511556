<template>
  <div>
    <div class="mb-3 flex justify-between">
      <h3 class="text-lg">
        Contracts
      </h3>
      <inline-muted-select
          v-model="status"
          :options="statusOptions"
          select-classes="font-medium" />
    </div>
    <div class="bg-white shadow-md rounded-lg">
      <div
          v-if="!loadedFirstTime"
          class="animate-loading">
        <div class="px-4 divide-y divide-gray-200">
          <div
              v-for="i in 5"
              :key="i"
              class="py-4 flex">
            <div class=" w-2/3 mr-2 items-center overflow-hidden truncate">
              <div class="text-lg ">
                <loading-skeleton
                    :max="40"
                    class-type="inline"
                    random-text />
              </div>
              <div class="text-sm text-gray-700">
                <loading-skeleton
                    class-type="inline"
                    text="Aug 13, 2020 to Aug 15, 2020" />
              </div>
            </div>
            <div class="w-1/3 flex justify-end">
              <div class="loading-bg inline-block h-8 rounded-full w-16" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="loadedFirstTime">
        <div v-if="items.length > 0">
          <div
              v-if="items.length > 0"
              class="px-4 divide-y divide-gray-200">
            <div
                v-for="contract in items"
                :key="contract.id"
                class="py-4">
              <div class="flex justify-between">
                <div class="flex flex-col justify-between">
                  <div>
                    <div class="text-lg">
                      {{ contract.name }}
                    </div>
                  </div>

                  <div class="text-sm text-gray-700">
                    <timestamp-to-date
                        :timestamp="contract.start_date"
                        studio
                        has-date
                        natural-date />
                    to
                    <timestamp-to-date
                        v-if="contract.end_date"
                        :timestamp="contract.end_date"
                        studio
                        has-date
                        natural-date />
                    <span v-if="!contract.end_date">no end date</span>
                  </div>
                </div>
                <div class="text-xs text-right flex flex-col justify-between">
                  <div class="grid grid-cols-1 gap-1 mb-3">
                    <div>
                      <span
                          class="badge font-medium badge-gray"
                          :class="{'badge-green': contract.is_active, 'badge-yellow': contract.is_suspended}">{{
                          contract.status
                                                                                                              }}</span>
                    </div>
                  </div>

                  <div
                      v-if="contract.price > 0"
                      class="text-gray-600 self-end">
                    <p class="font-mono">$ {{ contract.price }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="items.length === 0">
          <div class="py-16 px-4 w-full h-full text-center">
            <div class="text-xl text-bold text-gray-600">
              Nothing to show!
            </div>
            <p
                v-if="status === 'All'"
                class="text-gray-600">
              {{ client.first_name }} has not purchased any contract
              yet!
            </p>
            <p
                v-if="status !== 'All'"
                class="text-gray-600">
              {{ client.first_name }} does not have any contract with the
              selected filter!
            </p>
          </div>
        </div>
        <div
            v-if="loadedFirstTime && paginatorInfo.lastPage > 1"
            class="w-full pt-4 pb-4 text-right bg-gray-100 border-t border-gray-200 px-4 rounded-b-lg">
          <pagination
              v-if="paginatorInfo.lastPage > 1"
              :pagination="paginatorInfo"
              class="flex items-center justify-between text-gray-700 text-sm"
              @setPage="paginationData.page = $event">
            <div
                v-if="loading"
                class="inline-block w-8 h-8 mr-2 text-gray-400">
              <loader />
            </div>
          </pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Loader            from "@/components/layout/Loader.vue";
import Pagination        from "@/components/layout/Pagination.vue";
import TimestampToDate   from "@/components/layout/TimestampToDate.vue";
import LoadingSkeleton   from "@/components/layout/LoadingSkeleton.vue";
import InlineMutedSelect from "@/components/layout/InlineMutedSelect.vue";

import {ref, toRef, watch} from "vue";
import {
  QLClient, QLClientContractsQuery, QLContractStatus, QLSortOrder, useClientContractsQuery
}                          from "@/graphql/queries/ql/composables";
import {
  createPaginationData, paginationWithAlreadyLoaded
}                          from "@/composables/paginationWithAlreadyLoaded";
import {
  echoListener
}                          from "@/composables/echoListener";
import {
  EventTypes
}                          from "@/classes/notifications/EventTypes";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 10
                                              });

const status = ref<"All" | QLContractStatus>("All");
const statusOptions = [
  {label: "All", value: "All"},
  {label: "Active", value: QLContractStatus.ACTIVE},
  {label: "Suspended", value: QLContractStatus.SUSPENDED},
  {label: "Inactive", value: QLContractStatus.INACTIVE}
];

const client = toRef(props, "client");

const productsQ = useClientContractsQuery(() => {
  let q = {
    client_id: client.value.id.toString(),
    page:      paginationData.page,
    first:     paginationData.perPage,
    status:    status.value === "All" ? null : status.value,
    orderBy:   [
      {
        column: "end_date",
        order:  QLSortOrder.DESC
      }
    ]
  };
  if (status.value !== "All") {
    q.status = status.value;
  }
  return q;
});

const {
  paginatorInfo,
  loadedFirstTime,
  results: items,
  loading
} = paginationWithAlreadyLoaded<QLClientContractsQuery["contracts"]["data"]>(productsQ);

watch(status, () => {
  paginationData.page = 1;
});

echoListener([EventTypes.ContractWasUpdated], (ev) => {
  if (ev.data.client_id == client.value.id) {
    productsQ.refetch();
  }
}, 2000);

</script>
