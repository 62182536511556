import {reactive} from "vue";

export default function dragDropComposable() {
	const status = reactive({
		                        started: false,
		                        over:    {
			                        index:  -1,
			                        active: false,
			                        itemId: null
		                        }
	                        });


	const startDrag = (evt, item) => {
		status.started = true;
		evt.dataTransfer.dropEffect = "move";
		evt.dataTransfer.effectAllowed = "move";
		evt.dataTransfer.setData("itemID", item.id);
		status.over.itemId = item.id;
	};

	const endDragging = () => {
		status.over.itemId = null;
		status.over.index = -1;
		status.over.active = false;
		status.started = false;
	};

	const onDragEnter = (index, active) => {
		status.over.index = index;
		status.over.active = active;
		status.over.itemId = null;
	};

	const onDragEnd = () => {
		status.over.index = -1;
	};

	const onDragOver = () => {
		status.started = false;
	};

	return {
		status,
		dragFns: {
			startDrag,
			endDragging,
			onDragEnter,
			onDragEnd,
			onDragOver
		}
	};
};
