import {apolloClient}            from "@/graphql/apollo";
import {RouteLocationNormalized} from "vue-router";
import {
	RouterGuardExecutor
}                                from "@/router/RouterGuardExecutor";
import {
	CurrentTeamDocument, MeDocument, QLFragmentCurrentTeamFragment, QLFragmentUserMeFragment
}                                from "@/graphql/queries/ql/composables";
import {CurrentUserInstance}     from "@/classes/state/CurrentUserInstance";

export class RouterGuard {
	private user: QLFragmentUserMeFragment | null;
	private currentTeam: QLFragmentCurrentTeamFragment | null;
	private to: RouteLocationNormalized;
	private from: RouteLocationNormalized;
	private readonly next: Function;
	private sentNext = false;

	constructor(to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) {
		this.to = to;
		this.from = from;
		this.next = next;
	}

	public async analyze() {
		await this.prepare();
		return RouterGuardExecutor.next(this.to, this.from, this.user, this.currentTeam);
	}

	private async prepare() {
		await this.getUser();
		await this.getCurrentTeam();
	}


	private async getUser(): Promise<QLFragmentUserMeFragment | null> {
		try {
			const userQ = await apolloClient.query({
				                                       query:       MeDocument,
				                                       fetchPolicy: "cache-first"
			                                       });
			this.user = userQ.data?.me;
			CurrentUserInstance.getInstance().setUser(this.user);
		} catch (e) {
			this.user = null;
		}

		return this.user;
	}

	private async getCurrentTeam(): Promise<QLFragmentCurrentTeamFragment | null> {
		try {
			const team = await apolloClient.query({
				                                      query:       CurrentTeamDocument,
				                                      fetchPolicy: "cache-first"
			                                      });
			this.currentTeam = team.data?.current_team;
		} catch (e) {
			this.currentTeam = null;
		}
		return this.currentTeam;
	}
}
