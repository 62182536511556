import CalendarGroup     from "./partials/CalendarGroup";
import DayOfWeekOperator from "../operators/DayOfWeekOperator";

class CalendarCurrentDateInWeek extends CalendarGroup {
	constructor(name: string, options: any) {
		super(name, options);

		this.clearOperators();
		this.addOperators([
			                  new DayOfWeekOperator("=", "is"),
			                  new DayOfWeekOperator("!=", "is not")

		                  ]);
	}

	get type(): string {
		return "CurrentDateInWeekFilter";
	}

}


export default CalendarCurrentDateInWeek;
