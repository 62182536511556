<template>
  <div class="w-full max-h-full h-full" ref="chartdiv" style="height: 500px"></div>
</template>
<script lang="ts" setup>

import * as am4charts                                                     from "@amcharts/amcharts4/charts";
import {DateAxis, XYSeriesDataItem}                                       from "@amcharts/amcharts4/charts";
import * as am4plugins_timeline                                           from "@amcharts/amcharts4/plugins/timeline";
import {AxisRendererCurveX, AxisRendererCurveY, CurveChart}               from "@amcharts/amcharts4/plugins/timeline";
import * as am4plugins_bullets                                            from "@amcharts/amcharts4/plugins/bullets";
import * as am4core                                                       from "@amcharts/amcharts4/core";
import am4themes_Loopspark                                                from "@/components/layout/Charts/Am4Theme";
import am4themes_animated                                                 from "@amcharts/amcharts4/themes/animated";
import {computed, onMounted, onUnmounted, ref, useAttrs, useSlots, watch} from "vue";
import {QLDiscussion}                                                     from "@/graphql/queries/ql/composables";

interface Props {
  engagements: QLDiscussion[];
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const chartdiv = ref<HTMLElement>(null);

const mobile = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBjbGFzcz0iaGVyb2ljb24tdWkiIGQ9Ik04IDJoOGEyIDIgMCAwIDEgMiAydjE2YTIgMiAwIDAgMS0yIDJIOGEyIDIgMCAwIDEtMi0yVjRjMC0xLjEuOS0yIDItMnptMCAydjE2aDhWNEg4em00IDE0YTEgMSAwIDEgMSAwLTIgMSAxIDAgMCAxIDAgMnoiLz48L3N2Zz4=";
const email = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxwYXRoIGQ9Ik0yMCA0SDRjLTEuMSAwLTEuOTkuOS0xLjk5IDJMMiAxOGMwIDEuMS45IDIgMiAyaDE2YzEuMSAwIDItLjkgMi0yVjZjMC0xLjEtLjktMi0yLTJ6bTAgNGwtOCA1LTgtNVY2bDggNSA4LTV2MnoiLz4KICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz4KPC9zdmc+Cg==";
const colorSet = new am4core.ColorSet();

let chart: CurveChart = null;

const generatedData = computed(() => {
  return props.engagements.map((discussion) => {
    return {
      "category": "",
      "start":    discussion.created_at * 1000,
      "end":      discussion.created_at * 1000,
      "color":    colorSet.getIndex(discussion.sms ? 4 : 6),
      "icon":     discussion.sms ? mobile : email,
      "text":     discussion.automation.name
    };
  });
});

const getMinY = () => {
  if (generatedData.value.length === 0) {
    return 0;
  }
  return generatedData.value.reduce((min, p) => p.start < min ? p.start : min, generatedData.value[0].start);
};

const getMaxY = () => {
  if (generatedData.value.length === 0) {
    return 0;
  }
  return generatedData.value.reduce((max, p) => p.start > max ? p.start : max, generatedData.value[0].start);
};

onUnmounted(() => {
  if (chart) {
    chart.dispose();
  }
});

onMounted(() => {
  createTheChart(chartdiv, getPoints());
});

const getPoints = () => {

  let k;
  let i;
  let points = [{x: -400, y: 200}, {x: 0, y: 200}];

  let w = 400;
  let h = 400;
  let levelCount = 5;

  let radius = am4core.math.min(w / (levelCount - 1) / 2, h / 2);
  let startX = radius;

  for (i = 0; i < 25; i++) {
    let angle = 0 + i / 25 * 90;
    let centerPoint = {y: 200 - radius, x: 0};
    points.push({
                  y: centerPoint.y + radius * am4core.math.cos(angle),
                  x: centerPoint.x + radius * am4core.math.sin(angle)
                });
  }


  for (i = 0; i < levelCount; i++) {

    if (i % 2 != 0) {
      points.push({y: -h / 2 + radius, x: startX + w / (levelCount - 1) * i});
      points.push({y: h / 2 - radius, x: startX + w / (levelCount - 1) * i});

      let centerPoint = {y: h / 2 - radius, x: startX + w / (levelCount - 1) * (i + 0.5)};
      if (i < levelCount - 1) {
        for (k = 0; k < 50; k++) {
          let angle = -90 + k / 50 * 180;
          points.push({
                        y: centerPoint.y + radius * am4core.math.cos(angle),
                        x: centerPoint.x + radius * am4core.math.sin(angle)
                      });
        }
      }

      if (i == levelCount - 1) {
        points.pop();
        points.push({y: -radius, x: startX + w / (levelCount - 1) * i});
        let centerPoint = {y: -radius, x: startX + w / (levelCount - 1) * (i + 0.5)};
        for (k = 0; k < 25; k++) {
          let angle = -90 + k / 25 * 90;
          points.push({
                        y: centerPoint.y + radius * am4core.math.cos(angle),
                        x: centerPoint.x + radius * am4core.math.sin(angle)
                      });
        }
        points.push({y: 0, x: 1300});
      }

    } else {
      points.push({y: h / 2 - radius, x: startX + w / (levelCount - 1) * i});
      points.push({y: -h / 2 + radius, x: startX + w / (levelCount - 1) * i});
      let centerPoint = {y: -h / 2 + radius, x: startX + w / (levelCount - 1) * (i + 0.5)};
      if (i < levelCount - 1) {
        for (k = 0; k < 50; k++) {
          let angle = -90 - k / 50 * 180;
          points.push({
                        y: centerPoint.y + radius * am4core.math.cos(angle),
                        x: centerPoint.x + radius * am4core.math.sin(angle)
                      });
        }
      }
    }
  }

  return points;
};

watch(props, () => {
  chart.data = generatedData.value;
  let dateAxis = <DateAxis><any>chart.xAxes.getIndex(0);
  dateAxis.min = getMinY();
  dateAxis.max = getMaxY();
});

const createTheChart = (chartdiv, chartData) => {
  am4core.unuseAllThemes();
  am4core.useTheme(am4themes_Loopspark);
  am4core.useTheme(am4themes_animated);

  am4core.addLicense("TLABCDE");
  am4core.addLicense("MPABCDE");
  am4core.addLicense("CHABCDE");
  chart = am4core.create(chartdiv.value, am4plugins_timeline.CurveChart);
  chart.responsive.enabled = false;
  chart.responsive.useDefault = true;
  chart.curveContainer.padding(100, 100, 50, 20);
  chart.maskBullets = false;

  chart.dateFormatter.inputDateFormat = "yyyy-MM-dd HH:mm";
  chart.dateFormatter.dateFormat = "HH";

  chart.data = generatedData.value;

  chart.fontSize = 10;
  chart.tooltipContainer.fontSize = 10;

  let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis<AxisRendererCurveY>());
  categoryAxis.dataFields.category = "category";
  categoryAxis.renderer.grid.template.disabled = true;
  categoryAxis.renderer.labels.template.paddingRight = 25;
  categoryAxis.renderer.minGridDistance = 10;
  categoryAxis.renderer.innerRadius = 10;
  categoryAxis.renderer.radius = 30;

  let dateAxis = chart.xAxes.push(new am4charts.DateAxis<AxisRendererCurveX>());
  dateAxis.renderer.points = getPoints();

  dateAxis.renderer.autoScale = false;
  dateAxis.renderer.autoCenter = false;
  dateAxis.renderer.minGridDistance = 100;
  dateAxis.baseInterval = {count: 60, timeUnit: "minute"};
  dateAxis.renderer.tooltipLocation = 0;
  dateAxis.renderer.line.strokeDasharray = "1,4";
  dateAxis.renderer.line.strokeOpacity = 0.5;
  dateAxis.tooltip.background.fillOpacity = 0.2;
  dateAxis.tooltip.background.cornerRadius = 5;
  dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
  dateAxis.tooltip.label.paddingTop = 7;
  dateAxis.endLocation = 0;
  dateAxis.startLocation = -0.5;
  dateAxis.min = getMinY();
  dateAxis.max = getMaxY();

  let labelTemplate = dateAxis.renderer.labels.template;
  labelTemplate.verticalCenter = "middle";
  labelTemplate.fillOpacity = 0.6;
  labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor("background");
  labelTemplate.background.fillOpacity = 1;
  labelTemplate.fill = new am4core.InterfaceColorSet().getFor("text");
  labelTemplate.padding(7, 7, 7, 7);

  let series = chart.series.push(new am4plugins_timeline.CurveColumnSeries());
  series.columns.template.height = am4core.percent(30);

  series.dataFields.openDateX = "start";
  series.dataFields.dateX = "end";
  series.dataFields.categoryY = "category";
  series.baseAxis = categoryAxis;
  series.columns.template.propertyFields.fill = "color"; // get color from data
  series.columns.template.propertyFields.stroke = "color";
  series.columns.template.strokeOpacity = 0;
  series.columns.template.fillOpacity = 0.6;

  let imageBullet1 = series.bullets.push(new am4plugins_bullets.PinBullet());
  imageBullet1.background.radius = 18;
  imageBullet1.locationX = 1;
  imageBullet1.propertyFields.stroke = "color";
  imageBullet1.background.propertyFields.fill = "color";
  imageBullet1.image = new am4core.Image();
  imageBullet1.image.propertyFields.href = "icon";
  imageBullet1.image.scale = 0.7;
  imageBullet1.circle.radius = am4core.percent(100);
  imageBullet1.background.fillOpacity = 0.8;
  imageBullet1.background.strokeOpacity = 0;
  imageBullet1.dy = -2;
  imageBullet1.background.pointerBaseWidth = 10;
  imageBullet1.background.pointerLength = 10;
  imageBullet1.tooltipText = "{text}";

  series.tooltip.pointerOrientation = "up";

  imageBullet1.background.adapter.add("pointerAngle", (value, target) => {
    if (target.dataItem) {
      let position = dateAxis.valueToPosition((target.dataItem as XYSeriesDataItem).openDateX.getTime());
      return dateAxis.renderer.positionToAngle(position);
    }
    return value;
  });

  let hs = imageBullet1.states.create("hover");
  hs.properties.scale = 1.3;
  hs.properties.opacity = 1;

  let textBullet = series.bullets.push(new am4charts.LabelBullet());
  textBullet.label.propertyFields.text = "text";
  textBullet.disabled = true;
  textBullet.propertyFields.disabled = "textDisabled";
  textBullet.label.strokeOpacity = 0;
  textBullet.locationX = 1;
  textBullet.dy = -100;
  textBullet.label.textAlign = "middle";

  chart.scrollbarX = new am4core.Scrollbar();
  chart.scrollbarX.align = "center";
  chart.scrollbarX.width = am4core.percent(75);
  chart.scrollbarX.parent = chart.curveContainer;
  chart.scrollbarX.height = 300;
  chart.scrollbarX.orientation = "vertical";
  chart.scrollbarX.x = 92;
  chart.scrollbarX.y = -140;
  chart.scrollbarX.isMeasured = false;
  chart.scrollbarX.opacity = 0.5;

  let cursor = new am4plugins_timeline.CurveCursor();
  chart.cursor = cursor;
  cursor.xAxis = dateAxis;
  cursor.yAxis = categoryAxis;
  cursor.lineY.disabled = true;
  cursor.lineX.disabled = true;

  dateAxis.renderer.tooltipLocation2 = 0;
  categoryAxis.cursorTooltipEnabled = false;

  chart.zoomOutButton.disabled = false;
};

</script>
