import Routes              from "@/router/Routes";
import EventsIndex         from "@/views/Events/EventsIndex.vue";
import VirtualEventManager from "@/views/Events/VirtualEventManager.vue";
import EventsShow          from "@/views/Events/EventsShow.vue";

export const EventRoutes = [
	{
		path:      "/events/calendar",
		component: EventsIndex,
		name:      Routes.event.calendar,
		meta:{
			title: "Calendar"
		}
	},
	{
		path:      "/events/virtual-events/manager",
		component: VirtualEventManager,
		name:      Routes.event.virtual_manager,
		meta:{
			title: "Virtual Event Manager"
		}
	},
	{
		path:      "/events/:id",
		component: EventsShow,
		props:     true,
		name:      Routes.event.show,
		meta:{
			title: "Event"
		}
	}
];
