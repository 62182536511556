<template>
  <side-panel
      v-model:open="openModel"
      :closable="!sending">
    <template #header>
      Edit note
    </template>
    <div class="px-4 py-4 h-full ">
      <form
          ref="form"
          class="h-full flex flex-col">
        <label class="form-label pb-2">
          Note
        </label>
        <rich-editor
            v-model="message"
            :disabled="sending"
            class="mb-4 grow"
            placeholder="Note content" />

        <span
            v-if="errors.has('note')"
            class="form-error-message">{{ errors.get("note") }}</span>
      </form>
    </div>
    <template #footer>
      <div
          v-if="!sending"
          class="flex m-2 justify-between w-full">
        <button
            class="btn btn-white"
            @click="openModel = false">
          Cancel
        </button>
        <button
            class="btn btn-green"
            @click="send">
          Save
        </button>
      </div>
      <div
          v-if="sending"
          class="flex m-2 justify-center">
        <div class="h-8 w-8">
          <loader />
        </div>
      </div>
    </template>
  </side-panel>
</template>
<script lang="ts" setup>

import SidePanel                                                       from "@/components/layout/SidePanel.vue";
import Loader                                                          from "@/components/layout/Loader.vue";
import RichEditor                                                      from "@/components/layout/RichEditor.vue";
import {computed, onMounted, ref, toRef, useAttrs, useSlots, watch}    from "vue";
import {QLClient, QLFragmentClientNoteFragment, useUpdateNoteMutation} from "@/graphql/queries/ql/composables";
import SparkFormErrors                                                 from "@/classes/utils/SparkFormErrors";
import fillValidationErrorsOrToast                                     from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import $toast                                                          from "@/composables/toast";

interface Props {
  open: boolean;
  client: QLClient;
  note: QLFragmentClientNoteFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open"]);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const form = ref<HTMLFormElement>(null);
const errorsData = ref({});

const message = ref("");
const sending = ref(false);

const client = toRef(props, "client");
const note = toRef(props, "note");

const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

onMounted(() => {
  message.value = note.value.note;
});

watch(client, () => {
  sending.value = false;
  message.value = "";
  errorsData.value = {};
});

watch(note, () => {
  message.value = note.value.note;
});

const updateNoteMutation = useUpdateNoteMutation({});
updateNoteMutation.onError((err) => {
  fillValidationErrorsOrToast(err, errorsData, "Error updating the note. Please try again!");
  sending.value = false;
});

updateNoteMutation.onDone(() => {
  $toast({
           type:         "success",
           message:      "Note updated",
           linkText:     null,
           linkRouteObj: null
         });
  sending.value = false;
});

const send = () => {
  errorsData.value = {};
  if (!form.value.reportValidity()) {
    return;
  }

  sending.value = true;
  updateNoteMutation.mutate({
                              id:   note.value.id,
                              note: message.value
                            });
};
</script>
