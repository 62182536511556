<template>
  <div>
    <Menu
        as="div"
        class="relative inline-block text-left">
      <div>
        <MenuButton class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          {{ timeframeSelectedText }}
          <svg-icon
              name="down"
              class="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true" />
        </MenuButton>
      </div>

      <transition
          enter-active-class="transition ease-out duration-100"
          enter-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95">
        <MenuItems
            :class="[position === 'right' ? 'origin-top-right absolute right-0': 'origin-top-left absolute left-0', 'mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10']">
          <div class="py-1">
            <MenuItem
                v-for="timeframeOption in timeframes"
                v-slot="{ active }"
                :key="timeframeOption.value">
              <button
                  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'w-full text-left block px-4 py-2 text-sm']"
                  @click="selectTimeFrame(timeframeOption)">
                {{ timeframeOption.name }}
              </button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>
<style>
.date-time-picker, .date-time-picker input, .date-time-picker label, .date-time-picker p, .date-time-picker span {
  @apply font-sans;
}

.date-time-picker .field-input {
  @apply font-medium text-gray-700;
}
</style>
<script lang="ts" setup>

// noinspection ES6UnusedImports
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";

import {TimeframeParser, Timeframes}            from "@/classes/dates/TimeframeParser";
import {DateTimeRange, DateTimeRangeWithPeriod} from "@/classes/dates/DateRangePeriodParser";
import {DateTime}                               from "luxon";
import {computed, toRef, useAttrs, useSlots}    from "vue";
import SvgIcon                                  from "@/components/SvgIcon.vue";

interface TimeframeOption {
  name: string,
  value: Timeframes,
  dates: DateTimeRange
}

interface Props {
  date: DateTimeRangeWithPeriod;
  position?: "left" | "right";
  minDate: number;
}

const props = withDefaults(defineProps<Props>(), {
  position: "right"
});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:date"]);

const timeframes: TimeframeOption[] = [
  {name: "Last 7 days", value: Timeframes.Last7days, dates: new TimeframeParser(Timeframes.Last7days).result},
  {name: "Last 14 days", value: Timeframes.Last14days, dates: new TimeframeParser(Timeframes.Last14days).result},
  {name: "Last 30 days", value: Timeframes.Last30days, dates: new TimeframeParser(Timeframes.Last30days).result},
  {name: "This week", value: Timeframes.ThisWeek, dates: new TimeframeParser(Timeframes.ThisWeek).result},
  {name: "Last week", value: Timeframes.LastWeek, dates: new TimeframeParser(Timeframes.LastWeek).result},
  {name: "This month", value: Timeframes.ThisMonth, dates: new TimeframeParser(Timeframes.ThisMonth).result},
  {name: "Last month", value: Timeframes.LastMonth, dates: new TimeframeParser(Timeframes.LastMonth).result},
  {name: "All time", value: Timeframes.AllTime, dates: new TimeframeParser(Timeframes.AllTime).result}
];

const dateSelected = toRef(props, "date");
const position = toRef(props, "position");

const selectedTimeframe = computed(() => {
  // check if matches, or "Custom"
  const found = timeframes.filter((i) => i.dates.start === dateSelected.value.range.start && i.dates.end === dateSelected.value.range.end);
  if (found.length > 0) {
    return found[0];
  }
  return null;
});

const selectTimeFrame = (timeframe: TimeframeOption) => {
  emit("update:date", {
    range:     timeframe.dates,
    timeframe: timeframe.value
  });
};

const timeframeSelectedText = computed((): string => {
  const sel = selectedTimeframe.value;
  if (sel === null) {
    if (dateSelected.value.range.start !== null && dateSelected.value.range.end !== null) {
      return "Custom: " + DateTime.fromSeconds(dateSelected.value.range.start)
                                  .toFormat("DD") + " to " + DateTime.fromSeconds(dateSelected.value.range.end)
                                                                     .toFormat("DD");
    }

    return "Custom";
  }
  return sel.name;
});

// TODO: IMPORTANT. Add the custom date picker

// @Emit("change")
// customDateChanged(date): DateTimeRangeWithPeriod {
//   return {
//     range:     {
//       start: parseInt(date.start),
//       end:   isNaN(date.end) ? null : parseInt(date.end)
//     },
//     timeframe: null
//   };
// }
//
// validateCustom() {
//   this.timeframeOpen = false;
//   this.customDateChanged(this.customDateInt);
// }

</script>
