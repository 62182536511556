import {loadStripe, Stripe, StripeCardElement} from "@stripe/stripe-js";
import {useGeneratePaymentTokenMutation} from "@/graphql/queries/ql/composables";
import {Ref, ref}                        from "vue";
import SparkFormErrors, {SparkErrors}    from "@/classes/utils/SparkFormErrors";

export default function getStripeCardToken(containerId: string, errorsData: Ref<SparkErrors>) {
	let stripe: Stripe | null = null;
	let cardElement: StripeCardElement | null = null;

	const cardHolderName = ref("");

	const generateTokenMutation = useGeneratePaymentTokenMutation();

	const createCardElement = (container: string) => {
		if (!stripe) {
			throw "Invalid Stripe Key/Secret";
		}

		const card = stripe.elements().create("card", {
			hideIcon:       true,
			hidePostalCode: true,
			style:          {
				base: {
					"::placeholder": {
						color: "#aab7c4"
					},
					fontFamily:      "Whitney, Lato, -apple-system, BlinkMacSystemFont,\"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, sans-serif, \"Apple Color Emoji\",\"Segoe UI Emoji\", \"Segoe UI Symbol\"",
					color:           "#495057",
					fontSize:        "15px"
				}
			}
		});

		card.mount(container);

		return card;
	};

	const generateToken = async () => {
		try {
			const result = await generateTokenMutation.mutate();
			return result.data.generatePaymentToken.client_secret;
		} catch (e) {
			throw "Can't generate token";
		}
	};

	const getPaymentMethod = async () => {
		const token = await generateToken();
		const response = await stripe.confirmCardSetup(token,
		                                               {
			                                               payment_method: {
				                                               card:            cardElement!,
				                                               billing_details: {
					                                               name: cardHolderName.value
				                                               }
			                                               }
		                                               });
		if (response.error) {
			errorsData.value = {
				                    card: [
					                    response.error.message
				                    ]
			                    };
			throw response.error.message;

		} else {
			return response.setupIntent.payment_method;
		}
	};


	const onMounted = async () => {
		stripe = await loadStripe(import.meta.env.VITE_APP_STRIPE_KEY.toString());
		cardElement = createCardElement(containerId);
	};


	return {
		cardHolderName,
		getPaymentMethod,
		onMounted
	};

}
