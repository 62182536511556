<template>
  <div class="flex items-center">
    <div class="py-2">
      {{ pagination.firstItem }} - {{ pagination.lastItem }} / {{ pagination.total }}
    </div>
    <slot></slot>
    <div>
      <button v-if="pagination.currentPage > 2"
              class="ml-2 bg-white appearance-none border border-gray-400 p-2 rounded hover:bg-blue-600 hover:text-white"
              @click="setPage(1)">
        <svg-icon name="left-double" class="w-4 h-4"></svg-icon>
      </button>
      <button v-if="pagination.currentPage > 1"
              class="ml-2 bg-white appearance-none border border-gray-400 p-2 rounded hover:bg-blue-600 hover:text-white"
              @click="setPage(pagination.currentPage - 1)">
        <svg-icon name="left" class="w-4 h-4"></svg-icon>
      </button>
      <button v-if="pagination.hasMorePages"
              class="ml-2 bg-white appearance-none border border-gray-400 p-2 rounded  hover:bg-blue-600 hover:text-white"
              @click="setPage(pagination.currentPage + 1)">
        <svg-icon name="right" class="w-4 h-4"></svg-icon>
      </button>
      <button v-if="pagination.currentPage < pagination.lastPage"
              class="ml-2 bg-white appearance-none border border-gray-400 p-2 rounded  hover:bg-blue-600 hover:text-white"
              @click="setPage(pagination.lastPage)">
        <svg-icon name="right-double" class="w-4 h-4"></svg-icon>
      </button>
    </div>
  </div>
</template>
<script lang="ts">

import {PropType}                    from "vue";
import {QLPaginatorFragmentFragment} from "@/graphql/queries/ql/composables";
import SvgIcon                       from "@/components/SvgIcon.vue";

export default {
  name:       "Pagination",
  components: {SvgIcon},
  props:      {
    pagination: {
      type:     Object as PropType<QLPaginatorFragmentFragment>,
      required: true
    }
  },
  emits:      ["setPage"],
  setup(_, {emit}) {

    const setPage = (page) => emit("setPage", page);
    return {
      setPage
    };
  }


};

</script>
