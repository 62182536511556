<template>
  <form ref="form" class="block overflow-hidden shadow-md rounded-lg" @submit.prevent="send">
    <div class="space-y-6 bg-white p-4">
      <text-input v-model="formData.currentPassword"
                  :error-message="errors.get('input.current_password')"
                  label="Current password"
                  autocomplete="current-password"
                  required
                  type="password"></text-input>
      <text-input v-model="formData.newPassword"
                  :error-message="errors.get('input.password')"
                  label="New password"
                  autocomplete="new-password"
                  required
                  type="password"></text-input>
      <text-input v-model="formData.confirmPassword"
                  autocomplete="new-password"
                  :error-message="errors.get('input.password_confirmation')"
                  label="Confirm new password"
                  required
                  type="password"></text-input>
    </div>
    <div class="bg-gray-100">
      <div class="flex justify-end p-4 py-3">
        <v-button :loading="sending" class="btn btn-green text-sm font-normal" type="submit">Change password</v-button>
      </div>

    </div>
  </form>
</template>
<script lang="ts" setup>
import TextInput                      from "@/components/layout/Forms/TextInput.vue";
import VButton                        from "@/components/layout/VButton.vue";
import {computed, reactive, ref}      from "vue";
import SparkFormErrors, {SparkErrors} from "@/classes/utils/SparkFormErrors";
import {useChangePasswordMutation}    from "@/graphql/queries/ql/composables";
import $toast                         from "@/composables/toast";
import fillValidationErrorsOrToast    from "@/composables/queries/mutations/fillValidationErrorsOrToast";

const form = ref(null);
const sending = ref(false);
const formData = reactive({
                            currentPassword: "",
                            newPassword:     "",
                            confirmPassword: ""
                          });

const errorsData = ref<SparkErrors>({});
const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

const updatePasswordMutation = useChangePasswordMutation({});

updatePasswordMutation.onDone(() => {
  sending.value = false;
  $toast({
           type:         "success",
           message:      "Password changed!",
           linkRouteObj: null,
           linkText:     null
         });

  formData.newPassword = '';
  formData.currentPassword = '';
  formData.confirmPassword = '';
});

updatePasswordMutation.onError((err) => {
  sending.value = false;
  fillValidationErrorsOrToast(err, errorsData, "There was a problem with your request. Please try again");
});

const send = () => {
  sending.value = true;
  errorsData.value = {};
  updatePasswordMutation.mutate({
                                  input: {
                                    current_password:      formData.currentPassword,
                                    password:              formData.newPassword,
                                    password_confirmation: formData.confirmPassword
                                  }
                                });
};

</script>
