<template>
  <side-panel
      v-model:open="openModel"
      :closable="true">
    <template #header>
      Audience quick glance
    </template>

    <div class="px-4 py-4 h-full">
      <p class="text-sm pb-6 text-gray-700">
        Audience segments control which customers trigger an automation and when.
        Before activating your automation, please ensure your segment is setup using correct filters and rules.
      </p>
      <form
          ref="form"
          class="h-full">
        <segment-view-edit
            :editable="false"
            :model-value="segment" />
      </form>
    </div>
  </side-panel>
</template>
<script lang="ts" setup>

import SegmentViewEdit from "@/components/Segment/SegmentViewEdit.vue";
import SidePanel       from "@/components/layout/SidePanel.vue";
import {QLSegment}       from "@/graphql/queries/ql/composables";
import {computed, toRef} from "vue";


interface Props {
  open: boolean;
  segment: QLSegment;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:open"]);

const segment = toRef(props, "segment");

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

</script>
