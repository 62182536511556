import BaseQlError     from "./BaseQlError";
import SparkFormErrors from "@/classes/utils/SparkFormErrors";
import {ApolloError}   from "@apollo/client/core";

export default class ValidationError extends BaseQlError {
	errors: SparkFormErrors = new SparkFormErrors();

	constructor(error: ApolloError) {
		super(error);

		if(this.error.graphQLErrors[0]!.extensions!.validation){
			// @ts-ignore
			this.errors.set({...this.error.graphQLErrors[0]!.extensions!.validation});
		}
		else {
			this.errors.set({});
		}
	}
}
