import {computed, reactive} from "vue";

export function smsLengthInit(originalText, unitsAllowed = 3) {
	const gsmLength = 153;
	const unicodeLength = 63;

	function isGSMAlphabet(text) {
		const regexp = new RegExp(
			"^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$");
		return regexp.test(text);
	}

	const maxLength = computed(() => {
		if (isGSMAlphabet(originalText.value)) {
			return gsmLength * unitsAllowed;
		}

		return unicodeLength * unitsAllowed;
	});

	const errorMessage = computed(() => {
		if (originalText.value.length >= maxLength.value) {
			if (maxLength.value === gsmLength * unitsAllowed) {
				return "This message is too long for a single SMS. Try shortening your message length or send via Email.";
			}
			return "You've reached your maximum message length. To add more text, remove any paragraph/line spacing, special characters etc";
		}
		return false;
	});

	const units = computed(() => {
		if (!originalText.value.length) {
			return 0;
		}
		const toUse = isGSMAlphabet(originalText.value) ? gsmLength : unicodeLength;

		return Math.ceil(originalText.value.length / toUse);
	});

	return reactive({
		                maxLength,
		                errorMessage,
		                units
	                }
	);

}
