<template>
  <div>
    <div class="linechart" ref="chartdiv" style="height: 100%; width: 100%"></div>
  </div>
</template>
<script lang="ts" setup>

import * as am4core                                                    from "@amcharts/amcharts4/core";
import * as am4charts                                                  from "@amcharts/amcharts4/charts";
import am4themes_Loopspark                                             from "@/components/layout/Charts/Am4Theme";
import {DateTime}                                                      from "luxon";
import {onMounted, onUnmounted, ref, toRef, useAttrs, useSlots, watch} from "vue";

interface Props {
  items: any;
  itemName?: string;
  itemValue?: string;
  minValue?: number;
}

const props = withDefaults(defineProps<Props>(), {
  itemName:  "timestamp",
  itemValue: "cc"
});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

interface TimeDataPoint {
  x: Date,
  y: number
}

let chart = null;

const updateChart = () => {
  if (chart) {
    chart.data = generatedChartData();
  }
};
watch(props, () => {
  updateChart();
});

const chartdiv = ref(null);
const items = toRef(props, "items");

const generatedChartData = () => {
  return items.value.map((i) => {
    return {x: DateTime.fromSeconds(i[props.itemName]).toJSDate(), y: i[props.itemValue]};
  });
};

const createTheChart = (chartdiv, chartData) => {
  am4core.unuseAllThemes();
  am4core.useTheme(am4themes_Loopspark);
  am4core.options.commercialLicense = true;
  chart = am4core.create((chartdiv.value as HTMLElement), am4charts.XYChart);
  chart.responsive.enabled = true;
  chart.responsive.useDefault = true;
  chart.padding(20, 0, 0, 0);
  chart.data = chartData;

  let categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
  // @ts-ignore
  categoryAxis.dataFields.category = "x";
  categoryAxis.title.text = null;

  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.title.text = null;
  if (props.minValue !== null) {
    valueAxis.min = props.minValue;
  }

  let series = chart.series.push(new am4charts.LineSeries());

  series.dataFields.valueY = "y";
  series.dataFields.dateX = "x";

  // series.fill = am4core.color('#4292c6');
  series.strokeWidth = 3;
  series.strokeLinejoin = "round";
  series.fillOpacity = 0.6;
  series.smoothing = "monotoneX";
  series.tooltipText = "{y}";

  series.tooltip.pointerOrientation = "vertical";

  chart.cursor = new am4charts.XYCursor();
  chart.cursor.snapToSeries = series;
  return {
    chart
  };
};

onMounted(() => {
  createTheChart(chartdiv, generatedChartData());
});

onUnmounted(() => {
  if (chart) {
    chart.dispose();
  }
});

</script>
