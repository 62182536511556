import Filter            from "../base-filter";
import ExactTimeOperator from "../operators/ExactTimeOperator";

class AppointmentTimesFilter extends Filter {
	path = ["Appointments"];
	smallText = "Appointment";
	group = "Appointment";

	constructor(name: string, options: any) {
		super(name, options);

		this.clearOperators();
		this.addOperators([
			                  new ExactTimeOperator("at_time", "at"),
			                  new ExactTimeOperator("before_time", "before"),
			                  new ExactTimeOperator("after_time", "after")
		                  ]);
	}

	get type(): string {
		return "AppointmentTimesFilter";
	}
}

export default AppointmentTimesFilter;
