export function possessive(name: string | null) {
	if (!name || name.length === 0) {
		return "";
	}

	if (name.endsWith("s")) {
		return name + "'";
	}
	return name + '\'s';
}
