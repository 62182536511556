<template>
  <div class="side-menu-new">
    <side-menu-item
        :route="{name: routeList.client.show, params: {id: client.id}}"
        icon="gauge-duotone">
      Info
    </side-menu-item>
    <side-menu-item
        :route="{name: routeList.client.discussions.index, params: {id: client.id}}"
        icon="chats-circle-duotone"
        :badge="client.total_open_discussions">
      Discussions
    </side-menu-item>
    <side-menu-item
        :route="{name: routeList.client.events.index, params: {id: client.id}}"
        icon="calendar-blank-duotone"
        :badge="client.total_club_counts">
      Events
    </side-menu-item>

    <side-menu-item
        :route="{name: routeList.client.services, params: {id: client.id}}"
        icon="swatches-duotone">
      Services
    </side-menu-item>

    <side-menu-item
        :route="{name: routeList.client.sales, params: {id: client.id}}"
        icon="money-duotone"
        :badge="client.total_sales">
      Sales
    </side-menu-item>

    <side-menu-item
        :route="{name: routeList.client.todos.index, params: {id: client.id}}"
        icon="todo"
        :badge="client.total_todos">
      To-Dos
    </side-menu-item>

    <side-menu-item
        :route="{name: routeList.client.notes.index, params: {id: client.id}}"
        icon="notes"
        :badge="client.total_notes">
      Notes
    </side-menu-item>
    <side-menu-item
        :route="{name: routeList.client.engagement, params: {id: client.id}}"
        icon="user-gear-duotone">
      Engagement
    </side-menu-item>
    <side-menu-item
        v-if="formsEnabled && formAddonsEnabled"
        :route="{name: routeList.client.forms.index, params: {id: client.id}}"
        :badge="client.total_form_instances"
        icon="signature-duotone">
      Forms & Waivers
    </side-menu-item>
    <a
        v-if="formAddonsEnabled && !formsEnabled"
        role="button"
        class="opacity-50"
        @click="addonFormsOpen = true">
      <SvgIcon name="signature-duotone" />
      <span>Forms & Waivers</span>
    </a>
    <modal v-model:open="addonFormsOpen">
      <template #default>
        <div>
          <div class="flex justify-between w-full items-center">
            <h3 class="text-lg block leading-6 font-medium text-gray-900">
              Forms & Waivers
            </h3>
            <div>
              <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">Addon</span>
            </div>
          </div>
          <p
              v-if="canSeeBilling"
              class="pt-4 text-sm text-gray-500">
            Customizable liability waivers, client forms, surveys and
            questionnaires! Organized in client profiles with rich form submission
            analytics, streamline customer intake and learn more about your client
            base.
          </p>
          <p
              v-if="!canSeeBilling"
              class="pt-4 text-sm text-gray-500">
            Customizable liability waivers, client forms, surveys and
            questionnaires! Ask {{ currentTeam.owner.name }} to add Forms & Waivers.
          </p>
        </div>
      </template>
      <template #buttons>
        <router-link
            v-if="canSeeBilling"
            v-slot="{ href, navigate }"
            :to="{name: routeList.studio.settings.billing}"
            custom>
          <button
              type="button"
              :href="href"
              class="modal-button modal-button-loopspark"
              @click="navigate">
            Go to billing
          </button>
        </router-link>
      </template>
    </modal>
  </div>
</template>
<script lang="ts" setup>
import SideMenuItem           from "@/components/layout/SideMenuItem.vue";
import routesList             from "@/composables/routesList";
import {QLClient}             from "@/graphql/queries/ql/composables";
import {computed, ref, toRef} from "vue";
import Modal                  from "@/components/layout/Modal.vue";
import meQuery                from "@/composables/queries/meQuery";
import currentTeamQuery       from "@/composables/queries/currentTeamQuery";
import SvgIcon                from "@/components/SvgIcon.vue";
import {formAddonsEnabledFn}  from "@/composables/formAddonsEnabled";
import {addonEnabled}         from "@/classes/billing/addons";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});
const client = toRef(props, "client");
const routeList = routesList().routeList;
const addonFormsOpen = ref(false);

const {me} = meQuery();
const {currentTeam} = currentTeamQuery();

const canSeeBilling = computed(() => {
  return currentTeam.value && me.value && !currentTeam.value.is_free && me.value.policy.is_main_owner;
});

const formsEnabled = computed(() => {
  return currentTeam.value && addonEnabled(currentTeam.value, 'forms');
});
const formAddonsEnabled = formAddonsEnabledFn();

</script>
