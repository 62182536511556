<template>
  <div class="">
    <div class="bg-white sm:rounded-lg shadow overflow-hidden">
      <div class="p-4">
        <div class="px-2 py-5 lg:px-6">
          <h3 class="text-2xl leading-6 font-medium text-gray-900">
            {{ singleEvent.name }}
          </h3>
          <p class="mt-1 max-w-2xl text-lg text-gray-500">
            {{ decorator.classDefinition }}
          </p>
        </div>
        <div class="space-y-5 border-t py-5 lg:px-4">
          <div
              v-if="singleEvent.cancelled"
              class="bg-red-100 text-red-900 w-full rounded border border-red-300 font-semibold p-4 mb-6">
            <div class="text-base">
              Cancelled
            </div>
            <div class="text-sm font-normal pt-1">
              This {{ decorator.classDefinition.toLowerCase() }} has been cancelled.
            </div>
          </div>

          <div class="flex items-center space-x-4 text-gray-700">
            <svg-icon
                name="clock"
                class="h-5 w-5 text-gray-500" />
            <div class="text-sm text-gray-700">
              <timestamp-to-date
                  class="font-medium"
                  :timestamp="singleEvent.start_time"
                  has-day-name
                  natural-date
                  studio />
              <div>
                <timestamp-to-date
                    :timestamp="singleEvent.start_time"
                    has-time
                    :has-date="false"
                    studio />
                to
                <timestamp-to-date
                    :has-date="false"
                    :timestamp="singleEvent.end_time"
                    studio
                    has-time />
              </div>
            </div>
          </div>

          <div
              v-if="!!singleEvent.location"
              class="flex items-center space-x-4 text-gray-900">
            <svg-icon
                name="store"
                class="h-5 w-5 text-gray-500" />
            <span class="text-sm text-gray-700">{{ singleEvent.location.name }}</span>
          </div>

          <div
              v-if="!!singleEvent.staff"
              class="flex items-center space-x-4 text-gray-900">
            <svg-icon
                name="user"
                class="h-5 w-5 text-gray-500" />
            <div class="">
              <div class="text-sm text-gray-700">
                {{ singleEvent.staff.name }}
              </div>
              <div class="text-xs lg:divide-x lg:divide-gray-300 text-gray-700 lg:flex lg:-ml-2">
              <span
                  v-if="singleEvent.staff.email"
                  class="block lg:px-2">{{ singleEvent.staff.email }}</span>
                <span
                    v-if="singleEvent.staff.mobile_phone"
                    class="block lg:px-2">{{
                    phone(singleEvent.staff.mobile_phone)
                                          }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="border-t flex divide-x" v-if="!singleEvent.is_virtual">
        <button class="text-center py-2 hover:bg-blue-50 block w-full text-blue-900 hover:text-blue-600 text-sm"
                @click="sideOpen = true">Set as
                                         virtual
                                         event...
        </button>
      </div>
    </div>

    <div class="bg-white shadow overflow-hidden sm:rounded-lg mt-10" v-if="singleEvent.is_virtual">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Virtual {{ decorator.isGroupClass ? "Group Class" : "Appointment" }}
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          Virtual event information
        </p>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8">
          <div>
            <dt class="text-sm font-medium text-gray-500">
              Streaming URL
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              <a
                  target="_blank"
                  :href="singleEvent.virtual_url">{{ singleEvent.virtual_url }}</a>
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">
              Streaming ID
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ singleEvent.virtual_data.virtual_id }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">
              Streaming Password
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ singleEvent.virtual_data.virtual_password }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">
              Streaming Info
            </dt>
            <dd class="mt-1 text-sm text-gray-900 prose prose-sm">
              {{ singleEvent.virtual_data.virtual_info }}
            </dd>
          </div>
        </dl>
      </div>
      <button class="border-t text-center py-2 hover:bg-blue-50 block w-full text-blue-900 hover:text-blue-600 text-sm"
              @click="sideOpen = true">
        Edit virtual information
      </button>
    </div>

    <event-edit-virtual-event-sidebar
        v-model:open="sideOpen"
        :single-event="singleEvent" />
  </div>
</template>
<script lang="ts" setup>
import {QLSingleClassFragmentFragment} from "@/graphql/queries/ql/composables";
import TimestampToDate                 from "@/components/layout/TimestampToDate.vue";
import {phone}                         from "@/classes/vue-filters/PhoneFilter";
import EventEditVirtualEventSidebar    from "@/components/Events/Show/EventEditVirtualEventSidebar.vue";
import SvgIcon                         from "@/components/SvgIcon.vue";
import {ref, toRef}                    from "vue";
import {EventDecorator}                from "@/composables/decorators/EventDecorator";

interface Props {
  singleEvent: QLSingleClassFragmentFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const sideOpen = ref(false);

const singleEvent = toRef(props, "singleEvent");

const {computed: decorator} = EventDecorator(singleEvent);

</script>
