<template>
  <router-link
      :to="{name:routeList.client.discussions.show, params:{id: client.id, discussionId: discussion.id}}"
      class="group hover:bg-gray-50 cursor-pointer block">
    <div class="px-4 py-4 sm:px-6">
      <div class="flex justify-between">
        <div>
    <span
        v-if="discussion.sms && !discussion.automation_id"
        class="text-lg">Direct discussion</span>
          <span
              v-if="discussion.email && !discussion.automation_id"
              class="text-lg">Subject: {{ discussion.subject }}</span>
          <span
              v-if="discussion.automation_id"
              class="text-lg">Automation: <router-link
              :to="{ name: routeList.automations.show.index, params: {id: discussion.automation.id}}"
              class="text-loopspark">
                        {{ discussion.automation.name }}
                    </router-link></span>
        </div>
        <div class="text-xs font-light text-gray-500">
          <time-ago :timestamp="discussion.last_contacted_at" />
        </div>
      </div>

      <div class="truncate text-gray-600 text-sm pt-2">
        <strong>{{ messageSenderName }}:</strong>
        <div class="inline pl-1">
          {{ messageText }}
        </div>
      </div>
      <div class="mt-3 flex justify-between items-center">
        <div>
          <p class="flex items-center text-sm text-gray-500">
            <svg-icon :name="discussion.email ? 'email': 'mobile'"
                      class="shrink-0 mr-1 h-5 w-5 text-gray-400"></svg-icon>
            <span v-text="discussion.email ? 'Email':'Text'" class="font-medium"></span>
          </p>
        </div>
        <div>
          <tag-with-icon
              v-if="!discussion.closed"
              class="mr-2"
              color="loopspark"
              icon="exclamation">
            Action
            needed
          </tag-with-icon>
          <tag-with-icon
              v-if="discussion.bounced"
              class="mr-2"
              color="red"
              icon="exclamation">
            Bounced
          </tag-with-icon>

          <tag-with-icon
              :color="discussion.delivered ? 'green': 'gray'"
              class="mr-2"
              icon="delivered"
              :class="{'opacity-50': !discussion.delivered}">
            Delivered
          </tag-with-icon>

          <tag-with-icon
              v-if="discussion.email"
              :color="discussion.opened ? 'green': 'gray'"
              class="mr-2"
              icon="email"
              :class="{'opacity-50': !discussion.opened}">
            Opened
          </tag-with-icon>

          <tag-with-icon
              v-if="discussion.email"
              :color="discussion.clicked ? 'green': 'gray'"
              class="mr-2"
              icon="click"
              :class="{'opacity-50': !discussion.clicked}">
            Clicked
          </tag-with-icon>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script lang="ts" setup>

import {QLClient, QLDiscussion} from "@/graphql/queries/ql/composables";
import TimeAgo                  from "@/components/layout/TimeAgo.vue";
import TagWithIcon              from "@/components/layout/TagWithIcon.vue";
import {useRouter}              from "vue-router";
import {computed, toRef}        from "vue";
import routesList               from "@/composables/routesList";
import SvgIcon                  from "@/components/SvgIcon.vue";
import MessageDecorator         from "@/composables/decorators/MessageDecorator";

interface Props {
  client: QLClient;
  discussion: QLDiscussion;
}

const props = withDefaults(defineProps<Props>(), {});

const discussion = toRef(props, "discussion");
const client = toRef(props, "client");

const decorator = MessageDecorator(discussion.value.last_message, discussion.value);
const messageText = computed(() => {
  return decorator.computed.messageText;
});

const messageSenderName = computed(() => {
  return decorator.computed.senderName;
});

const router = useRouter();
const {routeList} = routesList();

</script>
