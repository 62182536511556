<template>
  <div
      class="w-full bg-gray-50 border rounded"
      tabindex="-1">
    <div class="flex">
      <div class="bg-gray-100 border-r border-gray-200 w-7 overflow-hidden draggable-handle hover:cursor-move">
        <div class="flex items-center justify-center h-full">
          <svg-icon
              name="dragorder"
              class="w-4 text-gray-400" />
        </div>
      </div>

      <div class="pl-2 py-2 flex-1 flex items-center grow ">
        <svg-icon
            :name="icon"
            class="w-4" />
        <button
            type="button"
            class="pl-2 hover:text-blue-600 inline cursor-pointer"
            @click="sideOpen = true">
          {{ fieldModel.title }}
        </button>
      </div>
      <div class="px-2 pr-3 flex items-center space-x-2">
        <form-field-size-selector v-model:size="sizeModel" />

        <button
            type="button"
            class="w-4 text-gray-400 hover:text-red-800"
            @click="deleteOpen = true">
          <svg-icon name="trash" />
        </button>
      </div>
    </div>
    <ConfirmModal
        v-model:open="deleteOpen"
        type="error"
        title="Delete this field?"
        @clicked="deleted">
      <template #text>
        Are you sure you want to delete the field named {{ fieldModel.title }}?
      </template>
    </ConfirmModal>

    <component
        v-if="fieldModel"
        :is="componentType"
        v-model:open="sideOpen"
        v-model:field="fieldModel"
        :use-mappings="useMappings"
        :other-fields="otherFields" />
  </div>
</template>
<script lang="ts" setup>
import {QLFieldTypeEnum, QLFormField}      from "@/graphql/queries/ql/composables";
import {computed, ref, useAttrs, useSlots} from "vue";
import SvgIcon                             from "@/components/SvgIcon.vue";
import FormFieldSizeSelector               from "@/components/Forms/FormFieldSizeSelector.vue";
import ConfirmModal                        from "@/components/layout/ConfirmModal.vue";
import InputFieldsFunctions                from "@/components/Forms/Fields/Input/InputFieldsFunctions";

interface Props {
  field: QLFormField;
  otherFields: QLFormField[];
  useMappings: boolean;

}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["delete", "update:field"]);

const fieldModel = computed({
                              get: () => props.field,
                              set: (val) => emit("update:field", val)
                            });

const sizeModel = computed({
                             get: () => props.field.size,
                             set: (val) => {
                               console.log("AA");
                               fieldModel.value.size = val;
                             }
                           });

const fieldTypes = QLFieldTypeEnum;

const deleteOpen = ref(false);

const sideOpen = ref(false);

const deleted = () => {
  deleteOpen.value = false;
  emit("delete");
};

const {componentType} = InputFieldsFunctions.createComponentTypeFunction(fieldModel);

const icon = computed(() => {
  return InputFieldsFunctions.iconForField(fieldModel.value.kind);
});

</script>
