<template>
  <div class="inline-block rounded-full overflow-hidden">
    <div
        v-if="!isImage"
        ref="textcontainer"
        class="flex w-full h-full content-center items-center justify-center overflow-hidden"
        :style="userDivStyle">
      <span>{{ userInitials }}</span>
    </div>

    <div
        v-if="isImage"
        class="block w-full h-full">
      <img
          :alt="username && username.length > 0 ? username : '' "
          :src="image"
          class="rounded-full object-cover h-full w-full">
    </div>
  </div>
</template>
<script lang="ts">
import {computed, onMounted, ref, toRefs} from "vue";

export default {
  props: {
    username: {
      type:     String,
      required: false
    },
    image:    {
      type:     String,
      required: false
    }
  },
  setup(props) {
    const {username, image} = toRefs(props);

    const textcontainer = ref(null);

    const backgroundColors = [
      "#F44336", "#FF4081", "#9C27B0", "#673AB7",
      "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688",
      "#4CAF50", "#8BC34A", "#CDDC39", /* '#FFEB3B' , */ "#FFC107",
      "#FF9800", "#FF5722", "#795548", "#9E9E9E", "#607D8B"
    ];

    const containerSize = ref(0);

    const isImage = computed(() => image.value && image.value.length > 0);

    const userInitials = computed(() => {
      let parts = username.value.split(/[ -]/);
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        initials += parts[i].charAt(0);
      }
      if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, "");
      }
      initials = initials.substr(0, 3).toUpperCase();
      return initials;
    });

    const backgroundColor = computed(() => {
      let seed = username.value ? username.value.length : 0;
      return backgroundColors[seed % backgroundColors.length];
    });

    const lightenColor = function (hex: string, amt: number) {
      // From https://css-tricks.com/snippets/javascript/lighten-darken-color/
      let usePound = false;
      if (hex[0] === "#") {
        hex = hex.slice(1);
        usePound = true;
      }
      const num = parseInt(hex, 16);
      let r = (num >> 16) + amt;
      if (r > 255) {
        r = 255;
      } else if (r < 0) {
        r = 0;
      }
      let b = ((num >> 8) & 0x00FF) + amt;
      if (b > 255) {
        b = 255;
      } else if (b < 0) {
        b = 0;
      }
      let g = (num & 0x0000FF) + amt;
      if (g > 255) {
        g = 255;
      } else if (g < 0) {
        g = 0;
      }
      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    };

    const userDivStyle = computed(() => {
      return {
        "background-color": backgroundColor.value,
        "color":            lightenColor(backgroundColor.value, 80),
        "font-size":        containerSize.value > 0 ? containerSize.value / 2 + "px" : "1rem"
      };
    });

    const calculateContainerSize = function () {
      if (!textcontainer.value) {
        return 0;
      }
      containerSize.value = (textcontainer.value as HTMLElement).clientHeight;
    };

    onMounted(() => {
      calculateContainerSize();
    });

    return {
      userInitials,
      isImage,
      userDivStyle,
      textcontainer
    };
  }
};
</script>
