<template>

  <side-panel
      v-model:open="openModel"
      :closable="!sending">
    <template #header>
      Virtual Settings for this Event
    </template>
    <div class="p-4 h-full">
      <form
          ref="form"
          @submit.prevent="send">
        <div class="h-full space-y-6">
          <RadioGroup v-model="formData.is_virtual">
            <RadioGroupLabel class="text-base font-medium text-gray-700">
              Event type
            </RadioGroupLabel>

            <div class="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              <RadioGroupOption as="template"
                                v-for="mailingList in mailingLists"
                                :key="mailingList.id"
                                :value="mailingList.isVirtual"
                                v-slot="{ checked, active }">
                <div :class="[checked ? 'border-transparent' : 'border-gray-300', active ? 'ring-2 ring-indigo-500' : '', 'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none']">
                  <div class="flex-1 flex">
                    <div class="flex flex-col">
                      <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">
                        {{ mailingList.title }}
                      </RadioGroupLabel>
                      <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">
                        {{ mailingList.description }}
                      </RadioGroupDescription>
                    </div>
                  </div>
                  <svg-icon name="check"
                            :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']"
                            aria-hidden="true" />
                  <div :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']"
                       aria-hidden="true" />
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
          <div v-if="formData.is_virtual">
            <text-input
                v-model="formData.virtual_url"
                :error-message="errors.get('virtual_url')"
                class="form-input-default"
                label="Streaming URL *"
                placeholder="ex: https://zoom.com/myvirtualclass"
                required
                type="url" />
          </div>
          <div v-if="formData.is_virtual">
            <text-input
                v-model="formData.virtual_id"
                class="form-input-default"
                label="Streaming ID"
                placeholder="" />
          </div>
          <div v-if="formData.is_virtual">
            <text-input
                v-model="formData.virtual_password"
                class="form-input-default"
                label="Streaming Password"
                placeholder="" />
          </div>
          <div v-if="formData.is_virtual">
            <text-area-input
                v-model="formData.virtual_info"
                :rows="5"
                class="form-input-default"
                label="Streaming Info"
                placeholder="" />
          </div>
        </div>
      </form>
    </div>

    <template #footer>
      <div
          v-if="!sending"
          class="flex m-2 justify-between w-full">
        <button
            class="btn btn-white"
            @click="openModel = false">
          Cancel
        </button>

        <button
            class="btn btn-green"
            type="submit" @click.prevent="send()">
          Save
        </button>
      </div>
      <div
          v-if="sending"
          class="flex m-2 justify-center">
        <div class="h-8 w-8">
          <loader />
        </div>
      </div>
    </template>
  </side-panel>

</template>
<script lang="ts" setup>

import SidePanel                                                                               from "@/components/layout/SidePanel.vue";
import Loader                                                                                  from "@/components/layout/Loader.vue";
import TextInput                                                                               from "@/components/layout/Forms/TextInput.vue";
import TextAreaInput                                                                           from "@/components/layout/Forms/TextAreaInput.vue";
import {computed, onMounted, reactive, ref, toRef, useAttrs, useSlots, watch}                  from "vue";
import {QLSingleClassFragmentFragment, QLUpdateClassMutationVariables, useUpdateClassMutation} from "@/graphql/queries/ql/composables";
import SparkFormErrors                                                                         from "@/classes/utils/SparkFormErrors";
import SvgIcon                                                                                 from "@/components/SvgIcon.vue";
import {RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption}                  from "@headlessui/vue";
import $toast                                                                                  from "@/composables/toast";
import fillValidationErrorsOrToast                                                             from "@/composables/queries/mutations/fillValidationErrorsOrToast";

const mailingLists = [
  {id: 1, isVirtual: false, title: "In person", description: "This event is hosted at your studio location"},
  {id: 2, isVirtual: true, title: "Virtual Event", description: "This event is a virtual event"}
];

interface Props {
  singleEvent: QLSingleClassFragmentFragment;
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});
const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open", "updated"]);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const form = ref<HTMLFormElement>(null);
const errorsData = ref({});
const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});
const sending = ref(false);

const formData = reactive({
                            is_virtual:       false,
                            virtual_url:      "",
                            virtual_id:       "",
                            virtual_info:     "",
                            virtual_password: ""
                          });

const singleEvent = toRef(props, "singleEvent");

const refreshFromData = () => {

  formData.is_virtual = singleEvent.value.is_virtual;
  if (formData.is_virtual) {
    formData.virtual_url = singleEvent.value.virtual_url;
    formData.virtual_id = singleEvent.value.virtual_data.virtual_id;
    formData.virtual_info = singleEvent.value.virtual_data.virtual_info;
    formData.virtual_password = singleEvent.value.virtual_data.virtual_password;
  } else {
    formData.virtual_url = null;
    formData.virtual_id = null;
    formData.virtual_info = null;
    formData.virtual_password = null;
  }
};

onMounted(() => {
  if(!sending.value) {
    refreshFromData();
  }
});

watch(openModel, refreshFromData);
watch(singleEvent, refreshFromData);

const virtualMutation = useUpdateClassMutation({});

virtualMutation.onDone(() => {
  sending.value = false;
  $toast({
           type:         "success",
           message:      "Event updated",
           linkRouteObj: null,
           linkText:     null
         });
  emit("updated");
  openModel.value = false;
});

virtualMutation.onError((err) => {
  sending.value = false;
  fillValidationErrorsOrToast(err, errorsData, "Error while updating the event. Please try again!");

});

const send = () => {
  errorsData.value = {};
  if (!form.value.reportValidity() || sending.value) {
    return;
  }
  sending.value = true;

  const mutationData: QLUpdateClassMutationVariables = {
    id:           singleEvent.value.id.toString(),
    is_virtual:   formData.is_virtual,
    virtual_url:  formData.is_virtual ? formData.virtual_url : null,
    virtual_data: {
      virtual_id:       formData.virtual_id,
      virtual_info:     formData.virtual_info,
      virtual_password: formData.virtual_password
    }
  };
  if (!formData.is_virtual) {
    mutationData.virtual_data = {
      virtual_id:       null,
      virtual_info:     null,
      virtual_password: null
    };
  }

  virtualMutation.mutate(mutationData);
};

</script>
