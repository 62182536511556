import {reactive, Ref, ref}                from "vue";
import {QLClient, useEmailPreviewMutation} from "@/graphql/queries/ql/composables";
import $toast                              from "@/composables/toast";

export default function viewEmail() {

	const props = {
		subject: {
			type:     String,
			required: true
		},
		body:    {
			type:     String,
			required: true
		}
	};


	const setup = (props, client: Ref<QLClient | null>) => {

		const loading = ref(true);
		const finalSubject = ref("");
		const finalBody = ref("");

		const mut = useEmailPreviewMutation({});

		mut.onDone((data) => {
			finalSubject.value = data.data.EmailPreview.subject;
			finalBody.value = data.data.EmailPreview.html;
			loading.value = false;
		});

		mut.onError((e) => {
			$toast({
				       type:    "error",
				       message: "Error fetching this email",
				       sticky:  true
			       });
			loading.value = false;
		});


		const getEmailPreview = () => {
			loading.value = true;
			mut.mutate({
				           subject:   props.subject,
				           body:      props.body,
				           client_id: client.value
				                      ? <string><any>client.value.id
				                      : null
			           });
		};

		return reactive({
			                loading,
			                finalBody,
			                finalSubject,
			                getEmailPreview
		                });

	};


	return {
		props,
		setup
	};


}
