import Operator                       from "hm-filters/operators/operator";
import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import NumericOperator                from "hm-filters/operators/NumericOperator";
import ExactDateOperator              from "hm-filters/operators/ExactDateOperator";

export interface groupedDateOperator {
	firstGroup: string,
	secondGroup: string | null,
	thirdGroup: string | null,
	operator: Operator
}

export const GroupedDateFilters = [
	// is
	{
		firstGroup:  "is",
		secondGroup: "today",
		thirdGroup:  null,
		operator:    new OperatorAsFixedValueOperator("today", "today")
	},
	{
		firstGroup:  "is",
		secondGroup: "yesterday",
		thirdGroup:  null,
		operator:    new OperatorAsFixedValueOperator("yesterday", "yesterday")
	},
	{
		firstGroup:  "is",
		secondGroup: "tomorrow",
		thirdGroup:  null,
		operator:    new OperatorAsFixedValueOperator("tomorrow", "tomorrow")
	},
	{
		firstGroup:  "is",
		secondGroup: "in the past",
		thirdGroup:  null,
		operator:    new OperatorAsFixedValueOperator("past", "in the past")
	},
	{
		firstGroup:  "is",
		secondGroup: "in the future",
		thirdGroup:  null,
		operator:    new OperatorAsFixedValueOperator("future", "in the future")
	},
	{
		firstGroup:  "is",
		secondGroup: "in the last",
		thirdGroup:  "days",
		operator:    new NumericOperator("in_the_last", "in the last", "days")
	},
	{
		firstGroup:  "is",
		secondGroup: "in the next",
		thirdGroup:  "days",
		operator:    new NumericOperator("in_the_next", "in the next", "days")
	},
	{
		firstGroup:  "is",
		secondGroup: "in the last",
		thirdGroup:  "minutes",
		operator:    new NumericOperator("in_the_last_minutes", "in the last", "minutes")
	},
	{
		firstGroup:  "is",
		secondGroup: "in the next",
		thirdGroup:  "minutes",
		operator:    new NumericOperator("in_the_next_minutes", "in the next", "minutes")
	},

	// is not
	{
		firstGroup:  "is not",
		secondGroup: "today",
		thirdGroup:  null,
		operator:    new OperatorAsFixedValueOperator("not_today", "not today")
	},
	{
		firstGroup:  "is not",
		secondGroup: "in the last",
		thirdGroup:  "days",
		operator:    new NumericOperator("not_in_the_last", "not in the last", "days")
	},
	{
		firstGroup:  "is not",
		secondGroup: "in the next",
		thirdGroup:  "days",
		operator:    new NumericOperator("not_in_the_next", "not in the next", "days")
	},
	{
		firstGroup:  "is not",
		secondGroup: "in the last",
		thirdGroup:  "minutes",
		operator:    new NumericOperator("not_in_the_last_minutes", "not in the last", "minutes")
	},
	{
		firstGroup:  "is not",
		secondGroup: "in the next",
		thirdGroup:  "minutes",
		operator:    new NumericOperator("not_in_the_next_minutes", "not in the next", "minutes")
	},
	// exactly
	{
		firstGroup:  "exactly",
		secondGroup: null,
		thirdGroup:  "days ago",
		operator:    new NumericOperator("exactly_ago", "exactly", "days ago")
	},
	{
		firstGroup:  "exactly",
		secondGroup: null,
		thirdGroup:  "days from now",
		operator:    new NumericOperator("exactly_next", "exactly", "days from now")
	},
	// exactly
	{
		firstGroup:  "exactly not",
		secondGroup: null,
		thirdGroup:  "days ago",
		operator:    new NumericOperator("not_exactly_ago", "exactly not", "days ago")
	},
	{
		firstGroup:  "exactly not",
		secondGroup: null,
		thirdGroup:  "days from now",
		operator:    new NumericOperator("not_exactly_next", "exactly not", "days from now")
	},
	// before
	{
		firstGroup:  "before",
		secondGroup: null,
		thirdGroup:  null,
		operator:    new ExactDateOperator("before", "before")
	},
	// not before
	{
		firstGroup:  "not before",
		secondGroup: null,
		thirdGroup:  null,
		operator:    new ExactDateOperator("not_before", "not before")
	},
	// after
	{
		firstGroup:  "after",
		secondGroup: null,
		thirdGroup:  null,
		operator:    new ExactDateOperator("after", "after")
	},
	// not after
	{
		firstGroup:  "not after",
		secondGroup: null,
		thirdGroup:  null,
		operator:    new ExactDateOperator("not_after", "not after")
	}

];

