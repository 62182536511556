import Bugsnag                    from "@bugsnag/js";
import BugsnagPluginVue           from "@bugsnag/plugin-vue";
import {QLFragmentUserMeFragment} from "@/graphql/queries/ql/composables";

export class MyBugsnag {
	private static instance: MyBugsnag;

	private constructor() {
		const bugConf = {
			apiKey:               "40fd249da1581b85f2d7576dcb6a9654",
			plugins:              [new BugsnagPluginVue()],
			enabledReleaseStages: ["production"],
			releaseStage:         import.meta.env.MODE.toString(),
			appVersion:           import.meta.env.VITE_APP_VERSION.toString()
		};
		Bugsnag.start(bugConf);
	}

	public static getInstance(): MyBugsnag {
		if (!MyBugsnag.instance) {
			MyBugsnag.instance = new MyBugsnag();
		}

		return MyBugsnag.instance;
	}

	public bugsnag() {
		return Bugsnag;
	}

	public setUser(user: QLFragmentUserMeFragment | null){
		if(user){
			Bugsnag.setUser(user.id, user.email, user.name);
		}

	}
}

//
// if (Spark.state.currentTeam && Spark.state.currentTeam.id > 0) {
// 	Bugsnag.addMetadata("Team", {
// 		name: Spark.state.currentTeam.name,
// 		id:   Spark.state.currentTeam.id
// 	});
// }
