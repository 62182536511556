import {QLAddon, QLFragmentCurrentTeamFragment} from "@/graphql/queries/ql/composables";

const findAddon = (team: QLFragmentCurrentTeamFragment, addon: string): QLAddon | null => {
	return team.addons.find((item) => item.name == addon);
};

const addonEnabled = (team: QLFragmentCurrentTeamFragment, addon: string): boolean => {
	const found = findAddon(team, addon);
	if (found) {
		return found.active;
	}
	return false;
};

const addonCancellable = (team: QLFragmentCurrentTeamFragment, addon: string): boolean => {
	const found = findAddon(team, addon);
	if (found) {
		return found.cancellable;
	}
	return false;
};

export {
	findAddon,
	addonEnabled,
	addonCancellable
};
