<template>
  <page-title title="Create a new Automation" section="Automations" />
  <div class="container mx-auto grow">

    <automation-form-container
        v-model:automation="automation"
        v-model:errors="errors"
        :saving="saving"
        class="grow"
        @save="saveAutomation" />
  </div>
</template>
<script lang="ts" setup>
import PageTitle from "@/components/navbar/PageTitle.vue";

import {SparkErrors}                                                               from "@/classes/utils/SparkFormErrors";
import AutomationFormContainer                                                     from "@/components/automations/Create/AutomationFormContainer.vue";
import {ActiveClientFilterGenerator}                                               from "hm-filters/segment/ActiveClientFilterGenerator";
import {ref}                                                                       from "vue";
import {QLAutomationDeliveryTypeEnum, QLTodoPriority, useCreateAutomationMutation} from "@/graphql/queries/ql/composables";
import AutomationDecorator                                                         from "@/composables/decorators/AutomationDecorator";
import {fillValidationErrorsAndToast}                                              from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import {useRouter}                                                                 from "vue-router";
import $toast                                                                      from "@/composables/toast";
import routesList                                                                  from "@/composables/routesList";

const router = useRouter();

const emptyAutomation = {
  id:                        "0",
  name:                      "",
  delivery_type:             QLAutomationDeliveryTypeEnum.EMAIL,
  one_off:                   true,
  send_window_start_minutes: null,
  send_window_end_minutes:   null,
  active:                    false,
  start_at:                  null,
  segment:                   {
    id:                        "0",
    name:                      "",
    filters:                   [
      ActiveClientFilterGenerator.generate()
    ],
    is_communication:          true,
    only_deliverable_by_sms:   false,
    only_deliverable_by_email: true
  },
  subject:                   "",
  content:                   "",
  email_content:             {
    html: "",
    json: null
  },
  todo_assigned_to_id:       null,
  todo_team_group_id:        null,
  todo_tags:                 [],
  todo_overdue_hours:        null,
  todo_postponed_hours:      null,
  todo_priority:             QLTodoPriority.NONE,
  tags:                      [],
  tags_add:                  true
};

const saving = ref(false);
const automation = ref(emptyAutomation);
const errors = ref<SparkErrors>({});

const mut = useCreateAutomationMutation({});

mut.onDone((d) => {
  saving.value = false;
  const finalRoute = {
    name:   routesList().routeList.automations.show.index,
    params: {id: d.data.CreateAutomation.id}
  };
  $toast({
           type:         "success",
           message:      "The automation was created successfully",
           linkRouteObj: finalRoute,
           linkText:     "View your automation"
         });

  router.push(finalRoute);
});

mut.onError((err) => {
  fillValidationErrorsAndToast(err, errors);
  saving.value = false;
});

const saveAutomation = () => {
  saving.value = true;

  const toPost = AutomationDecorator(automation).computed.toPost;

  mut.mutate({automation: toPost});
};

defineExpose({saveAutomation});

</script>
