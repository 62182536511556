<template>
  <div>
    <div class="hourlychart" ref="chartdiv" style="width: 100%; height: 100%"></div>
  </div>
</template>
<script lang="ts" setup>

// @ts-nocheck
import * as am4core                         from "@amcharts/amcharts4/core";
import * as am4charts                       from "@amcharts/amcharts4/charts";
import am4themes_Loopspark                  from "@/components/layout/Charts/Am4Theme";
import {onMounted, onUnmounted, ref, watch} from "vue";


interface Props {
  items: any;
  itemName?: string;
  itemValue?: string;
  gap?: number;
}

const props = withDefaults(defineProps<Props>(), {
  itemName:  "start_minutes",
  itemValue: "cc",
  gap:       30
});

interface HourlyData {
  [minute: string]: number;
}

const minutes_to_time = (mins: number): string => {
  let hours = mins === 0 ? 0 : Math.floor(mins / 60);
  const minutes = (mins % 60).toString().padStart(2, "0");
  let ap = "AM";
  if (hours > 11) {
    ap = "PM";
  }
  if (hours > 12) {
    hours = hours - 12;
  }
  if (hours == 0) {
    hours = 12;
  }
  return `${hours}:${minutes} ${ap}`;
};

const emptyDatapoints = (): HourlyData => {
  let data: HourlyData = {};

  let minute = 0;
  while (minute < 60 * 24) {
    data[minute] = 0;
    minute += props.gap;
  }
  return data;
};

const generatedChartData = () => {
  let data = emptyDatapoints();

  props.items.forEach((i) => {
    data[i[props.itemName] - (i[props.itemName] % props.gap)] += i[props.itemValue];
  });

  return Object.keys(data).map((key: string) => {
    return {
      hour:  minutes_to_time(key),
      value: data[key]
    };
  });
};

let chart = null;

const updateChart = () => {
  if (chart) {
    chart.data = generatedChartData();
  }
};
watch(props, () => {
  updateChart();
});


const createTheChart = (chartdiv, chartData) => {
  am4core.unuseAllThemes();
  am4core.useTheme(am4themes_Loopspark);
  am4core.options.commercialLicense = true;

  chart = am4core.create((chartdiv.value as HTMLElement), am4charts.XYChart);
  chart.responsive.enabled = true;
  chart.responsive.useDefault = true;
  chart.padding(20, 0, 0, 0);
  chart.data = chartData;

  let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "hour";
  categoryAxis.title.text = null;
  // categoryAxis.renderer.grid.template.location = 0;
  // categoryAxis.renderer.minGridDistance = 50;
  // categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
  //   if (target.dataItem && target.dataItem.index & 2 == 2) {
  //     return dy + 25;
  //   }
  //   return dy;
  // });

  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.title.text = null;

  let series = chart.series.push(new am4charts.ColumnSeries());

  series.dataFields.valueY = "value";
  series.dataFields.categoryX = "hour";
  series.strokeWidth = 1;
  series.strokeLinejoin = "round";
  series.fillOpacity = 0.6;
  series.smoothing = "monotoneX";
  series.tooltipText = "{hour}\n {value}";

  chart.cursor = new am4charts.XYCursor();
  chart.cursor.snapToSeries = series;
  return {
    chart
  };
};

const chartdiv = ref(null);
onMounted(() => {
  let {chart: chartCreated} = createTheChart(chartdiv, generatedChartData());
  chart = chartCreated;
});

onUnmounted(() => {
  if (chart) {
    chart.dispose();
  }
});

</script>
