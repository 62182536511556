import SparkFormErrors, {SparkErrors} from "@/classes/utils/SparkFormErrors";
import QlErrorParser                  from "@/graphql/Errors/QlError";
import ValidationError                from "@/graphql/Errors/ValidationError";
import $toast                         from "@/composables/toast";
import WebNotificationData            from "@/classes/notifications/WebNotificationData";
import {Ref}                          from "vue";
import {ApolloError}                  from "@apollo/client/core";


export default function fillValidationErrorsOrToast(qlError: ApolloError,
                                                    sparkFormError: Ref<SparkErrors>,
                                                    toastMessage: string,
                                                    replaceInputFromMessage: string | null = null
) {
	if (!fillValidationErrors(qlError, sparkFormError, replaceInputFromMessage)) {
		$toast({
			       type:    "error",
			       message: toastMessage
		       } as WebNotificationData);
	}
}

const fillValidationErrors = (qlError: ApolloError,
                              sparkFormError: Ref<SparkErrors>,
                              replaceInputFromMessage: string | null = null): boolean => {
	const err = QlErrorParser.returnError(qlError);
	if (err instanceof ValidationError) {
		const sparkFormErrors = new SparkFormErrors();

		if (replaceInputFromMessage && replaceInputFromMessage.length > 0) {
			const errors = SparkFormErrors.replaceInputFromMessage(err.errors.all(), replaceInputFromMessage);
			sparkFormErrors.set(errors);
		} else {
			sparkFormErrors.set(err.errors.all());
		}

		sparkFormError.value = sparkFormErrors.all();
		return true;
	}
	return false;
};

export const fillValidationErrorsAndToast = (qlError: ApolloError, sparkFormError: Ref<SparkErrors>,
                                             withValidationMessage: string = "Some fields have some errors, please check them and resubmit",
                                             genericErrorMessage: string = "There was an error while saving. Try again",
                                             replaceInputFromMessage: string | null = null) => {
	const filled = fillValidationErrors(qlError, sparkFormError, replaceInputFromMessage);

	const toastMessage: WebNotificationData = {
		type:         "error",
		message:      genericErrorMessage,
		linkRouteObj: null,
		linkText:     null
	};
	if (filled) {
		toastMessage.message = withValidationMessage;
	}

	$toast(toastMessage);
};
