<template>
  <div>
    <select :disabled="disabled" class="form-select text-sm" name="month" v-model="selectedMonth">
      <option :value="index" v-for="(i, index) in months" v-bind:key="i">{{ i }}</option>
    </select>
    <select :disabled="disabled" class="form-select text-sm" name="day" v-model="selectedDay">
      <option :value="day" v-for="day in numDaysInMonth[selectedMonth]" v-bind:key="day">{{ day }}</option>
    </select>
  </div>
</template>
<script lang="ts" setup>

import {computed} from "vue";
import {DateTime} from "luxon";

interface Props {
  disabled?: boolean;
  modelValue: number;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
});

const emit = defineEmits(["update:modelValue"]);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
const numDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const calculateDate = (day, month) => {
  let date = DateTime.local(2019, month! + 1, day);
  return date.diff(DateTime.local(2019, 1, 1), ["days"]).days + 1;
};

const updateDate = (day, month) => {
  emit("update:modelValue", calculateDate(day, month));
};

const selectedDay = computed({
                               get: () => {
                                 if (props.modelValue === 0) {
                                   return null;
                                 }
                                 // return 1;
                                 return DateTime.local(2019, 1, 1).plus({days: props.modelValue - 1}).day;
                               },
                               set: (val) => {
                                 updateDate(val, selectedMonth.value);
                               }
                             });

const selectedMonth = computed({
                                 get: () => {
                                   if (props.modelValue === 0) {
                                     return null;
                                   }
                                   let date = DateTime.local(2019, 1, 1).plus({days: props.modelValue - 1});

                                   return date.month - 1;
                                 },
                                 set: (val) => {
                                   let day = selectedDay.value;
                                   if (val !== null) {
                                     if (day > numDaysInMonth[val]) {
                                       day = numDaysInMonth[val];
                                     }
                                     if (!day) {
                                       updateDate(1, val);
                                     } else {
                                       updateDate(day, val);
                                     }
                                   }
                                 }
                               });

</script>
