<template>
  <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
    <dt class="text-base font-medium text-gray-800">{{ bundle.is_purchased ? "Paid" : "Free" }} Bundle</dt>
    <dd class="mt-1">
      <div class="flex items-baseline justify-between md:block lg:flex">
        <div>
          <div :class="[bundle.units_left >0 ? 'text-indigo-600': 'text-red-600']"
               class="flex items-baseline text-2xl font-semibold ">
            {{ (bundle.units_left).toLocaleString(undefined) }} <span :class="[bundle.units_left >0 ? 'text-indigo-800': 'text-red-800']"
                                          class="text-lg ml-1">of {{
              (bundle.units).toLocaleString(undefined)
                                                               }}</span>
          </div>
        </div>

        <div class="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0" :class="[bundle.units_left >0 ? 'bg-green-100 text-green-800': 'bg-red-100 text-red-800']">
          {{ perc }}% used
        </div>
      </div>
      <p class="text-sm font-medium text-gray-500 mt-1.5">SMS units left <span v-if="!bundle.is_purchased">in current billing cycle</span>
      </p>
    </dd>
  </div>
</template>
<script lang="ts" setup>
import {QLFragmentSmsBundleFragment}  from "@/graphql/queries/ql/composables";
import {computed, useAttrs, useSlots} from "vue";

interface Props {
  bundle: QLFragmentSmsBundleFragment;
}

const props = defineProps<Props>();

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const perc = computed(() => {
  if (props.bundle.units_left === 100) {
    return 0;
  }

  return Math.abs(Math.round(props.bundle.units_left / props.bundle.units * 100) - 100);
});

</script>
