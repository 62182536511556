<template>
  <div class="flex flex-col min-h-full bg-loopspark-50">
    <navbar></navbar>
    <status-banners />
    <router-view />
    <ls-footer></ls-footer>
    <!--    <notifications-panel></notifications-panel>-->
    <!--    <announcements-panel></announcements-panel>-->
    <toast-manager />
    <version-was-updated-banner></version-was-updated-banner>
    <pending-invitations />
    <changed-team-notification />
    <user-firebase-notification-request></user-firebase-notification-request>
  </div>
</template>
<script lang="ts" setup>

import ToastManager                    from "@/components/layout/Toast/ToastManager.vue";
import LsFooter                        from "@/components/layout/LsFooter.vue";
import Navbar                          from "@/components/navbar/Navbar.vue";
import PendingInvitations              from "@/components/user/invitations/PendingInvitations.vue";
import ChangedTeamNotification         from "@/components/layout/ChangedTeamNotification.vue";
import StatusBanners                   from "@/components/StatusBanner/StatusBanners.vue";
import UserFirebaseNotificationRequest from "@/components/user/UserFirebaseNotificationRequest.vue";
import VersionWasUpdatedBanner         from "@/components/layout/Toast/VersionWasUpdatedBanner.vue";
import placeholdersQuery               from "@/composables/queries/placeholdersQuery";

const {placeholders} = placeholdersQuery();

</script>
