import Package         from "./partials/package";
import NumericOperator from "../operators/NumericOperator";

class PackageRemaining extends Package {
	constructor(name: string, options: any) {
		super(name, options);

		this.addOperators([
			                  new NumericOperator(">", "more than", "classes"),
			                  new NumericOperator("<", "less than", "classes"),
			                  new NumericOperator("=", "exactly", "classes")
		                  ]);
	}

	get type(): string {
		return "PackageRemainingFilter";
	}
}

export default PackageRemaining;
