import Filter from "../base-filter";

class AppointmentNameFilter extends Filter {
	path = ["Appointments"];
	smallText = "Appointment";
	group = "Appointment";


	get type(): string {
		return "AppointmentNameFilter";
	}
}

export default AppointmentNameFilter;
