<template>
    <div>
        <div class="relative">
            <div class="form-input-default w-full h-full p-0 m-0">
                <div class="flex flex-wrap items-center">

                    <div v-for="current in passedValue"
                         class="my-1 mr-2 shrink-0">
                        <div class="bg-gray-100 border border-gray-300 text-gray-900 rounded py-1 px-2 inline-flex items-center whitespace-nowrap">
                            <span>{{ current }}</span>
                            <div class="pl-1 cursor-pointer" @click="removeItem(current)">
                                <svg-icon class="w-3 h-3" name="close"></svg-icon>
                            </div>
                        </div>
                    </div>

                    <input v-model="inputText"
                           :placeholder="placeholder"
                           class="form-select text-sm leading-none grow"
                           type="text"
                           @blur="close"
                           @focus="openNow"
                           @keydown.down.prevent="keyBoard.arrowDown"
                           @keydown.up.prevent="keyBoard.arrowUp"
                           @keydown.enter.prevent="keyBoard.pressedEnter">

                </div>

            </div>

            <div v-show="open" class="absolute w-full rounded-b bg-white shadow-xl z-30">
                <ul ref="dropdown"
                    class="max-h-sm rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 text-gray-900"
                    role="listbox"
                    tabindex="-1">

                    <li v-if="itemsShown.length === 0 && !allowNew" class="py-4 px-2 text-center text-gray-900 ">
                        <span>Sorry, no matching options.</span>
                    </li>

                    <li v-for="(item, i) in itemsShown"
                        v-if="itemsShown.length > 0"
                        id="listbox-item-0"
                        class=" cursor-pointer select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-loopspark-600"
                        role="option"
                        v-bind:class="{'bg-loopspark-600': pointer === (i+1), 'text-white': pointer === (i+1), 'bg-blue-100': isSelected(item)}"
                        @mousedown="selectItem(item)">
                        <div class="flex items-center space-x-3">

              <span class="font-normal block truncate" v-bind:class="{'font-semibold': isSelected(item)}">
                <slot v-bind:item="item">{{ item }}</slot>
            </span>
                        </div>

                        <span v-if="isSelected(item)" class="absolute inset-y-0 right-0 flex items-center pr-4">
            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
              <path clip-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    fill-rule="evenodd" />
            </svg>
          </span>
                    </li>

                    <!-- More options... -->
                </ul>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import {computed, ref, toRef, watch} from "vue";
import {adjustScrollComposable}      from "@/components/layout/Forms/composables/adjustScrollComposable";
import SvgIcon                       from "@/components/SvgIcon.vue";

interface Props {
	modelValue: Array<string>,
	items: Array<any>;
	placeholder?: string;
	allowNew?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
	placeholder: "",
	allowNew:    false
});
const emit = defineEmits(["update:modelValue"]);

const dropdown = ref<HTMLUListElement>(null);

const open = ref(false);
const inputText = ref("");
const pointer = ref(0);

const passedValue = toRef(props, "modelValue");

const itemsShown = computed(() => {
	if (!inputText.value || inputText.value.length === 0) {
		return props.items;
	}
	let search = inputText.value.toLowerCase();
	let found = props.items.filter((i) => {
		return i.toLowerCase().indexOf(search) !== -1;
	});

	if (props.allowNew) {
		found = [...new Set([inputText.value, ...found])];
	}
	return found;
});

const openNow = () => {
	if (props.allowNew.value && inputText.value == "" && itemsShown.value.length === 0) {
		open.value = false;
	} else {
		open.value = true;
	}
};

const removeItem = (item: string) => {
	emit("update:modelValue", props.modelValue.filter((it) => it != item));
};
const resetPointer = () => {
	pointer.value = 0;
};

const close = () => {
	open.value = false;
	resetPointer();
};

const isSelected = (item) => {
	return passedValue.value.includes(item);
};

const caseInsensitiveTag = (newTag: string, otherTags: Array<string>) => {
	return otherTags.find((i) => i.toLowerCase() === newTag.toLowerCase()) ?? newTag;
};

const selectItem = (item: any) => {
	let newData = [];

	if (isSelected(item)) {
		// remove it if already in list
		newData = passedValue.value.filter(obj => obj !== item);
	} else {
		if (passedValue.value.includes(caseInsensitiveTag(item, props.items))) {
			// already in it
			newData = [new Set([...passedValue.value])];
		} else {
			newData = [...new Set([...passedValue.value, caseInsensitiveTag(item, props.items)])];
		}
	}

	inputText.value = "";
	open.value = false;
	resetPointer();
	emit("update:modelValue", newData);
};

const scrollStuff = adjustScrollComposable(dropdown, pointer);

const keyBoard = {
	arrowUp:      () => {
		if (!open.value) {
			open.value = true;
			pointer.value = 0;
			return;
		}
		if (pointer.value > 1) {
			pointer.value -= 1;
			scrollStuff.maybeAdjustScroll();
		}
	},
	arrowDown:    () => {
		if (!open.value) {
			open.value = true;
			pointer.value = 0;
			return;
		}
		if (pointer.value < itemsShown.value.length) {
			pointer.value += 1;
			scrollStuff.maybeAdjustScroll();
		}
	},
	pressedEnter: () => {
		if (pointer.value > 0) {
			return selectItem(itemsShown.value[pointer.value - 1]);
		}
		if (!inputText.value || inputText.value.length === 0) {
			return false;
		}
		if (props.allowNew) {
			selectItem(inputText.value);
			return;
		}
		if (pointer.value === 0) {
			// using the text, not the arrows selections
			if (props.items.filter((i) => {
				return i.toLowerCase().indexOf(inputText.value.toLowerCase()) !== -1;
			}).length > 0) {
				selectItem(inputText.value);
				open.value = false;
			}
		}
	}
};

watch(inputText, () => {
	resetPointer();
	// reopenIfTyping
	openNow();
});

</script>
