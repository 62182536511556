<template>
  <div class="container mx-auto flex justify-end">
    <div class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <svg-icon
                v-if="notification.type === 'success'"
                aria-hidden="true"
                class="h-6 w-6 text-green-400"
                name="check" />
            <svg-icon
                v-if="notification.type === 'error'"
                aria-hidden="true"
                class="h-6 w-6 text-red-500"
                name="exclamation-circle" />
          </div>
          <div class="ml-3 w-0 flex-1 ">
            <p class="text-sm font-medium text-gray-900">
              {{ notification.message }}
            </p>
            <div
                v-if="notification.linkRouteObj"
                class="mt-3 flex space-x-7">
              <router-link
                  v-if="notification.linkRouteObj"
                  :to="notification.linkRouteObj"
                  class="rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                {{ notification.linkText }}
              </router-link>
            </div>
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <button
                class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                type="button"
                @click="close()">
              <span class="sr-only">Close</span>
              <svg-icon
                  aria-hidden="true"
                  class="h-5 w-5"
                  name="closeCircle" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {useAttrs, useSlots} from "vue";
import SvgIcon              from "@/components/SvgIcon.vue";
import WebNotificationData  from "@/classes/notifications/WebNotificationData";

interface Props {
  notification: WebNotificationData;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["closed"]);
const close = () => {
  emit("closed", props.notification);
};
</script>
