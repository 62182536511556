<template>
  <div class="flex flex-col">
    <automation-form-steps
        v-model:step="currentStep"
        :delivery-type="automationModel.delivery_type"
        :saving="saving"
        :steps-status="stepsStatus"
        class="grow-0 shrink-0" />

    <div class="mt-6 grow">
      <automation-form-settings
          v-if="currentStep === 1"
          v-model:automation="automationModel"
          v-model:errors="errorsModel"
          :timezone="currentTeam ? currentTeam.timezone : 'local'" />

      <automation-form-audience
          v-if="currentStep === 2"
          v-model:segment="automationModel.segment"
          v-model:errors="errorsModel"
          :timezone="currentTeam ? currentTeam.timezone : 'local'" />

      <automation-form-sms-message
          v-if="currentStep === 3 && automationDecorated.computed.via_sms"
          v-model:content="automationModel.content"
          v-model:errors="errorsModel"
          :timezone="currentTeam ? currentTeam.timezone : 'local'" />

      <automation-form-email
          v-if="currentStep === 3 && automationDecorated.computed.via_email"
          v-model:emailContent="automationModel.email_content"
          v-model:replyTo="automationModel.reply_to"
          v-model:subject="automationModel.subject"
          v-model:errors="errorsModel"
          :timezone="currentTeam ? currentTeam.timezone : 'local'" />

      <automation-form-todo
          v-if="currentStep === 3 && automationDecorated.computed.via_todo"
          v-model:subject="automationModel.subject"
          v-model:content="automationModel.content"
          v-model:priority="automationModel.todo_priority"
          v-model:overdueHours="automationModel.todo_overdue_hours"
          v-model:postponedHours="automationModel.todo_postponed_hours"
          v-model:assignedToId="automationModel.todo_assigned_to_id"
          v-model:assignedTeamGroupId="automationModel.todo_team_group_id"
          v-model:tags="automationModel.todo_tags"
          v-model:errors="errorsModel"
          :timezone="currentTeam ? currentTeam.timezone : 'local'" />

      <automation-form-tag
          v-if="currentStep === 3 && automationDecorated.computed.via_tag"
          v-model:tags="automationModel.tags"
          v-model:tagsAdd="automationModel.tags_add"
          v-model:errors="errorsModel"
          :timezone="currentTeam ? currentTeam.timezone : 'local'" />

      <automation-form-review
          v-if="currentStep === 4"
          v-model:automation="automationModel"
          v-model:errors="errorsModel"
          :saving="saving"
          :timezone="currentTeam ? currentTeam.timezone : 'local'"
          @save="save" />
    </div>
    <automation-form-bottom-steps
        v-model:step="currentStep"
        :saving="saving"
        :steps-status="stepsStatus" />
  </div>
</template>
<script lang="ts" setup>

import AutomationFormSteps                 from "@/components/automations/Create/AutomationFormSteps.vue";
import AutomationFormSettings              from "@/components/automations/Create/AutomationFormSettings.vue";
import SparkFormErrors, {SparkErrors}      from "@/classes/utils/SparkFormErrors";
import AutomationFormAudience              from "@/components/automations/Create/AutomationFormAudience.vue";
import AutomationFormSmsMessage            from "@/components/automations/Create/Mediums/AutomationFormSmsMessage.vue";
import AutomationFormEmail                 from "@/components/automations/Create/Mediums/AutomationFormEmail.vue";
import AutomationFormTodo                  from "@/components/automations/Create/Mediums/AutomationFormTodo.vue";
import AutomationFormTag                   from "@/components/automations/Create/Mediums/AutomationFormTag.vue";
import AutomationFormReview                from "@/components/automations/Create/AutomationFormReview.vue";
import AutomationFormBottomSteps           from "@/components/automations/Create/AutomationFormBottomSteps.vue";
import {computed, ref, useAttrs, useSlots} from "vue";
import {QLAutomation}                      from "@/graphql/queries/ql/composables";
import currentTeamQuery                    from "@/composables/queries/currentTeamQuery";
import AutomationDecorator                 from "@/composables/decorators/AutomationDecorator";
import {AutomationSingleStepStatus}        from "@/components/automations/Create/AutomationStepStatus";

interface Props {
  automation: QLAutomation;
  saving: boolean;
  errors: SparkErrors;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:automation", "update:errors", "save"]);

const automationModel = computed({
                                   get: () => props.automation,
                                   set: (val) => emit("update:automation", val)
                                 });

const errorsModel = computed({
                               get: () => props.errors,
                               set: (val) => emit("update:errors", val)
                             });

const sparkErrors = computed(() => {
  return new SparkFormErrors(errorsModel.value);
});

const {currentTeam} = currentTeamQuery();

const automationDecorated = AutomationDecorator(automationModel);

const currentStep = ref(1);

const stepsStatus = computed(() => {

  const step1: AutomationSingleStepStatus = {
    step:      1,
    completed: false,
    errors:    sparkErrors.value.hasAnyOf(["name", "delivery_type", "one_off", "start_at"])
  };
  step1.completed = (!step1.errors && automationDecorated.computed.validSettings);

  const step2: AutomationSingleStepStatus = {
    step:      2,
    completed: false,
    errors:    sparkErrors.value.hasErrorFor("segment")
  };
  step2.completed = (!step2.errors && automationDecorated.computed.validSegment);

  const step3: AutomationSingleStepStatus = {
    step:      3,
    completed: false,
    errors:    sparkErrors.value.hasAnyOf([
                                            "content",
                                            "subject",
                                            "tags",
                                            "tags_add",
                                            "todo_overdue_hours",
                                            "todo_postponed_hours",
                                            "todo_priority",
                                            "todo_tags",
                                            "email_content",
                                            "email_content.json",
                                            "email_content.html",
                                            "reply_to"
                                          ])
  };

  step3.completed = (!step3.errors && (automationDecorated.computed.via_tag
                                       ? automationDecorated.computed.validTag
                                       : automationDecorated.computed.validMessage));

  return {
    step1,
    step2,
    step3
  };
});

const save = () => {
  emit("save", automationModel.value);
};

</script>
