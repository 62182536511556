<template>
  <page-title title="Virtual Events Manager" section="Events">
    <template #right>
      <div>
        <button
            type="button"
            class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-loopspark-700 bg-loopspark-100 hover:bg-loopspark-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            @click="virtualCreateOpen = true">
          <svg-icon
              name="plus"
              class="w-4 h-4" />
          <span>Add new</span>
        </button>
      </div>
    </template>
  </page-title>
  <div class="container mx-auto grow">
    <div>
      <div class="split-view-left">
        <div class="pb-4 md:pb-0 md:w-1/3">
          <!--        <div class="text-lg font-medium text-gray-900">Exclude events</div>-->
          <div class="mt-1 text-gray-600 text-sm space-y-6">
            <p>
              For studios that re-use virtual links for the same event, week after week, this feature will save you time
              by applying your virtual event settings to
              all future events of the same type.
            </p>

            <p>
              <span class="font-medium">Important:</span> If you add a new event type in your booking software OR change
                                                          the name of a current event type, you will need to update the
                                                          virtual settings for that event type on this Virtual Event
                                                          Manager.
            </p>

            <p>
              For more information, please read the
              <a
                  class="text-loopspark-600 hover:text-loopspark-900"
                  href="https://docs.loopspark.com/livestream_classes/virtual_event_manager/"
                  target="_blank">related help
                                  article</a>.
            </p>

            <p>
              To get started,
              <button
                  class="text-loopspark-600 hover:text-loopspark-900"
                  @click="virtualCreateOpen = true">
                create a new
                template!
              </button>
            </p>
          </div>
        </div>

        <div
            v-if="loading"
            class="flex-1 animate-loading">
          <div
              v-for="i in 2"
              :key="i"
              class="mb-16">
          <span class="text-lg font-medium"><loading-skeleton
              :max="12"
              :min="6"
              random-text /></span>
            <div
                v-for="b in 3"
                :key="b"
                class="py-4">
              <div class="bg-white rounded-lg shadow">
                <div class="py-2 px-4 border-b">
                  <div class="font-medium">
                    <loading-skeleton
                        class-type="inline"
                        random-text />
                  </div>
                  <div class="text-sm font-medium">
                    <loading-skeleton
                        :max="20"
                        class-type="inline"
                        random-text />
                  </div>
                </div>
                <div class="py-2 px-4">
                  <div class="text-sm flex space-x-2">
                    <loading-skeleton
                        class-type="inline"
                        text="Streaming URL:" />
                    <loading-skeleton
                        class-type="inline"
                        random-text />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
            v-if="!loading"
            class="flex-1">
          <empty v-if="!virtualClassesInfo.length">
            <template #title>
              You don't have any virtual event template yet.
            </template>
            <template #subtitle>
              <div class="pt-6">
                <button
                    class="btn btn-loopspark"
                    @click="virtualCreateOpen = true">
                  Create a new template!
                </button>
              </div>
            </template>
          </empty>

          <div
              v-for="dayToDo in daysData"
              v-show="dayToDo.data.length > 0"
              :key="dayToDo.name"
              class="pb-16">
            <div class="bg-white  sm:rounded-lg sm:shadow">
              <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  {{ dayToDo.name }}
                </h3>
              </div>

              <ul
                  role="list"
                  class="divide-y divide-gray-200">
                <single-virtual-event
                    v-for="evn in dayToDo.data"
                    :key="evn.id"
                    :virtual-event="evn"
                    class="py-4"
                    @deleted="reloadAfterDelete" />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <virtual-event-create
        v-model:open="virtualCreateOpen"
        @created="reloadAfterDelete" />
  </div>
</template>
<script lang="ts" setup>
import Empty from "@/components/layout/Empty.vue";

import SingleVirtualEvent                               from "@/components/Events/VirtualEvents/SingleVirtualEvent.vue";
import LoadingSkeleton                                  from "@/components/layout/LoadingSkeleton.vue";
import VirtualEventCreate                               from "@/components/Events/VirtualEvents/VirtualEventCreate.vue";
import {QLVirtualClassInfo, useVirtualClassesInfoQuery} from "@/graphql/queries/ql/composables";
import {useResult}                                      from "@vue/apollo-composable";
import {computed, ref}                                  from "vue";
import SvgIcon                                          from "@/components/SvgIcon.vue";
import PageTitle                                        from "@/components/navbar/PageTitle.vue";

const virtualCreateOpen = ref(false);

const virtualClassInfoQ = useVirtualClassesInfoQuery({});
const {loading} = virtualClassInfoQ;

const virtualClassesInfo = useResult(virtualClassInfoQ.result, [], data => data.virtual_classes_info);

const daysToConsider = [
  {dayId: null, name: "Any day"},
  {dayId: 0, name: "Sunday"},
  {dayId: 1, name: "Monday"},
  {dayId: 2, name: "Tuesday"},
  {dayId: 3, name: "Wednesday"},
  {dayId: 4, name: "Thursday"},
  {dayId: 5, name: "Friday"},
  {dayId: 6, name: "Saturday"}
];

const daysData = computed(() => {
  return daysToConsider.map((dConsidered) => {
    return {
      name: dConsidered.name,
      data: virtualClassesInfo.value.filter((i) => i.day_of_week === dConsidered.dayId)
                              .sort((a, b) => {
                                return (a.start_time ?? -1) > (b.start_time ?? -1) ? 1 : -1;
                              }) as Array<QLVirtualClassInfo>
    };
  });
});

const reloadAfterDelete = () => {
  virtualClassInfoQ.refetch({});
};

</script>
