<template>
  <TransitionRoot
      :show="openModel"
      as="template">
    <Dialog
        as="div"
        static
        class="fixed z-10 inset-0 overflow-y-auto"
        @close="closeButton">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
            aria-hidden="true"
            class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
        <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div
                  :class="{'bg-green-100': type === 'question', 'bg-loopspark-100': type === 'regular', 'bg-red-100': type === 'warning' || type === 'error'}"
                  class="mx-auto flex items-center justify-center h-12 w-12 rounded-full">
                <slot name="icon">
                  <!--                  <svg-icon name="check" class="h-6 w-6 text-green-600" aria-hidden="true" />-->
                  <svg-icon
                      v-if="type === 'warning' || type==='error'"
                      aria-hidden="true"
                      class="h-6 w-6 text-red-600"
                      name="exclamation" />
                  <svg-icon
                      v-if="type === 'question'"
                      aria-hidden="true"
                      class="h-6 w-6 text-green-600"
                      name="question-mark-cirlce" />

                  <svg-icon
                      v-if="type === 'regular'"
                      aria-hidden="true"
                      class="h-6 w-6 text-loopspark2-600"
                      name="question-mark-cirlce" />
                </slot>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle
                    as="h3"
                    class="text-lg leading-6 font-medium text-gray-900">
                  {{ title }}
                </DialogTitle>
                <div class="mt-2 text-gray-500 text-sm">
                  <slot name="text" />
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <v-button
                  :class="{'question': type === 'question', 'regular': type === 'regular', 'warning': type === 'warning', 'error': type === 'error'}"
                  :loading="loading"
                  class="confirmButton"
                  type="button"
                  @click="clicked">
                <slot name="button">
                  Yes
                </slot>
              </v-button>

              <button
                  ref="cancelButtonRef"
                  :class="{'disabled': loading}"
                  class="mt-3 w-full inline-flex justify-center rounded-md items-center border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  data-testid="close"
                  type="button"
                  @click="closeButton">
                {{ closeText }}
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts">
import {computed, PropType}                                                  from "vue";
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import SvgIcon                                                               from "@/components/SvgIcon.vue";
import VButton                                                               from "@/components/layout/VButton.vue";

export default {
  components: {
    SvgIcon,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    VButton
  },
  props:      {
    open:      {
      type:     Boolean,
      required: true
    },
    loading:   {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    type:      {
      type:     String as PropType<"warning" | "error" | "question" | "regular">,
      required: false,
      default:  () => "regular"
    },
    title:     {
      type:     String,
      required: false,
      default:  () => ""
    },
    closeText: {
      type:     String,
      required: false,
      default:  () => "Close"
    }
  },
  emits:      ["update:open", "clicked"],
  setup(props, {emit}) {
    const openModel = computed({
                                 get: () => props.open,
                                 set: (val) => emit("update:open", val)
                               });

    const clicked = () => {
      if (!props.loading) {
        emit("clicked");
      }
    };
    const closeButton = () => {
      if (!props.loading) {
        emit("update:open", false);
      }
    };

    return {
      openModel,
      clicked,
      closeButton
    };
  }
};

</script>
<style scoped>
.confirmButton {
  @apply w-full inline-flex justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2  sm:col-start-2 sm:text-sm;
}

.confirmButton.regular {
  @apply bg-indigo-600 hover:bg-indigo-700 text-white focus:ring-indigo-500
}

.confirmButton.question {
  @apply bg-green-600 hover:bg-green-700 text-white focus:ring-green-500
}

.confirmButton.error {
  @apply bg-red-600 hover:bg-red-700 text-white focus:ring-red-500
}

.confirmButton.warning {
  @apply bg-red-600 hover:bg-red-700 text-white focus:ring-red-500
}

.disabled {
  @apply disabled:opacity-50 disabled:cursor-not-allowed bg-gray-100
}

</style>
