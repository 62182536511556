<template>
    <router-link
            v-if="todo"
            v-slot="{navigate, href}"
            :to="{name: routeList.client.todos.show, params:{id: todo.client_id, todoId: todo.id}}"
            custom>
        <tr
                class="hover:bg-blue-50 cursor-pointer">
            <slot />
            <td
                    class=" whitespace-nowrap"
                    @click="navigate">
                <a :href="href" class="px-6 py-4 flex items-center">
                    <div class="shrink-0 h-10 w-10">
                        <client-avatar
                                :client="todo.client"
                                class="w-10 h-10" />
                    </div>
                    <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                            {{ todo.client.first_name }} {{ todo.client.last_name }}
                        </div>
                        <div class="text-sm text-gray-500">
                            <client-status-text :client="todo.client" />
                        </div>
                    </div>
                </a>
            </td>
            <td
                    @click="navigate">
                <a :href="href" class="px-6 py-4 block">
                <div class="text-sm text-gray-900">
                    {{ todo.subject }}
                </div>
                </a>
            </td>
            <td
                    class="whitespace-nowrap"
                    @click="navigate">
                <a class="flex flex-col space-y-1 px-6 py-4 " :href="href">
                    <tag-with-user
                            v-if="todo.assigned_to"
                            :user="todo.assigned_to" />
                    <tag-with-icon
                            v-if="decorator.assignedToEveryone"
                            color="green"
                            icon="users">
                        Everyone
                    </tag-with-icon>
                    <tag-with-icon
                            v-if="todo.team_group"
                            color="gray"
                            icon="users">
                        {{
                        todo.team_group.name
                        }}
                    </tag-with-icon>
                </a>
            </td>
            <td
                    class="whitespace-nowrap"
                    @click="navigate">
                <a class="flex flex-col items-start space-y-2 px-6 py-4" :href="href">
                    <div
                            v-if="decorator.isOpen && decorator.isOverduePast"
                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Overdue
                    </div>
                    <div
                            v-if="decorator.isOpen && !decorator.isPostponed && !decorator.isOverduePast"
                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                        Open
                    </div>
                    <div
                            v-if="decorator.isOpen && decorator.isPostponed"
                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800">
                        Postponed
                    </div>

                    <div
                            v-if="todo.is_closed && todo.is_completed"
                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Completed
                    </div>
                    <div
                            v-if="todo.is_closed && todo.is_deleted"
                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 opacity-50">
                        Deleted
                    </div>

                    <tag-with-icon
                            v-if="todo.priority === 'LOW'"
                            color="gray"
                            icon="thermometerLow">
                        <span class="text-xs font-semibold">Low priority</span>
                    </tag-with-icon>
                    <tag-with-icon
                            v-if="todo.priority === 'MEDIUM'"
                            color="purple"
                            icon="thermometerMed">
                        <span class="text-xs font-semibold">Medium priority</span>
                    </tag-with-icon>
                    <tag-with-icon
                            v-if="todo.priority === 'URGENT'"
                            color="loopspark"
                            icon="thermometerHigh">
                        <span class="text-xs font-semibold">Urgent</span>
                    </tag-with-icon>
                </a>
            </td>
            <td
                    @click="navigate">
                <a class="block px-6 py-4 whitespace-nowrap text-right text-sm text-gray-500" :href="href">
                    <div>
                        <timestamp-to-date
                                :timestamp="todo.created_at"
                                natural-date />
                    </div>
                    <div>
                        <timestamp-to-date
                                :has-date="false"
                                :timestamp="todo.created_at"
                                has-time />
                    </div>
                </a>
            </td>
        </tr>
    </router-link>
</template>
<script lang="ts" setup>

import TagWithIcon      from "@/components/layout/TagWithIcon.vue";
import ClientAvatar     from "@/components/client/ClientAvatar.vue";
import TimestampToDate  from "@/components/layout/TimestampToDate.vue";
import TagWithUser      from "@/components/layout/TagWithUser.vue";
import ClientStatusText from "@/components/client/ClientStatusText.vue";
import {toRef}          from "vue";
import {QLTodo}         from "@/graphql/queries/ql/composables";
import todoDecorator    from "@/composables/decorators/TodoDecorator";
import routesList       from "@/composables/routesList";

interface Props {
	todo: QLTodo;
}

const props = withDefaults(defineProps<Props>(), {});

const {routeList} = routesList();
const todo = toRef(props, "todo");

const decorator = todoDecorator(todo).computed;

</script>
