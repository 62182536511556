import Package                                            from "./partials/package";
import FixedValueOperator, {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";

class PackageActive extends Package {
	constructor(name: string, options: any) {
		super(name, options);

		this.addOperators(
			[
				new FixedValueOperator(true, "is active"),
				new FixedValueOperator(false, "is not active")
			]);
	}

	get type(): string {
		return "PackageActiveFilter";
	}
}

export default PackageActive;
