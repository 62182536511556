<template>
  <div class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-gray-100 text-gray-800">
    <span v-text="name" />
    <button
        v-if="deletable && !deleting"
        type="button"
        class="shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-red-200 hover:text-red-500 focus:outline-none focus:bg-red-500 focus:text-white"
        @click="sendDelete">
      <span class="sr-only">Remove large option</span>
      <svg-icon
          name="close"
          class=" w-3 h-3"
          icon="close" />
    </button>
    <div
        v-if="deleting"
        class="ml-1 h-3 w-3">
      <loader class="block" />
    </div>
  </div>
</template>
<script lang="ts">

import Loader            from "@/components/layout/Loader.vue";
import {defineComponent} from "vue";
import SvgIcon           from "@/components/SvgIcon.vue";

export default defineComponent(
    {
      name:       "Tag",
      components: {
        SvgIcon,
        Loader
      },
      props:      {
        name:      {
          type:     String,
          required: true,
          default:  () => ""
        },
        deletable: {
          type:     Boolean,
          required: false,
          default:  () => false
        },
        deleting:  {
          type:     Boolean,
          required: false,
          default:  () => false
        }
      },
      emits:      ["delete"],
      setup(props, {emit}) {
        const sendDelete = () => emit("delete");
        return {
          sendDelete
        };
      }
    }
);

</script>
