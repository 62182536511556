<template>
  <Popover>
    <PopoverButton class="text-loopspark-100 text-base no-underline hover:no-underline font-bold cursor-pointer flex items-center">
      <div
          v-if="currentTeam"
          class="inline-flex leading-none text-left space-x-2">
        <div class=""><img class="inline-block h-8 w-8 rounded-full" :src="currentTeam.photo_url" /></div>
        <div>
          <div class="max-w-xs truncate pb-1">{{ currentTeam.name }}</div>
          <span
              v-if="currentTeam.phone_number"
              class="text-xs opacity-50 block">{{
              displayPhone
                                               }}</span>
        </div>
      </div>
      <loader
          v-if="!currentTeam"
          class="h-8 w-8" />
      <svg-icon
          v-if="currentTeam && teams && teams.length > 1"
          class="ml-2 lg:ml-4 w-6 h-6 inline-block"
          name="down" />
    </PopoverButton>
    <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0 translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-1">
      <PopoverPanel class="absolute z-10 mt-3 transform w-screen max-w-md">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 ">
            <a
                v-for="team in withoutCurrentTeam"
                :key="team.id"
                :href="switchTeamUrl(team)"
                class="-m-3 p-2 flex items-center space-x-4 rounded-lg hover:bg-loopspark-100 transition ease-in-out duration-150 cursor-pointer">
              <div>
                <img class="inline-block h-8 w-8 rounded-full" :src="team.photo_url" />
              </div>
              <div class="space-y-1 grow">
                <p class="text-base leading-6 font-medium text-gray-900">
                  {{ team.name }}
                </p>
                <p class="text-sm leading-5 text-gray-500">
                  Switch to work station {{ team.name }}
                </p>
              </div>
              <svg-icon
                  class="shrink-0 h-6 w-6 text-indigo-600"
                  name="right" />
            </a>
            <div
                class="-m-3 p-2 flex items-center space-x-4 rounded-lg hover:bg-loopspark-100 transition ease-in-out duration-150 cursor-pointer"
                role="button"
                @click="newModal = true">
              <div class="space-y-1 grow">
                <p class="text-base leading-6 font-medium text-gray-900">
                  Create a new studio
                </p>
                <p class="text-sm leading-5 text-gray-500">
                  Integrate a new business with LoopSpark.
                </p>
              </div>
              <svg-icon
                  class="shrink-0 h-6 w-6 text-green-600"
                  name="plus" />
            </div>
          </div>
          <div
              class="">
            <div class="relative grid gap-6 bg-gray-50 px-5 py-4 sm:gap-8 sm:px-8">
              <div class="text-sm text-gray-500">
                <span>Switch your work station to another business.</span>
              </div>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
  <create-new-studio-modal v-model:open="newModal" />
</template>
<script lang="ts" setup>
import {Popover, PopoverButton, PopoverPanel}                  from "@headlessui/vue";
import {switchTeamUrl}                                         from "@/composables/switchTeamUrl";
import {QLFragmentCurrentTeamFragment, QLFragmentTeamFragment} from "@/graphql/queries/ql/composables";
import Loader                                                  from "@/components/layout/Loader.vue";
import CreateNewStudioModal
                                                               from "@/components/studio/CreateNew/CreateNewStudioModal.vue";
import {computed, ref, toRef}                                  from "vue";
import phone                                                   from "@/classes/vue-filters/PhoneFilter";
import SvgIcon                                                 from "@/components/SvgIcon.vue";

interface Props {
  currentTeam: QLFragmentCurrentTeamFragment | null;
  teams?: QLFragmentTeamFragment[];
}

const props = withDefaults(defineProps<Props>(), {});

const currentTeam = toRef(props, "currentTeam");
const teams = toRef(props, "teams");

const newModal = ref(false);

const withoutCurrentTeam = computed(() => {
  if (!currentTeam.value) {
    return [];
  }
  return teams.value.filter((item) => {
    return item.id !== currentTeam.value.id;
  });
});

function openCreateModal() {
  newModal.value = true;
}

const displayPhone = computed(() => {
  if (!currentTeam.value) {
    return "";
  }
  return phone(currentTeam.value.phone_number);
});
</script>
