<template>
  <Menu
      as="div"
      class="relative inline-block text-left">
    <div>
      <MenuButton
          v-slot="{open}"
          class="text-sm font-medium text-blue-900 inline-flex items-center">
        Merge Tags
        <svg-icon
            name="down"
            class="-mr-1 ml-1 h-5 w-5"
            :class="{'rotate-180': open}"
            aria-hidden="true" />
      </MenuButton>
    </div>
    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0">
      <MenuItems class="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="divide-y">
          <div
              v-for="(main, title) in placeholders"
              :key="title"
              class="pb-2 pt-2">
            <div class="px-4 pt-2 pb-1 text-sm font-medium text-gray-500">
              {{ title }}
            </div>
            <MenuItem
                v-for="i in main"
                :key="i.label"
                as="div">
              <button
                  class="block w-full text-left px-4 py-2 text-sm text-gray-900 hover:bg-blue-50 hover:text-blue-900"
                  @click.prevent="selectTag(i.val)">
                {{ i.label }}
              </button>
            </MenuItem>
          </div>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
<script lang="ts" setup>
// noinspection ES6UnusedImports
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";

import SvgIcon from "@/components/SvgIcon.vue";

import {useAttrs, useSlots} from "vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["tag"]);

const placeholders = {
  "Client": [
    {label: "First Name", val: "{{FIRST_NAME}}"},
    {label: "Last Name", val: "{{LAST_NAME}}"},
    {label: "Referred By", val: "{{REFERRED_BY}}"}
  ],
  "Studio": [
    {label: "Book Class URL", val: "{{STUDIO_BOOK_CLASS_URL}}"},
    {label: "List Packages URL", val: "{{STUDIO_LIST_PACKAGES_URL}}"},
    {label: "List Contracts URL", val: "{{STUDIO_LIST_CONTRACTS_URL}}"},
    {label: "Phone Number", val: "{{STUDIO_PHONE_NUMBER}}"},
    {
      label: "SMS Number",
      val:   "{{STUDIO_SMS_NUMBER}}"
    },
    {
      label: "Owner Name",
      val:   "{{STUDIO_OWNER_NAME}}"
    },
    {
      label: "Manager Name",
      val:   "{{STUDIO_MANAGER_NAME}}"
    }
  ]
};

const selectTag = (tag) => {
  emit("tag", tag);
};
</script>
