<template>
  <div>
    <div
        v-if="filterAndOperator.filter.reversedDisplay === false"
        class="space-x-1 ">
                    <span
                        v-for="filterPathName in filterAndOperator.filter.path"
                        :class="` mb-1 filter-group-tag filter-text-${cssFilterCategory} filter-group-tag-${cssFilterCategory}`">{{
                        filterPathName
                                                                                                                           }}</span>
      <strong
          :class="`filter-text-${cssFilterCategory}`"
          class="pl-1 "
          style="">{{
          filterAndOperator.filter.name
                   }}</strong>
      <span>{{ filterAndOperator.operator.text }}</span>
      <span v-if="!isFixedValue && Array.isArray(originalValues) === false">
                        <strong
                            v-if="!filterAndOperator.operator.isDate && !filterAndOperator.operator.isTime "
                            :class="`filter-value filter-text-${cssFilterCategory} filter-value-${cssFilterCategory}`">{{
                            formattedOutput
                                                                                                                       }}</strong>

                        <i v-if="filterAndOperator.operator.isDate"><timestamp-to-date
                            :timestamp="filterAndOperator.original.options.value"
                            has-date
                            studio
                            has-time /></i>
                        <i v-if="filterAndOperator.operator.isTime">{{ minutesToTime }}</i>
                    </span>

      <span
          v-if="!isFixedValue && Array.isArray(originalValues)"
          class="filter-tag-list">
                        <span
                            v-for="(opt,key,index) in originalValues"
                            :key="index">
                            <span class="filter-tag">
                                <strong :class="`filter-value filter-text-${cssFilterCategory} filter-value-${cssFilterCategory}`">{{
                                    opt
                                                                                                                                   }}</strong>
                            </span>
                            <span v-if="key !== originalValues.length - 1">&nbsp;or&nbsp;</span>
                        </span>
                    </span>
      <span v-if="filterAndOperator.operator.hasAppendText">{{ filterAndOperator.operator.appendText }}</span>
    </div>
    <div v-if="catalogVariables.length > 0 && possibleCatalogVariables.length > 0">
      <label class="form-label my-2">
        Replace with catalog:
      </label>
      <select
          class="form-select"
          @change="useCatalogVariable">
        <option value="">
          -
        </option>
        <option
            v-for="(cats,index) in possibleCatalogVariables"
            :key="index"
            :value="cats.id">
          {{
            cats.id
          }}
        </option>
      </select>
    </div>
  </div>
</template>
<script lang="ts" setup>

import {FilterWithSelectedOperator}            from "hm-filters/filterGetter";
import FixedValueOperator                      from "hm-filters/operators/FixedValueOperator";
import TimestampToDate                         from "@/components/layout/TimestampToDate.vue";
import {MinutesFromMidnightToTime}             from "@/classes/dates/MinutesFromMidnightToTime";
import {QLCatalogItemVariableFragmentFragment} from "@/graphql/queries/ql/composables";
import {computed, toRef}                       from "vue";

interface Props {
  filterAndOperator: FilterWithSelectedOperator;
  catalogVariables?: QLCatalogItemVariableFragmentFragment[];
}

const props = withDefaults(defineProps<Props>(), {
  catalogVariables: () => []
});

const catalogVariables = toRef(props, "catalogVariables");
const filterAndOperator = toRef(props, "filterAndOperator");

const emit = defineEmits(["replaceWithCatalog"]);

const isFixedValue = computed(() => {
  return filterAndOperator.value.operator instanceof FixedValueOperator;
});

const formattedOutput = computed(() => {
  return filterAndOperator.value.operator.getFormattedOutput(filterAndOperator.value.original.options);
});

const originalValues = computed(() => {
  if(isAWeekDayFilter.value){
    return formattedOutput.value;
  }
  return filterAndOperator.value.original.options.value;
});

const cssFilterCategory = computed(() => {
  return filterAndOperator.value.filter.path[0].toLowerCase();
});

const minutesToTime = computed(() => {
  return MinutesFromMidnightToTime(filterAndOperator.value.original.options.value);
});

const possibleCatalogVariables = computed(() => {
  return catalogVariables.value.filter((cat) => {
    if(cat.field_type === null) return false;
    return cat.field_type.toString() == filterAndOperator.value.operator.type || cat.field_type.toString() == filterAndOperator.value.operator.fullType;
  });
});

const useCatalogVariable = (v) => {
  if (v.target.value) {
    return emit("replaceWithCatalog", v.target.value);
  }
};

const isAWeekDayFilter = computed(() => {
  return filterAndOperator.value.original.type === 'CurrentDateInWeekFilter'|| filterAndOperator.value.original.type === 'BookingClassDayOfWeek';
});

</script>
