import ClientGroup       from "./partials/client";
import OperatorGenerator from "../operatorGenerator";

class Birthday extends ClientGroup {
	constructor(name: string, options: any) {
		super(name, options);
		this.addOperators(OperatorGenerator.datesAll(true, true, false, false));
	}

	get type(): string {
		return "Birthday";
	}
}

export default Birthday;
