import {computed, Ref}                                                    from "vue";
import {
	QLFragmentTeamFragment, QLFragmentTeamWithOwnerFragment, QLFragmentUserMeFragment, QLUserRoleEnum
} from "@/graphql/queries/ql/composables";

export default function (me: Ref<QLFragmentUserMeFragment>) {
	const teamsCopiable = computed(() => {
		if (!me.value) {
			return [];
		}

		return (<QLFragmentTeamWithOwnerFragment[]>me.value.teams).filter((t) => {
			return (t.role !== QLUserRoleEnum.MEMBER) && t.id != me.value.current_team_id;
		});
	});

	return {teamsCopiable}
}
