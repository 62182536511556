import OperatorGenerator from "../../operatorGenerator";
import Form              from "hm-filters/filters/Forms/forms";

class FormHistoryFilter extends Form {
	constructor(name: string, options: any) {
		super(name, options);

		this.setOperators(OperatorGenerator.datesPastOnly(true, false));
	}

	get type(): string {
		return "FormHistoryFilter";
	}
}

export default FormHistoryFilter;
