<template>
  <div v-html="str" class="myicon"></div>
</template>
<script lang="ts">
import {computed, PropType}       from "vue";
import {iconsSvgs, PossibleIcons} from "@/assets/icons/iconNames";

export default {
  name:  "SvgIcon",
  props: {
    name:   {
      type:     String as PropType<PossibleIcons>,
      required: true
    }
  },
  setup(props) {
    const str = computed(() => {
      return iconsSvgs[props.name];
    })
    // const symbolId = computed(() => `#${props.prefix}-${props.name}`);
    return {str};
  }
};
</script>
