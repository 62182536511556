import {QLFragmentFormSmallFragment, useArchiveFormMutation} from "@/graphql/queries/ql/composables";
import {computed, PropType, ref}                             from "vue";
import $toast                                                from "@/composables/toast";

export default function archiveUnarchiveDialog(archived: boolean, successMessage: string, errorMessage: string) {
	const props = {
		form: {
			type:     Object as PropType<QLFragmentFormSmallFragment>,
			required: true
		},
		open: {
			type:     Boolean,
			required: true
		}
	};

	const emits = ["update:open"];

	const insideSetup = (props, emit) => {
		const deleting = ref(false);

		const openModel = computed({
			                           get: () => props.open,
			                           set: (val) => {
				                           emit("update:open", val);
			                           }
		                           });

		const closeIt = () => {
			deleting.value = false;
			emit("update:open", false);
		};

// Mutation
		const mut = useArchiveFormMutation({});

		mut.onDone(() => {
			$toast({
				       type:         "success",
				       message:      successMessage,
				       linkRouteObj: null,
				       linkText:     null
			       });

			closeIt();
		});

		mut.onError(() => {
			$toast({
				       type:         "error",
				       message:      errorMessage,
				       linkRouteObj: null,
				       linkText:     null
			       });
			deleting.value = false;
		});

		const performAction = () => {
			deleting.value = true;

			mut.mutate({
				           archive: archived,
				           id: props.form.id
			           });
		};

		return {
			performAction,
			openModel,
			deleting,
			closeIt
		};
	};

	return {
		component: {
			props,
			emits
		},
		insideSetup
	};
}
