<template>
  <router-link
      :to="{name: routeList.forms.show.index, params: {id: form.id}}"
      class="p-4 hover:bg-blue-100  cursor-pointer block"
      role="link">
    <div class="flex">
      <div class="grow">
        <div>
          <h3 class="text-base text-blue-700 font-medium pb-1">
            {{ form.name }}
          </h3>
        </div>
      </div>
      <div class="ml-4">
        <div
            v-if="form.is_archived"
            class="badge-sm badge-gray font-medium opacity-50">
          Archived
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="flex items-center flex-col md:flex-row space-y-2 md:space-y-0  md:space-x-8 text-sm text-gray-700">
        <div
            v-if="form.is_legal"
            class="inline-flex items-center">
          <svg-icon
              name="pdf-duotone"
              class="shrink-0 mr-1 h-5 w-5 text-gray-400" />
          <div class="pl-1">
            Creates PDF
          </div>
        </div>
        <div
            v-if="form.allow_leads"
            class="inline-flex items-center">
          <svg-icon
              name="user-plus-duotone"
              class="shrink-0 mr-1 h-5 w-5 text-gray-400" />
          <div class="pl-1">
            Allows new clients
          </div>
        </div>
        <div
            v-if="!form.allow_leads"
            class="inline-flex items-center">
          <svg-icon
              name="users-duotone"
              class="shrink-0 mr-1 h-5 w-5 text-gray-400" />
          <div class="pl-1">
            Only existing clients
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script lang="ts" setup>
import {QLForm}   from "@/graphql/queries/ql/composables";
import routesList from "@/composables/routesList";
import SvgIcon    from "@/components/SvgIcon.vue";
import {toRef}    from "vue";

interface Props {
  form: QLForm;
}

const props = withDefaults(defineProps<Props>(), {});

const {routeList} = routesList();

const emit = defineEmits([]);
const form = toRef(props, "form");
</script>
