<template>
  <filter-group-base-category
      v-model:filterSelected="filterSelected"
      v-model:operatorSelected="operatorSelected"
      :category-name="categoryName"
      @step-back="stepBack"
      @added-rule="addedRule($event)"
  >
    <template #title>
      New Filter - Client
    </template>
    <template #subtitle>
      The Client category of filters deals with information specific to your client. This is
      mostly data that is synced from the information section of your client within your booking
      platform, but also includes a few other data points added by LoopSpark.
    </template>
    <template #side-description>
      <p>
        The Client category of filters deals with information specific to your client. This is mostly data that is
        synced from the information section of your client within your booking platform, but also includes a few other
        data points added by LoopSpark.
      </p>
    </template>
    <template #filters>
      <slot />
    </template>
  </filter-group-base-category>
</template>
<script lang="ts">
import {singleCategoryComposition} from "@/components/Segment/Edit/Categories/SingleCategories/singleCategoryComposition";

const cat = singleCategoryComposition();
export default {
  components: {
    ...cat.components
  },
  props:      {
    ...cat.props
  },
  emits:      cat.emits,
  setup(props, {emit}) {
    return cat.setup(props, emit);
  }
};
</script>
