<template>
  <v-button
      v-if="hasSaveButton"
      :disabled="saving"
      :loading="saving"
      class="btn btn-green w-full flex items-center justify-center text-xs mt-2"
      role="button"
      @click="saveNow">
    <div class="flex items-center">
      <svg-icon
          name="save-disk"
          class="w-4 h-4" />
      <span class="ml-1">Save changes</span>
    </div>
  </v-button>
  <form @submit.prevent="createNewSegment">
    <modal
        v-model:open="modalOpen"
        :loading="saving"
        close-button-text="Close">
      <div class="w-full">
        <h2 class="text-lg ">
          What is the new segment name?
        </h2>
        <div class="mt-4">
          <text-input
              v-model="newSegmentName"
              label="Segment name"
              required
              small-info="Give a name to your new segment." />
        </div>
      </div>
      <template #buttons>
        <v-button
            :disabled="saving || newSegmentName.length === 0"
            :loading="saving"
            class="modal-button modal-button-green"
            type="submit">
          Save it!
        </v-button>
      </template>
    </modal>
  </form>
</template>
<script lang="ts" setup>

import VButton                                                         from "@/components/layout/VButton.vue";
import Modal                                                           from "@/components/layout/Modal.vue";
import TextInput                                                       from "@/components/layout/Forms/TextInput.vue";
import {computed, ref, toRef, useAttrs, useSlots}                      from "vue";
import {QLSegment, useCreateSegmentMutation, useUpdateSegmentMutation} from "@/graphql/queries/ql/composables";
import SvgIcon                                                         from "@/components/SvgIcon.vue";
import SegmentDecorator                                                from "@/composables/decorators/SegmentDecorator";
import $toast                                                          from "@/composables/toast";

interface Props {
  segment: QLSegment;
  originalSegment: QLSegment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["saved"]);

const modalOpen = ref(false);
const saving = ref(false);

const segment = toRef(props, "segment");
const originalSegment = toRef(props, "originalSegment");

const newSegmentName = ref("");

const hasSaveButton = computed(() => {
  return JSON.stringify(segment.value) != JSON.stringify(originalSegment.value);
});

const decorated = computed(() => {
  return SegmentDecorator(segment).computed;
});

const saveNow = () => {
  if (!decorated.value.isStored) {
    modalOpen.value = true;
    return;
  }
  updateSegment();
};

const updateSegmentMut = useUpdateSegmentMutation({});

updateSegmentMut.onDone((res) => {
  saving.value = false;
  $toast({
           type:         "success",
           message:      "Segment updated!",
           linkRouteObj: null,
           linkText:     null
         });
});

updateSegmentMut.onError(() => {
  saving.value = false;
  $toast({
           type:         "error",
           message:      "There was a problem with your request. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const updateSegment = () => {
  saving.value = true;
  updateSegmentMut.mutate({
                            id:   segment.value.id,
                            data: decorated.value.toPost
                          });
};

const createMutation = useCreateSegmentMutation({});

createMutation.onDone((res) => {
  saving.value = false;
  $toast({
           type:         "success",
           message:      "Segment created successfully",
           linkRouteObj: null,
           linkText:     null
         });
  emit("saved", res.data.CreateSegment);
  modalOpen.value = false;
  newSegmentName.value = "";

});

createMutation.onError(() => {
  saving.value = false;
  $toast({
           type:         "error",
           message:      "There was a problem with your request. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const createNewSegment = () => {
  saving.value = true;
  const toSend = {
    ...decorated.value.toPost,
    name: newSegmentName.value
  };
  delete toSend["id"];

  createMutation.mutate({data: toSend});
};

</script>
