<template>
  <div class="container mx-auto grow">
    <div class="">
      <div class="w-full py-20 flex items-center justify-center" v-if="!client">
        <loader class="w-12 h-12"></loader>
      </div>
      <div v-if="client" class="flex flex-wrap">
        <div class="w-full md:w-1/4 mt-6">
          <client-top-info-box :client="client" class="mb-4"></client-top-info-box>
          <client-side-menu :client="client"></client-side-menu>
        </div>

        <div class="w-full md:w-3/4 mt-6">
          <div class="mx-2 md:ml-6 md:mr-0">
            <router-view :client="client"></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import ClientTopInfoBox       from "@/components/client/ClientTopInfoBox.vue";
import ClientSideMenu         from "@/components/client/ClientSideMenu.vue";
import Loader                 from "@/components/layout/Loader.vue";
import {computed, toRef}      from "vue";
import {useClientSingleQuery} from "@/graphql/queries/ql/composables";
import {echoListener}         from "@/composables/echoListener";
import {EventTypes}           from "@/classes/notifications/EventTypes";

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {});

const id = toRef(props, "id");

const clientQ = useClientSingleQuery(() => ({id: id.value}));

const client = computed(() => clientQ.result.value?.client ?? null);

const refresh = () => {
  clientQ.restart();
}

echoListener([EventTypes.BroadcastClientWasUpdated], (ev) => {
  console.debug(ev);
  if (!!client && ev.data.client_id == client.value.id) {
    clientQ.refetch();
  }
}, 5000);

</script>
