<template>
  <modal
      v-model:open="openModel"
      v-focus
      has-close-button
      quick-close
      tabindex="0"
      wide
      @close="openModel = false">
    <template #default>
      <div class="block w-full">
        <div>
          <h1 class="text-lg">
            SMS Preview
          </h1>
          <p class="text-sm text-gray-500">
            Below you can see how this SMS would look like with generic merge tags.
          </p>
        </div>

        <div class="mt-8 pt-4 mb-6">
          <div class="p-4 bubble text-white rounded-lg ">
            <automation-preview-sms
                :message-content="messageContent"
                class="automation-preview-sms" />
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>
<style>
.automation-preview-sms span {
  @apply bg-gray-200 text-gray-900 px-1 inline-block rounded;
}
</style>
<style scoped>
.bubble {
  background-color:#0381e3;
}

.preview-sms span {
  @apply bg-gray-200;
}
</style>
<script lang="ts" setup>

interface Props {
  messageContent: string;
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

import {computed, useAttrs, useSlots} from "vue";
import Modal                          from "@/components/layout/Modal.vue";
import AutomationPreviewSms           from "@/components/automations/Create/partials/AutomationPreviewSms.vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open"]);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

</script>
