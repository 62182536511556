export function adjustScrollComposable(dropdown, pointer) {
	const scrollStuff = {
		pixelsToPointerTop:    () => {
			let pixelsToPointerTop = 0;
			if (dropdown.value && (dropdown.value as HTMLUListElement).children) {
				for (let i = 0; i < (pointer.value - 1); i++) {
					pixelsToPointerTop += ((dropdown.value as HTMLUListElement).children[i] as HTMLElement).offsetHeight;
				}
			}
			return pixelsToPointerTop;
		},
		pixelsToPointerBottom: () => {
			return scrollStuff.pixelsToPointerTop() + scrollStuff.pointerHeight();
		},
		pointerHeight:         () => {
			const element = dropdown.value ? (dropdown.value as HTMLUListElement).children[(pointer.value - 1)] : false;
			return element ? (element as HTMLElement).offsetHeight : 0;
		},
		viewport:              () => {
			return {
				top:    dropdown.value ? (dropdown.value as HTMLUListElement).scrollTop : 0,
				bottom: dropdown.value
				        ? (dropdown.value as HTMLUListElement).offsetHeight + (dropdown.value as HTMLUListElement).scrollTop
				        : 0
			};
		},
		scrollTo:              (position) => {
			return dropdown.value ? (dropdown.value as HTMLUListElement).scrollTop = position : null;
		},

		maybeAdjustScroll: () => {
			const pixelsToPointerTop = scrollStuff.pixelsToPointerTop();
			const pixelsToPointerBottom = scrollStuff.pixelsToPointerBottom();
			if (pixelsToPointerTop <= scrollStuff.viewport().top) {
				return scrollStuff.scrollTo(pixelsToPointerTop);
			} else if (pixelsToPointerBottom >= scrollStuff.viewport().bottom) {
				return scrollStuff.scrollTo(scrollStuff.viewport().top + scrollStuff.pointerHeight());
			}
		}
	};

	return scrollStuff;
}
