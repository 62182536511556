import {QLFragmentUserMeFragment, useMeQuery} from "@/graphql/queries/ql/composables";
import {computed, Ref}                        from "vue";
import {WatchQueryFetchPolicy}                from "@apollo/client/core";

export default function meQuery(fetchPolicy: WatchQueryFetchPolicy = "cache-first", options: {} = {}) {
	const {result, loading, onError, onResult, refetch} = useMeQuery({fetchPolicy, ...options});

	const me = computed(() => result.value ? result.value.me : null) as Ref<QLFragmentUserMeFragment | null>;

	return {
		me,
		result,
		loading,
		onError,
		onResult,
		refetch
	};
}
