<template>
  <page-title title="Create Email Template" section="Automations" sub-text="Create branded email designs without creating full automations! Start creating your own email design library for easier, faster automation setup." />
  <div class="container mx-auto grow">

    <div>
      <router-link
          :to="{name: routeList.automations.email_templates.index}"
          class="text-link inline-flex items-center">
        <svg-icon
            name="left"
            class="w-4 h-4 inline" />
        All Email Templates
      </router-link>
    </div>

    <div class="mt-3">
      <form
          ref="form"
          class="bg-white rounded-lg shadow"
          @submit.prevent="send">
        <div
            v-if="loading"
            class="flex items-center justify-center h-64">
          <loader class="w-8 h-8 text-gray-400" />
        </div>

        <div
            v-if="!loading"
            class="p-4 space-y-4">
          <text-input
              v-model="emailAdditionalData.name"
              :error-message="errors.get('name')"
              label="Name"
              :max-length="254"
              placeholder="Ex: Monthly newsletter"
              required
              small-info="Name this email template. This name will be used when you are selecting this email template in the automations page, so try to use a descriptive name." />

          <div>
            <text-input
                v-model="emailAdditionalData.subject"
                :error-message="errors.get('subject')"
                :max-length="254"
                label="Subject"
                :placeholder="'Welcome to {{STUDIO_NAME}}'"
                small-info="Optional subject of this email template. The subject will be prefilled in the creation of automations as a starting point." />
          </div>

          <div
              v-if="emailAdditionalData.internalData.html"
              class="mt-12">
            <unlayer v-model="emailAdditionalData.internalData" />
          </div>
        </div>

        <div class="w-full flex justify-between p-4 py-4 bg-gray-100 rounded-b-lg">
          <div>
            <v-button
                :disabled="sending"
                :loading="sending"
                class="btn btn-link hover:bg-loopspark-700 text-loopspark-700 hover:text-white"
                type="button"
                @click="emailPreviewOpen = true">
              Send preview email...
            </v-button>
          </div>
          <div>
            <v-button
                :disabled="sending"
                :loading="sending"
                class="btn btn-green"
                type="submit">
              Create!
            </v-button>
          </div>
        </div>
      </form>
    </div>
    <email-preview
        v-if="emailAdditionalData.internalData.html"
        ref="emailpreview"
        v-model:open="emailPreviewOpen"
        :body="emailAdditionalData.internalData.html"
        :subject="previewSubject" />
  </div>
</template>
<script lang="ts" setup>

import TextInput                                                                from "@/components/layout/Forms/TextInput.vue";
import Unlayer                                                                  from "@/components/automations/Unlayer.vue";
import VButton                                                                  from "@/components/layout/VButton.vue";
import Loader                                                                   from "@/components/layout/Loader.vue";
import {ref, toRef, useAttrs, useSlots}                                         from "vue";
import SvgIcon                                                                  from "@/components/SvgIcon.vue";
import {QLCreateEmailTemplateMutationVariables, useCreateEmailTemplateMutation} from "@/graphql/queries/ql/composables";
import routesList                                                               from "@/composables/routesList";
import EmailPreview                                                             from "@/components/automations/EmailPreview.vue";
import {useRouter}                                                              from "vue-router";
import $toast                                                                   from "@/composables/toast";
import {fillValidationErrorsAndToast}                                           from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import {loadEmailTemplate}                                                      from "@/views/Automations/emailTemplateShowEdit";
import PageTitle                                             from "@/components/navbar/PageTitle.vue";
const {routeList} = routesList();
const router = useRouter();

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const form = ref<HTMLFormElement>(null);
const id = toRef(props, "id");

const emailPreviewOpen = ref(false);

const {
  currentTeamQ,
  currentTeam,
  emailTemplateQ,
  loading,
  emailTemplate,
  sending,
  errorsData,
  errors,
  emailAdditionalData,
  previewSubject
} = loadEmailTemplate(id);

const sendMutation = useCreateEmailTemplateMutation({});

sendMutation.onDone((data) => {
  var finalRoute = {
    name:   routeList.automations.email_templates.show,
    params: {id: data.data.CreateEmailTemplate.id}
  };
  $toast({
           type:         "success",
           message:      "Email template created!",
           linkText:     "View it",
           linkRouteObj: finalRoute
         });
  sending.value = false;
  router.push(finalRoute);
});

sendMutation.onError((err) => {
  fillValidationErrorsAndToast(err, errorsData);
  sending.value = false;
});

const send = () => {
  errorsData.value = {};
  if (!form.value.reportValidity()) {
    return;
  }

  sending.value = true;

  const data: QLCreateEmailTemplateMutationVariables = {
    email_content: {
      html: emailAdditionalData.internalData.html,
      json: JSON.stringify(emailAdditionalData.internalData.json)
    },
    name:          emailAdditionalData.name,
    subject:       emailAdditionalData.subject
  };

  sendMutation.mutate(data);
};

</script>
