import Operator        from "./operator";
import {HasInput}      from "hm-filters/operators/Bases/InputBases";
import {OperatorTypes} from "hm-filters/operators/OperatorTypes";

class NumericOperator extends Operator implements HasInput {
	hasInput: boolean = true;
	minValue?: number;
	maxValue?: number;

	get formInputType(): string {
		return "number";
	}

	get type(): string {
		return OperatorTypes.Numeric;
	}

	setMaxValue(value: number) {
		this.maxValue = value;
		return this;
	}

	setMinValue(value: number) {
		this.minValue = value;
		return this;
	}
}

export default NumericOperator;
