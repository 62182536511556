<template>
  <div>
    <svg class="w-full h-full stroke-current"
         preserveAspectRatio="xMidYMid"
         style="display: block; shape-rendering: auto; background-position: initial; background-repeat: initial;"
         viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
            fill="none"
            stroke-dasharray="42.76482137044271 42.76482137044271"
            stroke-linecap="round"
            stroke-width="8"
            style="transform:scale(0.8);transform-origin:50px 50px">
        <animate attributeName="stroke-dashoffset"
                 dur="1s"
                 keyTimes="0;1"
                 repeatCount="indefinite"
                 values="0;256.58892822265625"></animate>
      </path>
    </svg>
  </div>
</template>
<script lang="ts">
export default {
  name: "Loader"
};
</script>
