import {defineComponent}                                                 from "vue";
// import {Vue} from "vue-property-decorator";
import {QLAnnouncement, QLFragmentUserMeFragment, QLNotification, QLTag} from "@/graphql/queries/ql/composables";

export interface BusInterface {
	allTags: object[];
	lastUpdateUserData: number;
	lastUpdateUserStatus: number;
	tags?: BusTagsInterface;
	announcements: QLAnnouncement[];
	notifications: QLNotification[];
	me: QLFragmentUserMeFragment | null;
	unreadNotifications: number;
	unreadAnnouncements: number;

	[key: string]: any;
}

export interface BusTagsInterface {
	clients: QLTag[];
	todos: QLTag[];
	automations: QLTag[];
}

export interface ReceivedEventInterface {
	eventType: string;
	data: any | null;
}

export default defineComponent({
	                               name: "Bus",
	                               setup() {


	                               }
                               });
