<template>
    <form
            ref="form"
            class="block overflow-hidden shadow-md rounded-lg"
            @submit.prevent="send">
        <div class="space-y-6 bg-white p-6">
            <text-input
                    v-model="toSend.sms_sender"
                    :error-message="errors.get('input.sms_sender')"
                    label="Text message sender name"
                    placeholder="ex Yogaville - NYC"
                    small-info="This will be the name displayed in the text messages for actions like Unsubscribe/STOP" />
            <div>
                <label
                        :class="{'has-error': errors.has('input.phone_forward')}"
                        class="form-label"
                        for="">Business
                               landline</label>
                <input
                        v-model="toSend.phone_forward"
                        v-maska data-maska="+1 (###) ###-####"
                        :class="{'has-error': errors.has('input.phone_forward')}"
                        class="form-input form-input-default mt-1"
                        placeholder="ex: +1 (212) 555-1234"
                        type="tel">
                <span
                        v-if="errors.has('input.phone_forward')"
                        class="form-error-message">{{
                    errors.get("input.phone_forward")
                                                   }}</span>
                <div class="leading-tight form-small-info text-gray-600">
                    Clients calling your phone number provided by LoopSpark
                    will be redirected to the phone number listed as your
                    business landline. This number will also be passed
                    through the merge tag
                    <span class="studio-settings-merge-tag">STUDIO_PHONE_NUMBER</span>
                </div>
            </div>

            <div>
                <label
                        :class="{'has-error': errors.has('input.address')}"
                        class="form-label"
                        for="">Business
                               Address</label>
                <input
                        v-model="toSend.address"
                        :class="{'has-error': errors.has('input.address')}"
                        class="form-input form-input-default mt-1"
                        placeholder="123 Moon St,Kennedy Space Center, FL 32899">
                <span
                        v-if="errors.has('input.address')"
                        class="form-error-message">{{ errors.get("input.address") }}</span>
                <div class="leading-tight form-small-info text-gray-600">
                    Add your business address for use in merge tag
                    <span class="studio-settings-merge-tag">STUDIO_ADDRESS</span>
                </div>
            </div>

            <text-input
                    v-model="toSend.manager_name"
                    :error-message="errors.get('input.manager_name')"
                    label="Manager name"
                    placeholder="ex Audrey Hepburn">
                <div class="leading-tight form-small-info text-gray-600">
                    If your studio has a manager that is more customer
                    facing than you, add your manager’s name to personalize
                    emails using the merge tag
                    <span class="studio-settings-merge-tag">STUDIO_MANAGER_NAME</span>
                </div>
            </text-input>

            <div class="grid md:grid-cols-2 gap-6">
                <text-input
                        v-model="toSend.email_sender_name"
                        :error-message="errors.get('input.email_sender_name')"
                        label="Email sender name"
                        placeholder="ex Yogaville - NYC"
                        small-info="This will be the name displayed in the email as the sender name." />

                <text-input
                        v-model="toSend.email_sender"
                        :error-message="errors.get('input.email_sender')"
                        label="Reply to Email"
                        placeholder="ex hello@yogavillenyc.com"
                        small-info="Your clients will send their replies to this email address"
                        type="email" />
            </div>

            <text-area-input
                    v-model="toSend.email_signature"
                    :error-message="errors.get('input.email_signature')"
                    label="Email signature"
                    placeholder="ex Yogaville - NYC | 212-555-5555 | hello@yogavillenyc.com"
                    small-info="Signature appended at the end of each email sent through a client profile." />
        </div>
        <div class="bg-gray-100">
            <div class="flex justify-end p-6 py-3">
                <v-button
                        :loading="sending"
                        class="btn btn-green text-sm font-normal"
                        type="submit">
                    Update settings
                </v-button>
            </div>
        </div>
    </form>
</template>
<script lang="ts" setup>
import {onMounted, ref, watch}         from "vue";
import TextInput                       from "@/components/layout/Forms/TextInput.vue";
import VButton                         from "@/components/layout/VButton.vue";
import studioSettingsUpdate            from "@/components/studio/settings/studioSettingsUpdate";
import TextAreaInput                   from "@/components/layout/Forms/TextAreaInput.vue";
import {Mask}                          from "maska";
import {QLFragmentCurrentTeamFragment} from "@/graphql/queries/ql/composables";

interface Props {
	currentTeam: QLFragmentCurrentTeamFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const {
	currentTeam,
	form,
	errors,
	sending,
	sendMutation
} = studioSettingsUpdate(props);

const {toSend, loadFromQL} = fieldsAndLoading(currentTeam);

onMounted(() => {
	sending.value = false;
	loadFromQL();
});

const send = () => {
	const vars = {
		name: currentTeam.value.name,
		...toSend.value,
		phone_forward: toSend.value.phone_forward ? toSend.value.phone_forward.replace(/[^0-9]/g, ""): ''
	};
	sendMutation(vars);
};

const masked = (e) => {
	// console.log(e.target.dataset.maskRawValue);
};

function fieldsAndLoading(currentTeam) {
	const toSend = ref({
		                   address:           "",
		                   sms_sender:        "",
		                   phone_forward:     "",
		                   manager_name:      "",
		                   email_sender_name: "",
		                   email_sender:      "",
		                   email_signature:   ""
	                   });

	const loadFromQL = () => {
		let {
			sms_sender,
			phone_forward,
			manager_name,
			email_signature,
			email_sender,
			email_sender_name,
			address
		} = currentTeam.value;

		Object.assign(toSend.value,
		              {
			              sms_sender,
			              address,
			              phone_forward,
			              manager_name,
			              email_signature,
			              email_sender,
			              email_sender_name
		              });

		if (toSend.value.phone_forward) {
			const m = new Mask({mask: "+1 (###) ###-####"});
			toSend.value.phone_forward = m.masked(toSend.value.phone_forward);
		}

	};

	watch(currentTeam, loadFromQL);

	return {
		toSend, loadFromQL
	};
}
</script>
