<template>
  <div class="bg-white rounded-lg shadow-md">
    <div class="flex items-center justify-between py-3 px-6">
      <div>
        <span class="text-lg font-medium text-gray-800">Week {{ daysInfo.weekNumber }}</span>
        <span class="ml-3 text-sm text-gray-600 font-normal">
          <timestamp-to-date
              :timestamp="daysInfo.firstDay.toSeconds()"
              natural-date /> -
          <timestamp-to-date
              :timestamp="daysInfo.lastDay.toSeconds()"
              natural-date /></span>
      </div>

      <div class="flex items-center">
        <div class="pr-4">
          <toggle-checkbox
              v-model="includeCancelled"
              label="Include cancelled">
<span
    class="text-xs ml-2 text-gray-500"
    v-text="includeCancelled ? 'Including cancelled': 'Excluding cancelled'" />
          </toggle-checkbox>
        </div>
        <div class="border rounded-lg flex divide-x overflow-hidden">
          <button
              class="px-1 py-1 block hover:bg-loopspark-500 hover:text-white"
              @click="previousTimeframe">
            <svg-icon
                name="left"
                class="w-4 h-4" />
          </button>
          <button
              class="px-1 py-1 block hover:bg-loopspark-500 hover:text-white"
              @click="nextTimeframe">
            <svg-icon
                name="right"
                class="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-7 divide-y md:divide-y-0 md:divide-x border-t">
      <div
          v-for="day in daysList"
          class="px-2 flex flex-col">
        <div class="py-1 w-full text-center grow-0 shrink-0">
          <div
              class=" inline-block px-2 py-1 rounded-full text-gray-600 text-sm uppercase tracking-wide"
              :class="{'bg-green-100': day.today, 'text-green-700': day.today}">
            <span class="font-bold">{{ day.day.toFormat("ccc") }}</span> {{ day.day.toFormat("d") }}
          </div>
        </div>
        <div class="divide-y divide-gray-100 grow">
          <div v-if="calendar.length > 0">
            <single-event-in-calendar
                v-for="singleEvent in eventsForDay(day.day)"
                :key="singleEvent.id"
                :single-event="singleEvent" />
          </div>
          <div
              v-if="!loading && eventsForDay(day.day).length === 0"
              class="flex h-full flex-col items-center justify-center py-20">
            <span class="text-gray-400 text-sm">No events scheduled</span>
          </div>

          <div v-if="calendar.length === 0&& loading">
            <div
                v-for="fakeOne in Math.round(10 * Math.random())+1"

                :key="fakeOne"
                class="py-4 w-full overflow-hidden animate-loading">
              <div class="flex justify-between items-center">
                <div class="text-xs text-gray-800">
                  <loading-skeleton
                      class-type="inline"
                      text="4:00 PM" />
                </div>
                <div class="text-xs flex items-center space-x-1 text-gray-500">
                  <div class="w-3 h-3 loading-bg block" />
                  <loading-skeleton
                      class-type="inline"
                      text="1" />
                </div>
              </div>
              <div class="">
                <div class="block font-medium text-sm text-loopspark-700 hover:text-loopspark-500">
                  <loading-skeleton
                      class-type="inline"
                      random-text />
                </div>
                <div class="block text-gray-500 text-xs">
                  <loading-skeleton
                      class-type="inline"
                      text="Group class" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {DateTime}            from "luxon";
import TimestampToDate       from "@/components/layout/TimestampToDate.vue";
import SingleEventInCalendar from "@/components/Events/Calendar/SingleEventInCalendar.vue";
import LoadingSkeleton       from "@/components/layout/LoadingSkeleton.vue";
import ToggleCheckbox        from "@/components/layout/Forms/ToggleCheckbox.vue";

import {computed, ref, toRef, useAttrs, useSlots}      from "vue";
import {QLCalendarQuery, QLLocation, useCalendarQuery} from "@/graphql/queries/ql/composables";
import SvgIcon                                         from "@/components/SvgIcon.vue";
import {useResult}                                     from "@vue/apollo-composable";

interface CalendarDay {
  day: DateTime,
  today: boolean,
}

interface Props {
  location: QLLocation;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const location = toRef(props, "location");

const includeCancelled = ref(false);

const week = ref<DateTime>(DateTime.local().startOf("week"));

const daysInfo = computed(() => {
  return {
    weekNumber: week.value.toFormat("W"),
    firstDay:   week.value.startOf("week"),
    lastDay:    week.value.endOf("week")
  };
});

const {result, loading} = useCalendarQuery(() => {
  return {
    location_id: location.value.id,
    after:       Math.round(daysInfo.value.firstDay.startOf("day").toSeconds()),
    before:      Math.round(daysInfo.value.lastDay.endOf("day").toSeconds())
  };
});

const calendar = useResult(result, [], data => data.calendar);

const previousTimeframe = () => {
  week.value = week.value.minus({"week": 1}).startOf("week");
};

const nextTimeframe = () => {
  week.value = week.value.plus({"week": 1}).startOf("week");
};

const eventsForDay = (day: DateTime): QLCalendarQuery["calendar"] => {
  return calendar.value.filter((ev) => {
    if (!includeCancelled.value && ev.cancelled) {
      return false;
    }

    return day.hasSame(DateTime.fromSeconds(ev.start_time), "day");
  }).sort((a, b) => a.start_time > b.start_time ? 1 : -1);
};

const daysList = computed((): CalendarDay[] => {
  let today = DateTime.local().startOf("day");

  let days: CalendarDay[] = [];
  for (let i = 0; i < 7; i++) {
    let day = daysInfo.value.firstDay.plus({days: i});

    days.push({
                day:   day,
                today: day.hasSame(today, "day")
              });
  }
  return days;
});

</script>
