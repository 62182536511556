import ClientGroup       from "./partials/client";
import OperatorGenerator from "../operatorGenerator";

class ClientFirstEventFilter extends ClientGroup {
	constructor(name: string, options: any) {
		super(name, options);
		this.addOperators(OperatorGenerator.datesAll(true, true, false));
	}

	get type(): string {
		return "ClientFirstEventFilter";
	}
}

export default ClientFirstEventFilter;
