<template>
  <div class="block shadow-md rounded-lg">
    <div
        class="bg-white p-4 rounded-t-lg"
        :class="{'rounded-b-lg': !hasEdits}">
      <div class="mb-2">
        {{ user.name }}
      </div>

      <smart-select-tags
          v-model="editedGroups"
          :items="available"
          :allow-new="canAddTags"
          placeholder="Add group..." />
    </div>

    <div
        v-show="hasEdits"
        class="bg-gray-100 rounded-b-lg flex justify-end px-4 py-2">
      <div>
        <v-button
            :disabled="saving"
            :loading="saving"
            class="btn btn-green text-sm py-2"
            @click="save">
          Save
        </v-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import {QLTeamGroup, QLUser, useUpdateUserTeamGroupsMutation}       from "@/graphql/queries/ql/composables";
import SmartSelectTags                                              from "@/components/layout/Forms/SmartSelectTags.vue";
import VButton                                                      from "@/components/layout/VButton.vue";
import {computed, onMounted, ref, toRef, useAttrs, useSlots, watch} from "vue";
import $toast                                                       from "@/composables/toast";
import meQuery                                                      from "@/composables/queries/meQuery";
import currentTeamQuery                                             from "@/composables/queries/currentTeamQuery";
import useCanAddTags                                                from "@/composables/useCanAddTags";

interface Props {
  user: QLUser;
  groups: QLTeamGroup[];
  availableGroups: QLTeamGroup[];
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const user = toRef(props, "user");
const groups = toRef(props, "groups");
const availableGroups = toRef(props, "availableGroups");

const editedGroups = ref([]);
const saving = ref(false);

const reloadGroups = () => {
  editedGroups.value = groups.value.map((i) => i.name);
};

onMounted(() => reloadGroups());
watch(groups, () => {
  reloadGroups();
});

const available = computed(() => {
  return availableGroups.value.map((item) => {
    return item.name;
  });
});

const hasEdits = computed(() => {
  let original = groups.value.map((i) => i.name);

  return !(original.length === editedGroups.value.length && original.sort().every((value, index) => {
    return value === editedGroups.value.sort()[index];
  }));
});

const updateUserTeamMutation = useUpdateUserTeamGroupsMutation({});

updateUserTeamMutation.onDone(() => {
  saving.value = false;
});

updateUserTeamMutation.onError(() => {
  saving.value = false;
  $toast({
           type:         "error",
           message:      "Error updating the groups for the user. Please try again!",
           linkRouteObj: null,
           linkText:     null
         });
});

const save = () => {
  saving.value = true;
  updateUserTeamMutation.mutate({
                                  id:     user.value.id,
                                  groups: editedGroups.value
                                });
};

const canAddTags = useCanAddTags();

</script>
