import {getCurrentInstance} from "vue";
import mitt, {Emitter}      from "mitt";

const emitter = mitt();

import { useEventBus } from '@vueuse/core'
/**
 * usage:
 *
 *  setup() {
    const emitter = useEmitter()
    ...
  }
 */
export default function useEmitter() : Emitter<any>{
	return emitter;
	// const internalInstance = getCurrentInstance();
	// return internalInstance.appContext.config.globalProperties.emitter;
}
