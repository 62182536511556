import {QLAutomationSegmentInput, QLSegment, QLSegmentInput} from "@/graphql/queries/ql/composables";
import {v4 as uuidv4}                                        from "uuid";
import {computed, isRef, reactive, ref, Ref}                 from "vue";

export default function SegmentDecorator(passed: Ref<QLSegment> | QLSegment) {
	const entity = isRef(passed) ? passed : ref(passed);


	const isCustom = computed(() => {
		return parseInt(entity.value.id) === 0 && entity.value.filters.length > 0 && entity.value.filters[0].filters.length > 0;
	});

	const isStored = computed(() => {
		return parseInt(entity.value.id) > 0;
	});

	const isAll = computed(() => {
		return !isStored.value && !isCustom.value;
	});

	const canBeSaved = computed(() => {
		return isValid.value;
	});


	const isValid = computed(() => {
		if (!entity.value.filters || entity.value.filters.length == 0) {
			return false;
		}

		return entity.value.filters.filter(item => item.filters.length === 0).length === 0;
	});

	const toPost = computed((): Partial<QLSegmentInput> => {
		const ret = {
			name:                      entity.value.name?.toString() === "" ? "Custom" : entity.value.name,
			id:                        entity.value.id,
			only_deliverable_by_email: !!entity.value.only_deliverable_by_email,
			only_deliverable_by_sms:   !!entity.value.only_deliverable_by_sms,
			visible_dashboard:         !!entity.value.visible_dashboard,
			filter_groups:             entity.value.filters.map((filterGroup) => {
				return {
					operator_is_and: filterGroup.operator_is_and,
					uid:             filterGroup.uid ? filterGroup.uid : uuidv4(),
					filters:         filterGroup.filters.map((fil) => {
						return {
							uid:     fil.uid ? fil.uid : uuidv4(),
							type:    fil.type,
							options: (fil.options !== null && typeof fil.options === "object")
							         ? JSON.stringify(fil.options)
							         : fil.options
						};
					})
				};
			}),
			order:                     entity.value.order,
			archived:                  entity.value.archived
		};

		return Object.keys(ret).reduce((acc, key) => {
			const _acc = acc;
			if (ret[key] !== undefined) {
				_acc[key] = ret[key];
			}
			return _acc;
		}, {});
	});

	const asAutomationInputPost = computed((): QLAutomationSegmentInput => {
		return {
			filter_groups: entity.value.filters.map((filterGroup) => {
				return {
					operator_is_and: filterGroup.operator_is_and,
					uid:             filterGroup.uid ? filterGroup.uid : uuidv4(),
					filters:         filterGroup.filters.map((fil) => {
						return {
							uid:     fil.uid ? fil.uid : uuidv4(),
							type:    fil.type,
							options: (fil.options !== null && typeof fil.options === "object")
							         ? JSON.stringify(fil.options)
							         : fil.options
						};
					})
				};
			}),
			id:            entity.value.id,
			name:          entity.value.name
		};
	});

	const refreshIds = (): Ref<QLSegment> => {

		entity.value.filters.map((filterGroup) => {
			filterGroup.uid = uuidv4();
			filterGroup.filters.map((filter) => {
				filter.uid = uuidv4();
			});
		});

		return entity;
	};

	return {
		computed: reactive({
			                   isCustom,
			                   isStored,
			                   isAll,
			                   canBeSaved,
			                   isValid,
			                   toPost,
			                   asAutomationInputPost
		                   }),
		methods:  {
			refreshIds
		}
	};

}
