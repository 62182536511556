<template>
  <div>
    <div class="flex">
      <div class="grow">
        <div>
          <h3 class="text-base text-blue-700 font-medium pb-1">
            {{ automation.name }}
          </h3>
          <p class="text-xs text-gray-500 italic">
            {{ automation.description }}
          </p>
        </div>
      </div>
      <div class="ml-4">
        <div
            v-if="automation.active"
            class="badge-sm badge-green font-medium">
          Active
        </div>
        <div
            v-if="!automation.active && decorator.isDelayed"
            class="badge-sm badge-yellow font-medium">
          Scheduled
        </div>
        <div
            v-if="!automation.active && !decorator.isDelayed"
            class="badge-sm badge-gray font-medium"
            v-text="automation.ever_ran && automation.one_off ? 'Completed': 'Off'" />
      </div>
    </div>
    <div class="mt-4">
      <div class="flex items-center flex-col md:flex-row space-y-2 md:space-y-0  md:space-x-6 text-sm text-gray-700">
        <div
            v-if="decorator.via_email"
            class="inline-flex items-center">
          <svg-icon
              name="email-solid"
              class="shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
          <div class="pl-1">
            Email
          </div>
        </div>
        <div
            v-if="decorator.via_sms"
            class="inline-flex items-center">
          <svg-icon
              name="mobile-alt"
              class="shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
          <div class="pl-1">
            SMS
          </div>
        </div>
        <div
            v-if="decorator.via_todo"
            class="inline-flex items-center">
          <svg-icon
              name="todo"
              class="shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
          <div class="pl-1">
            To-Do
          </div>
        </div>
        <div
            v-if="decorator.via_tag"
            class="inline-flex items-center">
          <svg-icon
              name="tag"
              class="shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
          <div
              class="pl-1"
              v-text="automation.tags_add ? 'Add Tag':'Remove Tag'" />
        </div>
        <div
            v-if="!automation.one_off"
            class="inline-flex items-center">
          <svg-icon
              name="repeat"
              class="shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
          <div class="pl-1">
            Continuous
          </div>
        </div>
        <div
            v-if="automation.one_off"
            class="inline-flex items-center">
          <svg-icon
              name="bullhorn"
              class="shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
          <div class="pl-1">
            One Time Only
          </div>
        </div>
        <div class="inline-flex items-center">
          <svg-icon
              name="users"
              class="shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
          <div class="pl-1">
            {{ automation.segment.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {toRef}                             from "vue";
import {QLFragmentAutomationIndexFragment} from "@/graphql/queries/ql/composables";
import routesList                          from "@/composables/routesList";
import AutomationDecorator                 from "@/composables/decorators/AutomationDecorator";
import SvgIcon                             from "@/components/SvgIcon.vue";

interface Props {
  automation: QLFragmentAutomationIndexFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const automation = toRef(props, "automation");
const {routeList} = routesList();

const {computed: decorator} = AutomationDecorator(automation);

</script>
