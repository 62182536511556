<template>
  <div>
    <timeframe-selector
        v-model:date="dateSelected"
        position="left"
        :min-date="automation.created_at"
        class="mb-4" />

    <div class="space-y-3 lg:space-y-6">
      <div class="grid lg:grid-cols-2 gap-3 lg:gap-6 grid-flow-row-dense">
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <automation-stat-box-simple :current="automationStats.created"
                                      :loading="loadingStats"
                                      :show-past="showPastData"
                                      :past="automationStatsPast.created">
            <template #icon>
              <svg-icon name="tag" class="w-5 h-5" />
            </template>
            <template #title>Tags <span v-text="automation.tags_add ? 'Assigned': 'Removed'"></span></template>
          </automation-stat-box-simple>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import TimeframeSelector                     from "@/components/layout/TimeframeSelector.vue";
import AutomationStatBoxSimple               from "@/components/layout/StatBoxSimple.vue";
import {computed, toRef, useAttrs, useSlots} from "vue";
import automationShowInfoDateSelection                               from "@/components/automations/Show/Infos/automationShowInfoDateSelection";
import {useResult}                                                   from "@vue/apollo-composable";
import SvgIcon                                                       from "@/components/SvgIcon.vue";
import {QLFragmentFullAutomationFragment, useAutomationStatTagQuery} from "@/graphql/queries/ql/composables";


interface Props {
  automation: QLFragmentFullAutomationFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const automation = toRef(props, "automation");

const infoDate = automationShowInfoDateSelection(automation);

const {showPastData, dateSelected} = infoDate;

const currentPeriod = useAutomationStatTagQuery(infoDate.queryVariablesCurrent, infoDate.queryOptionsCurrent);
const pastPeriod = useAutomationStatTagQuery(infoDate.queryVariablesPast, infoDate.queryOptionsPast);

const currentPeriodResult = useResult(currentPeriod.result);
const pastPeriodResult = useResult(pastPeriod.result);

const loadingStats = computed(() => {
  return currentPeriod.loading.value || pastPeriod.loading.value;
});

const automationStats = computed(() => {
  if (!currentPeriodResult.value) {
    return {
      created: 0
    };
  }
  return currentPeriodResult.value;
});

const automationStatsPast = computed(() => {
  if (!pastPeriodResult.value) {
    return {
      created: 0
    };
  }
  return pastPeriodResult.value;
});

</script>
