<template>
  <div>
    <div class="rounded-lg border border-gray-200 p-4">
      <div class="text-lg font-bold text-green-900">
        {{ regularPlan.plan.name }}
      </div>
      <div class="divide-y">
        <p class="py-2">
          <strong class="font-semibold">
            <currency :amount="regularPlan.plan.price" />
          </strong> / {{
            regularPlan.plan.interval === "MONTHLY"
            ? "month"
            : "year"
                    }} per location
        </p>
        <p class="py-2">
          <strong class="font-semibold">{{ regularPlan.quantity }}</strong> billed
          <span v-text="regularPlan.quantity === 1 ?'location':'locations'" />
        </p>
        <p class="py-2">
          <strong class="font-semibold">{{
              regularPlan.plan.interval === "MONTHLY"
              ? "Monthly"
              : "Yearly"
                                        }}</strong> committment
        </p>
      </div>
    </div>

    <div v-if="!regularPlan.plan.active">
      This plan has been discontinued, but you may continue your subscription to this plan as long as you wish. If you
      cancel your subscription and later want to begin a new subscription, you will need to choose from one of the
      active plans listed below.": "This plan has been discontinued, but you may continue your subscription to this plan
      as long as you wish. If you cancel your subscription and later want to begin a new subscription, you will need to
      choose from one of the active plans listed below.
    </div>

      <div
          v-if="isOnTrial"
        class="bg-yellow-100 w-full p-4 rounded mt-6">
        You are currently within your free trial period. Your trial will expire on <strong>
        <timestamp-to-date
            :has-day-name="false"
            :timestamp="mySubscription.trial_ends_at"
            format="DDD"
            natural-date />
      </strong>
      </div>

      <div
          v-if="isOnGracePeriod"
        class="bg-red-100 w-full p-4 rounded mt-6">
        <p class="pb-2">
          You have cancelled your subscription to the <strong>{{ regularPlan.plan.name }}</strong> plan.
        </p>
        <p>
          The benefits of your subscription will continue until your current billing period ends on <strong>
          <timestamp-to-date
              :has-day-name="false"
              :timestamp="mySubscription.ends_at"
              format="DDD"
              natural-date />
        </strong>. You may resume your subscription at no extra cost until the end of the billing period.
        </p>

        <div class="pt-4">
          <v-button
              :disabled="saving"
              :loading="saving"
              class="btn btn-sm btn-loopspark"
              @click="resumeSub">
            Resume my subscription
          </v-button>
        </div>
      </div>
  </div>
</template>
<script lang="ts" setup>
import {computed, ref} from "vue";
import {
  MySubscriptionDocument, QLFragmentCurrentTeamFragment, QLFragmentTeamSubscriptionFragment,
  useResumeSubscriptionMutation
}                      from "@/graphql/queries/ql/composables";
import $toast          from "@/composables/toast";
import Currency        from "@/components/layout/Currency.vue";
import VButton         from "@/components/layout/VButton.vue";
import TimestampToDate from "@/components/layout/TimestampToDate.vue";

interface Props {
  currentTeam: QLFragmentCurrentTeamFragment;
  mySubscription: QLFragmentTeamSubscriptionFragment;
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(["updated"]);
const saving = ref(false);

const isActive = computed(() => {
  return props.mySubscription.ends_at === null;
});

const isOnTrial = computed(() => {
  return props.mySubscription.trial_ends_at !== null && props.mySubscription.trial_ends_at > Math.floor(Date.now() / 1000);
});

const isOnGracePeriod = computed(() => {
  return props.mySubscription.ends_at !== null;
});

const {mutate, onError, onDone} = useResumeSubscriptionMutation(() => ({
  update: (cache, {data: {resumeSubscription}}) => {
    cache.writeQuery({query: MySubscriptionDocument, data: {my_subscription: resumeSubscription}});
  }
}));

const regularPlan = computed(() => {
  return props.mySubscription.items.find((p) => !p.plan.addon);
});

onDone(() => {
  emit("updated");
});

onError((err) => {
  $toast({
           type:         "error",
           message:      "There was an error while resuming your subscription. Please try again or contact support",
           linkRouteObj: null,
           linkText:     null
         });
});

const addons = computed(() => {
  return props.mySubscription.items.filter((p) => p.plan.addon);
});

const resumeSub = () => {
  saving.value = true;
  mutate().finally(() => saving.value = false);
};

</script>
