import Operator        from "./operator";
import {find}          from "lodash";
import {OperatorTypes} from "hm-filters/operators/OperatorTypes";

export interface MultipleChoiceOption {
	value: any,
	display?: string
}

class MultipleChoiceOperator extends Operator {
	multipleChoicesData: MultipleChoiceOption[] = [];
	hasInput = true;

	get type(): string {
		return OperatorTypes.MultipleChoice;
	}

	addMultipleChoice(displayedValue: string, value: any | null = null) {
		this.multipleChoicesData.push({
			                              "value":   value !== null ?
			                                         value : displayedValue,
			                              "display": displayedValue
		                              });
	}

	getFormattedOutput(options: MultipleChoiceOption) {
		const found = find(this.multipleChoicesData, (o) => {
			return o.value == options.value;
		});
		if (!found) {
			return options.value;
		}

		return found.display;
	}

}

export default MultipleChoiceOperator;
