import ProductType          from "hm-filters/filters/productTypeFilter";
import ProductHistoryFilter from "hm-filters/filters/productHistoryFilter";
import {ProductCountFilter} from "hm-filters/filters/ProductCountFilter";

export const ProductFilters = [
	new ProductType("Purchased", {"field": "bought"}).setDescription(
		"Product Purchased will filter users by the products they have purchased."),
	new ProductCountFilter("Count", {"field": "product_count"}).setDescription(
		"Product Count filters by the number of products a client has purchased that meet all other product filters contained in that rule."),
	new ProductHistoryFilter("Product History", {"field": ""}).setDateFilter().setDescription(
		"Product History will filter users who have or have not purchased any products in the selected timeframe.")
];
