<template>
  <dl class="relative bg-white py-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg overflow-hidden">
    <dt>
      <div class="absolute bg-loopspark-100 rounded-md p-3">
        <svg-icon
            name="users"
            class="w-5 h-5 text-loopspark-500" />
      </div>
      <p class="ml-16 text-sm font-medium text-gray-500 truncate">
        Clients visiting today
      </p>
    </dt>
    <dd class="ml-16  flex items-center">
      <div class="text-2xl font-semibold text-gray-900">
        <span v-if="!loading">{{ clientsToday }}</span>
        <div
            v-if="loading"
            class="animate-loading">
          <loading-skeleton
              class-type="inline-block"
              text="50" />
        </div>
      </div>
      <div class="ml-2 flex items-baseline text-sm text-green-800">
        <span v-if="!loading && clientsTodayFirst > 0" class="bg-green-100 rounded-full inline-block px-2 py-0.5"><span class="font-semibold">{{ clientsTodayFirst }}</span> first time</span>
        <div
            v-if="loading"
            class="animate-loading">
          <div class="font-semibold text-green-500 text-sm">
            <loading-skeleton
                class-type="inline-block"
                text="20 first time" />
          </div>
        </div>
      </div>
    </dd>
  </dl>
</template>
<script lang="ts" setup>

import LoadingSkeleton                                                                   from "@/components/layout/LoadingSkeleton.vue";
import SvgIcon                                                                           from "@/components/SvgIcon.vue";
import {computed, toRef, useAttrs, useSlots}                                             from "vue";
import {QLFragmentLocationInfoFragment, useClientsTodayFirstQuery, useClientsTodayQuery} from "@/graphql/queries/ql/composables";
import {useResult}                                                                       from "@vue/apollo-composable";

interface Props {
  location: QLFragmentLocationInfoFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const location = toRef(props, "location");

const {result: resToday, loading: loadingToday} = useClientsTodayQuery(() => ({
  location_id: location.value ? location.value.id : null
}), () => ({
  enabled: props.location !== null
}));

const {result: resTodayFirst, loading: loadingTodayFirst} = useClientsTodayFirstQuery(() => ({
  location_id: location.value ? location.value.id : null
}), () => ({
  enabled: props.location !== null
}));

const clientsToday = useResult(resToday, 0, data => data.clients_today.value);
const clientsTodayFirst = useResult(resTodayFirst, 0, data => data.clients_today_first.value);

const loading = computed(() => {
  return loadingToday.value || loadingTodayFirst.value;
});
</script>
