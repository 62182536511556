import {computed, isRef, reactive, ref, Ref}   from "vue";
import {QLFragrmentSmallEmailTemplateFragment} from "@/graphql/queries/ql/composables";
import {DecoratorInterface}                    from "@/composables/decorators/DecoratorInterface";

export default function emailTemplateDecorator(passed: Ref<QLFragrmentSmallEmailTemplateFragment> | QLFragrmentSmallEmailTemplateFragment) : DecoratorInterface {

	const entity = isRef(passed) ? passed : ref(passed);

	const isGlobal = computed<boolean>(() => {
		if (entity.value.brand_id && parseInt(entity.value.brand_id) > 0) {
			return true;
		}
		return entity.value.automation_id === null && entity.value.team_id === null;
	});

	const isPreviousEmail = computed<boolean>(() => {
		if (isGlobal.value) {
			return false;
		}

		return !!(entity.value.automation_id && parseInt(entity.value.automation_id) > 0);
	});

	const isCustomEmail = computed<boolean>(() => {
		if (isGlobal.value || isPreviousEmail.value) {
			return false;
		}

		return true;
	});


	return {
		computed: reactive({
			                   isGlobal,
			                   isPreviousEmail,
			                   isCustomEmail
		                   })
	};
}
