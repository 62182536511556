<template>
  <div aria-live="assertive" class="pointer-events-none fixed bottom-0 pb-12 inset-x-0 flex justify-center">
    <div class="flex  flex-col items-center space-y-4">
      <transition enter-active-class="transform ease-out duration-300 transition"
                  enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0">
        <div v-if="openModel"
             class="pointer-events-auto rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-10">
          <div class="p-4 ">
            <div class="flex items-start justify-between">

              <div class="">
                <p class="text-lg font-medium text-gray-900">{{ itemsCount }} automations selected</p>
                <p class="mt-1 text-sm text-gray-500">You can perform one of the following actions</p>

              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button type="button"
                        @click="openModel = false"
                        class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Close</span>
                  <SvgIcon name="close" class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div class="mt-6 flex space-x-4">

              <v-button class="btn btn-green"
                        @click="openConfirmModal(QLAutomationBulkActionEnum.RUN)"
                        :disabled="itemsCount ==0">
                    <span class="inline-flex items-center">
                      <svg-icon
                          name="play"
                          class="w-3 h-3" />

                    <span class="pl-1">Activate/Run...</span>
                  </span>
              </v-button>

              <v-button class="btn btn-white"
                        @click="openConfirmModal(QLAutomationBulkActionEnum.PAUSE)"
                        :disabled="itemsCount ==0">
                    <span class="inline-flex items-center">
                      <svg-icon
                          name="pause"
                          class="w-3 h-3" />

                    <span class="pl-1">Pause...</span>
                  </span>

              </v-button>
              <v-button class="btn btn-red"
                        @click="openConfirmModal(QLAutomationBulkActionEnum.ARCHIVE)"
                        :disabled="itemsCount ==0">
                    <span class="inline-flex items-center">
                      <svg-icon
                          name="trash"
                          class="w-3 h-3" />

                    <span class="pl-1">Archive...</span>
                  </span>

              </v-button>

            </div>
          </div>
        </div>
      </transition>
    </div>

    <confirm-modal
        v-model:open="modalOpenToggle"
        :loading="sending"
        type="warning"
        close-text="Nevermind"
        :title="modalInfoData.title"
        @clicked="informOfAction">
      <template #button>
        {{ modalInfoData.confirmButton }}
      </template>
      <template #text>
        <div v-if="modalOpen == QLAutomationBulkActionEnum.RUN" class="text-sm space-y-4 py-4">
          <p>
            You have selected several ({{ itemsCount }}) automations for activation. Here's what will happen next:
          </p>
          <ul class="text-left space-y-3">
            <li><strong>Continous Automations:</strong> If an automation is set to recur, it will be turned on and will
                                                        run as per its scheduled frequency.
            </li>
            <li><strong>Archived Automations:</strong> If an automation is archived, no action will be taken. Archived
                                                       automations will stay in their archived state and will not be
                                                       unarchived or run.
            </li>
            <li><strong>One-Off Automations:</strong> If an automation is set to run only once, no action will be taken.
                                                      This is to prevent potential errors or inaccuracies that could
                                                      occur with repeated executions.
            </li>
          </ul>
          <p>
            Please review your selections and click "Confirm" to proceed with activation or "Nevermind" to go back.
          </p>
        </div>
        <div v-if="modalOpen == QLAutomationBulkActionEnum.PAUSE" class="text-sm space-y-4 py-4">
          <p>
            You have selected several ({{ itemsCount }}) automations for pausing. Do you want to proceed and pause these
            automations immediately?
          </p>
          <p>Please note, pausing these automations will prevent them from running until they are reactivated.</p>

        </div>

        <div v-if="modalOpen == QLAutomationBulkActionEnum.ARCHIVE" class="text-sm space-y-4 py-4">
          <p>
            You have selected several ({{ itemsCount }}) automations for archiving. Are you sure you want to proceed?
          </p>
          <p>
            Archiving these automations will also pause them and they will not run until they are unarchived. Moreover,
            archived automations cannot be edited.
          </p>
          <p>
            We recommend archiving only if you are sure that these automation will not be needed in the near future.
            Archiving an automation will remove it from your automation list.
          </p>

        </div>
      </template>
    </confirm-modal>

  </div>
</template>

<script setup lang="ts">
import SvgIcon                                                           from "@/components/SvgIcon.vue";
import VButton                                                           from "@/components/layout/VButton.vue";
import {computed, reactive, ref, useAttrs, useSlots, watch, watchEffect} from "vue";
import ConfirmModal                                                      from "@/components/layout/ConfirmModal.vue";
import {QLAutomationBulkActionEnum}                                      from "@/graphql/queries/ql/composables";

interface Props {
  open: boolean;
  sending: boolean;
  itemsCount: number;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open", "perform"]);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });
const modalOpen = ref<QLAutomationBulkActionEnum | "">("");
const modalOpenToggle = ref(false);

watchEffect(() => {
  if (openModel.value == false) {
    modalOpenToggle.value = false;
  }
});

const modalInfoData = reactive({
                                 title:         "",
                                 confirmButton: ""
                               });

watch(modalOpen, () => {
  if (modalOpen.value === QLAutomationBulkActionEnum.RUN) {
    modalInfoData.title = "Activate automations?";
    modalInfoData.confirmButton = "Confirm";
  }

  if (modalOpen.value === QLAutomationBulkActionEnum.PAUSE) {
    modalInfoData.title = "Pause automations?";
    modalInfoData.confirmButton = "Yes, pause";
  }

  if (modalOpen.value === QLAutomationBulkActionEnum.ARCHIVE) {
    modalInfoData.title = "Archive automations?";
    modalInfoData.confirmButton = "Yes, archive";
  }
});

const openConfirmModal = (i: QLAutomationBulkActionEnum) => {
  modalOpenToggle.value = true;
  modalOpen.value = i;
};

const informOfAction = () => {
  emit("perform", modalOpen.value);
};
</script>
