<template>
  <div class="flex items-center justify-center">
    <img :src="item.content" class="max-h-[20rem] object-cover">
  </div>
</template>
<script lang="ts" setup>
import {QLFormField} from "@/graphql/queries/ql/composables";
import {toRef}       from "vue";

interface Props {
  item: QLFormField;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits([]);

const item = toRef(props, "item");
</script>
