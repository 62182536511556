<template>
  <div class="split-view-left">
    <div class="pb-4 md:pb-0 md:w-1/3">
      <div class="text-lg font-medium text-gray-900">
        Set your studio APIs
      </div>
      <div class="mt-1 text-gray-600 text-sm">
        Login to fitDEGREE and click Setup. Under My fitSpot, click
        Integrations. Click on the middle tab for API Keys. Click the blue
        button for "+ Create New API Key." You can add an optional label, use
        something you won't forget like "LoopSpark".
      </div>
    </div>

    <form
        class="flex-1"
        @submit.prevent="firstStep">
      <div class="block overflow-hidden shadow-md rounded-lg">
        <div class="space-y-6 bg-white p-4">
          <p class="text-sm">
            Using the API key you generated in your fitDEGREE account, copy the API key and paste it
            into this box "Your API Key"
          </p>

          <div>
            <text-input
                v-model="siteId"
                label="Your API key"
                placeholder="ex 23984"
                required />
          </div>
        </div>
        <div class="bg-gray-100">
          <div class="flex justify-end p-4 py-3">
            <v-button
                :disabled="step1submitting || siteId === null"
                :loading="step1submitting"
                class="btn btn-green text-sm font-normal"
                type="submit">
              Confirm my API Key...
            </v-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script lang="ts" setup>
import {ref}                        from "vue";
import VButton                      from "@/components/layout/VButton.vue";
import TextInput                    from "@/components/layout/Forms/TextInput.vue";
import {useFitDegreeApiSetMutation} from "@/graphql/queries/ql/composables";
import $toast                       from "@/composables/toast";

const siteId = ref(null);
const step1submitting = ref(false);

const mutation = useFitDegreeApiSetMutation({});

const firstStep = () => {
  if (step1submitting.value) {
    return;
  }

  step1submitting.value = true;


  mutation.mutate({api_key: siteId.value}).finally(() => step1submitting.value = false);

  mutation.onDone(() => {
    $toast({
             type:         "success",
             message:      "API is set!",
             linkRouteObj: null,
             linkText:     null
           });
  });
  mutation.onError((error) => {
    $toast({
             type:         "error",
             message:      "There was an error while completing this step. Please make sure you authorized LoopSpark on fitDEGREE",
             linkRouteObj: null,
             linkText:     null
           });
  });
};

</script>
