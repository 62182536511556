export interface AddonInfo {
	addonType: string,
	title: string,
	enabled: boolean,
	price: number,
	priceUnit: string,
	features: string[],
	cancelMessage: string,
	description: string,
	addButtonText: string

}

export interface AddonsConfig {
	[key: string]: AddonInfo;
}

export interface Config {
	addonsEnabled: boolean,
	addons: AddonsConfig;
}

const config: Config = {
	addonsEnabled: true,
	addons:        {
		forms: {
			addonType:     "forms",
			title:         "Forms & Waivers",
			enabled:       true,
			price:         45,
			priceUnit:     "month per location",
			features:      [
				"Customizable designs",
				"Waivers, forms, surveys and more",
				"Rich form data analytics"
			],
			cancelMessage: "Cancelling your Forms & Waivers addon will result in losing the addon functionality. All active forms will become archived, but completed forms will remain saved in client profile records.",
			description:   "Customizable liability waivers, client forms, surveys and questionnaires! Organized in\n" +
			               "        client profiles with rich form submission analytics, streamline customer intake and learn\n" +
			               "        more about your client base.",
			addButtonText: "Add Forms and Waivers"
		},

		zapier: {
			addonType:     "zapier",
			title:         "Zapier",
			enabled:       true,
			price:         10,
			priceUnit:     "month per location",
			features:      [
				"Push leads from Facebook, Google, Instagram and more directly into LoopSpark",

				"Connect your LoopSpark account to thousands of other 3rd party providers available in the Zapier marketplace."
			],
			cancelMessage: "Cancelling your Zapier addon will result in losing the addon functionality. All active zaps will be disabled.",
			description:   "Expand your LoopSpark account with our Zapier integration.  Push leads into LoopSpark from Facebook, Google, and webpage lead forms, send information from LoopSpark to other 3rd party providers, and much more.",
			addButtonText: "Add Zapier"
		}
	}
};

export default config;
