<template>
  <div class="w-full inline-flex items-center">
    <input
        ref="inputBox"
        v-model="selectedValue"
        :max="operator.maxValue != null ? operator.maxValue : null"
        :min="operator.minValue != null ? operator.minValue : null"
        autofocus
        class="form-input form-input-default "
        :class="{'rounded-r-none': operator.hasAppendText}"
        :type="type">
    <div
        v-if="operator.hasAppendText"
        class="form-input form-input-default shrink w-auto rounded-l-none border-l-0 bg-gray-50 text-gray-600">
      {{ operator.appendText }}
    </div>
  </div>
</template>
<script lang="ts" setup>

import TextOperator                             from "hm-filters/operators/TextOperator";
import NumericOperator                          from "hm-filters/operators/NumericOperator";
import {computed, onMounted, ref, toRef, watch} from "vue";

interface Props {
  operator: TextOperator | NumericOperator;
  modelValue: any;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:modelValue", "is-valid"]);
const modelValue = toRef(props, "modelValue");
const operator = toRef(props, "operator");
const inputBox = ref(null);

const type = computed(() => {
  return operator.value.type == "NumericOperator" ? "number" : "text";
});

onMounted(() => {
  inputBox.value.focus();
});

const isNumbericOperator = (operator: TextOperator | NumericOperator): operator is NumericOperator => {
  return type.value === "number";
};

const selectedValue = computed({
                                 get: () => {
                                   return modelValue.value;
                                 },
                                 set: (newVal) => {
                                   if (type.value === "number" && !isNaN(newVal)) {
                                     newVal = parseInt(newVal);
                                   }
                                   emit("update:modelValue", newVal);
                                 }

                               });

const validCalculationInternal = () => {
  if (isNumbericOperator(operator.value)) {
    if (isNaN(modelValue.value)) {
      return false;
    }
    if (operator.value.minValue !== undefined && operator.value.minValue !== null) {
      return parseInt(modelValue.value) >= operator.value.minValue;
    }
    if (operator.value.maxValue !== undefined && operator.value.maxValue !== null) {
      return parseInt(modelValue.value) <= operator.value.maxValue;
    }
    return true;
  }

  if (modelValue.value === null || modelValue.value === "") {
    return false;
  }
  return true;
};

const isValidCalculation = () => {
  let valid = validCalculationInternal();
  emit("is-valid", valid);
  return valid;
};

const isValid = computed(() => {
  return isValidCalculation();
});

watch(modelValue, isValidCalculation);
</script>
