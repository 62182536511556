<template>
  <div class="flex-1 flex flex-col bg-loopspark-500">
    <div class="container mx-auto mt-4 flex justify-between items-center">
      <div />
      <navbar-user class="text-white" />
    </div>
    <div class="flex-1 bg-loopspark-500 flex items-center justify-center">
      <div class="text-white">
        <div class="text-center">
          <p class="text-2xl font-semibold">
            Where is your team?
          </p>
          <p class="pt-4">
            It looks like you're not part of any team!
          </p>
          <p class="pt-8 pb-4">
            You can ask your business owner to invite you to their team, or
          </p>
          <button
              class="btn btn-green"
              @click="openModal = true">
            Create your own team
          </button>
        </div>
      </div>
    </div>

    <toast-manager />
    <pending-invitations />
    <create-new-studio-modal v-model:open="openModal" />
  </div>
</template>
<script lang="ts" setup>
import useEmitter from "@/composables/useEmitter";

import PendingInvitations   from "@/components/user/invitations/PendingInvitations.vue";
import ToastManager         from "@/components/layout/Toast/ToastManager.vue";
import CreateNewStudioModal from "@/components/studio/CreateNew/CreateNewStudioModal.vue";
import NavbarUser           from "@/components/navbar/NavbarUser.vue";
import {ref}                from "vue";
import {echoListener}       from "@/composables/echoListener";
import {AllEventTypes}      from "@/classes/notifications/EventTypes";

const emitter = useEmitter();

const openModal = ref(false);

echoListener([AllEventTypes.AcceptedInvitation], (ev) => {
  window.location.replace("/");
});

echoListener([AllEventTypes.NewStudioCreated], (ev) => {
  setTimeout(() => {
    window.location.replace("/");
  }, 1000);
});
</script>
