<template>
  <transition
      v-focus
      name="z-fade-modal">
    <div
        v-show="openModel"
        ref="maindiv"
        aria-labelledby="modal-headline"
        aria-modal="true"
        aria-hidden="false"
        role="dialog"
        class="fixed inset-0 w-full h-full z-1000 flex"
        @keydown.esc="escPressed">
      <transition
          enter-active-class="ease-out duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in duration-200"
          leave-class="opacity-100"
          leave-to-class="opacity-0">
        <div
            v-show="openModel"
            class="fixed inset-0 transition-opacity"
            @click="escPressed">
          <div class="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
      </transition>
      <transition
          enter-active-class="ease-out duration-300"
          enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-200"
          leave-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          @after-enter="afterTransition">
        <div
            v-show="openModel"

            class="fixed shadow-inner max-h-screen sm:max-w-lg sm:w-full md:relative bottom-0 left-0 right-0 align-top m-auto justify-end md:justify-center bg-white rounded-lg w-full md:h-auto md:shadow flex flex-col"
            :class="{'sm:max-w-screen-lg': wide, 'sm:max-w-screen-xl': fullWidth}">
          <div
              class="overflow-y-auto">
            <div
                class="overflow-visible px-4 pt-5 pb-4 sm:p-6 sm:pb-4 overflow-y-auto max-h-screen-80"
                :class="{'h-screen': fullHeigth}">
              <div class="sm:flex sm:items-start overflow-y-auto h-full">
                <slot />
              </div>
            </div>
            <div
                v-if="hasAnyButtons"
                class="bg-gray-100 rounded-b-lg px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse sm:space-x-2 sm:space-x-reverse ">
              <slot name="buttons" />
              <div>
                <v-button
                    v-if="hasCloseButton"
                    class="modal-button modal-button-white w-auto"
                    type="button"
                    @click="openModel = false">
                  {{ closeButtonText }}
                </v-button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<style>
.z-fade-modal-leave-active {
  transition:z-index 300ms;
}

.z-fade-modal-enter-to, .z-fade-modal-leave {
  @apply z-1000;
}

.z-fade-modal-enter, .z-fade-modal-leave-to {
  @apply -z-1;
}
</style>
<script lang="ts">

import {computed, onMounted, onUnmounted, ref, toRef, watch} from "vue";
import * as focusTrap                                        from "focus-trap";
import VButton                                               from "@/components/layout/VButton.vue";

export default {
  components: {
    VButton
  },
  props:      {
    open:            {
      type:     Boolean,
      required: true
    },
    manualOpen:      {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    wide:            {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    fullWidth:       {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    fullHeigth:      {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    quickClose:      {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    hasCloseButton:  {
      type:     Boolean,
      required: false,
      default:  () => true
    },
    closeButtonText: {
      type:     String,
      required: false,
      default:  () => "Close"
    },
    focus:  {
      type:     Boolean,
      required: false,
      default:  () => true
    },
  },
  emits:      ["closed", "update:open"],

  setup(props, {emit, slots}) {
    const maindiv = ref(null);

    let focusTr = props.focus ? focusTrap.createFocusTrap(maindiv.value) : null;

    const quickClose = toRef(props, "quickClose");
    const manualOpen = toRef(props, "manualOpen");


    const openModel = computed({
                                 get: () => props.open,
                                 set: (val) => emit("update:open", val)
                               });

    const escPressed = () => {
      if (quickClose.value) {
        openModel.value = false;
      }
    };

    const focusOnFirst = () => {
      const focusableElements =
          "button, [href], input, select, textarea, [tabindex]:not([tabindex=\"-1\"])";

      const selectables = maindiv.value.querySelectorAll(focusableElements);
      if (selectables.length > 0) {
        const firstFocusableElement = selectables[0] as HTMLElement;
        firstFocusableElement.focus();
      }
    };

    const afterTransition = () => {
      if(props.focus) {
        focusOnFirst();

        try {
          focusTr = focusTrap.createFocusTrap(maindiv.value, {allowOutsideClick: true});
          focusTr.activate();
        } catch (e) {
          console.debug(e);
        }
      }
    };

    const hasAnyButtons = computed(() => {
      return !!props.hasCloseButton || (!!slots["buttons"]);
    });

    onMounted(() => {
      if (manualOpen.value) {
        open();
      }
    });

    onUnmounted(() => {
      try {
        if(props.focus) {
          focusTr.deactivate();
        }
      } catch (e) {

      }
    });

    watch(openModel, (newV, oldV) => {
      if (openModel.value) {
        if(props.focus) {
          maindiv.value.focus();
        }
      } else {
        emit("closed");
      }
    });

    return {
      maindiv,
      focusTr,
      openModel,
      escPressed,
      afterTransition,
      hasAnyButtons
    };


  }
};

</script>
