<template>
  <div class="flex items-center grow bg-white p-3 py-5">
    <div class="p-3 mr-4 text-loopspark-500 bg-loopspark-100 rounded-full dark:text-loopspark-100 dark:bg-loopspark-500">
      <slot name="icon"></slot>
    </div>
    <div class="grow">
      <p class="text-base text-gray-700">
        <slot name="title"></slot>
      </p>
      <div v-if="!loading" class="flex items-baseline">
        <div class="grow inline-flex items-baseline">
          <div class="text-3xl font-semibold text-blue-700">
            <slot name="main">{{ current }}</slot>
            <slot name="unit"></slot>
          </div>
          <div class="text-sm text-gray-500 pl-2" v-if="showPast">from {{ past }}
            <slot name="unit"></slot>
          </div>
        </div>
        <div v-if="showPast && percentage !== 0"
             class="rounded-full text-sm px-1 font-medium tracking-wider"
             v-bind:class="{'bg-green-100': isPercentageUp, 'text-green-900': isPercentageUp, 'bg-red-100': !isPercentageUp, 'text-red-900': !isPercentageUp}">
          <div class="inline-flex items-center">
            <svg-icon name="arrow-up" v-if="isPercentageUp" class="w-3 h-3" />
            <svg-icon name="arrow-down" v-if="!isPercentageUp" class="w-3 h-3" />
            <span class="pl-1">{{ percentage }}%</span>
          </div>
        </div>
      </div>
      <div v-if="loading" class="animate-loading">
        <div class="loading-bg w-12 h-6 mt-2"></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SvgIcon                        from "@/components/SvgIcon.vue";
import {computed, useAttrs, useSlots} from "vue";
import {isNumber, toRefs}             from "@vueuse/core";

interface Props {
  current: number | string;
  past?: number;
  loading: boolean;
  showPast?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  showPast: false,
  past: 0
});

const slots = useSlots();
const attrs = useAttrs();
const {loading, current, past, showPast} = toRefs(props);

const emit = defineEmits([]);

const percentage = computed(() => {
  if (loading.value || past.value === 0 || current.value === 0 || !isNumber(current.value)) {
    return 0;
  }

  return Math.round((((current.value - past.value) / past.value) * 100) * 100) / 100;
});

const isPercentageUp = computed(() => {
  return percentage.value > 0;
});

</script>
