import {computed, isRef, reactive, ref, Ref}                                                    from "vue";
import {QLFragmentClassFullFragment, QLFragmentVisitFullFragment, QLFragmentVisitSmallFragment} from "@/graphql/queries/ql/composables";
import {DecoratorInterface}                                                                     from "@/composables/decorators/DecoratorInterface";

export function VisitDecorator(passed: Ref<QLFragmentVisitFullFragment | QLFragmentVisitSmallFragment> | QLFragmentVisitFullFragment | QLFragmentVisitSmallFragment,
                               event: Ref<QLFragmentClassFullFragment> | QLFragmentClassFullFragment) {

	const entity = isRef(passed) ? passed : ref(passed);
	const eventRef = isRef(event) ? event : ref(event);

	const hasClass = computed((): boolean => {
		return !!eventRef.value;
	});

	const hasStaff = computed((): boolean => {
		if (eventRef.value === null) {
			return false;
		}
		return !!eventRef.value.staff;
	});

	const hasPackage = computed((): boolean => {
		return !!entity.value.package;
	});

	const hasLocation = computed((): boolean => {
		return !!eventRef.value && !!eventRef.value.location;
	});

	const classCancelled = computed((): boolean => {
		if (hasClass.value) {
			return eventRef.value.cancelled;
		}
		return false;
	});

	const isSignedIn = computed((): boolean => {
		if (!isPast.value || classCancelled.value) {
			return false;
		}
		if (!entity.value.signed_in) {
			return false;
		}
		return !(entity.value.early_cancel || entity.value.late_cancelled);
	});

	const isAbsent = computed((): boolean => {
		if (!isPast.value) {
			return false;
		}

		if (classCancelled.value) {
			return false;
		}
		return !entity.value.early_cancel && !entity.value.signed_in && !entity.value.late_cancelled;
	});

	const isLateCancelled = computed((): boolean => {
		if (classCancelled.value) {
			return false;
		}
		return !!entity.value.late_cancelled;
	});

	const isPast = computed((): boolean => {
		if (!hasClass.value && !!entity.value.class_end_time) {

			return false;
		}

		if (hasClass.value) {
			return Math.round(Date.now() / 1000) > eventRef.value.end_time;
		} else {
			return Math.round(Date.now() / 1000) > entity.value.class_end_time;
		}
	});


	const isEarlyCancelled = computed((): boolean => {
		if (classCancelled.value) {
			return false;
		}
		return !!entity.value.early_cancel;
	});


	return {
		computed: reactive({
			                   hasClass,
			                   hasStaff,
			                   hasPackage,
			                   hasLocation,
			                   classCancelled,
			                   isSignedIn,
			                   isAbsent,
			                   isLateCancelled,
			                   isPast,
			                   isEarlyCancelled
		                   })
	};
}
