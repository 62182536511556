<template>
  <div>
    <div class="mt-1 flex rounded-md shadow-sm">
      <div class="relative flex items-stretch flex-grow focus-within:z-10">
        <input :class="[isSupported ? '': 'rounded-r-md','bg-gray-50 text-gray-700 focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300']"
               :value="url"
               readonly
               type="text">
      </div>
      <button v-if="isSupported"
              :aria-tooltip="copied ? 'Copied!' : 'Copy to clipboard'"
              class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              type="button"
              @click="copy()">
        <svg aria-hidden="true"
             class="h-5 w-5 text-gray-400"
             fill="none"
             stroke="currentColor"
             viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {useClipboard}              from "@vueuse/core";
import {toRef, useAttrs, useSlots} from "vue";

interface Props {
  url: string;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const url = toRef(props, "url");
const {text, isSupported, copy, copied} = useClipboard({source: url});

</script>
