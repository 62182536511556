<template>
  <div>
    <div>
      <h1 class="main-page-title">
        Services of {{ client.first_name }}
      </h1>
    </div>
    <div class="grid md:grid-cols-2 gap-6">
      <div>
        <client-packages-list :client="client" />
      </div>
      <div>
        <client-contracts-list :client="client" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import ClientPackagesList  from "@/components/client/Services/ClientPackagesList.vue";
import ClientContractsList from "@/components/client/Services/ClientContractsList.vue";
import {toRef}             from "vue";
import {QLClient}          from "@/graphql/queries/ql/composables";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");
</script>
