<template>
  <div>
    <smart-select
        v-model="selectedValue"
        :items="items"
        :label="'name'"
        always-show-all
        focus-on-show
    >
      <template #default="{ item }">
        <div>{{ item.name }}</div>
        <div class="text-xs text-gray-500">
          {{ item.address_city }}
        </div>
      </template>
    </smart-select>
  </div>
</template>
<script lang="ts" setup>

import SmartSelect              from "@/components/layout/Forms/SmartSelect.vue";
import LocationOperator         from "hm-filters/operators/LocationOperator";
import {computed, toRef, watch} from "vue";
import currentTeamQuery         from "@/composables/queries/currentTeamQuery";

interface Props {
  operator: LocationOperator;
  modelValue: any;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:modelValue", "is-valid"]);

const {currentTeam, loading} = currentTeamQuery();

const items = computed(() => {
  return currentTeam.value ? currentTeam.value.locations : [];
});

const modelValue = toRef(props, "modelValue");

const selectedValue = computed({
                                 get: () => {
                                   if (!modelValue.value) {
                                     return null;
                                   }
                                   return items.value.find((choice) => choice.name === modelValue.value);
                                 },
                                 set: (newVal) => emit("update:modelValue",
                                                       newVal !== null ? newVal.name : null)
                               });

const isValidCalculation = () => {
  let valid = modelValue.value !== null && selectedValue.value !== null;
  emit("is-valid", valid);
  return valid;
};

const isValid = computed(() => {
  return isValidCalculation();
});

watch(modelValue, isValidCalculation);
</script>
