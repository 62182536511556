import {computed, Ref} from "vue";

const urlShorteners = [
	"1url\\.com",
	"2\\.gp",
	"2\\.ly",
	"3\\.ly",
	"4\\.gp",
	"4\\.ly",
	"5\\.gp",
	"6\\.gp",
	"6\\.ly",
	"7\\.ly",
	"8\\.ly",
	"9\\.ly",
	"0\\.gp",
	"adf\\.ly",
	"bc\\.vc",
	"bit\\.do",
	"bit\\.ly",
	"bitly\\.com",
	"buzurl\\.com",
	"cur\\.lv",
	"cutt\\.us",
	"db\\.tt",
	"filoops\\.info",
	"g\\.asia",
	"goo\\.gl",
	"is\\.gd",
	"ity\\.im",
	"j\\.mp",
	"link\\.zip\\.net",
	"lnkd\\.in",
	"ow\\.ly",
	"p\\.asia",
	"po\\.st",
	"prettylinkpro\\.com",
	"q\\.gs",
	"qr\\.ae",
	"qr\\.net",
	"rb\\.gy",
	"scrnch\\.me",
	"t\\.co",
	"tcrn\\.ch",
	"tinyurl\\.com",
	"tr\\.im",
	"tweez\\.me",
	"twitthis\\.com",
	"u\\.bb",
	"u\\.to",
	"ur3\\.us",
	"v\\.gd",
	"vzturl\\.com",
	"x\\.co",
	"yourls\\.org",
];

export function messageHasUrlShortner(text: Ref<string>) {
	const regUrls = urlShorteners.join("|");
	const regEx = new RegExp(`((https?:\/\/)?(` + regUrls + `)\S*)`, 'gmi');

	const msgIsShortened = computed((): boolean => {
		return regEx.test(text.value);
	});

	return {
		msgIsShortened: msgIsShortened
	};
}
