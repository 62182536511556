<template>
    <div>
        <div id="editor-container" ref="editor" style="min-height: 800px; height: 100px"></div>
    </div>
</template>
<script lang="ts" setup>
import {QLEmailTemplateContent, QLPlaceholderFragmentFragment} from "@/graphql/queries/ql/composables";
import {onBeforeUnmount, onMounted, ref, toRaw}                from "vue";
import placeholdersQuery                                       from "@/composables/queries/placeholdersQuery";
import {loadScript}                                            from "@/classes/utils/LoadScript";
// import {UnlayerConfig, UnlayerExportedHtml}                    from "unlayer";
import axios                                                   from "@/classes/boot/axios";
import {MyBugsnag}                                             from "@/classes/boot/bugsnag";

interface UnlayerConfig {
	id?: string
	displayMode?: "email" | "web"
	mergeTags?: QLPlaceholderFragmentFragment[]
	tools?: UnlayerToolsSettings,
	projectId?: number,
	version?: string
}

interface UnlayerToolsSettings {
	[propName: string]: UnlayerToolSetting;
}

interface UnlayerToolSetting {
	enabled?: boolean,

	[propName: string]: any
}

interface UnlayerExportedHtml {
	html: string,
	design: UnlayerExportedJson
}

interface UnlayerExportedJson {
	[propName: string]: any;
}

interface Props {
	modelValue: QLEmailTemplateContent;
	readOnly?: false;
	uploadUrl?: string;
	mergeTags?: QLPlaceholderFragmentFragment[];
}

const props = withDefaults(defineProps<Props>(), {
	readOnly:  false,
	uploadUrl: import.meta.env.VITE_APP_MAIN_URL + "/api/emails/upload-image",
	mergeTags: () => []
});

const emit = defineEmits(["update:modelValue"]);

const data = ref({
	                 lastUpdate:      0,
	                 loadedFirstTime: false
                 });

const editor = ref(null);

const cleanJson = () => {
	let stringed = props.modelValue.json;
	if (typeof stringed !== "string") {
		// replacing of color should be done on the external data
		stringed = JSON.stringify(props.modelValue.json);
	}

	if (props.readOnly) {

		stringed = stringed.replace(/(\"selectable\":true)/g, "\"selectable\":false");
		stringed = stringed.replace(/(\"draggable\":true)/g, "\"draggable\":false");
		stringed = stringed.replace(/(\"deletable\":true)/g, "\"deletable\":false");

	}
	return JSON.parse(stringed);
};

const {placeholders, onResult, loading} = placeholdersQuery();

const timers = (cb) => {
	const timer = ref(null);
	const timeout = ref(null);

	const removeTimers = () => {
		if (timer.value !== null) {
			clearInterval(timer.value);
		}
		if (timeout.value !== null) {
			clearTimeout(timeout.value);
		}
	};

	const startTimer = () => {
		removeTimers();
		timeout.value = setTimeout(() => {
			timer.value = setInterval(cb, 500);
		}, 2500);
	};
	return {
		removeTimers,
		startTimer
	};
};

function listAllOfAProperty(arr: Array<QLPlaceholderFragmentFragment>, objProperty: string) {
	return arr.map((i) => i[objProperty])
	          .filter((value, index, array) => array.indexOf(value) === index);
}

const tagsToShow = (): Array<QLPlaceholderFragmentFragment> | any => {
	if (props.mergeTags.length > 0) {
		return toRaw(props.mergeTags);
	}

	let final = {};
	let placeholdersRaw = toRaw(placeholders.value);
	let allGroups = listAllOfAProperty(placeholdersRaw, "group");
	allGroups.forEach((item) => {
		let newGroup = {
			name:      item,
			mergeTags: {}
		};

		let subgroups = listAllOfAProperty(placeholdersRaw.filter((i) => i.group === item), "subgroup");
		subgroups.forEach((subItemGroup) => {
			let groupChildren = placeholdersRaw.filter((i) => i.group === item && i.subgroup == subItemGroup);
			if (subItemGroup == null) {
				// add them as direct children
				groupChildren.forEach((child) => {
					newGroup.mergeTags[child.value.replace("{{", "").replace("}}", "")] = {
						name:   child.name,
						value:  child.value,
						sample: child.name
					};
				});
			} else {
				let subgroup = {
					name:      subItemGroup,
					mergeTags: {}
				};

				groupChildren.forEach((child) => {
					subgroup.mergeTags[child.value.replace("{{", "").replace("}}", "")] = {
						name:   child.name,
						value:  child.value,
						sample: child.name
					};
				});
				newGroup.mergeTags[subItemGroup.toLowerCase().replace(/\s+/g, "_")] = subgroup;
			}
		});

		final[item.toLowerCase().replace(/\s+/g, "_")] = newGroup;

	});

	return final;
};

const exportNow = () => {
	const startDate = Date.now() - 490;
	if (data.value.lastUpdate < startDate) {
		try {
			window.unlayer!.exportHtml((exportedHtml: UnlayerExportedHtml) => {
				let val = {
					"html": exportedHtml.html,
					"json": JSON.parse(JSON.stringify(exportedHtml.design))
				};
				emit("update:modelValue", val);
				data.value.lastUpdate = Date.now();
			});
		} catch (e) {
			MyBugsnag.getInstance().bugsnag().notify(e);
		}
	}
};

const {startTimer, removeTimers} = timers(exportNow);

const uploadImage = (file: any, done: any) => {
	let data = new FormData();
	data.append("photo", file.attachments[0]);
	let config = {
		onUploadProgress: (progressEvent: ProgressEvent) => {
			let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			if (percentCompleted < 100) {
				done({progress: percentCompleted});
			}
		},
		withCredentials:  true
	};
	axios.post(props.uploadUrl, data, config)
	     .then((response) => {
		     done({progress: 100, url: response.data.url});
	     })
	     .catch((error) => {
		     MyBugsnag.getInstance().bugsnag().notify(error);
		     throw new Error("Error while uploading the image");
	     });
};

const loadUnlayer = async () => {
	data.value.loadedFirstTime = true;
	await loadScript("//editor.unlayer.com/embed.js");
	let conf: UnlayerConfig = {
		id:          editor.value.id,
		displayMode: "email",
		projectId:   18628,
		mergeTags:   tagsToShow(),
		version:     "1.5.2"
	};
	if (props.readOnly) {
		conf.tools = {
			image:   {
				enabled: false
			},
			text:    {
				enabled: false
			},
			button:  {
				enabled: false
			},
			divider: {
				enabled: false
			},
			html:    {
				enabled: false
			},
			social:  {
				enabled: true
			}
		};
	} else {
		conf.tools = {
			image: {
				properties: {
					src: {
						value: {
							url:    "https://images.loopspark.com/cdn/placeholder.png",
							width:  500,
							height: 100
						}
					}
				}
			}
		};
	}

	window.unlayer!.init(JSON.parse(JSON.stringify(conf)));
	window.unlayer!.loadDesign(cleanJson());
	window.unlayer!.addEventListener("design:updated", exportNow);
	window.unlayer!.registerCallback("image", uploadImage);
	startTimer();
};

const applyMergeTags = () => {
	window.unlayer!.setMergeTags(tagsToShow());
};

onResult(async (queryResult) => {
	if (!queryResult.loading) {
		if (data.value.loadedFirstTime) {
			applyMergeTags();
		} else {
			loadUnlayer();
		}
	}
});

onMounted(() => {
	if (loading.value === false) {
		// reload since it's a change of page
		loadUnlayer();
	}
});

onBeforeUnmount(() => {
	removeTimers();
});

</script>
