<template>
  <smart-select :items="selectOptions" label="label" v-model="selected">
    <template v-slot:default="slotProps">
      {{ slotProps.item.label }}
    </template>
  </smart-select>

</template>
<script lang="ts">
import {RawTimeZones} from "@/components/layout/Forms/data/raw-time-zones";
import SmartSelect    from "./SmartSelect.vue";
import {computed}     from "vue";

interface SingleTimezone {
  label: string;
  code: string;
}

export default {
  components: {
    SmartSelect
    // Listbox,
    // ListboxButton,
    // ListboxOptions,
    // ListboxOption
  },
  props:      {
    modelValue: {
      type:     String,
      required: true,
      default:  () => ""
    }
  },
  emits:      ["update:modelValue"],

  setup(props, {emit}) {


    const selectOptions: Array<SingleTimezone> = RawTimeZones.map((rawTimezone) => {
      return {
        label: rawTimezone.rawFormat,
        code:  rawTimezone.name
      };
    });

    const changedTimezone = (val: string | null) => {
      emit("update:modelValue", val);
    };

    const selected = computed({
                                get: () => {
                                  if (!props.modelValue) {
                                    return null;
                                  }
                                  // find the selected one
                                  return selectOptions.find(e => e.code === props.modelValue);
                                },
                                set: (val: SingleTimezone | string | null) => {
                                  if (!val) {
                                    changedTimezone(null);
                                  } else {
                                    if (typeof val === "string") {
                                      changedTimezone(selectOptions.find(e => e.label === val).code);
                                    } else {
                                      changedTimezone(val.code);
                                    }

                                  }
                                }
                              });

    return {
      selectOptions,
      changedTimezone,
      selected
    };

  }

};

</script>
