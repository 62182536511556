import {v4 as uuidv4}  from "uuid";
import {QLFilterGroup} from "@/graphql/queries/ql/composables";

export class FilterGroup {
	static createEmptyWithActiveClients(): QLFilterGroup {
		return {
			operator_is_and: true,
			filters:         [
				{
					type:    "ClientValues",
					uid:     uuidv4(),
					options: {
						value:      true,
						field:      "active",
						"operator": "="
					}
				}
			]
		};
	}

	static createEmpty(): QLFilterGroup {
		return {
			operator_is_and: true,
			filters:         []
		};
	}
}
