<template>
  <div>
    <div class="shadow-lg rounded-lg overflow-hidden">
      <div v-if="loadedFirstTime">
        <div
            v-if="todos && todos.length > 0"
            class="bg-white">
          <div class="divide-y">
            <dashboard-todos-item
                v-for="todo in todos"
                :key="todo.id"
                :todo="todo" />
          </div>
        </div>
        <div
            v-if="loadedFirstTime && paginatorInfo.lastPage > 1"
            class="w-full pt-4 pb-4 text-right bg-gray-100 border-t border-gray-200 px-4 rounded-b">
          <pagination
              v-if="paginatorInfo.lastPage > 1"
              :pagination="paginatorInfo"
              class="flex items-center justify-between text-gray-700 text-sm"
              @setPage="paginationData.page = $event">
            <div
                v-if="loading"
                class="inline-block w-8 h-8 mr-2 text-gray-400">
              <loader />
            </div>
          </pagination>
        </div>
        <div
            v-if="todos && todos.length === 0"
            class="">
          <empty>
            <template #title>
              Nothing to show!
            </template>
            <template #subtitle>
              There are no To-Dos assigned to you.
            </template>
          </empty>
        </div>
      </div>

      <div
          v-if="!loadedFirstTime"
          class="">
        <div class="h-48 w-full bg-white flex items-center justify-center">
          <loader class="w-12 h-12 text-gray-400 animate-loading" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {echoListener}                                      from "@/composables/echoListener";
import Empty                                               from "@/components/layout/Empty.vue";
import Pagination                                          from "@/components/layout/Pagination.vue";
import Loader                                              from "@/components/layout/Loader.vue";
import DashboardTodosItem                                  from "@/components/dashboard/DashboardTodosItem.vue";
import {QLMyTodosQuery, QLSortOrder, useMyTodosQuery}      from "@/graphql/queries/ql/composables";
import {createPaginationData, paginationWithAlreadyLoaded} from "@/composables/paginationWithAlreadyLoaded";
import {EventTypes}                                        from "@/classes/notifications/EventTypes";

const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 10
                                              });
const orderBy = [{column: "updated_at", order: QLSortOrder.DESC}];

const myTodosQ = useMyTodosQuery(() => ({
  page:  paginationData.page,
  first: paginationData.perPage,
  orderBy
}));

const {
  paginatorInfo,
  loadedFirstTime,
  results: todos,
  loading
} = paginationWithAlreadyLoaded<QLMyTodosQuery["my_todos"]["data"]>(myTodosQ);

echoListener([EventTypes.TodoNew, EventTypes.TodoUpdated], (ev) => {
  myTodosQ.refetch();
}, 5000);

</script>
