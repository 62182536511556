<template>
  <form
      ref="form"
      class="block overflow-hidden shadow-md rounded-lg"
      @submit.prevent="send">
    <div class="bg-white p-6">
      <text-input
          v-model="toSend.website"
          :error-message="errors.get('input.website')"
          label="Website"
          placeholder="ex http://www.yogaisnice.com">
        <div class="leading-tight form-small-info text-gray-600">
          Add your studio’s website URL to quickly use in
          automations through the merge tag
          <span class="studio-settings-merge-tag">STUDIO_WEBSITE</span>
        </div>
      </text-input>

      <text-input
          v-model="toSend.facebook_url"
          :error-message="errors.get('input.facebook_url')"
          class="mt-6"
          label="Facebook profile"
          placeholder="ex https://www.facebook.com/loopspark/">
        <div class="leading-tight form-small-info text-gray-600">
          Add your studio’s Facebook page URL to quickly use in
          automations through the merge tag
          <span class="studio-settings-merge-tag">STUDIO_FACEBOOK_URL</span>
        </div>
      </text-input>

      <div class="md:flex md:space-x-4">
        <div class="md:w-1/2 mt-6">
          <label
              class="form-label"
              for=""
              :class="{'has-error': errors.has('input.twitter_handle')}">Twitter
                                                                         handle</label>
          <div class="mt-1 relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span class="text-gray-600 sm:text-sm sm:leading-5">
              @
            </span>
            </div>
            <input
                v-model="toSend.twitter_handle"
                class="form-input form-input-default block pl-7 sm:text-sm sm:leading-5"
                placeholder="LoopSpark"
                type="text"
                :class="{'has-error': errors.has('input.twitter_handle')}">
          </div>

          <span
              v-if="errors.has('input.twitter_handle')"
              class="form-error-message">{{
              errors.get("input.twitter_handle")
                                         }}</span>
          <div class="leading-tight form-small-info text-gray-600">
            Add your studio’s Twitter handle to quickly use in
            automations through the merge tag
            <span class="studio-settings-merge-tag">STUDIO_TWITTER_HANDLE</span>
          </div>
        </div>
        <div class="md:w-1/2 mt-6">
          <label
              class="form-label"
              for=""
              :class="{'has-error': errors.has('input.instagram_handle')}">Instagram
                                                                           handle</label>
          <div class="mt-1 relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span class="text-gray-600 sm:text-sm sm:leading-5">
              @
            </span>
            </div>
            <input
                v-model="toSend.instagram_handle"
                class="form-input form-input-default block pl-7 sm:text-sm sm:leading-5"
                placeholder="LoopSpark"
                type="text"
                :class="{'has-error': errors.has('input.instagram_handle')}">
          </div>

          <span
              v-if="errors.has('input.instagram_handle')"
              class="form-error-message">{{
              errors.get("input.instagram_handle")
                                         }}</span>
          <div class="leading-tight form-small-info text-gray-600">
            Add your studio’s Instagram handle to quickly use in
            automations through the merge tag
            <span class="studio-settings-merge-tag">STUDIO_INSTAGRAM_HANDLE</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-100">
      <div class="flex justify-end p-6 py-3">
        <v-button
            :loading="sending"
            class="btn btn-green text-sm font-normal"
            type="submit">
          Update social
        </v-button>
      </div>
    </div>
  </form>
</template>
<script lang="ts" setup>
import {onMounted, reactive, watch}    from "vue";
import TextInput                       from "@/components/layout/Forms/TextInput.vue";
import VButton                         from "@/components/layout/VButton.vue";
import studioSettingsUpdate            from "@/components/studio/settings/studioSettingsUpdate";
import {QLFragmentCurrentTeamFragment} from "@/graphql/queries/ql/composables";

interface Props {
  currentTeam: QLFragmentCurrentTeamFragment;
}

const props = withDefaults(defineProps<Props>(), {});


const {
  currentTeam,
  form,
  errors,
  sending,
  sendMutation
} = studioSettingsUpdate(props);

const {toSend, loadFromQL} = fieldsAndLoading(currentTeam);

onMounted(() => {
  sending.value = false;
  loadFromQL();
});

const send = () => {
  const vars = {
    name: currentTeam.value.name,
    ...toSend
  };
  sendMutation(vars);
};


function fieldsAndLoading(currentTeam) {
  const toSend = reactive({
                            facebook_url:     "",
                            twitter_handle:   "",
                            instagram_handle: "",
                            website:          ""
                          });

  const loadFromQL = () => {
    let {
      website, facebook_url, twitter_handle, instagram_handle
    } = currentTeam.value;

    Object.assign(toSend,
                  {website, facebook_url, twitter_handle, instagram_handle});
  };

  watch(currentTeam, loadFromQL);

  return {
    toSend, loadFromQL
  };
}
</script>
