export const EventTypes = {
	CommunicationNew:          "Communication\\CommunicationWasCreated",
	CommunicationUpdated:      "Communication\\CommunicationWasUpdated",
	ContractWasUpdated:        "Sale\\ContractWasUpdated",
	DiscussionNew:             "Communication\\DiscussionWasCreated",
	DiscussionUpdated:         "Communication\\DiscussionWasUpdated",
	MessageNew:                "Communication\\MessageWasCreated",
	MessageUpdated:            "Communication\\MessageWasUpdated",
	NoteWasArchived:           "Note\\NoteWasArchived",
	NoteWasCreated:            "Note\\NoteWasCreated",
	PackageWasUpdated:         "Sale\\PackageWasUpdated",
	SaleWasUpdated:            "Sale\\SaleWasUpdated",
	SegmentWasDeleted:         "Segment\\SegmentWasDeleted",
	SegmentWasUpdated:         "Segment\\SegmentWasUpdated",
	TagCreated:                "Tags\\TagWasCreated",
	TagUpdated:                "Tags\\TagWasUpdated",
	TeamUpdated:               "Team\\TeamWasUpdated",
	TodoNew:                   "Todo\\TodoWasCreated",
	TodoUpdated:               "Todo\\TodoWasUpdated",
	VisitWasUpdated:           "Visit\\VisitWasUpdated",
	ChangedTeam:               "User\\ChangedTeam",
	LoggedOut:                 "User\\LoggedOut",
	FormWasCreated:            "Forms\\Events\\FormWasCreated",
	FormWasUpdated:            "Forms\\Events\\FormWasUpdated",
	FormInstanceWasCreated:    "Forms\\Events\\FormInstanceWasCreated",
	FormInstancePdfWasCreated: "Forms\\Events\\FormInstancePdfWasCreated",
	BroadcastClientWasUpdated: "Client\\BroadcastClientWasUpdated",
};

type Values<T> = T[keyof T];

export const PublicEventTypes = {
	WebVersionUpdated: "WebVersionUpdated"
};

export const RootEventTypes = {

	UpdateStatus:       "update-status",
	WebNotification:    "web-notification",
	NewStudioCreated:   "new-studio-created",
	AcceptedInvitation: "accepted-invitation",
	UserNotification:   "user-notification",
	RefreshTeam:        "refresh-team",
	RefreshMe:          "refresh-me"
};

export const AllEventTypes = {
	...EventTypes,
	...RootEventTypes,
	...PublicEventTypes
};

export interface EventInterface {
	data: any;
}

export const ECHO_EVENT_NAME = "eventreceived";
