import ValidationError                 from "./ValidationError";
import BaseQlError, {QlErrorInterface} from "./BaseQlError";
import InternalError                   from "./InternalError";
import AuthorizationError              from "./AuthorizationError";
import {GraphQLError}                  from "graphql";
import UnauthenticatedError            from "@/graphql/Errors/UnauthenticatedError";
import {ApolloError}                   from "@apollo/client/core";


export default class QlErrorParser {
	static returnError(error: ApolloError): QlErrorInterface {
		if (error.graphQLErrors.length == 0 || error.graphQLErrors[0].extensions == null) {
			return new BaseQlError(error);
		}
		const errorKind: string = error.graphQLErrors[0].extensions.category as string;
		// console.log(errorKind);
		if (errorKind === "authentication") {
			return new UnauthenticatedError(error);
		}
		if (errorKind === "validation") {
			return new ValidationError(error);
		}
		if (errorKind === "internal") {
			return new InternalError(error);
		}

		if (errorKind === "authorization") {
			return new AuthorizationError(error);
		}

		return new BaseQlError(error);
	}

	static returnSingleGraphQLError(error: GraphQLError) {
		const errorKind: string = error?.extensions.category as string;
		const apolloError = new ApolloError({graphQLErrors: [error]});
		// if (errorKind === "authentication") {
		// 	return new UnauthenticatedError(error);
		// }
		if (errorKind === "validation") {
			return new ValidationError(apolloError);
		}
		if (errorKind === "internal") {
			return new InternalError(apolloError);
		}

		if (errorKind === "authorization") {
			return new AuthorizationError(apolloError);
		}

		return new BaseQlError(apolloError);
	}
}

