<template>
  <span
      :class="{'as-tag': asTag, 'active': isActive && usesColors, 'prospect': isProspect && usesColors, 'lead': isLead && usesColors, 'inactive': (isInactive || isArchivedLead) && usesColors}"
      v-text="clientText" />
</template>
<style scoped>
.active {
  @apply text-gray-600
}

.inactive {
  @apply text-red-600
}

.lead {
  @apply text-green-600
}

.prospect {
  @apply text-orange-600
}

.as-tag {
  @apply inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium;
}

.as-tag.active {
  @apply text-gray-800 bg-gray-100;
}

.as-tag.inactive {
  @apply text-red-800 bg-red-100;
}

.as-tag.lead {
  @apply text-green-800 bg-green-100;
}

.as-tag.prospect {
  @apply text-orange-800 bg-orange-100;
}

</style>
<script lang="ts" setup>

import {computed, useAttrs, useSlots}   from "vue";
import {QLFragmentClientStatusFragment} from "@/graphql/queries/ql/composables";

interface Props {
  client: QLFragmentClientStatusFragment,
  activeText?: string,
  leadText?: string,
  archivedLeadText?: string,
  inactiveText?: string,
  prospectText?: string,
  usesColors?: boolean,
  asTag?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  activeText:   "Active",
  leadText:     "Lead",
  inactiveText: "Inactive",
  prospectText: "Prospect",
  archivedLeadText: "Archived Lead",
  usesColors:   false,
  asTag:        false
});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const isLead = computed(() => {
  return props.client.is_lead && !props.client.frozen;
});

const isArchivedLead = computed(() => {
  return props.client.is_lead && props.client.frozen;
});

const isActive = computed(() => {
  return props.client.active && !props.client.is_lead && !props.client.is_prospect;
});

const isProspect = computed(() => {
  return props.client.active && props.client.is_prospect;
});

const isInactive = computed(() => {
  return !props.client.is_lead && !props.client.active;
});

const clientText = computed(() => {
  if (props.client.is_lead) {
    return props.client.frozen ? props.archivedLeadText : props.leadText;
  }
  if (props.client.active) {
    if (!props.client.is_lead && !props.client.is_prospect) {
      return props.activeText;
    }

    if (props.client.is_prospect) {
      return props.prospectText;
    }

  } else {
    return props.inactiveText;
  }
  return "";
});

</script>
