import {createRouter, createWebHistory} from "vue-router";
import {AllRoutes}                      from "@/router/all-routes";
import {RouterGuard}                    from "@/router/RouterGuard";


const router = createRouter({
	                            "history": createWebHistory(),
	                            routes:    AllRoutes
                            });
//
// router.beforeEach(async (to, from, next) => {
router.beforeEach(async (to, from, next) => {
	const guard = new RouterGuard(to, from, next);
	const res = await guard.analyze();
	if(res){
		next(res);
	}else {
		next();
	}
//
});
//
router.afterEach((to, from) => {
	let title = "LoopSpark";
	to.matched.forEach((m) => {
		if (m.meta && m.meta.title) {
			title = m.meta.title + " | LoopSpark";
		}
	});
	document.title = title;
});

export default router;
