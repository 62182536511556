<template>
  <Popover
      v-if="me"
      as="div"
      class="shrink-0 relative ml-4">
    <PopoverButton class="bg-gray-800 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
      <span class="sr-only">Open user menu</span>
      <img
          class="h-8 w-8 rounded-full"
          :src="me.photo_url"
          :alt="me.name">
    </PopoverButton>
    <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95">
      <PopoverPanel class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
        <div class="divide-y space-y-2">
          <div>
            <router-link
                :to="{ name: routeList.user.settings }"
                class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
              My account
            </router-link>
          </div>
          <div class="pt-2">
            <div class="block py-2 pb-1 px-4 text-sm text-gray-500 font-semibold">
              Support
            </div>
            <a
                class="w-full flex items-center py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                href="mailto:support@LoopSpark.com">
              <svg-icon
                  name="email"
                  class="w-4 h-4" />
              <span class="pl-2">Email us</span>
            </a>
            <a
                class="w-full flex items-center py-2 px-4 text-sm text-gray-700 hover:bg-gray-100"
                href="https://docs.loopspark.com/"
                target="_blank">
              <svg-icon
                  name="question-duotone"
                  class="w-4 h-4" />
              <span class="pl-2">Docs</span>
            </a>
          </div>
          <div class="pt-2">
            <router-link
                :to="{ name: routeList.logout }"
                class="w-full flex items-center py-2 px-4 text-sm text-gray-700 hover:bg-gray-100">
              <svg-icon
                  name="signout-duotone"
                  class="w-4 h-4" />
              <span class="pl-2">Logout</span>
            </router-link>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
<style scoped>
.list-link {
  @apply px-6 py-2 block text-gray-900 hover:bg-blue-200 no-underline hover:no-underline;
}

.list-link-with-icon {
  @apply px-6 py-2 block text-gray-900 hover:bg-blue-200 no-underline hover:no-underline flex items-center;
}
</style>
<script lang="ts" setup>
import {Disclosure, DisclosureButton, DisclosurePanel, Popover, PopoverButton, PopoverGroup, PopoverPanel} from "@headlessui/vue";
import SvgIcon                                             from "@/components/SvgIcon.vue";
import meQuery                                             from "@/composables/queries/meQuery";
import routesList                                          from "@/composables/routesList";

const {routeList} = routesList();
const {me} = meQuery();

</script>
