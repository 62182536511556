import Filter from "../../base-filter";

import config                  from "./config";
import GraphQlOperator         from "hm-filters/operators/GraphQlOperator";
import MultipleTextOperator    from "hm-filters/operators/MultipleTextOperator";
import QLQueriesData           from "hm-filters/operators/GraphQls/QLQueriesData";
import {OperatorTypesFullName} from "hm-filters/operators/OperatorTypes";

export class AppointmentPackageUsed extends Filter {
	smallText = config.appointments.groups.appointment.group;
	group = config.appointments.groups.appointment.group;
	path = ["Appointments", this.group];

	constructor(name: string, options: any) {
		super(config.appointments.usingPackage, options);

		this.operators = [
			new AppointmentPackageUsedQlOperator("=", "used").setIsMultipleChoice(),
			new MultipleTextOperator("contains", "contains"),
			new MultipleTextOperator("does_not_contain", "does not contain"),
		];

	}

	get type(): string {
		return "AppointmentPackageUsed";
	}
}

export class AppointmentNoShowPackageUsed extends AppointmentPackageUsed {
	smallText = config.appointments.groups.noShow.group;
	group = config.appointments.groups.noShow.group;
	path = ["Appointments", this.group];

	get type(): string {
		return "AppointmentNoShowPackageUsed";
	}
}

export class AppointmentUpcomingPackageUsed extends AppointmentPackageUsed {
	smallText = config.appointments.groups.upcoming.group;
	group = config.appointments.groups.upcoming.group;
	path = ["Appointments", this.group];

	get type(): string {
		return "AppointmentUpcomingPackageUsed";
	}
}

export class AppointmentPackageUsedQlOperator extends GraphQlOperator {
	graphQLData = QLQueriesData.AppointmentPackageUsedQlOperator;

	get type(): string {
		return "AppointmentPackageUsedQlOperator";
	}

	get fullType(): string{
		return OperatorTypesFullName.AppointmentPackageUsedQlOperator;
	}
}
