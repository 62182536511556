import Filter from "../base-filter";

class ClassValues extends Filter {
	smallText = "Class";
	group = "Class";
	path = ["Visit", "Class"];

	get type(): string {
		return "ClassValues";
	}
}


export default ClassValues;
