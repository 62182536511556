import Birthday                  from "hm-filters/filters/birthday";
import ClientValues              from "hm-filters/filters/client-values";
import FixedValueOperator        from "hm-filters/operators/FixedValueOperator";
import OperatorGenerator         from "hm-filters/operatorGenerator";
import ClientAgeFilter           from "hm-filters/filters/ClientAgeFilter";
import TextOperator              from "hm-filters/operators/TextOperator";
import ClientHomeLocationFilter  from "hm-filters/filters/ClientHomeLocationFilter";
import PostalCodeFilter          from "hm-filters/filters/postalCodeFilter";
import ClientMboIndexFilter      from "hm-filters/filters/ClientMboIndexFilter";
import TagFilter                 from "hm-filters/filters/tagFilter";
import {Social, SocialInfluence} from "hm-filters/filters/social";
import {ClientOptOutFilter}      from "hm-filters/filters/ClientOptOutFilter";
import {ClientOptOutDateFilter}  from "hm-filters/filters/ClientOptOutDateFilter";
import {TagDateFilter}           from "hm-filters/filters/TagDateFilter";
import ClientFirstEventFilter    from "hm-filters/filters/ClientFirstEventFilter";

export const ClientFilters = [

	new ClientFirstEventFilter("Anniversary", {"field": "anniversary"})
		.setDescription(
			"Filter clients that have a first attendance to any event (group class or appointment) anniversary at your business within your selected timeframe")
		.setDateFilter(),

	new Birthday("Birthday", {"field": "birthday"}).setDescription(
		                                               "Filter clients that have a birthday within your selected timeframe")
	                                               .setDateFilter(),

	// Client values
	new ClientValues("Active Profile", {"field": "active"})
		.setOperators([
			              new FixedValueOperator(
				              true,
				              "is active"),
			              new FixedValueOperator(
				              false,
				              "is not active")
		              ])
		.hideOnClubready()
		.setDescription(
			"Client has an \"active\" or \"deactivated\" profile on booking software. This does not relate to member status."),

	new ClientValues("Frozen", {"field": "frozen"})
		.setOperators([
			              new FixedValueOperator(
				              true,
				              "is frozen"),
			              new FixedValueOperator(
				              false,
				              "is not frozen")
		              ])
		.hideOnMindbody()
		.setDeprecated()
		.setDescription(
			"Client is marked as frozen in your booking software."),

	new ClientValues("Prospect", {"field": "is_prospect"})
		.setOperators([
			              new FixedValueOperator(
				              true,
				              "is a prospect"),
			              new FixedValueOperator(
				              false,
				              "is not a prospect")
		              ])
		.setDescription(
			"Filter contacts based on their “prospect” flag in your booking software."),

	new ClientValues("Lead", {"field": "is_lead"})
		.setOperators([
			              new FixedValueOperator(
				              true,
				              "is a lead"),
			              new FixedValueOperator(
				              false,
				              "is not a lead")
		              ])
		.setDescription(
			"Filter list to show contacts that are leads in LoopSpark (a lead is a contact that is not yet in your booking software)."),

	new ClientValues("Lead creation date",
	                 {"field": "lead_added_at"})
		.setOperators(OperatorGenerator.datesPastOnly(true))
		.setDescription("Filter leads by the date their lead account was created on LoopSpark.")
		.setDateFilter(),

	new ClientAgeFilter("Age", {"field": "client_age"})
		.setDescription(
			"Filter clients by their age (data must be correct in your booking software)."),

	new ClientValues("Account creation date", {"field": "creation_date"})
		.setOperators(OperatorGenerator.datesPastOnly(true))
		.setDescription(
			"Filter clients by the date they created an account on your booking software. This is a very important filter to setup your welcome sequence")
		.setDateFilter(),

	new ClientValues("First name", {"field": "first_name"})
		.setDescription("Filter by client's first name"),
	new ClientValues("Last name", {"field": "last_name"})
		.setDescription("Filter by a client's last name"),
	new ClientValues("Address", {"field": "address_line"})
		.setDescription("Filter by clients mailing address (street name and number)"),

	new ClientValues("City", {"field": "city"})
		.setDescription("Filter clients by their mailing address city."),
	new ClientValues("Club Count (total)", {"field": "cc_club_counts"})
		.setOperators(OperatorGenerator.numericOperators())
		.setDescription(
			"Club Count is the total number of attended events (group classes plus the total number of attended appointments / private sessions)."),

	new ClientValues("Period Count", {"field": "cc_period_counts"})
		.setOperators(OperatorGenerator.numericOperators())
		.setDescription(
			"Filter by a client's Period Count (total of attended events contributing to Club Count, reset on a rolling annual basis)."),

	new ClientValues("Lifetime Value", {"field": "ltv"})
		.setOperators(OperatorGenerator.numericOperators())
		.setDescription(
			"Client's life time value (total dollar amount spent at your studio)."),

	new ClientValues("State", {"field": "state"}).setDescription("Filter by clients' mailing address postal state"),
	new ClientValues("Postal code", {"field": "postal_code"})
		.setOperators([
			              new TextOperator(
				              "=",
				              "is"),
			              new TextOperator(
				              "!=",
				              "is not")
		              ])
		.setDescription(
			"Filter by clients' mailing address postal code"),
	new ClientValues("Country", {"field": "country"})
		.setDescription("Filter clients by their mailing address country."),
	new ClientValues("Mobile phone", {"field": "mobile_phone"})
		.setDescription("Filter by a client's mobile phone number")
		.addOperators(OperatorGenerator.presenceOperators("has a mobile phone", "does not have a mobile phone")),
	new ClientValues("Email", {"field": "email"})
		.setDescription("Filter by client email addresses"),
	new ClientValues("Referral", {"field": "referred_by"})
		.addOperators(OperatorGenerator.presenceOperators())
		.hideOnClubready()
		.setDescription(
			"Booking softwares save data for client referral types (Facebook, Another Client, Pop-up class etc). This filter allows you to target client's based on their referral type"),

	new ClientValues("Member Status", {"field": "mbo_member_status"})
		.setOperators([
			              new FixedValueOperator(
				              0,
				              "is Active"),
			              new FixedValueOperator(
				              1,
				              "is Non-Member"),
			              new FixedValueOperator(
				              2,
				              "is Expired"),
			              new FixedValueOperator(
				              3,
				              "is Suspended"),
			              new FixedValueOperator(
				              4,
				              "is Terminated"),
			              new FixedValueOperator(
				              5,
				              "is Declined"),
			              new FixedValueOperator(
				              6,
				              "Other")
		              ]).visibleOnlyOn(1).setDescription(
		"Member Status filters are only available for LoopSpark clients using MINDBODY Online. The member status filter allows you to filter clients based on their MINDBODY member status. To learn more about MINDBODY member statuses, please refer to MINDBODY’s Help Article"),

	new ClientValues("Yellow Note", {"field": "yellow_alert"})
		.clearOperators()
		.addOperators(OperatorGenerator.textOperators())
		.addOperators(OperatorGenerator.textContains())
		.addOperators(OperatorGenerator.presenceOperators("has a yellow note", "does not have a yellow note"))
		.visibleOnlyOn(1)
		.setDescription(
			"Yellow Note filters are only available for LoopSpark clients using MINDBODY Online. Filter by a client's yellow note."),

	new ClientValues("Red Note", {"field": "red_alert"})
		.clearOperators()
		.addOperators(OperatorGenerator.textOperators())
		.addOperators(OperatorGenerator.textContains())
		.addOperators(OperatorGenerator.presenceOperators("has a red note", "does not have a red note"))
		.visibleOnlyOn(1)
		.setDescription(
			"Red Note filters are only available for LoopSpark clients using MINDBODY Online. Filter by a client's red note."),

	new ClientOptOutFilter("SMS Unsubscribe Status", {"field": "unsubscribed_sms_at"}).setDescription(
		"Filter clients by their SMS subscription status. This filter is very helpful to create audiences for one-time-only email campaigns encouraging unsubscribed clients to subscribe, and trigger automated emails throughout the client journey encouraging SMS subscription."),
	new ClientOptOutFilter("Email Unsubscribe Status", {"field": "unsubscribed_email_at"}).setDescription(
		"Filter clients by their email subscription status. This filter is very helpful to create audiences for one-time-only To-Do campaigns encouraging unsubscribed clients to subscribe, and trigger automated To-Dos throughout the client journey encouraging email subscription."),

	new ClientOptOutDateFilter("SMS Unsubscribe Date", {"field": "unsubscribed_sms_at"}).setDateFilter().setDescription(
		"Filter clients by the date they unsubscribed from SMS communications. This is a very helpful filter to trigger email automations to clients who recently unsubscribed from SMS, reiterating the benefits of remaining opted-in and encouraging them to opt-in to SMS communications."),
	new ClientOptOutDateFilter("Email Unsubscribe Date", {"field": "unsubscribed_email_at"})
		.setDateFilter()
		.setDescription(
			"Filter clients by the date they unsubscribed from email communications through LoopSpark. This is a very helpful filter to trigger To-Do automations for clients who recently unsubscribed from email, reiterating the benefits of remaining opted-in and encouraging them to opt-in to email communications."),

	new ClientValues("Email opt in", {"field": "email_opt_in"})
		.setOperators(OperatorGenerator.booleanOperators())
		.setDescription(
			"This filter will return clients that are marked as \"email opt-in = true\" in your booking software. This filter is specific to booking platforms, please make sure your booking platform has client data points for email opt-in"),
	new ClientValues("Email opt in (promo)",
	                 {"field": "promotional_email_opt_in"})
		.setOperators(OperatorGenerator.booleanOperators())
		.hideOnClubready()
		.setDescription(
			"This filter will return clients that are marked as eligible to receive promotional emails in your booking software. This filter is specific to booking platforms, please make sure your booking platform has client data points for email opt-in"),

	new ClientHomeLocationFilter("Home Location", {"field": "home_location_id"})
		.setDescription(
			"Home location is a data point from your booking software, to use this filter please make sure your booking software has \"home location\" and client profiles have this field filled out in your booking software."),

	new PostalCodeFilter("Distance", {"field": "distance"})
		.setDescription(
			"Filter clients my the mileage between your studio location and client mailing address (Note: this is currently only available/functional for studios located in United States)"),

	new ClientMboIndexFilter("Index", {"field": "mbo_index"})
		.visibleOnlyOn(1)
		.setDescription(
			"The client index filter will allow you to filter clients by indexes provided through your booking software, and whether or not the clients have or do not have specific indexes."
		),

	// Tag
	new TagFilter("Tag", {"field": "tagname"})
		.setDescription(
			"Tags can be added to a client's profile directly through LoopSpark. This filter allows you to narrow down your audience by clients who have, or do not have specific tags in their LoopSpark profile"),

	new TagDateFilter("Assigned Date", {"field": "tagdate"})
		.setDescription(
			"Filter clients by the date they were assigned a specific Tag. This filter will almost always require the filter Client Tag in the same rule."),

	/// SOCIAL
	// Social
	new SocialInfluence("Followers",
	                    {"field": "social_followers"})
		.followersOperators()
		.setPath([
			         "Client",
			         "Social"
		         ])
		.setDescription(
			"Filter clients by the aggregate number of followers they have for all social media accounts LoopSpark has found"),

	new Social("Account", {"field": "instagram"})
		.changeGroup("Instagram")
		.setPath([
			         "Client",
			         "Social",
			         "Instagram"
		         ])
		.setDescription(
			"This filter allows you to segment your audience by clients that LoopSpark has found an Instagram account"),
	new SocialInfluence("Followers", {"field": "instagram_followers"})
		.followersOperators()
		.changeGroup("Instagram")
		.setPath([
			         "Client",
			         "Social",
			         "Instagram"
		         ])
		.setDescription("Filter clients by the number of Instagram followers they have"),

	new Social("Account", {"field": "twitter"})
		.changeGroup("Twitter")
		.setPath([
			         "Client",
			         "Social",
			         "Twitter"
		         ]).setDescription(
		"This filter allows you to segment your audience by clients that LoopSpark has found a Twitter account"),
	new SocialInfluence("Followers",
	                    {"field": "twitter_followers"})
		.followersOperators()
		.changeGroup("Twitter")
		.setPath([
			         "Client",
			         "Social",
			         "Twitter"
		         ])
		.setDescription("Filter clients by the number of Twitter followers they have"),

	new Social("Account", {"field": "facebook"})
		.changeGroup("Facebook")
		.setPath([
			         "Client",
			         "Social",
			         "Facebook"
		         ])
		.setDescription(
			"This filter allows you to segment your audience by clients that LoopSpark has found a Facebook account"),

	new Social("Account", {"field": "linkedin"})
		.changeGroup("LinkedIn")
		.setPath([
			         "Client",
			         "Social",
			         "LinkedIn"
		         ])
		.setDescription(
			"This filter allows you to segment your audience by clients that LoopSpark has found a LinkedIn account."),
	new SocialInfluence("Followers",
	                    {"field": "linkedin_followers"})
		.followersOperators()
		.changeGroup("LinkedIn")
		.setPath([
			         "Client",
			         "Social",
			         "LinkedIn"
		         ])
		.setDescription("Filter clients by the number of LinkedIn connections they have")
];

