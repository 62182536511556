<template>
  <div class="bg-white rounded-lg shadow-xs overflow-hidden">
    <div class="grid gap-0 grid-cols-1 md:grid-cols-4">
      <automation-form-single-step
          :current-step="step"
          :disabled="false"
          :saving="saving"
          :step-status="stepsStatus.step1"
          @change="changeStep"
      >
        <template #title>
          Settings
        </template>
        <template #description>
          Setup and name of your automation
        </template>
        <template #icon>
          1
        </template>
      </automation-form-single-step>

      <automation-form-single-step
          :current-step="step"
          :disabled="false"
          :saving="saving"
          :step-status="stepsStatus.step2"
          @change="changeStep"
      >
        <template #title>
          Audience
        </template>
        <template #description>
          Who will receive props automation?
        </template>
        <template #icon>
          2
        </template>
      </automation-form-single-step>

      <automation-form-single-step
          :current-step="step"
          :disabled="false"
          :saving="saving"
          :step-status="stepsStatus.step3"
          @change="changeStep"
      >
        <template #title>
          {{ step3Data.title }}
        </template>
        <template #description>
          {{ step3Data.description }}
        </template>
        <template #icon>
          3
        </template>
      </automation-form-single-step>

      <automation-form-single-step
          :current-step="step"
          :disabled="!lastStepEnabled"
          :saving="saving"
          :step-status="{step:4,completed: false, errors: false}"
          @change="changeStep"
      >
        <template #title>
          Review & Save
        </template>
        <template #description>
          Review your automation and set it live
        </template>
        <template #icon>
          4
        </template>
      </automation-form-single-step>
    </div>
  </div>
</template>
<style scoped>
.icon-container {
  @apply w-8 h-8 flex items-center justify-center rounded-full font-medium;

  & > .icon-inside {
    @apply leading-none inline-block w-4 h-4 text-center;
  }
}

.step-current > .icon-container {
  @apply bg-indigo-500;

  & > .icon-inside {
    @apply text-white;
  }
}

</style>
<script lang="ts" setup>

import {AutomationStepsStatus}        from "@/components/automations/Create/AutomationStepStatus";
import AutomationFormSingleStep       from "@/components/automations/Create/AutomationFormSingleStep.vue";
import {computed, toRef}              from "vue";
import {QLAutomationDeliveryTypeEnum} from "@/graphql/queries/ql/composables";

interface Props {
  stepsStatus: AutomationStepsStatus;
  deliveryType: QLAutomationDeliveryTypeEnum;
  saving: boolean,
  step: number
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:step"]);


const step3Data = computed(() => {
  if (props.deliveryType == QLAutomationDeliveryTypeEnum.EMAIL || props.deliveryType == QLAutomationDeliveryTypeEnum.SMS) {
    return {
      title:       "Message",
      description: "Design your message"
    };
  }
  if (props.deliveryType == QLAutomationDeliveryTypeEnum.TODO) {
    return {
      title:       "To-Do",
      description: "Design your To-Do"
    };
  }
  if (props.deliveryType == QLAutomationDeliveryTypeEnum.TAG) {
    return {
      title:       "Tag",
      description: "Add or remove Tags"
    };
  }
  return {
    title:       "",
    description: ""
  };
});

const lastStepEnabled = computed(() => {
  for (let step in props.stepsStatus) {
    if (!props.stepsStatus[step].completed || props.stepsStatus[step].errors) {
      return false;
    }
  }
  return true;
});

const changeStep = (step) => emit("update:step", step);

const stepsStatus = toRef(props, "stepsStatus");
const saving = toRef(props, "saving");
const step = toRef(props, "step");
</script>
