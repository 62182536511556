import {QLDiscussion, QLMessage} from "@/graphql/queries/ql/composables";
import ret                       from "hm-filters/filters/visit";

export default class Message {
	static senderName(message: QLMessage, discussion: QLDiscussion): string {

		if (message.from_client) {
			return discussion.client?.first_name + " " + discussion.client?.last_name;
		}

		if (message.user?.id) {
			return message.user.name;
		}


		if (!message.user?.id && discussion.automation_id) {
			return "LoopSpark";
		}

		return "";
	}

	static messageText(message: QLMessage, discussion: QLDiscussion): string {
		if (discussion.sms) {
			return message.message;
		}
		if (!<string><unknown>message.message.includes("</html>")) {
			return message.message;
		}
		const doc = new DOMParser().parseFromString(<string>message.message, "text/html");
		const txt = doc.body.textContent || "";
		return txt.replace("Don't like these emails? Unsubscribe", "")
		          .replace("Don't like these emails? Manage Email Preferences", "")
		          .replace(" ", " ")
		          .replace(/Powered by(.*)(LoopSpark|StudioGo)+/g, "")
		          .replace(/^\s*[\r\n]/gm, "")
		          .trim();
	}

	static status(message: QLMessage) : MessageStatus{
		if(message.sent && message.delivered) return MessageStatus.DELIVERED;
		if(message.sent && message.bounced) return MessageStatus.BOUNCED;
		if(message.sent) return MessageStatus.PENDING_STATUS;

		if(message.created_at < (Math.round(Date.now() / 1000) - (10 * 60))){
			return MessageStatus.FAILED;
		}

		return MessageStatus.SENDING;
	}

	static replaceInconsequentCharacters(body: string){
		const repl = [
			["\u00AB", "\""],
			["\u00AD", "-"],
			["\u00B4", "'"],
			["\u00BB", "\""],
			["\u00F7", "/"],
			["\u01C0", "|"],
			["\u01C3", "!"],
			["\u02B9", "'"],
			["\u02BA", "\""],
			["\u02BC", "'"],
			["\u02C4", "^"],
			["\u02C6", "^"],
			["\u02C8", "'"],
			["\u02CB", "`"],
			["\u02CD", "_"],
			["\u02DC", "~"],
			["\u0300", "`"],
			["\u0301", "'"],
			["\u0302", "^"],
			["\u0303", "~"],
			["\u030B", "\""],
			["\u030E", "\""],
			["\u0331", "_"],
			["\u0332", "_"],
			["\u0338", "/"],
			["\u0589", ":"],
			["\u05C0", "|"],
			["\u05C3", ":"],
			["\u066A", "%"],
			["\u066D", "*"],
			["\u200B", " "],
			["\u2010", "-"],
			["\u2011", "-"],
			["\u2012", "-"],
			["\u2013", "-"],
			["\u2014", "-"],
			["\u2015", "--"],
			["\u2016", "||"],
			["\u2017", "_"],
			["\u2018", "'"],
			["\u2019", "'"],
			["\u201A", ","],
			["\u201B", "'"],
			["\u201C", "\""],
			["\u201D", "\""],
			["\u201E", "\""],
			["\u201F", "\""],
			["\u2032", "'"],
			["\u2033", "\""],
			["\u2034", "''"],
			["\u2035", "`"],
			["\u2036", "\""],
			["\u2037", "''"],
			["\u2038", "^"],
			["\u2039", "<"],
			["\u203A", ">"],
			["\u203D", "?"],
			["\u2044", "/"],
			["\u204E", "*"],
			["\u2052", "%"],
			["\u2053", "~"],
			["\u2060", " "],
			["\u20E5", "\\"],
			["\u2212", "-"],
			["\u2215", "/"],
			["\u2216", "\\"],
			["\u2217", "*"],
			["\u2223", "|"],
			["\u2236", ":"],
			["\u223C", "~"],
			["\u2264", "<="],
			["\u2265", ">="],
			["\u2266", "<="],
			["\u2267", ">="],
			["\u2303", "^"],
			["\u2329", "<"],
			["\u232A", ">"],
			["\u266F", "#"],
			["\u2731", "*"],
			["\u2758", "|"],
			["\u2762", "!"],
			["\u27E6", "["],
			["\u27E8", "<"],
			["\u27E9", ">"],
			["\u2983", "{"],
			["\u2984", "}"],
			["\u3003", "\""],
			["\u3008", "<"],
			["\u3009", ">"],
			["\u301B", "]"],
			["\u301C", "~"],
			["\u301D", "\""],
			["\u301E", "\""],
			["\uFEFF", " "]
		];

		for (const replKey in repl) {
			body = body.replaceAll(new RegExp(repl[replKey][0], 'gm'), repl[replKey][1]);
		}

		return body.replace( /  +/g, ' ' );
	}
}

export enum MessageStatus{
	SENDING,
	FAILED,
	PENDING_STATUS,
	DELIVERED,
	BOUNCED
}
