<template>
  <div v-if="originalForms.length > 0">
    <div class="flex items-baseline">
      <div class="grow">
        <div
            class="inline-flex items-baseline"
            :class="{'cursor-pointer': !forceOpen,'group': !forceOpen, 'hover:text-blue-900': !forceOpen}"
            @click="listOpened = !listOpened">
          <div
              v-if="!forceOpen"
              class="h-6 w-6 self-center">
            <div v-show="listOpened">
              <svg-icon
                  name="minus"
                  class="h-6 w-6 self-center" />
            </div>
            <div v-show="!listOpened">
              <svg-icon
                  name="down"
                  class="h-6 w-6 self-center" />
            </div>
          </div>
          <h2 class="text-lg font-medium">
            {{ categoryName }}
          </h2>

        </div>
      </div>
      <div class="ml-2 px-2 bg-gray-50 rounded text-gray-500">
        <span class="text-green-900"><span class="font-semibold">{{ formsActiveCount }}</span> active</span>
        <span v-if="includeInactiveCount"> - </span>
        <span v-if="includeInactiveCount"
              class="text-gray-500">
              <span class="font-semibold">{{ formsInactiveCount }}</span> archived</span>
      </div>
    </div>
    <div
        v-show="forceOpen || listOpened"
        class="mt-2 mb-4 bg-white rounded-lg shadow divide-y overflow-hidden">
      <div
          v-for="form in orderedForms"
          :key="form.id">
        <form-index-single
            :form="form"
            class="grow" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SvgIcon from "@/components/SvgIcon.vue";

import {computed, ref, toRef, useAttrs, useSlots} from "vue";
import {QLForm}                                   from "@/graphql/queries/ql/composables";
import FormIndexSingle                            from "@/views/Forms/Components/Index/FormIndexSingle.vue";

interface Props {
  forms: QLForm[];
  includeInactiveCount?: boolean;
  forceOpen?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  includeInactiveCount: false,
  forceOpen:            false
});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const originalForms = toRef(props, "forms");

const listOpened = ref(false);

const orderedForms = computed(() => {
  return originalForms.value
                      .map((i) => i)
                      .sort((a, b) => {
                        return a.name > b.name ? 1 : -1;
                      });
});

const categoryName = computed(() => {
  if (props.forms[0].category == null) {
    return "Uncategorized";
  }
  return props.forms[0].category;
});

const formsActiveCount = computed(() => {
  return props.forms.filter((a) => !a.is_archived).length;
});

const formsInactiveCount = computed(() => {
  return props.forms.filter((a) => a.is_archived).length;
});

</script>
