<template>
  <filter-group-base-category
      v-model:filter-selected="filterSelected"
      v-model:operator-selected="operatorSelected"
      :category-name="categoryName"
      @step-back="stepBack"
      @added-rule="addedRule($event)"
  >
    <template #title>
      New Filter - Communications
    </template>
    <template #subtitle>
      The Communications filters are all related to the content, timeframe, and user action
      created from your communication. Want to target users who opened an email but never
      purchased anything? Or create a retention campaign to contact clients 30 days after each
      prior retention email? These are the filter for you.
    </template>
    <template #side-description>
      <p>
        Rules containing communication filters almost <strong>ALWAYS</strong> contain 2 more communication’s
        filters. Make sure to checkout the following examples of how each filter is used in combination with each other
        to form
        rules.
      </p>

      <div class="pt-4">
        <p>
          Examples:
        </p>

        <ol class="filter-side-examples pt-2">
          <li>
            <div class="filter-side-examples-filters">
              <span class="fake-filter">Communication Received</span> +
              <span class="fake-filter">Communication History</span>
            </div>
            <div class="filter-side-examples-info">
              Show me clients who received a specific
              communication
              within my selected timeframe.
            </div>
          </li>
          <li>
            <div class="filter-side-examples-filters">
              <span class="fake-filter">Communication Received</span> +
              <span class="fake-filter">Communication Action</span>
            </div>
            <div class="filter-side-examples-info">
              Show me clients who received a specific
              communication, and
              opened or clicked on the communication.
            </div>
          </li>
          <li>
            <div class="filter-side-examples-filters">
              <span class="fake-filter">Communication Received</span> +
              <span class="fake-filter">Communication History</span> +
              <span class="fake-filter">Communication Action</span>
            </div>
            <div class="filter-side-examples-info">
              Show me clients who received a
              specific communication within my selected
              timeframe, and opened or clicked on the
              communication.
            </div>
          </li>
        </ol>
      </div>
    </template>
    <template #filters>
      <slot />
    </template>
  </filter-group-base-category>
</template>
<script lang="ts">
import {singleCategoryComposition} from "@/components/Segment/Edit/Categories/SingleCategories/singleCategoryComposition";

const cat = singleCategoryComposition();
export default {
  components: {
    ...cat.components
  },
  props:      {
    ...cat.props
  },
  emits:      cat.emits,
  setup(props, {emit}) {
    return cat.setup(props, emit);
  }
};
</script>
