<template>
  <div :class="['border border-gray-100 rounded', wasUnread ? 'bg-green-50': 'bg-white']">
    <div class="flex items-center p-3">
      <div class="grow ">
        <div class="flex justify-between">
          <div class="grow">
            <p class="pb-4">{{ notification.body }}</p>
            <p
                v-if="notification.team"
                class="text-sm font-semibold text-gray-700">
              {{ notification.team.name }}
            </p>
          </div>

          <div>
            <time-ago
                :timestamp="notification.created_at"
                class="text-sm text-gray-600 whitespace-nowrap"
                :class="{'text-green-700': wasUnread}" />
          </div>
        </div>

        <div class="block pt-3" v-if="notificationUrl">
          <a
              class="border border-blue-400 text-blue-600 px-2 py-1 rounded hover:bg-blue-700 hover:border-blue-700 hover:text-white font-weight-bold text-sm"
              :href="notificationUrl">{{
              notification.action_text
                                      }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
//TODO: This need to use the new icon system. Also needs to replace the old "fa-" icons to the new ones somehow

import {DateTime}                                  from "luxon";
import TimeAgo                                     from "@/components/layout/TimeAgo.vue";
import {QLNotificationFragmentFragment}            from "@/graphql/queries/ql/composables";
import {computed, onMounted, PropType, ref, toRef} from "vue";

interface Props {
  notification: QLNotificationFragmentFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const notification = toRef(props, "notification");
const wasUnread = computed(() => {
  return !notification.value.read;
});

const notificationUrl = computed(() => {
  if (notification.value.action_url?.startsWith("/")) {
    return import.meta.env.VITE_APP_MAIN_URL + notification.value.action_url;
  }

  return notification.value.action_url;
});

const timeDiff = computed(() => {
  let dt = DateTime.fromSeconds(notification.value.created_at);
  return dt.toRelative().toString();
});
</script>
