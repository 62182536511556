<template>
  <filter-group-base-category
      v-model:filter-selected="filterSelected"
      v-model:operator-selected="operatorSelected"
      :category-name="categoryName"
      @step-back="stepBack"
      @added-rule="addedRule($event)">
    <template #title>
      New Filter - Booking
    </template>
    <template #subtitle>
      The Booking category contains filters regarding all client bookings (group classes,
      appointments, etc). Bookings can be for events that occurred in the past, are scheduled to
      occur in the future, and can be further filtered by the client’s attendance status
      (attended, upcoming (scheduled to attend), early cancel, late cancel etc) and event
      details (event type, date range, location, staff etc).
    </template>
    <template #side-description>
      <div class="space-y-4">
        <p>
          Booking rules will almost always contain filters for <span class="fake-filter">booking status</span> ,
          <span class="fake-filter">booking count</span> and <span class="fake-filter">event type</span>.
        </p>
        <div>
          <p><strong>The sub-rules are as follows</strong>:</p>
          <ol class="filter-side-examples">
            <li>
              <span class="font-medium">Event</span> - The Event sub-category contains information about the specific
                                                     event, including: Event name, event type (group class vs
                                                     appointment/private), date range of event, event start and end
                                                     times, day of week, virtual/non-virtual, staff name instructing
                                                     event, event location, event cancellation.
            </li>
            <li>
              <span class="font-medium">Count</span> - The number of bookings made by a client. <span
                class="fake-filter">Count</span> will almost always occur in a rule with
              <span class="fake-filter">booking status</span> , <span class="fake-filter">event type</span> , or <span
                class="fake-filter">event date</span>
            </li>
            <li>
              <span class="font-medium">Status</span> - By default, a client booking does not imply
                                                      attendance (example: Booking Count = 1 does not
                                                      define a client that has actually attended an event,
                                                      only that the client has made a booking). Adding the
              <span class="fake-filter">status</span> filter to a booking rule will filter results based on the
                                                      actual status of a client’s booked event. <span
                class="fake-filter">Status</span> should be present in almost all booking rules, along with <span
                class="fake-filter">count</span> and <span class="fake-filter">event type</span>.
            </li>
            <li>
              <span class="font-medium">Using Package</span> - Which package was used to make a
                                                             booking? <span class="fake-filter">Using Package</span>
                                                             should be added to rules
                                                             containing <span class="fake-filter">booking status</span>
                                                             , <span class="fake-filter">booking count</span>
                                                             and <span class="fake-filter">event type</span>
            </li>
          </ol>
        </div>
      </div>
    </template>
    <template #filters>
      <slot />
    </template>
  </filter-group-base-category>
</template>
<script lang="ts">
import {
  singleCategoryComposition
} from "@/components/Segment/Edit/Categories/SingleCategories/singleCategoryComposition";

const cat = singleCategoryComposition();
export default {
  components: {
    ...cat.components
  },
  props:      {
    ...cat.props
  },
  emits:      cat.emits,
  setup(props, {emit}) {
    return cat.setup(props, emit);
  }
};
</script>
