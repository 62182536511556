import ClientGroup      from "./partials/client";
import LocationOperator from "hm-filters/operators/LocationOperator";

class ClientHomeLocationFilter extends ClientGroup {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new LocationOperator("=", "is"),
			new LocationOperator("!=", "is not")
		];
	}

	get type(): string {
		return "ClientHomeLocationFilter";
	}
}

export default ClientHomeLocationFilter;
