<template>
  <tr>
    <td class="py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
      {{ line.name }}
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-right text-gray-500">
      <div v-if="line.last_completion_at > 0">
        <timestamp-to-date
            :timestamp="line.last_completion_at"
            has-time
            class="block text-gray-500" />
        <time-ago
            :timestamp="line.last_completion_at"
            class="block text-sm text-gray-500 pt-1" />
      </div>
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right">
                    <span
                        class="inline-flex items-center px-3 py-0.5 rounded-full text-sm"
                        :class="[line.cc > 0 ? 'bg-green-100 text-green-800 font-bold' : 'font-medium bg-red-100 text-red-800']">{{
                        line.cc
                                                                                                                                      }}</span>
    </td>
    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
      <client-forms-recap-line-dropdown :form-fragment="line" :client="client"/>
    </td>
  </tr>
</template>
<script lang="ts" setup>
import {QLClient, QLFragmentFormWithCountFragment} from "@/graphql/queries/ql/composables";
import TimestampToDate                             from "@/components/layout/TimestampToDate.vue";
import TimeAgo                               from "@/components/layout/TimeAgo.vue";
import ClientFormsRecapLineDropdown
  from "@/views/ClientShow/Components/Forms/ClientFormsRecapLineDropdown.vue";
interface Props {
  line:  QLFragmentFormWithCountFragment;
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {
});

import {ref, useSlots, useAttrs} from "vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
</script>
