import Filter from "../../base-filter";

import config                  from "./config";
import MultipleTextOperator    from "hm-filters/operators/MultipleTextOperator";
import {PackageNameQlOperator} from "hm-filters/filters/packageNameFilter";

class VisitPackageUsed extends Filter {
	smallText = config.visits.groups.visit.group;
	group = config.visits.groups.visit.group;
	path = ["Visit", this.group];

	constructor(name: string, options: any) {
		super(config.visits.usingPackage, options);

		this.operators = [
			new PackageNameQlOperator("=", "used").setIsMultipleChoice(),
			new MultipleTextOperator("contains", "contains"),
			new MultipleTextOperator("does_not_contain", "does not contain")
		];
	}

	get type(): string {
		return "VisitPackageUsed";
	}
}

class LateCancelPackageUsed extends VisitPackageUsed {
	smallText = config.visits.groups.lateCancelled.group;
	group = config.visits.groups.lateCancelled.group;
	path = ["Visit", this.group];

	get type(): string {
		return "LateCancelPackageUsed";
	}
}

class AbsentPackageUsed extends VisitPackageUsed {
	smallText = config.visits.groups.absent.group;
	group = config.visits.groups.absent.group;
	path = ["Visit", this.group];

	get type(): string {
		return "AbsentPackageUsed";
	}
}

class EarlyCancelPackageUsed extends VisitPackageUsed {
	smallText = config.visits.groups.early_cancel.group;
	group = config.visits.groups.early_cancel.group;
	path = ["Visit", this.group];

	get type(): string {
		return "EarlyCancelPackageUsed";
	}
}

class UpcomingVisitPackageUsed extends VisitPackageUsed {
	smallText = config.visits.groups.upcomingVisit.group;
	group = config.visits.groups.upcomingVisit.group;
	path = ["Visit", this.group];

	get type(): string {
		return "UpcomingVisitPackageUsed";
	}
}

export {
	VisitPackageUsed,
	LateCancelPackageUsed,
	UpcomingVisitPackageUsed,
	AbsentPackageUsed,
	EarlyCancelPackageUsed
};
