<template>
  <div class="bg-white shadow rounded overflow-hidden h-56">
    <div class="relative">
      <transition-group
          class="object-cover h-56"
          name="fadecarousel"
          tag="div">
        <div
            v-for="i in [currentIndex]"
            :key="i">
          <img
              :src="currentImg"
              alt=""
              class="object-cover h-56 w-full ">
        </div>
      </transition-group>
      <div
          v-if="photos.length > 0"
          class="absolute bottom-0 left-0 w-full">
        <div class="flex justify-between p-2" v-if="photos.length > 1">
          <button
              class="bg-white rounded-full text-gray-700 h-8 w-8 flex justify-center align-center items-center hover:text-blue-800 hover:bg-blue-200 opacity-75 hover:opacity-100"
              @click="prevPage">
            <svg-icon
                name="left"
                class="h-4 w-4 fill-current" />
          </button>
          <button
              class="bg-white rounded-full text-gray-700 h-8 w-8 flex justify-center align-center items-center hover:text-blue-800 hover:bg-blue-200 opacity-75 hover:opacity-100"
              @click="nextPage">
            <svg-icon
                name="right"
                class="h-4 w-4 fill-current" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.fadecarousel-enter-active,
.fadecarousel-leave-active {
  transition:all 0.9s ease;
  overflow:hidden;
  visibility:visible;
  position:absolute;
  width:100%;
  opacity:1;
}

.fadecarousel-enter,
.fadecarousel-leave-to {
  visibility:hidden;
  width:100%;
  opacity:0;
}
</style>
<script lang="ts" setup>
import {QLMedia}                                          from "@/graphql/queries/ql/composables";
import SvgIcon                                            from "@/components/SvgIcon.vue";
import {computed, onBeforeUnmount, onMounted, ref, toRef} from "vue";
import Timeout = NodeJS.Timeout;

interface Props {
  photos: QLMedia[];
}

const props = withDefaults(defineProps<Props>(), {});

const currentIndex = ref(0);
const timer = ref<Timeout | null>(null);

const photos = toRef(props, "photos");

const currentImg = computed(() => {
  return photos.value[Math.abs(currentIndex.value) % photos.value.length].url;
});

const startTimer = () => {
  if(photos.value.length > 1) {
    stopTimer();
    timer.value = setTimeout(nextPage, 4000);
  }
};

const stopTimer = () => {
  if (timer.value !== null) {
    clearTimeout(timer.value);
  }
};

const nextPage = () => {
  currentIndex.value += 1;
  startTimer();
};

const prevPage = () => {
  currentIndex.value -= 1;
  startTimer();
};

onMounted(startTimer);
onBeforeUnmount(stopTimer);

</script>
