<template>
  <div>
    <div class="md:flex items-center">
      <div class="flex items-center grow">
        <div class="mr-3">
          <avatar :image="staff.photo_url" :username="staff.name" class="w-12 h-12"></avatar>
        </div>
        <div class="grow">
          <div class="font-semibold">{{ staff.name }}</div>
          <div class="text-sm text-gray-500">{{ staff.email }}</div>
        </div>
      </div>
      <div class="flex justify-between md:block mt-4 md:mt-0">
        <div class="capitalize text-sm font-semibold tracking-wide text-green-900 text-right">
          {{ staff.role.toLowerCase() }}
        </div>
        <studio-staff-list-item-update-role v-if="canChangeRole" :staff="staff" :me="me">
          <button class="text-link text-xs text-blue-900">
            Change access
          </button>
        </studio-staff-list-item-update-role>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Avatar                        from "@/components/layout/Avatar.vue";
import StudioStaffListItemUpdateRole from "@/components/studio/settings/Staff/StudioStaffListItemUpdateRole.vue";

import {QLFragmentCurrentTeamFragment, QLFragmentUserMeFragment, QLUserWithRoleFragmentFragment} from "@/graphql/queries/ql/composables";
import {computed, toRef, useAttrs, useSlots}                                                     from "vue";

interface Props {
  staff: QLUserWithRoleFragmentFragment;
  currentTeam: QLFragmentCurrentTeamFragment;
  me: QLFragmentUserMeFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const staff = toRef(props, "staff");
const me = toRef(props, "me");

const canChangeRole = computed(() => {
  // user can't change themselves
  if (staff.value.id === me.value.id) {
    return false;
  }
  if (!me.value.policy.staff_change || !me.value.policy.staff_remove) {
    return false;
  }
  // if is admin, can't change admins and owners
  if (me.value.policy.is_admin) {
    const role = staff.value.role.toLowerCase();
    if (role === "owner" || role === "admin") {
      return false;
    }
  }

  return me.value.policy.staff_change || me.value.policy.staff_remove;
});

</script>
