<template>
  <div class="bg-white shadow-lg rounded-lg">
    <div
        v-if="!loading && segments.length > 0"
        class="divide-y">
      <dashboard-segment-item
          v-for="segment in segments"
          :key="segment.id"
          :segment="segment" />
    </div>

    <div
        v-if="loading"
        class="h-48 flex items-center justify-center">
      <loader class="w-12 h-12 text-gray-300" />
    </div>

    <empty v-if="!loading && segments.length === 0">
      <template #title>
        You don't have any segment yet.
      </template>
      <template #subtitle>
        <router-link
            :to="{ name: routeList.clients.index }"
            class="text-link">
          Create a new one
        </router-link>
        in the Clients page.
      </template>
    </empty>
  </div>
</template>
<script lang="ts" setup>
import Empty from "@/components/layout/Empty.vue";

import DashboardSegmentItem        from "@/components/dashboard/DashboardSegmentItem.vue";
import Loader                      from "@/components/layout/Loader.vue";
import {useDashboardSegmentsQuery} from "@/graphql/queries/ql/composables";
import {computed, onMounted}       from "vue";
import {useResult}                 from "@vue/apollo-composable";
import routesList                  from "@/composables/routesList";

const {result, loading, refetch} = useDashboardSegmentsQuery();
const dashSegments = useResult(result, [], data => data.dashboard_segments);

const segments = computed(() => {
  return [...dashSegments.value].sort((a, b) => a.order < b.order
                                                ? -1
                                                : 1
  ).slice(0, 10);
});

const {routeList} = routesList();

</script>
