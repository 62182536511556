<template>
  <div>
    <div class="block h-48 flex items-center justify-center" v-if="!me">
      <loader class="w-8 h-8"></loader>
    </div>
    <form v-if="me"
          ref="form"
          class="block overflow-hidden shadow-md rounded-lg"
          @submit.prevent="send">
      <div class="space-y-6 bg-white p-4">
        <text-input
            v-model="formData.name"
            autocomplete="name"
            :error-message="errors.get('input.name')"
            label="Full name *"
            required />
        <text-input
            v-model="formData.email"
            autocomplete="email"
            :error-message="errors.get('input.email')"
            label="Email *"
            required
            type="email" />
        <div>
          <label
              class="form-label mb-2"
              :class="{'has-error': errors.has('input.photo')}">Profile photo
            <span class="text-xs font-normal">(optional)</span></label>

          <div class="flex items-center space-x-4">
            <img
                ref="imgpreview"
                alt="preview"
                :src="imgSrc"
                class="w-16 h-16 rounded-full shrink-0">
            <div class="shrink-0">
              <label class=" flex flex-col items-center px-4 py-4 text-gray-800 bg-white rounded-lg tracking-wide  border border-gray-400 cursor-pointer hover:bg-loopspark-700 hover:text-white">
                <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span class="mt-1 text-sm leading-normal">Select a photo</span>
                <input
                    ref="file"
                    :disabled="sending"
                    accept="image/*"
                    class="hidden"
                    type="file"
                    @change="handleFileUpload()">
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-100">
        <div class="flex justify-end p-4 py-3">
          <v-button
              :loading="sending"
              class="btn btn-green text-sm font-normal"
              type="submit">
            Update profile
          </v-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script lang="ts" setup>
import Loader from "@/components/layout/Loader.vue";

import TextInput                                   from "@/components/layout/Forms/TextInput.vue";
import VButton                                     from "@/components/layout/VButton.vue";
import {computed, onMounted, reactive, ref, watch} from "vue";
import SparkFormErrors, {SparkErrors}              from "@/classes/utils/SparkFormErrors";
import meQuery                                     from "@/composables/queries/meQuery";
import {useUpdateUserMutation}                     from "@/graphql/queries/ql/composables";
import $toast                                      from "@/composables/toast";
import fillValidationErrorsOrToast                 from "@/composables/queries/mutations/fillValidationErrorsOrToast";

const form = ref<HTMLFormElement>(null);
const file = ref<HTMLInputElement>(null);
const imgpreview = ref<HTMLImageElement>(null);
const imgSrc = ref(null);
const sending = ref(false);

const formData = reactive({
                            email: "",
                            name:  "",
                            photo: null
                          });

const errorsData = ref<SparkErrors>({});
const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

const {me, loading} = meQuery();

const handleFileUpload = () => {
  formData.photo = file.value.files[0];
  imgpreview.value.src = URL.createObjectURL(formData.photo);
  imgpreview.value.onload = () => {
    URL.revokeObjectURL(formData.photo);
  };
};
const loadFromQL = () => {
  if (me.value) {
    formData.name = me.value.name;
    formData.email = me.value.email;
    imgSrc.value = me.value.photo_url;
  }
};

watch(me, () => {
  loadFromQL();
});

onMounted(() => {
  sending.value = false;
  if (me.value) {
    loadFromQL();
  }
});

const updateUserMutation = useUpdateUserMutation({
                                                   context: {
                                                     hasUpload: true
                                                   }
                                                 });

updateUserMutation.onDone(() => {
  sending.value = false;
  $toast({
           type:         "success",
           message:      "Information changed!",
           linkRouteObj: null,
           linkText:     null
         });
});

updateUserMutation.onError((error) => {
  console.log(error);
  sending.value = false;
  fillValidationErrorsOrToast(error, errorsData, "Error updating your information. Please try again!");
});

const send = () => {
  errorsData.value = {};
  if (!form.value.reportValidity()) {
    return;
  }
  sending.value = true;
  updateUserMutation.mutate({
                              input: {
                                email: formData.email,
                                name:  formData.name,
                                photo: formData.photo
                              }
                            });
};

</script>
