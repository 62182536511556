<template>
  <div class="shadow bg-white rounded">
    <div class="flex-col">
      <div class="p-4 border-b">
        <div class="pt-2">
          <h1 class="font-medium text-xl leading-tight mb-2">
            {{ automation.name }}
          </h1>
          <p class="text-sm text-gray-500">
            Category: {{
              automation.category_name
              ? automation.category_name
              : "Uncategorized"
            }}
          </p>
          <p class="text-xs text-gray-500 truncate hover-all-text">
            {{ automation.description }}
          </p>
        </div>
      </div>
    </div>
    <dl class="space-y-8 py-5">
      <automation-info-box-line
          v-if="!automation.archived && automation.active"
          title-class="text-green-800">
        <template #icon>
          <svg-icon
              name="play"
              class="w-full h-full" />
        </template>
        <template #title>
          <span>Active</span>
        </template>
        <template #smalltext>
          Automation is active and will trigger for clients who match your audience criteria.
          <button
              v-if="canEditAutomations"
              class="text-link"
              @click="openStartStop">
            Pause...
          </button>
        </template>
      </automation-info-box-line>

      <automation-info-box-line
          v-if="!automation.archived && !automation.active && !decorated.isDelayed"
          :title-class="pausedClass">
        <template #icon>
          <svg-icon
              name="pause"
              class="w-full h-full" />
        </template>
        <template #title>
          <span
              v-if="!automation.one_off"
              class="text-red-800">Off</span>
          <span
              v-if="automation.one_off && automation.ever_ran"
              class="text-green-800">Completed</span>
          <span
              v-if="automation.one_off && !automation.ever_ran"
              class="text-yellow-800">Yet to run</span>
        </template>
        <template #smalltext>
          Automation is inactive and will not trigger any action until activated.
          <button
              v-if="canEditAutomations && !automation.one_off"
              class="text-link"
              @click="openStartStop">
            Activate...
          </button>
          <button
              v-if="canEditAutomations && automation.one_off && automation.ever_ran"
              class="text-link"
              @click="openStartStop">
            Run
            again...
          </button>
          <button
              v-if="canEditAutomations && automation.one_off && !automation.ever_ran"
              class="text-link"
              @click="openStartStop">
            Run
            now...
          </button>
        </template>
      </automation-info-box-line>

      <automation-info-box-line
          v-if="automation.archived"
          title-class="text-red-800">
        <template #icon>
          <svg-icon
              name="trash"
              class="w-full h-full" />
        </template>
        <template #title>
          Archived
        </template>
        <template #smalltext>
          Automation is archived and inactive. To activate or edit your automation, click unarchive.
        </template>
      </automation-info-box-line>

      <automation-info-box-line
          v-if="decorated.isDelayed"
          title-class="text-yellow-800">
        <template #icon>
          <svg-icon
              name="timer"
              class="w-full h-full" />
        </template>
        <template #title>
          Delayed
        </template>
        <template #smalltext>
          Automation is delayed and will start on
          <timestamp-to-date
              :timestamp="automation.start_at"
              class="font-medium"
              has-time
              natural-date />
          .
          <button
              v-if="canEditAutomations"
              class="text-link"
              @click="openStartStop">
            Run now...
          </button>
        </template>
      </automation-info-box-line>

      <!--      Action type -->

      <automation-info-box-line v-if="decorated.via_email">
        <template #icon>
          <svg-icon
              name="email"
              class="w-full h-full" />
        </template>
        <template #title>
          <span>Via email</span>
        </template>
        <template #smalltext>
          Automation will send an email to clients who match your audience criteria and are eligible
          to receive email communications.
          <button
              class="text-link"
              @click="openEmail">
            Preview the email
          </button>
        </template>
      </automation-info-box-line>

      <automation-info-box-line v-if="decorated.via_sms">
        <template #icon>
          <svg-icon
              name="mobile-alt"
              class="w-full h-full" />
        </template>
        <template #title>
          <span>Via SMS</span>
        </template>
        <template #smalltext>
          Automation will send an SMS to clients who match your audience criteria and are eligible to receive SMS
          communications.
          <button
              class="text-link"
              @click="openSms">
            Preview the SMS
          </button>
        </template>
      </automation-info-box-line>

      <automation-info-box-line v-if="decorated.via_todo">
        <template #icon>
          <svg-icon
              name="todo"
              class="w-full h-full" />
        </template>
        <template #title>
          <span>Creates To-Dos</span>
        </template>
        <template #smalltext>
          Automation will create a To-Do for clients who match your audience criteria.
          <button
              class="text-link"
              @click="openTodo">
            Preview the To-Do
          </button>
        </template>
      </automation-info-box-line>

      <automation-info-box-line v-if="decorated.via_tag">
        <template #icon>
          <svg-icon
              name="tag"
              class="w-full h-full" />
        </template>
        <template #title>
          <span v-text="automation.tags_add ? 'Adds Tags': 'Removes Tags'" />
        </template>
        <template #smalltext>
          <span v-if="automation.tags_add">Automation will assign the followin tags in each client's profile who matches your audience criteria.</span>
          <span v-if="!automation.tags_add">Automation will remove the followin tags in each client's profile who matches your audience criteria.</span>
          <div class="flex flex-wrap space-x-2 leading-loose">
            <div
                v-for="tag in automation.tags"
                :key="tag"
                class="py-1">
              <span class="block px-1 bg-gray-100 leading-none py-1 rounded">{{ tag }}</span>
            </div>
          </div>
        </template>
      </automation-info-box-line>

      <!--      end Action type -->

      <automation-info-box-line v-if="!automation.one_off">
        <template #icon>
          <svg-icon
              name="repeat"
              class="w-full h-full" />
        </template>
        <template #title>
          <span>Continuous</span>
        </template>
        <template #smalltext>
          Evergreen automation will continue to trigger for clients that match your audience criteria
          as long as automation is active.
        </template>
      </automation-info-box-line>
      <automation-info-box-line v-if="automation.one_off">
        <template #icon>
          <svg-icon
              name="bullhorn"
              class="w-full h-full " />
        </template>
        <template #title>
          <span>One Time Only</span>
        </template>
        <template #smalltext>
          Automation will trigger one time only for clients that match your audience criteria at point of activation.
          Automation will automatically deactivate when completed.
        </template>
      </automation-info-box-line>

      <automation-info-box-line>
        <template #icon>
          <svg-icon
              name="users"
              class="w-full h-full " />
        </template>
        <template #title>
          <span>{{ automation.segment.name }}</span>
        </template>
        <template #smalltext>
          Automation will trigger for all clients that match the criteria for audience {{ automation.segment.name }} and
          are eligible to receive automation medium type.
          <button
              class="text-link"
              @click="openSegment">
            View the audience
          </button>
        </template>
      </automation-info-box-line>

      <automation-info-box-line>
        <template #icon>
          <svg-icon
              name="clock"
              class="w-full h-full " />
        </template>
        <template #title>
          <span>{{ decorated.sendWindowStr }}</span>
        </template>
        <template #smalltext>
          <span v-if="decorated.isSendWindowImmediate">Automation will be dispatched immediately for clients that match your audience criteria.</span>
          <span v-if="!decorated.isSendWindowImmediate">Automation will only dispatch between the hours {{
              decorated.sendWindowStr
                                                        }} for clients that match your audience criteria.</span>
        </template>
      </automation-info-box-line>
    </dl>
    <email-preview
        v-if="decorated.via_email"
        v-model:open="emailPreview"
        :body="automation.email_content.html"
        :subject="automation.subject" />
    <sms-preview
        v-if="decorated.via_sms"
        v-model:open="smsPreview"
        :message-content="automation.content" />

    <automation-view-segment
        v-model:open="segmentPreview"
        :segment="automation.segment" />

    <automation-show-todo-preview
        v-model:open="todoPreview"
        :automation="automation" />

    <automation-start-stop-modal
        v-model:open="startStopModal"
        :automation="automation" />
  </div>
</template>
<style scoped>
dl.alternate-bg > div:nth-child(odd) {
  @apply bg-gray-50;
}

dl.alternate-bg > div:nth-child(even) {
  @apply bg-white;
}

</style>

<script lang="ts" setup>
import AutomationInfoBoxLine                      from "@/components/automations/Show/partials/AutomationInfoBoxLine.vue";
import TimestampToDate                            from "@/components/layout/TimestampToDate.vue";
import EmailPreview                               from "@/components/automations/EmailPreview.vue";
import SmsPreview                                 from "@/components/automations/Show/SmsPreview.vue";
import AutomationViewSegment                      from "@/components/automations/Show/AutomationViewSegment.vue";
import AutomationShowTodoPreview                  from "@/components/automations/Show/Infos/Todos/AutomationShowTodoPreview.vue";
import AutomationStartStopModal                   from "@/components/automations/Show/partials/AutomationStartStopModal.vue";
import {computed, ref, toRef, useAttrs, useSlots} from "vue";
import {QLFragmentFullAutomationFragment}         from "@/graphql/queries/ql/composables";
import AutomationDecorator                        from "@/composables/decorators/AutomationDecorator";
import meQuery                                    from "@/composables/queries/meQuery";
import SvgIcon                                    from "@/components/SvgIcon.vue";


interface Props {
  automation: QLFragmentFullAutomationFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emailPreview = ref(false);
const smsPreview = ref(false);
const segmentPreview = ref(false);
const todoPreview = ref(false);
const startStopModal = ref(false);
const deletingTags = ref(false);

const {me} = meQuery();
const automation = toRef(props, "automation");

const canEditAutomations = computed(() => {
  if(!me.value) return false;
  return me.value.policy.edit_automations;
});

const openStartStop = () => {
  startStopModal.value = true;
};

const openEmail = () => {
  emailPreview.value = true;
};

const openSms = () => {
  smsPreview.value = true;
};

const openSegment = () => {
  segmentPreview.value = true;
};

const openTodo = () => {
  todoPreview.value = true;
};

const pausedClass = computed(() => {
  if (props.automation.one_off && !props.automation.ever_ran) {
    return "text-yellow-800";
  }
  if (props.automation.one_off && props.automation.ever_ran) {
    return "text-green-800";
  }
  return "text-red-800";
});

const dec = AutomationDecorator(props.automation);

const decorated = computed(() => {
  return dec.computed;
});

</script>
