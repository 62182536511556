<template>
  <copy-dialog
      v-model:open="openModel"
      v-model:selected-team="selectedTeam"
      :sending="sending"
      :success="success"
      :teams="teams"
      @clicked="sendNow">
    <template #title>
      Copy this segment to:
    </template>
    <template #description>
      Select a studio where you'd like to copy this audience segment. You may only copy this segment to a location where
      you have manager, admin or owner access.
    </template>
    <template #success>
      Segment successfully copied!
    </template>
  </copy-dialog>
</template>
<script lang="ts" setup>
import {QLFragmentTeamWithOwnerFragment, QLSegment, useCopySegmentMutation} from "@/graphql/queries/ql/composables";
import {useAttrs, useSlots}                                                 from "vue";
import copyDialog                                                           from "@/composables/copyDialog";
import CopyDialog                                                           from "@/components/layout/CopyDialog.vue";

interface Props {
  segment: QLSegment;
  teams: QLFragmentTeamWithOwnerFragment[];
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open"]);

const mut = useCopySegmentMutation({});

const {sending, success, openModel, sendNow, selectedTeam} = copyDialog(props.segment.id, mut, props, emit);
</script>
