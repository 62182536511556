<template>
  <div>
    <h2 class="text-xl mb-3">
Completed Forms
</h2>
    <div v-if="loadedFirstTime">
      <div v-if="forms && forms.length === 0">
        <empty>
          <template #title>
            Nothing to show!
          </template>
          <template #subtitle>
            <p class="text-gray-600 pt-2">
              {{ client.first_name }} has not completed any forms yet!
            </p>
          </template>
        </empty>
      </div>

      <div
          v-if="forms && forms.length > 0">
        <div class="flex flex-col">
          <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 md:px-6 lg:px-8 align-middle">
              <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table class="table table-full-mobile divide-y">
                  <thead>
                  <tr>
                    <table-th
                        :is-down="orderBy.column === 'forms.name' && !isOrderUp"
                        :is-up="orderBy.column === 'forms.name' && isOrderUp"
                        has-sort
                        @down="changeOrder('forms.name', false)"
                        @up="changeOrder('forms.name', true)">
                      Form name
                    </table-th>
                    <table-th
                        :is-down="orderBy.column === 'created_at' && !isOrderUp"
                        :is-up="orderBy.column === 'created_at' && isOrderUp"
                        class="text-right md:whitespace-nowrap"
                        has-sort
                        @down="changeOrder('created_at', false)"
                        @up="changeOrder('created_at', true)">
                      Completion date
                    </table-th>
                    <table-th>&nbsp;</table-th>
                  </tr>
                  </thead>
                  <tbody
                      v-if="forms && forms.length > 0"
                      class="bg-white divide-y divide-gray-200">
                  <tr
                      v-for="form in forms"
                      :key="form.id">
                    <td class="td w-full">
                      <div class="text-gray-900 text-sm md:text-base">
                        {{ form.form.name }}
                      </div>
                      <div class="text-gray-500 text-sm pt-1">
                        {{ form.form.category }}
                      </div>
                    </td>
                    <td class="td text-right">
                      <timestamp-to-date
                          :timestamp="form.created_at"
                          has-time
                          class="block text-gray-500 text-sm md:text-base" />
                      <time-ago
                          :timestamp="form.created_at"
                          class="block text-sm text-gray-500 pt-1" />
                    </td>
                    <td class="td text-sm md:text-base text-right">
                      <router-link
                          :to="{ name: routeList.client.forms.show, params: {id: client.id, formInstanceId: form.id}}"
                          class="text-link font-medium">
                        View
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot
                      v-if="forms && paginatorInfo.total > 0&& paginatorInfo.lastPage > 1"
                      class="bg-gray-50">
                  <tr>
                    <td colspan="3">
                      <div class="w-full py-2 text-right px-4 rounded-b">
                        <pagination
                            v-if="paginatorInfo.lastPage > 1"
                            :pagination="paginatorInfo"
                            class="flex items-center justify-between text-gray-700 text-sm"
                            @set-page="paginationData.page = $event">
                          <div
                              v-if="loading"
                              class="inline-block w-8 h-8 mr-2 text-gray-400">
                            <loader />
                          </div>
                        </pagination>
                      </div>
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        v-if="!loadedFirstTime"
        class="block bg-white rounded shadow-lg overflow-hidden">
      <div class="table-container">
        <table class="table divide-y">
          <thead>
          <tr>
            <table-th class="w-full">
              Form name
            </table-th>
            <table-th class="whitespace-nowrap">
              Completion date
            </table-th>
            <table-th>&nbsp;</table-th>
          </tr>
          </thead>
          <tbody
              class="bg-white divide-y divide-gray-200 animate-loading">
          <tr
              v-for="i in 5"
              :key="i">
            <td class="td">
              <div>
                <div class="inline-block">
                  <loading-skeleton
                      class-type="block"
                      :random-text="true" />
                </div>
              </div>
              <div>
                <div class="inline-block">
                  <loading-skeleton
                      class-type="block"
                      class="text-sm mt-1"
                      :random-text="true"
                      :min="40"
                      :max="90" />
                </div>
              </div>
            </td>
            <td class="td text-right">
              <div>
                <div class="inline-block">
                  <loading-skeleton
                      class=""
                      text="3/22/22 7:05PM" />
                </div>
              </div>
              <div>
                <div class="inline-block">
                  <loading-skeleton
                      class="text-sm"
                      text="10 days ago" />
                </div>
              </div>
            </td>
            <td class="td">
              <div class="inline-block">
                <loading-skeleton
                    class=""
                    text="View" />
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  QLClient, QLFormInstancesForClientQuery, QLFormInstancesForClientQueryVariables, QLOrderByClause, QLSortOrder,
  useFormInstancesForClientQuery
}                                                          from "@/graphql/queries/ql/composables";
import {computed, reactive, toRef}                         from "vue";
import LoadingSkeleton                                     from "@/components/layout/LoadingSkeleton.vue";
import Pagination                                          from "@/components/layout/Pagination.vue";
import Empty                                               from "@/components/layout/Empty.vue";
import Loader                                              from "@/components/layout/Loader.vue";
import {createPaginationData, paginationWithAlreadyLoaded} from "@/composables/paginationWithAlreadyLoaded";
import TableTh                                             from "@/components/layout/Tables/TableTh.vue";
import TimestampToDate                                     from "@/components/layout/TimestampToDate.vue";
import TimeAgo                                             from "@/components/layout/TimeAgo.vue";
import {useRouter}                                         from "vue-router";
import routesList                                          from "@/composables/routesList";
import {echoListener}                                      from "@/composables/echoListener";
import {EventTypes}                                        from "@/classes/notifications/EventTypes";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");
const router = useRouter();
const {routeList} = routesList();

const orderBy = reactive<QLOrderByClause>({
                                            column: "created_at",
                                            order:  QLSortOrder.DESC
                                          });
const isOrderUp = computed(() => orderBy.order === QLSortOrder.DESC);
const changeOrder = (field, isUp: boolean) => {
  // this might make two queries, test it
  orderBy.order = isUp ? QLSortOrder.DESC : QLSortOrder.ASC;
  orderBy.column = field;
};
const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 5
                                              });

const formInstancesVariables = computed<QLFormInstancesForClientQueryVariables>(() => {
  return {
    client_id: client.value.id,
    page:      paginationData.page,
    first:     paginationData.perPage,
    orderBy:   [orderBy]
  };
});

const formsQ = useFormInstancesForClientQuery(formInstancesVariables);

echoListener([EventTypes.FormInstanceWasCreated], (ev) => {
  if (ev.data.client_id == client.value.id) {
    formsQ.refetch();
  }
}, 5000);

const {
  paginatorInfo,
  loadedFirstTime,
  results: forms,
  loading
} = paginationWithAlreadyLoaded<QLFormInstancesForClientQuery["form_instances_for_client"]["data"]>(formsQ);

</script>
