import {BookingEventGroup} from "hm-filters/filters/partials/BookingEventGroup";

export class BookingClassValuesFilter extends BookingEventGroup {

	get type(): string {
		return "BookingClassValues";
	}
}


