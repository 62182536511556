<template>
  <page-title title="Opt-Out Report" section="Clients" />
  <div class="container mx-auto grow">
    <div class="w-full">
      <h2 class="text-gray-800 font-medium text-lg mb-4">
        Last 15 days
      </h2>

      <div class="bg-white rounded-lg shadow divide-y md:divide-y-0 md:divide-x grid grid-cols-1 md:grid-cols-3">
        <opt-out-stat
            :current-value="totalOptOuts.current"
            :loading="loadingStats"
            :past-value="totalOptOuts.past"
            title="Total Opt-Outs" />
        <opt-out-stat
            :current-value="unsubscribedStats.emails"
            :loading="loadingStats"
            :past-value="unsubscribedStats.emails_past"
            title="Email Opt-Outs" />
        <opt-out-stat
            :current-value="unsubscribedStats.sms"
            :loading="loadingStats"
            :past-value="unsubscribedStats.sms_past"
            title="SMS Opt-Outs" />
      </div>
    </div>

    <div class="mt-16">
      <h2 class="text-gray-800 font-medium text-lg mb-4">
        History
      </h2>

      <div class="flex items-center">
        <div class="grow">
          <div>
            <div class="flex items-center space-x-4">
              <div role="button"
                   class="pillbox"
                   :class="{'selected-pill': isEmail}"
                   @click="isEmail = true">
                <div class="inline-flex items-center">
                  <svg-icon name="email" class="w-3 h-3" />
                  <span class="pl-2">Emails</span>
                </div>
              </div>
              <div
                  role="button"
                  class="pillbox"
                  :class="{'selected-pill': !isEmail}"
                  @click="isEmail = false">
                <div class="inline-flex items-center">
                  <svg-icon name="mobile" class="w-4 h-4" />
                  <span class="pl-1">SMS</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <opt-out-export-button :is-email="isEmail" />
        </div>
      </div>

      <opt-out-history :is-email="isEmail" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import SvgIcon from "@/components/SvgIcon.vue";

import OptOutStat                         from "@/components/Clients/OptOutReport/OptOutStat.vue";
import OptOutHistory                      from "@/components/Clients/OptOutReport/OptOutHistory.vue";
import OptOutExportButton                 from "@/components/Clients/OptOutReport/OptOutExportButton.vue";
import {useClientsUnsubscribedStatsQuery} from "@/graphql/queries/ql/composables";
import {useResult}                        from "@vue/apollo-composable";
import {ref}                              from "vue";
import PageTitle                          from "@/components/navbar/PageTitle.vue";

const isEmail = ref(true);

const unsubQ = useClientsUnsubscribedStatsQuery({days: 15});
const totalOptOuts = useResult(unsubQ.result, {current: 0, past: 0}, data => {
  return {
    current: data.clients_unsubscribed_stats.emails + data.clients_unsubscribed_stats.sms,
    past:    data.clients_unsubscribed_stats.emails_past + data.clients_unsubscribed_stats.sms_past
  };
});
const unsubscribedStats = useResult(unsubQ.result,
                                    {emails: 0, emails_past: 0, sms: 0, sms_past: 0},
                                    data => data.clients_unsubscribed_stats);

const {loading: loadingStats} = unsubQ;

</script>
