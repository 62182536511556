<template>
  <div class="p-2 border-4 border-gray-200 h-64 rounded-lg">
    <VPerfectSignature :stroke-options="strokeOptions" ref="signaturePad" />
  </div>
</template>
<script lang="ts" setup>
import {QLFormField} from "@/graphql/queries/ql/composables";
import VPerfectSignature from 'v-perfect-signature';
import {ref, toRef}      from "vue";

interface Props {
  item: QLFormField;
}

const signaturePad = ref();
const strokeOptions = {
  size: 16,
  thinning: 0.75,
  smoothing: 0.5,
  streamline: 0.5,
};

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits([]);

const item = toRef(props, "item");
</script>
