import Filter            from "../base-filter";
import OperatorGenerator from "../operatorGenerator";

class SaleFilter extends Filter {
	smallText = "Sale";
	group = "Sale";
	path = ["Sale"];

	constructor(name: string, options: any) {
		super(name, options);
		this.smallText = "Sale";
		this.group = "Sale";
		this.setOperators(OperatorGenerator.datesPastOnly(true, true));

	}

	get type(): string {
		return "SaleFilter";
	}
}

// let ret = [
// 	new SaleFilterClass('History', {'field': ''})
// ];

export default SaleFilter;
