import StudioSettings         from "@/views/Studio/Settings/StudioSettings.vue";
import Routes                 from "@/router/Routes";
import StudioClubCountManager from "@/views/Studio/Settings/StudioClubCountManager.vue";
import StudioManageGroups     from "@/views/Studio/Settings/StudioManageGroups.vue";
import StudioManageSegments   from "@/views/Studio/Settings/StudioManageSegments.vue";
import StudioStaff            from "@/views/Studio/Settings/StudioStaff.vue";
import StudioBilling          from "@/views/Studio/Settings/StudioBilling.vue";
import DashboardSkeleton      from "@/views/DashboardSkeleton.vue";
import StudioQuickResponses   from "@/views/Studio/Settings/StudioQuickResponses.vue";
import StudioUsage   from "@/views/Studio/Settings/StudioUsage.vue";
import StudioZapier           from "@/views/Studio/Settings/StudioZapier.vue";
import {QLUserRoleEnum}       from "@/graphql/queries/ql/composables";

export const StudioRoutes = [
	{
		path:      "/studio/settings",
		component: DashboardSkeleton,
		meta:      {
			requiresAuth: true,
			requiresTeam: true
		},
		children:  [
			{
				path:      "profile",
				component: StudioSettings,
				name:      Routes.studio.settings.profile,
				meta:      {
					atLeastRole: QLUserRoleEnum.ADMIN,
					title:       "Studio Profile"
				}
			},
			{
				path:      "club-count-manager",
				component: StudioClubCountManager,
				name:      Routes.studio.settings.clubCountManager,
				meta:      {
					atLeastRole: QLUserRoleEnum.MANAGER,
					title:       "Club Count Manager"
				}
			},
			{
				path:      "groups",
				component: StudioManageGroups,
				name:      Routes.studio.settings.groups,
				meta:      {
					atLeastRole: QLUserRoleEnum.MANAGER,
					title:       "Manage Groups"
				}

			},
			{
				path:      "segments",
				component: StudioManageSegments,
				name:      Routes.studio.settings.segments,
				meta:      {
					atLeastRole: QLUserRoleEnum.MANAGER,
					title:       "Manage Segments"
				}

			},
			{
				path:      "staff",
				component: StudioStaff,
				name:      Routes.studio.settings.staff,
				meta:      {
					atLeastRole: QLUserRoleEnum.ADMIN,
					title:       "Manage Staff"
				}
			},
			{
				path:      "billing",
				component: StudioBilling,
				name:      Routes.studio.settings.billing,
				meta:      {
					owner: true,
					title: "Billing"
				}
			},
			{
				path:      "quick-responses",
				component: StudioQuickResponses,
				name:      Routes.studio.settings.quickResponses,
				meta:      {
					atLeastRole: QLUserRoleEnum.ADMIN,
					title:       "Quick Responses"
				}
			},
			{
				path:      "usage",
				component: StudioUsage,
				name:      Routes.studio.settings.usage,
				meta:      {
					atLeastRole: QLUserRoleEnum.ADMIN,
					title: "Studio Usage"
				}
			},
			{
				path:      "zapier",
				component: StudioZapier,
				name:      Routes.studio.settings.zapier,
				meta:      {
					atLeastRole: QLUserRoleEnum.ADMIN,
					addonZapier: true,
					title:       "Zapier Settings"
				}
			}
		]
	}

];
