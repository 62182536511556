<template>
  <form
      ref="form"
      class="block overflow-hidden shadow-md rounded-lg"
      @submit.prevent="send">
    <div class="space-y-6 bg-white p-6">
      <text-input
          v-model="name"
          :error-message="errors.get('input.name')"
          required
          label="Name">
        <div class="leading-tight form-small-info text-gray-600">
          Studio Name will be passed through the merge tag
          <span class="studio-settings-merge-tag">STUDIO_NAME</span>
        </div>
      </text-input>

      <div>
        <label
            class="form-label mb-1"
            for="color"
            :class="{'has-error': errors.has('input.color')}">
          Color
        </label>
        <div class="flex items-center">
          <div class="h-16 w-16 border p-0 m-0 box-content">
            <input
                v-model="color"
                class="h-full w-full appearance-none"
                name="color"
                type="color">
          </div>
          <div class="pl-2 flex-no-shrink form-small-info text-gray-600">
            Choose your brand color. When selecting
            automations from the LoopSpark Automation
            Catalog, your studio color will be used for
            email template design elements.
          </div>
        </div>
      </div>
      <div>
        <label
            class="form-label mb-1"
            for="color"
            :class="{'has-error': errors.has('input.timezone')}">
          Timezone
        </label>
        <timezone-selector
            v-model="timezone"
            class="form-input-default"
            required
            :class="{'has-error': errors.has('input.timezone')}" />

        <div class="leading-tight form-small-info text-gray-600">
          Timezone will control timestamps on communications and
          To-Dos, as well as when automations are triggered. Make
          sure your timezone is accurate before activating any
          automations.
        </div>
      </div>
    </div>
    <div class="bg-gray-100">
      <div class="flex justify-end p-4 py-6">
        <v-button
            :loading="sending"
            class="btn btn-green text-sm font-normal"
            type="submit">
          Update profile
        </v-button>
      </div>
    </div>
  </form>
</template>
<script lang="ts" setup>

import {onMounted, ref, watch} from "vue";
import TextInput               from "@/components/layout/Forms/TextInput.vue";
import VButton                 from "@/components/layout/VButton.vue";
import TimezoneSelector        from "@/components/layout/Forms/TimezoneSelector.vue";
import studioSettingsUpdate    from "@/components/studio/settings/studioSettingsUpdate";

interface Props {
  currentTeam: QLFragmentCurrentTeamFragment;
}

const props = withDefaults(defineProps<Props>(), {});

import {QLFragmentCurrentTeamFragment} from "@/graphql/queries/ql/composables";

const emit = defineEmits([]);

const {
  currentTeam,
  form,
  errors,
  sending,
  sendMutation
} = studioSettingsUpdate(props);

const {color, name, timezone, loadFromQL} = fieldsAndLoading(currentTeam);

onMounted(() => {
  sending.value = false;
  loadFromQL();
});

const send = () => {
  const vars = {
    color:    color.value,
    name:     name.value,
    timezone: timezone.value
  };
  sendMutation(vars);
};

function fieldsAndLoading(currentTeam) {
  const color = ref("#0262AC");
  const name = ref("");
  const timezone = ref("");

  const loadFromQL = () => {
    color.value = currentTeam.value.color;
    name.value = currentTeam.value.name;
    timezone.value = currentTeam.value.timezone;
  };

  watch(currentTeam, loadFromQL);

  return {
    color, name, timezone, loadFromQL
  };
}
</script>
