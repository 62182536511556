import {v4 as uuidv4} from "uuid";

export class ActiveClientFilterGenerator {
	static generate() {
		return {
			"operator_is_and": true,
			"filters":         [
				{
					"type": "ClientValues",
					"uid": uuidv4().toString(),
					"options": {"field": "active", "operator": "=", "value": true}
				}
			]
		};
	}
}
