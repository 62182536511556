<template>
  <div v-if="teamPendingInvitations && teamPendingInvitations.length > 0">
    <div class="pt-10">
      <div class="pt-10 border-t">
        <h4 class="font-semibold text-gray-600 mb-2">
          Pending invitations
        </h4>

        <div class="divide-y divide-gray-100">
          <studio-staff-invite-pending-list-item
              v-for="invitation in teamPendingInvitations"
              :key="invitation.id"
              :current-team="currentTeam"
              :invitation="invitation" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import StudioStaffInvitePendingListItem                                from "@/components/studio/settings/Staff/StudioStaffInvitePendingListItem.vue";
import {QLFragmentCurrentTeamFragment, useTeamPendingInvitationsQuery} from "@/graphql/queries/ql/composables";
import {computed}                                                      from "vue";

interface Props {
  currentTeam: QLFragmentCurrentTeamFragment;
}

const props = withDefaults(defineProps<Props>(), {});
const {result} = useTeamPendingInvitationsQuery({
                                                  team_id: props.currentTeam.id
                                                });

const teamPendingInvitations = computed(() => result.value?.team_pending_invitations ?? []);
</script>
