<script setup lang="ts">
import SvgIcon from "@/components/SvgIcon.vue";

interface Props {
  value: string;
  canUp: boolean;
  canDown: boolean
}

const props = withDefaults(defineProps<Props>(), {
});

import {ref, useSlots, useAttrs} from "vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(['updated', 'deleted', 'moveUp', 'moveDown']);
const editing = ref(false);

const editingValue = ref("");

const startEditing = () => {
  editingValue.value = props.value;
  editing.value = true;
}

const deleteOption = () => {
  emit('deleted')
}

const saveTheEdit = () => {
  emit('updated', editingValue.value);
  editing.value = false;
}

</script>

<template>
  <div
      class="flex justify-between py-4">
    <div class="grow">
      <span v-if="!editing">{{ value }}</span>
      <div class="flex items-stretch mr-2" v-if="editing">
        <input
            v-model="editingValue"
            type="text"
            class="form-input rounded-r-none flex-1 border-r-0"
            @keyup.enter.prevent="saveTheEdit">
        <button
            class="btn btn-green rounded-l-none"
            @click.prevent="saveTheEdit">
          <svg-icon name="save-disk"/>
        </button>
      </div>
    </div>
    <div class="shrink-0 space-x-2 flex items-center">
      <div
          class="text-gray-500 hover:text-blue-800 cursor-pointer"
          @click.prevent="emit('moveUp')" v-if="!editing && props.canUp">
        <svg-icon
            class="h-3 w-3"
            name="arrow-up" />
      </div>
      <div
          class="text-gray-500 hover:text-blue-800 cursor-pointer"
          @click.prevent="emit('moveDown')" v-if="!editing && props.canDown">
        <svg-icon
            class="h-3 w-3 rotate-180"
            name="arrow-up"  />
      </div>
      <div
          class="text-gray-800 hover:text-gray-400 cursor-pointer"
          @click.prevent="startEditing" v-if="!editing">
        <svg-icon
            class="h-4 w-4"
            name="edit" />
      </div>
      <div
          class="text-red-800 hover:text-red-400 cursor-pointer"
          @click.prevent="deleteOption">
        <svg-icon
            class="h-4 w-4"
            name="trash" />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
