<template>
  <copy-dialog
      v-model:open="openModel"
      v-model:selected-team="selectedTeam"
      :sending="sending"
      :success="success"
      :teams="teams"
      @clicked="sendNow">
    <template #title>
      Copy this form to:
    </template>
    <template #description>
      Select a studio where you'd like to copy this form. You may only copy this form
      to a location
      where you have manager, admin or owner access. The form will be copied into your
      selected location
      with an active status.
    </template>
    <template #success>
      Form successfully copied!
    </template>
  </copy-dialog>
</template>
<script lang="ts" setup>
import {
  QLAutomation, QLFragmentFormSmallFragment, QLFragmentTeamWithOwnerFragment, useCopyAutomationMutation,
  useCopyFormMutation
}                           from "@/graphql/queries/ql/composables";
import {useAttrs, useSlots} from "vue";
import copyDialog           from "@/composables/copyDialog";
import CopyDialog           from "@/components/layout/CopyDialog.vue";

interface Props {
  form: QLFragmentFormSmallFragment;
  teams: QLFragmentTeamWithOwnerFragment[];
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open"]);

const mut = useCopyFormMutation({});

const {sending, success, openModel, sendNow, selectedTeam} = copyDialog(props.form.id, mut, props, emit);
</script>
