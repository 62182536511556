import {computed, ref, toRef}                     from "vue";
import SparkFormErrors, {SparkErrors}             from "@/classes/utils/SparkFormErrors";
import {QLUpdateTeamInput, useUpdateTeamMutation} from "@/graphql/queries/ql/composables";
import $toast                                     from "@/composables/toast";
import fillValidationErrorsOrToast                from "@/composables/queries/mutations/fillValidationErrorsOrToast";

export default function studioSettingsUpdate(props,
                                             errorMessage: string = "Error updating your information. Please try again!",
                                             context = null) {
	const currentTeam = toRef(props, "currentTeam");
	const form = ref(null);
	const errors = ref<SparkErrors>({});
	const sparkErrors = computed(() => {
		return new SparkFormErrors(errors.value);
	});
	const sending = ref(false);

	const {mutate, onDone, onError} = useUpdateTeamMutation({});
	onDone(() => {
		$toast({
			       type:         "success",
			       message:      "Settings changed!",
			       linkText:     null,
			       linkRouteObj: null
		       });
		sending.value = false;
	});
	onError((error) => {
		fillValidationErrorsOrToast(error, errors, errorMessage, "input.");
		sending.value = false;
	});

	const sendMutation = (variables: QLUpdateTeamInput) => {
		errors.value = {};
		if (!form.value.reportValidity()) {
			return;
		}
		sending.value = true;

		mutate({input: variables});
	};

	return {
		currentTeam,
		form,
		errors: sparkErrors,
		sending,
		sendMutation
	};


}
