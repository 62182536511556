<template>
  <form
      ref="form"
      @submit.prevent="submitChange">
    <side-panel
        v-model:open="openModel"
        wide
        ultra-wide
        :closable="true"
        @close="cancelNow">
      <template #header>
        Field Settings
      </template>
      <template #default>
        <div>
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ fieldInfo.title }}
            </h3>
            <p class="mt-1 text-sm text-gray-700">
              {{ fieldInfo.description }}
            </p>
          </div>

          <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div>
              <fieldset class="grid grid-cols-1 gap-x-4 gap-y-8">
                <div class="">
                  <label
                      :class="[nameAlreadyUsed ? 'has-error': '']"
                      class="form-label"
                      for="title">
                    Title
                  </label>
                  <div
                      class="mt-1 text-sm text-gray-900">
                    <input
                        v-model="fieldModel.title"
                        name="title"
                        type="text"
                        class="form-input form-input-default"
                        :class="{'has-error': nameAlreadyUsed}"
                        required>

                    <div
                        v-if="titleOptional && !nameAlreadyUsed"
                        class="form-small-info mt-2">
                      The Title will not be visible to your clients. Use a descriptive name to identify this content.
                    </div>
                    <div
                        v-if="nameAlreadyUsed"
                        class="form-error-message mt-2">
                      This title is already used in another
                      field.
                    </div>
                  </div>
                </div>
                <slot name="fields" />
              </fieldset>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div
            class="w-full flex justify-between items-center">
          <button
              type="button"
              class="inline-flex space-x-1  items-center px-2.5 py-1.5 border border-red-300 shadow-sm text-xs font-medium rounded text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              @click="cancelNow">
            <span>Cancel</span>
          </button>
          <button
              type="button"
              :class="[canSave ? '': 'opacity-50 cursor-not-allowed']"
              class="inline-flex space-x-1 items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              @click="submitChange">
            <svg-icon
                name="save-disk"
                class="w-3 h-3" />
            <span>Save changes</span>
          </button>
        </div>
      </template>

    </side-panel>
  </form>
</template>
<script lang="ts" setup>
import {QLFormField}                                                  from "@/graphql/queries/ql/composables";
import {computed, ref, toRef, withDefaults} from "vue";
import SvgIcon                                                        from "@/components/SvgIcon.vue";
import SidePanel                                                      from "@/components/layout/SidePanel.vue";
import fieldsData                                                     from "@/components/Forms/Fields/fields";

interface Props {
  field: QLFormField;
  nameAlreadyUsed: boolean;
  canSave: boolean;
  open: boolean;
  titleOptional?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  titleOptional: false
});
const form = ref(null);
const canSave = toRef(props, "canSave");
const emit = defineEmits(["cancelled", "update:field", "saved", "update:open"]);

const nameAlreadyUsed = toRef(props, "nameAlreadyUsed");

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const fieldModel = computed({
                              get: () => props.field,
                              set: (val) => emit("update:field", val)
                            });

const fieldInfo = computed(() => {
  return fieldsData[props.field.kind];
});

const cancelNow = () => {
  emit("cancelled");
};

const submitChange = () => {
  if (!form.value.reportValidity() || !canSave.value) {
    return;
  }
  emit("saved");
};
</script>
