<template>
  <router-link
      :to="{name: routeList.client.todos.show, params:{id: todo.client_id, todoId: todo.id}}"
      class="group bg-white  hover:shadow-lg rounded-lg px-4 py-4 cursor-pointer block hover:opacity-100"
      :class="{'opacity-50': todo.is_closed, 'border-2 bg-yellow-50 border-red-300': decorator.isOpen && decorator.isOverduePast, 'border border-gray-300': !(decorator.isOpen && decorator.isOverduePast) }">
    <div class="flex">
      <div class="flex-1 min-w-0">
        <div class="">
          <div class="text-base text-loopspark-700 group-hover:text-loopspark-500 truncate">
            {{ todo.subject }}
          </div>
          <div class="text-sm text-gray-600 pt-2">
            Created by
            <span v-if="!!todo.created_by">{{ todo.created_by.name }}</span>
            <span v-if="!!todo.automation">automation <span class="font-medium">{{ todo.automation.name }}</span></span>
          </div>
        </div>
      </div>
      <div class="shrink-0 sm:ml-5">
        <div class="flex flex-col items-end space-y-1">
          <div
              v-if="decorator.isOpen && !decorator.isPostponed && !decorator.isOverduePast"
              class="badge-new-gray bg-loopspark-100 text-loopspark-800">
            Open
          </div>
          <div
              v-if="decorator.isOpen && decorator.isPostponed"
              class="badge-new-gray">
            Postponed
          </div>
          <div
              v-if="decorator.isOpen && decorator.isOverduePast"
              class="badge-new-red">
            Overdue
          </div>
          <div
              v-if="todo.is_closed && todo.is_completed"
              class="badge-new-green">
            Completed
          </div>
          <div
              v-if="todo.is_closed && todo.is_deleted"
              class="badge-new-gray ">
            Deleted
          </div>
        </div>
      </div>
    </div>

    <div class="md:flex justify-between mt-6">
      <div class="text-sm text-gray-700 ">
        <div class="md:inline-flex items-center">
          <span class="pr-2">Assigned to:</span>
          <div class="md:flex space-y-2 md:space-y-0 md:space-x-2 items-center">
            <tag-with-user
                v-if="todo.assigned_to"
                :user="todo.assigned_to" />
            <tag-with-icon
                v-if="decorator.assignedToEveryone"
                color="green"
                icon="users">
              Everyone
            </tag-with-icon>
            <tag-with-icon
                v-if="todo.team_group"
                color="gray"
                icon="users">
              {{
                todo.team_group.name
              }}
            </tag-with-icon>
          </div>
        </div>
      </div>
      <div class="mt-1 md:mt-0">
        <tag-with-icon
            v-if="todo.priority === todoPriorities.LOW"
            color="gray"
            icon="thermometerLow">
          Low-priority
        </tag-with-icon>
        <tag-with-icon
            v-if="todo.priority === todoPriorities.MEDIUM"
            color="purple"
            icon="thermometerMed">
          Medium priority
        </tag-with-icon>
        <tag-with-icon
            v-if="todo.priority === todoPriorities.URGENT"
            color="loopspark"
            icon="thermometerHigh">
          Urgent
        </tag-with-icon>
      </div>
    </div>
  </router-link>
</template>
<script lang="ts" setup>
import TagWithIcon              from "@/components/layout/TagWithIcon.vue";
import TagWithUser              from "@/components/layout/TagWithUser.vue";
import {toRef}                  from "vue";
import {QLTodo, QLTodoPriority} from "@/graphql/queries/ql/composables";
import routesList               from "@/composables/routesList";
import todoDecorator            from "@/composables/decorators/TodoDecorator";

interface Props {
  todo: QLTodo;
}

const props = withDefaults(defineProps<Props>(), {});

const todo = toRef(props, "todo");

const routeList = routesList().routeList;

const {computed: decorator} = todoDecorator(todo);

const todoPriorities = QLTodoPriority;

</script>
