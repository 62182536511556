import Package                        from "./partials/package";
import OperatorGenerator              from "../operatorGenerator";
import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";

class PackageStarting extends Package {
	constructor(name: string, options: any) {
		super(name, options);

		this.addOperators(OperatorGenerator.datesAll(true, true, false));
		this.addOperators([
			                  ...OperatorGenerator.dateBefore(false),
			                  ...OperatorGenerator.dateAfter(false),
			                  new OperatorAsFixedValueOperator("past", "in the past"),
			                  new OperatorAsFixedValueOperator("future", "in the future")
		                  ]);
	}

	get type(): string {
		return "PackageStartingFilter";
	}
}

export default PackageStarting;
