<template>
  <div class="overflow-x-auto">
    <div v-if="!fileUploaded">
      <div class="flex justify-center">
        <div class="shrink-0 ">
          <label class="flex flex-col items-center px-8 py-8 text-gray-800 bg-white rounded-lg tracking-wide  border border-gray-400 cursor-pointer hover:bg-loopspark-700 hover:text-white">
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
            </svg>
            <span class="mt-2 text-sm leading-relaxed text-center">Drop your Excel file here <br />or click to select one</span>
            <input ref="file"
                   accept="*/*"
                   class="hidden"
                   type="file"
                   v-on:change="handleFileUpload()">

          </label>
        </div>

      </div>
      <div v-if="fileUploading" class="mt-8 flex justify-center">
        <loader class="w-12 h-12 text-gray-500"></loader>
      </div>
    </div>

    <div v-if="fileUploaded">
      <div>
        <h2 class="text-lg pb-8 text-gray-700">Assign columns and select the leads</h2>
        <p class="text-sm text-gray-800 pb-2">
          Please define each column in your uploaded list before saving your upload.
        </p>
        <ul class="list-outside">
          <li v-for="field in fieldsToChoose"
              v-bind:class="{ 'text-red-600': field.required, 'font-bold': field.required && fieldIsAvailable(field.field), 'text-gray-700': !field.required,  'text-green-500': !fieldIsAvailable(field.field)}">
            <div class="inline-flex items-center leading-none text-sm">
              <div>
                <svg-icon name="check" v-if="!fieldIsAvailable(field.field)" class="h-4 w-4"></svg-icon>
                <svg-icon name="closeCircle" v-if="fieldIsAvailable(field.field) && field.required"
                          class="h-4 w-4"></svg-icon>
                <svg-icon name="minus"
                          v-if="fieldIsAvailable(field.field) && !field.required"
                          class="h-4 w-4"></svg-icon>
              </div>
              <span class="pl-2">
                {{ field.name }} <span class="italic text-xs"
                                       v-bind:class="{'font-medium': field.required}"
                                       v-text="'(' + (field.required ? 'required': 'optional') + ')'"></span>
              </span>
            </div>
          </li>
        </ul>

      </div>

      <div class="mt-6 shadow border-b border-gray-200 sm:rounded-lg">
        <div class="table-container big-table">
          <table class="table divide-y divide-gray-200">
            <thead>
              <tr class="bg-gray-50">
                <th></th>
                <th v-for="(column,index) in columns" class="px-1 py-1">
                  <select v-model="columns[index]" class="form-select text-sm">
                    <option value="">---</option>
                    <option v-for="field in fieldsToChoose"
                            :key="field.field"
                            :value="field.field"
                            :disabled="fieldIsAvailable(field.field) ? undefined : true">{{ field.name }}
                    </option>

                  </select>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-for="(item,index) in tableData" class=" table-row">
                <td class="px-4">
                  <input v-show="!item.sent && !item.sending"
                         v-model="tableData[index].selected"
                         class="form-checkbox"
                         type="checkbox">
                  <loader v-if="!item.sent && item.sending" class="w-4 h-4 text-gray-500"></loader>
                  <svg-icon name="check" v-if="item.sent && item.added" class="w-4 h-4 text-green-500"></svg-icon>
                  <svg-icon name="closeCircle" v-if="item.sent && !item.added" class="w-4 h-4 text-red-500"></svg-icon>
                </td>
                <td v-for="(column,index) in columns" class="px-2 py-2 text-sm">{{ item.columns[index] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="flex justify-between mt-8">
        <v-button :disabled="isUploading" class="btn btn-white" @click="reset">Reset</v-button>
        <v-button :disabled="isUploading || !canUpload" class="btn btn-green" @click="send">Add {{ sendCount }}
                                                                                            leads!
        </v-button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.table-row:nth-child(even) {
  @apply bg-gray-50;
}

.table-row > td {
  @apply border border-gray-200 py-4;
}

.big-table {
  max-height:40rem;
}
</style>
<script lang="ts" setup>
import {read, utils}                                                  from "xlsx";
import Loader                                                         from "@/components/layout/Loader.vue";
import VButton                                                        from "@/components/layout/VButton.vue";
import SvgIcon                                                        from "@/components/SvgIcon.vue";
import {computed, ref, useAttrs, useSlots}                            from "vue";
import {QLCreateProspectMutationVariables, useCreateProspectMutation} from "@/graphql/queries/ql/composables";

interface TableDataItem {
  selected: boolean;
  sending: boolean;
  sent: boolean;
  added: boolean;
  columns: string[];
}

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
const file = ref<HTMLInputElement>(null);

const fileUploading = ref<boolean>(false);
const fileUploaded = ref<boolean>(false);
const tableData = ref<TableDataItem[]>([]);
const maxColumns = ref<number>(0);
const columns = ref<Array<string>>([]);


const fieldsToChoose = [
  {field: "first_name", name: "First Name", required: true},
  {field: "last_name", name: "Last Name", required: true},
  {field: "email", name: "Email", required: true},
  {field: "mobile_phone", name: "Mobile Phone", required: false},
  {field: "referred_by", name: "Referral", required: false}
];

const reset = () => {
  fileUploaded.value = false;
  tableData.value = [];
  maxColumns.value = 0;
  columns.value = [];
};

const canUpload = computed(() => {
  return !fieldIsAvailable("email") && !fieldIsAvailable("first_name") && !fieldIsAvailable(
      "last_name");
});

const fieldIsAvailable = (fieldName: string) => {
  return columns.value.indexOf(fieldName) < 0;
};

const sendCount = computed(() => {
  return tableData.value.filter((td) => td.selected).length;
});

const isUploading = computed(() => {
  return tableData.value.findIndex((i) => i.sending) >= 0;
});

const handleFileUpload = () => {
  fileUploading.value = true;
  const fileSent = file.value.files[0];
  const fileReader: FileReader = new FileReader();


  fileReader.onload = (evt) => {
    let data = new Uint8Array(<any>fileReader.result);
    let workbook = read(data, {
      type: "array"
    });
    let first_sheet_name = workbook.SheetNames[0];
    tableData.value = utils.sheet_to_json(workbook.Sheets[first_sheet_name], {header: 1}).map((item) => {
      return <TableDataItem>{
        selected: true,
        sending:  false,
        sent:     false,
        added:    false,
        columns:  item
      };
    });

    columns.value = [];
    maxColumns.value = tableData.value.map((item) => item.columns.length).sort().reverse()[0];
    for (let i = 0; i < maxColumns.value; i++) {
      columns.value.push("");
    }
    fileUploading.value = false;
    fileUploaded.value = true;
  };
  fileReader.readAsArrayBuffer(fileSent);
};
const mut = useCreateProspectMutation({});
const send = () => {
  tableData.value.forEach((item) => {
    item.sending = true;
    item.sent = false;
    item.added = false;

    let post: QLCreateProspectMutationVariables = {
      email:        columns.value.indexOf("email") >= 0 ?
                    <string>item.columns[columns.value.indexOf("email")] :
                    null,
      first_name:   columns.value.indexOf("first_name") >= 0 ?
                    <string>item.columns[columns.value.indexOf("first_name")] :
                    null,
      last_name:    columns.value.indexOf("last_name") >= 0 ?
                    <string>item.columns[columns.value.indexOf("last_name")] :
                    null,
      mobile_phone: columns.value.indexOf("mobile_phone") >= 0 ?
                    item.columns[columns.value.indexOf("mobile_phone")]?.toString() :
                    null,
      referred_by:  columns.value.indexOf("referred_by") >= 0 ?
                    <string>item.columns[columns.value.indexOf("referred_by")] :
                    null
    };

    mut.mutate({
                 ...post
               })
       .then(() => {
         item.sent = true;
         item.sending = false;
         item.added = true;
       })
       .catch((err) => {
         console.log(err);
         item.sent = true;
         item.sending = false;
         item.added = false;
       });
  });
};

</script>
