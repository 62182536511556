import Operator           from "./operator";
import {HasInput, IsTime} from "hm-filters/operators/Bases/InputBases";
import {OperatorTypes}    from "hm-filters/operators/OperatorTypes";

class ExactTimeOperator extends Operator implements HasInput, IsTime {
	fixedValue = undefined;
	hasInput: boolean = true;
	isTime: boolean = true;

	get type(): string {
		return OperatorTypes.ExactTime;
	}
}

export default ExactTimeOperator;
