<template>
  <div>
    <div class="relative" contenteditable="true">
      <input ref="maininput"
             v-model="inputText"
             :placeholder="placeholder"
             class="w-full form-select form-input-default"
             type="text"
             v-bind:class="{'has-error': hasError}"
             @blur="onBlur"
             @click="openSelections"
             @focus="openSelections"
             @keydown.down.prevent="keyBoard.arrowDown"
             @keydown.up.prevent="keyBoard.arrowUp"
             @keydown.enter.prevent="keyBoard.pressedEnter">

      <div v-show="open" class="absolute w-full rounded-b bg-white shadow-xl z-30">
        <ul ref="dropdown"
            class="max-h-sm rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 text-gray-900"
            role="listbox"
            tabindex="-1">

          <li v-if="itemsShown.length === 0 && !allowNew" class="py-4 px-2 text-center text-gray-900 ">
            Sorry, no matching options
          </li>

          <li v-for="(item, i) in itemsShown"
              v-if="itemsShown.length > 0"
              id="listbox-item-0"
              class=" cursor-pointer select-none relative py-2 pl-3 pr-9 hover:text-white hover:bg-loopspark-600 group"
              role="option"
              v-bind:class="{'bg-loopspark-600': pointer === (i+1), 'text-white': pointer === (i+1), 'bg-blue-100': item === passedValue}"
              @mousedown="selectItem(item)">
            <div class="flex items-center space-x-3">

              <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
              <span class="font-normal block truncate" v-bind:class="{'font-semibold': item === passedValue}">
                <slot v-bind:item="item">{{ item }}</slot>
            </span>
            </div>

            <span v-if="item === passedValue" class="absolute inset-y-0 right-0 flex items-center pr-4">
            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
              <path clip-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    fill-rule="evenodd"/>
            </svg>
          </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {adjustScrollComposable}                                     from "@/components/layout/Forms/composables/adjustScrollComposable";
import {computed, onMounted, ref, toRef, useAttrs, useSlots, watch} from "vue";

interface Props {
  modelValue: any,
  items: Array<any>;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  focusOnShow?: boolean;
  alwaysShowAll?: boolean;
  emptyOnOpen?: boolean;
  allowNew?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  label:         "",
  placeholder:   "",
  errorMessage:  "",
  focusOnShow:   false,
  alwaysShowAll: false,
  emptyOnOpen:   false,
  allowNew:      false
});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:modelValue", "blur"]);

const dropdown = ref<HTMLUListElement>(null);
const maininput = ref<HTMLInputElement>(null);

const open = ref(false);
const inputText = ref("");
const pointer = ref(0);

const passedValue = toRef(props, "modelValue");

// Functions below

const itemsShown = computed(() => {
  if (props.alwaysShowAll) {
    return props.items;
  }
  if (!inputText.value || inputText.value.length === 0) {
    return props.items;
  }
  let search = "";
  if (typeof inputText.value === "object") {
    search = (inputText.value[props.label] as string).toLowerCase();
  } else {
    search = inputText.value.toLowerCase();
  }
  let found = props.items.filter((i) => {
    if (props.label != "") {
      return i[props.label].toLowerCase().indexOf(search) !== -1;
    }
    return i.toLowerCase().indexOf(search) !== -1;
  });

  if (props.allowNew) {
    found = [...new Set([inputText.value, ...found])];
  }
  return found;
});

const hasError = computed(() => {
  return props.errorMessage && props.errorMessage.length > 0;
});

const selectedTextValue = computed(() => {
  if (props.modelValue === null || props.modelValue === undefined) {
    return null;
  }
  if (props.label) {
    return props.modelValue[props.label];
  }
  return props.modelValue;
});

const openSelections = () => {
  if (props.emptyOnOpen) {
    inputText.value = "";
  }
  open.value = true;
};

onMounted(() => {
  inputText.value = selectedTextValue.value;
  if (props.focusOnShow) {
    maininput.value.focus();
  }
});

const resetPointer = () => {
  pointer.value = 0;
};

const close = () => {
  open.value = false;
  inputText.value = selectedTextValue.value;
  resetPointer();
};

const onBlur = () => {
  close();
  emit("blur");
};

const selectItem = (item: any) => {
  if (item === props.modelValue) {
    item = null;
  }

  inputText.value = item;
  open.value = false;
  resetPointer();
  emit("update:modelValue", item);
};

const scrollStuff = adjustScrollComposable(dropdown, pointer);

const keyBoard = {
  arrowUp:      () => {
    if (!open.value) {
      open.value = true;
      pointer.value = 0;
      return;
    }
    if (pointer.value > 1) {
      pointer.value -= 1;
      scrollStuff.maybeAdjustScroll();
    }
  },
  arrowDown:    () => {
    if (!open.value) {
      open.value = true;
      pointer.value = 0;
      return;
    }
    if (pointer.value < itemsShown.value.length) {
      pointer.value += 1;
      scrollStuff.maybeAdjustScroll();
    }
  },
  pressedEnter: () => {
    if (pointer.value > 0) {
      return selectItem(itemsShown.value[pointer.value - 1]);
    }
    if (!inputText.value || inputText.value.length === 0) {
      return false;
    }
    if (props.allowNew) {
      emit("update:modelValue", inputText.value);
      return;
    }
    if (pointer.value === 0) {
      // using the text, not the arrows selections
      if (props.items.filter((i) => {
        const t = props.label != "" ? i[props.label] : i;
        return t.toLowerCase().indexOf(inputText.value.toLowerCase()) !== -1;
      }).length > 0) {
        emit("update:modelValue", inputText.value);

        open.value = false;
      }
    }
  }
};

watch(passedValue, () => {
  inputText.value = selectedTextValue.value;
  close();
});

watch(inputText, () => {
  resetPointer();
  // reopenIfTyping
  if (inputText.value != selectedTextValue.value) {
    open.value = true;
  }
});

</script>
