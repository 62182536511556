export enum CatalogOperatorTypeEnum {
	/** Client tag operator */
	ClientTagOperator = 'ClientTagOperator',
	/** Multiple text operator */
	MultipleTextOperator = 'MultipleTextOperator',
	/** Numeric operator */
	NumericOperator = 'NumericOperator',
	/** Text operator */
	TextOperator = 'TextOperator',
	/** Class name ql operator */
	ClassNameQlOperator = 'ClassNameQlOperator',
	/** Communication received ql operator */
	CommunicationReceivedQlOperator = 'CommunicationReceivedQlOperator',
	/** Contract name ql operator */
	ContractNameQlOperator = 'ContractNameQlOperator',
	/** Package name ql operator */
	PackageNameQlOperator = 'PackageNameQlOperator',
	/** Product type ql operator */
	ProductTypeQlOperator = 'ProductTypeQlOperator',
	/** Staff name ql operator */
	StaffNameQlOperator = 'StaffNameQlOperator'
}
