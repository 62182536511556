<template>
  <modal
      v-model:open="openModel"
      :focus="false"
      :has-close-button="!sending"
      :quick-close="!sending">
    <template #buttons>
      <v-button
          v-if="selectedTeam && !success"
          :disabled="sending"
          :loading="sending"
          class="modal-button modal-button-loopspark w-auto"
          type="button"
          @click="sendNow">
        Copy!
      </v-button>
    </template>
    <template #default>
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900 mb-2">
            <slot name="title" />
          </h3>
          <p class="text-gray-500 text-sm">
            <slot name="description" />
          </p>
        </div>
        <div class="mt-4 ">
          <Transition
              enter-active-class="transition-opacity ease-in duration-300"
              enter-from-class="opacity-0"
              leave-active-class="transition-opacity ease-in duration-100"
              leave-to-class="opacity-0"
              mode="out-in">
            <div v-if="!success">
              <RadioGroup

                  v-model="selectedTeamModel"
                  :disabled="sending">
                <RadioGroupLabel class="sr-only">
                  Studio
                </RadioGroupLabel>
                <div class="space-y-4">
                  <RadioGroupOption
                      v-for="team in teams"
                      :key="team.id"
                      v-slot="{ checked, active }"
                      :value="team"
                      as="template">
                    <div :class="[checked ? 'border-transparent' : 'border-gray-300', active ? 'border-indigo-500 ring-2 ring-indigo-500' : '', 'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none']">
                  <span class="flex items-center">
                    <span class="text-sm flex space-x-2 items-center">
                      <span class="block">
                        <img
                            :src="team.photo_url"
                            alt="studio logo"
                            class="inline-block h-8 w-8 rounded-full">
                      </span>
                      <RadioGroupLabel
                          as="span"
                          class="font-medium text-gray-900">
                        {{ team.name }}
                      </RadioGroupLabel>
                    </span>
                  </span>
                      <span
                          :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']"
                          aria-hidden="true" />
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>
            </div>
            <div
                v-else
                class=" block w-full">
              <SvgIcon
                  class="h-48 w-full text-green-500"
                  name="check" />
              <p class="text-center text-green-700 font-semibold">
                <slot name="success" />
              </p>
              <p class="text-xs text-gray-500 text-center">
                This dialog will close automatically after 3 seconds
              </p>
            </div>
          </Transition>
        </div>
      </div>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import {QLFragmentTeamFragment}                        from "@/graphql/queries/ql/composables";
import {computed, useAttrs, useSlots}                  from "vue";
import Modal                                           from "@/components/layout/Modal.vue";
import {RadioGroup, RadioGroupLabel, RadioGroupOption} from "@headlessui/vue";
import VButton                                         from "@/components/layout/VButton.vue";
import SvgIcon                                         from "@/components/SvgIcon.vue";

interface Props {
  teams: QLFragmentTeamFragment[];
  open: boolean;
  sending: boolean;
  success: boolean;
  selectedTeam: QLFragmentTeamFragment | null;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open", "update:selectedTeam", "clicked"]);

const selectedTeamModel = computed({
                                     get: () => props.selectedTeam,
                                     set: (val) => emit("update:selectedTeam", val)
                                   });

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const sendNow = () => {
  emit("clicked");
};
</script>
