<template>
  <div
      class="inline"
      v-text="calculatedText" />
</template>
<script lang="ts">
import {computed, toRefs} from "vue";
import currentTeamQuery   from "@/composables/queries/currentTeamQuery";
import {DateTime}         from "luxon";

export default {
  name:  "TimestampToDate",
  props: {
    timestamp:   {
      type:     Number,
      required: true
    },
    hasTime:     {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    hasDate:     {
      type:     Boolean,
      required: false,
      default:  () => true
    },
    hasDayName:  {
      type:     Boolean,
      required: false,
      default:  () => true
    },
    naturalDate: {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    studio:      {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    utc:         {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    yearFormat:  {
      type:     String,
      required: false,
      default:  () => "yy"
    },
    format:      {
      type:     String,
      required: false,
      default:  () => ""
    }
  },
  setup(props) {
    const {currentTeam, loading} = currentTeamQuery();

    const propsRef = toRefs(props);

    const calculatedText = computed(() => {
      let zone = "local";


      if (propsRef.studio.value && !!currentTeam.value?.timezone) {
        zone = currentTeam.value.timezone;
      }

      if (propsRef.utc.value) {
        zone = "UTC";
      }

      let date = DateTime.fromSeconds(propsRef.timestamp.value, {zone});

      let format = propsRef.format.value ?? "";
      if (format.length === 0) {
        if (propsRef.hasDate.value) {
          if (propsRef.naturalDate.value) {
            format = propsRef.hasDayName.value ? "ccc, DD" : "DD";
          } else {
            format = "L/d/" + propsRef.yearFormat.value;
          }
        }
        if (propsRef.hasDate.value != null && propsRef.hasTime.value) {
          format += " ";
        }
        if (propsRef.hasTime.value) {
          format += "h:mm a";
        }
      }
      return date.toFormat(format);

    });
    return {
      calculatedText,
      currentTeam
    };
  }
};
</script>
