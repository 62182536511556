<template>
  <div
      v-if="note"
      class="border w-full rounded p-4 flex items-start"
      :class="{'note-red': color === 'red', 'note-yellow': color === 'yellow','note-blue': color === 'blue'}">
    <p
        ref="textbl"
        class="pr-2 grow text-sm leading-relaxed"
        :class="{'truncate': !clicked}">
      {{ note }}
    </p>
    <button
        v-if="hasEllipsis"
        @click="clicked = !clicked">
      <svg-icon
          name="down"
          class="h-6 w-6 fill-current opacity-50 hover:opacity-100 transform transition-transform ease-in-out"
          role="button"
          :class="{'rotate-180': clicked}" />
    </button>
  </div>
</template>
<style scoped>
.note-red {
  @apply text-red-900 bg-red-50 border-red-300;
}

.note-yellow {
  @apply text-yellow-900 bg-yellow-50 border-yellow-300;
}

.note-blue {
  @apply text-blue-900 bg-blue-50 border-blue-300;
}
</style>
<script lang="ts" setup>
import SvgIcon                from "@/components/SvgIcon.vue";
import {computed, ref, toRef} from "vue";

interface Props {
  note: string;
  color?: "red" | "yellow" | "blue";
}

const props = withDefaults(defineProps<Props>(), {
  color: "red"
});
const textbl = ref(null);

const clicked = ref(false);
const fullOpen = ref(false);

const hasEllipsis = computed(() => {
  if (textbl.value == null) {
    return false;
  }
  return ((textbl.value as HTMLElement).offsetWidth < (textbl.value as HTMLElement).scrollWidth);
});

const note = toRef(props,"note");
const color = toRef(props,"color");

</script>
