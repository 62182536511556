import Filters    from "hm-filters/filters";
import Filter     from "hm-filters/base-filter";
import Operator   from "hm-filters/operators/operator";
import {QLFilter} from "@/graphql/queries/ql/composables";

export interface FilterWithSelectedOperator {
	filter: Filter;
	operator: Operator;
	original: QLFilter;
}

function cleanUpFilterFromApi(filterFromApi: any) {
	if (!(typeof filterFromApi.options === "object" && filterFromApi.options !== null)) {
		return {...filterFromApi, options: JSON.parse(filterFromApi.options)};
	}
	return filterFromApi;
}

export const filterGetter = (filterFromApi: any) => {
	// it's already set
	if (filterFromApi.operator !== undefined) {
		return filterFromApi;
	}
	filterFromApi = cleanUpFilterFromApi(filterFromApi);
	const foundFilter = Filters.getInstance().filters.find((filter) => {
		return filter.type === filterFromApi.type && (filter.options.field === filterFromApi.options.field || filter.operators.find(
			(op) => op.caresAboutField === false));
	});

	return foundFilter.getCloned();

};

export const selectedOperator = (filterFromApi: any, currentFilter: Filter) => {
	filterFromApi = cleanUpFilterFromApi(filterFromApi);

	return currentFilter.operators.find((op) => {
		const exported = op.exportObject(currentFilter, op.transformApiOptionsToValue(filterFromApi.options));

		return (op.caresAboutField
		        ? filterFromApi.options.field == exported.field
		        : true) && filterFromApi.options.value == exported.value && filterFromApi.options.operator == exported.operator;
	});

};

export const filterAndSelectedOperatorGetter = (filterFromApi: any): FilterWithSelectedOperator => {
	filterFromApi = cleanUpFilterFromApi(filterFromApi);
	const filter = filterGetter(filterFromApi);
	return {
		filter,
		operator: selectedOperator(filterFromApi, filter),
		original: filterFromApi
	};
};

