<template>
  <div>
    <div class="">
      <div class="pb-4 md:pb-0 max-w-5xl px-2 md:px-0">
        <div class="text-lg font-medium text-gray-900">
          Email
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>Select an email template for your automation.</p>
          <p>
            You can start from scratch, OR save time by copying the
            design from a saved email automation under <span class="italic">"Previously Used Emails"</span>. If your
            team has used the stand alone
            email template builder you can select these templates from
            <span class="italic">"Your Creations"</span>.<br> Brands and franchises may also
            have categories of pre-designed templates for you to choose from.
          </p>
        </div>
      </div>
    </div>

    <div class="mt-10">
      <div
          v-if="loading"
          class="w-full h-48 flex items-center justify-center">
        <loader class="w-10 h-10 text-gray-400" />
      </div>

      <div
          v-if="!loading"
          class="md:flex">
        <div class="w-lg shrink-0 max-w-lg mb-6 md:mb-0">
          <div>
            <span class="uppercase text-sm text-gray-600 mb-1">Templates</span>

            <ul class="space-y-1 mt-2">
              <li
                  class="menu-item "
                  :class="{'selected': currentCategory === '_all'}"
                  @click="currentCategory = '_all'">
                All
              </li>
              <li
                  v-for="category in categories"
                  :key="category"
                  class="menu-item"
                  :class="{'selected': currentCategory === category}"
                  @click="currentCategory = category">
                {{ category }}
              </li>
            </ul>
          </div>

          <div class="mt-8">
            <span class="uppercase text-sm text-gray-600 mb-1">Your templates</span>
            <ul class="space-y-1 mt-2">
              <li
                  class="menu-item"
                  :class="{'selected': currentCategory === '_past'}"
                  @click="currentCategory = '_past'">
                Previously used emails
              </li>
              <li
                  class="menu-item"
                  :class="{'selected': currentCategory === '_manual'}"
                  @click="currentCategory = '_manual'">
                Your creations
              </li>
            </ul>
          </div>
        </div>

        <div class="grow md:ml-8">
          <div
              v-if="emailsToDisplay.length === 0"
              class="h-full grow flex items-center justify-center">
            <div class="px-8 max-w-lg text-gray-500 text-sm font-medium">
              <div
                  v-if="currentCategory === '_past'"
                  class="space-y-6">
                <p>
                  Once you create your first email automation,
                  you can re-use the
                  email design to quickly create new email
                  automations.
                </p>
                <p>
                  All of your email automations will create a template that will be available in this section
                  “Previously
                  used emails” for faster automation setup.
                </p>
              </div>
              <span v-if="currentCategory === '_manual'">Start building your custom email design library! For faster template creation, start a new template using the design from an existing email automation, or start from scratch.</span>
            </div>
          </div>
          <div v-if="emailsToDisplay.length > 0">
            <div class="flex justify-end">
              <div class="py-2">
                <input type="text" class="form-input" placeholder="Search..." v-model="filterText">
              </div>
            </div>
            <div class="mt-8 py-8 px-8 text-gray-700 text-sm font-medium bg-gray-100 rounded border border-gray-300"
                 v-if="filteredEmails.length === 0">
              No email templates found for <strong>{{ filterText }}</strong>
            </div>
            <div
                class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 grid-wrap" v-if="filteredEmails.length > 0">
              <div
                  v-for="emailTemplate in filteredEmails"
                  :key="emailTemplate.id"
                  class="rounded-lg overflow-hidden shadow-md bg-white cursor-pointer hover:opacity-75"
                  role="button"
                  @click="selectTemplate(emailTemplate)">
                <div class="flex flex-col h-full">
                  <div class="grow">
                    <img
                        :src="emailTemplate.thumbnail"
                        alt=""
                        class="h-48 w-full object-cover">
                    <div class="px-4 py-4">
                      <span class="text-lg">{{ emailTemplate.name }}</span>
                    </div>
                  </div>
                  <div>
                    <div class="flex items-center justify-center w-full border-t py-2 px-2 bg-gray-100">
                      <div class="px-2">
                        <button
                            class="appearance-none text-sm font-medium text-blue-900 hover:text-blue-600"
                            @click="selectTemplate(emailTemplate)">
                          Use this template
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.menu-item {
  @apply px-4 py-2 rounded-lg text-gray-800 ;

  &.selected {
    @apply bg-gray-200 font-medium cursor-default;
  }

  &:hover {
    @apply bg-blue-100 cursor-pointer text-blue-800;
  }

}
</style>
<script lang="ts" setup>
import Loader                                                                                 from "@/components/layout/Loader.vue";
import {computed, ref}                                                                        from "vue";
import {QLFragrmentSmallEmailTemplateFragment, useEmailTemplateQuery, useEmailTemplatesQuery} from "@/graphql/queries/ql/composables";
import emailTemplateDecorator                                                                 from "@/composables/decorators/emailTemplateDecorator";
import FuzzySearch                                                                            from "fuzzy-search";

const emit = defineEmits(["selected"]);

const currentCategory = ref("_all");
const loadingFullVersion = ref(false);
const filterText = ref("");

const {result, loading: loadingQuery} = useEmailTemplatesQuery();
const email_templates = computed(() => result.value?.email_templates ?? []);

const loading = computed(() => {
  return loadingQuery.value || loadingFullVersion.value;
});

const categories = computed(() => {
  return Array.from(new Set(filteredEmails.value.map((i) => i.category).filter(i => i != null && i.length > 0)));
});

const emailTemplateQRun = ref(false);
const emailTemplateQ = useEmailTemplateQuery({id: "0"}, () => ({
  enabled: emailTemplateQRun.value
}));

const emailsToDisplay = computed<Array<QLFragrmentSmallEmailTemplateFragment>>(() => {
  if (currentCategory.value === "_all") {
    // all but manual
    return email_templates.value.filter(email => {
      const d = emailTemplateDecorator(email);
      return !d.computed.isPreviousEmail && !d.computed.isCustomEmail;
    });
  }

  if (currentCategory.value === "_manual") {
    return email_templates.value.filter(email => {
      const d = emailTemplateDecorator(email);
      return d.computed.isCustomEmail;
    });
  }
  if (currentCategory.value === "_past") {
    return email_templates.value.filter(email => {
      const d = emailTemplateDecorator(email);
      return d.computed.isPreviousEmail;
    });
  }

  return email_templates.value.filter(email => email.category === currentCategory.value);
});

const selectTemplate = (emailTemplate) => {
  loadingFullVersion.value = true;
  emailTemplateQ.variables.value = {
    id: emailTemplate.id
  };
  emailTemplateQRun.value = true;

  emailTemplateQ.onResult((queryResult) => {
    if (!queryResult.loading) {
      loadingFullVersion.value = false;
      emit("selected", queryResult.data.email_template);
    }
  });
};

const filteredEmails = computed(() => {
  const searcher = new FuzzySearch(emailsToDisplay.value, ["name"], {sort: true});
  return searcher.search(filterText.value);
});

</script>
