import FixedValueOperator, {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import GraphQlOperator                                    from "hm-filters/operators/GraphQlOperator";
import QLQueriesData                                      from "hm-filters/operators/GraphQls/QLQueriesData";
import {OperatorTypes, OperatorTypesFullName}             from "hm-filters/operators/OperatorTypes";
import Form                                               from "hm-filters/filters/Forms/forms";

class FormNameFilter extends Form {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new FormReceivedQlOperator("=", "at least once").setIsMultipleChoice(),
			new FormReceivedQlOperator("only", "only").setAloneInFilterGroup(),
			new FormReceivedQlOperator("!=", "never"),
			new OperatorAsFixedValueOperator("any", "any form"),
			new OperatorAsFixedValueOperator("never", "no form")
		];
	}

	get type(): string {
		return "FormNameFilter";
	}
}

export default FormNameFilter;

export class FormReceivedQlOperator extends GraphQlOperator {
	graphQLData = QLQueriesData.FormNameQlOperator;

	get type(): string {
		return OperatorTypes.GraphQlMultiple;
	}

	get fullType(): string{
		return OperatorTypesFullName.FormNameQlOperator;
	}
}
