<template>
  <div class="cursor-default">
    <div class="loading-bg" v-bind:class="[classType]">
      <span class="opacity-0">{{ calculatedText }}</span>
    </div>
  </div>
</template>
<script lang="ts">

import {computed, toRef} from "vue";


export default {
  props: {
    text:       {
      type:     String,
      required: false,
      default:  () => null
    },
    classType:  {
      type:     String,
      required: false,
      default:  () => "inline-block"
    },
    randomText: {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    min:        {
      type:     Number,
      required: false,
      default:  () => 10
    },
    max:        {
      type:     Number,
      required: false,
      default:  () => 40
    }
  },
  setup(props) {
    const randomText = toRef(props, "randomText");
    const text = toRef(props, 'text');
    const min = toRef(props, 'min');
    const max = toRef(props, 'max');
    const lettersAndNumbers = [..."abcdefghijklmnopqrstuvwxyz0123456789 "];

    const generator = (base, len) => {
      return [...Array(len)]
          .map(i => base[Math.random() * base.length | 0])
          .join("");
    };

    const getRandomString = (): string =>{
      let random = Math.round(Math.random() * (max.value - min.value) + min.value);
      return generator(lettersAndNumbers, random);
    }

    const calculatedText = computed(() => {
      if (randomText.value) {
        return getRandomString();
      }
      return text.value;
    });

    return {
      calculatedText
    }
  }

};
</script>
