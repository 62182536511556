<template>
  <th
      class="th"
      :class="{'text-gray-700': isUp || isDown}">
    <div class="inline-flex items-center">
      <div :class="{'pr-1': hasSort}">
        <slot />
      </div>
      <div v-if="hasSort">
        <div
            :class="{'text-gray-900': isUp, 'text-gray-400': !isUp}"
            @click="changeSort(true)">
          <svg-icon
              name="down"
              class="block w-4 h-4 transform rotate-180  cursor-pointer hover:text-blue-700" />
        </div>
      </div>
      <div v-if="hasSort">
        <div
            :class="{'text-gray-900': isDown, 'text-gray-400': !isDown}"
            @click="changeSort(false)">
          <svg-icon
              name="down"
              class="w-4 h-4 cursor-pointer hover:text-blue-700" />
        </div>
      </div>
    </div>
  </th>
</template>
<script lang="ts" setup>
import SvgIcon from "@/components/SvgIcon.vue";

interface Props {
  hasSort?: boolean;
  isUp?: boolean;
  isDown?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  hasSort: false,
  isUp:    false,
  isDown:  false

});

import {ref, useSlots, useAttrs, toRefs, toRef} from "vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["up", "down"]);
const changeSort = (isUp: boolean) => {
  if (isUp) {
    emit("up");
  } else {
    emit("down");
  }
};

const isUp = toRef(props, "isUp");
const isDown = toRef(props, "isDown");
const hasSort = toRef(props, "hasSort");

</script>
