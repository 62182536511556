import {ApolloError} from "@apollo/client/core";
import $toast        from "@/composables/toast";

export function toastIfRateLimitWasHit(err: ApolloError): boolean {
	if (err.graphQLErrors.length > 0) {
		const isRateLimit = err.graphQLErrors.find((i) => {
			return i.extensions?.category == "rate-limit";
		});
		if (isRateLimit) {
			$toast({
				       type:    "error",
				       message: "Please slow down and try again later."
			       });
            return true;
		}

        return false;
	}
}
