import Contract                       from "./partials/contract";
import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import GraphQlOperator                from "hm-filters/operators/GraphQlOperator";
import MultipleTextOperator           from "hm-filters/operators/MultipleTextOperator";
import QLQueriesData                          from "hm-filters/operators/GraphQls/QLQueriesData";
import {OperatorTypes, OperatorTypesFullName} from "hm-filters/operators/OperatorTypes";
import OperatorGenerator                      from "hm-filters/operatorGenerator";

class ContractName extends Contract {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new ContractNameQlOperator("=", "at least once")
				.setIsMultipleChoice()
				.allowsCustomInput(),
			new ContractNameQlOperator("only", "only")
				.setIsMultipleChoice()
				.allowsCustomInput()
				.setAloneInFilterGroup(),
			new ContractNameQlOperator("!=", "never"),
			...OperatorGenerator.textContainsMultiple(),
			new OperatorAsFixedValueOperator("any", "any contract"),
			new OperatorAsFixedValueOperator("never", "no contract")
		];
	}

	get type(): string {
		return "ContractNameFilter";
	}
}

export default ContractName;

export class ContractNameQlOperator extends GraphQlOperator {
	graphQLData = QLQueriesData.ContractNameQlOperator;

	get type(): string {
		return OperatorTypes.GraphQlMultiple;
	}

	get fullType(): string{
		return OperatorTypesFullName.ContractNameQlOperator;
	}
}
