import {computed, isRef, reactive, ref, Ref} from "vue";
import {
	QLDiscussion, QLFragmentDiscussionFromAutomationsFragment, QLFragmentDiscussionFullFragment,
	QLFragmentMessageFragment, QLMessage, QLMessageErrorType
}                                            from "@/graphql/queries/ql/composables";

export enum MessageStatus {
	SENDING,
	FAILED,
	PENDING_STATUS,
	DELIVERED,
	BOUNCED
}

export default function MessageDecorator(passed: Ref<QLFragmentMessageFragment | QLMessage> | QLFragmentMessageFragment | QLMessage,
                                         discussion: Ref<QLDiscussion | QLFragmentDiscussionFullFragment | QLFragmentDiscussionFromAutomationsFragment> | QLDiscussion | QLFragmentDiscussionFullFragment | QLFragmentDiscussionFromAutomationsFragment | null) {
	const entity = isRef(passed) ? passed : ref(passed);
	const _discussion = isRef(discussion) ? discussion : ref(discussion);

	const senderName = computed(() => {
		if (entity.value.from_client) {
			return _discussion.value.client?.first_name + " " + _discussion.value.client?.last_name;
		}

		if (entity.value.user?.id) {
			return entity.value.user.name;
		}


		if (!entity.value.user?.id && _discussion.value.automation_id) {
			return "LoopSpark";
		}

		return "";
	});


	const messageText = computed(() => {
		if (_discussion.value.sms) {
			return entity.value.message;
		}
		if (!<string><unknown>entity.value.message.includes("</html>")) {
			return entity.value.message;
		}
		const doc = new DOMParser().parseFromString(<string>entity.value.message, "text/html");
		const txt = doc.body.textContent || "";
		return txt.replace("Don't like these emails? Unsubscribe", "")
		          .replace("Don't like these emails? Manage Email Preferences", "")
		          .replace(" ", " ")
		          .replace("&nbsp;", " ")
		          .replace(/Powered by(.*)(LoopSpark|StudioGo)+/g, "")
		          .replace(/^\s*[\r\n]/gm, "")
		          .trim();
	});

	const status = computed((): MessageStatus => {
		if (entity.value.sent && entity.value.delivered) {
			return MessageStatus.DELIVERED;
		}
		if (entity.value.sent && entity.value.bounced) {
			return MessageStatus.BOUNCED;
		}
		if (entity.value.sent) {
			return MessageStatus.PENDING_STATUS;
		}

		if (entity.value.created_at < (Math.round(Date.now() / 1000) - (10 * 60))) {
			return MessageStatus.FAILED;
		}

		return MessageStatus.SENDING;
	});

	const errorMessage = computed(() => {
		switch (entity.value.error_code) {
			case QLMessageErrorType.BlockedByStop:
				return "This client has sent a STOP message and they can't be contacted via SMS until they send a message containing \"START\".";
			case QLMessageErrorType.InvalidMessage:
				return "Our SMS provider could not deliver this message because of the following reason : InvalidMessage";
			case QLMessageErrorType.Spam:
				return "Our SMS provider could not deliver this message because it was flagged as SPAM.";
			case QLMessageErrorType.InvalidDestinationNumber:
				return "The phone number of this client was flagged as invalid from our SMS provider. We suggest to change it to a working number";
			case QLMessageErrorType.DestinationPermanentlyUnavailable:
			case QLMessageErrorType.DestinationTemporaryUnavailable:
				return "The phone number of this client was flagged as Unavailable or Temporary Unavailable from our SMS provider. We suggest validating this mobile number with your client.";
			case QLMessageErrorType.ProhibitedByCarrier:
				return "Our SMS provider could not deliver this message because it was flagged as Prohibited by the client's network carrier.";
			case QLMessageErrorType.LoopDetected:
				return "Our SMS provider could not deliver this message because of the following reason: LoopDetected";
			case QLMessageErrorType.NoRoute:
				return "Our SMS provider could not deliver this message because of the following reason: NoRoute";
			case QLMessageErrorType.NetworkError:
				return "Our SMS provider could not deliver this message because of the following reason: NetworkError";
			default:
				return null;
		}
	});

	return {
		computed: reactive({
			                   senderName,
			                   messageText,
			                   status,
			                   errorMessage
		                   })
	};

};
