import CalendarGroup   from "./partials/CalendarGroup";
import NumericOperator from "../operators/NumericOperator";

class CalendarCurrentDateInMonth extends CalendarGroup {
	constructor(name: string, options: any) {
		super(name, options);

		this.clearOperators();
		this.addOperators([
			                  new NumericOperator("=", "is").setMinValue(1).setMaxValue(31),
			                  new NumericOperator("!=", "is not").setMinValue(1).setMaxValue(31)
		                  ]);
	}

	get type(): string {
		return "CurrentDateInMonthFilter";
	}
}


export default CalendarCurrentDateInMonth;
