<template>
  <div>
    <div class="mb-4">
      <text-input
          v-model="searching"
          label="Search filters"
          focus />
    </div>
    <ul
        ref="ullist"
        class="divide-y">
      <li
          v-for="(filter, index) in searchedFilters"
          :key="filter.fullId"
          class="py-4 px-2 hover:bg-blue-50 cursor-pointer  focus:bg-green-50 focus:ring-0 focus:outline-none"
          :class="{'bg-green-50': selectedIndex === index}"
          tabindex="0"
          role="menuitem"
          @keyup.enter="selectOne(filter)"
          @keyup.space="selectOne(filter)"
          @click="selectOne(filter)">
        <div class="flex items-center justify-between">
          <filter-in-filter-group
              :filter="filter"
              class="grow" />
          <div>
            <svg-icon
                name="right"
                class="h-4 w-4 text-gray-500" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>

import Filter                                      from "hm-filters/base-filter";
import FilterInFilterGroup                         from "@/components/Segment/Edit/Categories/FilterInFilterGroup.vue";
import Fuse                                        from "fuse.js";
import TextInput                                   from "@/components/layout/Forms/TextInput.vue";
import SvgIcon                                     from "@/components/SvgIcon.vue";
import {computed, onBeforeUnmount, onMounted, ref} from "vue";

interface Props {
  filters: Filter[];
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["selected"]);

const ullist = ref<HTMLUListElement>(null);

const searching = ref("");
const selectedIndex = ref(-1);

const searchedFilters = computed(() => {
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  selectedIndex.value = -1;
  if (searching.value === "") {
    return props.filters;
  }

  const options = {
    includeScore: true,
    shouldSort:   true,
    threshold:    0.3,
    distance:     50,
    keys:         [
      "name",
      "path",
      "description"
    ]
  };

  const fuse = new Fuse(props.filters, options);
  return fuse.search(searching.value).map((i) => i.item);
});

const nextItem = (event: KeyboardEvent) => {
  if (event.key == "ArrowDown" || event.key == "ArrowUp") {
    if (event.key == "ArrowDown") {
      selectedIndex.value += 1;
    }
    if (event.key == "ArrowUp") {
      selectedIndex.value -= 1;
    }

    if (selectedIndex.value < 0) {
      selectedIndex.value = 0;
    }
    if (selectedIndex.value > searchedFilters.value.length - 1) {
      selectedIndex.value = searchedFilters.value.length - 1;
    }

    ullist.value.getElementsByTagName("li")[selectedIndex.value].focus();
  }
};

onMounted(() => {
  document.addEventListener("keyup", nextItem);
});
onBeforeUnmount(() => {
  document.removeEventListener("keyup", nextItem);
});

const selectOne = (one) => {
  emit("selected", one);
};

</script>
