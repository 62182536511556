<template>
  <div>
    <div class="mb-8">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">
            History
          </h1>
          <p class="mt-2 text-sm text-gray-700">
            List of all completed form submissions, ordered by most recent submission.
          </p>
        </div>
        <div
            v-if="loadedFirstTime && history.length > 0"
            class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <form-export-button :form="form" />
        </div>
      </div>
    </div>
    <empty v-if="loadedFirstTime && history.length === 0">
      <template #title>
        Nothing to show!
      </template>
      <template #subtitle>
        This form has not been completed by anyone yet.
      </template>
    </empty>

    <div
        v-if="!loadedFirstTime"
        class="w-full h-40 px-2 my-2">
      <div class="flex h-full items-center justify-center">
        <div class="w-12 h-12 text-gray-400 opacity-75">
          <loader />
        </div>
      </div>
    </div>
    <div
        v-if="loadedFirstTime && history.length > 0"
        class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
              <tr>
                <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Client
                </th>
                <th
                    v-if="form.is_legal"
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 text-center">
                  PDF
                </th>
                <th
                    scope="col"
                    class="px-3 py-3.5 text-sm font-semibold text-gray-900 text-right">
                  Date
                </th>
                <th
                    scope="col"
                    class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
              <tr
                  v-for="formInstance in history"
                  :key="formInstance.id">
                <td class="w-full py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div class="flex items-center">
                    <div class="h-10 w-10 flex-shrink-0">
                      <client-avatar
                          class="w-10 h-10"
                          :client="formInstance.client" />
                    </div>
                    <div class="ml-4">
                      <router-link
                          :to="{name: routeList.client.forms.show, params: {id: formInstance.client.id, formInstanceId: formInstance.id}}"
                          class="text-sm leading-5 font-medium text-loopspark-500 group-hover:text-loopspark-700">
                        {{ formInstance.client.first_name }} {{ formInstance.client.last_name }}
                      </router-link>
                      <div class="text-gray-500">
                        <client-status-text :client="formInstance.client" />
                      </div>
                    </div>
                  </div>
                </td>
                <td
                    v-if="form.is_legal"
                    class="whitespace-nowrap px-5 py-4 text-sm text-gray-500 text-center">
                  <a
                      v-if="formInstance.pdf_url"
                      :href="formInstance.pdf_url"
                      target="_blank"
                      class="text-link">
                    <svg-icon
                        name="download"
                        class="h-4 w-4" />
                  </a>
                </td>
                <td class="whitespace-nowrap px-5 py-4 text-sm text-gray-500 text-right">
                  <div class="text-gray-700">
                    <timestamp-to-date
                        natural-date
                        :timestamp="formInstance.created_at" />
                  </div>
                  <div>
                    <time-ago :timestamp="formInstance.created_at" />
                  </div>
                </td>
                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <router-link
                      :to="{name: routeList.client.forms.show, params: {id: formInstance.client.id, formInstanceId: formInstance.id}}"
                      class="text-indigo-600 hover:text-indigo-900">
                    View
                  </router-link>
                </td>
              </tr>
              </tbody>
              <tfoot
                  v-if="history && paginatorInfo.total > 0 && paginatorInfo.lastPage > 1"
                  class="bg-gray-50">
              <tr>
                <td
                    colspan="5"
                    class="p-4">
                  <pagination
                      v-if="paginatorInfo.lastPage > 1"
                      :pagination="paginatorInfo"
                      class="flex items-center justify-between text-gray-700 text-sm"
                      @set-page="paginationData.page = $event">
                    <div
                        v-if="loading"
                        class="inline-block w-8 h-8 mr-2 text-gray-400">
                      <loader />
                    </div>
                  </pagination>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  QLFormInstancesHistoryQuery, QLFormInstancesHistoryQueryVariables, QLFragmentFormFullFragment, QLSortOrder,
  useFormInstancesHistoryQuery
}                                                          from "@/graphql/queries/ql/composables";
import {computed, toRef, useAttrs, useSlots}               from "vue";
import {createPaginationData, paginationWithAlreadyLoaded} from "@/composables/paginationWithAlreadyLoaded";
import ClientAvatar     from "@/components/client/ClientAvatar.vue";
import routesList       from "@/composables/routesList";
import TimestampToDate  from "@/components/layout/TimestampToDate.vue";
import TimeAgo          from "@/components/layout/TimeAgo.vue";
import SvgIcon          from "@/components/SvgIcon.vue";
import Pagination       from "@/components/layout/Pagination.vue";
import Loader           from "@/components/layout/Loader.vue";
import Empty            from "@/components/layout/Empty.vue";
import ClientStatusText from "@/components/client/ClientStatusText.vue";
import FormExportButton from "@/views/Forms/Components/Show/FormExportButton.vue";
import {EventTypes}     from "@/classes/notifications/EventTypes";
import {echoListener}   from "@/composables/echoListener";

const {routeList} = routesList();

interface Props {
  form: QLFragmentFormFullFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const form = toRef(props, "form");
const perPageOptions = [
  {label: 10, value: 10},
  {label: 25, value: 25},
  {label: 50, value: 50}
];

const {paginationData} = createPaginationData({
                                                page:     1,
                                                perPage:  10,
                                                perPages: [10, 25, 50]
                                              });

const historyVariables = computed<QLFormInstancesHistoryQueryVariables>(() => {
  return {
    page:    paginationData.page,
    first:   paginationData.perPage,
    form_id: form.value.id,
    orderBy: [{column: "created_at", order: QLSortOrder.DESC}]
  };
});

const historyQ = useFormInstancesHistoryQuery(historyVariables);

const {
  paginatorInfo,
  loadedFirstTime,
  results: history,
  loading
} = paginationWithAlreadyLoaded<QLFormInstancesHistoryQuery["form_instances_history"]["data"]>(historyQ);

echoListener([EventTypes.FormInstanceWasCreated], (ev) => {
  if(ev.data.form_id == props.form.id){
    historyQ.refetch();
  }
}, 5000);

</script>
