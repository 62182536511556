<template>
  <Menu
      as="div"
      class="relative inline-block text-left">
    <div>
      <MenuButton
          ref="trigger"
          class="text-sm font-medium text-blue-900 inline-flex items-center">
        <svg-icon
            name="dots-vertical"
            class="-mr-1 ml-1 h-5 w-5"
            aria-hidden="true" />
      </MenuButton>
    </div>
    <Portal>
      <div ref="popoverPanel">
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
          <MenuItems class=" mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 w-64">
            <div class="py-1 divide-y w-full">
              <MenuItem v-slot="{ active }">
                <router-link
                    :to="{name: routeList.forms.show.index, params: {id: formFragment.id}}"
                    :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 pr-8 py-2 text-sm']">
                  View Form details
                </router-link>
              </MenuItem>
              <MenuItem
                  v-if="formFragment.cc > 0"
                  v-slot="{ active }">
                <button
                    :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 pr-8 py-2 text-sm w-full text-left']"
                    @click="open = true">
                  View client's
                  completions...
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </div>
    </Portal>
  </Menu>
  <client-forms-recap-line-modal
      v-model:open="open"
      :form-fragment="formFragment"
      :client="client" />
</template>
<script lang="ts" setup>
import {QLClient, QLFragmentFormWithCountFragment}     from "@/graphql/queries/ql/composables";
import {Menu, MenuButton, MenuItem, MenuItems, Portal} from "@headlessui/vue";

import SvgIcon                          from "@/components/SvgIcon.vue";
import {ref, toRef, useAttrs, useSlots} from "vue";
import {useRouter}                      from "vue-router";
import routesList                       from "@/composables/routesList";
import ClientFormsRecapLineModal        from "@/views/ClientShow/Components/Forms/ClientFormsRecapLineModal.vue";
import {usePopper}                      from "@/composables/usePopper";

interface Props {
  formFragment: QLFragmentFormWithCountFragment;
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
const router = useRouter();
const {routeList} = routesList();
const formFragment = toRef(props, "formFragment");
const open = ref(false);

const [trigger, popoverPanel] = usePopper({
                                            placement: "left",
                                            strategy:  "fixed",
                                            modifiers: [
                                              {
                                                name:    "offset",
                                                options: {
                                                  offset: [0, -10]
                                                }
                                              }
                                            ]
                                          });
</script>
