<template>
  <div>
    <h1 class="main-page-title">
      Discussions with {{ client.first_name }}
    </h1>

    <div
        v-if="!loadedFirstTime"
        class="block bg-white rounded shadow-lg">
      <div class="animate-loading">
        <div
            v-for="i in 5"
            :key="i"
            class="p-4 border-b border-gray-200">
          <div class="flex justify-between pb-2">
            <loading-skeleton
                :text="i % 2 === 0 ? 'TEXT' : 'EMAIL'"
                class="text-xs tracking-wide" />
            <loading-skeleton
                class="text-xs"
                text="10 days ago" />
          </div>
          <loading-skeleton
              class="pb-1 text-lg"
              text="Direct discussion" />
          <loading-skeleton
              class="text-sm"
              class-type="block"
              text="Sample long message replied" />

          <div class="mt-4">
            <div class="space-x-2 flex">
              <loading-skeleton text="--- Action needed" />
              <loading-skeleton text="--- Delivered" />
              <loading-skeleton
                  v-if="i === 2 || i === 4"
                  text="--- Bounced" />
              <loading-skeleton
                  v-if="i === 2 || i === 4"
                  text="--- Bounced" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loadedFirstTime">
      <div
          v-if="discussions && discussions.length > 0"
          class="block bg-white rounded shadow-lg oveflow-hidden">
        <div class="">
          <client-discussion-list-item
              v-for="discussion in discussions"
              :key="discussion.id"
              :client="client"
              :discussion="discussion"
              class="border-b border-gray-200" />
        </div>
        <div
            v-if="discussions && paginatorInfo.total > 0&& paginatorInfo.lastPage > 1"
            class="w-full pt-4 pb-4 text-right bg-gray-100 border-t border-gray-200 px-4 rounded-b">
          <pagination
              v-if="paginatorInfo.lastPage > 1"
              :pagination="paginatorInfo"
              class="flex items-center justify-between text-gray-700 text-sm"
              @setPage="paginationData.page = $event">
            <div
                v-if="loading"
                class="inline-block w-8 h-8 mr-2 text-gray-400">
              <loader />
            </div>
          </pagination>
        </div>
      </div>
      <div v-if="discussions && discussions.length === 0">
        <empty>
          <template #title>Nothing to show!</template>
          <template #subtitle>
            <p class="text-gray-600 pt-2">
              There aren't any discussions with {{ client.first_name }} yet!
            </p>
            <div class="flex pt-6 space-x-4 justify-center" v-if="me && me.policy.send_messages">
              <button
                  v-if="client.can_contact_by_email"
                  class="text-sm text-blue-600 hover:text-blue-800"
                  @click="sendEmail">
                Send a new email
              </button>
              <button
                  v-if="client.can_contact_by_sms"
                  class="text-sm text-blue-600 hover:text-blue-800"
                  @click="sendText">
                Send a new text
              </button>
            </div>
          </template>
        </empty>
      </div>

      <client-send-email
          v-model:open="modalsStatus.email"
          :client="client" />
      <client-send-text
          v-model:open="modalsStatus.sms"
          :client="client" />
    </div>
  </div>
</template>
<script lang="ts" setup>

import ClientDiscussionListItem                             from "@/components/client/Discussions/ClientDiscussionListItem.vue";
import Pagination                                           from "@/components/layout/Pagination.vue";
import Loader                                               from "@/components/layout/Loader.vue";
import ClientSendEmail                                      from "@/components/client/ClientSendEmail.vue";
import ClientSendText                                       from "@/components/client/ClientSendText.vue";
import LoadingSkeleton                                      from "@/components/layout/LoadingSkeleton.vue";
import {createPaginationData, paginationWithAlreadyLoaded}  from "@/composables/paginationWithAlreadyLoaded";
import {ref, useSlots, useAttrs, reactive, computed, toRef} from "vue";
import {
  QLClient, QLClientDiscussionsQuery, QLClientDiscussionsQueryVariables, QLClientsListIndexQuery, QLSortOrder,
  useClientDiscussionsQuery
}              from "@/graphql/queries/ql/composables";
import Empty   from "@/components/layout/Empty.vue";
import {
  echoListener
}              from "@/composables/echoListener";
import {
  EventTypes
}              from "@/classes/notifications/EventTypes";
import meQuery from "@/composables/queries/meQuery";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");

const {me} = meQuery();

const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 10
                                              });

const modalsStatus = reactive({
                                email: false,
                                sms:   false
                              });

const discusssionsVariables = computed<QLClientDiscussionsQueryVariables>(() => {
  return {
    client_id: client.value.id,
    page:      paginationData.page,
    first:     paginationData.perPage,
    orderBy:   [{column: "last_contacted_at", order: QLSortOrder.DESC}]
  };
});

const discussionsQ = useClientDiscussionsQuery(discusssionsVariables);

const {
  paginatorInfo,
  loadedFirstTime,
  results: discussions,
  loading
} = paginationWithAlreadyLoaded<QLClientDiscussionsQuery["discussions"]["data"]>(discussionsQ);

echoListener([EventTypes.DiscussionUpdated, EventTypes.DiscussionNew], (ev) => {
  if (ev.data.client_id == client.value.id) {
    discussionsQ.refetch();
  }
}, 5000);

const sendEmail = () => {
  if (client.value.can_contact_by_email) {
    modalsStatus.email = true;
  }
};

const sendText = () => {
  if (client.value.can_contact_by_sms) {
    modalsStatus.sms = true;
  }
};
</script>
