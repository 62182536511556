import Operator from "hm-filters/operators/operator";

export interface WithCustomInputInterface {
	allowsCustom: boolean;

	allowsCustomInput(): this;
}

export default class WithCustomInput extends Operator implements WithCustomInputInterface {
	allowsCustom: boolean = false;

	allowsCustomInput(): this {
		this.allowsCustom = true;
		return this;
	}

}
