<template>
  <side-panel v-model:open="openModel" wide>
    <template #header>
      Announcements
    </template>
    <div class="px-4 pt-4">
      <loader
          v-if="loading"
          class="w-8 h-8" />
      <div v-if="!loading">
        <div v-if="announcements.length > 0" class="-my-8 px-2 divide-y">
          <view-announcement
              v-for="announcement in announcements"
              :key="announcement.id"
              :announcement="announcement"
              class="py-8"
          />
        </div>
        <div
            v-if="announcements.length == 0"
            class="rounded bg-gray-50 p-2 py-8 border border-gray-200">
          <p class="text-gray-800 text-sm text-center">
            There are no announcements at the moment
          </p>
        </div>
      </div>
    </div>
  </side-panel>
</template>
<script lang="ts" setup>

interface Props {
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});


const emit = defineEmits(["update:open"]);
import SidePanel                                                   from "@/components/layout/SidePanel.vue";
import Loader                                                      from "@/components/layout/Loader.vue";
import {computed}                                                  from "vue";
import {useAnnouncementsQuery, useAnnouncementsUpdateReadMutation} from "@/graphql/queries/ql/composables";
import {useResult}                                                 from "@vue/apollo-composable";
import ViewAnnouncement                                            from "@/components/notifications/ViewAnnouncement.vue";

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const {result, loading, onResult} = useAnnouncementsQuery(() => ({enabled: openModel.value}));
const announcements = useResult(result, [], data => data.announcements);

const readMutation = useAnnouncementsUpdateReadMutation();

onResult((data) => {
  if(data.loading === false){
    markAsRead();
  }

})

const markAsRead = () => {
  readMutation.mutate();
};

</script>
