export interface FilterConfigBase {
	group: string;
}

export interface FilterConfigVisits {
	count: string,
	filter: string,
	first: string,
	location: string,
	usingPackage: string,
	groups: {
		visit: FilterConfigBase,
		upcomingVisit: FilterConfigBase,
		lateCancelled: FilterConfigBase,
		signedUp: FilterConfigBase,
		absent: FilterConfigBase,
		early_cancel: FilterConfigBase,
	}
}

export interface FilterConfigAppointments {
	count: string,
	filter: string,
	first: string,
	location: string,
	usingPackage: string,
	groups: {
		appointment: FilterConfigBase,
		upcoming: FilterConfigBase,
		noShow: FilterConfigBase,
	}
}

export interface FilterConfig {
	visits: FilterConfigVisits,
	appointments: FilterConfigAppointments
}

export default <FilterConfig>{
	visits: {
		count:        "Count",
		filter:       "History",
		first:        "First",
		location:     "Location",
		usingPackage: "Using Package",
		groups:       {
			visit:         {
				group: "Visit"
			},
			upcomingVisit: {
				group: "Upcoming"
			},
			lateCancelled: {
				group: "Late Cancel"
			},
			signedUp:      {
				group: "Signed up"
			},
			absent:        {
				group: "Absent"
			},
			early_cancel:  {
				group: "Early Cancel"
			}
		}
	},

	appointments: {
		count:        "Count",
		filter:       "History",
		first:        "First",
		location:     "Location",
		usingPackage: "Using Package",
		groups:       {
			appointment: {
				group: "Appointment"
			},
			upcoming:    {
				group: "Upcoming"
			},
			noShow:      {
				group: "No Show"
			}
		}
	}
};
