<template>
  <div>
    <div class="flex justify-between">
      <div class="flex flex-col justify-between">
        <div>
          <div class="text-lg">
            {{ pkg.name }}
          </div>

          <div v-if="decorated.usageIsRelevant" class="text-sm text-gray-700"><span class="font-medium">{{
              pkg.remaining
                                                                                                        }}</span> of {{
              pkg.qta
                                                                                                                  }}
                                                                                                                  remaining
          </div>
        </div>

        <div class="text-sm text-gray-700">
          <div v-if="decorated.isOneTimer && pkg.last_used_at > 0">Used @
            <timestamp-to-date :timestamp="pkg.last_used_at" has-date has-time studio></timestamp-to-date>
          </div>
          <div v-if="!decorated.isOneTimer" class="mt-2">
            <timestamp-to-date :timestamp="pkg.active_at" has-date natural-date studio />
            to
            <timestamp-to-date v-if="pkg.expires_at" :timestamp="pkg.expires_at" has-date natural-date studio />
            <span v-if="!pkg.expires_at">no end date</span>
          </div>
        </div>
      </div>
      <div class="text-xs text-right flex flex-col justify-between">
        <div class="grid grid-cols-1 gap-1 mb-3">
          <div v-if="decorated.isCurrent">
            <span class="badge badge-green font-medium">Current</span>
          </div>
          <div v-if="decorated.isActive"><span class="badge badge-green font-medium">Active</span></div>
          <div v-if="decorated.isCancelled"><span class="badge badge-yellow font-medium">Cancelled</span></div>
          <div v-if="decorated.isUnused"><span class="badge badge-gray">Unused</span></div>
          <div v-if="!decorated.futureExpiration && decorated.isAllUsed"><span class="badge badge-gray">Used</span>
          </div>
        </div>

        <div v-if="decorated.expirationIsRelevant" class="text-gray-600 self-end">
          <span v-if="decorated.futureExpiration">Expires: </span>
          <span v-if="!decorated.futureExpiration">Expired: </span>
          <time-ago :timestamp="pkg.expires_at" studio class="inline"></time-ago>
          <span v-if="decorated.hasContract" class="block"> (on contract)</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import {QLClient, QLPackage} from "@/graphql/queries/ql/composables";

import TimeAgo            from "@/components/layout/TimeAgo.vue";
import TimestampToDate    from "@/components/layout/TimestampToDate.vue";
import {PackageDecorator} from "@/composables/decorators/PackageDecorator";
import {toRef}            from "vue";

interface Props {
  client: QLClient;
  pkg: QLPackage;
}

const props = withDefaults(defineProps<Props>(), {});

const pkg = toRef(props, "pkg");

const {computed: decorated} = PackageDecorator(pkg);

</script>
