<template>
  <div>
    <div class="flex justify-end mb-4 px-4">
      <transition
          enter-active-class="transition ease-in-out duration-200"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="transition ease-in-out duration-200"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
          mode="out-in">
        <div>
          <div
              v-if="!sent && !sending && shouldSendToThisPerson && canSend"
              key="cansend"
              class="text-xs text-green-800 px-2 py-1 border border-green-100 rounded-full inline-flex items-center">
            <svg-icon
                name="check"
                class="w-4 h-4" />
            <div class="ml-1">
              Will send <span
                class="font-medium"
                v-text="usingEmail ? 'an email': 'an SMS'" />
            </div>
          </div>
          <div
              v-if="!sent && !sending && shouldSendToThisPerson && !canSend"
              key="cantsend"
              class="text-xs text-red-800 px-2 py-1 border bg-red-50 border-red-100 rounded-full inline-flex items-center">
            <div
                v-if="!canSend"
                class="text-yellow-800 inline-flex items-center font-bold">
              <svg-icon
                  name="closeCircle"
                  class="w-4 h-4" />
              <div class="ml-1">
                Can't contact
              </div>
            </div>
          </div>
          <div
              v-if="sending"
              key="sending"
              class="text-xs text-blue-900 inline-flex items-center inline-flex px-2 py-0 rounded-full animate-loading">
            <div class="w-4 h-4">
              <loader class="" />
            </div>
            <span class="ml-1">Sending</span>
          </div>

          <div
              v-if="sent && !success"
              key="errormsg"
              class="text-xs text-red-900 inline-flex items-center inline-flex border border-red-200 bg-red-50 px-2 py-1 rounded-full">
            <svg-icon
                name="exclamation"
                class="w-3 h-3" />
            <div class="ml-1 font-bold">
              There was a problem while contacting {{ visit.client.first_name }}.
            </div>
          </div>

          <div
              v-if="sent && success"
              key="sent"
              class="text-xs text-green-600 inline-flex items-center inline-flex px-2 py-0 rounded-full animate-bounce">
            <svg-icon
                name="check"
                class="w-4 h-4" />
            <div class="ml-1 font-bold">
              Sent to {{ visit.client.first_name }}
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Loader                                                                                                                                         from "@/components/layout/Loader.vue";
import SvgIcon                                                                                                                                        from "@/components/SvgIcon.vue";
import {computed, ref, useAttrs, useSlots, watch}                                                                                                     from "vue";
import {toRefs}                                                                                                                                       from "@vueuse/core";
import {
  QLCreateDiscussionEventMutationVariables,
  QLDiscussionCreateMutationVariables, QLFragmentVisitWithClientAndPackageFragment, QLSingleClassFragmentFragment,
  useCreateDiscussionEventMutation, useDiscussionCreateMutation
} from "@/graphql/queries/ql/composables";
import {VisitDecorator}                                                                                                                               from "@/composables/decorators/VisitDecorator";


interface Props {
  singleEvent: QLSingleClassFragmentFragment;
  visit: QLFragmentVisitWithClientAndPackageFragment;
  sendingMethod: "all" | "sms" | "email" | "prefer_email";
  attendanceStatus: "all" | "attending" | "cancelled";
  sendNow: boolean;
  message: string;
  subject: string;
}

const props = withDefaults(defineProps<Props>(), {});


const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["sent"]);

const sending = ref(false);
const sent = ref(false);
const success = ref(false);

const {
  singleEvent,
  visit,
  sendingMethod,
  attendanceStatus,
  sendNow,
  message,
  subject
} = toRefs(props);

const {computed: decorator} = VisitDecorator(visit, singleEvent);

const shouldSendToThisPerson = computed(() => {
  if (attendanceStatus.value === "all") {
    return true;
  }
  if (attendanceStatus.value === "attending") {
    return !decorator.isLateCancelled && !decorator.isEarlyCancelled && !(decorator.isPast && decorator.isAbsent);
  }
  if (attendanceStatus.value === "cancelled") {
    return decorator.isLateCancelled || decorator.isEarlyCancelled || decorator.isAbsent;
  }
  return false;
});

const canSend = computed(() => {
  let email = visit.value.client.can_contact_by_email;
  let sms = visit.value.client.can_contact_by_sms;
  if (sendingMethod.value === "sms" && !sms) {
    return false;
  }
  if (sendingMethod.value === "email" && !email) {
    return false;
  }
  return email || sms;
});

const usingEmail = computed(() => {
  let email = visit.value.client.can_contact_by_email;
  let sms = visit.value.client.can_contact_by_sms;

  if (sendingMethod.value === "sms" && sms) {
    return false;
  }
  if (sendingMethod.value === "email" && email) {
    return true;
  }
  if (sendingMethod.value === "prefer_email") {
    return email;
  }

  return !sms;
});

watch(sendNow, () => {
  if (sendNow.value === true) {
    // sending time
    sendNowIfNeeded();
  } else {
  }
});

const sendMutation = useCreateDiscussionEventMutation({});

sendMutation.onDone(() => {
  sending.value = false;
  sent.value = true;
  success.value = true;
  emit("sent");
  resetAfterTimeout();
});

sendMutation.onError((e) => {
  sending.value = false;
  sent.value = true;
  success.value = false;
  emit("sent");
  resetAfterTimeout();
});

const resetAfterTimeout = () => {
  setTimeout(() => {
    sent.value = false;
    success.value = false;
  }, 5000);
};

const sendNowIfNeeded = () => {
  if (!shouldSendToThisPerson.value || !canSend.value) {
    return;
  }

  sending.value = true;
  sent.value = false;
  success.value = false;

  let vars: QLCreateDiscussionEventMutationVariables = {
    client_id: visit.value.client.id,
    message:   message.value,
    is_email:  usingEmail.value
  };

  if (vars.is_email) {
    vars.subject = subject.value;
  }
  sendMutation.mutate(vars);
};

</script>
