import {QLFragmentUserMeFragment}    from "@/graphql/queries/ql/composables";
import {MyBugsnag}                   from "@/classes/boot/bugsnag";
import {matomoLogout, matomoSetUser} from "@/classes/boot/matomo";

export class CurrentUserInstance {
	private static instance: CurrentUserInstance;
	private _user: QLFragmentUserMeFragment | null;

	get user(): QLFragmentUserMeFragment | null {
		return this._user;
	}

	setUser(value: QLFragmentUserMeFragment | null) {
		this._user = value;

		MyBugsnag.getInstance().setUser(value);
		matomoSetUser(this.user);
	}

	logout() {
		matomoLogout();
	}

	private constructor() {

	}

	public static getInstance(): CurrentUserInstance {
		if (!CurrentUserInstance.instance) {
			CurrentUserInstance.instance = new CurrentUserInstance();
		}

		return CurrentUserInstance.instance;
	}
}
