<template>
  <side-panel
      v-model:open="openModel"
      :closable="!sending">
    <template #header>
      Create Virtual Event Template
    </template>
    <form @submit.prevent="send" ref="form" class="h-full">
      <virtual-event-form
          v-model:form-data="formData"
          :errors="errorsData"
          :sending="sending" />
    </form>
    <template #footer>
      <div
          v-if="!sending"
          class="flex m-2 justify-between w-full">
        <button
            class="btn btn-white"
            @click="openModel = false">
          Cancel
        </button>
        <button
            class="btn btn-green"
            @click="send">
          Create
        </button>
      </div>
      <div
          v-if="sending"
          class="flex m-2 justify-center">
        <div class="h-8 w-8">
          <loader />
        </div>
      </div>
    </template>
  </side-panel>
</template>
<script lang="ts" setup>

import SidePanel                                                                      from "@/components/layout/SidePanel.vue";
import Loader                                                                         from "@/components/layout/Loader.vue";
import VirtualEventForm                                                               from "@/components/Events/VirtualEvents/VirtualEventForm.vue";
import SparkFormErrors                                                                from "@/classes/utils/SparkFormErrors";
import {QLCreateVirtualClassInfoMutationVariables, useCreateVirtualClassInfoMutation} from "@/graphql/queries/ql/composables";
import {computed, ref, useAttrs, useSlots}                                            from "vue";
import {useVModel}                                                                    from "@vueuse/core";
import $toast                                                                         from "@/composables/toast";
import fillValidationErrorsOrToast                                                    from "@/composables/queries/mutations/fillValidationErrorsOrToast";


interface Props {
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open", "created"]);

const openModel = useVModel(props, "open", emit);

const errorsData = ref({});
const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

const formData = ref<QLCreateVirtualClassInfoMutationVariables>({
                                                                  name:         "",
                                                                  virtual_url:  "",
                                                                  day_of_week:  null,
                                                                  start_time:   null,
                                                                  virtual_data: {
                                                                    virtual_id:       null,
                                                                    virtual_info:     null,
                                                                    virtual_password: null
                                                                  }
                                                                });
const form = ref(null);
const sending = ref(false);

const createVirtualMutation = useCreateVirtualClassInfoMutation({});

createVirtualMutation.onDone(() => {
  $toast({
           type:         "success",
           message:      "Virtual event template created",
           linkRouteObj: null,
           linkText:     null
         });
  sending.value = false;
  openModel.value = false;
  emit("created");
});

createVirtualMutation.onError((err) => {
  sending.value = false;
  fillValidationErrorsOrToast(err, errorsData, "Error while creating the virtual event. Please try again!");
});

const send = () => {
  errorsData.value = {};
  if (!form.value.reportValidity()) {
    return;
  }
  sending.value = true;
  createVirtualMutation.mutate(formData.value);
};
</script>
