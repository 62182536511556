<template>
  <button :class="[loading ? 'cursor-wait': '', disabled ? 'cursor-not-allowed': '']"
          :disabled="disabled">
    <slot name="loading">

      <span class="block w-full h-full" v-if="loading">
        <loader class="w-4 h-4"/>
      </span>
    </slot>
    <span class="block" v-if="!loading">
      <slot />
    </span>
  </button>
</template>
<script lang="ts" setup>
import Loader from "@/components/layout/Loader.vue";

interface Props {
  loading?: boolean;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  loading:  false,
  disabled: false
});

import {toRef, useAttrs, useSlots} from "vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["click"]);

const loading = toRef(props, "loading");
const disabled = toRef(props, "disabled");
</script>
