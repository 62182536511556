<template>
  <div>
    <minute-of-day-picker v-model="selectedValue"></minute-of-day-picker>
  </div>
</template>
<script lang="ts" setup>

import MinuteOfDayPicker        from "@/components/layout/Forms/MinuteOfDayPicker.vue";
import {computed, toRef, watch} from "vue";
import Operator                 from "hm-filters/operators/operator";


interface Props {
  operator: Operator;
  modelValue: any;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:modelValue", "is-valid"]);
const modelValue = toRef(props, "modelValue");
const operator = toRef(props, "operator");

const selectedValue = computed({
                                 get: () => {
                                   if(modelValue.value === null){
                                     emit("update:modelValue", 0);
                                   }
                                   return modelValue.value ?? 0;
                                 },
                                 set: (newVal) => emit("update:modelValue",
                                                       newVal !== null ? newVal ?? 0 : null)
                               });


const isValidCalculation = () => {
  let valid = modelValue.value !== null && !isNaN(modelValue.value);
  emit("is-valid", valid);
  return valid;
};

const isValid = computed(() => {
  return isValidCalculation();
});

watch(modelValue, isValidCalculation);

</script>
