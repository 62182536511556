import GraphQlOperator from "hm-filters/operators/GraphQlOperator";
import QLQueriesData                          from "hm-filters/operators/GraphQls/QLQueriesData";
import {OperatorTypes, OperatorTypesFullName} from "hm-filters/operators/OperatorTypes";

export default class AppointmentNameQlOperator extends GraphQlOperator {
	graphQLData = QLQueriesData.AppointmentNameQlOperator;

	get type(): string {
		return OperatorTypes.GraphQlMultiple;
	}

	get fullType(): string{
		return OperatorTypesFullName.AppointmentNameQlOperator;
	}
}
