<template>
  <div
      class="p-4 hover:bg-gray-50"
      :class="{'bg-red-50': decorated.computed.isAbsent, 'bg-yellow-50': decorated.computed.isLateCancelled || decorated.computed.isEarlyCancelled }">
    <div class="md:flex">
      <div class="grow flex flex-col">
        <div class="pb-3">
          <router-link
              :to="{name: routeList.event.show, params: {id: visit.class.id}}"
              class="text-loopspark-500 font-medium pr-1">
            {{ visit.class.name }}
          </router-link>
          <span
              v-if="decorated.computed.hasStaff"
              class="text-sm text-gray-700">with {{ visit.class.staff.name }}</span>
          <div class="text-xs text-gray-800">
            <timestamp-to-date
                :timestamp="visit.class.start_time"
                format="DD, t"
                studio
                has-date
                has-time />
          </div>
        </div>
        <div>
          <div class="flex flex-col md:block md:space-x-2">
            <tag-with-icon
                v-if="decorated.computed.hasPackage"
                color="gray"
                icon="invoice">
              {{
                visit.package.name
              }}
            </tag-with-icon>
            <tag-with-icon
                v-if="hasMoreThanOneLocation && decorated.computed.hasLocation"
                color="gray"
                icon="store">
              {{
                visit.class.location.name
              }}
            </tag-with-icon>
          </div>
        </div>
      </div>

      <div class="flex mt-4 md:mt-0 md:flex-col md:text-right">
        <div class="grow">
          <span class="text-gray-700 font-light text-sm"><time-ago :timestamp="visit.class.start_time" /></span>
        </div>
        <div>
          <span
              v-if="visit.class.cancelled"
              class="badge badge-gray text-sm font-medium">Event Cancelled</span>
          <span
              v-if="decorated.computed.isAbsent"
              class="badge badge-red text-sm font-medium">Absent</span>
          <span
              v-if="decorated.computed.isLateCancelled"
              class="badge badge-yellow text-sm font-medium">Late Cancelled</span>
          <span
              v-if="decorated.computed.isEarlyCancelled"
              class="badge badge-yellow text-sm font-medium">Early Cancelled</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import TagWithIcon       from "@/components/layout/TagWithIcon.vue";
import TimestampToDate   from "@/components/layout/TimestampToDate.vue";
import TimeAgo           from "@/components/layout/TimeAgo.vue";
import {computed, toRef} from "vue";
import {QLVisit}         from "@/graphql/queries/ql/composables";
import currentTeamQuery  from "@/composables/queries/currentTeamQuery";
import {VisitDecorator}  from "@/composables/decorators/VisitDecorator";
import routesList        from "@/composables/routesList";

interface Props {
  visit: QLVisit;
}

const props = withDefaults(defineProps<Props>(), {});

const visit = toRef(props, "visit");
const {currentTeam} = currentTeamQuery();

const {routeList} = routesList();

const hasMoreThanOneLocation = computed(() => {
  if (currentTeam.value) {
    return currentTeam.value.locations.length > 1;
  }

  return false;
});

const decorated = computed(() => {
  return VisitDecorator(visit.value, visit.value.class);
});
</script>
