<template>
  <form
      ref="form"
      class="block overflow-hidden shadow-md rounded-lg"
      @submit.prevent="send">
    <div class="space-y-6 bg-white p-6">
      <div>
        <label class="form-label mb-2">Studio photo</label>

        <div class="flex items-center justify-between space-x-8">
          <img
              ref="imgpreview"
              alt="preview"
              class="w-32 h-32 rounded shrink-0">
          <div class="shrink-0">
            <label class=" flex flex-col items-center px-4 py-4 text-gray-800 bg-white rounded-lg tracking-wide  border border-gray-400 cursor-pointer hover:bg-loopspark-700 hover:text-white">
              <svg
                  class="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
              </svg>
              <span class="mt-1 text-sm leading-normal">Select a photo</span>
              <input
                  ref="file"
                  :disabled="sending"
                  accept="image/*"
                  class="hidden"
                  type="file"
                  @change="handleFileUpload">
            </label>
          </div>
        </div>
        <span
            v-if="sparkErrors.has('photo')"
            class="form-error-message">{{ sparkErrors.get("photo") }}</span>
      </div>
    </div>
    <div class="bg-gray-100">
      <div class="flex justify-end p-6 py-3">
        <v-button
            :disabled="isDisabled"
            :loading="sending"
            class="btn btn-green text-sm font-normal"
            type="submit">
          Update photo
        </v-button>
      </div>
    </div>
  </form>
</template>
<script lang="ts" setup>
import {computed, onMounted, ref, toRef, watch} from "vue";
import VButton                                  from "@/components/layout/VButton.vue";
import SparkFormErrors, {SparkErrors}           from "@/classes/utils/SparkFormErrors";
import {useUpdateTeamProfileImageMutation}      from "@/graphql/queries/ql/composables";
import $toast                                   from "@/composables/toast";
import fillValidationErrorsOrToast              from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import {QLFragmentCurrentTeamFragment}          from "@/graphql/queries/ql/composables";

interface Props {
  currentTeam: QLFragmentCurrentTeamFragment;
}

const props = withDefaults(defineProps<Props>(), {});


const currentTeam = toRef(props, "currentTeam");
const form = ref(null);
const errors = ref<SparkErrors>({});
const sparkErrors = computed(() => {
  return new SparkFormErrors(errors.value);
});

const sending = ref(false);

const file = ref(null);
const {photo, imgpreview, loadFromQL} = fieldsAndLoading(currentTeam);

onMounted(() => {
  sending.value = false;
  loadFromQL();
});

const handleFileUpload = () => {
  photo.value = (file.value as HTMLInputElement).files[0];
  imgpreview.value.src = URL.createObjectURL(photo.value);
  imgpreview.value.onload = () => {
    URL.revokeObjectURL(photo.value);
  };
};

const isDisabled = computed(() => {
  return sending.value || photo.value === null;
});

const {
  mutate,
  onDone,
  onError
} = useUpdateTeamProfileImageMutation(() => ({
      variables: {photo: photo.value},
      context:   {
        hasUpload: true
      }
    }
));

onDone(() => {
  $toast({
           type:         "success",
           message:      "Profile photo changed!",
           linkText:     null,
           linkRouteObj: null
         });
  sending.value = false;
});
onError((error) => {
  fillValidationErrorsOrToast(error, errors, "Error updating your profile photo. Please try again!");
  sending.value = false;
});

const send = () => {
  errors.value = {};
  if (isDisabled.value || !form.value.reportValidity()) {
    return;
  }
  sending.value = true;

  mutate({photo: photo.value}, {
    context: {
      hasUpload: true
    }
  });
};


function fieldsAndLoading(currentTeam) {
  const imgpreview = ref(null);
  const photo = ref(null);


  const loadFromQL = () => {
    imgpreview.value.src = currentTeam.value.photo_url;
  };

  watch(currentTeam, loadFromQL);

  return {
    photo, imgpreview, loadFromQL
  };
}
</script>
