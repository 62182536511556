<template>
  <div class=" flex flex-col h-full grow">
    <div class="px-2">
      <h3 class="font-semibold text-gray-600 mb-2">
        <slot />
      </h3>
    </div>

    <div class="grow flex justify-center items-center">
      <div class="h-48 w-full">
        <div
            v-if="loading"
            class="animate-loading w-full flex items-center justify-center h-full">
          <div class="flex justify-center">
            <div class="loading-bg h-20 w-20 rounded-full" />
          </div>
          <div class="flex justify-center ml-4">
            <div class="flex flex-col space-y-4">
              <div class="inline-flex space-x-2">
                <div class="loading-bg h-6 w-6 rounded" />
                <div class="loading-bg h-6 w-24 rounded" />
              </div>
              <div class="inline-flex space-x-2">
                <div class="loading-bg h-6 w-6 rounded" />
                <div class="loading-bg h-6 w-24 rounded" />
              </div>
            </div>
          </div>
        </div>
        <pie-chart
            v-if="!loading && generatedData.length > 0"
            :items="generatedData"
            :label="chartLabel"
            class="h-full relative"
            item-name="name"
            item-value="cc"
            legend-position="right" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import {PieChartData} from "@/components/layout/Charts/PieChartData";
import PieChart           from "@/components/layout/Charts/PieChart.vue";
import {computed} from "vue";
import {toRefs}       from "@vueuse/core";

interface Props {
  loading: boolean;
  chartLabel: string;
  stats: PieChartData[];
}

const props = withDefaults(defineProps<Props>(), {});

const {loading, chartLabel, stats} = toRefs(props);

const generatedData = computed(() => {
  return stats.value;
});

</script>
