import useEmitter                      from "@/composables/useEmitter";
import {Emitter}                       from "mitt";
import {AllEventTypes, EventInterface} from "@/classes/notifications/EventTypes";

const emitter = useEmitter();

type OnEventFunction = (ev: EventInterface) => void;

type Values<T> = T[keyof T];

type EventsToListen = Values<typeof AllEventTypes>[];

export class EventBus {
	private static instance: EventBus;
	emitter: Emitter<any>;

	constructor() {
		this.emitter = useEmitter();
	}

	public static getInstance(): EventBus {
		if (!EventBus.instance) {
			EventBus.instance = new EventBus();
		}

		return EventBus.instance;
	}

	on(eventName: Values<typeof AllEventTypes>, fn: OnEventFunction) {
		emitter.on(eventName, fn);
	}

	off(eventName: Values<typeof AllEventTypes>, fn: OnEventFunction) {
		emitter.off(eventName, fn);
	}

	emit(eventName: Values<typeof AllEventTypes>, data: EventInterface) {
		emitter.emit(eventName, data);
	}
}
