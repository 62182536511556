<template>
  <div>
    <div class="mb-3 flex justify-between">
      <h3 class="text-lg">
        Packages
      </h3>
      <inline-muted-select
          v-model="status"
          :options="statusOptions"
          select-classes="font-medium" />
    </div>
    <div class="bg-white shadow-md rounded-lg">
      <div
          v-if="!loadedFirstTime"
          class="animate-loading">
        <div class="px-4 divide-y divide-gray-200">
          <div
              v-for="i in 5"
              :key="i"
              class="py-4 ">
            <div class="mr-2 w-2/3  items-center overflow-hidden truncate">
              <div class="text-lg ">
                <loading-skeleton
                    :max="40"
                    class-type="inline"
                    random-text />
              </div>
              <div class="text-sm text-gray-700">
                <loading-skeleton
                    class-type="inline"
                    text="6 out of 20 remaining" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loadedFirstTime">
        <div v-if="items.length > 0">
          <div
              v-if="items.length > 0"
              class="px-4 divide-y divide-gray-200">
            <div
                v-for="pkg in items"
                class="py-4">
              <client-single-package
                  :client="client"
                  :pkg="pkg" />
            </div>
          </div>
        </div>
        <div v-if="items.length === 0">
          <div class="py-16 px-4 w-full h-full text-center">
            <div class="text-xl text-bold text-gray-600">
              Nothing to show!
            </div>
            <p
                v-if="status === 'All'"
                class="text-gray-600">
              {{ client.first_name }} has not purchased any package
              yet!
            </p>
            <p
                v-if="status !== 'All'"
                class="text-gray-600">
              {{ client.first_name }} does not have any package with the
              selected filter!
            </p>
          </div>
        </div>
        <div
            v-if="loadedFirstTime && paginatorInfo.lastPage > 1"
            class="w-full pt-4 pb-4 text-right bg-gray-100 border-t border-gray-200 px-4 rounded-b-lg">
          <pagination
              v-if="paginatorInfo.lastPage > 1"
              :pagination="paginatorInfo"
              class="flex items-center justify-between text-gray-700 text-sm"
              @setPage="paginationData.page = $event">
            <div
                v-if="loading"
                class="inline-block w-8 h-8 mr-2 text-gray-400">
              <loader />
            </div>
          </pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
// TOOD: add echo update
// echoUpdate(ev: ReceivedEventInterface) {
//   if (ev.eventType === EventTypes.PackageWasUpdated) {
//     if (ev.data.client_id === this.client.id) {
//       this.$apollo.queries.packages.refresh();
//     }
//   }
// }
import Loader              from "@/components/layout/Loader.vue";
import ClientSinglePackage from "@/components/client/Services/ClientSinglePackage.vue";
import Pagination          from "@/components/layout/Pagination.vue";
import LoadingSkeleton     from "@/components/layout/LoadingSkeleton.vue";
import InlineMutedSelect   from "@/components/layout/InlineMutedSelect.vue";

import {ref, toRef, watch}                                                                     from "vue";
import {QLClient, QLClientPackagesQuery, QLPackageStatus, QLSortOrder, useClientPackagesQuery} from "@/graphql/queries/ql/composables";
import {createPaginationData, paginationWithAlreadyLoaded}                                     from "@/composables/paginationWithAlreadyLoaded";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 10
                                              });

const status = ref<"All" | QLPackageStatus>("All");
const statusOptions = [
  {label: "All", value: "All"},
  {label: "Active", value: QLPackageStatus.ACTIVE},
  {label: "Used", value: QLPackageStatus.USED},
  {label: "Inactive", value: QLPackageStatus.INACTIVE}
];

const client = toRef(props, "client");
watch(status, () => {
  paginationData.page = 1;
});

const productsQ = useClientPackagesQuery(() => {
  let q = {
    client_id: client.value.id.toString(),
    page:      paginationData.page,
    first:     paginationData.perPage,
    status:    status.value === "All" ? null : status.value,
    orderBy:   [
      {
        column: "active_at",
        order:  QLSortOrder.DESC
      }
    ]
  };
  if (status.value !== "All") {
    q.status = status.value;
  }
  return q;
});

const {
  paginatorInfo,
  loadedFirstTime,
  results: items,
  loading
} = paginationWithAlreadyLoaded<QLClientPackagesQuery["packages"]["data"]>(productsQ);

</script>
