<template>
  <iframe
      ref="myframe"
      class="w-full h-full"
      frameborder="0"
      scrolling="auto"
      @load="iframeLoaded"
  />
</template>
<script lang="ts" setup>
interface Props {
  html: string;
}

const props = withDefaults(defineProps<Props>(), {});

import {nextTick, onMounted, ref, toRef, watch} from "vue";

const emit = defineEmits([]);

const myframe = ref(null);
const iframeHasLoaded = ref(false);

const html = toRef(props, "html");

const resize = () => {
  // // stackoverflow.com/questions/1145850/
  if (myframe.value.contentDocument.body.scrollHeight === 0) {
    return;
  }
  myframe.value.style.height = (myframe.value.contentDocument.body.scrollHeight + 100) + "px";
};

const updateHtml = () => {
  if (myframe.value) {
    let doc = myframe.value.contentWindow.document;
    doc.open();
    doc.write(html.value);
    doc.close();
    resize();
  }
};

const iframeLoaded = async () => {
  updateHtml();
  await nextTick();

  iframeHasLoaded.value = true;
};

onMounted(() => {
  updateHtml();
});

watch(html, async () => {
  await nextTick();
  updateHtml();
});

</script>
