<template>
  <copy-dialog
      v-model:open="openModel"
      v-model:selected-team="selectedTeam"
      :sending="sending"
      :success="success"
      :teams="teams"
      @clicked="sendNow">
    <template #title>
      Copy this email template to:
    </template>
    <template #description>
      Select a studio where you'd like to copy this audience email template. You may only copy this email template to a location where you have manager, admin or owner access.
    </template>
    <template #success>
      Email template successfully copied!
    </template>
  </copy-dialog>
</template>
<script lang="ts" setup>
import {
  QLEmailTemplate, QLFragmentTeamWithOwnerFragment, QLSegment, useCopyEmailTemplateMutation, useCopySegmentMutation
} from "@/graphql/queries/ql/composables";
import {useAttrs, useSlots}                                                 from "vue";
import copyDialog                                                           from "@/composables/copyDialog";
import CopyDialog                                                           from "@/components/layout/CopyDialog.vue";

interface Props {
  emailTemplate: QLEmailTemplate;
  teams: QLFragmentTeamWithOwnerFragment[];
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open"]);

const mut = useCopyEmailTemplateMutation({});

const {sending, success, openModel, sendNow, selectedTeam} = copyDialog(props.emailTemplate.id, mut, props, emit);
</script>
