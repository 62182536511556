import Operator, {FilterExportedData} from "./operator";
import {OperatorTypes}                from "hm-filters/operators/OperatorTypes";
import Filter                         from "hm-filters/base-filter";

export interface MboIndexPossibleValue {
	id: number | string;
	name: string;
}

export default class MboClientIndexOperator extends Operator {
	hasInput = true;

	get type(): string {
		return OperatorTypes.MboClientIndex;
	}

	get caresAboutField(): boolean {
		return false;
	}

	getFormattedOutput(options: { value: any, field: any }) {
		return options.field;
	}

	parsesValueBeforeExport(myvalue: MboIndexPossibleValue) {
		return {
			field:    myvalue.name,
			operator: this.value,
			value:    myvalue.id
		};
	}

	transformApiOptionsToValue(options: FilterExportedData) {
		return options;
	}


	exportObject(filter: Filter, myvalue: FilterExportedData): FilterExportedData {
		return {
			field:    myvalue.field,
			operator: this.value,
			value:    myvalue.value
		};
	}
}
