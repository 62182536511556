<template>
  <confirm-modal
      v-model:open="openModel"
      :loading="sending"
      type="warning"
      close-text="Nevermind"
      :title="title"
      @close="openModel = false"
      @clicked="performAction">
    <template #button>
      {{ confirmButton }}
    </template>
    <template #text>
      <p
          v-if="automationStatus === 'pause'"
          class="text-sm">
        This automation is currently <strong>active</strong>. Are
        you sure you'd like to pause this
        automation?
      </p>
      <p
          v-if="automationStatus === 'activate'"
          class="text-sm">
        This automation is currently <strong>paused</strong>. Would you like to activate this
        automation now?
      </p>
      <div
          v-if="automationStatus === 'activate-now'"
          class="text-sm">
        <p class="text-sm">
          This automation will activate immediately instead of your original activation time <strong>
          <timestamp-to-date
              :timestamp="automation.start_at"
              has-date
              has-time />
        </strong>.
        </p>
        <p class="text-sm">
          Are you sure you'd like to activate this automation immediately?
        </p>
      </div>
      <p
          v-if="automationStatus === 'run-again'"
          class="text-sm">
        The automation will run again for clients that did not match your segment during previous
        activation(s).
      </p>
      <p
          v-if="automationStatus === 'run'"
          class="text-sm">
        Your automation is currently inactive. Would you like to run this automation now?
      </p>
    </template>
  </confirm-modal>
</template>
<script lang="ts" setup>

import {QLFragmentFullAutomationFragment, QLToggleAutomationActiveMutationVariables, useToggleAutomationActiveMutation} from "@/graphql/queries/ql/composables";
import {computed, ref, useAttrs, useSlots}                                                                              from "vue";
import $toast                                                                                                           from "@/composables/toast";
import TimestampToDate                                                                                                  from "@/components/layout/TimestampToDate.vue";
import ConfirmModal                                                                                                     from "@/components/layout/ConfirmModal.vue";
import AutomationDecorator                                                                                              from "@/composables/decorators/AutomationDecorator";


interface Props {
  automation: QLFragmentFullAutomationFragment;
  open: boolean;
}

const props = defineProps<Props>();

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open"]);

const sending = ref(false);
const openModel = computed({
                             get: () => props.open,
                             set: (val) => {
                               emit("update:open", val);
                             }
                           });

const decorated = AutomationDecorator(props.automation);

const automationStatus = computed(() => {
  if (props.automation.active) {
    return "pause";
  }
  if (!props.automation.active && !props.automation.one_off && !decorated.computed.isDelayed) {
    return "activate";
  }
  if (!props.automation.active && decorated.computed.isDelayed) {
    return "activate-now";
  }

  if (props.automation.one_off && !props.automation.active && !decorated.computed.isDelayed && props.automation.ever_ran) {
    return "run-again";
  }

  if (props.automation.one_off && !props.automation.active && !decorated.computed.isDelayed && !props.automation.ever_ran) {
    return "run";
  }
  return "";
});

const title = computed(() => {
  if (automationStatus.value === "pause") {
    return "Pause this automation?";
  }
  if (automationStatus.value === "activate") {
    return "Activate?";
  }
  if (automationStatus.value === "activate-now") {
    return "Activate now?";
  }

  if (automationStatus.value === "run-again") {
    return "Run again?";
  }

  if (automationStatus.value === "run") {
    return "Run?";
  }
  return "";
});

const confirmButton = computed(() => {
  if (props.automation.active) {
    return "Yes, pause it";
  }

  if (!props.automation.one_off) {
    if (!decorated.computed.isDelayed) {
      return "Yes, activate it!";
    } else {
      return "Yes, activate it now!";
    }

  } else {
    // one off
    if (decorated.computed.isDelayed) {
      return "Yes, run it now!";
    } else {
      if (props.automation.ever_ran) {
        return "Yes, run it again";
      } else {
        return "Yes, run now!";
      }
    }
  }
});


const closeIt = () => {
  sending.value = false;
  emit("update:open", false);
};

// Mutation
const mut = useToggleAutomationActiveMutation({});
const mutationVariables: QLToggleAutomationActiveMutationVariables = {
  active: !props.automation.active,
  id:     props.automation.id
};

mut.onDone(() => {
  $toast({
           type:         "success",
           message:      mutationVariables.active
                         ? "The automation is now active"
                         : "Automation is now paused and will remain inactive until manual reactivation.",
           linkRouteObj: null,
           linkText:     null
         });

  closeIt();
});

mut.onError(() => {
  $toast({
           type:         "error",
           message:      "There was an error while changing the status of the automation. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
  sending.value = false;
});

const performAction = () => {
  mutationVariables.active = !props.automation.active;

  sending.value = true;
  mut.mutate(mutationVariables);
};

</script>
