import {ApolloClient, ApolloLink, HttpLink, HttpOptions, InMemoryCache} from "@apollo/client/core";
import {BatchHttpLink}                                                  from "@apollo/client/link/batch-http";
import AuthorizationError                                               from "./Errors/AuthorizationError";
import UnauthenticatedError                                             from "hm-graphql/Errors/UnauthenticatedError";
import {createUploadLink}                                               from "apollo-upload-client";

import {LocalResolvers} from "@/graphql/schema/resolvers";
// import "unfetch/polyfill";
import fetch            from "unfetch";

// if (!process || process.env.NODE_ENV !== "test") {
// Vue.use(VueApollo);
// }

export interface ApolloProviderSetup {
	url(): string;

	headers(): any;

	unauthorizedErrorAction(error: AuthorizationError): void;

	unauthenticatedErrorAction(error: UnauthenticatedError): void;
}

// const authLink = setContext((_, {headers}) => {
// 	return {
// 		headers: {
// 			...headers
// 		}
// 	};
// });

const linkOptions: HttpOptions = {
	uri: import.meta.env.VITE_APP_MAIN_URL + "/graphql",
	// uri:         "https://loopspark.com/graphql",
	credentials: "include",
	fetch:       fetch as any
};

const batchLink = new BatchHttpLink(linkOptions);

const singleLink = new HttpLink(linkOptions);

const uploadLink = createUploadLink(linkOptions);

// https://dev.to/marvinrabe/file-upload-with-vue-apollo-client-and-graphql-5emb
// const regularLink = ApolloLink.split(operation => operation.getContext().singleRequest, singleLink, batchLink);
const regularLink = ApolloLink.split(operation => operation.getContext().singleRequest, singleLink, singleLink);
// @ts-ignore
const httpLink = ApolloLink.split(operation => operation.getContext().hasUpload, uploadLink, regularLink);

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
export const apolloClient = new ApolloClient({
	                                             // link:              onErrorLink.concat(httpLink),
	                                             // link: authLink.concat(httpLink),
	                                             link: httpLink,
	                                             // typeDefs,
	                                             resolvers:         LocalResolvers,
	                                             cache,
	                                             connectToDevTools: true,
	                                             defaultOptions:    {
		                                             watchQuery: {
			                                             fetchPolicy: "cache-and-network"
		                                             }
	                                             }
                                             });
//
// export const apolloProvider =  new createApolloProvider({
// 	                                            defaultClient: apolloClient,
//
// 	                                            defaultOptions: {
// 		                                            $query: {
// 			                                            fetchPolicy: "cache-and-network"
// 		                                            }
// 	                                            },
//
// 	                                            errorHandler(error) {
// 		                                            const err = QlErrorParser.returnError(error);
// 		                                            if (err instanceof AuthorizationError) {
// 			                                            Bus.webNotification({
// 				                                                                linkRouteObj: {},
// 				                                                                linkText:     null,
// 				                                                                message:      "You are not authorized for this operation!",
// 				                                                                sticky:       false,
// 				                                                                type:         "error"
//
// 			                                                                });
// 		                                            }
// 		                                            if (err instanceof UnauthenticatedError) {
// 			                                            window.location.replace(import.meta.env.VITE_APP_MAIN_URL + "/login");
// 			                                            return;
// 		                                            }
// 		                                            return err;
// 	                                            }
//                                             });

