<template>
  <filter-group-base-category
      v-model:filter-selected="filterSelected"
      v-model:operator-selected="operatorSelected"
      :category-name="categoryName"
      @step-back="stepBack"
      @added-rule="addedRule($event)"
  >
    <template #title>
      New Filter - Package
    </template>
    <template #subtitle>
      The package filter category contains filters about the a clients packages. These filters
      will almost always be used in rules containing other package filters.
    </template>
    <template #side-description>
      <div>
        <p>
          You will almost <strong>ALWAYS</strong> want to add the Package Active filter to any rule related to
          packages. Rules containing Package filters can be really complex, please read over the example rules below for
          more information.
        </p>
        <div class="pt-4">
          <p>
            Examples:
          </p>

          <ol class="filter-side-examples pt-2">
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Package Status = Active</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that have at least one active
                package.
              </div>
            </li>
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Package Status = Active</span> +
                <span class="fake-filter">Package Purchased</span>
              </div>
              <div class="filter-side-examples-info">
                Show all clients that have a specific package that is active.
              </div>
            </li>
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Package Purchased</span> +
                <span class="fake-filter">Package Purchase Date</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that purchased a specific package
                within a defined timeframe.
              </div>
            </li>

            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Package Purchased</span> +
                <span class="fake-filter">Package Start Date</span> +
                <span class="fake-filter">Package Status = active</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that have a specific active
                package that started on a specific date.
              </div>
            </li>
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Package Purchased</span> +
                <span class="fake-filter">Package Expiration Date</span> +
                <span class="fake-filter">Package Status = active</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that have a specific package that
                is currently active, and expiring in a defined
                timeframe.
              </div>
            </li>
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Package Purchased</span> +
                <span class="fake-filter">Package Expiration Date</span> +
                <span class="fake-filter">Package Status = Inactive</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that have a specific package
                that is not longer active, and expired in a defined
                timeframe.
              </div>
            </li>
          </ol>
        </div>
      </div>
    </template>
    <template #filters>
      <slot />
    </template>
  </filter-group-base-category>
</template>
<script lang="ts">
import {singleCategoryComposition} from "@/components/Segment/Edit/Categories/SingleCategories/singleCategoryComposition";

const cat = singleCategoryComposition();
export default {
  components: {
    ...cat.components
  },
  props:      {
    ...cat.props
  },
  emits:      cat.emits,
  setup(props, {emit}) {
    return cat.setup(props, emit);
  }
};
</script>
