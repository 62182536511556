<template>
  <div class="w-full min-w-full relative">
    <div ref="chartdiv" class="flowchart w-full h-96 min-h-full"></div>
  </div>
</template>
<script lang="ts" setup>
import * as am4charts                                                      from "@amcharts/amcharts4/charts";
import * as am4core                                                        from "@amcharts/amcharts4/core";
import {am4themes_Loopspark_DeliveryFunnel}                                from "@/components/layout/Charts/Am4Theme";
import {computed, nextTick, onBeforeUnmount, onMounted, ref, toRef, watch} from "vue";
import {QLAutomationStatsEmailOrSms}                                       from "@/graphql/queries/ql/composables";


interface Props {
  stats: QLAutomationStatsEmailOrSms;
}

const props = withDefaults(defineProps<Props>(), {});

const chartdiv = ref(null);

let chart = null;

const stats = toRef(props, "stats");


const generatedData = computed(() => {
  return [
    {name: "Emails Sent", value: stats.value.sent},
    {name: "Delivered", value: stats.value.delivered},
    {name: "Opened", value: stats.value.opened},
    {name: "Clicked", value: stats.value.clicked}
  ];
});
onMounted(() => {
  nextTick(() => {
    am4core.unuseAllThemes();
    am4core.useTheme(am4themes_Loopspark_DeliveryFunnel);
    am4core.options.commercialLicense = true;
    chart = am4core.create((chartdiv.value as HTMLElement), am4charts.SlicedChart);
    chart.responsive.enabled = true;
    chart.responsive.useDefault = true;

    chart.data = generatedData.value;

    let pieSeries = chart.series.push(new am4charts.FunnelSeries());
    // pieSeries.orientation = "horizontal";
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "name";
    pieSeries.alignLabels = true;
    pieSeries.bottomRatio = 1;

    pieSeries.labelsOpposite = false;
    pieSeries.labels.template.text = "{category}: [bold]{value}[/]";
    pieSeries.slices.template.tooltipText = "{category}: {value.value}";
  });

});

watch(generatedData, () => {
  chart.data = generatedData.value;
});

onBeforeUnmount(() => {
  if (chart) {
    chart.dispose();
  }
});

</script>
