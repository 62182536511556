<template>
  <dashboard-stat-item
      title="Open discussions"
      icon="chats-circle-duotone"
      :route-name="routes.discussions"
      link-name="View all discussions"
      :loading="loading"
      :value="statusCount" />
</template>
<script lang="ts" setup>
import DashboardStatItem from "@/components/dashboard/DashboardStatItem.vue";
import {useStatusQuery}  from "@/graphql/queries/ql/composables";
import {useResult}       from "@vue/apollo-composable";
import routesList        from "@/composables/routesList";

const {result, loading} = useStatusQuery();
const statusCount = useResult(result, 0, data => data.status.discussions.open);
const routes = routesList().routeList;

</script>
