<template>
  <page-title
      section="Settings"
      title="Zapier Integration" />
  <div class="container mx-auto grow space-y-16">

    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Zapier Integration
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <div class="space-y-4 ">
            <p>
              API Keys allow your LoopSpark account to send and receive information from other 3rd party applications
              you choose in Zapier. Generate an API key to authorize your LoopSpark account to send information to
              connected Zapier applications, or push information from connected Zapier integrations into your LoopSpark
              account.
            </p>
            <p>
              You can delete an API key anytime to de-authorize the connection between your LoopSpark account and
              specific Zapier integration.
            </p>

          </div>
        </div>
      </div>
      <div class="flex-1 block space-y-6">
        <div
            v-if="loading"
            class="p-4 w-full h-40 flex items-center justify-center">
          <loader class="w-16 h-16 text-gray-500 animate-loading" />
        </div>

        <div v-if="!loading && tokens.length >0">
          <div class="text-lg font-medium text-gray-900">
            Your active API Keys
          </div>
          <div class="mt-4 flex flex-col">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300 table-auto">
                    <thead class="bg-gray-50">
                    <tr>
                      <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-4 w-full"
                          scope="col">
                        Name
                      </th>
                      <th class="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-4 " scope="col">
                        Creation Date
                      </th>
                      <th class="relative py-3.5 pl-3 pr-4 sm:pr-6" scope="col">
                        <span class="sr-only">Delete</span>
                      </th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-for="token in tokens" :key="token.id"
                        class="border-b border-gray-200">
                      <td class="py-4 pl-4 pr-3 text-sm sm:pl-4">
                        <div class="font-medium text-gray-900">{{ token.name }}</div>
                      </td>

                      <td class="py-4 pl-3 pr-4 text-right text-sm text-gray-700 sm:pr-4 whitespace-nowrap">
                        <div>
                          <timestamp-to-date
                              :timestamp="token.created_at"
                              has-day-name
                              natural-date
                              studio />

                        </div>
                        <div class="text-gray-500 text-xs">
                          <time-ago :timestamp="token.created_at" />
                        </div>
                      </td>
                      <td class=" py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <zapier-delete-token :token="token" @deleted="deletedOne(token)" />
                      </td>
                    </tr>
                    <!-- More people... -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!loading" class="shadow-md rounded-lg bg-white p-4">
          <h2 class="mb-4 text-gray-800">
            Create a new Zapier API Key
            <span class="block text-sm pt-2 text-gray-500">Provide a name that is easy to remember and describes the purpose of this API Key (example - Zapier Facebook Lead Ads integration). The API name will only be visible to you and your staff in LoopSpark.</span>
          </h2>

          <form
              v-if="!justCreated"
              class="space-y-4"
              @submit.prevent="submitForm">
            <text-input
                v-model="newData.name"
                :error-message="errors.get('name')"
                label="Name"
                required
                small-info="Provide an easy name to identify the use of this API Key. This name is visible only to you and your staff." />

            <v-button
                :disabled="sending"
                :loading="sending"
                class="btn btn-green"
                type="submit">
              Create!
            </v-button>
          </form>
          <div v-if="justCreated">
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="rounded-md bg-green-50 p-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <!-- Heroicon name: mini/check-circle -->
                  <svg aria-hidden="true"
                       class="h-5 w-5 text-green-400"
                       fill="currentColor"
                       viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          fill-rule="evenodd" />
                  </svg>
                </div>
                <div class="ml-3 mr-3 grow">
                  <h3 class="text-md font-medium text-green-800">API Key created!</h3>
                  <div class="mt-2 text-md text-green-700">
                    <p> Your API Key named <strong>{{ justCreated.name }}</strong> was created. Be sure to copy and
                        store
                        the API Key
                        in a secure location. </p>

                    <p class="font-semibold">After you leave the page, you no longer have access to the API Key.</p>

                    <div class="mt-8 w-full">
                      <div class="p-4 border border-gray-500 bg-gray-100 rounded w-full text-black font-mono text-lg whitespace-pre">
                        {{ justCreated.plain_token }}
                      </div>
                    </div>
                  </div>
                  <div class="mt-4">
                    <div class="-mx-2 -my-1.5 flex">
                      <button class="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                              type="button" @click="justCreated = null">
                        Create another API Key
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import PageTitle                                     from "@/components/navbar/PageTitle.vue";
import {computed, reactive, ref, useAttrs, useSlots} from "vue";
import {
  QLAccessToken, QLZapierTokensQuery, useCreateZapierTokenMutation, useZapierTokensQuery, ZapierTokensDocument
}                                                    from "@/graphql/queries/ql/composables";
import Loader                                        from "@/components/layout/Loader.vue";
import TextInput                                     from "@/components/layout/Forms/TextInput.vue";
import VButton                                       from "@/components/layout/VButton.vue";
import SparkFormErrors, {SparkErrors}                from "@/classes/utils/SparkFormErrors";
import $toast                                        from "@/composables/toast";
import fillValidationErrorsOrToast                   from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import TimeAgo                                       from "@/components/layout/TimeAgo.vue";
import TimestampToDate                               from "@/components/layout/TimestampToDate.vue";
import ZapierDeleteToken
                                                     from "@/views/Studio/Settings/Components/Zapier/ZapierDeleteToken.vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const zapierTokensQuery = useZapierTokensQuery();
const {loading} = zapierTokensQuery;

const tokens = computed(() => zapierTokensQuery.result.value?.zapier_tokens ?? []);

const newData = reactive({
                           name: ""
                         });

const sending = ref(false);

const errorsData = ref<SparkErrors>({});
const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

const createZapierTokenMutation = useCreateZapierTokenMutation({});

createZapierTokenMutation.onDone(() => {
  sending.value = false;
  $toast({
           type:         "success",
           message:      "Created!",
           linkRouteObj: null,
           linkText:     null
         });
});

createZapierTokenMutation.onError((err) => {
  sending.value = false;
  fillValidationErrorsOrToast(err,
                              errorsData,
                              "There was an error while creating the Zapier API Key. Please try again");
});

const justCreated = ref<QLAccessToken | null>(null);

const submitForm = () => {
  sending.value = true;
  createZapierTokenMutation.mutate({
                                     name: newData.name
                                   }, {
                                     update: (cache, {data}) => {
                                       const otherData: QLZapierTokensQuery = cache.readQuery({query: ZapierTokensDocument});
                                       if (otherData) {
                                         const d = Object.assign([], otherData.zapier_tokens);
                                         d.push(data.CreateZapierToken);
                                         cache.writeQuery({query: ZapierTokensDocument, data: {zapier_tokens: d}});
                                       }
                                       justCreated.value = data.CreateZapierToken;
                                     }
                                   });
};

const deletedOne = (token) => {
  console.log(token);
};

</script>
