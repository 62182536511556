<template>
  <div
class="w-full p-4 border-b-2 "
:class="{'s-banner-lightyellow': color === 'lightyellow',
's-banner-orange': color === 'orange',
's-banner-green': color === 'green',
's-banner-red': color === 'red'}">
    <div class="container mx-auto">
      <slot />
    </div>
  </div>
</template>
<style>
.s-banner-lightyellow {
  @apply bg-lightyellow-300 border-lightyellow-300 text-lightyellow-900 font-medium;
}

.s-banner-orange {
  @apply bg-orange-600 border-orange-300 text-white font-semibold;
}

.s-banner-green {
  @apply bg-green-600 border-green-300 text-white font-semibold;
}

.s-banner-red {
  @apply bg-red-600 border-red-300 text-red-50 text-white font-semibold;
}
</style>
<script lang="ts" setup>
interface Props {
  color:
      "lightyellow" |
      "orange" |
      "green" |
      "red";
}

const props = withDefaults(defineProps<Props>(), {});

import {toRef, useAttrs, useSlots} from "vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const color = toRef(props, "color");
</script>
