import {QLSegment}   from "@/graphql/queries/ql/composables";
import {FilterGroup} from "@/classes/entities/FilterGroup";

export class Segment {
	static createEmptyCustomSegment(withActive = true) {
		const segment: QLSegment = {
			name:                      "Custom segment",
			id:                        "0",
			filters:                   [],
			visible_dashboard:         false,
			only_deliverable_by_email: false,
			only_deliverable_by_sms:   false,
			archived:                  false
		};

		if (withActive) {
			segment.filters.push(FilterGroup.createEmptyWithActiveClients());
		}

		return segment;

	}
}
