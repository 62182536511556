import {FormCountFilter}  from "hm-filters/filters/Forms/FormCountFilter";
import FormHistoryFilter  from "hm-filters/filters/Forms/FormHistoryFilter";
import FormNameFilter     from "hm-filters/filters/Forms/FormNameFilter";
import FormCategoryFilter from "hm-filters/filters/Forms/FormCategoryFilter";

export const FormFilters = [
	new FormCountFilter("Count", {"field": "count"}).setDescription(
		"How many times a client has completed forms that match the rest of your form rule filters."),
	new FormHistoryFilter("History", {"field": "history"}).setDateFilter().setDescription(
		"Timeframe of when client completed forms that match the rest of your form rule filters."),
	new FormNameFilter("Form Name", {"field": "name"}).setDescription(
		"Select the form name and use other form filters to narrow down completion count and history."),
	new FormCategoryFilter("Category", {"field": "category"}).setDescription(
		"Select the from category name and use other form filters to narrow down completion count and history.")
];
