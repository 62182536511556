import Filter from "../../base-filter";

import config           from "./config";
import LocationOperator from "hm-filters/operators/LocationOperator";


class VisitLocation extends Filter {
	smallText = config.visits.groups.visit.group;
	group = config.visits.groups.visit.group;
	path = ["Visit", this.group];

	constructor(name: string, options: any) {
		super(config.visits.location, options);
		this.operators = [
			new LocationOperator("=", "is"),
			new LocationOperator("!=", "is not")
		];

	}

	get type(): string {
		return "VisitLocation";
	}
}

class LateCancelLocation extends VisitLocation {
	smallText = config.visits.groups.lateCancelled.group;
	group = config.visits.groups.lateCancelled.group;
	path = ["Visit", this.group];

	get type(): string {
		return "LateCancelLocation";
	}
}

class AbsentLocation extends VisitLocation {
	smallText = config.visits.groups.absent.group;
	group = config.visits.groups.absent.group;
	path = ["Visit", this.group];

	get type(): string {
		return "AbsentLocation";
	}
}

class SignedUpLocation extends VisitLocation {
	smallText = config.visits.groups.signedUp.group;
	group = config.visits.groups.signedUp.group;
	path = ["Visit", this.group];

	get type(): string {
		return "SignedUpLocation";
	}
}


class UpcomingVisitLocation extends VisitLocation {
	smallText = config.visits.groups.upcomingVisit.group;
	group = config.visits.groups.upcomingVisit.group;
	path = ["Visit", this.group];

	get type(): string {
		return "UpcomingVisitLocation";
	}
}

class EarlyCancelLocation extends VisitLocation {
	smallText = config.visits.groups.early_cancel.group;
	group = config.visits.groups.early_cancel.group;
	path = ["Visit", this.group];

	get type(): string {
		return "EarlyCancelLocation";
	}
}

export {
	VisitLocation,
	LateCancelLocation,
	SignedUpLocation,
	UpcomingVisitLocation,
	AbsentLocation,
	EarlyCancelLocation
};
