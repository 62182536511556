<template>
  <div>
    <div class="form-input bg-white form-input-parent">
      <input type="time" class="appearance-none border-0 p-0 m-0 min-h-full min-w-full form-no-focus" v-model="dataString">
    </div>
  </div>
</template>
<style scoped>
input {
  font-size:inherit;
  line-height:inherit;
}
</style>
<script lang="ts" setup>

import {DateTime}                     from "luxon";
import {computed, useAttrs, useSlots} from "vue";

interface Props {
  modelValue: number | null;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:modelValue"]);

const dataString = computed(
    {
      get: () => {
        const inDate = DateTime.now().startOf("day").plus({minutes: props.modelValue === null ? 0 : props.modelValue});
        return inDate.toFormat("HH:mm");
      },
      set: (newStr) => {
        let finalMinute = 0;
        if (newStr === null || newStr.length === 0) {
          finalMinute = 0;
        } else {

          const newDate = DateTime.fromFormat(newStr, "HH:mm").set({second: 0});
          finalMinute = newDate.minute + (newDate.hour * 60);
        }

        emit("update:modelValue", finalMinute);
      }
    }
);

</script>
