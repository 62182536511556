<template>
  <div>
    <div class="block shadow-md rounded-lg bg-white">
      <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Excluded events
        </h3>
      </div>

      <div class="bg-white rounded-t-lg ">
        <div
            v-if="loadingExcluded"
            class="animate-loading">
          <ul class="mt-2 divide-y px-4">
            <li
                v-for="i in 10"
                :key="i"
                class="py-2 text-sm hover:bg-gray-50">
              <div class="flex">
                <div class="grow">
                  <loading-skeleton random-text />
                </div>
                <div>
                  <div class="loading-bg w-4 h-4 mr-1 rounded" />
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div v-if="!loadingExcluded">
          <div
              v-if="excludedEvents.length === 0"
              class="p-4 w-full h-32 flex items-center justify-center text-gray-600 text-sm">
            You don't have any excluded event yet.
          </div>
          <ul
              v-if="excludedEvents.length > 0"
              class="divide-y">
            <li
                v-for="excluded_event_name in excludedEvents"
                :key="excluded_event_name.id"
                class="py-4 text-sm hover:bg-gray-50 sm:px-6">
              <div class="flex items-center">
                <div class="grow text-gray-900">
                  {{ excluded_event_name.name }}
                </div>
                <div class="">
                  <club-count-manager-delete-button
                      :excluded-event="excluded_event_name"
                      @deleted="deleted(excluded_event_name.id)" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="bg-gray-100 rounded-b-lg border-t">
        <form
            ref="form"
            class="flex p-4 py-3 space-x-4 items-center"
            @submit.prevent="addEvent">
          <div class="flex-1">
            <smart-select
                v-model="eventToSend"
                :items="itemsToExclude"
                empty-on-open
                class=""
                placeholder="Choose an event to exclude..." />
          </div>
          <simple-button
              :loading="sending"
              :disabled="eventToSend.length ===0"
              type="submit"
              class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Add
          </simple-button>
        </form>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import SimpleButton from "@/components/layout/SimpleButton.vue";

import ClubCountManagerDeleteButton from "@/components/studio/settings/ClubCountManager/ClubCountManagerDeleteButton.vue";
import SmartSelect                  from "@/components/layout/Forms/SmartSelect.vue";
import LoadingSkeleton              from "@/components/layout/LoadingSkeleton.vue";

import {computed, ref}                                                                                                                             from "vue";
import {ExcludedEventNamesDocument, QLExcludedEventNamesQuery, useCreateExcludedEventNameMutation, useEventNamesQuery, useExcludedEventNamesQuery} from "@/graphql/queries/ql/composables";
import {useApolloClient}                                                                                                                           from "@vue/apollo-composable";
import $toast                                                                                                                                      from "@/composables/toast";

const {resolveClient} = useApolloClient();
const emit = defineEmits([]);

const eventNamesQuery = useEventNamesQuery();
const excludedEventNamesQuery = useExcludedEventNamesQuery();

const deletedEvents = ref([]);
const sending = ref(false);
const eventToSend = ref("");

const form = ref<HTMLFormElement>(null);

const {loading: loadingExcluded} = excludedEventNamesQuery;

const eventNames = computed(() => eventNamesQuery.result.value?.event_names ?? []);
const excludedEvents = computed(() => excludedEventNamesQuery.result.value?.excluded_event_names ?? []);

const itemsToExclude = computed(() => {
  if (eventNames.value.length === 0) {
    return [];
  }
  return eventNames.value.map((item) => item.name).filter((name) => {
    return !excludedEvents.value.find((excluded) => {
      return excluded.name == name;
    });
  });
});

const deleted = (id: string) => {
  const client = resolveClient();
  const cached: QLExcludedEventNamesQuery = client.cache.readQuery({query: ExcludedEventNamesDocument});

  client.cache.writeQuery({
                            query: ExcludedEventNamesDocument, data: {
      excluded_event_names: cached.excluded_event_names.filter((i) => {
        return i.id != id;
      })
    }
                          });

};

const addEventMutation = useCreateExcludedEventNameMutation({});

addEventMutation.onDone(() => {
  sending.value = false;
  eventToSend.value = "";
});

addEventMutation.onError((e) => {
  sending.value = false;
  $toast({
           type:         "error",
           message:      "Error adding the excluded event. Please try again!",
           linkRouteObj: null,
           linkText:     null
         });
});

const addEvent = () => {
  sending.value = true;
  addEventMutation.mutate({
                            name: eventToSend.value
                          }, {
                            update: (cache, {data}) => {
                              const otherData: QLExcludedEventNamesQuery = cache.readQuery({query: ExcludedEventNamesDocument});
                              const d = Object.assign([], otherData.excluded_event_names);
                              d.push(data.CreateExcludedEventName);
                              cache.writeQuery({query: ExcludedEventNamesDocument, data: {excluded_event_names: d}});
                            }
                          });

};
</script>
