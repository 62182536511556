<template>
  <div></div>
</template>
<script lang="ts">

import {QLUpdateNotificationTokenMutationVariables, useMeQuery, useUpdateNotificationTokenMutation} from "@/graphql/queries/ql/composables";
import {ref}                                                                                        from "vue";
import {firebaseGetToken}                                                                           from "@/classes/boot/firebase";

export default {
  setup() {
    const requestedToken = ref(false);

    const {result, onResult} = useMeQuery();

    const askForToken = async function () {
      if (!requestedToken.value && result.value.me) {
        requestedToken.value = true;

        try {
          const token = await firebaseGetToken();
          if (token) {
            const dataToSend: QLUpdateNotificationTokenMutationVariables = {
              token:  token,
              device: "browser",
              serial: window.navigator.userAgent
            };
            useUpdateNotificationTokenMutation({variables: dataToSend});
          }

        } catch (e) {
          console.log("An error occurred while retrieving token. ", e);
        }
      }
    };

    onResult(queryResult => {
      if (!queryResult.loading && !queryResult.errors) {
        askForToken();
      }
    });

    return {};
  }
};

</script>
