<template>
  <div>
    <form class="space-y-4" @submit.prevent="submitForm">
      <text-input v-model="inviteEmail" label="New staff member email" required type="email"></text-input>
      <v-button :disabled="sending" :loading="sending" class="btn btn-green" type="submit">Invite</v-button>
    </form>

    <div v-if="currentTeam && currentTeam.id">
      <studio-staff-invite-pending :current-team="currentTeam"></studio-staff-invite-pending>
    </div>

  </div>
</template>
<script lang="ts" setup>

import TextInput                                                                                          from "@/components/layout/Forms/TextInput.vue";
import VButton                                                                                            from "@/components/layout/VButton.vue";
import StudioStaffInvitePending                                                                           from "@/components/studio/settings/Staff/StudioStaffInvitePending.vue";
import {ref}                                                                                              from "vue";
import currentTeamQuery                                                                                   from "@/composables/queries/currentTeamQuery";
import {QLTeamPendingInvitationsQuery, QLUserRoleEnum, TeamPendingInvitationsDocument, useInviteMutation} from "@/graphql/queries/ql/composables";
import $toast                                                                                             from "@/composables/toast";

const {currentTeam} = currentTeamQuery();
const inviteEmail = ref("");
const sending = ref(false);

const inviteMutation = useInviteMutation(
    {
      update: (cache, {data: {invite}}) => {

        const data: QLTeamPendingInvitationsQuery = cache.readQuery(
            {
              query:     TeamPendingInvitationsDocument,
              variables: {
                team_id: currentTeam.value.id
              }
            });
        if (data) {
          const d = [...data.team_pending_invitations].push(invite);
          cache.writeQuery({
                             query:     TeamPendingInvitationsDocument,
                             variables: {
                               team_id: currentTeam.value.id
                             }, data:   {team_pending_invitations: d}
                           });
        }
      }
    });

inviteMutation.onDone(() => {
  $toast({
           type:         "success",
           message:      "Invitation sent!",
           linkRouteObj: null,
           linkText:     null
         });
});
inviteMutation.onError((error) => {
  console.log(error);
  $toast({
           type:         "error",
           message:      "There was an error while inviting the member. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const submitForm = () => {
  sending.value = true;

  inviteMutation.mutate({
                          team_id: currentTeam.value.id,
                          email:   inviteEmail.value,
                          role:    QLUserRoleEnum.MEMBER
                        }).finally(() => sending.value = false);


};

</script>
