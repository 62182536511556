<template>
  <confirm-modal
      v-model:open="openModel"
      :loading="deleting"
      type="warning"
      close-text="No, go back"
      title="Archive this form?"
      @close="openModel = false"
      @clicked="performAction">
    <template #button>
      Yes, archive it!
    </template>
    <template #text>
      <p>
        Archived forms will deactivate and will not be publically visible until they are dearchived. Archive when you are confident
        this form will not be used in the near future and would like to remove the form from your forms
        list.
      </p>
    </template>
  </confirm-modal>
</template>
<script lang="ts">

import ConfirmModal           from "@/components/layout/ConfirmModal.vue";
import archiveUnarchiveDialog from "@/views/Forms/Components/Show/archiveUnarchiveDialog";

const dialog = archiveUnarchiveDialog(true,
                                      "The form has been archived",
                                      "There was an error while archiving. Please try again");

export default {
  components: {
    ConfirmModal
  },
  props:      {
    ...dialog.component.props
  },
  emits:      dialog.component.emits,
  setup(props, {emit}) {
    return dialog.insideSetup(props, emit);
  }

};

</script>
