<template>
  <page-title :sub-text="!loading ? catalogItem.description: null" section="Automations" title="Catalog">
    <template #title>
      <loader
          v-if="loading"
          class="w-10 h-10 text-gray-400" />
      <div v-if="!loading">
        Catalog: {{ catalogItem.name }}
      </div>
    </template>
  </page-title>
  <div class="container mx-auto grow">
    <router-link
        :to="{name: routeList.forms.catalog.index}"
        class="text-link text-sm text-blue-900 inline-flex items-center mb-6">
      <svg-icon
          class="h-4 w-4"
          name="left" />
      <div>Back to the catalog list</div>
    </router-link>
    <div
        v-if="loading"
        class="w-full h-48 flex items-center justify-center">
      <loader class="w-10 h-10 text-gray-400" />
    </div>

    <div
        v-if="!loading"
        class="md:flex md:space-x-8">
      <div class="md:w-1/3 ">
        <div class="bg-white px-6 p-4 rounded-lg shadow prose">
          <div v-html="catalogItem.long_description" />
        </div>
      </div>
      <div class="grow ">
        <h3 class="text-gray-700 text-lg mb-2">
          Your form is almost ready!
        </h3>
        <div class="text-gray-700 text-sm mb-4">
          <p>
            Please fill out the information below and, click "Add to my account" to save the automation in your
            automations list. Your new
            automation will remain inactive until manually activated.
          </p>
        </div>
        <div class="bg-white rounded-lg shadow-xl">
          <div class="p-6 space-y-6">
            <text-input
                v-model="formName"
                data-name
                label="Name"
                placeholder="Example: Liability Waiver"
                required>
              <div class="leading-tight form-small-info text-gray-600">
                Use a name that is easy to understand the purpose of the form (eg - Liability Waiver 2022)
              </div>
            </text-input>

            <div class="flex justify-end">
              <v-button
                  :disabled="!buttonEnabled"
                  class="btn btn-green"
                  @click="openModal = true">
                Add to my account
              </v-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal
        v-model:open="openModal"
        :loading="saving"
        close-text="No, go back"
        title="Add this form?"
        type="warning"
        @clicked="save"
        @close="openModal = false">
      <template #button>
        I undestand. Add it!
      </template>
      <template #text>
        <p>
          This item is a LoopSpark template provided for your marketing convenience. LoopSpark takes no liability or
          responsibility in your personal utilization of this template. We encourage that you consult an attorney for
          any formal waivers and legality regarding your business. By continuing, you attest that your
          business takes on full responsibility for the implementation of this templated item and any alterations made
          once applied to your account. If you have any questions about this template or your intended uses as it
          relates to your specific business, please contact a licensed attorney.
        </p>
      </template>
    </confirm-modal>
  </div>
</template>
<script lang="ts" setup>
import PageTitle       from "@/components/navbar/PageTitle.vue";
import Loader          from "@/components/layout/Loader.vue";
import TextInput       from "@/components/layout/Forms/TextInput.vue";
import VButton         from "@/components/layout/VButton.vue";
import SvgIcon         from "@/components/SvgIcon.vue";
import {
  QLMutationAddCatalogToFormsArgs, useAddCatalogToFormsMutation, useFormCatalogItemQuery
}                      from "@/graphql/queries/ql/composables";
import meQuery         from "@/composables/queries/meQuery";
import {computed, ref} from "vue";
import {
  deepCopy
}                      from "@/classes/helpers/DeepCopy";
import routeList       from "@/router/Routes";
import $toast          from "@/composables/toast";
import routesList      from "@/composables/routesList";
import {useRouter}     from "vue-router";
import ConfirmModal    from "@/components/layout/ConfirmModal.vue";

const router = useRouter();

interface Props {
  id: string;
}

const openModal = ref(false);
const props = withDefaults(defineProps<Props>(), {});

const saving = ref(false);

const formNameInt = ref(null);

const {me} = meQuery();

const getCatalog = useFormCatalogItemQuery({id: props.id});
const {loading} = getCatalog;

const catalogItem = ref(null);

getCatalog.onResult((res) => {
  if (!res.loading) {
    catalogItem.value = deepCopy(res.data.form_catalog_item);
  }
});

const formName = computed({
                            get: () => {
                              if (!catalogItem.value) {
                                return "";
                              }
                              if (formNameInt.value !== null) {
                                return formNameInt.value;
                              }
                              return catalogItem.value.form_name;
                            },
                            set: (val) => formNameInt.value = val
                          });

const canSave = computed(() => {
  return !(!formName.value || formName.value.length === 0);

});

const buttonEnabled = computed(() => {
  return canSave.value && saving.value === false;
});

const mut = useAddCatalogToFormsMutation({});
mut.onError(() => {
  saving.value = false;
  $toast({
           type:         "error",
           message:      "There was an error while creating the form. Please check the information and try again",
           linkRouteObj: null,
           linkText:     null
         });
});

mut.onDone((d) => {
  saving.value = false;
  const finalRoute = {
    name:   routesList().routeList.forms.show.index,
    params: {id: d.data.AddCatalogToForms.id}
  };

  $toast({
           type:    "success",
           message: "The form has been created!"
         });

  router.push(finalRoute);
});

const save = () => {
  saving.value = true;
  const dataToSend: QLMutationAddCatalogToFormsArgs = {
    catalog_id: catalogItem.value.id,
    form_name:  formName.value
  };

  mut.mutate(dataToSend);
};

</script>
