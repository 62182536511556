import ClientShowIndex            from "@/views/ClientShow/ClientShowIndex.vue";
import Routes                     from "@/router/Routes";
import ClientShowInfo             from "@/views/ClientShow/ClientShowInfo.vue";
import ClientShowDiscussions      from "@/views/ClientShow/ClientShowDiscussions.vue";
import ClientShowSingleDiscussion from "@/views/ClientShow/ClientShowSingleDiscussion.vue";
import ClientShowEvents           from "@/views/ClientShow/ClientShowEvents.vue";
import ClientEventsList           from "@/components/client/Events/ClientEventsList.vue";
import ClientShowSales            from "@/views/ClientShow/ClientShowSales.vue";
import ClientShowServices         from "@/views/ClientShow/ClientShowServices.vue";
import ClientShowTodos            from "@/views/ClientShow/ClientShowTodos.vue";
import ClientShowSingleTodo       from "@/views/ClientShow/ClientShowSingleTodo.vue";
import ClientShowNotes            from "@/views/ClientShow/ClientShowNotes.vue";
import ClientShowEngagement       from "@/views/ClientShow/ClientShowEngagement.vue";
import ClientShowForms            from "@/views/ClientShow/ClientShowForms.vue";
import ClientShowSingleForm       from "@/views/ClientShow/ClientShowSingleForm.vue";
import {RouteRecordRaw}           from "vue-router";

export const ClientRoutes : Array<RouteRecordRaw> = [
	{
		path:      "/client/:id",
		component: ClientShowIndex,
		meta:{
			title: "Client"
		},
		props: true,
		children:  [
			{
				path:      "",
				name:      Routes.client.show,
				component: ClientShowInfo
			},
			{
				path:      "discussions",
				name:      Routes.client.discussions.index,
				component: ClientShowDiscussions
			},
			{
				path:      "discussions/:discussionId",
				name:      Routes.client.discussions.show,
				component: ClientShowSingleDiscussion,
				props:     true
			},
			{
				path: "events",
				component: ClientShowEvents,
				children:  [
					{
						path:      "",
						name:      Routes.client.events.index,
						component: ClientEventsList,
						props:     {upcoming: false}
					},
					{
						path:      "upcoming",
						name:      Routes.client.events.upcoming,
						component: ClientEventsList,
						props:     {upcoming: true}
					}
				]
			},
			{
				path:      "sales",
				name:      Routes.client.sales,
				component: ClientShowSales
			},
			{
				path:      "services",
				name:      Routes.client.services,
				component: ClientShowServices
			},
			{
				path:      "todos",
				name:      Routes.client.todos.index,
				component: ClientShowTodos
			},
			{
				path:      "todos/:todoId",
				name:      Routes.client.todos.show,
				component: ClientShowSingleTodo,
				props:     true
			},
			{
				path:      "notes",
				name:      Routes.client.notes.index,
				component: ClientShowNotes
			},
			{
				path:      "engagement",
				name:      Routes.client.engagement,
				component: ClientShowEngagement
			},
			{
				path:      "forms",
				name:      Routes.client.forms.index,
				component: ClientShowForms,
				meta: {
					addonForms: true
				}
			},
			{
				path:      "forms/:formInstanceId",
				name:      Routes.client.forms.show,
				component: ClientShowSingleForm,
				props:     true,
				meta: {
					addonForms: true
				}
			},
		]
	}

];

