import {BookingGroup}     from "hm-filters/filters/partials/BookingGroup";
import FixedValueOperator from "hm-filters/operators/FixedValueOperator";

export class BookingFirstFilter extends BookingGroup {

	constructor(name: string, options: any) {
		super(name, options);

		this.clearOperators();
		this.addOperators([
			                  new FixedValueOperator("attended", "attended").setOperator("="),
			                  new FixedValueOperator("attended", "not attended").setOperator("!="),
			                  new FixedValueOperator("cancel", "cancelled").setOperator("="),
			                  new FixedValueOperator("cancel", "not cancelled").setOperator("!=")
		                  ]);
	}

	get type(): string {
		return "BookingFirst";
	}

}
