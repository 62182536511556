<template>
  <div class="py-8 px-4 lg:py-16 lg:px-16 text-center bg-gray-50 border border-gray-200 rounded-lg ">
    <div class="text-xl text-bold text-gray-700">
      <slot name="title"></slot>
    </div>
    <p class="text-gray-600 pt-2">
      <slot name="subtitle"></slot>
    </p>
  </div>
</template>
<script lang="ts" setup>
</script>
