import Contract                       from "./partials/contract";
import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import NumericOperator                from "hm-filters/operators/NumericOperator";

export class ContractCountFilter extends Contract {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new OperatorAsFixedValueOperator("never", "never before"),
			new NumericOperator(">", "more than", "contracts"),
			new NumericOperator("<", "less than", "contracts"),
			new NumericOperator("=", "exactly", "contracts")
		];
	}

	get type(): string {
		return "ContractCountFilter";
	}
}
