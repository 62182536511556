<template>
  <transition-group class="fixed flex top-0 left-0 right-0 bottom-0 pointer-events-none overflow-hidden p-2 flex-col items-center  space-y-4 mt-2"
                    name="fade"
                    style="z-index: 1050;"
                    tag="div">
    <toast :notification="notification"
           @closed="closed"
           v-bind:key="notification.id"
           v-for="notification in notifications"></toast>
  </transition-group>

</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition:opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity:0;
}
</style>
<script lang="ts" setup>
import Toast                                            from "@/components/layout/Toast/Toast.vue";
import WebNotificationData, {WebNotificationDataWithId} from "@/classes/notifications/WebNotificationData";
import {ref}                                            from "vue";
import Timeout = NodeJS.Timeout;
import {echoListener}                                   from "@/composables/echoListener";
import {AllEventTypes}                                  from "@/classes/notifications/EventTypes";

const notifications = ref([] as Array<WebNotificationData>);
const intervals = ref([] as Array<Timeout>);

const closed = (e: WebNotificationData) => {
  let i = notifications.value.indexOf(e);
  if (i >= 0) {
    notifications.value.splice(i, 1);
  }
};

const listenToNotification = (e: WebNotificationData) => {
  let n: WebNotificationDataWithId = {...e, id: Math.random().toString()};
  notifications.value.push(n);
  if (!n.sticky) {
    intervals.value.push(setTimeout(() => closed(n), 5000));
  }
};

echoListener([AllEventTypes.WebNotification], (ev) => {
  listenToNotification(ev.data);
});

</script>
