import Filter            from "../base-filter";
import DayOfWeekOperator from "hm-filters/operators/DayOfWeekOperator";

class ClassDayOfWeekFilter extends Filter {
	path = ["Visit", "Class"];
	smallText = "Class";
	group = "Class";

	constructor(name: string, options: any) {
		super(name, options);

		this.clearOperators();
		this.addOperators([
			                  new DayOfWeekOperator("=", "is"),
			                  new DayOfWeekOperator("!=", "is not")

		                  ]);
	}

	get type(): string {
		return "ClassDayOfWeekFilter";
	}
}

export default ClassDayOfWeekFilter;
