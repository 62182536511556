<template>
  <side-panel
      v-model:open="openModel"
      :closable="!sending">
    <template #header>
      Create a To-Do for {{ client.first_name }}
    </template>
    <div class="px-4 py-4 h-full">
      <form @submit.prevent="send" ref="form" class="h-full">
        <todo-create-edit-form
            v-model:form-data="formData"
            :errors="errorsData"
            :sending="sending" />
      </form>
    </div>
    <template #footer>
      <div
          v-if="!sending"
          class="flex m-2 justify-between w-full">
        <button
            class="btn btn-white"
            @click="openModel = false">
          Cancel
        </button>
        <button
            class="btn btn-green"
            @click="send">
          Create!
        </button>
      </div>
      <div
          v-if="sending"
          class="flex m-2 justify-center">
        <div class="h-8 w-8">
          <loader />
        </div>
      </div>
    </template>
  </side-panel>
</template>
<script lang="ts" setup>

import SidePanel                                         from "@/components/layout/SidePanel.vue";
import Loader                                            from "@/components/layout/Loader.vue";
import TodoCreateEditInterface                           from "@/components/client/Todos/TodoCreateEditInterface";
import TodoCreateEditForm                                from "@/components/client/Todos/TodoCreateEditForm.vue";
import {computed, ref, toRef, watch}                     from "vue";
import {QLClient, QLTodoPriority, useCreateTodoMutation} from "@/graphql/queries/ql/composables";
import SparkFormErrors                                   from "@/classes/utils/SparkFormErrors";
import fillValidationErrorsOrToast                       from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import $toast                                            from "@/composables/toast";
import routesList                                        from "@/composables/routesList";

interface Props {
  client: QLClient;
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:open", 'created']);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const errorsData = ref({});
const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

const sending = ref(false);

const formData = ref<TodoCreateEditInterface>({
                                                message:        "",
                                                subject:        "",
                                                priority_type:  QLTodoPriority.NONE,
                                                overdue_at:     null,
                                                postponed_to:   null,
                                                assigned_to_id: null,
                                                team_group_id:  null
                                              });

const client = toRef(props, "client");
const form = ref(null);

const resetForm = () => {
  sending.value = false;
  errorsData.value = {};
  formData.value = {
    message:        "",
    subject:        "",
    priority_type:  QLTodoPriority.NONE,
    overdue_at:     null,
    postponed_to:   null,
    assigned_to_id: null,
    team_group_id:  null
  };
};

watch(client, () => {
  resetForm();
});

const mutation = useCreateTodoMutation({});
mutation.onError((err) => {
  fillValidationErrorsOrToast(err, errorsData, "Error creating the todo. Please try again!");
  sending.value = false;
});

mutation.onDone((res) => {
  $toast({
           type:     "success",
           message:  "To-Do created!",
           linkText: "View it now",
           linkRouteObj:
                     {
                       name:   routesList().routeList.client.todos.show,
                       params: {
                         id:      client.value.id,
                         todoId: res.data.CreateTodo.id
                       }
                     }
         });
  sending.value = false;
  openModel.value = false;
  emit('created');
});

const send = () => {
  errorsData.value = {};
  if (!form.value.reportValidity()) {
    return;
  }
  sending.value = true;
  mutation.mutate({
                    client_id: client.value.id,
                    ...formData.value
                  });

};

</script>
