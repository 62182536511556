<template>
  <div class="">
    <div v-if="loadedFirstTime && visitsList.length === 0">
      <empty>
        <template #title>Nothing to show!</template>
        <template #subtitle>
          {{ client.first_name }} does not have any
          <span v-text="upcoming ? 'upcoming':'past' " /> event
        </template>
      </empty>
    </div>
    <div class="mt-2 bg-white shadow-md rounded-lg overflow-hidden">
      <div
          v-if="!loadedFirstTime"
          class="px-4 divide-y divide-gray-200">
        <client-event-single-loading
            v-for="i in 5"
            :key="i" />
      </div>
      <div v-if="loadedFirstTime">
        <div
            v-if="visitsList.length > 0"
            class="divide-y divide-gray-200">
          <client-event-single-visit
              v-for="visit in visitsList"
              :key="visit.id"
              :visit="visit" />
        </div>
        <div
            v-if="loadedFirstTime && paginatorInfo.lastPage > 1"
            class="w-full pt-4 pb-4 text-right bg-gray-100 border-t border-gray-200 px-4 rounded-b">
          <pagination
              v-if="paginatorInfo.lastPage > 1"
              :pagination="paginatorInfo"
              class="flex items-center justify-between text-gray-700 text-sm"
              @setPage="paginationData.page = $event">
            <div
                v-if="loading"
                class="inline-block w-8 h-8 mr-2 text-gray-400">
              <loader />
            </div>
          </pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import ClientEventSingleVisit from "@/components/client/Events/ClientEventSingleVisit.vue";

import Loader                             from "@/components/layout/Loader.vue";
import Pagination                         from "@/components/layout/Pagination.vue";
import ClientEventSingleLoading           from "@/components/client/Events/ClientEventSingleLoading.vue";
import {toRef, useAttrs, useSlots, watch} from "vue";
import {
  QLClient, QLClientVisitFilterEnum, QLClientVisitsQuery, QLSortOrder, useClientVisitsQuery
}                                         from "@/graphql/queries/ql/composables";
import routesList                         from "@/composables/routesList";
import {
  createPaginationData, paginationWithAlreadyLoaded
}                                         from "@/composables/paginationWithAlreadyLoaded";
import Empty                              from "@/components/layout/Empty.vue";
import {
  echoListener
}                                         from "@/composables/echoListener";
import {
  EventTypes
}                                         from "@/classes/notifications/EventTypes";

interface Props {
  client: QLClient;
  upcoming?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  upcoming: false
});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
const {routeList} = routesList();

const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 10
                                              });

const client = toRef(props, "client");
const upcoming = toRef(props, "upcoming");

const clientVisitsQ = useClientVisitsQuery(() => ({
  client_id: client.value.id.toString(),
  page:      paginationData.page,
  first:     paginationData.perPage,
  filter:    upcoming.value
             ? QLClientVisitFilterEnum.UPCOMING
             : QLClientVisitFilterEnum.PAST,
  orderBy:   [
    {
      column: "class_end_time",
      order:  upcoming.value ? QLSortOrder.ASC : QLSortOrder.DESC
    }
  ]
}));

const {
  paginatorInfo,
  loadedFirstTime,
  results: visitsList,
  loading
} = paginationWithAlreadyLoaded<QLClientVisitsQuery["client_visits"]["data"]>(clientVisitsQ);


echoListener([EventTypes.VisitWasUpdated], (ev) => {
  if (ev.data.client_id == client.value.id) {
    clientVisitsQ.refetch();
  }
}, 1000);
</script>
