<template>
  <div>
    <smart-select-tags :allow-new="false"
                       v-model="selectedValue"
                       :items="items"
                       class="block w-full"
                       focus-on-show>
    </smart-select-tags>
  </div>
</template>
<script lang="ts" setup>

import ClientTagOperator, {MultipleClientTagOperator} from "hm-filters/operators/ClientTagOperator";
import SmartSelectTags                                from "@/components/layout/Forms/SmartSelectTags.vue";
import {tagsClientsQuery}                             from "@/composables/queries/tagsQuery";
import {computed, toRef, watch}                       from "vue";

interface Props {
  operator: MultipleClientTagOperator | ClientTagOperator;
  modelValue: any;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:modelValue", "is-valid"]);

const {loading, tags} = tagsClientsQuery();

const items = computed(() => {
  return tags.value.map(item => item.name);
});

const modelValue = toRef(props, "modelValue");

const selectedValue = computed({
                                 get: () => {
                                   if (props.modelValue === null) {
                                     return [];
                                   }
                                   return props.modelValue;
                                 },
                                 set: (newVal) => emit("update:modelValue",
                                                       newVal !== null ? newVal : null)
                               });

const validCalculation = () => {
  let valid = !(props.modelValue === null || props.modelValue.length == 0);
  emit("is-valid", valid);
  return valid;
}

const isValid = computed(() => {
  return validCalculation();
});

watch(modelValue, validCalculation);

</script>
