import SaleFilter        from "hm-filters/filters/saleFilter";
import {SaleCountFilter} from "hm-filters/filters/SaleCountFilter";

export const SaleFilters = [
	new SaleFilter("History", {"field": ""}).setDateFilter().setDescription(
		"Filter users that made any purchases within your selected timeframe, which could be either a product sale or a service sale."),
	new SaleCountFilter("Count", {"field": "sale_count"}).setDescription(
		"Sale Count filters by the number of invoices/transactions a client has made that meet all other sale filters contained in that rule.")

];
