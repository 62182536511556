<template>
  <div>
    <div class="mb-8">
      <div class="pb-4 md:pb-0 max-w-5xl px-2 md:px-0">
        <div class="text-lg font-medium text-gray-900">
          Text message
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>
            SMS communications create highly engaging conversations between your business and subscribers, and
            strengthen staff-client relationships. To achieve a higher engagement, LoopSpark offers the ability to
            personalize the messages with a variety of personalized
            <a
                class="text-link"
                href="https://docs.loopspark.com/automations/mergetags/"
                target="_blank">merge tags</a>.
          </p>
          <div>
            <div class="text-base pt-4">
              Best practices
            </div>
            <p>
              SMS should be used for targeted transactional or urgent communications. For marketing messages, Email is
              the preferred option. To avoid SPAM blocks, make sure your clients are opted-in to SMS marketing, and
              your message does not include bit.ly links. Learn more about
              <a
                  class="text-link"
                  href="https://docs.loopspark.com/automations/sms_best_practices/"
                  target="_blank">SMS
                                  Best
                                  Practices</a>.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="split-view-left">
      <div class="block shadow-md rounded-lg flex-1 bg-white">
        <div class="p-4">
          <text-area-input
              v-model="contentText"
              v-model:position="cursorPosition"
              :error-message="formErrors.get('content') || smsData.errorMessage || null"
              :max-length="smsData.maxLength"
              :min-length="1"
              :placeholder="'Hi {{FIRST_NAME}}! {{FIRST_VISIT_TEACHER_NAME}} from {{STUDIO_NAME}} here. We just wanted to check in and see how your first class went?!'"
              :rows="10"
              data-content
              label="Text message content"
              replace-utf
              required>
            <div class="leading-tight form-small-info flex items-baseline justify-between text-gray-600">
              <div class=""><strong class="font-semibold">{{ smsData.units }}</strong> SMS Unit{{ smsData.units>1 ? 's':'' }}<span class="italic" v-if="isUsingMergeTags">. SMS with Merge tags could use more units.</span></div>
              <div class="text-right">{{ contentText.length }}/{{ smsData.maxLength }}</div>
            </div>
          </text-area-input>

          <div class="pt-4">
            <div class="text-sm font-medium text-gray-700 mb-2">
              You can use the following merge tags:
            </div>
            <placeholders-selector @selected="addPlaceholder" />
          </div>
        </div>
      </div>
      <div class="p-4 md:p-0 md:w-1/2">
        <div
            v-if="msgIsShortened"
            class="rounded-md bg-yellow-50 p-4 mb-12 border border-yellow-200">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/exclamation -->
              <svg
                  aria-hidden="true"
                  class="h-5 w-5 text-yellow-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                <path
                    clip-rule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    fill-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-md font-medium text-yellow-800">
                It looks like your message contains a link to an URL shortener service.
              </h3>
              <div class="mt-2 text-sm text-yellow-800">
                <p>
                  Unfortunately, link shorteners have been widely used for SMS spamming. Because of this, most major
                  mobile carriers will block messages that contain bit.ly or other popular link shortener URLs.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="text-lg font-medium text-gray-900">
          Text message preview
        </div>
        <p class="text-sm text-gray-600">
          The following is the preview of your SMS message. You should make sure that all
          the merge tags are correctly formatted.
        </p>
        <div class="mt-4 text-sm">
          <div
              v-if="contentText.length > 0"
              class="p-4 bubble text-white rounded-lg ">
            <automation-preview-sms
                :message-content="contentText"
                class="automation-preview-sms" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.automation-preview-sms span {
  @apply bg-gray-200 text-gray-900 px-1 inline-block rounded;
}
</style>
<style scoped>
.bubble {
  background-color:#0381e3;
}

.preview-sms span {
  @apply bg-gray-200;
}
</style>
<script lang="ts" setup>

import {computed, ref, useAttrs, useSlots} from "vue";
import SparkFormErrors, {SparkErrors}      from "@/classes/utils/SparkFormErrors";
import TextAreaInput                  from "@/components/layout/Forms/TextAreaInput.vue";
import PlaceholdersSelector           from "@/components/automations/Create/partials/PlaceholdersSelector.vue";
import AutomationPreviewSms           from "@/components/automations/Create/partials/AutomationPreviewSms.vue";
import {QLPlaceholder}                from "@/graphql/queries/ql/composables";
import {smsLengthInit}                from "@/composables/smsLength";
import {messageHasUrlShortner}        from "@/composables/messageHasUrlShortner";
import {smsCleanedContent}            from "@/composables/smsCleanedContent";

interface Props {
  content: string;
  errors: SparkErrors;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:content", "update:errors"]);

const formErrors = computed(() => {
  return new SparkFormErrors(props.errors);
});

const cursorPosition = ref(0);

const contentText = computed({
                               get: () => {
                                 if (!props.content) {
                                   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                   contentText.value = "";
                                   return "";
                                 } else {
                                   return props.content;
                                 }
                               },
                               set: (val) => {
                                 val = smsCleanedContent().replaceAllChars(val);

                                 emit("update:content", val);
                                 const err = new SparkFormErrors(props.errors);
                                 err.forget("content");
                                 emit("update:errors", err.all());
                               }
                             });

const {msgIsShortened} = messageHasUrlShortner(contentText);

const addPlaceholder = (placeholder: QLPlaceholder) => {
let t =  contentText.value.slice(0, cursorPosition.value) + placeholder.value + contentText.value.slice(cursorPosition.value);

  contentText.value = t;
};

const smsData = smsLengthInit(contentText, 2);

const isUsingMergeTags = computed(() => {
  return contentText.value.indexOf('{{') != -1;
});

</script>
