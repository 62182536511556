import Package                        from "./partials/package";
import OperatorGenerator              from "../operatorGenerator";
import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";

class PackageExpiring extends Package {
	constructor(name: string, options: any) {
		super(name, options);

		this.setOperators(OperatorGenerator.datesAll(true, true, false));

		this.addOperators([
			                  ...OperatorGenerator.dateBefore(false),
			                  ...OperatorGenerator.dateAfter(false),
			                  new OperatorAsFixedValueOperator("past", "in the past"),
			                  new OperatorAsFixedValueOperator("future", "in the future")
		                  ]);
	}

	get type(): string {
		return "PackageExpiringFilter";
	}
}

export default PackageExpiring;
