<template>
    <div>
        <smart-select
                :items="orderedPlaceholders"
                :model-value="null"
                label="label"
                placeholder="Select a merge tag..."
                @update:modelValue="selectedPlaceholder">
            <template #default="slotProps">
                <div>
                    <div class="inline-flex items-center text-gray-500">
                        <span>{{ slotProps.item.group }}</span>
                        <svg-icon
                                class="h-4 w-4"
                                name="right" />
                        <span v-if="slotProps.item.subgroup">{{ slotProps.item.subgroup }}</span>
                        <svg-icon
                                v-if="slotProps.item.subgroup"
                                class="h-4 w-4"
                                name="right" />
                        <span class="text-black">{{ slotProps.item.name }}</span>
                    </div>
                </div>
            </template>
        </smart-select>
    </div>
</template>
<script lang="ts" setup>
import SvgIcon           from "@/components/SvgIcon.vue";
import SmartSelect           from "@/components/layout/Forms/SmartSelect.vue";
import {computed, onMounted} from "vue";
import placeholdersQuery     from "@/composables/queries/placeholdersQuery";

const emit = defineEmits(["selected"]);

const {placeholders} = placeholdersQuery();


const orderedPlaceholders = computed(() => {
	return placeholders.value.map((a) => {
		return {
			...a,
			label: `${a.group}-${a.subgroup}-${a.name}`
		};
	}).sort((a, b) => {
		return a.label > b.label ? 1 : -1;
	});
});

const selectedPlaceholder = (placeholder) => {
	emit("selected", placeholder);
};
</script>
