import Filter from "../../base-filter";

import config           from "./config";
import LocationOperator from "hm-filters/operators/LocationOperator";

export class AppointmentLocation extends Filter {
	smallText = config.appointments.groups.appointment.group;
	group = config.appointments.groups.appointment.group;
	path = ["Appointments", this.group];

	constructor(name: string, options: any) {
		super(config.appointments.location, options);
		this.operators = [
			new LocationOperator("=", "is"),
			new LocationOperator("!=", "is not")
		];

	}

	get type(): string {
		return "AppointmentLocation";
	}
}

export class AppointmentNoShowLocation extends AppointmentLocation {
	smallText = config.appointments.groups.noShow.group;
	group = config.appointments.groups.noShow.group;
	path = ["Appointments", this.group];

	get type(): string {
		return "AppointmentNoShowLocation";
	}
}

export class AppointmentUpcomingLocation extends AppointmentLocation {
	smallText = config.appointments.groups.upcoming.group;
	group = config.appointments.groups.upcoming.group;
	path = ["Appointments", this.group];

	get type(): string {
		return "AppointmentUpcomingLocation";
	}
}
