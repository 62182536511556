import {QLFieldTypeEnum, QLFragmentFormBlockFragment} from "@/graphql/queries/ql/composables";

export default class FormBlockDecorator {
	private entity: QLFragmentFormBlockFragment;

	constructor(entity: QLFragmentFormBlockFragment) {
		this.entity = entity;
	}

	get isChartable(): boolean {
		switch (this.entity.kind) {
			case QLFieldTypeEnum.MULTIPLE:
			case QLFieldTypeEnum.SELECT:
			case QLFieldTypeEnum.STATE:
			case QLFieldTypeEnum.YES_NO:
			case QLFieldTypeEnum.RADIO:
				return true;
		}

		return false;
	}
}
