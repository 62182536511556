<template>
  <div>
    <div class="main-page-title">
      <span class="subtitle">Automations</span>
      Catalog EDIT
    </div>

    <loader v-if="loading" class="w-12 h-12"></loader>
    <div v-if="catalogItem && !loading">
      <catalog-create-edit-form
          v-if="catalogItem !== null"
          v-model:catalog-item="catalogItem"
          v-model:errors="errorsData"
          :saving="saving"
          @save="saveCatalog" />
    </div>

  </div>
</template>
<script lang="ts" setup>
import {useRouter}                                           from "vue-router";
import {ref, useAttrs, useSlots}                             from "vue";
import {useCatalogItemFullQuery, useSaveCatalogItemMutation} from "@/graphql/queries/ql/composables";
import routesList                                            from "@/composables/routesList";
import $toast                                                from "@/composables/toast";
import {fillValidationErrorsAndToast}                        from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import Loader                                                from "@/components/layout/Loader.vue";
import CatalogCreateEditForm                                 from "@/components/automations/Catalog/CatalogCreateEditForm.vue";
import {deepCopy}                                            from "@/classes/helpers/DeepCopy";
import AutomationDecorator                                   from "@/composables/decorators/AutomationDecorator";

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
const router = useRouter();

const saving = ref(false);
const errors = ref({});

const mut = useSaveCatalogItemMutation({});

mut.onDone((d) => {
  saving.value = false;
  const finalRoute = {
    name:   routesList().routeList.automations.catalog.edit,
    params: {id: d.data.SaveCatalogItem.id}
  };
  $toast({
           type:         "success",
           message:      "The catalog was update",
           linkRouteObj: finalRoute,
           linkText:     "View your catalog"
         });

  router.push(finalRoute);
});

const getCatalog = useCatalogItemFullQuery({id: props.id});

const {loading} = getCatalog;

const catalogItem = ref(null);

getCatalog.onResult((res) => {
  if (!res.loading) {
    catalogItem.value = deepCopy(res.data.catalog_item);
  }
});


mut.onError((err) => {
  fillValidationErrorsAndToast(err, errors);
  saving.value = false;
});

const saveCatalog = () => {
  saving.value = true;
  const toPost = AutomationDecorator(catalogItem.value.automation).computed.toPost;
  const {__typename, id, ...cleanedData} = catalogItem.value;
  const item = {...cleanedData, automation: toPost};

  item.variable_fields = item.variable_fields.map((itm) => {
    const {["__typename"]: remove, ...other} = itm;
    return other;
  });
  mut.mutate({id: props.id, catalogItem: item});
};

</script>
