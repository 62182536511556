<template>
  <div class="flex flex-col h-full">
    <div class="px-4 pt-4">
      <h3 class="font-semibold text-gray-600 mb-2">
        Medium ratio
      </h3>
    </div>

    <div class="grow flex justify-center items-center">
      <div class="h-56">
        <div
            v-if="loading"
            class="animate-loading w-full">
          <div class="flex justify-center pt-4">
            <div class="loading-bg h-24 w-24 rounded-full" />
          </div>
          <div class="flex justify-center mt-4">
            <div class="flex flex-col space-y-4">
              <div class="inline-flex space-x-2">
                <div class="loading-bg h-6 w-6 rounded" />
                <div class="loading-bg h-6 w-24 rounded" />
              </div>
              <div class="inline-flex space-x-2">
                <div class="loading-bg h-6 w-6 rounded" />
                <div class="loading-bg h-6 w-24 rounded" />
              </div>
            </div>
          </div>
        </div>

        <pie-chart
            v-if="!loading"
            :items="generatedData"
            class="h-full relative"
            item-name="name"
            item-value="cc"
            :legend="true"
            label="Email vs SMS"
            legend-position="bottom" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {QLClientEngagementStats}             from "@/graphql/queries/ql/composables";
import {computed, toRef, useAttrs, useSlots} from "vue";
import PieChart                              from "@/components/layout/Charts/PieChart.vue";

interface Props {
  engagementStats: QLClientEngagementStats;
  loading: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const loading = toRef(props, "loading");

const generatedData = computed(() => ([
  {name: "Email", cc: props.engagementStats.email.total},
  {name: "SMS", cc: props.engagementStats.sms.total}
]));

</script>
