<script setup lang="ts">
import SvgIcon                             from "@/components/SvgIcon.vue";
import {computed, ref, useAttrs, useSlots} from "vue";
import VueTailwindDatepicker               from "vue-tailwind-datepicker";

interface Props {
  dates: string[];
  placeholder: string;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:dates"]);

const datesModel = computed({
                              get: () => props.dates,
                              set: (val) => emit("update:dates", val)
                            });
const formatter = ref({
                        date:  "M/D/YYYY",
                        month: "MMM"
                      });

const dDate = (date) => {
  return date > new Date();
};

</script>

<template>
  <vue-tailwind-datepicker v-model="datesModel"
                           :placeholder="placeholder"
                           separator=" to "
                           :formatter="formatter"
                           :disable-date="dDate"
                           v-slot="{ value, placeholder, clear }"
  >
    <div :class="['flex items-center hover:text-blue-500', !value ? 'text-gray-500':'text-gray-700' ]">
      <div>
        <button
            type="button"
            class="block"
        >
            <span class="text-sm font-bold w-full truncate grow">
              {{ value || placeholder }}
            </span>
        </button>
      </div>
      <button
          type="button"
          class="grow-0 shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center hover:bg-red-200 hover:text-red-500 focus:outline-none focus:bg-red-500 focus:text-white"
          @click="clear"
          v-if="value"
      >
        <span class="sr-only">Remove large option</span>
        <svg-icon
            name="close"
            class=" w-3 h-3"
            icon="close" />
      </button>
    </div>
  </vue-tailwind-datepicker>
</template>

<style scoped>

</style>
