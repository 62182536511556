import {computed, Ref} from "vue";

export function smsCleanedContent() {

	const replaceChar = (text: string, charFrom: string, charTo: string) => {
		return text.replace(charFrom, charTo);
	};

	const chars = [
		{"from": "…", "to": " ..."},
		{"from": "‘", "to": "'"},
		{"from": "’", "to": "'"},
		{"from": "´", "to": "'"},
		{"from": "`", "to": "'"},
		{"from": "”", "to": "\""},
		{"from": "“", "to": "\""},
		{"from": "–", "to": "-"},
		{"from": "—", "to": "-"},
		{"from": "‚", "to": ","}
	];

	const replaceAllChars = (text: string) => {
		for (const charsKey in chars) {
			text = replaceChar(text, chars[charsKey].from, chars[charsKey].to);
		}

		return text;
	};

	const comp = (originalText: Ref<string>) => {
		return computed({
			                get: () => originalText.value,
			                set: (val) => {
				                originalText.value = replaceAllChars(val);
			                }
		                });
	}




	return {
		computed: comp,
		replaceAllChars
	};

}
