import {GraphQlOperatorData}                                                                                                                                                                         from "hm-filters/operators/GraphQlOperator";
import {
	AppointmentNamesDocument, AutomationCategoriesDocument, AutomationsQuickListDocument, ContractNamesDocument,
	EventNamesDocument, FormCategoriesDocument, FormsIndexDocument, PackageNamesDocument, ProductNamesDocument,
	StaffsDocument
} from "@/graphql/queries/ql/composables";

interface Indexable {
	[key: string]: any;
}

export default class QLQueriesData {
	static ClassNameQlOperator: GraphQlOperatorData = {
		query:     EventNamesDocument,
		field:     "name",
		queryPath: "event_names"
	};
	static AppointmentNameQlOperator: GraphQlOperatorData = {
		query:     AppointmentNamesDocument,
		field:     "name",
		queryPath: "appointment_names"
	};
	static StaffNameQlOperator: GraphQlOperatorData = {
		query:     StaffsDocument,
		field:     "name",
		queryPath: "staffs"
	};
	static AppointmentPackageUsedQlOperator: GraphQlOperatorData = {
		query:     PackageNamesDocument,
		field:     "name",
		queryPath: "package_names"
	};

	static CommunicationReceivedQlOperator: GraphQlOperatorData = {
		query:     AutomationsQuickListDocument,
		field:     "name",
		queryPath: "automations"
	};

	static CommunicationCategoryQlOperator: GraphQlOperatorData = {
		query:     AutomationCategoriesDocument,
		field:     "name",
		queryPath: "automation_categories"
	};


	static ContractNameQlOperator: GraphQlOperatorData = {
		query:     ContractNamesDocument,
		field:     "name",
		queryPath: "contract_names"
	};

	static PackageNameQlOperator: GraphQlOperatorData = {
		query:     PackageNamesDocument,
		field:     "name",
		queryPath: "package_names"
	};

	static ProductTypeQlOperator: GraphQlOperatorData = {
		query:     ProductNamesDocument,
		field:     "name",
		queryPath: "product_names"
	};

	static FormCategoryQlOperator: GraphQlOperatorData = {
		query:     FormCategoriesDocument,
		field:     "name",
		queryPath: "form_categories"
	};

	static FormNameQlOperator: GraphQlOperatorData = {
		query:     FormsIndexDocument,
		field:     "name",
		queryPath: "forms_index"
	};

	static getStaticProperty(propertyName: string): GraphQlOperatorData {
		return (QLQueriesData as Indexable)[propertyName];
	}
}
