<template>
  <div>
    <div class="">
      <div class="">
        <div class="px-4 sm:px-6">
          <div class="flex justify-between">
            <div class="grow">
              <div><span class="font-medium">{{ virtualEvent.name }}</span></div>
              <span class="text-sm text-gray-600 font-medium">{{ dayName }} @ {{ timeName }}</span>
            </div>

            <div class="shrink-0 self-center flex">
              <Menu as="div" class="relative z-30 inline-block text-left">
                <div>
                  <MenuButton class="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                    <span class="sr-only">Open options</span>
                    <svg-icon name="dots-vertical" class="h-5 w-5" aria-hidden="true" />
                  </MenuButton>
                </div>

                <transition enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95">
                  <MenuItems class="origin-top-right absolute right-0 z-40 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="py-1">
                      <MenuItem v-slot="{ active }">
                        <button
                            :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex px-4 py-2 text-sm w-full']"
                            @click="modals.editOpen = true">
                          <svg-icon
                              name="edit"
                              class="mr-3 h-5 w-5 text-gray-400" />
                          <span>Edit</span>
                        </button>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <button
                            :class="[active ? 'bg-gray-100 text-red-900' : 'text-red-700', 'flex px-4 py-2 text-sm w-full']"
                            @click="modals.deleteOpen = true">
                          <svg-icon
                              name="trash"
                              class="mr-3 h-5 w-5 text-red-400" />
                          <span>Delete</span>
                        </button>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div class="py-2 px-4 sm:px-6">
        <div class="flex justify-between">
          <div class="text-sm">
            <span class="text-gray-500 pr-2">Streaming URL:</span>
            <a
                class="text-loopspark-400 truncate hover:text-loopspark-900"
                target="_blank"
                :href="virtualEvent.virtual_url">{{
                virtualEvent.virtual_url
                                                 }}</a>
          </div>
          <button
              v-if="virtualEvent.virtual_data"
              class="grow-0 shrink-0 text-xs inline-flex items-center text-loopspark-400 hover:text-loopspark-500 space-x-1"
              @click="modals.moreInfo = !modals.moreInfo">
            <span v-text="modals.moreInfo ? 'Less info': 'More info'" />
            <div :class="{'transform' : modals.moreInfo, 'rotate-180': modals.moreInfo}">
              <svg-icon
                  name="down"
                  class="w-3 h-3"
                  icon="dropdown" />
            </div>
          </button>
        </div>
        <div
            v-if="virtualEvent.virtual_data"
            v-show="modals.moreInfo"
            class="mt-2 text-sm">
          <div class="space-y-2">
            <div>
              <span class="text-gray-500">Streaming ID:</span> <span>{{ virtualEvent.virtual_data.virtual_id }}</span>
            </div>
            <div>
              <span class="text-gray-500">Streaming Password:</span> <span>{{
                virtualEvent.virtual_data.virtual_password
                                                                           }}</span>
            </div>
            <div>
              <span class="text-gray-500">Streaming Info:</span>
              <div class="bg-loopspark-50 p-2 rounded mt-2">
                {{ virtualEvent.virtual_data.virtual_info }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <virtual-event-edit
        ref="virtualeventedit"
        v-model:open="modals.editOpen"
        :virtual-event="virtualEvent" @updated="emit('updated')" />
    <confirm-modal
        v-model:open="modals.deleteOpen"
        :loading="modals.deleteLoading"
        type="warning"
        title="Are you sure?"
        @clicked="startDelete">
      <template #button>
        Yes, delete it!
      </template>
      <template #text>
        The Virtual Event settings for {{ virtualEvent.name }} will be deleted. Deleting this
        template will not affect the events that were already updated. Are you sure you want to
        delete it?
      </template>
    </confirm-modal>
  </div>
</template>
<script lang="ts" setup>
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";

import {MinutesFromMidnightToTime}                             from "@/classes/dates/MinutesFromMidnightToTime";
import VirtualEventEdit
                                                               from "@/components/Events/VirtualEvents/VirtualEventEdit.vue";
import ConfirmModal                                            from "@/components/layout/ConfirmModal.vue";
import SvgIcon                                                 from "@/components/SvgIcon.vue";
import {computed, reactive, toRef, useAttrs, useSlots}         from "vue";
import {QLVirtualClassInfo, useDeleteVirtualClassInfoMutation} from "@/graphql/queries/ql/composables";
import $toast                                                  from "@/composables/toast";

interface Props {
  virtualEvent: QLVirtualClassInfo;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["updated", "deleted"]);

const daysToConsider = [
  {dayId: null, name: "Any day"},
  {dayId: 0, name: "Sundays"},
  {dayId: 1, name: "Mondays"},
  {dayId: 2, name: "Tuesdays"},
  {dayId: 3, name: "Wednesdays"},
  {dayId: 4, name: "Thursdays"},
  {dayId: 5, name: "Fridays"},
  {dayId: 6, name: "Saturdays"}
];

const modals = reactive({
                          deleteOpen:    false,
                          deleteLoading: false,
                          moreInfo:      false,
                          editOpen:      false
                        });

const virtualEvent = toRef(props, "virtualEvent");

const dayName = computed(() => {
  return daysToConsider.filter((i) => i.dayId === virtualEvent.value.day_of_week)[0].name;
});

const timeName = computed(() => {
  if (virtualEvent.value.start_time === null) {
    return "anytime";
  }
  return MinutesFromMidnightToTime(virtualEvent.value.start_time);
});

const deleteMutation = useDeleteVirtualClassInfoMutation({});

deleteMutation.onDone(() => {

  $toast({
           type:         "success",
           message:      "Successfully deleted!",
           linkRouteObj: null,
           linkText:     null
         });
  modals.deleteLoading = false;
  modals.deleteOpen = false;
  emit("deleted");
});

deleteMutation.onError(() => {
  modals.deleteLoading = false;
  $toast({
           type:         "error",
           message:      "There was a problem with your request. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const startDelete = () => {
  modals.deleteLoading = true;
  deleteMutation.mutate({
                          id: virtualEvent.value.id
                        });
};
</script>
