import ContractName               from "hm-filters/filters/contractNameFilter";
import ContractExpiring           from "hm-filters/filters/contractExpiringFilter";
import ContractStatus             from "hm-filters/filters/contractStatusFilter";
import ContractPurchaseDateFilter from "hm-filters/filters/contractPurchaseDateFilter";
import ContractStarting           from "hm-filters/filters/contractStartingFilter";
import {ContractCountFilter}      from "hm-filters/filters/contractCountFilter";

export const ContractFilters = [
	new ContractName("Purchased", {"field": "contract_name"}).setDescription(
		"Contract Purchased will filter clients that have, or have not purchased your selected contract(s)."),


	new ContractExpiring("Expiration Date", {"field": "contract_expiring"}).setDateFilter().setDescription(
		"Contract Expiration Date will filter clients that an active contract which will expire in your selected timeframe."),


	new ContractStatus("Status", {"field": "status"}).setDescription(
		"Contract Status is a great filter to add to rules containing other contract related filters. This filter will help you target clients by the status of their contracts."),


	new ContractCountFilter("Count", {"field": "contract_count"}).setDescription(
		"Contract Count filters by the number of contracts a client has purchased that meet all other contract filters contained in that rule."),


	new ContractPurchaseDateFilter("Purchase Date",
	                               {"field": "contract_purchase_date"}).setDateFilter().setDescription(
		"Contract Purchase Date will filter clients that have purchased a contract within a selected timeframe. "),


	new ContractStarting("Start Date", {"field": "contract_start_date"}).setDateFilter().setDescription(
		"Contract Start Date is a great filter to create trigger points based on a client’s contract start date. This is also a great filter to use as a conditional rule, such as contract start date = not in the next 90 days which would help you omit any client from receiving a communication that has a contract starting in the future")
];
