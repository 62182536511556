import {DocumentNode}  from "graphql";
import WithCustomInput from "hm-filters/operators/Bases/WithCustomInput";
import {OperatorTypes} from "hm-filters/operators/OperatorTypes";

export interface GraphQlOperatorData {
	query: DocumentNode,
	field: string,
	queryPath: string,
}

class GraphQlOperator extends WithCustomInput {

	graphQLData?: GraphQlOperatorData;
	isMultipleChoice: boolean = false;
	hasInput = true;

	get type(): string {
		return OperatorTypes.GraphQl;
	}

	setIsMultipleChoice(multiple: boolean = true) {
		this.isMultipleChoice = multiple;
		return this;
	}

	setQlQuery(query: DocumentNode, field: string, queryPath: string) {
		this.graphQLData = {
			query,
			field,
			queryPath
		};
		return this;
	}
}

export class GraphQlMultipleOperator extends GraphQlOperator {
	get type(): string {
		return OperatorTypes.GraphQlMultiple;
	}
}

export default GraphQlOperator;
