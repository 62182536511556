<template>
  <div>
    <status-banner-item
        v-if="currentTeam && !currentTeam.approved"
        color="green">
      <div>
        Welcome to LoopSpark! Your studio will be approved shortly by our staff. If you have any questions, you can reach us via
        <a href="mailto:support@loopspark.com" class="text-blue-50 underline underline-offset-2 hover:text-blue-100">email</a>
      </div>
    </status-banner-item>

    <status-banner-item
        v-if="showsApiError"
        color="lightyellow">
      <div
          v-if="me.policy.is_owner"
          class="flex justify-between items-center">
        <div>You need to set your {{ currentTeam.provider_name }} API</div>
        <router-link
            :to="{name: routeList.studio.settings.profile}"
            class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50">
          API Settings
        </router-link>
      </div>
      <div v-if="!me.policy.is_owner">
        This team is missing the {{ currentTeam.provider_name }} API settings. The team owner needs to fill out those
        details.
      </div>
    </status-banner-item>

    <status-banner-item
        v-if="showOutgoingNotSet"
        color="orange">
      <div v-if="!me.policy.is_owner">
        Your outgoing message settings are incomplete. Ask the team owner to fill out those details.
      </div>
      <div
          v-if="me.policy.is_owner"
          class="flex justify-between items-center">
        <div>Your outgoing message settings are incomplete.</div>
        <router-link
            :to="{name: routeList.studio.settings.profile}"
            class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50">
          Studio Settings
        </router-link>
      </div>
    </status-banner-item>

    <status-banner-item
        v-if="showStillImporting"
        color="green">
      <div>
        The initial import of your studio is running! The data you see on this page might be incomplete.
      </div>
    </status-banner-item>

    <status-banner-item
        v-if="showSubscriptionProblem"
        color="red">
      <div
          v-if="me.policy.is_owner"
          class="flex justify-between items-center">
        <div>You need a valid subscription to use LoopSpark.</div>
        <router-link
            :to="{name: routeList.studio.settings.billing}"
            class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50">
          Billing
        </router-link>
      </div>
      <div v-if="!me.policy.is_owner">
        There was a problem with the subscription for this team. Ask the team owner to fix it in the Billing section.
      </div>
    </status-banner-item>
  </div>
</template>
<script lang="ts" setup>
import StatusBannerItem                                          from "@/components/StatusBanner/StatusBannerItem.vue";
import {computed, toRef, useAttrs, useSlots}                     from "vue";
import routesList                                                from "@/composables/routesList";
import {QLFragmentCurrentTeamFragment, QLFragmentUserMeFragment} from "@/graphql/queries/ql/composables";

interface Props {
  me: QLFragmentUserMeFragment;
  currentTeam: QLFragmentCurrentTeamFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
const {routeList} = routesList();

const currentTeam = toRef(props, "currentTeam");
const me = toRef(props, "me");

const hasApi = computed(() => {
  return currentTeam.value.site_id != null;
});

const subscriptionActive = computed(() => {
  return currentTeam.value.is_free || currentTeam.value.subscription_active;
});

const showSubscriptionProblem = computed(() => {
  return !subscriptionActive.value;
});

const showsApiError = computed(() => {
  return subscriptionActive.value && !hasApi.value;
});

const showOutgoingNotSet = computed(() => {
  return !showsApiError.value && !currentTeam.value.outgoing_set;
});

const showStillImporting = computed(() => {
  return subscriptionActive.value && !showsApiError.value && !showOutgoingNotSet.value && !currentTeam.value.first_import_done;
});

</script>
