import {computed, isRef, reactive, ref, Ref}                                                     from "vue";
import {QLCalendarClassFragmentFragment, QLClassModel, QLClassType, QLFragmentClassFullFragment} from "@/graphql/queries/ql/composables";

export function EventDecorator(passed: Ref<QLCalendarClassFragmentFragment | QLClassModel | QLFragmentClassFullFragment> | QLCalendarClassFragmentFragment | QLClassModel | QLFragmentClassFullFragment) {

	const entity = isRef(passed) ? passed : ref(passed);

	const isGroupClass = computed<boolean>(() => {
		return entity.value.event_type == QLClassType.TYPE_CLASS;
	});

	const classDefinition = computed(() => {
		if (entity.value.is_virtual) {
			if (isGroupClass.value) {
				return "Virtual group class";
			} else {
				return "Virtual appointment";
			}
		}

		if (isGroupClass.value) {
			return "Group class";
		} else {
			return "Appointment";
		}
	});


	return {
		computed: reactive({
			                   isGroupClass,
			                   classDefinition
		                   })
	};
}
