<template>
  <div class="whitespace-pre-line" v-html="replacedContent"></div>
</template>
<script lang="ts" setup>

import placeholdersQuery from "@/composables/queries/placeholdersQuery";

interface Props {
  messageContent: string;
  tagClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  tagClass:    "placeholder-tag",
});

import {ref, useSlots, useAttrs, computed} from "vue";

function escapeStringRegexp(string) {
  if (typeof string !== 'string') {
    throw new TypeError('Expected a string');
  }

  // Escape characters with special meaning either inside or outside character sets.
  // Use a simple backslash escape when it’s always valid, and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
  return string
      .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
      .replace(/-/g, '\\x2d');
}

const emit = defineEmits([]);

const {placeholders} = placeholdersQuery();

const makeTag = (val: string) =>{
  return `<span class="${props.tagClass}">${val}</span>`;
}

const replacedContent = computed(() => {
  let str = `${props.messageContent}`;

  placeholders.value.forEach((placeholder) => {
    str = str.replace(
        new RegExp(escapeStringRegexp(placeholder.value), "g"),
        makeTag(placeholder.placeholder));
  });

  return str;
});


</script>
