<template>
  <div class="">
    <div>
      <draggable
          v-model="contentModel"
          class="space-y-2"
          handle=".draggable-handle"
          item-key="id"
          @start="drag=true"
          @end="drag=false">
        <template #item="{index}">
          <form-single-field-recap
              v-model:field="contentModel[index]"
              :use-mappings="useMappings"
              :other-fields="contentModel"
              @delete="removeItem(index)" />
        </template>
      </draggable>
    </div>
    <empty
        v-if="contentModel.length === 0"
        class="py-8">
      <template #subtitle>
        <div>Start designing your form by adding a "text block" or "input field."</div>
        <div>
          <div class="pt-4 w-full flex items-center justify-center">
            <button
                class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="button"
                @click="openAddModal = true">
              <div class="mr-1 h-4 w-4">
                <SvgIcon
                    class="w-4 h-4"
                    name="plus" />
              </div>

              <div>Add new field</div>
            </button>
          </div>
        </div>
      </template>
    </empty>
    <div
        v-if="contentModel.length > 0"
        class="pt-4 w-full flex items-center justify-center">
      <button
          type="button"
          class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          @click="openAddModal = true">
        <div class="mr-1 h-4 w-4">
          <SvgIcon
              name="plus"
              class="w-4 h-4" />
        </div>

        <div>Add new field</div>
      </button>
    </div>
  </div>
  <side-panel
      v-if="!newFieldAdded"
      v-model:open="openAddModal"
      closable
      wide>
    <template #header>
      Add a new field
    </template>
    <template #default>
      <div class="p-4">
        <div class="text-gray-600 mb-8">
          <p>
            Forms can be built using the list of 6 fields where customers can submit responses, and the field “Text
            Block” where you can add additional text such as terms of service, form instructions, or any other free
            text you’d like to include.
          </p>
          <p>
            Fields can be deleted, set as required, and field width can be changed to allow more than one field on the
            same line.
          </p>
        </div>

        <ul class="space-y-4">
          <li
              v-for="availableField in availableNewFields"
              :key="availableField.title"
              role="option"
              class="relative block bg-white border rounded-lg shadow-sm pl-4 pr-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none hover:border-indigo-500 hover:ring-1 hover:ring-indigo-500 group"
              @click="addNewFieldType(availableField.kind)">
            <div class="flex items-center">
              <div class="mr-4">
                <svg-icon
                    :name="availableField.icon"
                    class="h-5 w-5" />
              </div>
              <div>
                <p class="font-medium text-gray-900 group-hover:text-indigo-700">
                  {{ availableField.title }}
                </p>
                <div class="text-gray-500 text-sm">
                  {{ availableField.description }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </side-panel>

  <component
      :is="componentType"
      v-if="newFieldAdded"
      v-model:open="newFieldAddedOpen"
      v-model:field="newFieldAdded"
      :other-fields="contentModel"
      :use-mappings="useMappings"
      @saved="addTheNewField"
      @cancelled="newFieldAdded = null" />

</template>

<script lang="ts" setup>
import {SparkErrors}                  from "@/classes/utils/SparkFormErrors";
import {QLFieldTypeEnum, QLFormField} from "@/graphql/queries/ql/composables";
import {computed, ref, toRef}         from "vue";
import {klona}                        from "klona/full";
import {v4 as uuidv4}                 from "uuid";
import FormSingleFieldRecap           from "@/components/Forms/FormSingleFieldRecap.vue";
import SvgIcon                        from "@/components/SvgIcon.vue";
// noinspection ES6UnusedImports
import draggable                      from "vuedraggable";
import SidePanel                      from "@/components/layout/SidePanel.vue";
import InputFieldsFunctions           from "@/components/Forms/Fields/Input/InputFieldsFunctions";
import Empty                          from "@/components/layout/Empty.vue";
import fieldsData                     from "@/components/Forms/Fields/fields";

interface Props {
  content: QLFormField[];
  saving: boolean;
  errors: SparkErrors;
  useMappings?: boolean;
}

const props = withDefaults(defineProps<Props>(), {useMappings: false});
const emit = defineEmits(["update:content"]);

const items = toRef(props, "content");

const editing = ref<QLFormField | null>(null);

const contentModel = computed({
                                get: () => props.content,
                                set: (val) => emit("update:content", val)
                              });
const removeItem = (index) => {
  contentModel.value.splice(index, 1);
};

const openAddModal = ref(false);

const newFieldAdded = ref<QLFormField | null>(null);
const newFieldAddedOpen = ref(false);

const {componentType} = InputFieldsFunctions.createComponentTypeFunction(newFieldAdded);

const addNewFieldType = (fieldType: QLFieldTypeEnum) => {
  newFieldAdded.value = {
    id:          uuidv4(),
    description: "",
    title:       "title",
    optional:    (fieldType === QLFieldTypeEnum.TEXT_BLOCK || fieldType === QLFieldTypeEnum.IMAGE),
    kind:        fieldType,
    content:     fieldType === QLFieldTypeEnum.TEXT_BLOCK ? "your content here" : (fieldType === QLFieldTypeEnum.IMAGE
                                                                                   ? ""
                                                                                   : null),
    options:     fieldType === QLFieldTypeEnum.MULTIPLE || fieldType === QLFieldTypeEnum.SELECT || fieldType === QLFieldTypeEnum.RADIO
                 ? []
                 : null,
    size:        100,
    prefill:     null,
    mapping:     null
  };
  openAddModal.value = false;
  newFieldAddedOpen.value = true;
};

const addTheNewField = () => {
  contentModel.value.push(klona(newFieldAdded.value));
  newFieldAddedOpen.value = false;
  newFieldAdded.value = null;
};

const availableNewFields = fieldsData;

</script>
