import * as am4core from "@amcharts/amcharts4/core";

export default function am4themes_Loopspark(target) {
	if (target instanceof am4core.ColorSet) {
		target.reset();
		// target.currentStep = 0;
		// target.baseColor = am4core.color('#0262AC');
		target.list = [
			am4core.color("#08306b"),
			am4core.color("#08519c"),
			am4core.color("#2171b5"),
			am4core.color("#4292c6"),
			am4core.color("#6baed6"),
			am4core.color("#9ecae1"),
			am4core.color("#c6dbef"),
			am4core.color("#deebf7"),
			am4core.color("#f7fbff")
		];

		// target.list = ColorPalette.colors(9).map((color) => am4core.color(color));
		// target.reuse = true;
		// target.wrap = false;
		// target.startIndex = 1;
	}
}

export function am4themes_Loopspark_Reverse(target) {
	if (target instanceof am4core.ColorSet) {
		target.reset();
		// target.currentStep = 0;
		// target.baseColor = am4core.color('#0262AC');
		target.list = [
			am4core.color("#f7fbff"),
			am4core.color("#deebf7"),
			am4core.color("#c6dbef"),
			am4core.color("#9ecae1"),
			am4core.color("#6baed6"),
			am4core.color("#4292c6"),
			am4core.color("#2171b5"),
			am4core.color("#08519c"),
			am4core.color("#08306b"),
		];

		// target.list = ColorPalette.colors(9).map((color) => am4core.color(color));
		// target.reuse = true;
		// target.wrap = false;
		// target.startIndex = 1;
	}
}

export function am4themes_Loopspark_DeliveryFunnel(target) {
	if (target instanceof am4core.ColorSet) {
		target.reset();
		// target.currentStep = 0;
		// target.baseColor = am4core.color('#0262AC');
		target.list = [
			am4core.color("#c6dbef"),
			am4core.color("#9ecae1"),
			am4core.color("#6baed6"),
			am4core.color("#4292c6"),
		];

		// target.list = ColorPalette.colors(9).map((color) => am4core.color(color));
		// target.reuse = true;
		// target.wrap = false;
		// target.startIndex = 1;
	}
}
