<template>
  <input-field-base
      v-if="fieldModel"
      v-model:field="fieldModel"
      v-model:open="openModel"
      :can-save="canSave"
      :name-already-used="nameAlreadyUsed"
      :title-optional="true"
      @cancelled="openModel = false"
      @saved="saved">
    <template #fields>
      <div class="">
        <div class="text-sm font-medium text-gray-700">
          Image
        </div>
        <div class="mt-2 text-sm text-gray-900 sm:mt-2">
          <div v-show="!fieldModel.content">
            <div class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div class="space-y-1 text-center">
                <svg
                    aria-hidden="true"
                    class="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 48 48">
                  <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2" />
                </svg>
                <div class="flex text-sm text-gray-600 justify-center">
                  <label class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                    <span>Upload a file</span>
                    <input
                        accept="image/*"
                        class="hidden"
                        type="file"
                        @change="handleFileUpload">
                  </label>
                </div>
                <p class="text-xs text-gray-700">
                  PNG, JPG, GIF up to 1MB
                </p>
              </div>
            </div>
          </div>

          <div
              v-show="fieldModel.content"
              class="flex justify-between space-x-4 w-full">
            <div class="max-h-64">
              <img
                  :src="fieldModel.content"
                  alt="preview"
                  class="object-cover h-64">
            </div>
            <div class="inline-block shrink-0">
              <label class=" flex flex-col items-center px-4 py-4 text-gray-800 bg-white rounded-lg tracking-wide  border border-gray-400 cursor-pointer hover:bg-loopspark-700 hover:text-white">
                <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span class="mt-1 text-sm leading-normal">Select an image</span>
                <input
                    accept="image/*"
                    class="hidden"
                    type="file"
                    @change="handleFileUpload">
              </label>
            </div>
          </div>
          <span
              v-if="hasSizeError"
              class="form-error-message">The file is too big. Maximum size is 1MB.</span>

          <div class="mt-4 text-sm text-gray-700">
            <p>Your image will be automatically resized to a maximum height of 320px, and the width will be adjusted to maintain the aspect ratio.</p>
            <p class="font-medium pt-2 pb-1">Image Requirements:</p>
            <ul class="list-disc list-inside space-y-1">
              <li>1MB max upload</li>
              <li>File Formats: PNG, JPG, GIF</li>
              <li>Dark logo that will stand out on white background (recommended)</li>
              <li>Aspect ratio of 4:1 (recommended)</li>
            </ul>



          </div>
        </div>
      </div>
    </template>
  </input-field-base>
</template>
<script lang="ts" setup>
import {computed, ref, withDefaults} from "vue";
import InputFieldBase
                                                               from "@/components/Forms/Fields/Input/InputFieldBase.vue";
import InputFieldsFunctions
                                                               from "@/components/Forms/Fields/Input/InputFieldsFunctions";
import {QLFormField}                                           from "@/graphql/queries/ql/composables";

interface InputFieldProps {
  field: QLFormField;
  open: boolean;
  otherFields: QLFormField[];
  useMappings: boolean;
}

const props = withDefaults(defineProps<InputFieldProps>(), {});
const emit = defineEmits(["deleted", "update:field", "saved", "update:open"]);

const {
  fieldModel,
  openModel,
  otherFields,
  saved,
  toRun,
  nameAlreadyUsed
} = InputFieldsFunctions.bootstrapEditField(emit,
                                            props);
toRun();

const file = ref(null);
const hasSizeError = ref(false);

const handleFileUpload = async (ev) => {
  hasSizeError.value = false;
  if ((ev.target as HTMLInputElement).files[0].size > 1048576) {
    hasSizeError.value = true;
  } else {
    fieldModel.value.content = await toBase64((ev.target as HTMLInputElement).files[0]);
  }
};

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const canSave = computed(() => {
  if (!props.field) {
    return false;
  }
  if (InputFieldsFunctions.nameIsAlreadyUsed(otherFields.value, fieldModel.value)) {
    return false;
  }
  return (fieldModel.value.title.trim().length > 0 && fieldModel.value.content.length > 0 && hasSizeError.value == false);
});

</script>
