<template>
  <div class="">
    <div class="flex justify-between">
      <div class="flex items-center align-center">
        <img :src="announcement.creator.photo_url" alt="" class="w-10 h-10 rounded-full">
        <div class="pl-2">
          <strong class="block">{{ announcement.creator.name }}</strong>
          <timestamp-to-date class="block text-gray-500 text-sm" :timestamp="announcement.created_at"/>
        </div>
      </div>
      <time-ago :timestamp="announcement.created_at"
                class="block pt-1 pl-2 text-gray-500 text-sm"></time-ago>
    </div>

      <div class="prose prose-sm pt-2" v-html="announcement.parsed_body"></div>
      <div class="py-4" v-if="announcement.action_text">
        <a :href="announcement.action_url" class="btn btn-blue">{{ announcement.action_text }}</a>
      </div>
  </div>
</template>
<script lang="ts" setup>
import {QLAnnouncement} from "@/graphql/queries/ql/composables";
import TimeAgo          from "@/components/layout/TimeAgo.vue";

interface Props {
  announcement: QLAnnouncement;
}

const props = withDefaults(defineProps<Props>(), {});
import {toRef}          from "vue";
import TimestampToDate  from "@/components/layout/TimestampToDate.vue";

const announcement = toRef(props, "announcement");

</script>
