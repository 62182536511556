import Filter            from "../base-filter";
import OperatorGenerator from "../operatorGenerator";

class Social extends Filter {
	smallText = "Social";
	group = "Social";
	deprecated = true;

	constructor(name: string, options: any) {
		super(name, options);

		this.setOperators(OperatorGenerator.presenceOperators("has account",
		                                                      "does not have account"));
	}

	get type(): string {
		return "SocialValues";
	}
}

class SocialInfluence extends Filter {
	smallText = "Social";
	group = "Social";
	deprecated = true;

	get type(): string {
		return "SocialInfluence";
	}

	followingOperators() {
		this.setOperators(OperatorGenerator.socialFollowing());

		return this;
	}

	followersOperators() {
		this.setOperators(OperatorGenerator.socialFollowers());
		return this;
	}
}

export {SocialInfluence, Social};
