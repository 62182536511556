import {ApolloError} from "@apollo/client/core";

export interface QlErrorInterface {
	error: ApolloError
}

export default class BaseQlError implements QlErrorInterface {
	error: ApolloError;

	constructor(error: ApolloError) {
		this.error = error;
	}
}
