import Package           from "./partials/package";
import OperatorGenerator from "../operatorGenerator";

class PackageEndDateFilter extends Package {
	constructor(name: string, options: any) {
		super(name, options);

		this.setOperators(OperatorGenerator.datesPastOnly(false, false, true));
	}

	get type(): string {
		return "PackageEndDateFilter";
	}
}

export default PackageEndDateFilter;
