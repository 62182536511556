import {DateTime, Duration} from "luxon";
import {DateTimeRange}      from "@/classes/dates/DateRangePeriodParser";

export class TimeframeParser {
	private readonly _timeframe: Timeframes;
	private readonly _startTime: number;
	private readonly _calculated: DateTimeRange;

	constructor(timeframe: Timeframes, startTime: number | null = null) {
		this._timeframe = timeframe;
		this._startTime = startTime ?? Math.round(Date.now() / 1000);

		this._calculated = this.calculate();
	}

	get result(): DateTimeRange {
		return this._calculated;
	}

	get past(): DateTimeRange {
		let per = this._timeframe;
		if (per === Timeframes.LastWeek) {
			per = Timeframes.ThisWeek;
		}
		if (per === Timeframes.LastMonth) {
			per = Timeframes.ThisMonth;
		}
		return (new TimeframeParser(per, this._calculated.start - 1)).result;
	}

	private static returnInSeconds(from: DateTime, to: DateTime): DateTimeRange {
		return {start: Math.round(from.toMillis() / 1000), end: Math.round(to.toMillis() / 1000)};
	}

	private lastXDays(days: number): DateTimeRange {
		const to = DateTime.fromMillis(this._startTime * 1000)
		                   .endOf("day")
		                   .minus({second: 1});
		const from = to.minus({days: days})
		               .startOf("day");
		return TimeframeParser.returnInSeconds(from, to);
	}

	private thisPeriod(period: "week" | "month"): DateTimeRange {
		const to = DateTime.fromMillis(this._startTime * 1000).endOf(period).minus({second: 1});
		// days start on sunday in US
		const from = to.startOf(period).minus({days: period === "week" ? 1 : 0});
		return TimeframeParser.returnInSeconds(from, to);
	}

	private lastPeriod(period: "week" | "month"): DateTimeRange {
		const subPeriod: Duration = Duration.fromObject({
			                                                weeks:  period === "week" ? 1 : 0,
			                                                months: period === "month" ? 1 : 0
		                                                });

		const to = DateTime.fromMillis(this._startTime * 1000).minus(subPeriod).endOf(period).minus({seconds: 1});
		// days start on sunday in US
		const from = to.startOf(period).minus({days: period === "week" ? 1 : 0});
		return {start: Math.round(from.toMillis() / 1000), end: Math.round(to.toMillis() / 1000)};
	}

	private calculate(): DateTimeRange {
		switch (this._timeframe) {
			case Timeframes.Last7days:
				return this.lastXDays(7);
			case Timeframes.Last14days:
				return this.lastXDays(14);
			case Timeframes.Last30days:
				return this.lastXDays(30);
			case Timeframes.ThisWeek:
				return this.thisPeriod("week");
			case Timeframes.ThisMonth:
				return this.thisPeriod("month");
			case Timeframes.LastWeek:
				return this.lastPeriod("week");
			case Timeframes.LastMonth:
				return this.lastPeriod("month");
			case Timeframes.AllTime:
				return {
					start: 0,
					end:   Math.round(DateTime.fromMillis(this._startTime * 1000).endOf("day").toMillis() / 1000)
				};
			default:
				return {
					start: 0,
					end:   Math.round(DateTime.fromMillis(this._startTime * 1000).endOf("day").toMillis() / 1000)
				};
		}
	}
}

export interface TimeframeResult {
	from: number,
	to: number
}

export enum Timeframes {
	Last7days = "Last7days",
	Last14days = "Last14days",
	Last30days = "Last30days",
	ThisWeek = "ThisWeek",
	LastWeek = "LastWeek",
	ThisMonth = "ThisMonth",
	LastMonth = "LastMonth",
	AllTime = "AllTime"
}
