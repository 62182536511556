<template>
  <div class="flex flex-col mt-6">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  scope="col">
                Client
              </th>
              <th
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  scope="col">
                Action
              </th>
              <th
                  class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  scope="col">
                Date
              </th>
            </tr>
            </thead>
            <tbody
                v-if="loadedFirstTime && paginatorInfo.total > 0"
                class="bg-white divide-y divide-gray-200">
            <router-link
                v-for="automationAction in automationActions"
                :key="automationAction.id"
                v-slot="{navigate,href,route}"
                :to="{name: routeList.client.show, params:{id: automationAction.affected.id}}"
                custom>
              <tr
                  role="link"
                  class="cursor-pointer group hover:bg-blue-50"
                  @click="navigate">
                <td class="px-6 py-4 whitespace-nowrap">
                  <router-link
                      :to="{name: routeList.client.show, params:{id: automationAction.affected.id}}"
                      class="flex items-center hover:text-blue-600 group-hover:text-blue-600 "
                      tag="div">
                    <div class="w-8 h-8 block">
                      <client-avatar
                          :client="automationAction.affected"
                          class="w-full h-full" />
                    </div>
                    <div class="grow ml-2">
                    <span class="">{{ automationAction.affected.first_name }} {{
                        automationAction.affected.last_name
                                   }}</span>
                      <div class="text-sm text-gray-600">
                        <client-status-text :client="automationAction.affected" />
                      </div>
                    </div>
                  </router-link>
                </td>
                <td
                    v-if="automation.tags_add"
                    class="px-6 py-4 whitespace-nowrap">
                  <span class="text-sm text-green-900">Added tag: </span>
                  <span class="badge-tag-green">{{ automationAction.related.name }}</span>
                </td>
                <td
                    v-if="!automation.tags_add"
                    class="px-6 py-4 whitespace-nowrap">
                  <span class="text-sm text-red-900">Removed tag: </span>
                  <span class="badge-tag-red">{{ automationAction.related.name }}</span>
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                  <timestamp-to-date
                      :timestamp="automationAction.created_at"
                      class="text-gray-900"
                      has-time
                      natural-date />
                  <time-ago
                      :timestamp="automationAction.created_at"
                      class="text-gray-500 block" />
                </td>
              </tr>
            </router-link>
            </tbody>
            <tbody
                v-if="!loadedFirstTime && loading"
                class="bg-white">
            <tr>
              <td colspan="3">
                <div class="flex items-center justify-center h-48">
                  <loader class="w-8 h-8 text-gray-500" />
                </div>
              </td>
            </tr>
            </tbody>
            <tbody
                v-if="!loading && paginatorInfo.total === 0"
                class="bg-white">
            <tr>
              <td colspan="3">
                <div class="flex items-center justify-center h-48">
                  <div class="text-center">
                    <div class="text-xl text-bold text-gray-600">
                      Nothing to show!
                    </div>
                    <p class="text-gray-600">
                      This automation has not
                      <span v-text="automation.tags_add ? 'added':'removed'" /> any
                      tags yet
                    </p>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
            <tfoot
                v-if="loadedFirstTime && paginatorInfo.total > 0 && paginatorInfo.lastPage > 1"
                class="bg-gray-100">
            <tr>
              <td
                  class="p-4 text-right"
                  colspan="3">
                <pagination
                    v-if="automationActions && paginatorInfo.lastPage > 1"
                    :pagination="paginatorInfo"
                    class="flex items-center justify-between text-gray-700 text-sm"

                    @setPage="paginationData.page = $event">
                  <div
                      v-if="loading"
                      class="inline-block w-8 h-8 mr-2 text-gray-400">
                    <loader />
                  </div>
                </pagination>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import ClientAvatar                                                                                                                                                                       from "@/components/client/ClientAvatar.vue";
import TimeAgo                                                                                                                                                                            from "@/components/layout/TimeAgo.vue";
import TimestampToDate                                                                                                                                                                    from "@/components/layout/TimestampToDate.vue";
import Pagination                                                                                                                                                                         from "@/components/layout/Pagination.vue";
import Loader                                                                                                                                                                             from "@/components/layout/Loader.vue";
import ClientStatusText                                                                                                                                                                   from "@/components/client/ClientStatusText.vue";
import {QLAutomationActionsQueryVariables, QLFragmentAutomationActionTagsFragment, QLFragmentFullAutomationFragment, QLPaginatorFragmentFragment, QLSortOrder, useAutomationActionsQuery} from "@/graphql/queries/ql/composables";
import {computed, reactive, ref, toRef, useAttrs, useSlots}                                                                                                                               from "vue";
import routesList                                                                                                                                                                         from "@/composables/routesList";

interface Props {
  automation: QLFragmentFullAutomationFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
const automation = toRef(props, "automation");
const {routeList} = routesList();

const paginationData = reactive({
                                  page:    1,
                                  perPage: 25
                                });

const loadedFirstTime = ref(false);

const apolloVariables = computed((): QLAutomationActionsQueryVariables => {
  return {
    page:    paginationData.page,
    first:   paginationData.perPage,
    orderBy: [{column: "created_at", order: QLSortOrder.DESC}],
    id:      automation.value.id.toString()
  };
});

const actionsQuery = useAutomationActionsQuery(apolloVariables);

const loading = computed(() => {
  if (actionsQuery.loading.value === true) {
    return true;
  }
  return false;
});

const paginatorInfo = ref<QLPaginatorFragmentFragment>({
                                                         firstItem:    0,
                                                         lastItem:     0,
                                                         count:        0,
                                                         currentPage:  0,
                                                         hasMorePages: false,
                                                         lastPage:     0,
                                                         perPage:      0,
                                                         total:        0
                                                       });
const automationActions = ref<QLFragmentAutomationActionTagsFragment[]>([]);

actionsQuery.onResult((result) => {
  if (result.loading === false) {
    paginatorInfo.value = result.data.automation_actions.paginatorInfo;
    automationActions.value = result.data.automation_actions.data;
    loadedFirstTime.value = true;
  }
});

</script>
