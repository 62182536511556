import {RouteRecordRaw}           from "vue-router";
import Routes                     from "@/router/Routes";
import MissingStudio              from "@/views/user/MissingStudio.vue";
import CurrentStudioBillingIssues from "@/views/user/CurrentStudioBillingIssues.vue";

export const UserRoutes: RouteRecordRaw[] = [
	{
		path:      "/missing-studio",
		component: MissingStudio,
		name:      Routes.missingStudio,
		meta:      {
			requiresAuth: true,
			title:        "Missing Studio"
		}
	},
	{
		path:      "/billing-issues",
		component: CurrentStudioBillingIssues,
		name:      Routes.billingIssues,
		meta:      {
			requiresAuth: true,
			title:        "Billing Issues"
		}
	}
];
