<template>
  <page-title
      section="Settings"
      title="Manage Segments" />
  <div class="container mx-auto grow ">
    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/4 shrink-0">
        <div class="mt-1 text-gray-600 text-sm space-y-6">
          <p>
            Manage your segments using this segment management tool! For more information on managing segments and
            making segments visible on your dashboard, please refer to the help article:
            <a
                class="text-link"
                href="https://docs.loopspark.com/settings/manage_segments/"
                target="_blank">Manage
                                Segments</a>
          </p>
          <p>
            Segments can be re-ordered by dragging and dropping them in the order you would like them to appear in your
            client list and dashboard.
          </p>
          <p>
            <strong>Dashboard Segments:</strong>
            Your dashboard displays 10 segments. All segments you wish to display on your dashboard must be marked as
            <span class="bg-gray-200 text-gray-900 px-2 inline-block rounded-full whitespace-nowrap">visible on dash board</span>.
            If you have more than 10 segments marked as
            <span class="bg-gray-200 text-gray-900 px-2 inline-block rounded-full whitespace-nowrap">visible on dash board</span>,
            only the first 10, as
            ordered on your Manage Segments page, will display.
          </p>
          <p>
            <strong>Archiving Segments:</strong>
            Segments can also be "archived" which will remove the segment from appearing on your dashboard and client
            list page. Archived segments will still be saved incase you want to use them in the future. Archiving
            segments is a good way to remove "clutter" from your list of custom segments, while still preserving the
            segment/report for future use.
          </p>
        </div>
      </div>
      <div
          v-if="loading"
          class="flex-1">
        <div class="w-full h-32 flex items-center justify-center">
          <loader class="w-12 h-12 text-gray-700 animate-pulse" />
        </div>
      </div>
      <div
          v-if="!loading && usableSegments.length === 0"
          class="flex-1 flex items-center justify-center">
        <empty class="max-w-xl">
          <template #title>
            You don't have any segment yet
          </template>
          <template #subtitle>
            You can create your custom segments in the
            <router-link
                :to="{name: routeList.clients.index}"
                class="text-link">
              Clients page
            </router-link>
          </template>
        </empty>
      </div>
      <div
          v-if="!loading && usableSegments.length > 0"
          class="flex-1"
          @dragend="dragFns.onDragOver">
        <div class="grid grid-cols-2 gap-4">
          <div class="rounded-lg py-4">
            <div class="text-gray-700 font-medium tracking-wide ">
              Active segments
            </div>

            <div
                class="mt-4 drop-zone "
                @dragover.prevent
                @dragenter.prevent>
              <div class="-mt-2">
                <div
                    v-for="(item,index) in myVersions.myActive"
                    :key="item.id">
                  <div
                      class="h-2 w-full block"
                      :class="{'available-drop': dragStatus.over.index == index && dragStatus.over.active}"
                      @drop="onDrop($event, true, index)"
                      @dragover.prevent
                      @dragleave.prevent="dragFns.onDragEnd"
                      @dragenter.prevent="dragFns.onDragEnter(index, true)" />
                  <div
                      class="rounded shadow bg-white p-2 group cursor-move"
                      draggable="true"
                      @dragstart="dragFns.startDrag($event, item)">
                    <div class="inline-flex items-center text-sm w-full">
                      <svg-icon
                          name="dragorder"
                          class="w-4 h-4 text-gray-300 group-hover:text-black" />
                      <div class="pl-2 grow ">
                        {{ item.name }}
                      </div>
                      <div
                          v-if="inDashboard.indexOf(item.id) !== -1"
                          class="pl-2">
                        <svg-icon
                            aria-tooltip="Visible in the dashboard"
                            class="w-4 h-4 text-green-600 group-hover:text-black"
                            name="dashboard" />
                      </div>
                      <div
                          v-if="item.visible_dashboard && !item.archived && inDashboard.indexOf(item.id) === -1"
                          class="pl-2">
                        <svg-icon
                            aria-tooltip="Only 10 segments can be made visible on dashboard. To make this segment visible on your dashboard, either re-order the segment above other dashboard-visible segments, or remove other segments from dashboard visibility."
                            class="w-4 h-4 text-gray-300 group-hover:text-black"
                            name="dashboard" />
                      </div>
                    </div>
                  </div>
                  <div
                      v-if="index == myVersions.myActive.length - 1"
                      class="h-2 w-full"
                      :class="{'available-drop': dragStatus.over.index == index +1 && dragStatus.over.active}"
                      @drop="onDrop($event, true, index+1)"
                      @dragover.prevent
                      @dragleave.prevent="dragFns.onDragEnd"
                      @dragenter.prevent="dragFns.onDragEnter(index+1, true)" />
                </div>
              </div>
              <div
                  v-if="myVersions.myActive.length === 0 && dragStatus.started"
                  class="h-10 w-full rounded bg-gray-200"
                  @drop="onDrop($event, true, 0)" />
            </div>
          </div>
          <div class="rounded-lg py-4">
            <div class="text-gray-700 font-medium tracking-wide ">
              Inactive segments
            </div>

            <div
                class="mt-4 drop-zone "
                @drop="onDrop($event, false, 0)"
                @dragover.prevent
                @dragenter.prevent>
              <div
                  v-for="item in myVersions.myInactive"
                  :key="item.id">
                <div
                    class="rounded shadow p-2 bg-white group cursor-move mb-2"
                    draggable="true"
                    @dragstart="dragFns.startDrag($event, item)">
                  <div class="inline-flex items-center text-sm">
                    <svg-icon
                        name="dragorder"
                        class="w-4 h-4 text-gray-300 group-hover:text-black" />
                    <div class="pl-2">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                  class="rounded shadow w-full bg-gray-50"
                  :class="{'h-8': dragStatus.started}" />
            </div>
          </div>
        </div>

        <div
            v-if="hasChanges"
            class="flex justify-end">
          <v-button
              :disabled="!hasChanges || saving"
              :loading="saving"
              class="btn btn-loopspark"
              @click="saveChanged">
            Save changes
          </v-button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.available-drop {
  @apply h-10 bg-gray-200;
}
</style>
<script lang="ts" setup>
import VButton                          from "@/components/layout/VButton.vue";
import SvgIcon                          from "@/components/SvgIcon.vue";
import {computed, reactive, ref, watch} from "vue";
import {
  QLFragmentSmallSegmentFragment, useReorderSegmentMutation, useUsableSegmentsQuery
}                                       from "@/graphql/queries/ql/composables";
import {
  useResult
}                                       from "@vue/apollo-composable";
import dragDropComposable               from "@/composables/dragDropComposable";
import Loader                           from "@/components/layout/Loader.vue";
import Empty                            from "@/components/layout/Empty.vue";
import routesList                       from "@/composables/routesList";
import PageTitle                        from "@/components/navbar/PageTitle.vue";

const {routeList} = routesList();

const saving = ref(false);
const toSave = ref(0);

const {
  status: dragStatus,
  dragFns
} = dragDropComposable();

const {result, loading} = useUsableSegmentsQuery();
const usableSegments = useResult(result, [], data => data.usable_segments);

const myVersions = reactive({
                              myActive:   [],
                              myInactive: []
                            });

watch(usableSegments, () => {
  const sortAndOrder = (segments: QLFragmentSmallSegmentFragment[]) => {
    return segments.sort((a, b) => a.order > b.order ? 1 : -1)
                   .map((i, index) => {
                     const tst = {...i};
                     tst.order = index;
                     return tst;
                   });
  };
  myVersions.myActive = sortAndOrder(usableSegments.value.slice(0).filter((i) => !i.archived));
  myVersions.myInactive = sortAndOrder(usableSegments.value.slice(0).filter((i) => i.archived));
});

const inDashboard = computed(() => {
  return myVersions.myActive.slice(0).filter((i) => i.visible_dashboard && !i.archived).slice(0, 10).map((i) => i.id);
});

const sortMyActive = () => {
  myVersions.myActive.sort((a, b) => a.order > b.order ? 1 : -1)
            .map((i, index) => {
              i.order = index;
              return i;
            });
};

const findOnOriginalArray = (id: string) => {
  return usableSegments.value.find(item => item.id === id);
};

const hasChangedItem = (item): boolean => {
  let past = findOnOriginalArray(item.id);
  if (past) {
    if (past.order != item.order || past.archived != item.archived) {
      return true;
    }
  }
  return false;
};

const hasChanges = computed(() => {
  for (const checkItem of myVersions.myActive) {
    if (hasChangedItem(checkItem)) {
      return true;
    }
  }

  for (const checkItem of myVersions.myInactive) {
    if (hasChangedItem(checkItem)) {
      return true;
    }
  }

  return false;
});

const onDrop = (evt, active, order) => {
  const itemID = evt.dataTransfer.getData("itemID");
  let item = myVersions.myActive.find(item => item.id == itemID);
  if (!item) {
    item = myVersions.myInactive.find(item => item.id == itemID);
  }


  if (!active && item.archived) {
    // do nothing because archived on archived
    dragFns.endDragging();
    return;
  }

  // from active to active

  if (!item.archived && active) {
    // if order = (index || index+1) => no move
    if (item.order == order || item.order + 1 == order) {
      // do nothing
      dragFns.endDragging();
      return;
    }

    const idWithSameOrder = myVersions.myActive.find(item => item.order == order);
    const indexWithSameOrder = myVersions.myActive.indexOf(idWithSameOrder);

    myVersions.myActive = myVersions.myActive.map((inArrayItem, index) => {
      if (inArrayItem.id === item.id) {
        inArrayItem.order = order;
        return inArrayItem;
      }
      if (index >= indexWithSameOrder) {
        inArrayItem.order += 1;
      }

      return inArrayItem;
    });

    sortMyActive();
    dragFns.endDragging();
    return;

  }

  // from inactive to active
  if (item.archived && active) {
    // move everything down of 1
    const idWithSameOrder = myVersions.myActive.find(item => item.order == order);
    const indexWithSameOrder = myVersions.myActive.indexOf(idWithSameOrder);

    myVersions.myActive = myVersions.myActive.map((inArrayItem, index) => {
      if (index >= indexWithSameOrder) {
        inArrayItem.order += 1;
      }

      return inArrayItem;
    });
    // remove the item from the archived
    const indexOfArchived = myVersions.myInactive.indexOf(item);
    myVersions.myInactive.splice(indexOfArchived, 1);

    // add the item to the active list
    item.order = order;
    item.archived = false;
    myVersions.myActive.push(item);
    sortMyActive();

    dragFns.endDragging();
    return;


  }

  // from active to inactive
  if (!item.archived && !active) {
    // remove the item from the active
    const indexOfItem = myVersions.myActive.indexOf(item);
    myVersions.myActive.splice(indexOfItem, 1);
    item.archived = true;
    myVersions.myInactive.push(item);
    sortMyActive();

    dragFns.endDragging();
    return;
  }


  sortMyActive();
  dragFns.endDragging();
};
const {mutate, onDone} = useReorderSegmentMutation({});
onDone(() => {
  toSave.value -= 1;
  if (toSave.value <= 0) {
    saving.value = false;
  }
});
const saveOne = (item) => {
  mutate({
           id:       item.id,
           order:    item.order,
           archived: item.archived
         });
};

const saveChanged = () => {
  saving.value = true;
  let changed = [];
  for (const checkItem of myVersions.myActive) {
    if (hasChangedItem(checkItem)) {
      changed.push(checkItem);
    }
  }

  for (const checkItem of myVersions.myInactive) {
    if (hasChangedItem(checkItem)) {
      changed.push(checkItem);
    }
  }
  toSave.value = changed.length;

  changed.forEach((item) => {
    saveOne(item);
  });
};

</script>
