import {createApp, h, provide, ref} from "vue";
import App                          from "./App.vue";
import "@/assets/css/index.css";

import {MyBugsnag}     from "@/classes/boot/bugsnag";
import {apolloClient}  from "@/graphql/apollo";
import {firebaseInit}  from "@/classes/boot/firebase";
import {ApolloClients} from "@vue/apollo-composable";
import router          from "@/router";
import {vblur}         from "@/classes/vue-filters/directives/vblur";
import {vfocus}        from "@/classes/vue-filters/directives/vfocus";
import {hoist}         from "@/classes/vue-filters/directives/hoist";
import {vMaska}         from "maska";
import config          from "@/config";
import VueMatomo       from 'vue-matomo';
import matomoConfig    from "@/classes/boot/matomo";
import VueTailwindDatepicker from 'vue-tailwind-datepicker'


const bugs = MyBugsnag.getInstance();

firebaseInit();

const conf = ref(config);

window.ls = {version: import.meta.env.VITE_APP_VERSION, config: conf};

const app = createApp({
	                      setup() {
		                      provide(ApolloClients, {
			                      default: apolloClient
		                      });
	                      },

	                      render: () => h(App)
                      });

app.directive("blur", vblur);
app.directive("focus", vfocus);
app.directive("maska", vMaska);
app.directive("hoist", el => hoist(el));

const bugsnagVue = bugs.bugsnag().getPlugin("vue");
app.use(bugsnagVue);
app.use(router);
app.use(VueMatomo, matomoConfig(router));
// @ts-ignore
app.use(VueTailwindDatepicker);

router.isReady().then(() => { console.log('router ready ' + Date.now());app.mount("#app")});

