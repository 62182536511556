import PackageName           from "hm-filters/filters/packageNameFilter";
import PackageUsedCount      from "hm-filters/filters/packageUsedCountFilter";
import PackageExpiring       from "hm-filters/filters/packageExpiringFilter";
import PackageStarting       from "hm-filters/filters/packageStartingFilter";
import PackageRemaining      from "hm-filters/filters/packageRemainingFilter";
import PackageActive         from "hm-filters/filters/packageActiveFilter";
import PackageCancelled      from "hm-filters/filters/packageCancelledFilter";
import PackagePurchaseDate   from "hm-filters/filters/packagePurchaseDateFilter";
import PackageLastUsedFilter from "hm-filters/filters/PackageLastUsedFilter";
import PackageEndDateFilter  from "hm-filters/filters/PackageEndDateFilter";
import {PackageCountFilter}  from "hm-filters/filters/PackageCountFilter";

export const PackageFilters = [
	new PackageName("Purchased", {"field": "uses"})
		.setDescription(
			"Package Purchased will filter clients that have, or have not purchased your selected package(s)."),

	new PackageUsedCount("Used Count", {"field": "used_count"})
		.hideOnClubready()
		.setDescription(
			"Package Used Count will filter clients with packages that have been used a desired number of times."),

	new PackageExpiring("Expiration Date", {"field": "expiring"})
		.setDateFilter()
		.setDescription(
			"Package Expiration Date will filter users that have (or have had) a package with an expiration date of your choice."),

	new PackageStarting("Start Date", {"field": "active_at"})
		.setDateFilter()
		.setDescription(
			"Package Start Date will filter clients that have an active package that became active (started) in the selected timeframe. This is a great filter to use for creating drip campaigns that target users throughout the course of their Intro offers, Intro Month Packages etc."),

	new PackageRemaining("Remaining Count",
	                     {"field": "remaining_count"})
		.hideOnClubready()
		.setDescription(
			"Package Remaining Count will filter clients that have an active package that has the number of remaining classes available for use that you select."),

	new PackageActive("Status", {"field": "active"})
		.setDescription(
			"Package Status will filter users that currently have an active package, or that do not currently have active package."),

	new PackageCancelled("Cancelled", {"field": "cancelled"})
		.hideOnMindbody()
		.setDescription("Package Cancelled will filter users that have cancelled a package"),

	new PackagePurchaseDate("Purchase Date", {"field": "payment_at"})
		.setDateFilter()
		.setDescription(
			"Package Purchase Date will filter clients that have purchased a package within a selected timeframe. This filter is most commonly used in a rule combined with the filter Package Purchased, which will apply a package purchase date filter to a selected list of packages types"),

	new PackageLastUsedFilter("Last Used Date", {"field": "last_used_at"})
		.setDateFilter()
		.setDescription(
			"Last Used Date will calculate the last date a specific package was used to attend a class or appointment. This filter will almost always need to be contained in the same rule as Package Purchased to define which package was used, and the date it was last used."),

	new PackageEndDateFilter("Inactive Date", {"field": "ended_at"})
		.setDateFilter()
		.setDescription(
			"Package Inactive Date will calculate the date when a package became inactive either due to expiration OR last credit was used."),

	new PackageCountFilter("Count", {"field": "package_count"})
		.setDescription(
			"Package Count filters by the number of packages a client has purchased that meet all other package filters contained in that rule.")
];
