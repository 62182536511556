<template>
  <page-title
      title="Edit Automation"
      section="Automations" />
  <div class="container mx-auto grow">
    <div
        v-if="loading"
        class="w-full flex items-center justify-center h-64">
      <loader class="w-12 h-12 text-gray-500" />
    </div>

    <automation-form-container
        v-if="!loading"
        v-model:automation="automation"
        v-model:errors="errors"
        :saving="saving"
        class="grow"
        @save="saveAutomation" />
  </div>
</template>
<script lang="ts" setup>

import AutomationFormContainer                               from "@/components/automations/Create/AutomationFormContainer.vue";
import Loader                                                from "@/components/layout/Loader.vue";
import {useRouter}                                           from "vue-router";
import {useAutomationQuery, useUpdateAutomationFullMutation} from "@/graphql/queries/ql/composables";
import {computed, ref, toRef, watch}                         from "vue";
import {useResult}                                           from "@vue/apollo-composable";
import {deepCopy}                                            from "@/classes/helpers/DeepCopy";
import routesList                                            from "@/composables/routesList";
import $toast                                                from "@/composables/toast";
import {
  fillValidationErrorsAndToast
}                                                            from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import AutomationDecorator                                   from "@/composables/decorators/AutomationDecorator";
import {SparkErrors}                                         from "@/classes/utils/SparkFormErrors";
import PageTitle                                             from "@/components/navbar/PageTitle.vue";

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {});
const router = useRouter();

const automationId = toRef(props, "id");
const saving = ref(false);
const errors = ref<SparkErrors>({});

const automationQuery = useAutomationQuery({
                                             id: automationId.value
                                           });

const automationQL = computed(() => automationQuery.result.value?.automation ?? null);


const automation = ref(null);

watch(automationQL, (data) => {
  if(!saving.value) {
    automation.value = cleanAutomation(deepCopy(data));
  }
});

const cleanAutomation = (data) => {
  if (data.email_content && data.email_content.json) {
    if (typeof data.email_content.json !== "object") {
      data.email_content.json = JSON.parse(data.email_content.json);
    }
  }
  return data;
};

const {loading} = automationQuery;

const mut = useUpdateAutomationFullMutation({});

mut.onDone((d) => {
  saving.value = false;
  const finalRoute = {
    name:   routesList().routeList.automations.show.index,
    params: {id: d.data.UpdateAutomationFull.id}
  };
  $toast({
           type:         "success",
           message:      "The automation was updated successfully",
           linkRouteObj: finalRoute,
           linkText:     "View your automation"
         });

  router.push(finalRoute);
});

mut.onError((err) => {
  fillValidationErrorsAndToast(err, errors);
  saving.value = false;
});

const saveAutomation = () => {
  saving.value = true;
  const toPost = AutomationDecorator(automation).computed.toPost;

  mut.mutate({automation: toPost, id: automation.value.id});
};

// defineExpose({saveAutomation});
</script>
