<template>
  <div v-if="canExport">
    <div
        :aria-disabled="isExporting"
        class="inline-block cursor-pointer border border-transparent text-sm py-2 px-2 font-medium leading-none text-blue-900 btn-transition hover:bg-white hover:border-blue-100 hover:text-blue-600 rounded"
        role="button"
        :class="{'animate-pulse': isExporting, 'cursor-not-allowed': isExporting}"
        @click="exportNow">
      <div class="inline-flex items-center">
        <svg-icon
            v-if="!isExporting"
            name="download"
            class="w-4 h-4" />
        <loader
            v-if="isExporting"
            class="w-4 h-4" />
        <span class="pl-1">Export</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import meQuery                                                         from "@/composables/queries/meQuery";
import Loader                                                          from "@/components/layout/Loader.vue";
import SvgIcon                                                         from "@/components/SvgIcon.vue";
import {computed, ref, useAttrs, useSlots}                             from "vue";
import {QLUnsubscribeMediumEnum, useExportClientsUnsubscribedMutation} from "@/graphql/queries/ql/composables";
import $toast                                                          from "@/composables/toast";

interface Props {
  isEmail: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const {me} = meQuery();
const isExporting = ref(false);

const canExport = computed(() => {
  return me.value && me.value.policy.export_clients;
});

const exportMutation = useExportClientsUnsubscribedMutation({});

const toastError = () => {
  $toast({
           type:         "error",
           message:      "There was a problem with your request. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
};

exportMutation.onDone((res) => {
  isExporting.value = false;
  if (res.data.ExportClientsUnsubscribed.status === false) {
    toastError();
  } else {
    $toast({
             type:         "success",
             message:      "The report is being created. You will receive a notification once it's ready to download",
             linkRouteObj: null,
             linkText:     null
           });
  }
});

exportMutation.onError(() => {
  isExporting.value = false;
  toastError();
});

const exportNow = () => {
  isExporting.value = true;
  exportMutation.mutate({
                          medium: props.isEmail ? QLUnsubscribeMediumEnum.Email : QLUnsubscribeMediumEnum.SMS
                        });
};
</script>
