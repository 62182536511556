import {UserRolePolicy}                           from "@/classes/policy/UserRolePolicy";
import {QLFragmentUserMeFragment, QLUserRoleEnum} from "@/graphql/queries/ql/composables";

export const LocalResolvers = {
	User: {
		policy:       (user: QLFragmentUserMeFragment, _args, {cache}) => {

			const currentRole = findRole(user);
			const is_owner = currentRole === QLUserRoleEnum.OWNER;
			const is_manager = currentRole === QLUserRoleEnum.MANAGER;
			const is_admin = currentRole === QLUserRoleEnum.ADMIN;
			const teamApproved = user.current_team && user.current_team.approved;

			return {
				__typename:             "UserPolicy",
				role:                   findRole(user),
				is_owner,
				is_manager,
				is_admin,
				is_main_owner:          isMainOwner(user),
				add_tags:               is_manager || is_owner || is_admin,
				staff_invite:           is_owner || is_admin,
				staff_remove:           is_owner || is_admin,
				staff_change:           is_owner || is_admin,
				edit_automations:       (is_owner || is_admin || is_manager) && teamApproved,
				create_automations:     (is_owner || is_admin || is_manager) && teamApproved && (user.current_team && user.current_team.can_create_automations),
				edit_forms:             (is_owner || is_admin || is_manager) && teamApproved,
				create_forms:           (is_owner || is_admin || is_manager) && teamApproved,
				export_clients:         is_owner || is_admin || is_manager,
				create_email_templates: is_owner || is_admin || is_manager,
				edit_email_templates:   is_owner || is_admin || is_manager,
				view_email_templates:   is_owner || is_admin || is_manager,
				send_messages:          teamApproved
			};
		},
		current_role: (user: QLFragmentUserMeFragment, _args, {cache}) => {
			const currentRole = findRole(user);
			const ret = {
				role:     QLUserRoleEnum.MEMBER,
				at_least: {
					member:  true,
					manager: false,
					admin:   false,
					owner:   false
				}
			};
			if (!currentRole) {
				return ret;
			}
			ret.role = currentRole;
			ret.at_least.member = UserRolePolicy.isAtLeast(currentRole, QLUserRoleEnum.MEMBER);
			ret.at_least.manager = UserRolePolicy.isAtLeast(currentRole, QLUserRoleEnum.MANAGER);
			ret.at_least.admin = UserRolePolicy.isAtLeast(currentRole, QLUserRoleEnum.ADMIN);
			ret.at_least.owner = UserRolePolicy.isAtLeast(currentRole, QLUserRoleEnum.OWNER);
			return ret;
		}
	}
};

function findRole(user: QLFragmentUserMeFragment): QLUserRoleEnum | null {
	if (!user.current_team) {
		return null;
	}

	return user.current_team.users.find((i) => i.id === user.id)?.role;
}

function checkRole(user, role: QLUserRoleEnum) {
	return user.current_team && user.current_team.users.filter((userWithRole) => userWithRole.id === user.id && userWithRole.role === role).length > 0;
}

function isMainOwner(user: QLFragmentUserMeFragment) {
	return user.current_team && user.current_team.owner_id === user.id;
}
