import {BookingEventGroup} from "hm-filters/filters/partials/BookingEventGroup";
import LocationOperator    from "hm-filters/operators/LocationOperator";

export class BookingEventLocationFilter extends BookingEventGroup {

	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new LocationOperator("=", "is"),
			new LocationOperator("!=", "is not")
		];
	}

	get type(): string {
		return "BookingEventLocation";
	}
}
