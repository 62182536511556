<template>
  <router-link
      :to="{name: routeList.client.show, params:{id: visit.client.id}}"
      class="group block">
    <div class="p-4 group-hover:bg-blue-50 flex justify-between ">
      <div class="flex items-center">
        <client-avatar
            :client="visit.client"
            class="w-8 h-8" />
        <div class="ml-2">
          <div class="text-sm text-gray-900 group-hover:text-blue-500">
            {{ visit.client.first_name }}
            {{ visit.client.last_name }}
          </div>
          <div
              v-if="!!visit.package"
              class="text-xs text-gray-500">
            {{ visit.package.name }}
          </div>
          <div
              v-if="!visit.package"
              class="text-xs text-gray-500">
            -
          </div>
        </div>
      </div>
      <div
          class="flex items-center space-y-2"
          :class="{'tag-muted': muted}">
        <div
            v-if="decorator.isAbsent"
            class="visit-tag absent">
          Absent
        </div>
        <div
            v-if="decorator.isLateCancelled"
            class="visit-tag absent">
          Late cancel
        </div>
        <div
            v-if="decorator.isEarlyCancelled"
            class="visit-tag early">
          Early cancel
        </div>
        <div
            v-if="decorator.isSignedIn"
            class="visit-tag signed">
          Signed In
        </div>
      </div>
    </div>
    <slot />
  </router-link>
</template>
<style scoped>
.visit-tag {
  @apply text-xs rounded-full px-2 py-1 font-medium
}

.visit-tag.absent {
  @apply text-white bg-red-600 border border-transparent;
}

.visit-tag.early {
  @apply border border-yellow-400 text-yellow-900 bg-yellow-50;
}

.visit-tag.signed {
  @apply border border-green-200 text-green-900 bg-green-100;
}

.tag-muted > .visit-tag.absent {
  @apply text-red-600 bg-transparent ;
}

.tag-muted > .visit-tag.early {
  @apply text-yellow-600 bg-transparent;
}

.tag-muted > .visit-tag.signed {
  @apply text-green-600 bg-transparent;
}

.tag-muted > .visit-tag {
  @apply border-transparent px-0;
}

</style>
<script lang="ts" setup>

import ClientAvatar                                                                 from "@/components/client/ClientAvatar.vue";
import {toRef, useAttrs, useSlots}                                                  from "vue";
import {QLFragmentVisitWithClientAndPackageFragment, QLSingleClassFragmentFragment} from "@/graphql/queries/ql/composables";
import {VisitDecorator}                                                             from "@/composables/decorators/VisitDecorator";
import routesList                                                                   from "@/composables/routesList";

interface Props {
  singleEvent: QLSingleClassFragmentFragment;
  visit: QLFragmentVisitWithClientAndPackageFragment;
  muted?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  muted: false
});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const singleEvent = toRef(props, "singleEvent");
const visit = toRef(props, "visit");
const muted = toRef(props, "muted");
const {routeList} = routesList();

const {computed: decorator} = VisitDecorator(visit, singleEvent);
</script>
