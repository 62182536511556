import MultipleChoiceOperator, {MultipleChoiceOption} from "./MultipleChoiceOperator";
import {OperatorTypes}                                from "hm-filters/operators/OperatorTypes";
import {find}                                         from "lodash";

class DayOfWeekOperator extends MultipleChoiceOperator {
	multipleChoicesData: MultipleChoiceOption[] = [
		{
			"value":   0,
			"display": "Sunday"
		},
		{
			"value":   1,
			"display": "Monday"
		},
		{
			"value":   2,
			"display": "Tuesday"
		},
		{
			"value":   3,
			"display": "Wednesday"
		},
		{
			"value":   4,
			"display": "Thursday"
		},
		{
			"value":   5,
			"display": "Friday"
		},
		{
			"value":   6,
			"display": "Saturday"
		}
	];

	get type(): string {
		return OperatorTypes.DayOfWeek;
	}

	getFormattedOutput(options: MultipleChoiceOption) {
		// return this.multipleChoicesData.map((choices));
		return options.value.map((item) => {
			return this.multipleChoicesData.find((o) => o.value == item).display;
		});
	}
}

export default DayOfWeekOperator;
