<template>
  <filter-group-base-category
      v-model:filter-selected="filterSelected"
      v-model:operator-selected="operatorSelected"
      :category-name="categoryName"
      @step-back="stepBack"
      @added-rule="addedRule($event)"
  >
    <template #title>
      New Filter - Sale
    </template>
    <template #subtitle>
      The Sales filter category contains filters regarding the general sales of packages and/or
      products.<br>
      For more specific filters regarding Packages or Products please refer to the filter
      categories Packages and Products
    </template>
    <template #side-description>
      <div>
        The <span class="fake-filter">Sale</span> filter category won’t be used as much as the
        <span class="fake-filter">Product</span> and <span class="fake-filter">Package</span> filter categories. If
        you’re seeking a
        filter that would, for example: - Find clients that purchased Alo Leggings within the last 30 days, you would
        want to use filters from the <span class="fake-filter">Product History</span> category.
      </div>
    </template>
    <template #filters>
      <slot />
    </template>
  </filter-group-base-category>
</template>
<script lang="ts">
import {singleCategoryComposition} from "@/components/Segment/Edit/Categories/SingleCategories/singleCategoryComposition";

const cat = singleCategoryComposition();
export default {
  components: {
    ...cat.components
  },
  props:      {
    ...cat.props
  },
  emits:      cat.emits,
  setup(props, {emit}) {
    return cat.setup(props, emit);
  }
};
</script>
