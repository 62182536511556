<template>
  <div>
    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Tags
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>
            Client tags provide additional data points to help target, or remove clients from audience segments. Tags
            also provide quick visual reference for your staff to help better understand the client.
          </p>

          <div class="text-base pt-4">
            Examples:
          </div>
          <ul class="space-y-4 pt-4 list-disc list-inside ">
            <li>
              Client has late cancelled more than 5 times. A tag automation can be created to add the tag
              <span class="badge-sm badge-gray">Frequently Cancels</span> so staff can quickly identify the client, and
              other clients, that have issues with cancelling in the allowable timeframe.
            </li>

            <li>
              Client purchases 10 products containing the word “pants” and an automatic tag is added to their profile
              <span class="badge-sm badge-gray">Loves Pants</span>, helping staff recognize clients that are likely to
              purchase similar products in the future.
            </li>
          </ul>
        </div>
      </div>

      <div class="block  flex-1 ">
        <div class="p-4 space-y-6 shadow-md rounded-lg bg-white">
          <div>
            <label
                class="form-label mb-2"
                for=""
            >Action</label>
            <div class="space-y-2">
              <div>
                <label class="inline-flex items-center">
                  <input
                      v-model="tagsAddModel"
                      :value="true"
                      name="radio-add-remove"
                      type="radio"
                  >
                  <div class="ml-2">
                    <label class="block text-sm font-medium">Add tags</label>
                    <span class="block text-gray-500 text-xs">Add the following tag to a client's LoopSpark profile when they enter this automation's audience segment.</span>
                  </div>
                </label>
              </div>
              <div>
                <label class="inline-flex items-center">
                  <input
                      v-model="tagsAddModel"
                      :value="false"
                      name="radio-add-remove"
                      type="radio"
                  >
                  <div class="ml-2">
                    <label class="block text-sm font-medium">Remove tags</label>
                    <span class="block text-gray-500 text-xs">Remove the following tag from a client's LoopSpark profile when they enter this automation's audience segment.</span>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div>
            <label
                class="form-label mb-2"
                for=""
            >Tags</label>

            <smart-select-tags
                v-model="tagsModel"
                :items="items"
                :allow-new="canAddTags"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import {computed}                     from "vue";
import SparkFormErrors, {SparkErrors} from "@/classes/utils/SparkFormErrors";
import SmartSelectTags                from "@/components/layout/Forms/SmartSelectTags.vue";
import {tagsClientsQuery}             from "@/composables/queries/tagsQuery";
import meQuery                        from "@/composables/queries/meQuery";
import currentTeamQuery               from "@/composables/queries/currentTeamQuery";
import useCanAddTags                  from "@/composables/useCanAddTags";

interface Props {
  errors: SparkErrors;
  tags: string[],
  tagsAdd: boolean

}

const props = withDefaults(defineProps<Props>(), {});


const emit = defineEmits(["update:tags", "update:tagsAdd", "update:errors"]);


const { tags: clientTags} = tagsClientsQuery();
const items = computed(() => {
  return clientTags.value.map(item => item.name);
});

const tagsModel = computed({
                             get: () => props.tags,
                             set: (val) => emit("update:tags", val)
                           });

const tagsAddModel = computed({
                                get: () => props.tagsAdd,
                                set: (val) => emit("update:tagsAdd", val)
                              });

const canAddTags = useCanAddTags();


</script>
