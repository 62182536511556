<template>
  <div>

  </div>
</template>
<script lang="ts" setup>
import {onMounted, useAttrs, useSlots} from "vue";

interface Props {
  url: string;
}

const props = defineProps<Props>();

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

onMounted(() => {
  console.log("Redirecting in component");
  window.location.href = props.url;
});
</script>
