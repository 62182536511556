<template>
  <div class="form-input flex items-center justify-start bg-white form-input-parent">
    <input
        v-if="!onlyTime"
        v-model="dateModel"
        type="date"
        class="appearance-none border-0 p-0 form-no-focus "
        :min="minDateModel.date">
    <input
        v-if="!onlyDate"
        v-model="timeModel"
        type="time"
        class="appearance-none border-0 p-0 form-no-focus"
        :min="minDateModel.time">
  </div>
</template>
<style scoped>
input {
  font-size:inherit;
  line-height:inherit;
}
</style>
<script lang="ts" setup>
import {DateTime, DateObjectUnits}        from "luxon";

interface Props {
  minDate?: number;
  onlyTime?: boolean;
  onlyDate?: boolean;
  localTimezone?: string | null;
  modelValue: number | null;
}

const props = withDefaults(defineProps<Props>(), {
  minDate:       0,
  onlyTime:      false,
  onlyDate:      false,
  localTimezone: null
});

import {ref, computed, useSlots, useAttrs} from "vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:modelValue"]);

const getZone = () => {
  return {zone: props.localTimezone ? props.localTimezone : "local"};
};

const getToday = () => {
  return DateTime.fromSeconds(Math.round(Date.now() / 1000), getZone()).set({millisecond: 0});
};

const modelV = computed<DateTime>(() => {
  const secs = props.modelValue > 0 ? Math.round(props.modelValue) : (props.minDate > 0
                                                                      ? props.minDate
                                                                      : Math.round(Date.now() / 1000));

  if (secs != props.modelValue) {
    emit("update:modelValue", secs);
  }

  return DateTime.fromSeconds(secs, getZone());
});

const minDateModel = computed(() => {

  if (props.minDate > 0) {
    const d = DateTime.fromSeconds(props.minDate, getZone()).set({millisecond: 0});
    let time = d.toFormat("HH:mm");
    // should check if it's the same day, otherwise no limit on time
    if (modelV.value !== null) {
      const currentSelected = DateTime.fromSeconds(modelV.value.toSeconds(), getZone()).set({millisecond: 0});
      if (currentSelected.day != d.day || currentSelected.year != d.year || currentSelected.month != d.month) {
        time = null;
      }
    }

    return {
      date: d.toISODate(),
      time: time
    };
  }
  return {
    date: null,
    time: null
  };
});

const setNewDate = (values: DateObjectUnits) => {
  const newSeconds = DateTime.fromSeconds(modelV.value.toSeconds(), getZone())
                             .set(values)
                             .set({millisecond: 0})
                             .toSeconds();
  emit("update:modelValue", Math.round(newSeconds));
};

const dateModel = computed({
                             get: () => {
                               return modelV.value.toISODate();
                             },
                             set: (val) => {
                               const passedDate = !val || val.length === 0 ? getToday() : DateTime.fromISO(val).set({millisecond: 0});
                               setNewDate({
                                            day:   passedDate.day,
                                            month: passedDate.month,
                                            year:  passedDate.year
                                          });
                             }
                           });

const timeModel = computed({
                             get: () => {
                               return modelV.value.toFormat("HH:mm");
                             },
                             set: (val) => {
                               const passedDate = !val || val.length === 0 ? getToday() : DateTime.fromISO(val).set({millisecond: 0});

                               setNewDate({
                                            hour:   passedDate.hour,
                                            minute: passedDate.minute
                                          });
                             }
                           });

</script>
