import ClientGroup            from "hm-filters/filters/partials/client";
import MboClientIndexOperator from "hm-filters/operators/MboClientIndexOperator";

export default class ClientMboIndexFilter extends ClientGroup {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new MboClientIndexOperator("=", "has"),
			new MboClientIndexOperator("!=", "does not have")
		];
	}


	get type(): string {
		return "ClientMboIndexFilter";
	}
}
