import {QLUserRoleEnum} from "@/graphql/queries/ql/composables";

export class UserRolePolicy {

	static roleOrder(role: QLUserRoleEnum): number{
		switch (role) {
			case QLUserRoleEnum.OWNER: return 0;
			case QLUserRoleEnum.ADMIN: return 1;
			case QLUserRoleEnum.MANAGER: return 2;
			case QLUserRoleEnum.MEMBER: return 3;
		}
	}

	static isAtLeast(currentRole: QLUserRoleEnum, atLeast: QLUserRoleEnum): boolean {
		return this.roleOrder(currentRole) <= this.roleOrder(atLeast);
	}


}
