<template>
  <div>
    <smart-select-tags :items="items"
                       v-model="selectedValue"
                       allow-new
                       class="block w-full"
                       focus-on-show
    >
    </smart-select-tags>
  </div>
</template>
<script lang="ts" setup>

import {computed, ref, toRef, watch} from "vue";
import MultipleTextOperator          from "hm-filters/operators/MultipleTextOperator";
import SmartSelectTags               from "@/components/layout/Forms/SmartSelectTags.vue";

interface Props {
  operator: MultipleTextOperator;
  modelValue: any;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:modelValue", "is-valid"]);
const modelValue = toRef(props, "modelValue");
const operator = toRef(props, "operator");
const items = ref([]);

const selectedValue = computed({
                                 get: () => {
                                   if (!modelValue.value) {
                                     return [];
                                   }
                                   return modelValue.value;
                                 },
                                 set: (newVal) => emit("update:modelValue",
                                                       newVal !== null ? newVal : null)
                               });


const isValidCalculation = () => {
  let valid = modelValue.value !== null && modelValue.value.length > 0;
  emit("is-valid", valid);
  return valid;
};

const isValid = computed(() => {
  return isValidCalculation();
});

watch(modelValue, isValidCalculation);
</script>
