<template>
  <div>
    <div class="md:flex items-center md:space-x-8">
      <div class="grow">
        <div class="font-medium">
          {{ quickResponse.title }}
        </div>
        <div class="text-sm mt-2 text-gray-700">
          {{ quickResponse.content }}
        </div>
      </div>
      <div class="text-right shrink-0">
        <v-button :loading="sending"
                  :disabled="sending"
                  class="p-1 px-2  border rounded-lg border-red-100 bg-red-50 text-red-800 hover:bg-red-500 hover:text-white"
                  @click="confirmOpen = true">
          <div class="flex items-center">
            <div class="text-sm pr-1">Delete</div>
            <div>
              <svg-icon
                  name="trash"
                  class="block w-4 h-4 " />
            </div>
          </div>
        </v-button>

      </div>
    </div>
    <confirm-modal v-model:open="confirmOpen"
                   :loading="sending"
                   title="Are you sure?"
                   type="warning"
                   @clicked="deleteNow">
      <template #text>
        <p>Are you sure you want to delete the quick response name <strong>{{ quickResponse.title }}</strong>?</p>
        <p class="italic text-xs">This action is not reversible</p>
      </template>
    </confirm-modal>
  </div>
</template>
<script lang="ts" setup>
import VButton                                                                   from "@/components/layout/VButton.vue";
import SvgIcon                                                                   from "@/components/SvgIcon.vue";
import {ref, toRef, useAttrs, useSlots}                                          from "vue";
import {QLQuickResponse, QuickResponsesDocument, useQuickResponseDeleteMutation} from "@/graphql/queries/ql/composables";
import $toast                                                                    from "@/composables/toast";
import {updateCache}                                                             from "@/composables/queries/mutations/updateCache";
import ConfirmModal                                                              from "@/components/layout/ConfirmModal.vue";

interface Props {
  quickResponse?: QLQuickResponse;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["deleted"]);

const sending = ref(false);
const confirmOpen = ref(false);
const quickResponse = toRef(props, "quickResponse");

const deleteMutation = useQuickResponseDeleteMutation({});

deleteMutation.onDone(() => {
  sending.value = false;
  confirmOpen.value = false;
  $toast({
           type:         "success",
           message:      "Quick Response deleted",
           linkRouteObj: null,
           linkText:     null
         });
});

deleteMutation.onError(() => {
  sending.value = false;

  $toast({
           type:         "error",
           message:      "There was a problem with your request. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const deleteNow = () => {
  sending.value = true;
  deleteMutation.mutate({
                          id: props.quickResponse.id
                        }, {
                          update: (store) => {
                            updateCache.deleteById(props.quickResponse.id,
                                                   QuickResponsesDocument,
                                                   "quick_responses",
                                                   store);
                          }
                        });
};

</script>
