import Filter                         from "../../base-filter";
import config                         from "./config";
import OperatorGenerator              from "../../operatorGenerator";
import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";

export class AppointmentFilter extends Filter {
	smallText = config.appointments.groups.appointment.group;
	group = config.appointments.groups.appointment.group;
	path = ["Appointments", this.group];

	constructor(name: string, options: any) {
		super(config.appointments.filter, options);
		this.setOperators(OperatorGenerator.datesPastOnly(true));
	}

	get type(): string {
		return "AppointmentFilter";
	}
}

export class AppointmentNoShowFilter extends AppointmentFilter {
	smallText = config.appointments.groups.noShow.group;
	group = config.appointments.groups.noShow.group;
	path = ["Appointments", this.group];

	get type(): string {
		return "AppointmentNoShowFilter";
	}
}

export class AppointmentUpcomingFilter extends AppointmentFilter {
	smallText = config.appointments.groups.upcoming.group;
	group = config.appointments.groups.upcoming.group;
	path = ["Appointments", this.group];

	constructor(name: string, options: any) {
		super(config.appointments.filter, options);
		this.clearOperators();

		this.addOperators(OperatorGenerator.datesFutureOnly());
		this.addOperators([new OperatorAsFixedValueOperator("never", "none")]);
	}

	get type(): string {
		return "AppointmentUpcomingFilter";
	}
}
