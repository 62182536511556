import Communication     from "./partials/communication";
import OperatorGenerator from "../operatorGenerator";

class CommunicationHistoryFilter extends Communication {
	constructor(name: string, options: any) {
		super(name, options);

		this.setOperators(OperatorGenerator.datesPastOnly(true, false));
	}

	get type(): string {
		return "CommunicationHistoryFilter";
	}
}

export default CommunicationHistoryFilter;
