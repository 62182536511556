<template>
  <div class="grow container mx-auto">
    <div
        v-if="!form"
        class="h-64 w-full">
      <div class="flex items-center justify-center h-full">
        <loader class="w-12 h-12 text-gray-500" />
      </div>
    </div>
    <div
        v-if="form"
        class="">
      <div class="">
        <div
            v-if="form"
            class="flex flex-wrap">
          <div class="w-full md:w-1/4 mt-6">
            <form-show-sidebar :form="form" />
            <div class="mt-4">
              <ul class="side-menu">
                <li>
                  <router-link
                      v-slot="{ href, navigate, isExactActive }"
                      custom
                      :to="{name: routeList.forms.show.index, params: {id: form.id}}">
                    <a :href="href" @click="navigate" :class="[ isExactActive? 'active':'']">
                      <svg-icon name="dashboard" class="w-4 h-4 inline-block mr-4" />
                      <span>Info</span>
                    </a>
                  </router-link>
                </li>
                <li>
                  <router-link
                      :to="{name: routeList.forms.show.history, params: {id: form.id}}"
                      custom
                      v-slot="{ href,  navigate,  isExactActive }">
                    <a :href="href" @click="navigate" :class="[ isExactActive? 'active':'']">
                      <svg-icon name="list" class="w-4 h-4 inline-block mr-4" />
                      <span>History</span>
                    </a>
                  </router-link>
                </li>
                <li
                    v-if="canEditForm"
                    class="py-2 px-4">
                  <hr>
                </li>
                <li v-if="canEditForm">
                  <router-link :to="{name: routeList.forms.edit, params: {id: form.id}}">
                    <svg-icon name="edit" class="w-4 h-4 inline-block mr-2" />
                    <span>Edit</span>
                  </router-link>
                </li>
                <li v-if="teamsCopiable.length > 0">
                  <button
                      class="side-menu-item "
                      @click="copyDialogOpen = true">
                    <svg-icon name="copy" class="w-4 h-4 inline-block mr-2" />
                    <span class="font-medium">Copy to another studio...</span>
                  </button>
                </li>
                <li v-if="canEditForm">
                  <button
                      class="side-menu-item"
                      @click="openDuplicate">
                    <svg-icon name="stack-duotone" class="w-4 h-4 inline-block mr-2" />
                    <span class="font-medium">Clone...</span>
                  </button>
                </li>
                <li>
                  <button
                      v-if="!form.is_archived"
                      class="side-menu-item text-red-800 hover:bg-red-50"
                      @click="openArchive">
                    <svg-icon name="trash" class="w-4 h-4 inline-block mr-2" />
                    <span class="font-medium">Archive...</span>
                  </button>
                  <button
                      v-if="form.is_archived"
                      class="side-menu-item text-green-800 hover:bg-green-50"
                      @click="openUnarchive">
                    <svg-icon name="unarchive" class="w-4 h-4 inline-block mr-2" />
                    <span class="font-medium">Unarchive...</span>
                  </button>

                </li>
              </ul>
            </div>
          </div>
          <div class="w-full md:w-3/4 mt-6">
            <div class="ml-2 md:ml-6">
              <router-view :form="form" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <form-archive-dialog
        v-if="form"
        v-model:open="archiveDialogOpen"
        :form="form" />
    <form-unarchive-dialog
        v-if="form"
        v-model:open="unArchiveDialogOpen"
        :form="form" />
    <form-duplicate-dialog
        v-if="form"
        v-model:open="duplicateDialogOpen"
        :form="form" />

    <form-copy-dialog v-if="form" :form="form" :teams="teamsCopiable" v-model:open="copyDialogOpen"/>
  </div>
</template>
<script lang="ts" setup>
import {computed, ref, toRef}         from "vue";
import {QLUserRoleEnum, useFormQuery} from "@/graphql/queries/ql/composables";
import routesList                     from "@/composables/routesList";
import {useRouter}         from "vue-router";
import {useResult}         from "@vue/apollo-composable";
import meQuery             from "@/composables/queries/meQuery";
import Loader              from "@/components/layout/Loader.vue";
import FormShowSidebar     from "@/views/Forms/Components/Show/FormShowSidebar.vue";
import SvgIcon             from "@/components/SvgIcon.vue";
import FormArchiveDialog   from "@/views/Forms/Components/Show/FormArchiveDialog.vue";
import FormUnarchiveDialog from "@/views/Forms/Components/Show/FormUnarchiveDialog.vue";
import {echoListener}      from "@/composables/echoListener";
import {EventTypes}        from "@/classes/notifications/EventTypes";
import FormDuplicateDialog from "@/views/Forms/Components/Show/FormDuplicateDialog.vue";
import FormCopyDialog      from "@/views/Forms/Components/Show/FormCopyDialog.vue";

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {});
const formId = toRef(props, "id");
const router = useRouter();
const {routeList} = routesList();
const formQueryVariables = computed(() => ({
  id: formId.value
}));
const formQuery = useFormQuery(formQueryVariables);
formQuery.onError((err) => console.log(err));
const form = computed(() => formQuery.result.value?.form ?? null);
const {loading} = formQuery;

const {me} = meQuery();
const canEditForm = computed(() => {
  if (!me.value) {
    return false;
  }
  return me.value.policy.edit_forms;
});

const archiveDialogOpen = ref(false);
const unArchiveDialogOpen = ref(false);
const duplicateDialogOpen = ref(false);

const openArchive = () => {
  archiveDialogOpen.value = true;
};

const openDuplicate = () => {
  duplicateDialogOpen.value = true;
};

const openUnarchive = () => {
  unArchiveDialogOpen.value = true;
};
const copyDialogOpen = ref(false);

echoListener([EventTypes.FormWasCreated, EventTypes.FormWasUpdated], (ev) => {
  formQuery.refetch();
}, 5000);

const teamsCopiable = computed(() => {
  if(!me.value) return [];

  return me.value.teams.filter(( t ) => {
    return t.addon_forms && (t.role !== QLUserRoleEnum.MEMBER) && t.id != me.value.current_team_id
  })
});

</script>
