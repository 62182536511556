<template>
  <confirm-modal
      v-model:open="openModel"
      :loading="sending"
      type="question"
      close-text="No"
      title="Clone this automation?"
      @close="openModel = false"
      @clicked="performAction">
    <template #button>
      Yes, clone it
    </template>
    <template #text>
      <p>Are you sure you want to create a duplicate of the current automation?</p>
      <div class="text-left pt-4 space-y-4">
        <p>
          Cloning the automation will create an exact replica of all its conditions, actions, and settings. This can be
          beneficial if you wish to use the
          existing automation as a template for a new workflow, saving you the time and effort of setting up similar
          parameters from scratch.
        </p>
        <p>The cloned automation will be a separate entity and can be modified, executed or deleted independently.
           Please ensure this is what you want before proceeding.</p>
      </div>

    </template>
  </confirm-modal>
</template>
<script lang="ts" setup>

import {
  QLCloneAutomationMutationVariables, QLFragmentFullAutomationFragment, useCloneAutomationMutation
}                                          from "@/graphql/queries/ql/composables";
import {computed, ref, useAttrs, useSlots} from "vue";
import $toast                              from "@/composables/toast";
import ConfirmModal                        from "@/components/layout/ConfirmModal.vue";
import AutomationDecorator                 from "@/composables/decorators/AutomationDecorator";
import routesList                          from "@/composables/routesList";

interface Props {
  automation: QLFragmentFullAutomationFragment;
  open: boolean;
}

const props = defineProps<Props>();

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open"]);

const sending = ref(false);
const openModel = computed({
                             get: () => props.open,
                             set: (val) => {
                               emit("update:open", val);
                             }
                           });

const decorated = AutomationDecorator(props.automation);

const closeIt = () => {
  sending.value = false;
  emit("update:open", false);
};

// Mutation

const mut = useCloneAutomationMutation({});
const mutationVariables: QLCloneAutomationMutationVariables = {
  id: props.automation.id
};


mut.onDone((data) => {
  const finalRoute = {
    name:   routesList().routeList.automations.show.index,
    params: {id: data.data.CloneAutomation.id}
  };
  $toast({
           type:         "success",
           message:      "The automation was cloned!",
           linkRouteObj: finalRoute,
           linkText:     'View the cloned automation'
         });

  closeIt();
});

mut.onError(() => {
  $toast({
           type:         "error",
           message:      "There was an error while cloning the automation. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
  sending.value = false;
});

const performAction = () => {

  sending.value = true;
  mut.mutate(mutationVariables);
};

</script>
