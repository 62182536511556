import ClientGroup                                        from "./partials/client";
import ClientTagOperator, {MultipleClientTagOperator}     from "hm-filters/operators/ClientTagOperator";
import FixedValueOperator, {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import ClientTagGroup                                     from "hm-filters/filters/partials/tagGroup";

class TagFilter extends ClientTagGroup {

	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new MultipleClientTagOperator("=", "has"),
			new MultipleClientTagOperator("!=", "does not have"),
			new ClientTagOperator("only", "only").setAloneInFilterGroup(),
			new OperatorAsFixedValueOperator("any", "has any tag"),
			new OperatorAsFixedValueOperator("nothing", "has no tag")
		];
	}

	get type(): string {
		return "TagFilter";
	}

}

export default TagFilter;
