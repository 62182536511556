<template>
  <form
      ref="form"
      @submit.prevent="submitLead">
    <modal
        v-model:open="openModel"
        :loading="saving"
        close-button-text="Close">
      <div class="w-full">
        <h2 class="text-lg ">
          Update lead information
        </h2>
        <div class="mt-4 space-y-4">
          <text-input
              v-model="updateLeadData.first_name"
              :error-message="errors.get('first_name')"
              label="First name" />

          <text-input
              v-model="updateLeadData.last_name"
              :error-message="errors.get('last_name')"
              label="Last name" />

          <text-input
              v-model="updateLeadData.email"
              :error-message="errors.get('email')"
              label="Email"
              type="email" />

          <div>
            <label
                :class="{'has-error': errors.has('mobile_phone')}"
                class="form-label"
                for="">Mobile phone</label>
            <input
                v-model="updateLeadData.mobile_phone"
                v-maska data-maska="+1 (###) ###-####"
                :class="{'has-error': errors.has('mobile_phone')}"
                class="form-input form-input-default mt-1"
                placeholder="ex: +1 (212) 555-1234"
                type="tel">
            <span
                v-if="errors.has('mobile_phone')"
                class="form-error-message">{{
                errors.get("mobile_phone")
                                           }}</span>
          </div>

          <text-input
              v-model="updateLeadData.referred_by"
              :error-message="errors.get('referred_by')"
              label="Referred By" />
        </div>
      </div>
      <template #buttons>
        <v-button
            :disabled="saving"
            :loading="saving"
            class="modal-button modal-button-green"
            type="submit">
          Save it!
        </v-button>
      </template>
    </modal>
  </form>
</template>
<script lang="ts" setup>

import Modal                                                       from "@/components/layout/Modal.vue";
import {computed, reactive, ref, toRaw, useAttrs, useSlots, watch} from "vue";
import VButton                                                     from "@/components/layout/VButton.vue";
import TextInput                                                   from "@/components/layout/Forms/TextInput.vue";
import SparkFormErrors, {SparkErrors}                              from "@/classes/utils/SparkFormErrors";
import {QLClient, useUpdateLeadMutation}                           from "@/graphql/queries/ql/composables";
import $toast                                                      from "@/composables/toast";
import fillValidationErrorsOrToast                                 from "@/composables/queries/mutations/fillValidationErrorsOrToast";

interface Props {
  open: boolean;
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const emit = defineEmits(["update:open"]);
const form = ref(null);
const saving = ref(false);
const errorsRaw = ref<SparkErrors>({});
const errors = computed(() => {
  return new SparkFormErrors(errorsRaw.value);
});
const updateLeadData = reactive({
                                  first_name:   "",
                                  last_name:    "",
                                  email:        "",
                                  mobile_phone: "",
                                  referred_by:  "",
                                  frozen:       false
                                });

function refreshData() {
  updateLeadData.first_name = props.client.first_name;
  updateLeadData.last_name = props.client.last_name;
  updateLeadData.email = props.client.email;
  updateLeadData.mobile_phone = props.client.mobile_phone;
  updateLeadData.referred_by = props.client.referred_by;
  updateLeadData.frozen = props.client.frozen;
}

watch(openModel, () => {
  refreshData();
});

const {mutate, onDone, onError} = useUpdateLeadMutation({});

onDone(() => {
  $toast({
           type:         "success",
           message:      "Lead updated!",
           linkText:     null,
           linkRouteObj: null
         });
  saving.value = false;
  openModel.value = false;
  refreshData();
});

onError((error) => {
  fillValidationErrorsOrToast(error, errorsRaw, "There was a problem while updating the lead.");
  saving.value = false;
});

const submitLead = () => {
  errorsRaw.value = {};
  if (!form.value.reportValidity()) {
    return;
  }
  saving.value = true;

  mutate({...toRaw(updateLeadData), id: props.client.id});
};
</script>
