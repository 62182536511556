<template>
  <filter-group-base-category
      v-model:filter-selected="filterSelected"
      v-model:operator-selected="operatorSelected"
      :category-name="categoryName"
      @step-back="stepBack"
      @added-rule="addedRule($event)">
    <template #title>
      New Filter - Forms
    </template>
    <template #subtitle>
      The Form filters are all related to the completion, timeframe, and number of times a client has completed a specific form, or forms within a form category.
    </template>
    <template #side-description>
      <div class="space-y-4">
        <p>
          Form rules will almost always contain filters for  <span class="fake-filter">Form Count</span> and  <span class="fake-filter">Form Name</span>.
        </p>
        <div class="pt-4">
          <p>
            <strong>Example Form Rules:</strong>
          </p>

          <ol class="filter-side-examples">
            <li>
              <div class="filter-side-examples-info mb-2">
                Client has completed specific form:
              </div>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Form Name = [select form name]</span>
              </div>
            </li>
            <li>
              <div class="filter-side-examples-info mb-2">
                Client has never completed specific form:
              </div>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Form Name = [select form name]</span> +
                <span class="fake-filter">Form Count</span>
              </div>
            </li>
            <li>
              <div class="filter-side-examples-info mb-2">
                Client has completed 3 forms in form category:
              </div>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Form Count = more than 2</span> +
                <span class="fake-filter">Form Category = [select category]</span>
              </div>
            </li>
            <li>
              <div class="filter-side-examples-info mb-2">
                Client completed 3 forms within a category in last 30 days:
              </div>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Form Count = more than 2</span> +
                <span class="fake-filter">Form Category = [select category]</span> +
                <span class="fake-filter">Form History = in last 30 days</span>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </template>
    <template #filters>
      <slot />
    </template>
  </filter-group-base-category>
</template>
<script lang="ts">
import {singleCategoryComposition} from "@/components/Segment/Edit/Categories/SingleCategories/singleCategoryComposition";

const cat = singleCategoryComposition();
export default {
  components: {
    ...cat.components
  },
  props:      {
    ...cat.props
  },
  emits:      cat.emits,
  setup(props, {emit}) {
    return cat.setup(props, emit);
  }
};
</script>
