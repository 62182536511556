import ClientGroup       from "hm-filters/filters/partials/client";
import OperatorGenerator from "hm-filters/operatorGenerator";

export class ClientOptOutDateFilter extends ClientGroup {
	constructor(name: string, options: any) {
		super(name, options);

		this.setOperators(OperatorGenerator.datesPastOnly(true, true, true));
	}

	get type(): string {
		return "ClientOptOutDateFilter";
	}
}
