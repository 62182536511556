<template>
  <div>
    <div class="font-medium text-base">
      <div class="inline-flex items-center">
        <div
            v-for="path in paths"
            class="pr-1 inline-flex items-center">
          <div>{{ path }}</div>
          <svg-icon
              name="right"
              class="w-4 h-4" />
        </div>
        <div>
          {{ filter.name }}
        </div>
      </div>
    </div>
    <div class="text-xs text-gray-500">
      {{ filter.description }}
    </div>
  </div>
</template>
<script lang="ts" setup>

import SvgIcon           from "@/components/SvgIcon.vue";
import {computed, toRef} from "vue";
import Filter            from "hm-filters/base-filter";

interface Props {
  filter: Filter;
}

const props = withDefaults(defineProps<Props>(), {});
const filter = toRef(props, "filter");

const paths = computed(() => {
  return props.filter.path.slice(1);
});

</script>
