import {QLFragmentFormFullFragment}                     from "@/graphql/queries/ql/composables";
import {computed, ref, Ref}                             from "vue";
import {DateRangePeriodParser, DateTimeRangeWithPeriod} from "@/classes/dates/DateRangePeriodParser";
import {TimeframeParser, Timeframes}                    from "@/classes/dates/TimeframeParser";

export interface QueryDates {
	starts_at: number
	ends_at: number
}

export default function formShowInfoDateSelection(
	form: Ref<QLFragmentFormFullFragment>
) {

	const dateSelected = ref<DateTimeRangeWithPeriod>(
		{
			range:     (new TimeframeParser(Timeframes.Last14days)).result,
			timeframe: Timeframes.Last14days
		}
	);

	const datesAreSet = computed(() => {
		return !(dateSelected.value.range.start === null || dateSelected.value.range.end === null);
	});

	const queryDatesCurrent = computed(() => {
		return {
			starts_at: dateSelected.value.range.start,
			ends_at:   dateSelected.value.range.end
		};
	});

	const previousDateRange = computed(() => {
		if (!datesAreSet.value) {
			return null;
		}
		if (dateSelected.value.timeframe && dateSelected.value.timeframe !== Timeframes.AllTime) {
			return (new TimeframeParser(dateSelected.value.timeframe)).past;
		}

		return (new DateRangePeriodParser(dateSelected.value.range, form.value.created_at)).past;
	});

	const queryDatesPast = computed(() => {
		if (!previousDateRange.value) {
			return {};
		}
		return {
			starts_at: previousDateRange.value.start,
			ends_at:   previousDateRange.value.end
		};
	});

	const pastStatsMatter = computed(() => {
		return previousDateRange.value !== null;
	});

	const showPastData = computed(() => {
		return pastStatsMatter.value;
	});

	const queryOptionsCurrent = computed(() => ({
		enabled: datesAreSet.value
	}));

	const queryOptionsPast = computed(() => ({
		enabled: pastStatsMatter.value
	}));

	const queryVariablesCurrent = computed(() => ({
		input: {
			id: form.value.id,
			...queryDatesCurrent.value
		}
	}));

	const queryVariablesPast = computed(() => ({
		input: {
			id: form.value.id,
			...queryDatesPast.value
		}
	}));

	return {
		dateSelected,
		datesAreSet,
		queryDatesCurrent,
		queryDatesPast,
		pastStatsMatter,
		// loadingStats,
		showPastData,
		queryOptionsCurrent,
		queryOptionsPast,
		queryVariablesCurrent,
		queryVariablesPast
	};

}
