<template>
  <div>
    <h2 class="text-xl mb-3">
      Recent engagements with {{ client.first_name }}
    </h2>

    <div
        v-if="!loadedFirstTime"
        class="block bg-white shadow overflow-hidden sm:rounded-md">
      <div class="animate-loading">
        <div class="divide-y divide-gray-300 px-4">
          <div
              v-for="i in 10"
              :key="i"
              class="py-2">
            <div class="flex justify-between mb-1 text-sm">
              <div>
                <loading-skeleton
                    :max="40"
                    :min="16"
                    class="mb-1"
                    class-type="inline"
                    random-text />
                <div class="space-x-2 text-xs flex">
                  <loading-skeleton
                      class-type="inline"
                      text="Delivered" />
                  <loading-skeleton
                      class-type="inline"
                      text="Opened" />
                  <loading-skeleton
                      class-type="inline"
                      text="Clicked" />
                </div>
              </div>
              <div class="text-right">
                <loading-skeleton
                    :max="12"
                    :min="12"
                    class="text-xs"
                    class-type="inline"
                    random-text />
                <loading-skeleton
                    class-type="inline"
                    text="4:14 PM" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loadedFirstTime">
      <div
          v-if="clientEngagements && clientEngagements.length > 0"
          class="block bg-white shadow overflow-hidden sm:rounded-md">
        <div class="divide-y divide-gray-100 ">
          <router-link
              v-for="discussion in clientEngagements"
              :key="discussion.id"
              :to="{name: routeList.client.discussions.show, params: {id: client.id, discussionId: discussion.id}}"
              class="py-3 px-4 hover:bg-gray-50 cursor-pointer block">
            <div class="flex justify-between text-md">
              <div>
                <div class="mb-1">
<span
    class="font-medium"
    v-text="discussion.sms ? 'Text': 'Email'" /> from:
                  <span class="text-loopspark-700">{{ discussion.automation.name }}</span>
                </div>
                <div
                    v-if="!discussion.sms"
                    class="space-x-4 text-sm pt-2">
                  <div
                      :class="{'text-green-600': discussion.delivered, 'text-red-600': !discussion.delivered}"
                      class="inline-flex items-center">
                    <div
                        class="w-4 h-4 rounded-full flex items-center justify-center"
                        :class="{'bg-green-500': discussion.delivered, 'bg-red-500': !discussion.delivered}">
                      <svg-icon
                          v-if="discussion.delivered"
                          name="check"
                          class="w-3 h-3 text-white" />
                      <svg-icon
                          v-if="!discussion.delivered"
                          name="close"
                          class="w-3 h-3 text-white" />
                    </div>

                    <span
                        class="pl-2"
                        :class="{'font-medium': discussion.delivered, 'font-semibold': !discussion.delivered}"
                        v-text="discussion.delivered ? 'Delivered':'Not delivered'" />
                  </div>

                  <div
                      v-if="discussion.delivered"
                      :class="{'text-green-600': discussion.opened, 'text-gray-400': !discussion.opened}"
                      class="inline-flex items-center">
                    <div
                        class="w-4 h-4 rounded-full flex items-center justify-center"
                        :class="{'bg-green-500': discussion.opened, 'bg-gray-200': !discussion.opened}">
                      <svg-icon
                          v-if="discussion.opened"
                          name="check"
                          class="w-3 h-3 text-white" />
                      <svg-icon
                          v-if="!discussion.opened"
                          name="close"
                          class="w-3 h-3 text-white" />
                    </div>

                    <span
                        class="pl-2"
                        :class="{'font-medium': discussion.opened}">Opened</span>
                  </div>

                  <div
                      v-if="discussion.delivered"
                      :class="{'text-green-600': discussion.clicked, 'text-gray-400': !discussion.clicked}"
                      class="inline-flex items-center">
                    <div
                        class="w-4 h-4 rounded-full flex items-center justify-center"
                        :class="{'bg-green-500': discussion.clicked, 'bg-gray-200': !discussion.clicked}">
                      <svg-icon
                          v-if="discussion.clicked"
                          name="check"
                          class="w-3 h-3 text-white" />
                      <svg-icon
                          v-if="!discussion.clicked"
                          name="close"
                          class="w-3 h-3 text-white" />
                    </div>

                    <span
                        class="pl-2"
                        :class="{'font-medium': discussion.clicked}">Clicked</span>
                  </div>

                  <!--                  <span :class="classesForItem(discussion.delivered, true)">Delivered</span>-->
                  <!--                  <span :class="classesForItem(discussion.opened)">Opened</span>-->
                  <!--                  <span :class="classesForItem(discussion.clicked)">Clicked</span>-->
                </div>
                <div
                    v-if="discussion.sms"
                    class="space-x-4 text-sm pt-2">
                  <div
                      :class="{'text-green-600': discussion.delivered, 'text-red-600': !discussion.delivered}"
                      class="inline-flex items-center">
                    <div
                        class="w-4 h-4 rounded-full flex items-center justify-center"
                        :class="{'bg-green-500': discussion.delivered, 'bg-red-500': !discussion.delivered}">
                      <svg-icon
                          v-if="discussion.delivered"
                          name="check"
                          class="w-3 h-3 text-white" />
                      <svg-icon
                          v-if="!discussion.delivered"
                          name="close"
                          class="w-3 h-3 text-white" />
                    </div>

                    <span
                        class="pl-2"
                        :class="{'font-medium': discussion.delivered, 'font-semibold': !discussion.delivered}"
                        v-text="discussion.delivered ? 'Delivered':'Not delivered'" />
                  </div>
                </div>
              </div>
              <div class="text-xs text-gray-700 text-right">
                <timestamp-to-date
                    :timestamp="discussion.created_at"
                    natural-date />
                <br>
                <timestamp-to-date
                    :has-date="false"
                    :timestamp="discussion.created_at"
                    class="text-gray-500"
                    has-time />
              </div>
            </div>
          </router-link>
        </div>
        <div
            v-if="loadedFirstTime && paginatorInfo.lastPage > 1"
            class="w-full pt-4 pb-4 text-right bg-gray-100 border-t border-gray-200 px-4 rounded-b">
          <pagination
              v-if="paginatorInfo.lastPage > 1"
              :pagination="paginatorInfo"
              class="flex items-center justify-between text-gray-700 text-sm"
              @setPage="paginationData.page = $event">
            <div
                v-if="loading"
                class="inline-block w-8 h-8 mr-2 text-gray-400">
              <loader />
            </div>
          </pagination>
        </div>
      </div>

      <div
          v-if="!loading && paginatorInfo.total === 0"
          class="mt-8">
        <empty>
          <template #title>
            Nothing to show!
          </template>
          <template #subtitle>
            You haven't engaged with {{ client.first_name }} yet!
          </template>
        </empty>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import TimestampToDate                                                              from "@/components/layout/TimestampToDate.vue";
import Pagination                                                                   from "@/components/layout/Pagination.vue";
import Loader                                                                       from "@/components/layout/Loader.vue";
import LoadingSkeleton                                                              from "@/components/layout/LoadingSkeleton.vue";
import {computed, toRef, useAttrs, useSlots}                                        from "vue";
import {QLClient, QLClientEngagementsQuery, QLSortOrder, useClientEngagementsQuery} from "@/graphql/queries/ql/composables";
import {createPaginationData, paginationWithAlreadyLoaded}                          from "@/composables/paginationWithAlreadyLoaded";
import routesList                                                                   from "@/composables/routesList";
import SvgIcon                                                                      from "@/components/SvgIcon.vue";
import Empty                                                                        from "@/components/layout/Empty.vue";

const {routeList} = routesList();

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 10
                                              });


const client = toRef(props, "client");


const clientEngagementsQ = useClientEngagementsQuery(() => ({
  client_id: client.value.id.toString(),
  page:      paginationData.page,
  first:     paginationData.perPage,
  orderBy:   [{column: "created_at", order: QLSortOrder.DESC}]
}));

const {
  paginatorInfo,
  loadedFirstTime,
  results: clientEngagements,
  loading
} = paginationWithAlreadyLoaded<QLClientEngagementsQuery["client_engagements"]["data"]>(clientEngagementsQ);


const classesForItem = (item: boolean, withDanger: boolean = false) => {
  if (item) {
    return ["text-green-800"];
  } else {
    return {
      "text-gray-500": !withDanger,
      "text-red-600":  withDanger,
      "line-through":  true
    };
  }
};
</script>
