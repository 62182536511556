<template>
  <modal
      v-model:open="openModel"
      :has-close-button="!sending">
    <div class="px-4 py-4 space-y-4 w-full">
      <div class="text-xl text-green-700 font-medium">
        Complete this To-Do?
      </div>
      <form
          ref="form"
          class="block w-full"
          @submit.prevent="completeTodo">
        <label class="block mt-4">
          <span class="form-label">What action did you take?</span>
          <select
              v-model="completedAction"
              :disabled="sending"
              class="form-select mt-1 block w-full"
              required>
            <option
                disabled
                selected
                value="">Select your action
            </option>
            <option :value="completeEnum.IN_PERSON">In person</option>
            <option :value="completeEnum.PHONE_CALL">I called them</option>
            <option :value="completeEnum.SMS">I sent a text</option>
            <option :value="completeEnum.EMAIL">I sent an email</option>
            <option :value="completeEnum.OTHER">Other</option>
          </select>
        </label>
        <div class="block mt-4">
          <text-area-input
              v-model="completedNote"
              :disabled="sending"
              class="disabled:text-gray-700"
              label="Note (optional)"
              placeholder="Example: left a voicemail" />
        </div>
      </form>
    </div>
    <template #buttons>
      <v-button
          :loading="sending"
          class="modal-button modal-button-green"
          @click="completeTodo">
        I completed it!
      </v-button>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import Modal         from "@/components/layout/Modal.vue";
import VButton       from "@/components/layout/VButton.vue";
import TextAreaInput from "@/components/layout/Forms/TextAreaInput.vue";

import {computed, onMounted, ref, toRef, watch}                  from "vue";
import {QLTodo, QLTodoCompletionTypeEnum, useUpdateTodoMutation} from "@/graphql/queries/ql/composables";
import $toast                                                    from "@/composables/toast";

interface Props {
  todo: QLTodo;
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:open", "completed"]);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const sending = ref(false);

const completedAction = ref<QLTodoCompletionTypeEnum>(QLTodoCompletionTypeEnum.IN_PERSON);
const completedNote = ref("");

const completeEnum = QLTodoCompletionTypeEnum;
const form = ref(null);

const passedTodo = toRef(props, "todo");
watch(passedTodo, () => {
  resetForm();
});
onMounted(() => {
  resetForm();
});

const resetForm = () => {
  completedAction.value = QLTodoCompletionTypeEnum.IN_PERSON;
  completedNote.value = "";
};

const mutation = useUpdateTodoMutation({});
mutation.onError((err) => {
  $toast({
           type:         "error",
           message:      "There was an error while completing this To-Do. Try again",
           linkRouteObj: null,
           linkText:     null
         });
  sending.value = false;
});

mutation.onDone((res) => {
  $toast({
           type:         "success",
           message:      "Successfully completed the To-Do for " + props.todo.client.first_name,
           linkText:     null,
           linkRouteObj: null
         });
  sending.value = false;
  openModel.value = false;
  emit("completed");
  resetForm();
});

const completeTodo = () => {
  if (!form.value.reportValidity()) {
    return;
  }
  if (sending.value === true) {
    return;
  }
  sending.value = true;
  mutation.mutate({
                    id:                    props.todo.id,
                    completed_action_type: completedAction.value,
                    completed_action_note: completedNote.value
                  });

};

</script>
