import Filter            from "../../base-filter";
import config            from "./config";
import OperatorGenerator from "../../operatorGenerator";


class FirstVisitFilter extends Filter {
	smallText = config.visits.groups.visit.group;
	group = config.visits.groups.visit.group;
	path = ["Visit", this.group];

	constructor(name: string, options: any) {
		super(config.visits.first, options);
		this.setOperators(OperatorGenerator.datesPastOnly(true));
	}

	get type(): string {
		return "VisitFirst";
	}
}

class FirstLateCancelFilter extends FirstVisitFilter {
	smallText = config.visits.groups.lateCancelled.group;
	group = config.visits.groups.lateCancelled.group;
	path = ["Visit", this.group];

	get type(): string {
		return "LateCancelFirst";
	}
}


export {
	FirstVisitFilter,
	FirstLateCancelFilter
};
