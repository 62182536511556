<template>
  <page-title
      title="Upload Leads"
      section="Clients">
    <template #right>
      <div>
        <button
            type="button"
            class="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-loopspark-700 bg-loopspark-100 hover:bg-loopspark-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            @click="singleOpen = true">
          <svg-icon
              name="plus"
              class="w-4 h-4" />
          <span>Add one lead</span>
        </button>
      </div>
    </template>
  </page-title>
  <div class="container mx-auto grow">
    <h1 class="main-page-title">
      <span class="subtitle">Clients</span>
      Upload Leads
    </h1>

    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3 shrink-0">
        <!--        <div class="text-lg font-medium text-gray-900">Exclude events</div>-->
        <div class="mt-1 text-gray-600 text-sm space-y-6">
          <p>Upload a list of prospective leads that are not yet in your booking software.</p>

          <div>
            Fields <span class="font-medium">required</span> to upload a leads list:

            <ul class="list-disc list-outside pl-4 pt-1 leading-relaxed">
              <li>Lead's First Name</li>
              <li>Lead's Last Name</li>
              <li>Lead's email address</li>
            </ul>
          </div>

          <div>
            Optional fields to include in leads list:
            <ul class="list-disc list-outside pl-4 pt-1 leading-relaxed">
              <li>Lead's mobile phone number</li>
              <li>
                Lead's lead type or lead source
                <div class="text-xs">
                  (example: Facebook, pop-up event, open house)
                </div>
              </li>
            </ul>
          </div>
          <p>
            Once a prospect/lead creates an account on your booking software using the same
            email address, their lead profile will become inactive in LoopSpark, preventing
            you from double communicating with a lead that has now become a client.
          </p>
        </div>
      </div>
      <upload-leads-excel class="flex-1" />
    </div>
  </div>

  <upload-single-lead v-model:open="singleOpen" />

</template>
<script lang="ts" setup>
import SvgIcon          from "@/components/SvgIcon.vue";
import UploadLeadsExcel from "@/components/Clients/Prospects/UploadLeadsExcel.vue";
import PageTitle        from "@/components/navbar/PageTitle.vue";
import UploadSingleLead from "@/views/Clients/Components/UploadSingleLead.vue"
import {ref}            from "vue";

const singleOpen = ref(false);
</script>
