import {QLFragmentCurrentTeamFragment, useCurrentTeamQuery} from "@/graphql/queries/ql/composables";
import {computed, Ref}                                      from "vue";
import {WatchQueryFetchPolicy}                              from "@apollo/client/core";

export default function currentTeamQuery(fetchPolicy: WatchQueryFetchPolicy = "cache-first") {
	const {result, loading, refetch} = useCurrentTeamQuery({fetchPolicy});

	const currentTeam = computed(() => result.value?.current_team ?? null) as Readonly<Ref<QLFragmentCurrentTeamFragment | null>>;

	return {
		currentTeam,
		loading,
		refetch
	};
}

