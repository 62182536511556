import Filter                                             from "../../base-filter";
import config                                             from "./config";
import OperatorGenerator                                  from "../../operatorGenerator";
import FixedValueOperator, {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";

class VisitFilter extends Filter {
	smallText = config.visits.groups.visit.group;
	group = config.visits.groups.visit.group;
	path = ["Visit", this.group];

	constructor(name: string, options: any) {
		super(config.visits.filter, options);
		this.setOperators(OperatorGenerator.datesPastOnly(true));
	}

	get type(): string {
		return "VisitFilter";
	}
}

class LateCancelFilter extends VisitFilter {
	smallText = config.visits.groups.lateCancelled.group;
	group = config.visits.groups.lateCancelled.group;
	path = ["Visit", this.group];

	get type(): string {
		return "LateCancelFilter";
	}
}

class AbsentFilter extends VisitFilter {
	smallText = config.visits.groups.absent.group;
	group = config.visits.groups.absent.group;
	path = ["Visit", this.group];

	get type(): string {
		return "AbsentFilter";
	}
}

class UpcomingVisitFilter extends VisitFilter {
	smallText = config.visits.groups.upcomingVisit.group;
	group = config.visits.groups.upcomingVisit.group;
	path = ["Visit", this.group];

	constructor(name: string, options: any) {
		super(config.visits.filter, options);
		this.clearOperators();

		this.addOperators(OperatorGenerator.datesFutureOnly());
		this.addOperators([new OperatorAsFixedValueOperator("never", "none")]);
	}

	get type(): string {
		return "UpcomingVisit";
	}
}

class SignedUpFilter extends VisitFilter {
	smallText = config.visits.groups.signedUp.group;
	group = config.visits.groups.signedUp.group;
	path = ["Visit", this.group];

	constructor(name: string, options: any) {
		super(config.visits.filter, options);

		this.setOperators(OperatorGenerator.datesAll(true, true, false));

		this.addOperators([
			                  new OperatorAsFixedValueOperator("never", "never")
		                  ]);
	}

	get type(): string {
		return "SignedUpFilter";
	}
}

class EarlyCancelFilter extends VisitFilter {
	smallText = config.visits.groups.early_cancel.group;
	group = config.visits.groups.early_cancel.group;
	path = ["Visit", this.group];

	constructor(name: string, options: any) {
		super(config.visits.filter, options);

		this.setOperators(OperatorGenerator.datesAll(true, true, false));

		this.addOperators([
			                  new OperatorAsFixedValueOperator("never", "never")
		                  ]);
	}

	get type(): string {
		return "EarlyCancelFilter";
	}
}


export {
	VisitFilter,
	LateCancelFilter,
	UpcomingVisitFilter,
	SignedUpFilter,
	AbsentFilter,
	EarlyCancelFilter
};
