<template>
  <page-title
      section="Settings"
      title="Studio Usage" />
  <div class="container mx-auto grow space-y-16">
    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          SMS Unit usage
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <div class="space-y-4">
            <p>
              Each subscription receives 2,500 free SMS units per month. These free SMS units do not roll over month to
              month.
            </p>
            <p>
              If your business requires more SMS units during any billing cycle, a new SMS bundle will automatically be
              purchased to ensure there is not SMS service disruption.
            </p>
            <p>
              The paid SMS bundle costs $15 and provides an addition 1,000 SMS units. Paid SMS bundles do not expire at
              the end of a billing cycle, and can be used in the future if your studio requires more than the monthly
              allotted SMS units.
            </p>
          </div>
        </div>
      </div>
      <div class="flex-1 block">
        <div
            v-if="loading"
            class="w-full py-16 text-center">
          <loader class="h-16 animate-loading" />
        </div>

        <div>
          <h3 class="text-lg font-medium text-gray-900">SMS Units remaining</h3>

          <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
            <studio-single-bundle-usage v-for="bundle in orderedBundles" :key="bundle.id" :bundle="bundle" />
          </dl>
        </div>

        <div v-if="simplePaginated.paginatedItems.length > 0" class="mt-16">
          <h3 class="text-lg font-medium text-gray-900">Past SMS Bundles</h3>
          <div class="overflow-hidden bg-white shadow sm:rounded-md mt-5">

            <ul class="divide-y divide-gray-200" role="list">
              <li v-for="usedBundle in simplePaginated.paginatedItems" :key="usedBundle.id">
                <PastPaidBundleListItem v-if="usedBundle.is_purchased" :bundle="usedBundle" />
                <PastFreeBundleListItem v-if="!usedBundle.is_purchased" :bundle="usedBundle" />
              </li>
            </ul>
          </div>
          <div v-if="simplePaginated.hasMorePages || simplePaginated.page > 1">
            <div class="flex justify-between mt-4">
              <div>
                <button
                    v-if="simplePaginated.page > 1"
                    class="btn btn-white"
                    @click="simplePaginated.setPage(simplePaginated.page - 1)">
                  <svg-icon
                      class="w-4 h-4"
                      name="left" />
                </button>
              </div>
              <div>
                <button
                    v-if="simplePaginated.hasMorePages"
                    class="btn btn-white"
                    @click="simplePaginated.setPage(simplePaginated.page + 1)">
                  <svg-icon
                      class="w-4 h-4"
                      name="right" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import PageTitle from "@/components/navbar/PageTitle.vue";

import {computed, reactive, useAttrs, useSlots} from "vue";
import currentTeamQuery                         from "@/composables/queries/currentTeamQuery";

import Loader                        from "@/components/layout/Loader.vue";
import StudioSingleBundleUsage       from "@/views/Studio/Settings/Components/Usage/StudioSingleBundleUsage.vue";
import PastPaidBundleListItem        from "@/views/Studio/Settings/Components/Usage/PastPaidBundleListItem.vue";
import PastFreeBundleListItem        from "@/views/Studio/Settings/Components/Usage/PastFreeBundleListItem.vue";
import fakeSimplePagination          from "@/composables/fakeSimplePagination";
import {QLFragmentSmsBundleFragment} from "@/graphql/queries/ql/composables";
import SvgIcon                       from "@/components/SvgIcon.vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const {currentTeam, loading} = currentTeamQuery();

const orderedBundles = computed(() => {
  if (!currentTeam.value) {
    return null;
  }

  return currentTeam.value.sms_bundles.filter((i) => i.expired === false).map((a) => a)
                    .sort((a, b) => (a.is_purchased ? 0 : 1) > (b.is_purchased ? 0 : 1) ? -1 : 1);
});

const usedBundles = computed((): QLFragmentSmsBundleFragment[] => {
  if (!currentTeam.value) {
    return [];
  }
  return currentTeam.value.sms_bundles.filter((i) => i.expired === true).map((a) => a)
                    .sort((a, b) => (a.created_at ? 0 : 1) > (b.created_at ? 0 : 1) ? -1 : 1);
});

// @ts-ignore
const simplePaginated = reactive(fakeSimplePagination(usedBundles, 10));

</script>
