<template>
  <div class="mt-6">
    <div class="main-page-title">
      <span class="subtitle">Automations</span>
      Catalog CREATE
    </div>

    <catalog-create-edit-form
        v-model:catalog-item="catalogItem"
        v-model:errors="errors"
        :saving="saving"
        @save="saveCatalog" />
  </div>
</template>
<script lang="ts" setup>

import {ActiveClientFilterGenerator} from "hm-filters/segment/ActiveClientFilterGenerator";
import CatalogCreateEditForm         from "@/components/automations/Catalog/CatalogCreateEditForm.vue";

import {ref}                                                                                          from "vue";
import {QLAutomationDeliveryTypeEnum, QLCatalogItemInput, QLTodoPriority, useSaveCatalogItemMutation} from "@/graphql/queries/ql/composables";
import routesList                                                                                     from "@/composables/routesList";
import $toast                                                                                         from "@/composables/toast";
import {fillValidationErrorsAndToast}                                                                 from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import {useRouter}                                                                                    from "vue-router";
import AutomationDecorator                                                                            from "@/composables/decorators/AutomationDecorator";

const router = useRouter();


const basicFilter = ActiveClientFilterGenerator.generate();

const catalogItem = ref({
                                              name:             "",
                                              description:      "",
                                              long_description: "",
                                              visible:          false,
                                              provider:         null,
                                              automation:       {
                                                name:                      "",
                                                delivery_type:             QLAutomationDeliveryTypeEnum.EMAIL,
                                                one_off:                   true,
                                                send_window_start_minutes: null,
                                                send_window_end_minutes:   null,
                                                active:                    false,
                                                start_at:                  null,
                                                segment:                   {
                                                  id:            "0",
                                                  name:          "",
                                                  filters: []
                                                },
                                                subject:                   "",
                                                content:                   "",
                                                email_content:             {
                                                  html: "",
                                                  json: null
                                                },
                                                todo_assigned_to_id:       null,
                                                todo_team_group_id:        null,
                                                todo_tags:                 [],
                                                todo_overdue_hours:        null,
                                                todo_postponed_hours:      null,
                                                todo_priority:             QLTodoPriority.NONE,
                                                tags:                      [],
                                                tags_add:                  true
                                              },
                                              variable_fields:  []
                                            });

const saving = ref(false);
const errors = ref({});

const mut = useSaveCatalogItemMutation({});

mut.onDone((d) => {
  saving.value = false;
  const finalRoute = {
    name:   routesList().routeList.automations.catalog.edit,
    params: {id: d.data.SaveCatalogItem.id}
  };
  $toast({
           type:         "success",
           message:      "The catalog was created successfully",
           linkRouteObj: finalRoute,
           linkText:     "View your automation"
         });

  router.push(finalRoute);
});

mut.onError((err) => {
  fillValidationErrorsAndToast(err, errors);
  saving.value = false;
});

const saveCatalog = () => {
  saving.value = true;
  const toPost = AutomationDecorator(catalogItem.value.automation).computed.toPost;
  const final = {...catalogItem.value, automation: toPost};

  mut.mutate({id: null, catalogItem: final});
};

</script>
