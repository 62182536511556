<template>
  <div class="inline-block">
    <div class="block h-5 leading-none">
      <div class="inline-flex items-center content-center rounded-full text-sm truncate"
           v-bind:class="containerClasses">
        <div class="w-5 h-5 rounded-full self-center inline-block inline-flex content-center justify-center items-center"
             v-bind:class="iconClasses">
          <div class="w-5 h-5">
            <avatar :image="user.photo_url" :username="user.name" class="w-5 h-5"></avatar>
          </div>
        </div>
        <div class="inline-block pr-2 pl-1">
          <slot>{{ user.name }}</slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {computed, PropType, toRef}   from "vue";
import {QLFragmentUserSmallFragment} from "@/graphql/queries/ql/composables";
import Avatar                        from "@/components/layout/Avatar.vue";

export default {
  name:       "TagWithUser",
  components: {
    Avatar
  },
  props:      {
    user:  {
      type:     Object as PropType<QLFragmentUserSmallFragment>,
      required: true
    },
    color: {
      type:     String as PropType<"green" | "gray" | "blue" | "yellow" | "red" | "loopspark" | "transparent">,
      required: false,
      default:  () => "gray"
    }
  },
  setup(props) {
    const color = toRef(props, "color");
    const containerClasses = computed(() => {
      if (color.value === "transparent") {
        return [];
      }
      return [
        "bg-" + color.value + "-100",
        "text-" + color.value + "-800"
      ];
    });

    const iconClasses = computed(() => {
      if (color.value === "transparent") {
        return [];
      }
      return ["bg-" + color.value + "-300"];
    });

    return {
      containerClasses,
      iconClasses
    };
  }

};
</script>
