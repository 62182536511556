import {QLClientsListIndexQueryVariables, useClientsListIndexQuery, QLSegmentInput, QLSortOrder} from "@/graphql/queries/ql/composables";
import {useResult}                                                  from "@vue/apollo-composable";
import {computed, Ref, toRef}                                       from "vue";
import {UnwrapNestedRefs}                                           from "@vue/reactivity";

interface DataInterface {
	page: number,
	hasErrors: boolean
}

export const previewSegment = (data: UnwrapNestedRefs<DataInterface>,segmentToPost: Ref<Partial<QLSegmentInput>>) => {

	const clientsVariables = computed<QLClientsListIndexQueryVariables>(() => {
		const segment = segmentToPost.value;
		return {
			segment,
			page:    data.page,
			first:   10,
			orderBy: [
				{
					column: "last_name",
					order:  QLSortOrder.ASC
				}
			]
		};
	});

	const query = useClientsListIndexQuery(clientsVariables);
	query.onResult((apolloData) => {
		if (apolloData.loading) {
			data.hasErrors = false;
		}
	});

	query.onError(() => data.hasErrors = true);

	const clients = useResult(query.result, [], data1 => data1.clients_list_index.data);
	const paginatorInfo = useResult(query.result, null, data1 => data1.clients_list_index.paginatorInfo);

	const foundClients = computed(() => {
		if (!paginatorInfo.value) {
			return null;
		}
		return paginatorInfo.value.total;
	});

	const loading = toRef(query, "loading");

	return {
		clients,
		paginatorInfo,
		foundClients,
		loading
	}
}
