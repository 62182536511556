<template>
  <page-title title="Manage Groups" section="Settings" />
  <div class="container mx-auto grow space-y-16">

    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Groups and Staff
        </div>
        <div class="mt-1 text-gray-600 text-sm space-y-4">
          <p>
            To help organize your staff's To-Do list, staff members can be assigned to one (or multiple) groups such as
            [Front Desk, Sales Manager, Operations Manager] etc.
          </p>
          <p>
            Assigning your staff members to group(s) will allow them to see any To-Do that is assigned to them
            directly, or assigned to any of their attached role groups. To assign one or more role groups to a staff
            member, select a group from the dropdown, or start typing to add a new group that currently doesn't
            exist.
          </p>
          <p>Groups do not control the access privileges of a staff member.</p>
        </div>
      </div>

      <div class="flex-1">
        <div class="">
          <div
              v-if="!currentTeam"
              class="space-y-10 animate-loading">
            <div
                v-for="i in 5"
                :key="i"
                class="block shadow-md rounded-lg bg-white p-4">
              <loading-skeleton
                  class="mb-2 inline-block"
                  random-text />
              <div class="space-x-2 mt-2">
                <loading-skeleton
                    :max="15"
                    :min="5"
                    class="inline-block"
                    class-type="inline-block rounded"
                    random-text />
                <loading-skeleton
                    :max="15"
                    :min="5"
                    class="inline-block"
                    class-type="inline-block rounded"
                    random-text />
                <loading-skeleton
                    :max="15"
                    :min="5"
                    class="inline-block"
                    class-type="inline-block rounded"
                    random-text />
              </div>
            </div>
          </div>

          <div
              v-if="currentTeam"
              class="space-y-10">
            <div v-for="user in currentTeam.users">
              <manage-groups-of-user
                  :key="user.id"
                  :available-groups="availableGroups"
                  :groups="groupsForUser(user)"
                  :user="user"
                  class="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import PageTitle                                             from "@/components/navbar/PageTitle.vue";
import ManageGroupsOfUser from "@/components/studio/settings/ManageGroups/ManageGroupsOfUser.vue";
import LoadingSkeleton    from "@/components/layout/LoadingSkeleton.vue";
import currentTeamQuery   from "@/composables/queries/currentTeamQuery";
import {computed}         from "vue";
import {QLUser}           from "@/graphql/queries/ql/composables";

const {currentTeam} = currentTeamQuery();

const availableGroups = computed(() => {
  if (!currentTeam.value) {
    return [];
  }
  return currentTeam.value!.groups!.map((group) => {
    return group;
  }).sort((a, b) => (a.name.localeCompare(b.name, "en", {"sensitivity": "base"})));
});

const groupsForUser = (user: QLUser) => {
  return currentTeam.value.groups!.filter((group) => {
    return group!.users!.filter((uGroup) => uGroup!.id == user.id).length > 0;
  });
};
</script>
