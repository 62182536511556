import Package           from "./partials/package";
import OperatorGenerator from "../operatorGenerator";

class PackagePurchaseDate extends Package {
	constructor(name: string, options: any) {
		super(name, options);

		this.setOperators(OperatorGenerator.datesPastOnly(true, true, false));
	}

	get type(): string {
		return "PackagePurchaseDateFilter";
	}
}

export default PackagePurchaseDate;
