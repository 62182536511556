<template>
  <page-title
      section="Settings"
      title="Studio Profile" />
  <div class="container mx-auto grow">
    <div class="space-y-16">
      <div class="split-view-left">
        <div class="pb-4 md:pb-0 md:w-1/3">
          <div class="text-lg font-medium text-gray-900">
            Studio information
          </div>
          <div class="mt-1 text-gray-600 text-sm">
            Update your studio name, primary brand color and studio timezone.
          </div>
        </div>

        <studio-settings-change-information
            v-if="currentTeam"
            :current-team="currentTeam"
            class="flex-1" />

        <div
            v-if="!currentTeam"
            class="flex-1 h-32 flex w-full items-center justify-center">
          <loader class="w-16 text-gray-500 animate-pulse" />
        </div>
      </div>

      <studio-settings-set-api :team="currentTeam" />

      <div class="split-view-left">
        <div class="pb-4 md:pb-0 md:w-1/3">
          <div class="text-lg font-medium text-gray-900">
            Studio photo
          </div>
          <div class="mt-1 text-gray-600 text-sm">
            This photo will not be client facing. Your studio photo controls the
            avatar associated for your business location, and will only be visible
            in the LoopSpark dashboard.
          </div>
        </div>

        <studio-settings-change-photo
            v-if="currentTeam"
            :current-team="currentTeam"
            class="flex-1" />
      </div>

      <div class="split-view-left">
        <div class="pb-4 md:pb-0 md:w-1/3">
          <div class="text-lg font-medium text-gray-900">
            Studio logo
          </div>
          <div class="mt-1 text-gray-600 text-sm space-y-2">
            <p>
              Your studio logo will be used when copying automations from the
              <router-link
                  :to="{name: routes.routeList.automations.catalog.index}"
                  class="text-link">
                Automation Catalog
              </router-link>
              and setting up forms.
              Your logo will be automatically resized to 1024px width, and the height will be adjusted to maintain the
              aspect ratio.
            </p>
            <div>
              <strong>Image Requirements:</strong>
              <ul class="pt-1 list-disc list-inside">
                <li>4MB max upload</li>
                <li>File Formats: PNG, JPG, GIF</li>
                <li>Dark logo that will stand out on white background (recommended)</li>
                <li>Aspect ratio of 4:1 (recommended)</li>
              </ul>
            </div>
          </div>
        </div>

        <studio-settings-change-logo
            v-if="currentTeam"
            :current-team="currentTeam"
            class="flex-1" />
      </div>

      <div class="split-view-left">
        <div class="pb-4 md:pb-0 md:w-1/3">
          <div
              class="text-lg font-medium text-gray-900"
              :class="{'text-red-800' : !outgoingSet}">
            Outgoing
            messages
            settings
          </div>

          <div
              v-if="!outgoingSet"
              class="my-4 p-2 px-3 rounded border-red-300 border text-red-900 bg-red-100 text-sm">
            Automations are
            disabled until outgoing
            message settings is
            complete. To enable
            automations, please
            fill out all required
            fields.
          </div>

          <div class="mt-1 text-gray-600 text-sm">
            Define your email sender name, where email responses will be delivered,
            phone forwarding number and other important studio information used in
            merge tags.
          </div>
        </div>

        <studio-settings-change-outgoing
            v-if="currentTeam"
            :current-team="currentTeam"
            class="flex-1" />
      </div>

      <div class="split-view-left">
        <div class="pb-4 md:pb-0 md:w-1/3">
          <div class="text-lg font-medium text-gray-900">
            Social
          </div>
          <div class="mt-1 text-gray-600 text-sm">
            Social settings will allow you to store information for quick use via
            merge tags.
          </div>
        </div>

        <studio-settings-change-social
            v-if="currentTeam"
            :current-team="currentTeam"
            class="flex-1" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Loader                    from "@/components/layout/Loader.vue";
import PageTitle                 from "@/components/navbar/PageTitle.vue";
import StudioSettingsChangeInformation
                                 from "@/views/Studio/Settings/Components/Settings/StudioSettingsChangeInformation.vue";
import StudioSettingsChangePhoto from "@/views/Studio/Settings/Components/Settings/StudioSettingsChangePhoto.vue";
import StudioSettingsChangeLogo  from "@/views/Studio/Settings/Components/Settings/StudioSettingsChangeLogo.vue";
import StudioSettingsChangeOutgoing
                                 from "@/views/Studio/Settings/Components/Settings/StudioSettingsChangeOutgoing.vue";
import StudioSettingsChangeSocial
                                 from "@/views/Studio/Settings/Components/Settings/StudioSettingsChangeSocial.vue";
import StudioSettingsSetApi      from "@/views/Studio/Settings/Components/Settings/Apis/StudioSettingsSetApi.vue";
import currentTeamQuery          from "@/composables/queries/currentTeamQuery";
import {computed}                from "vue";
import routesList                from "@/composables/routesList";

const routes = routesList();

const {currentTeam} = currentTeamQuery("cache-first");
const outgoingSet = computed(() => {
  if (!currentTeam.value) {
    return true;
  }
  return currentTeam.value.outgoing_set;
});

</script>
