<template>
  <confirm-modal
      v-model:open="openModel"
      :loading="saving"
      type="warning"
      close-text="No, go back"
      title="Archive this Lead?"
      @close="openModel = false"
      @clicked="submitLead">
    <template #button>
      Yes, archive it!
    </template>
    <template #text>
      <p>
        By archiving this lead, it will be removed from the results of any segments. However, the lead is not
        permanently gone. You can still find it using the search feature when needed.
      </p>
      <p>Please note, you have the option to un-archive this lead at any time.
      </p>
      <p>Are you sure you want to proceed?
      </p>
    </template>
  </confirm-modal>
</template>
<script lang="ts" setup>

import {QLClient}                        from "@/graphql/queries/ql/composables";
import ConfirmModal                      from "@/components/layout/ConfirmModal.vue";
import updateLeadArchiveStatusComposable from "@/views/ClientShow/Components/Info/updateLeadArchiveStatusComposable";

interface Props {
  open: boolean;
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(["update:open"]);

const {openModel, saving, submitLead} = updateLeadArchiveStatusComposable(props, emit, true);

</script>
