import {computed, isRef, reactive, ref, Ref}      from "vue";
import {QLFragmentPackageInfoFragment, QLPackage} from "@/graphql/queries/ql/composables";
import {DecoratorInterface}                       from "@/composables/decorators/DecoratorInterface";

export function PackageDecorator(passed: Ref<QLFragmentPackageInfoFragment | QLPackage> | QLFragmentPackageInfoFragment | QLPackage) {

	const entity = isRef(passed) ? passed : ref(passed);

	const isActive = computed(() => {
		return entity.value.active || false;
	});

	const isCancelled = computed(() => {
		return entity.value.cancelled;
	});

	const isAllUsed = computed(() => {
		return <number>entity.value.remaining < 1;
	});

	const isCurrent = computed(() => {
		return isActive.value && entity.value.current;
	});

	/**
	 * Unused is only for rolling packages that are in the future or not expiring
	 */
	const isUnused = computed(() => {
		return entity.value.has_rolling_activation &&
		       !entity.value.active &&
		       entity.value.remaining === entity.value.qta &&
		       (entity.value.expires_at == null || !(<number>entity.value.expires_at < Date.now() / 1000));
	});

	const isExpired = computed(() => {
		return !isAllUsed.value && !isUnused.value && <number>entity.value.expires_at < (Date.now() / 1000);
	});

	const hasContract = computed(() => {
		return !!entity.value.contract_expires_at;
	});

	const usageIsRelevant = computed(() => {
		return <number>entity.value.qta > 1;
	});

	const hasExpiration = computed(() => {
		return entity.value.expires_at !== null;
	});

	const futureExpiration = computed(() => {
		return <number>entity.value.expires_at > (Date.now() / 1000);
	});

	const isOneTimer = computed(() => {
		return entity.value.days_difference === 0;
	});

	const expirationIsRelevant = computed(() => {
		return hasExpiration.value && (
			(!isOneTimer.value && isActive.value && !usageIsRelevant.value) ||
			(!isAllUsed.value && !isUnused.value)
		);
	});

	return {
		computed: reactive({
			                   isActive,
			                   isCancelled,
			                   isAllUsed,
			                   isCurrent,
			                   isUnused,
			                   isExpired,
			                   hasContract,
			                   usageIsRelevant,
			                   hasExpiration,
			                   futureExpiration,
			                   isOneTimer,
			                   expirationIsRelevant
		                   })
	};
}
