<template>
  <confirm-modal
      v-model:open="openModel"
      :title="'Unsubscribe from ' + (type === 'sms' ? 'text messages' : 'emails') + '?'"
      type="warning"
      close-text="No, go back!"
      :loading="sending"
      @clicked="unsubscribe">
    <template #text>
      <div v-if="type === 'sms'">
        <p>Are you sure you want to unsubscribe {{ client.first_name }} from all text messages?</p>
        <p>{{ client.first_name }} will not receive any more text messages from LoopSpark's automations.</p>
      </div>
      <div v-if="type === 'email'">
        <p>Are you sure you want to unsubscribe {{ client.first_name }} from all emails?</p>
        <p>{{ client.first_name }} will not receive any more emails from LoopSpark's automations.</p>
      </div>
    </template>
    <template #button>
      {{ buttonText }}
    </template>
  </confirm-modal>
</template>
<script lang="ts" setup>
import {QLClient, useUpdateClientMutation} from "@/graphql/queries/ql/composables";
import {computed, ref, toRef}              from "vue";
import ConfirmModal                        from "@/components/layout/ConfirmModal.vue";
import $toast                              from "@/composables/toast";


interface Props {
  client: QLClient;
  type: "sms" | "email";
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(["update:open"]);

const type = toRef(props, "type");
const client = toRef(props, "client");

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const sending = ref(false);

const buttonText = computed(() => {
  if (type.value === "sms") {
    return "Unsubscribe from text messages";
  }
  return "Unsubscribe from emails";
});

const updateClient = useUpdateClientMutation({});

updateClient.onDone(() => {
  sending.value = false;
  $toast({
           type:         "success",
           message:      "Successfully unsubscribed " + client.value.first_name + " from " + (type.value === "sms"
                                                                                              ? "text messages"
                                                                                              : "emails"),
           linkRouteObj: null,
           linkText:     null
         });
  openModel.value = false;
});

updateClient.onError(() => {
  sending.value = false;
  $toast({
           type:         "error",
           message:      "There was an error while unsubscribing " + client.value.first_name + " from " + (type.value === "sms"
                                                                                                           ? "text messages"
                                                                                                           : "emails"),
           linkRouteObj: null,
           linkText:     null
         });
  openModel.value = false;
});

const unsubscribe = () => {
  sending.value = true;
  if (type.value === "sms") {
    updateClient.mutate({
                          id:                  client.value.id,
                          unsubscribed_sms_at: Math.round(Date.now() / 1000)
                        });
  } else {
    updateClient.mutate({
                          id:                    client.value.id,
                          unsubscribed_email_at: Math.round(Date.now() / 1000)
                        });
  }
};

</script>
