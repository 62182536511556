<template>
  <div>
    <div class="mt-4 mb-2 flex flex-row text-sm flex-wrap space-x-2">
      <div class="flex space-wrap space-x-2">
        <div class="text-gray-700">
          Status:
          <inline-muted-select
              v-model="status"
              :options="statusOptions" />
        </div>
        <div class="text-gray-700 ">
          Priority:
          <inline-muted-select
              v-model="priority"
              :options="priorityOptions" />
        </div>
        <div
            v-if="!!currentTeam"
            class="text-gray-700">
          Assignee:
          <inline-muted-select
              v-model="selectedUser"
              :max-width="240"
              :options="currentTeam.users"
              :with-empty="true"
              label="name"
              option="id" />
        </div>
      </div>
      <div class="text-gray-700 text-right grow">
        Per page:
        <inline-muted-select
            v-model="paginationData.perPage"
            :options="perPageOptions" />
      </div>
    </div>

    <empty v-if="loadedFirstTime && todos.length === 0">
      <template #title>
        Nothing to show!
      </template>
      <template #subtitle>
        There aren't any ToDos with the current filters
      </template>
    </empty>

    <div
        v-if="!loadedFirstTime"
        class="w-full h-40 px-2 my-2">
      <div class="flex h-full items-center justify-center">
        <div class="w-12 h-12 text-gray-400 opacity-75">
          <loader />
        </div>
      </div>
    </div>

    <div
        v-if="loadedFirstTime && todos.length > 0"
        class="flex flex-col mt-6">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class=" divide-y divide-gray-200 min-w-full">
              <thead class="bg-gray-50">
              <tr>
                <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Client
                </th>
                <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-full">
                  Subject
                </th>
                <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Assigned
                </th>
                <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right">
                  Creation
                  Date
                </th>
              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
              <single-todo
                  v-for="todo in todos"
                  :key="todo.id"
                  :todo="todo">
              </single-todo>
              </tbody>
              <tfoot
                  v-if="todos && paginatorInfo.total > 0&& paginatorInfo.lastPage > 1"
                  class="bg-gray-50 divide-y">
              <tr>
                <td
                    colspan="6"
                    class="p-4">
                  <pagination
                      v-if="paginatorInfo.lastPage > 1"
                      :pagination="paginatorInfo"
                      class="flex items-center justify-between text-gray-700 text-sm"
                      @setPage="paginationData.page = $event">
                    <div
                        v-if="loading"
                        class="inline-block w-8 h-8 mr-2 text-gray-400">
                      <loader />
                    </div>
                  </pagination>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Empty                                               from "@/components/layout/Empty.vue";
import Pagination                                           from "@/components/layout/Pagination.vue";
import Loader                                               from "@/components/layout/Loader.vue";
import SingleTodo                                           from "@/components/todos/listing/SingleTodo.vue";
import InlineMutedSelect                                    from "@/components/layout/InlineMutedSelect.vue";
import LoadingSkeleton                                      from "@/components/layout/LoadingSkeleton.vue";
import {
  QLFragmentFullAutomationFragment, QLSortOrder, QLTodosQuery, QLTodosQueryVariables, useTodosQuery
}                                                                  from "@/graphql/queries/ql/composables";
import {computed, reactive, ref, toRef, useAttrs, useSlots, watch} from "vue";
import currentTeamQuery                                            from "@/composables/queries/currentTeamQuery";
import {
  echoListener
}                                                           from "@/composables/echoListener";
import {
  EventTypes
}                                                           from "@/classes/notifications/EventTypes";
import {
  paginationWithAlreadyLoaded
}                                                           from "@/composables/paginationWithAlreadyLoaded";

interface Props {
  automation: QLFragmentFullAutomationFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const status = ref<"All" | "Open" | "Overdue" | "Scheduled" | "Closed">("All");
const priority = ref<"All" | "Urgent" | "Medium" | "Low">("All");

const automation = toRef(props, "automation");
const selectedUser = ref<string | null>(null);

const statusOptions = [
  {label: "All", value: "All"},
  {label: "Open", value: "Open"},
  {label: "Overdue", value: "Overdue"},
  {label: "Scheduled", value: "Scheduled"},
  {label: "Closed", value: "Closed"}
];
const priorityOptions = [
  {label: "All", value: "All"},
  {label: "Urgent", value: "Urgent"},
  {label: "Medium", value: "Medium"},
  {label: "Low", value: "Low"}
];

const perPageOptions = [
  {label: 10, value: 10},
  {label: 25, value: 25},
  {label: 50, value: 50}
];

const paginationData = reactive({
                                  page:    1,
                                  perPage: 10
                                });

const {currentTeam} = currentTeamQuery();

const apolloVariables = computed((): QLTodosQueryVariables => {

  let q: QLTodosQueryVariables = {
    page:          paginationData.page,
    first:         paginationData.perPage,
    orderBy:       [{column: "updated_at", order: QLSortOrder.DESC}],
    automation_id: automation.value.id.toString()
  };
  if (status.value === "Closed") {
    q.closed = true;
  }
  if (status.value === "Open") {
    q.open = true;
  }
  if (status.value === "Scheduled") {
    q.scheduled = true;
  }
  if (status.value === "Overdue") {
    q.overdue = true;
  }

  if (priority.value == "Low") {
    q.priority = 0;
  }
  if (priority.value == "Medium") {
    q.priority = 1;
  }
  if (priority.value == "Urgent") {
    q.priority = 10;
  }

  if (selectedUser.value) {
    q.assigned_to_id = selectedUser.value;
  }

  return q;
});

const todosQuery = useTodosQuery(apolloVariables);
const {
  paginatorInfo,
  loadedFirstTime,
  results: todos,
  loading
} = paginationWithAlreadyLoaded<QLTodosQuery["todos"]["data"]>(todosQuery);

function resetPage() {
  return () => {
    paginationData.page = 1;
  };
}

watch(() => status, resetPage());
watch(() => paginatorInfo.value.perPage, resetPage());
watch(() => priority, resetPage());
watch(() => selectedUser, resetPage());

echoListener([EventTypes.TodoUpdated, EventTypes.TodoNew], (ev) => {
  if (ev.data.communication_id == automation.value.id) {
    todosQuery.refetch();
  }
}, 10000);

</script>
