<template>
  <div class="p-4 flex items-center  "
       role="button"
       v-bind:class="mainClass"
       @click="changeStep">
    <div>
      <div class="w-8 h-8 flex items-center justify-center rounded-full font-medium icon-container">
        <div class="leading-none inline-block w-4 h-4 text-center icon-text">
          <div v-if="!stepStatus.errors && !stepStatus.completed">
            <slot name="icon"></slot>
          </div>
          <svg-icon name="exclamation-circle" v-if="stepStatus.errors" class="w-6 h-6 -ml-1 -mt-1" />
          <svg-icon name="check" v-if="stepStatus.completed" class="w-6 h-6 -ml-1 -mt-1" />

        </div>
      </div>
    </div>
    <div class="ml-3 leading-tight">
      <p class="uppercase text-sm  font-medium tracking-wide title-container">
        <slot name="title"></slot>
      </p>
      <p class="text-xs description-container">
        <slot name="description"></slot>
      </p>
    </div>
  </div>
</template>
<style scoped>
.step {
  .icon-container {
    @apply border-2 border-gray-400;
  }

  .icon-text {
    @apply text-gray-800;
  }

  .title-container {
    @apply text-gray-800;
  }

  .description-container {
    @apply text-gray-800;
  }
}

.step-completed {
  .icon-container {
    @apply border-2 bg-green-500 border-green-500;
  }

  .icon-text {
    @apply text-white;
  }

  .title-container {
    @apply text-green-800;
  }

  .description-container {
    @apply text-gray-800;
  }
}

.step-errors {
  .icon-container {
    @apply border-2 bg-red-500 border-red-500;
  }

  .icon-text {
    @apply text-white;
  }

  .title-container {
    @apply text-red-800;
  }

  .description-container {
    @apply text-red-800;
  }
}

.step-current {
  .icon-container {
    @apply border-2 bg-indigo-500 border-indigo-500;
  }

  .icon-text {
    @apply text-white;
  }

  .title-container {
    @apply text-indigo-800;
  }

  .description-container {
    @apply text-gray-800;
  }
}

.step-disabled {
  @apply cursor-not-allowed;

  .icon-container {
    @apply border-2 border-gray-400;
  }

  .icon-text {
    @apply text-gray-400;
  }

  .title-container {
    @apply text-gray-500;
  }

  .description-container {
    @apply text-gray-500;
  }
}

.step-disabled:hover {
  @apply bg-transparent;
}

</style>
<script lang="ts" setup>

import {AutomationSingleStepStatus} from "@/components/automations/Create/AutomationStepStatus";
import SvgIcon                      from "@/components/SvgIcon.vue";
import {computed, toRef}            from "vue";

interface Props {

  stepStatus: AutomationSingleStepStatus,
  currentStep: number,
  disabled: boolean,
  saving: boolean
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["change"]);

const canClick = computed(() => {
  return !props.disabled && !props.saving;
});

const mainClass = computed(() => {
  const ret = {
    "step-current":       false,
    "step-disabled":      false,
    "step-completed":     false,
    "step-errors":        false,
    "step":               false,
    "hover:bg-blue-100":  canClick.value,
    "cursor-not-allowed": !canClick.value
  };

  if (props.currentStep === props.stepStatus.step) {
    ret["step-current"] = true;
    return ret;
  }
  if (props.disabled) {
    ret["step-disabled"] = true;
    return ret;
  }

  if (props.stepStatus.completed) {
    ret["step-completed"] = true;
    return ret;
  }
  if (props.stepStatus.errors) {
    ret ["step-errors"] = true;
    return ret;
  }

  ret["step"] = true;
  return ret;
});

const changeStep = () => {
  if (canClick.value) {
    emit("change", props.stepStatus.step);
  }
};

const stepStatus = toRef(props, "stepStatus");
</script>
