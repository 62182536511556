<template>
  <page-title
      section="Automations"
      title="Edit Email Template" />
  <div class="container mx-auto grow">
    <div>
      <router-link
          :to="{name: routeList.automations.email_templates.index}"
          class="text-link inline-flex items-center">
        <svg-icon
            name="left"
            class="w-4 h-4 inline" />
        All Email Templates
      </router-link>
    </div>

    <div class="mt-3">
      <form
          ref="form"
          class="bg-white rounded-lg shadow"
          @submit.prevent="send">
        <div
            v-if="loading"
            class="flex items-center justify-center h-64">
          <loader class="w-8 h-8 text-gray-400" />
        </div>

        <div
            v-if="!loading"
            class="p-4 space-y-4">
          <text-input
              v-model="emailAdditionalData.name"
              :error-message="errors.get('name')"
              label="Name"
              :max-length="254"
              placeholder="Ex: Monthly newsletter"
              required
              small-info="Name this email template. This name will be used when you are selecting this email template in the automations page, so try to use a descriptive name." />

          <div>
            <text-input
                v-model="emailAdditionalData.subject"
                :error-message="errors.get('subject')"
                :max-length="254"
                label="Subject"
                :placeholder="'Welcome to {{STUDIO_NAME}}'"
                small-info="Optional subject of this email template. The subject will be prefilled in the creation of automations as a starting point." />
          </div>

          <div
              v-if="emailAdditionalData.internalData.html"
              class="mt-6">
            <unlayer v-model="emailAdditionalData.internalData" />
          </div>
        </div>

        <div
            v-if="!loading"
            class="w-full flex justify-between p-4 py-4 bg-gray-100 rounded-b-lg space-x-4">
          <div>
            <v-button
                :disabled="sending"
                :loading="sending"
                class="btn btn-link hover:bg-loopspark-700 text-loopspark-700 hover:text-white"
                type="button"
                @click="emailPreviewOpen = true">
              Send preview email...
            </v-button>
          </div>
          <div class="space-x-4">
            <v-button
                v-if="emailTemplate.id !== null && teamsCopiable.length > 0"
                :disabled="sending"
                :loading="sending"
                class="btn btn-link hover:bg-loopspark-500 text-loopspark-800 hover:text-loopspark-50"
                type="button"
                @click="openCopy = true">
              Copy to another studio...
            </v-button>
            <v-button
                v-if="emailTemplate.automation_id === null"
                :disabled="sending"
                :loading="sending"
                class="btn btn-link hover:bg-red-500 text-red-800 hover:text-red-50"
                type="button"
                @click="deleteOpen = true">
              Delete...
            </v-button>
            <v-button
                :disabled="sending"
                :loading="sending"
                class="btn btn-green"
                type="submit">
              Save changes!
            </v-button>
          </div>
        </div>
      </form>
    </div>

    <confirm-modal
        v-model:open="deleteOpen"
        title="Are you sure?"
        type="warning"
        :loading="deleting"
        close-text="No, I want to keep it"
        @clicked="deleteEmail">
      <template #text>
        <p>Are you sure you want to delete this email template?</p>

        <p class="italic text-sm mt-2 text-red-700">
          This action is not reversible
        </p>
      </template>
      <template #button>
        Yes, delete it
      </template>
    </confirm-modal>
    <email-preview
        v-if="emailAdditionalData.internalData.html"
        ref="emailpreview"
        v-model:open="emailPreviewOpen"
        :body="emailAdditionalData.internalData.html"
        :subject="previewSubject" />

    <email-template-copy-dialog
        v-if="emailTemplate"
        v-model:open="openCopy"
        :email-template="emailTemplate"
        :teams="teamsCopiable" />
  </div>
</template>
<script lang="ts" setup>
import TextInput               from "@/components/layout/Forms/TextInput.vue";
import Unlayer                 from "@/components/automations/Unlayer.vue";
import VButton                 from "@/components/layout/VButton.vue";
import Loader                  from "@/components/layout/Loader.vue";
import {
  ref, toRef
}                              from "vue";
import SvgIcon                 from "@/components/SvgIcon.vue";
import routesList              from "@/composables/routesList";
import EmailPreview            from "@/components/automations/EmailPreview.vue";
import {
  loadEmailTemplate
}                              from "@/views/Automations/emailTemplateShowEdit";
import ConfirmModal            from "@/components/layout/ConfirmModal.vue";
import {
  QLUpdateEmailTemplateMutationVariables, useDeleteEmailTemplateMutation, useUpdateEmailTemplateMutation
}                              from "@/graphql/queries/ql/composables";
import $toast                  from "@/composables/toast";
import {
  fillValidationErrorsAndToast
}                              from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import {
  useRouter
}                              from "vue-router";
import PageTitle               from "@/components/navbar/PageTitle.vue";
import meQuery                 from "@/composables/queries/meQuery";
import copiableTeams           from "@/composables/copiableTeams";
import EmailTemplateCopyDialog from "@/views/Automations/Components/EmailTemplates/EmailTemplateCopyDialog.vue";

const router = useRouter();
const {routeList} = routesList();

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {});

const form = ref<HTMLFormElement>(null);
const id = toRef(props, "id");

const emailPreviewOpen = ref(false);
const deleting = ref(false);
const deleteOpen = ref(false);


const {
  currentTeamQ,
  currentTeam,
  emailTemplateQ,
  loading,
  emailTemplate,
  sending,
  errorsData,
  errors,
  emailAdditionalData,
  previewSubject
} = loadEmailTemplate(id);


// Sending
const sendMutation = useUpdateEmailTemplateMutation({});

sendMutation.onDone((data) => {
  $toast({
           type:    "success",
           message: "Email template updated!"
         });
  sending.value = false;
});

sendMutation.onError((err) => {
  fillValidationErrorsAndToast(err, errorsData);
  sending.value = false;
});

const send = () => {
  errorsData.value = {};
  if (!form.value.reportValidity()) {
    return;
  }

  sending.value = true;

  const data: QLUpdateEmailTemplateMutationVariables = {
    id:            id.value,
    email_content: {
      html: emailAdditionalData.internalData.html,
      json: JSON.stringify(emailAdditionalData.internalData.json)
    },
    name:          emailAdditionalData.name,
    subject:       emailAdditionalData.subject
  };

  sendMutation.mutate(data);
};

// Deleting
const deleteMutation = useDeleteEmailTemplateMutation({});
deleteMutation.onDone((data) => {
  $toast({
           type:    "success",
           message: "Email template was deleted!"
         });
  sending.value = false;
  router.push({name: routeList.automations.email_templates.index});
});

deleteMutation.onError((err) => {
  fillValidationErrorsAndToast(err, errorsData);
  sending.value = false;
});

const deleteEmail = () => {
  sending.value = true;
  deleteMutation.mutate({id: id.value});
};

const me = meQuery();
const {teamsCopiable} = copiableTeams(me.me);
const openCopy = ref(false);

</script>
