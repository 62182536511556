<template>
  <div v-if="requiresApi">
    <studio-settings-set-api-mind-body v-if="team.provider_type ===providers.MindBody" />
    <studio-settings-set-api-fit-degree v-if="team.provider_type ===providers.FitDegree" />
    <studio-settings-set-api-pike v-if="team.provider_type ===providers.Pike13" />
    <studio-settings-set-api-mariana-tek v-if="team.provider_type ===providers.MarianaTek" :team="team"/>
  </div>
</template>
<script lang="ts" setup>

import StudioSettingsSetApiMindBody                     from "@/views/Studio/Settings/Components/Settings/Apis/StudioSettingsSetApiMindBody.vue";
import StudioSettingsSetApiFitDegree                    from "@/views/Studio/Settings/Components/Settings/Apis/StudioSettingsSetApiFitDegree.vue";
import StudioSettingsSetApiPike                         from "@/views/Studio/Settings/Components/Settings/Apis/StudioSettingsSetApiPike.vue";
import StudioSettingsSetApiMarianaTek                         from "@/views/Studio/Settings/Components/Settings/Apis/StudioSettingsSetApiMarianaTek.vue";
import {computed, toRef}                                from "vue";
import {QLFragmentCurrentTeamFragment, QLProvidersEnum} from "@/graphql/queries/ql/composables";

interface Props {
  team: QLFragmentCurrentTeamFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const requiresApi = computed(() => {
  return props.team && props.team.site_id === null;
});

const providers = QLProvidersEnum;
const team = toRef(props, "team");

</script>
