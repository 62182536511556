import {ApolloCache} from "@apollo/client/cache/core/cache";

export const updateCache = {
	deleteById: (id, query, queryResultName, store: ApolloCache<any>) => {
		const dataInCache = store.readQuery({query});
		if (dataInCache) {
			const d = dataInCache[queryResultName].filter((t) => t.id != id);
			const data = {};
			data[queryResultName] = d;
			store.writeQuery({query, data});
		}
	}
};
