<template>
  <div>
    <client-info-stats
        v-if="!client.is_lead"
        :client="client"
        class="mb-8" />
    <client-note
        v-if="client.note_yellow"
        :note="client.note_yellow"
        class="mb-4"
        color="yellow" />
    <client-note
        v-if="client.note_red"
        :note="client.note_red"
        class="mb-4"
        color="red" />
    <client-note
        v-if="client.note_generic"
        :note="client.note_generic"
        class="mb-4"
        color="blue" />
    <div :class="['grid gap-6 mb-4 lg:grid-cols-2', !client.is_lead && (client.note_yellow || client.note_red || client.note_generic) ? 'mt-8' :'']">
      <div>
        <description-list>
          <template #title>
            Account information
          </template>
          <template #right>
            <div>
              <button @click="archiveOpen = true"
                      v-if="client.is_lead && !client.frozen"
                      type="button"
                      class="ml-3 relative inline-flex items-center px-4 py-2 border border-red-300 shadow-sm text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                <svg-icon name="trash" class="-ml-1 mr-2 h-5 w-5 text-red-400" />
                <span> Archive </span>
              </button>

              <button @click="unArchiveOpen = true"
                      v-if="client.is_lead && client.frozen"
                      type="button"
                      class="ml-3 relative inline-flex items-center px-4 py-2 border border-green-300 shadow-sm text-sm font-medium rounded-md text-green-700 bg-white hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                <svg-icon name="unarchive" class="-ml-1 mr-2 h-5 w-5 text-green-400" />
                <span> Un-archive </span>
              </button>

              <button @click="leadModalOpen = true"
                      v-if="client.is_lead"
                      type="button"
                      class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <svg-icon name="userEdit" class="-ml-1 mr-2 h-5 w-5 text-gray-400" />
                <span> Edit </span>
              </button>
            </div>
          </template>
          <template #items>
            <description-list-item>
              <template #title>
                Email
              </template>
              <template #value>
                <a
                    :href="'mailto:' + client.email"
                    class="text-blue-800">{{ client.email }}</a>
              </template>
            </description-list-item>
            <description-list-item>
              <template #title>
                Mobile Phone
              </template>
              <template #value>
                {{ phone(client.mobile_phone) }}
              </template>
            </description-list-item>
            <description-list-item v-if="client.mbo_member_status">
              <template #title>
                Member Status
              </template>
              <template #value>
                {{ client.mbo_member_status }}
              </template>
            </description-list-item>
            <description-list-item v-if="client.creation_date || client.is_lead">
              <template #title>
                Creation Date
              </template>
              <template #value>
                <timestamp-to-date
                    v-if="!client.is_lead && client.creation_date"
                    :timestamp="client.creation_date"
                    has-date
                    year-format="yyyy" />

                <timestamp-to-date
                    v-if="client.is_lead && client.created_at"
                    :timestamp="client.created_at"
                    has-date
                    year-format="yyyy" />
              </template>
            </description-list-item>

            <description-list-item v-if="client.referred_by">
              <template #title>
                Referred by
              </template>
              <template #value>
                {{ client.referred_by }}
              </template>
            </description-list-item>

            <description-list-item
                v-for="indexes in client.mbo_index_values"
                :key="indexes.id">
              <template #title>
                {{ indexes.mbo_index.name }}
              </template>
              <template #value>
                {{ indexes.name }}
              </template>
            </description-list-item>
          </template>
        </description-list>
      </div>
      <div v-if="!client.is_lead">
        <description-list>
          <template #title>
            Personal information
          </template>
          <!--          <template #subtitle>-->
          <!--            Ac-->
          <!--          </template>-->
          <template #items>
            <description-list-item v-if="client.birthday">
              <template #title>
                Birthday
              </template>
              <template #value>
                <timestamp-to-date
                    :timestamp="client.birthday"
                    utc
                    has-date
                    year-format="yyyy" />
                <span class="text-sm text-gray-600"> ({{ age(client.birthday) }} years old)</span>
              </template>
            </description-list-item>
            <description-list-item>
              <template #title>
                Home Phone
              </template>
              <template #value>
                {{ phone(client.phone_home) }}
              </template>
            </description-list-item>
            <description-list-item>
              <template #title>
                Work Phone
              </template>
              <template #value>
                {{ phone(client.phone_work) }}
              </template>
            </description-list-item>
            <description-list-item>
              <template #title>
                Address
              </template>
              <template #value>
                {{ address }}
              </template>
            </description-list-item>
            <description-list-item v-if="client.job_title">
              <template #title>
                Job Title
              </template>
              <template #value>
                {{ client.job_title }}
              </template>
            </description-list-item>
            <description-list-item v-if="client.job_organization">
              <template #title>
                Company
              </template>
              <template #value>
                {{ client.job_organization }}
              </template>
            </description-list-item>

            <description-list-item v-if="client.bio">
              <template #title>
                Bio
              </template>
              <template #value>
                {{ client.bio }}
              </template>
            </description-list-item>

          </template>
        </description-list>
      </div>

      <div>
        <div class="bg-white shadow overflow-hidden sm:rounded-lg">
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900 ">
              Reachability
            </h3>
          </div>
          <div class="border-t border-gray-200 px-4 sm:px-6">
            <div class="divide-y divide-gray-100">
              <div class="py-4 sm:py-5">
                <div class="pb-2">
                  <div class="flex justify-between">
                    <span class="text-lg">Email</span>
                    <div class="inline-flex items-center" v-if="client.can_contact_by_email">
                      <div class="rounded-full bg-green-500 w-5 h-5 shrink-0 flex items-center justify-center mr-1.5">
                        <svg-icon name="check" class="w-4 h-4 block text-white"></svg-icon>
                      </div>
                      <span class="text-green-700 font-medium">Yes</span>
                    </div>
                    <div class="inline-flex items-center" v-if="!client.can_contact_by_email">
                      <div class="rounded-full bg-red-500 w-5 h-5 shrink-0 flex items-center justify-center mr-1.5">
                        <svg-icon name="close" class="w-4 h-4 block text-white"></svg-icon>
                      </div>
                      <span class="text-red-700 font-medium">No</span>
                    </div>
                  </div>
                </div>
                <div
                    v-if="client.can_contact_by_email"
                    class="text-sm pt-1">
                  <p>{{ client.first_name }} can receive emails from LoopSpark.</p>
                  <button
                      class="text-blue-800"
                      @click="modals.unsubEmail = true">
                    Unsubscribe {{
                      client.first_name
                    }}...
                  </button>
                  <client-unsubscribe-modal
                      v-model:open="modals.unsubEmail"
                      :client="client"
                      type="email" />
                </div>
                <div
                    v-if="!client.can_contact_by_email && client.email && client.unsubscribed_email_at > 0"
                    class="">
                  <p class="text-sm pt-1 text-red-900">
                    {{ client.first_name }} has
                    unsubscribed from LoopSpark
                    on
                    <timestamp-to-date
                        :timestamp="client.unsubscribed_email_at"
                        has-date
                        has-time />
                    <client-send-opt-in-reminder :client="client" />
                  </p>
                </div>
              </div>
              <div class="py-4 sm:py-5">
                <div class="pb-2">
                  <div class="flex justify-between">
                    <span class="text-lg">Text</span>
                    <div class="inline-flex items-center" v-if="client.can_contact_by_sms">
                      <div class="rounded-full bg-green-500 w-5 h-5 shrink-0 flex items-center justify-center mr-1.5">
                        <svg-icon name="check" class="w-4 h-4 block text-white"></svg-icon>
                      </div>
                      <span class="text-green-700 font-medium">Yes</span>
                    </div>
                    <div class="inline-flex items-center" v-if="!client.can_contact_by_sms">
                      <div class="rounded-full bg-red-500 w-5 h-5 shrink-0 flex items-center justify-center mr-1.5">
                        <svg-icon name="close" class="w-4 h-4 block text-white"></svg-icon>
                      </div>
                      <span class="text-red-700 font-medium">No</span>
                    </div>
                  </div>
                </div>
                <div
                    v-if="client.can_contact_by_sms"
                    class="text-sm pt-1">
                  <p>{{ client.first_name }} can receive text messages from LoopSpark.</p>
                  <button
                      class="text-blue-800"
                      @click="modals.unsubSms = true">
                    Unsubscribe {{
                      client.first_name
                    }}...
                  </button>
                  <client-unsubscribe-modal
                      v-model:open="modals.unsubSms"
                      :client="client"
                      type="sms" />
                </div>
                <div
                    v-if="!client.can_contact_by_sms"
                    class="text-sm pt-1 text-red-900">
                  <p v-if="!client.mobile_phone">
                    Missing phone number for {{ client.first_name }}
                  </p>

                  <p v-if="client.mobile_phone && client.unsubscribed_sms_at > 0 && client.unsubscribed_sms_text">
                    {{ client.first_name }} has
                    unsubscribed from LoopSpark on
                    <timestamp-to-date
                        :timestamp="client.unsubscribed_sms_at"
                        has-date
                        has-time />
                  </p>
                  <p v-if="client.mobile_phone && client.unsubscribed_sms_at > 0 && !client.unsubscribed_sms_text">
                    {{ client.first_name }} cannot receive SMS communications
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <client-carousel
          v-if="client.photos && client.photos.length > 0"
          :photos="client.photos" />
    </div>
    <update-lead-modal v-model:open="leadModalOpen" :client="client" />
    <archive-lead-modal :client="client" v-model:open="archiveOpen" />
    <unarchive-lead-modal :client="client" v-model:open="unArchiveOpen" />

  </div>
</template>
<script lang="ts" setup>
import DescriptionListItem              from "@stories/components/layout/DescriptionListItem.vue";
import ClientNote                       from "@/components/client/ClientNote.vue";
import phone                            from "@/classes/vue-filters/PhoneFilter";
import age                              from "@/classes/vue-filters/AgeFilter";
import TimestampToDate                  from "@/components/layout/TimestampToDate.vue";
import ClientCarousel                   from "@/components/client/ClientCarousel.vue";
import ClientUnsubscribeModal           from "@/components/client/ClientUnsubscribeModal.vue";
import ClientInfoStats                  from "@/components/client/Info/ClientInfoStats.vue";
import ClientSendOptInReminder          from "@/components/client/Info/ClientSendOptInReminder.vue";
import {QLClient}                       from "@/graphql/queries/ql/composables";
import SvgIcon                          from "@/components/SvgIcon.vue";
import {computed, reactive, ref, toRef} from "vue";
import DescriptionList                  from "@stories/components/layout/DescriptionList.vue";
import UpdateLeadModal                  from "@/views/ClientShow/Components/Info/UpdateLeadModal.vue";
import ArchiveLeadModal                 from "@/views/ClientShow/Components/Info/ArchiveLeadModal.vue";
import UnarchiveLeadModal               from "@/views/ClientShow/Components/Info/UnarchiveLeadModal.vue";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");
const leadModalOpen = ref(false);
const archiveOpen = ref(false);
const unArchiveOpen = ref(false);
const address = computed(() => {
  let address = "";
  if (client.value.address_line) {
    address += client.value.address_line;
  }
  if (client.value.address_city) {
    address += (address.length > 0 ? ", " : "") + client.value.address_city;
  }
  if (client.value.address_postal_code) {
    address += (address.length > 0
                ? ", "
                : "") + client.value.address_postal_code;
  }
  if (client.value.address_state) {
    address += (address.length > 0 ? ", " : "") + client.value.address_state;
  }

  if (address === "") {
    return null;
  }

  return address;
});

const modals = reactive({
                          unsubEmail: false,
                          unsubSms:   false
                        });
</script>
