<template>
  <input-field-base
      v-if="fieldModel"
      v-model:field="fieldModel"
      v-model:open="openModel"
      :name-already-used="nameAlreadyUsed"
      :can-save="canSave"
      @cancelled="openModel = false"
      @saved="saved">
    <template #fields>
      <div class="">
        <div class="text-sm font-medium text-gray-700">
          Description
        </div>
        <div
            class="mt-1 text-sm text-gray-900">
          <textarea
              v-model="fieldModel.description"
              class="form-input" />
        </div>
      </div>
      <form-check-box
          v-model="fieldModel.optional"
          title="Optional" />

      <mapping-select
          v-if="useMappings"
          v-model:mapped="fieldModel.mapping"
          :mappings="availableMappings"
          :original-map="field.mapping" />
<!--      <form-check-box-->
<!--          v-model="fieldModel.prefill"-->
<!--          title="Prefill"-->
<!--          value-for-on="email">-->
<!--        <template #default>-->
<!--          Pre-fill this field with the email information your client provided in the first-->
<!--          step "new or existing client."-->
<!--        </template>-->
<!--      </form-check-box>-->
    </template>
  </input-field-base>
</template>
<script lang="ts" setup>
import {computed, withDefaults} from "vue";
import FormCheckBox
                                                          from "@/components/Forms/Fields/Input/Partials/FormCheckBox.vue";
import InputFieldBase                                     from "@/components/Forms/Fields/Input/InputFieldBase.vue";
import InputFieldsFunctions                               from "@/components/Forms/Fields/Input/InputFieldsFunctions";
import {QLFormField}                                      from "@/graphql/queries/ql/composables";
import MappingSelect from "@/components/Forms/Fields/Input/Partials/MappingSelect.vue";

interface InputFieldProps {
  field: QLFormField;
  open: boolean;
  otherFields: QLFormField[];
  useMappings: boolean;
}

const props = withDefaults(defineProps<InputFieldProps>(), {});
const emit = defineEmits(["deleted", "update:field", "saved", "update:open"]);

const {fieldModel, openModel, otherFields, saved, toRun, nameAlreadyUsed} = InputFieldsFunctions.bootstrapEditField(emit,
                                                                                                                    props);
toRun();

const availableMappings = InputFieldsFunctions.availableMappings(fieldModel, otherFields.value);

const canSave = computed(() => {
  if (!props.field) {
    return false;
  }
  if (InputFieldsFunctions.nameIsAlreadyUsed(otherFields.value, fieldModel.value)) {
    return false;
  }

  return fieldModel.value.title.trim().length > 0;
});
</script>

