<template>
  <div class="inline-block">
    <div class="inline-flex content-center rounded-full text-sm truncate" v-bind:class="containerClasses">
      <div class="w-5 h-5 rounded-full self-center inline-block inline-flex content-center justify-center items-center"
           v-bind:class="iconClasses">
        <div class="w-3 h-3">
          <svg-icon :name="icon" class="w-full h-full"></svg-icon>
        </div>
      </div>
      <div class="inline-block pr-2 pl-1 font-medium">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {computed, PropType, toRef} from "vue";
import {PossibleIcons}             from "@/assets/icons/iconNames";
import SvgIcon                     from "@/components/SvgIcon.vue";

export default {
  name:  "TagWithIcon",
  components: {SvgIcon},
  props: {
    icon:  {
      type:     String as PropType<PossibleIcons>,
      required: true
    },
    color: {
      type:     String as PropType<"green" | "gray" | "blue" | "yellow" | "red" | "loopspark" | "purple">,
      required: true
    }
  },
  setup(props) {
    const color = toRef(props, "color");
    const containerClasses = computed(() => {
      return [
        "bg-" + color.value + "-100",
        "text-" + color.value + "-800"
      ];
    });

    const iconClasses = computed(() => {
      return ["bg-" + color.value + "-300"];
    });

    return {
      containerClasses,
      iconClasses
    };
  }
};
</script>
