<template>
  <div class="flex items-center py-2">
    <hr class="grow">
    <div class="relative inline-block">
      <div
          class="mx-2 text-gray-500 text-xs inline-flex items-center cursor-pointer hover:text-blue-500"
          role="button"
          @click="andOrSelectorOpen = !andOrSelectorOpen"
      >
        <span v-text="isAnd ? 'AND': 'OR'"/>
        <svg-icon
            name="down"
            class="w-4 h-4"
        />
      </div>
      <div v-show="andOrSelectorOpen">
        <div class="origin-top-left absolute left-0 mt-1 w-40 lg:w-56 rounded-md shadow-xl">
          <div class="rounded-md bg-white shadow-xs">
            <div
                aria-labelledby="options-menu"
                aria-orientation="vertical"
                class="py-1"
                role="menu"
            >
              <div
                  class="cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                  @click="chooseAndOrSelector(true)"
              >
                                <span
                                    class="block font-medium"
                                    :class="{'font-bold': isAnd}"
                                >AND</span>
                <div class="text-xs text-gray-500">
                  Add filters from same category to filter existing rule.
                </div>
              </div>
              <div
                  class="cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                  @click="chooseAndOrSelector(false)"
              >
                                <span
                                    class="block font-medium"
                                    :class="{'font-bold': !isAnd}"
                                >OR</span>
                <div class="text-xs text-gray-500">
                  Filter by clients that match at least 1 filter in this rule.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="grow">
  </div>
</template>
<script lang="ts" setup>

import SvgIcon      from "@/components/SvgIcon.vue";
import {ref, toRef} from "vue";

interface Props {
  modelValue: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:modelValue"]);

const isAnd = toRef(props, "modelValue");

const andOrSelectorOpen = ref(false);

const chooseAndOrSelector = (isAnd) => {
  emit("update:modelValue", isAnd);
  andOrSelectorOpen.value = false;
};

</script>
