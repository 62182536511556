import Operator, {FilterExportedData, FilterPossibleValue} from "./operator";
import Filter                                              from "hm-filters/base-filter";
import {OperatorTypes}                                     from "hm-filters/operators/OperatorTypes";

class FixedValueOperator extends Operator {
	// hasInput = false;
	get type(): string {
		return OperatorTypes.FixedValue;
	}

	exportObject(filter: Filter, myvalue: FilterPossibleValue): FilterExportedData {
		return {
			field:    filter.options.field,
			operator: "=",
			value:    this.value
		};
	}
}

export class OperatorAsFixedValueOperator extends FixedValueOperator {
	// hasInput = false;

	get operator() {
		return this.value;
	}

	exportObject(filter: Filter, myvalue: FilterPossibleValue): FilterExportedData {
		return {
			field:    filter.options.field,
			operator: this.value,
			value:    myvalue != null ? myvalue : this.value
		};
	}

}

export default FixedValueOperator;
