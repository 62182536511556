<template>
  <confirm-modal
      v-model:open="openModel"
      :loading="deleting"
      type="warning"
      close-text="No, go back"
      title="Archive this automation?"
      @close="openModel = false"
      @clicked="performAction">
    <template #button>
      Yes, archive it!
    </template>
    <template #text>
      <p>
        Archived automations will deactivate and will not be editable until unarchived. Archive when you are confident
        this automation will not be used in the near future and would like to remove the automation from your automation
        list.
      </p>
    </template>
  </confirm-modal>
</template>
<script lang="ts">

import ConfirmModal           from "@/components/layout/ConfirmModal.vue";
import archiveUnarchiveDialog from "@/components/automations/Show/archiveUnarchiveDialog";

const dialog = archiveUnarchiveDialog(true,
                                      "The automation has been archived",
                                      "There was an error while archiving. Please try again");

export default {
  components: {
    ConfirmModal
  },
  props:      {
    ...dialog.component.props
  },
  emits:      dialog.component.emits,
  setup(props, {emit}) {
    return dialog.insideSetup(props, emit);
  }

};

</script>
