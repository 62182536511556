export function hoist(el) {
	if (el.tagName === "TEMPLATE") {
		el.replaceWith(el.content);
	} else {
		const classes = el.getAttribute('class');
		if(el.children.length > 0){
			el.children[0].setAttribute('class', classes);
		}
		el.replaceWith(...el.children);
	}
}
