import {computed, isRef, reactive, ref, Ref}                         from "vue";
import {QLFragmentClientFullFragment, QLFragmentClientSmallFragment} from "@/graphql/queries/ql/composables";
import {DecoratorInterface}                                          from "@/composables/decorators/DecoratorInterface";

export function ClientDecorator(passed: Ref<QLFragmentClientFullFragment | QLFragmentClientSmallFragment> | QLFragmentClientFullFragment | QLFragmentClientSmallFragment){

	const entity = isRef(passed) ? passed : ref(passed);

	const isLead = computed<boolean>(() => {
		return entity.value.is_lead === true;
	});

	const isActive = computed(() => {
		if (isInactive.value || isLead.value || isProspect.value) {
			return false;
		}
		return entity.value.active;
	});

	const isInactive = computed(() => {
		return entity.value.active !== true && !isProspect.value && !isLead.value;
	});

	const isProspect = computed(() => {
		return entity.value.is_prospect;
	});

	return {
		computed: reactive({
			                   isLead,
			                   isActive,
			                   isInactive,
			                   isProspect
		                   })
	};
}
