<template>
  <div class="px-2 md:px-0">
    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Audience information
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>Name and construct your audience segment.</p>
          <div class="text-base pt-4">
            Tip:
          </div>
          <p>
            Each audience is saved and can be used to build new automations. <br> Naming your audience in a manner
            that
            clearly defines the purpose will help you quickly search for this audience when creating a similar
            automation (example: <span class="italic">"After client’s 3rd attendance using 7-day intro offer"</span>).
          </p>
        </div>
      </div>

      <div class="block  flex-1 ">
        <div class="p-4 space-y-6 shadow-md rounded-lg bg-white">
          <text-input
              v-model="audienceName"
              :error-message="formErrors.get('segment.name')"
              data-name
              label="Audience name"
              placeholder="e.g 'November Newsletter - All Active Clients'"
              required
          >
            <div class="leading-tight form-small-info text-gray-600">
              Hint: Use names that describe the audience purpose, e.g 'Intro package expiring in 7 days - no membership
              purchased'
            </div>
          </text-input>
        </div>
      </div>
    </div>

    <div class="md:flex md:flex-row-reverse mt-16">
      <div class="block flex-1 md:pl-4">
        <div class="text-lg font-medium text-gray-900">
          Segment
        </div>

        <div class="mt-2 shadow-md rounded-lg bg-white">
          <segment-view-edit
              v-model="segmentModel"
              :editable="true"
              class="p-4"
          />
        </div>
      </div>

      <div class="mt-16 md:mt-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Audience preview
        </div>

        <div
            v-if="loading"
            class="flex items-center justify-center h-20"
        >
          <div class="w-8 h-8 text-gray-600">
            <loader class="w-8-h-8" />
          </div>
        </div>

        <div v-if="!loading && data.hasErrors">
          <div class="p-4 bg-red-50 border border-red-200 rounded-md mt-2 text-red-900 text-sm">
            <span class="font-semibold">There was an error with your segment.</span>
            <p class="text-xs mt-1">
              Please check your rules and try again.
            </p>
          </div>
        </div>

        <div
            v-if="!loading && !data.hasErrors"
            class="mt-2"
        >
          <div class="mb-4">
            <span>The audiences matches <span class="font-medium italic">{{ foundClients }}</span> clients</span>
          </div>

          <div class="shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg">
            <table class="table divide-y">
              <thead>
              <tr>
                <table-th>Client</table-th>
                <table-th>Club Count</table-th>
                <table-th>Last Attended</table-th>
              </tr>
              </thead>
              <tbody
                  v-if="!loading && clients && clients.length > 0"
                  class="bg-white divide-y divide-gray-200"
              >
              <automation-form-audience-preview-row
                  v-for="client in clients"
                  :key="client.id"
                  :client="client"
              />
              </tbody>
              <tbody
                  v-if="!loading && clients && clients.length == 0"
                  class="bg-white divide-y divide-gray-200"
              >
              <tr>
                <td colspan="4">
                  <div class="p-12 text-center">
                    <p class="text-gray-500">
                      There are no clients matching the selected segment.
                    </p>
                  </div>
                </td>
              </tr>
              </tbody>
              <tr v-if="clients && paginatorInfo && paginatorInfo.total > 0&& paginatorInfo.lastPage > 1">
                <td colspan="4">
                  <div class="w-full py-2 text-right px-4 rounded-b">
                    <pagination
                        v-if="paginatorInfo && paginatorInfo.lastPage > 1"
                        :pagination="paginatorInfo"
                        class="flex items-center justify-between text-gray-700 text-sm"

                        @setPage="data.page = $event"
                    >
                      <div
                          v-if="loading"
                          class="inline-block w-8 h-8 mr-2 text-gray-400"
                      >
                        <loader />
                      </div>
                    </pagination>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import {QLSegment}                    from "@/graphql/queries/ql/composables";
import SparkFormErrors, {SparkErrors} from "@/classes/utils/SparkFormErrors";
import TextInput                      from "@/components/layout/Forms/TextInput.vue";
import SegmentViewEdit                  from "@/components/Segment/SegmentViewEdit.vue";
import TableTh                          from "@/components/layout/Tables/TableTh.vue";
import AutomationFormAudiencePreviewRow from "@/components/automations/Create/partials/AutomationFormAudiencePreviewRow.vue";
import Loader                           from "@/components/layout/Loader.vue";
import Pagination                       from "@/components/layout/Pagination.vue";
import {computed, reactive}             from "vue";
import SegmentDecorator                 from "@/composables/decorators/SegmentDecorator";
import {previewSegment}                 from "@/components/automations/Create/composables/previewSegment";


interface Props {
  segment: QLSegment;
  errors: SparkErrors;
}

const props = withDefaults(defineProps<Props>(), {});


const emit = defineEmits([
                           "update:segment", "update:errors"
                         ]);

const data = reactive({
                        page:      1,
                        hasErrors: false
                      });

const segmentModel = computed({
                                get: () => props.segment,
                                set: (val) => emit("update:segment", val)
                              });

const audienceName = computed({
                                get: () => props.segment.name,
                                set: (val) => {
                                  const err = new SparkFormErrors(props.errors);
                                  err.forget("segment.name");

                                  segmentModel.value.name = val;
                                  emit("update:errors", err.all());
                                }
                              });

const formErrors = computed(() => {
  return new SparkFormErrors(props.errors);
});

const segmentToPost = computed(() => {
  const {name, ...restOfSegment} = SegmentDecorator(segmentModel.value).computed.toPost;
  restOfSegment.name = "WIP";
  return restOfSegment;
});

const {
  clients,
  paginatorInfo,
  foundClients,
  loading,
} = previewSegment(data, segmentToPost);



</script>
