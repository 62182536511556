<template>
  <div>
    <div class="relative focus-within:text-loopspark-800">
      <input
          v-model="name"
          :class="inputClasses"
          class="w-full text-sm form-input transition rounded py-1 px-2 pl-10 ring-0 appearance-none leading-normal"
          placeholder="Search clients"
          type="search">
      <div class="absolute left-0 top-0 mt-2 ml-2">
        <loader
            v-if="isFetching"
            class="w-4 h-4" />
        <svg-icon
            v-if="!isFetching"
            class="fill-current text-gray-300 pointer-events-none w-4 h-4"
            name="search" />
      </div>
    </div>
    <div
        v-if="shouldBeOpen"
        class="absolute ml-0 mt-0 transform z-30">
      <div class="rounded-lg shadow-lg">
        <div class="rounded-lg shadow-xs overflow-hidden">
          <div class="z-20 relative bg-white py-2">
            <div
                v-for="client in clients_search.data"
                :key="client.id"
                class="cursor-pointer hover:bg-blue-200 px-5 py-2"
                @click="onHit(client)">
              <div class="flex items-center">
                <div class="inline-block">
                  <client-avatar
                      :client="client"
                      class="w-8 h-8" />
                </div>
                <div class="ml-2 text-gray-800">
                  <span class="whitespace-nowrap">{{ client.first_name }} {{ client.last_name }}</span>
                  <div class="text-sm text-gray-600">
                    <client-status-text :client="client" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import ClientAvatar                                           from "@/components/client/ClientAvatar.vue";
import Loader                                                 from "@/components/layout/Loader.vue";
import ClientStatusText                                       from "@/components/client/ClientStatusText.vue";
import Routes                                                 from "@/router/Routes";
import {computed, ref, useAttrs, useSlots}                    from "vue";
import {QLFragmentClientSmallFragment, useClientsSearchQuery} from "@/graphql/queries/ql/composables";
import SvgIcon                                                from "@/components/SvgIcon.vue";
import {useRouter}                                            from "vue-router";

interface Props {
  placeholder?: string;
  goOnHit?: boolean;
  onlyContactableViaEmail?: boolean;
  onlyContactableViaSms?: boolean;
  inputClasses?: string;
}

const props = withDefaults(defineProps<Props>(), {
  placeholder:             "Search for a client",
  goOnHit:                 true,
  onlyContactableViaEmail: false,
  onlyContactableViaSms:   false,
  inputClasses:            ""
});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["selected"]);

const name = ref("");
const $router = useRouter();

const {result, loading: isFetching} = useClientsSearchQuery(() => ({
                                                              search:                  name.value,
                                                              first:                   5,
                                                              page:                    0,
                                                              onlyContactableViaEmail: props.onlyContactableViaEmail,
                                                              onlyContactableViaSms:   props.onlyContactableViaSms
                                                            }),
                                                            () => ({
                                                              debounce: 500,
                                                              enabled:  name.value.length > 2
                                                            }));

// const clients_search = useResult(result, [], data => data.clients_search);
const clients_search = computed(() => result.value?.clients_search ?? {data: []});

const shouldBeOpen = computed(() => {
  return name.value.length > 2 && clients_search.value.data.length > 0; //
});

function onSelected(item) {
  emit("selected", item);
}

function onHit(item: null | QLFragmentClientSmallFragment) {
  name.value = "";
  if (props.goOnHit) {
    if (item != null) {
      $router.push({name: Routes.client.show, params: {id: item.id!.toString()}});
    }
    return false;
  }

  onSelected(item);
}

</script>
