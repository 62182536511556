<template>
  <div>
    <button
        v-if="canBeSent"
        class="text-blue-800 text-sm hover:text-blue-500"
        @click="modalOpen = true">
      Send an opt-in
      reminder...
    </button>

    <confirm-modal
        v-model:open="modalOpen"
        :loading="sending"
        type="regular"
        close-text="No, go back"
        :title="'Send an opt-in reminder to ' + client.first_name + '?'"
        @close="modalOpen = false"
        @clicked="sendEmail()">
      <template #button>
        Yes, send the email it!
      </template>
      <template #icon>
        <svg
            class="h-6 w-6 text-loopspark-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24">
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>
      </template>
      <template #text>
        <p class="text-sm leading-5 text-gray-600 text-left">
          {{ client.first_name }} is currently unsubscribed from email communications. You can send
          {{ client.first_name }} containing information to opt-in to email communications from
          {{ currentTeam.name }}.
        </p>
        <p class="text-sm leading-5 text-gray-600 mt-2 text-left">
          {{ client.first_name }} will not receive emails from LoopSpark's automations until they manage their
          email preferences by clicking on the opt-in email reminder.
        </p>
        <p class="text-xs italic text-gray-600 mt-4 text-left">
          Note: You can send this reminder only once in a 7 day
          period.
        </p>
      </template>
    </confirm-modal>
  </div>
</template>
<script lang="ts" setup>
import {DateTime}                                    from "luxon";
import {computed, ref, toRef, useAttrs, useSlots}    from "vue";
import {QLClient, useSendEmailOptInReminderMutation} from "@/graphql/queries/ql/composables";
import currentTeamQuery                              from "@/composables/queries/currentTeamQuery";
import ConfirmModal                                  from "@/components/layout/ConfirmModal.vue";
import $toast                                        from "@/composables/toast";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const sending = ref(false);

const {currentTeam} = currentTeamQuery();

const modalOpen = ref(false);
const client = toRef(props, "client");

const canBeSent = computed((): boolean => {
  if (client.value.email && client.value.unsubscribed_email_at > 0) {
    if (client.value.email_opt_in_reminder_at == null) {
      return true;
    }
    if (client.value.email_opt_in_reminder_at > 0 && Math.abs(DateTime.fromSeconds(client.value.email_opt_in_reminder_at)
                                                                      .diffNow("days").days) > 7) {
      return true;
    }
  }

  return false;
});

const SendReminderMutation = useSendEmailOptInReminderMutation({});
SendReminderMutation.onError((e) => {
  sending.value = false;
  $toast({
           type:         "error",
           message:      "There was an error while sending the email opt-in reminder to " + client.value.first_name,
           linkRouteObj: null,
           linkText:     null
         });
});

SendReminderMutation.onDone(() => {
  sending.value = false;
  $toast({
           type:         "success",
           message:      "Email Opt-In reminder sent to " + client.value.first_name,
           linkRouteObj: null,
           linkText:     null
         });
});


const sendEmail = () => {
  sending.value = true;
  SendReminderMutation.mutate({id: client.value.id});
  modalOpen.value = false;
};

</script>
