<template>
  <div class="w-full lg:w-2/3 max-w-sm">

    <button aria-label="Search"
            class="flex items-center w-full text-sm form-input transition rounded py-1 px-2 ring-0 appearance-none leading-normal bg-white border-loopspark-400 text-gray-500"
            type="button"
            @click="open = true">
      <span class="items-center flex grow">
        <span class="flex items-center grow">
            <svg-icon
                class="fill-current pointer-events-none w-4 h-4 inline-block"
                name="search" />
           <span
               class="pl-2 hidden md:inline text-sm">Search</span>
          </span>
        <!--          <kbd class="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400">{{keyString}}K</kbd>-->
      </span>
    </button>
  </div>

  <TransitionRoot
      :show="open"
      appear
      as="template"
      @after-leave="query = ''">
    <Dialog
        as="div"
        class="relative z-10"
        @close="open = false">
      <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95">
          <DialogPanel class="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
            <Combobox
                v-slot="{ activeOption }"
                default-value=""
                nullable
                @update:modelValue="onSelect">
              <div class="relative">
                <SvgIcon
                    aria-hidden="true"
                    class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    name="search" />

                <ComboboxInput
                    class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    @change="query = $event.target.value" />

                <Loader
                    v-if="isFetching"
                    aria-hidden="true"
                    class="pointer-events-none absolute right-3.5 top-3.5 h-5 w-5 text-gray-400" />
              </div>

              <ComboboxOptions
                  v-if="filteredPeople.length > 0"
                  as="div"
                  class="flex divide-x divide-gray-100"
                  hold
                  static>
                <div :class="['max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4 pb-6 flex flex-col justify-between', activeOption && 'sm:h-96']">
                  <div
                      class="-mx-2 text-sm text-gray-700"
                      hold>
                    <ComboboxOption
                        v-for="person in filteredPeople"
                        :key="person.id"
                        v-slot="{ active }"
                        :value="person"
                        as="template">
                      <div :class="['group flex cursor-default select-none items-center rounded-md p-2', active && 'bg-gray-100 text-gray-900']">
                        <client-avatar
                            :client="person"
                            alt=""
                            class="h-6 w-6 flex-none rounded-full" />
                        <div class="ml-3 flex-auto truncate">
                          <span class="block font-medium">{{ person.first_name }} {{ person.last_name }}</span>
                          <client-status-text class="text-xs text-gray-500" :client="person"/>
                        </div>
                        <svg-icon
                            v-if="active"
                            aria-hidden="true"
                            class="ml-3 h-5 w-5 flex-none text-gray-400"
                            name="right" />
                      </div>
                    </ComboboxOption>
                  </div>
                  <div class="w-full">
                    <pagination :pagination="paginator"
                                class="justify-between text-sm text-gray-500"
                                @set-page="currentPage = $event"></pagination>
                  </div>
                </div>

                <div
                    v-if="activeOption"
                    class="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                  <div class="flex-none p-6 text-center">
                    <client-avatar
                        :client="activeOption"
                        alt=""
                        class="mx-auto h-16 w-16 rounded-full" />
                    <h2 class="mt-3 mb-2 font-semibold text-gray-900">
                      {{ activeOption.first_name }} {{ activeOption.last_name }}
                    </h2>
                    <client-status-text
                        :client="activeOption"
                        as-tag
                        uses-colors />
                    <p class="text-sm leading-6 text-gray-500">
                      {{ activeOption.role }}
                    </p>
                  </div>
                  <div class="flex flex-auto flex-col justify-between p-6">
                    <dl class="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                      <dt class="col-end-1 font-semibold text-gray-900">
                        Mobile Phone
                      </dt>
                      <dd>{{ phone(activeOption.mobile_phone) }}</dd>
                      <dt class="col-end-1 font-semibold text-gray-900">
                        Email
                      </dt>
                      <dd class="truncate">
                        <a
                            v-if="!activeOption.can_contact_by_email"
                            :href="`mailto:${activeOption.email}`"
                            class="text-indigo-600 underline">
                          {{ activeOption.email }}
                        </a>
                        <span v-if="activeOption.can_contact_by_email">{{ activeOption.email }}</span>
                      </dd>
                    </dl>
                    <button
                        class="mt-6 w-full rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        type="button" @click="onSelect(activeOption)">
                      View profile
                    </button>
                  </div>
                </div>
              </ComboboxOptions>

              <div
                  v-if="query.length > 2 &&!isFetching && filteredPeople.length === 0"
                  class="py-14 px-6 text-center text-sm sm:px-14">
                <svg-icon
                    aria-hidden="true"
                    class="mx-auto h-6 w-6 text-gray-400"
                    name="users" />
                <p class="mt-4 font-semibold text-gray-900">
                  No client found
                </p>
                <p class="mt-2 text-gray-500">
                  We couldn’t find anyone with that term. Please try again.
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {computed, ComputedRef, ref, watch} from "vue";

import {Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Dialog, DialogPanel, TransitionChild, TransitionRoot} from "@headlessui/vue";
import SvgIcon                                                                                                          from "@/components/SvgIcon.vue";
import {QLFragmentClientSmallFragment, useClientsSearchQuery}                                                           from "@/graphql/queries/ql/composables";
import ClientAvatar                                                                                                     from "@/components/client/ClientAvatar.vue";
import ClientStatusText                                                                                                 from "@/components/client/ClientStatusText.vue";
import phone                                                                                                            from "@/classes/vue-filters/PhoneFilter";
import {useRouter}                                                                                                      from "vue-router";
import Routes                                                                                                           from "@/router/Routes";
import Loader                                                                                                           from "@/components/layout/Loader.vue";
import Pagination                                                                                                       from "@/components/layout/Pagination.vue";

const $router = useRouter();
const currentPage = ref(0);

// function isAppleDevice() {
//   return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
// }

// const keyString = isAppleDevice() ? "⌘" : "Ctrl";
// const keyType = isAppleDevice() ? "Meta+K" : "Ctrl+K";
//
// const keys = useMagicKeys();
// const metaK = keys[keyType];
// watch(metaK, (v) => {
//   if (v) {
//     open.value = true;
//   }
// });

const open = ref(false);
const query = ref("");

const {result, loading: isFetching} = useClientsSearchQuery(() => ({
                                                              search:                  query.value,
                                                              first:                   5,
                                                              page:                    currentPage.value,
                                                              onlyContactableViaEmail: false,
                                                              onlyContactableViaSms:   false
                                                            }),
                                                            () => ({
                                                              debounce: 500,
                                                              enabled:  query.value.length > 2
                                                            }));

const filteredPeople: ComputedRef<QLFragmentClientSmallFragment[]> = computed(() =>
                                                                                  query.value === ""
                                                                                  ? []
                                                                                  : result.value
                                                                                    ? result.value.clients_search.data
                                                                                    : []
);

const paginator = computed(() => result.value?.clients_search.paginatorInfo ?? null);

watch(query, () => currentPage.value = 0);

function onSelect(person) {
  if (!person) {
    return;
  }
  $router.push({name: Routes.client.show, params: {id: person.id!.toString()}});
  open.value = false;
  currentPage.value = 0;
}
</script>
