<template>
  <div>
    <div>
      <p v-show="via_sms">
        Your automation will <strong>send a text message</strong> to
        <strong>{{ foundClients }}</strong> clients.
      </p>
      <div
          v-if="via_email"
          class="flex items-center mb-2">
        <p>
          Your automation will <strong>send an email</strong> to
          <strong>{{ foundClients }}</strong> clients.
        </p>
        <button
            v-blur
            class="ml-4 inline-flex items-center btn btn-xs py-1 btn-white"
            @click="openEmail">
          <svg-icon
              name="send"
              class="h-4 w-4" />
          <div class="pl-1">
            Preview the email
          </div>
        </button>
      </div>
      <p v-if="via_todo">
        Your automation will <strong>create a todo</strong> about
        <strong>{{ foundClients }}</strong>
        clients.
      </p>

      <div v-if="via_tag">
        Your automation will <strong v-if="automation.tags_add">add the
                                                                tags</strong><strong
          v-if="!automation.tags_add ">remove the tags</strong>

        <div class="pl-2 inline-flex flex-wrap space-x-2">
          <span
              v-for="t in automation.tags"
              :key="t"
              class="block badge-sm badge-gray">{{ t }}</span>
        </div>
        to <strong>{{ foundClients }}</strong> clients.
      </div>
      <div v-if="!automation.one_off">
        <p>
          The automation will keep on looking for new clients
          that fit in
          the segment, and

          <span v-if="via_sms">send a text message</span>
          <span v-if="via_email">send an email</span>
          <span v-if="via_todo">create a todo</span>
          <span v-if="via_tag && automation.tags_add">add tags</span>
          <span v-if="via_tag && !automation.tags_add">remove tags</span>
          as soon as the filters are met
        </p>
      </div>
    </div>
    <email-preview
        v-if="via_email"
        v-model:open="emailpreviewOpen"
        :body="automation.email_content.html"
        :subject="automation.subject" />
  </div>
</template>
<script lang="ts" setup>
interface Props {
  automation: QLAutomation;
  foundClients?: number;
}

const props = withDefaults(defineProps<Props>(), {});

import {ref, toRef, useAttrs, useSlots} from "vue";
import {QLAutomation}                   from "@/graphql/queries/ql/composables";
import AutomationDecorator              from "@/composables/decorators/AutomationDecorator";
import EmailPreview                     from "@/components/automations/EmailPreview.vue";
import SvgIcon                          from "@/components/SvgIcon.vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const automation = toRef(props, "automation");

const {via_sms, via_email, via_todo, via_tag} = AutomationDecorator(automation.value).computed;
const emailpreviewOpen = ref(false);

const openEmail = (el) => {
  emailpreviewOpen.value = true;
};
</script>
