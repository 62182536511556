<template>
  <div class="flex items-center p-4 bg-white  rounded-lg shadow-md">
    <div class="w-full">
      <div class="text-sm font-medium tracking-normal text-gray-600 pb-0">
        Next event
      </div>
      <div
          v-if="loading"
          class="flex items-center justify-between animate-loading">
        <div class="">
          <div class="text-lg font-semibold text-loopspark-700">
            <loading-skeleton
                class-type="inline"
                random-text />
          </div>
          <div class="text-gray-700 text-sm">
            <loading-skeleton
                class-type="inline"
                text="10:00 AM" />
          </div>
        </div>
        <div class="text-right leading-none text-gray-400 tracking-tighter">
          <div class="text-3xl font-medium text-green-300">
            <loading-skeleton
                class-type="inline"
                text="25" />
          </div>
          <div class="text-sm">
            <loading-skeleton
                class-type="inline"
                text="attending" />
          </div>
        </div>
      </div>
      <div
          v-if="!loading"
          class="flex items-center justify-between">
        <div
            v-if="next_class"
            class="">
          <router-link
              :to="{name: routeList.event.show, params:{id: next_class.id}}"
              class="text-lg font-semibold text-loopspark-700">
            {{ next_class.name }}
          </router-link>
          <div class="text-gray-700 text-sm">
            <timestamp-to-date
                :has-date="false"
                :timestamp="next_class.start_time"
                has-time
                studio />
            <span v-if="next_class.staff">
            with {{ next_class.staff.name }}
            </span>
          </div>
        </div>
        <div
            v-if="!next_class"
            class="pt-3 text-gray-800 text-sm">
          No future events scheduled
        </div>
        <div
            v-if="next_class"
            class="text-right leading-none text-gray-400 tracking-tighter">
          <div class="text-3xl font-medium text-green-300 ">
            {{ attending }}
          </div>
          <div class="text-sm">
            attending
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import TimestampToDate                                     from "@/components/layout/TimestampToDate.vue";
import LoadingSkeleton                                     from "@/components/layout/LoadingSkeleton.vue";
import {QLFragmentLocationInfoFragment, useNextClassQuery} from "@/graphql/queries/ql/composables";
import {computed, toRef, useAttrs, useSlots}               from "vue";
import routesList                                          from "@/composables/routesList";
import {echoListener}                                      from "@/composables/echoListener";
import {EventTypes}                                        from "@/classes/notifications/EventTypes";

interface Props {
  location?: QLFragmentLocationInfoFragment;
}

const props = withDefaults(defineProps<Props>(), {
  location: null
});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const location = toRef(props, "location");

const {routeList} = routesList();

const {result, loading, refetch} = useNextClassQuery({
                                                       location_id: location.value ? location.value.id : null
                                                     });
const next_class = computed(() => result.value?.next_class ?? null);

const attending = computed(() => {
  if (!next_class.value) {
    return 0;
  }
  return next_class.value.visits.filter(visit => !visit.late_cancelled && !visit.early_cancel).length;
});

echoListener([EventTypes.VisitWasUpdated], (ev) => {
  if (next_class.value) {
    if (ev.data.class_model_id == next_class.value.id) {
      refetch();
    }
  }
}, 5000);

</script>
