import {computed, Ref, ref, watch} from "vue";
import $toast                      from "@/composables/toast";
import {QLFragmentTeamFragment}    from "@/graphql/queries/ql/composables";

export default function (id, mutation, props, emit) {

	const sending = ref(false);
	const success = ref(false);
	const selectedTeam: Ref<QLFragmentTeamFragment | null> = ref(null);

	const openModel = computed({
		                           get: () => props.open,
		                           set: (val) => emit("update:open", val)
	                           });

	watch(openModel, () => {
		if (props.open) {
			// reset
			selectedTeam.value = null;
			success.value = false;
			sending.value = false;
		}
	});

	const toggleSuccess = () => {
		sending.value = false;
		success.value = true;

		setTimeout(() => {
			selectedTeam.value = null;
			openModel.value = false;
			success.value = false;
		}, 3000);
	};

	mutation.onDone(() => {
		toggleSuccess();
	});

	mutation.onError(() => {
		sending.value = false;
		// throw error here
		$toast({
			       type:         "error",
			       message:      "There was an error while copying. Please try again",
			       linkRouteObj: null,
			       linkText:     null
		       });
	});

	const sendNow = () => {
		mutation.mutate({
			                id:                  id,
			                destination_team_id: selectedTeam.value.id
		                });
	};

	return {
		sending,
		success,
		openModel,
		selectedTeam,
		sendNow
	};

}
