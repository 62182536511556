<template>
  <div class="split-view-left">
    <div class="pb-4 md:pb-0 md:w-1/3">
      <div class="text-lg font-medium text-gray-900">
        Set your studio APIs
      </div>
      <div
          v-if="needsTokenStep"
          class="mt-1 text-gray-600 text-sm">
        Click the button to authorize LoopSpark's integration with your
        Pike13 account. You'll be redirected to Pike13 where you will need to
        login to your account and authorize your LoopSpark + Pike13
        integration.
      </div>

      <div
          v-if="!needsTokenStep"
          class="mt-1 text-gray-600 text-sm">
        Select the business you would like to integrate with LoopSpark. Please
        only select a business you own, operate, or have authorization to
        integrate with vendors. Your LoopSpark subscription is per location, per
        month.
      </div>
    </div>
    <div
        v-show="needsTokenStep && !loadingLink"
        class="flex-1 bg-white p-4 block shadow-md rounded-lg">
      <a
          :href="Pike13AuthUrl.link"
          class="btn btn-loopspark inline-block">Authorize LoopSpark</a>
    </div>
    <div
        v-if="!needsTokenStep && Pike13GetBusinesses && Pike13GetBusinesses.length > 0"
        class="flex-1">
      <div class="block overflow-hidden shadow-md rounded-lg">
        <div class="bg-white p-4">
          <h3 class="form-label">
            Select your business
          </h3>
          <div class="divide-y">
            <div
                v-for="business in Pike13GetBusinesses"
                :key="business.id"
                class="py-4">
              <div class="flex items-center justify-between">
                <div class="grow">
                  <strong>{{ business.name }}</strong>
                  <p class="text-xs">
                    Domain: http://{{ business.subdomain }}.pike13.com
                  </p>
                </div>
                <div>
                  <v-button
                      :disabled="posting != null"
                      :loading="posting === business.id"
                      class="btn btn-small btn-green"
                      @click="setBusiness(business)">
                    Select {{ business.name }}
                  </v-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import VButton          from "@/components/layout/VButton.vue";
import {computed, ref}  from "vue";
import $toast           from "@/composables/toast";
import {
  QLPike13Business, usePike13AuthUrlQuery, usePike13GetBusinessesQuery, usePike13SetBusinessMutation
}                       from "@/graphql/queries/ql/composables";
import {
  useResult
}                       from "@vue/apollo-composable";
import currentTeamQuery from "@/composables/queries/currentTeamQuery";

const posting = ref(null);

const {result: pikeAuthResult, loading: loadingLink} = usePike13AuthUrlQuery();
const Pike13AuthUrl = useResult(pikeAuthResult, null, data => data.Pike13AuthUrl);

const {currentTeam} = currentTeamQuery();

const needsTokenStep = computed(() => {
  return currentTeam.value && !currentTeam.value.external_token_present;
});

const enabledGetBusinessesQuery = computed(() => {
  return currentTeam.value && !currentTeam.value.api_set && currentTeam.value.external_token_present;
});

const {result: Pike13GetBusinessesQueryResult} = usePike13GetBusinessesQuery(() => ({
  enabled: enabledGetBusinessesQuery.value
}));
const Pike13GetBusinesses = useResult(Pike13GetBusinessesQueryResult,
                                      null,
                                      data => data.Pike13GetBusinesses);

const pike13SetBusinessMutation = usePike13SetBusinessMutation({});

const setBusiness = (business: QLPike13Business) => {
  posting.value = business.id;

  pike13SetBusinessMutation.mutate({
                                     id:        business.id,
                                     subdomain: business.subdomain,
                                     timezone:  business.timezone
                                   }).finally(() => posting.value = null);

  pike13SetBusinessMutation.onDone(() => {
    $toast({
             type:         "success",
             message:      "API is set!",
             linkRouteObj: null,
             linkText:     null
           });
  });
  pike13SetBusinessMutation.onError((error) => {
    $toast({
             type:         "error",
             message:      "There was an error while completing this step. Please make sure you authorized LoopSpark on Pike13",
             linkRouteObj: null,
             linkText:     null
           });
  });
};
</script>
