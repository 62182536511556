import Filter                         from "../base-filter";
import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import NumericOperator                from "hm-filters/operators/NumericOperator";

export class SaleCountFilter extends Filter {
	smallText = "Sale";
	group = "Sale";
	path = ["Sale"];

	constructor(name: string, options: any) {
		super(name, options);
		this.smallText = "Sale";
		this.group = "Sale";
		this.operators = [
			new OperatorAsFixedValueOperator("never", "never before"),
			new NumericOperator(">", "more than", "sales"),
			new NumericOperator("<", "less than", "sales"),
			new NumericOperator("=", "exactly", "sales")
		];

	}

	get type(): string {
		return "SaleCountFilter";
	}
}
