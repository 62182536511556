import Communication                                      from "./partials/communication";
import FixedValueOperator, {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import GraphQlOperator                                    from "hm-filters/operators/GraphQlOperator";
import QLQueriesData                          from "hm-filters/operators/GraphQls/QLQueriesData";
import {OperatorTypes, OperatorTypesFullName} from "hm-filters/operators/OperatorTypes";

class CommunicationReceivedFilter extends Communication {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new CommunicationReceivedQlOperator("=", "at least once").setIsMultipleChoice(),
			new CommunicationReceivedQlOperator("only", "only").setAloneInFilterGroup(),
			new CommunicationReceivedQlOperator("!=", "never"),
			new OperatorAsFixedValueOperator("any", "any communication"),
			new OperatorAsFixedValueOperator("never", "no communication")
		];
	}

	get type(): string {
		return "CommunicationReceivedFilter";
	}
}

export default CommunicationReceivedFilter;

export class CommunicationReceivedQlOperator extends GraphQlOperator {
	graphQLData = QLQueriesData.CommunicationReceivedQlOperator;

	get type(): string {
		return OperatorTypes.GraphQlMultiple;
	}

	get fullType(): string{
		return OperatorTypesFullName.CommunicationReceivedQlOperator;
	}
}
