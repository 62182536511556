import {RouteRecordRaw}    from "vue-router";
import Routes              from "@/router/Routes";
import EmailTemplatesIndex from "@/views/Automations/EmailTemplatesIndex.vue";
import EmailTemplateCreate from "@/views/Automations/EmailTemplateCreate.vue";
import EmailTemplateEdit   from "@/views/Automations/EmailTemplateEdit.vue";
import AutomationsIndex    from "@/views/Automations/AutomationsIndex.vue";
import AutomationCreate    from "@/views/Automations/AutomationCreate.vue";
import {AutomationRoutes}  from "@/router/automation";
import CatalogIndex        from "@/views/Automations/CatalogIndex.vue";
import CatalogShow         from "@/views/Automations/CatalogShow.vue";
import CatalogCreate       from "@/views/Automations/CatalogCreate.vue";
import CatalogEdit         from "@/views/Automations/CatalogEdit.vue";
import {QLUserRoleEnum}    from "@/graphql/queries/ql/composables";


export const AutomationsRoutes: Array<RouteRecordRaw> = [
	{
		path:      "/automations/email-templates",
		component: EmailTemplatesIndex,
		name:      Routes.automations.email_templates.index,
		meta:      {
			title:       "Email Templates",
			atLeastRole: QLUserRoleEnum.MANAGER
		}
	},
	{
		path:      "/automations/email-templates/:id/create",
		component: EmailTemplateCreate,
		name:      Routes.automations.email_templates.create,
		props:     true,
		meta:      {
			title:       "Create Email Template",
			atLeastRole: QLUserRoleEnum.MANAGER
		}
	},
	{
		path:      "/automations/email-templates/:id/edit",
		component: EmailTemplateEdit,
		name:      Routes.automations.email_templates.show,
		props:     true,
		meta:      {
			title:       "Edit Email Template",
			atLeastRole: QLUserRoleEnum.MANAGER
		}
	},
	{
		path:      "/automations",
		component: AutomationsIndex,
		name:      Routes.automations.index,
		meta:      {
			title: "Automations"
		}
	},
	{
		path:      "/automations/create",
		component: AutomationCreate,
		name:      Routes.automations.create,
		meta:      {
			requiresAuth:         true,
			canCreateAutomations: true,
			title:                "Create Automation"
		}
	},
	{
		path:      "/automations/catalog",
		component: CatalogIndex,
		name:      Routes.automations.catalog.index,
		meta:      {
			requiresAuth:         true,
			canCreateAutomations: true,
			title:                "Automations Catalog"
		}
	},
	{
		path:      "/automations/catalog/edit/:id",
		component: CatalogEdit,
		name:      Routes.automations.catalog.edit,
		props:     true,
		meta:      {
			requiresAuth: true,
			permission:   "manage catalog"
		}
	},
	{
		path:      "/automations/catalog/create",
		component: CatalogCreate,
		name:      Routes.automations.catalog.create,
		props:     true,
		meta:      {
			requiresAuth: true,
			permission:   "manage catalog"
		}
	},
	{
		path:      "/automations/catalog/:id",
		component: CatalogShow,
		name:      Routes.automations.catalog.show,
		props:     true,
		meta:      {
			requiresAuth:         true,
			canCreateAutomations: true,
			title:                "Automation Catalog"
		}
	},
	...AutomationRoutes

];
