import DayOfWeekOperator   from "hm-filters/operators/DayOfWeekOperator";
import {BookingEventGroup} from "hm-filters/filters/partials/BookingEventGroup";

export class BookingClassDayOfWeekFilter extends BookingEventGroup {
	constructor(name: string, options: any) {
		super(name, options);

		this.clearOperators();
		this.addOperators([
			                  new DayOfWeekOperator("=", "is"),
			                  new DayOfWeekOperator("!=", "is not")

		                  ]);
	}

	get type(): string {
		return "BookingClassDayOfWeek";
	}
}
