<template>
  <div class="p-4">
    <div>
      <div class="flex items-center">
        <div class="shrink-0 h-10 w-10">
          <ClientAvatar
              :client="client"
              class="w-10 h-10" />
        </div>
        <div class="ml-4">
          <div class="text-sm font-medium text-gray-900">
            {{ client.first_name }} {{ client.last_name }}
          </div>
          <div class="text-sm text-gray-500">
            <ClientStatusText :client="client" />
          </div>
        </div>
      </div>
    </div>
    <dl class="grid gap-x-4 gap-y-3 grid-cols-2 pt-3">
      <div>
        <dt class="text-sm font-medium text-gray-500">
          Last Attendance
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <span v-if="!client.last_attendance_at">-</span>
          <span v-if="client.last_attendance_at > 0"><TimeAgo :timestamp="client.last_attendance_at" /></span>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-medium text-gray-500">
          Next Attendance
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          <span v-if="!client.next_event">-</span>
          <span v-if="client.next_event?.class?.start_time > 0"><TimeAgo :timestamp="client.next_event.class.start_time" /></span>
        </dd>
      </div>
      <div>
        <dt class="text-sm font-medium text-gray-500">
          Club Count
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ client.total_club_counts }}
        </dd>
      </div>
      <div>
        <dt class="text-sm font-medium text-gray-500">
          LTV
        </dt>
        <dd class="mt-1 text-sm text-gray-900">
          $ {{ client.ltv }}
        </dd>
      </div>
    </dl>
  </div>
</template>
<script lang="ts" setup>
import {QLFragmentClientTooltipFragment} from "@/graphql/queries/ql/composables";
import {useAttrs, useSlots}              from "vue";
import ClientAvatar                      from "@/components/client/ClientAvatar.vue";
import ClientStatusText                  from "@/components/client/ClientStatusText.vue";
import TimeAgo                           from "@/components/layout/TimeAgo.vue";

interface Props {
  client: QLFragmentClientTooltipFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
</script>
