<template>
  <div class="mt-2 shadow border-b border-gray-200 sm:rounded-lg">
    <div class="table-container">
      <table class="table divide-y">
        <thead>
        <tr>
          <table-th
              :is-down="paginationData.orderByField === 'last_name' && isSelectedArrow(false)"
              :is-up="paginationData.orderByField === 'last_name' && isSelectedArrow(true)"
              class="w-3/5"
              :has-sort="loadedFirstTime && clientsUnsubscribed.length > 0"
              @down="changeSort(true,false)"
              @up="changeSort(true,true)">
            Client
          </table-th>

          <table-th class="text-right">
            Last Contact
          </table-th>

          <table-th
              :is-down="paginationData.orderByField !== 'last_name' && isSelectedArrow(false)"
              :is-up="paginationData.orderByField !== 'last_name' && isSelectedArrow(true)"
              class="text-right"
              :has-sort="loadedFirstTime && clientsUnsubscribed.length > 0"
              @down="changeSort(false,false)"
              @up="changeSort(false,true)">
            Opt-Out Date
          </table-th>
        </tr>
        </thead>
        <tbody
            v-if="!loadedFirstTime"
            class="bg-white">
        <tr>
          <td
              class="py-16 text-center text-gray-300"
              colspan="3">
            <loader class="w-8 h-8 inline-block" />
          </td>
        </tr>
        </tbody>
        <tbody
            v-if="loadedFirstTime"
            class="bg-white divide-y divide-gray-200">
        <tr v-if="clientsUnsubscribed.length === 0">
          <td
              class="py-16 text-center text-gray-500"
              colspan="3">
            <div class="font-medium text-lg">
              Great!
            </div>
            <div>No one unsubscribed to <span v-text="isEmail ? 'emails': 'text messages'" />.</div>
          </td>
        </tr>
        <tr
            v-for="client in clientsUnsubscribed"
            :key="client.id">
          <td class="td whitespace-nowrap">
            <div class="flex items-center group cursor-pointer">
              <div class="shrink-0 h-10 w-10">
                <client-avatar
                    :client="client"
                    class="h-10 w-10" />
              </div>
              <div class="ml-4">
                <router-link
                    :to="{name: routeList.client.show, params: {id: client.id}}"
                    class="text-sm leading-5 font-medium text-loopspark-500 group-hover:text-loopspark-700">
                  {{ client.first_name }} {{ client.last_name }}
                </router-link>
                <div
                    v-if="isEmail"
                    class="text-sm leading-5 text-gray-500">
                  {{ client.email }}
                </div>
                <div
                    v-if="!isEmail"
                    class="text-sm leading-5 text-gray-500">
                  {{ phone(client.mobile_phone) }}
                </div>
              </div>
            </div>
          </td>
          <td class="td whitespace-nowrap text-right">
            <div v-if="client.last_discussion">
              <router-link
                  :to="{name: routeList.client.discussions.show, params: {id: client.id, discussionId: client.last_discussion.id}}"
                  class="text-sm leading-5 text-loopspark-500 hover:text-loopspark-700">
                <timestamp-to-date
                    :has-day-name="false"
                    :timestamp="client.last_discussion.last_contacted_at"
                    natural-date />
              </router-link>
              <div class="text-sm leading-5 text-gray-500">
                <time-ago :timestamp="client.last_discussion.last_contacted_at" />
              </div>
            </div>
          </td>
          <td class="td whitespace-nowrap text-right">
            <div class="text-sm leading-5 text-gray-900">
              <timestamp-to-date
                  :has-day-name="false"
                  :timestamp="isEmail ? client.unsubscribed_email_at : client.unsubscribed_sms_at"
                  has-time
                  natural-date />
            </div>
            <div class="text-sm leading-5 text-gray-500">
              <time-ago :timestamp="isEmail ? client.unsubscribed_email_at : client.unsubscribed_sms_at" />
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot v-if="loadedFirstTime && paginatorInfo.lastPage > 1">
        <tr>
          <td colspan="3">
            <div class="w-full py-2 text-right px-4 rounded-b">
              <pagination
                  v-if="paginatorInfo.lastPage > 1"
                  :pagination="paginatorInfo"
                  class="flex items-center justify-between text-gray-700 text-sm"
                  @setPage="paginationData.page = $event">
                <div
                    v-if="loading"
                    class="inline-block w-8 h-8 mr-2 text-gray-400">
                  <loader />
                </div>
              </pagination>
            </div>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Pagination                                                                                      from "@/components/layout/Pagination.vue";
import ClientAvatar                                                                                    from "@/components/client/ClientAvatar.vue";
import TimestampToDate                                                                                 from "@/components/layout/TimestampToDate.vue";
import TimeAgo                                                                                         from "@/components/layout/TimeAgo.vue";
import Loader                                                                                          from "@/components/layout/Loader.vue";
import TableTh                                                                                         from "@/components/layout/Tables/TableTh.vue";
import {toRef, useAttrs, useSlots, watch}                                                              from "vue";
import routesList                                                                                      from "@/composables/routesList";
import {createPaginationData, paginationWithAlreadyLoaded}                                             from "@/composables/paginationWithAlreadyLoaded";
import {QLClientsUnsubscribedQuery, QLSortOrder, QLUnsubscribeMediumEnum, useClientsUnsubscribedQuery} from "@/graphql/queries/ql/composables";
import phone                                                                                           from "@/classes/vue-filters/PhoneFilter";

interface Props {
  isEmail: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
const {routeList} = routesList();

const isEmail = toRef(props, "isEmail");


const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 10
                                              });

const orderByOptions = ["last_name", "unsubscribed_email_at", "unsubscribed_sms_at"];
const sortOrderEnum = QLSortOrder;

const clientsUnsubscribedQ = useClientsUnsubscribedQuery(() => ({
  medium:  isEmail.value ? QLUnsubscribeMediumEnum.Email : QLUnsubscribeMediumEnum.SMS,
  page:    paginationData.page,
  first:   paginationData.perPage,
  orderBy: [
    {
      column: paginationData.orderByField,
      order:  paginationData.orderSort
    }
  ]
}));

const {
  paginatorInfo,
  loadedFirstTime,
  results: clientsUnsubscribed,
  loading
} = paginationWithAlreadyLoaded<QLClientsUnsubscribedQuery["clients_unsubscribed"]["data"]>(clientsUnsubscribedQ);


const isSelectedArrow = (up: boolean) => {
  return paginationData.orderSort === (up ? QLSortOrder.ASC : QLSortOrder.DESC);
};

const changeSort = (isLastName: boolean, asc: boolean) => {
  paginationData.page = 1;
  if (isLastName) {
    paginationData.orderByField = "last_name";
  } else {
    paginationData.orderByField = isEmail.value ? "unsubscribed_email_at" : "unsubscribed_sms_at";
  }

  paginationData.orderSort = (asc ? QLSortOrder.ASC : QLSortOrder.DESC);
};

watch(isEmail, () => {
  paginationData.page = 1;
  if (paginationData.orderByField != "last_name") {
    paginationData.orderByField = isEmail.value ? "unsubscribed_email_at" : "unsubscribed_sms_at";
  }
  loadedFirstTime.value = false;
});

</script>
