import {PackageNameQlOperator} from "hm-filters/filters/packageNameFilter";
import MultipleTextOperator    from "hm-filters/operators/MultipleTextOperator";
import {BookingGroup}          from "hm-filters/filters/partials/BookingGroup";

export class BookingPackageUsedFilter extends BookingGroup {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new PackageNameQlOperator("=", "used").setIsMultipleChoice(),
			new MultipleTextOperator("contains", "contains"),
			new MultipleTextOperator("does_not_contain", "does not contain")
		];
	}

	get type(): string {
		return "BookingPackageUsed";
	}
}
