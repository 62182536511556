import {Howl}       from "howler";
import {ref, watch} from "vue";

class SoundPlayer {
	private static instance: SoundPlayer;

	newMessageSound: Howl;

	private constructor() {
		this.newMessageSound = new Howl({
			                                src:     ["/audio/new-message.mp3"],
			                                preload: true
		                                });
	}

	public static getInstance(): SoundPlayer {
		if (!SoundPlayer.instance) {
			SoundPlayer.instance = new SoundPlayer();
		}

		return SoundPlayer.instance;
	}
}

const muted = ref(false);

export function SoundSystem() {
	const soundSystem = SoundPlayer.getInstance();

	const localS = localStorage.getItem("sound-muted");
	if (localS) {
		muted.value = JSON.parse(localS);
	}

	const play = () => {
		if (muted.value === false) {
			soundSystem.newMessageSound.play();
		}
	};

	const toggleSound = () => {
		muted.value = !muted.value;
		localStorage.setItem("sound-muted", JSON.stringify(muted.value));
	};

	return {
		muted, play, toggleSound
	};

};
