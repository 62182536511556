<template>
  <div
      v-if="!saving"
      class="mt-12 flex justify-between"
  >
    <div>
      <v-button
          v-if="hasPrevious"
          class="btn btn-white"
          @click="internalStep = internalStep-1"
      >
        <div class="inline-flex items-center">
          <svg-icon
              name="left"
              class="w-4 h-4"
              icon="left"
          />
          <span class="pl-1">Previous step</span>
        </div>
      </v-button>
    </div>
    <div>
      <v-button
          v-if="hasNext"
          class="btn btn-white"
          @click="internalStep = internalStep+1"
      >
        <div class="inline-flex items-center">
          <span class="pr-1">Next step</span>
          <svg-icon
              name="right"
              class="w-4 h-4"
              icon="left"
          />
        </div>
      </v-button>
    </div>
  </div>
</template>
<script lang="ts" setup>

import {AutomationStepsStatus} from "@/components/automations/Create/AutomationStepStatus";
import VButton                 from "@/components/layout/VButton.vue";
import SvgIcon                 from "@/components/SvgIcon.vue";
import {computed, toRef}       from "vue";

interface Props {
  step: number;
  saving: boolean;
  stepsStatus: AutomationStepsStatus;
}

const props = withDefaults(defineProps<Props>(), {});


const emit = defineEmits(["update:step"]);

const internalStep = computed({
                                get: () => props.step,
                                set: (val) => emit("update:step", val)
                              });

const hasPrevious = computed(() => {
  return props.step > 1;
});

const lastStepEnabled = computed(() => {
  for (let step in props.stepsStatus) {
    if (!props.stepsStatus[step].completed || props.stepsStatus[step].errors) {
      return false;
    }
  }
  return true;
});

const hasNext = computed(() => {
  return props.step < 3 || (props.step === 3 && lastStepEnabled.value);
});

const saving = toRef(props, "saving");

</script>
