<template>
  <div>
    <modal
        v-model:open="open"
        :has-close-button="false">
      <div class="w-full p-4">
        <div class="h-64 w-full flex items-center justify-center flex-col">
          <loader class="w-32 h-32 text-green-500" />
          <p class="text-lg font-semibold mb-2">
            Your current studio changed
          </p>
          <p class="text-sm">
            On a different browser window, you changed your current studio. To ensure consistency in
            the displayed
            information, this window needs to be refreshed. It will refresh automatically in 2
            seconds
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>
<script lang="ts" setup>

import Modal                         from "@/components/layout/Modal.vue";
import Loader                        from "@/components/layout/Loader.vue";
import {onMounted, onUnmounted, ref} from "vue";
import {echoListener}                from "@/composables/echoListener";
import {AllEventTypes, EventTypes}   from "@/classes/notifications/EventTypes";

const open = ref(false);

echoListener([AllEventTypes.ChangedTeam], (ev) => {
  open.value = true;

  setTimeout(() => {
    window.location.href = import.meta.env.VITE_APP_UI_URL + "/";
  }, 2000);
}, 0);

</script>
