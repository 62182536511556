<template>
  <div class="block">
    <div
        class="w-full flex"
        :class="{'is-text-message': discussion.sms, 'is-email': discussion.email, 'from-client': message.from_client, 'from-loopspark': !message.from_client}">
      <div class="px-2 flex w-5/6 main-div">
        <div class="shrink">
          <client-avatar
              v-if="message.from_client"
              :client="discussion.client"
              class="w-8 h-8 block" />
          <div
              v-if="!message.from_client"
              class="w-8 h-8 block">
            <avatar
                v-if="!userMessage"
                class="w-8 h-8"
                image="/images/logo-square.svg" />
            <div v-if="userMessage">
              <avatar
                  :image="message.user.photo_url"
                  :username="message.user.name"
                  class="w-8 h-8" />
            </div>
          </div>
        </div>
        <div class="px-2 block max-w-full min-w-0">
          <div class="bubble rounded-lg p-2 font-light relative">
            <p class="whitespace-pre-line text-base block break-words">
              {{ messageText }}
            </p>
          </div>
          <div class="text-gray-600 text-sm pt-1 flex note-area space-x-4 items-baseline">
            <div class="block">
              <span
                  v-if="!message.from_client && userMessage"
                  class="text-xs">{{ message.user.name }} on </span>

              <timestamp-to-date
                  :timestamp="message.created_at"
                  class="whitespace-nowrap"
                  has-date
                  has-time />
            </div>
            <div ref="status">
              <div
                  v-if="!message.from_client && messageStatus === messageStatuses.SENDING"
                  class="text-gray-400 flex items-baseline animated animate-pulse text-xs">
                <loader class="w-3 h-3 self-center" />
                <span class="ml-1">Sending...</span>
              </div>
              <div
                  v-if="!message.from_client && messageStatus === messageStatuses.FAILED"
                  class="text-red-600 font-medium flex items-center">
                <svg-icon
                    name="exclamation"
                    class="w-3 h-3" />
                <span>Failed</span>
                <span class="pl-1 text-xs self-end text-link" v-if="errorMessage" :aria-tooltip="errorMessage">Why?</span>
              </div>
              <div
                  v-if="!message.from_client && messageStatus === messageStatuses.PENDING_STATUS"
                  class="text-green-500 text-xs">
                Sent, status pending
              </div>

              <div
                  v-if="!message.from_client && messageStatus === messageStatuses.BOUNCED"

                  class="text-red-600 font-medium flex items-center">
                <svg-icon
                    name="exclamation"
                    class="w-3 h-3" />
                <span>Not delivered</span>
                <span class="pl-1 text-xs self-end text-link" v-if="errorMessage" :aria-tooltip="errorMessage">Why?</span>
              </div>
            </div>

            <p v-if="message.automation_id >0 ">
              Automation:
              <router-link
                  :to="{name: routeList.automations.show.index, params:{id: discussion.automation.id}}"
                  class="font-medium text-blue-600 hover:text-blue-800">
                {{
                  discussion.automation.name
                }}
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.is-text-message {
  --bubble-bg:#0381e3;
  --text-color:#FFF;
}

.is-email {
  --bubble-bg:#17a802;
  --text-color:#FFF;
}

.from-loopspark {
  @apply justify-end;
}

.from-loopspark .main-div {
  @apply flex-row-reverse ;
}

.from-client .main-div {
  @apply flex-row;
}

.from-client {
  --bubble-bg:#f6f6f6;
  --text-color:#111;
}

.bubble {
  background-color:var(--bubble-bg);
  color:var(--text-color);
}

.from-client .bubble {
  @apply rounded-tl-none;
}

.from-loopspark .bubble {
  @apply rounded-tr-none;
}

.from-client .note-area {
  @apply flex-row
}

.from-loopspark .note-area {
  @apply flex-row-reverse;
  @apply space-x-reverse;
}

.from-loopspark .note-area > :not(template) + :not(template) {
  /*/ / --space-x-reverse: 1;*/
}

</style>

<script lang="ts" setup>

import TimestampToDate                            from "@/components/layout/TimestampToDate.vue";
import ClientAvatar                               from "@/components/client/ClientAvatar.vue";
import Avatar                                     from "@/components/layout/Avatar.vue";
import Loader                                     from "@/components/layout/Loader.vue";
import SvgIcon                                    from "@/components/SvgIcon.vue";
import {computed, ref, toRef, useAttrs, useSlots} from "vue";
import {QLClient, QLDiscussion, QLMessage}        from "@/graphql/queries/ql/composables";
import routesList                                 from "@/composables/routesList";
import MessageDecorator, {MessageStatus}          from "@/composables/decorators/MessageDecorator";

interface Props {
  client: QLClient;
  discussion: QLDiscussion;
  message: QLMessage;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const client = toRef(props,'client');
const message = toRef(props,'message');
const discussion = toRef(props,'discussion');

const status = ref(null);

const openEmail = ref(false);

const {routeList} = routesList();

const _decorator = MessageDecorator(message,discussion);

const messageText = computed(() => {
  return _decorator.computed.messageText;
});

const messageStatus = computed(() => {
  return _decorator.computed.status;
});

const userMessage = computed(() => {
  return message.value.user;
});

const messageStatuses = MessageStatus;

const errorMessage = computed(() => {
  return _decorator.computed.errorMessage;
});

</script>
