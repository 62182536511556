<template>
  <Menu
      as="div"
      class="inline-block relative text-sm w-full grow" v-if="quickResponses.length > 0">
    <div class="flex items-end justify-end pr-2">
      <MenuButton class="inline-flex items-center justify-center focus:outline-none text-blue-500 text-sm font-medium px-2 py-1 rounded bg-opacity-50 bg-white  hover:bg-green-200 hover:text-green-900">
        <span class="block">Quick responses</span>
        <svg-icon
            name="down"
            class="h-4 w-4 block" />
      </MenuButton>
    </div>
    <div class="relative">
    <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="origin-top-right absolute right-0 mt-2 max-w-5xl max-h-[20rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-auto">
        <div class="py-1 divide-y divide-gray-100 ">
          <MenuItem v-slot="{ active }" v-for="response in quickResponses" :key="response.id">
            <div :class="['py-2 text-sm block cursor-pointer block text-gray-900', active ? 'bg-loopspark-50': '']"
                 @click="selectedText(response)">
              <div class="px-4 py-2">
                <span class="font-medium text-base">{{ response.title }}</span>
                <pre :class="['font-sans whitespace-pre-line text-xs pt-1', active ? 'text-gray-900': 'text-gray-600']">{{
                    replacePlaceholders(response.content)
                                                                                                                        }}</pre>
              </div>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
    </div>
  </Menu>

</template>
<script lang="ts" setup>
import SvgIcon                                                             from "@/components/SvgIcon.vue";
import {Menu, MenuButton, MenuItem, MenuItems}                             from "@headlessui/vue";
import {toRef, useAttrs, useSlots}                                         from "vue";
import {QLClient, QLFragmentQuickResponseFragment, useQuickResponsesQuery} from "@/graphql/queries/ql/composables";
import {useResult}                                                         from "@vue/apollo-composable";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["selected"]);


const client = toRef(props, "client");

const quickQuery = useQuickResponsesQuery({fetchPolicy: "cache-first"});

const quickResponses = useResult(quickQuery.result, [], data => data.quick_responses);

const replacePlaceholders = (body: string): string => {
  const toReplace = [
    {
      holder: "{{FIRST_NAME}}",
      val:    client.value.first_name
    },
    {
      holder: "{{LAST_NAME}}",
      val:    client.value.last_name
    },
    {
      holder: "{{PERIOD_COUNT}}",
      val:    client.value.total_period_counts
    },
    {
      holder: "{{CLUB_COUNT}}",
      val:    client.value.total_club_counts
    }
  ];
  toReplace.forEach((rep) => {
    body = body.replace(rep.holder, rep.val ? rep.val.toString() : null);
  });
  return body;
};

const selectedText = (res: QLFragmentQuickResponseFragment) => {
  emit("selected", replacePlaceholders(res.content));
};
</script>
