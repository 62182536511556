import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import NumericOperator                from "hm-filters/operators/NumericOperator";
import Form                           from "hm-filters/filters/Forms/forms";

export class FormCountFilter extends Form {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new OperatorAsFixedValueOperator("never", "never before"),
			new NumericOperator(">", "more than", "forms"),
			new NumericOperator("<", "less than", "forms"),
			new NumericOperator("=", "exactly", "forms")
		];
	}

	get type(): string {
		return "FormCountFilter";
	}
}
