<template>
  <side-panel v-model:open="openModel">
    <template #header>
      Your notifications
    </template>
    <div class="px-4 pt-4">
      <loader
          v-if="loading"
          class="w-8 h-8" />
      <div v-if="!loading">
        <div
            v-if="notifications.length > 0"
            class="space-y-4">
          <view-notification
              v-for="notification in notifications"
              :key="notification.id"
              :notification="notification"
              class="border-b" />
        </div>
        <div
            v-if="notifications.length === 0"
            class="rounded bg-gray-200 p-2 py-4 border border-gray-400">
          <p class="text-gray-800 text-sm text-center">
            You don't have any notifications
          </p>
        </div>
      </div>
    </div>
  </side-panel>
</template>
<script lang="ts" setup>
import {echoListener}                                        from "@/composables/echoListener";
import ViewNotification                                      from "@/components/notifications/ViewNotification.vue";
import Loader                                                from "@/components/layout/Loader.vue";
import {computed}                                            from "vue";
import {useNotificationsQuery, useReadNotificationsMutation} from "@/graphql/queries/ql/composables";
import {useResult}                                           from "@vue/apollo-composable";
import SidePanel                                             from "@/components/layout/SidePanel.vue";
import {RootEventTypes}                                      from "@/classes/notifications/EventTypes";

interface Props {
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:open"]);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const {result, loading, refetch, onResult} = useNotificationsQuery(() => ({enabled: openModel.value}));
const notifications = useResult(result, [], data => data.notifications);

const readNotificationsM = useReadNotificationsMutation();

const markAsRead = () => {
  let ids = notifications.value.filter((n) => !n.read).map((n) => n.id);
  if (ids.length > 0) {
    readNotificationsM.mutate({ids});
  }
};

onResult((data) => {
  if(data.loading === false){
    markAsRead();
  }
})
const openFn = () => openModel.value = true;

echoListener([RootEventTypes.UserNotification], (ev) => {
  refetch();
}, 5000);

</script>
