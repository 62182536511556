// For real ids:
// import ids                  from "virtual:svg-icons-names";
import IconArrowDown             from "@/assets/icons/arrow-down.svg?raw";
import IconArrowUp               from "@/assets/icons/arrow-up.svg?raw";
import IconAutomations           from "@/assets/icons/automations.svg?raw";
import IconBell                  from "@/assets/icons/bell.svg?raw";
import IconBullhorn              from "@/assets/icons/bullhorn.svg?raw";
import IconCalendar              from "@/assets/icons/calendar.svg?raw";
import IconCalendarSmall         from "@/assets/icons/calendar-small.svg?raw";
import IconCards                 from "@/assets/icons/cards.svg?raw";
import IconChartPie              from "@/assets/icons/chartPie.svg?raw";
import IconCheck                 from "@/assets/icons/check.svg?raw";
import IconClick                 from "@/assets/icons/click.svg?raw";
import IconClock                 from "@/assets/icons/clock.svg?raw";
import IconClose                 from "@/assets/icons/close.svg?raw";
import IconCloseCircle           from "@/assets/icons/closeCircle.svg?raw";
import IconDashboard             from "@/assets/icons/dashboard.svg?raw";
import IconDelivered             from "@/assets/icons/delivered.svg?raw";
import IconDiscussions           from "@/assets/icons/discussions.svg?raw";
import IconDown                  from "@/assets/icons/down.svg?raw";
import IconDownload              from "@/assets/icons/download.svg?raw";
import IconDragorder             from "@/assets/icons/dragorder.svg?raw";
import IconEdit                  from "@/assets/icons/edit.svg?raw";
import IconEmail                 from "@/assets/icons/email.svg?raw";
import IconEmailSolid            from "@/assets/icons/email-solid.svg?raw";
import IconQuestionMarkCirlce    from "@/assets/icons/question-mark-circle.svg?raw";
import IconExclamation           from "@/assets/icons/exclamation.svg?raw";
import IconExclamationCircle     from "@/assets/icons/exclamation-circle.svg?raw";
import IconFacebook              from "@/assets/icons/facebook.svg?raw";
import IconInstagram             from "@/assets/icons/Instagram.svg?raw";
import IconInvoice               from "@/assets/icons/invoice.svg?raw";
import IconLeft                  from "@/assets/icons/left.svg?raw";
import IconLeftDouble            from "@/assets/icons/left-double.svg?raw";
import IconLinkedin              from "@/assets/icons/linkedin.svg?raw";
import IconList                  from "@/assets/icons/list.svg?raw";
import IconListPlus              from "@/assets/icons/list-plus.svg?raw";
import IconLoadBalancer          from "@/assets/icons/loadBalancer.svg?raw";
import IconMenu                  from "@/assets/icons/menu.svg?raw";
import IconMinus                 from "@/assets/icons/minus.svg?raw";
import IconMobile                from "@/assets/icons/mobile.svg?raw";
import IconMobileAlt             from "@/assets/icons/mobile-alt.svg?raw";
import IconMoney                 from "@/assets/icons/money.svg?raw";
import IconNotes                 from "@/assets/icons/notes.svg?raw";
import IconPause                 from "@/assets/icons/pause.svg?raw";
import IconPlay                  from "@/assets/icons/play.svg?raw";
import IconPlus                  from "@/assets/icons/plus.svg?raw";
import IconReorder               from "@/assets/icons/reorder.svg?raw";
import IconRepeat                from "@/assets/icons/repeat.svg?raw";
import IconRight                 from "@/assets/icons/right.svg?raw";
import IconRightDouble           from "@/assets/icons/right-double.svg?raw";
import IconSave                  from "@/assets/icons/save.svg?raw";
import IconSaveDisk              from "@/assets/icons/save-disk.svg?raw";
import IconSearch                from "@/assets/icons/search.svg?raw";
import IconSend                  from "@/assets/icons/send.svg?raw";
import IconSettings              from "@/assets/icons/settings.svg?raw";
import IconStore                 from "@/assets/icons/store.svg?raw";
import IconTag                   from "@/assets/icons/tag.svg?raw";
import IconTableDuotone          from "@/assets/icons/table-duotone.svg?raw";
import IconThermometerHigh       from "@/assets/icons/thermometerHigh.svg?raw";
import IconThermometerLow        from "@/assets/icons/thermometerLow.svg?raw";
import IconThermometerMed        from "@/assets/icons/thermometerMed.svg?raw";
import IconTimer                 from "@/assets/icons/timer.svg?raw";
import IconTodo                  from "@/assets/icons/todo.svg?raw";
import IconTrash                 from "@/assets/icons/trash.svg?raw";
import IconTwitter               from "@/assets/icons/twitter.svg?raw";
import IconUnarchive             from "@/assets/icons/unarchive.svg?raw";
import IconUser                  from "@/assets/icons/user.svg?raw";
import IconUserEdit              from "@/assets/icons/userEdit.svg?raw";
import IconUsers                 from "@/assets/icons/users.svg?raw";
import IconUsersCogs             from "@/assets/icons/usersCogs.svg?raw";
import IconClubCount             from "@/assets/icons/club-count.svg?raw";
import IconSignoutDuotone        from "@/assets/icons/signout-duotone.svg?raw";
import IconGroupsDuotone         from "@/assets/icons/groups-duotone.svg?raw";
import IconBillingDuotone        from "@/assets/icons/billing-duotone.svg?raw";
import IconQuickResponsesDuotone from "@/assets/icons/quick-responses-duotone.svg?raw";
import IconStackDuotone          from "@/assets/icons/stack-duotone.svg?raw";
import IconGaugeDuotone          from "@/assets/icons/gauge-duotone.svg?raw";
import IconStaffDuotone          from "@/assets/icons/staff-duotone.svg?raw";
import IconChatsCircleDuotone    from "@/assets/icons/chats-circle-duotone.svg?raw";
import IconCalendarBlankDuotone  from "@/assets/icons/calendar-blank-duotone.svg?raw";
import IconCalendarDuotone       from "@/assets/icons/calendar-duotone.svg?raw";
import IconMonitorPlayDuotone    from "@/assets/icons/monitor-play-duotone.svg?raw";
import IconSwatchesDuotone       from "@/assets/icons/swatches-duotone.svg?raw";
import IconSegmentDuotone        from "@/assets/icons/segment-duotone.svg?raw";
import IconMoneyDuotone          from "@/assets/icons/money-duotone.svg?raw";
import IconQuestionDuotone       from "@/assets/icons/question-duotone.svg?raw";
import IconGearDuotone           from "@/assets/icons/gear-duotone.svg?raw";
import IconSignatureDuotone      from "@/assets/icons/signature-duotone.svg?raw";
import IconUserCircleGearDuotone from "@/assets/icons/user-circle-gear-duotone.svg?raw";
import IconUserGearDuotone       from "@/assets/icons/user-gear-duotone.svg?raw";
import IconUsersDuotone          from "@/assets/icons/users-duotone.svg?raw";
import IconUserMinusDuotone      from "@/assets/icons/user-minus-duotone.svg?raw";
import IconUserPlusDuotone       from "@/assets/icons/user-plus-duotone.svg?raw";
import IconLockClosed            from "@/assets/icons/lock-closed.svg?raw";
import IconLockOpen              from "@/assets/icons/lock-open.svg?raw";
import IconDotsVertical          from "@/assets/icons/dots-vertical.svg?raw";
import IconWarningTriangle       from "@/assets/icons/warning-triangle.svg?raw";
import IconPDFDuotone            from "@/assets/icons/pdf-duotone.svg?raw";
import IconCloudUpload           from "@/assets/icons/cloud-upload.svg?raw";

import IconFieldDate      from "@/assets/icons/field-date.svg?raw";
import IconFieldEmail     from "@/assets/icons/field-email.svg?raw";
import IconFieldInput     from "@/assets/icons/field-input.svg?raw";
import IconFieldMultiple  from "@/assets/icons/field-multiple.svg?raw";
import IconFieldSelect    from "@/assets/icons/field-select.svg?raw";
import IconFieldText      from "@/assets/icons/field-text.svg?raw";
import IconFieldYesNo     from "@/assets/icons/field-yes-no.svg?raw";
import IconFieldSignature from "@/assets/icons/field-signature.svg?raw";
import IconFieldRadio     from "@/assets/icons/field-radio.svg?raw";
import IconFieldImage     from "@/assets/icons/field-image.svg?raw";
import IconFieldTextArea  from "@/assets/icons/field-text-area.svg?raw";
import IconCopy           from "@/assets/icons/copy.svg?raw";
import IconChartBar       from "@/assets/icons/chart-bar.svg?raw";
import IconZapier         from "@/assets/icons/zapier.svg?raw";

export const iconNames = [
	"arrow-down",
	"arrow-up",
	"automations",
	"bell",
	"bullhorn",
	"calendar",
	"calendar-small",
	"cards",
	"chartPie",
	"check",
	"click",
	"clock",
	"close",
	"closeCircle",
	"dashboard",
	"delivered",
	"discussions",
	"down",
	"download",
	"dragorder",
	"edit",
	"email",
	"email-solid",
	"question-mark-cirlce",
	"exclamation",
	"exclamation-circle",
	"facebook",
	"Instagram",
	"invoice",
	"left",
	"left-double",
	"linkedin",
	"list",
	"list-plus",
	"loadBalancer",
	"menu",
	"minus",
	"mobile",
	"mobile-alt",
	"money",
	"notes",
	"pause",
	"play",
	"plus",
	"reorder",
	"repeat",
	"right",
	"right-double",
	"save",
	"save-disk",
	"search",
	"send",
	"settings",
	"store",
	"tag",
	"table-duotone",
	"thermometerHigh",
	"thermometerLow",
	"thermometerMed",
	"timer",
	"todo",
	"trash",
	"twitter",
	"unarchive",
	"user",
	"userEdit",
	"users",
	"usersCogs",
	"club-count",
	"signout-duotone",
	"groups-duotone",
	"billing-duotone",
	"quick-responses-duotone",
	"stack-duotone",
	"gauge-duotone",
	"staff-duotone",
	"chats-circle-duotone",
	"calendar-blank-duotone",
	"calendar-duotone",
	"monitor-play-duotone",
	"swatches-duotone",
	"segment-duotone",
	"money-duotone",
	"question-duotone",
	"gear-duotone",
	"user-circle-gear-duotone",
	"user-gear-duotone",
	"users-duotone",
	"user-minus-duotone",
	"user-plus-duotone",
	"lock-closed",
	"lock-open",
	"dots-vertical",
	"warning-triangle",
	"chart-bar",
	"signature-duotone",
	"pdf-duotone",
	"zapier",
	"cloud-upload",
	"field-date",
	"field-email",
	"field-input",
	"field-multiple",
	"field-select",
	"field-text",
	"field-yes-no",
	"field-signature",
	"field-radio",
	"field-text-area",
	"field-image"
] as const;

export const iconsSvgs = {
	"Instagram":                IconInstagram,
	"arrow-down":               IconArrowDown,
	"arrow-up":                 IconArrowUp,
	"automations":              IconAutomations,
	"bell":                     IconBell,
	"billing-duotone":          IconBillingDuotone,
	"bullhorn":                 IconBullhorn,
	"calendar":                 IconCalendar,
	"calendar-blank-duotone":   IconCalendarBlankDuotone,
	"calendar-duotone":         IconCalendarDuotone,
	"calendar-small":           IconCalendarSmall,
	"cards":                    IconCards,
	"chartPie":                 IconChartPie,
	"chats-circle-duotone":     IconChatsCircleDuotone,
	"check":                    IconCheck,
	"click":                    IconClick,
	"clock":                    IconClock,
	"close":                    IconClose,
	"closeCircle":              IconCloseCircle,
	"club-count":               IconClubCount,
	"dashboard":                IconDashboard,
	"delivered":                IconDelivered,
	"discussions":              IconDiscussions,
	"dots-vertical":            IconDotsVertical,
	"down":                     IconDown,
	"download":                 IconDownload,
	"dragorder":                IconDragorder,
	"edit":                     IconEdit,
	"email":                    IconEmail,
	"email-solid":              IconEmailSolid,
	"exclamation":              IconExclamation,
	"exclamation-circle":       IconExclamationCircle,
	"facebook":                 IconFacebook,
	"gauge-duotone":            IconGaugeDuotone,
	"gear-duotone":             IconGearDuotone,
	"groups-duotone":           IconGroupsDuotone,
	"invoice":                  IconInvoice,
	"left":                     IconLeft,
	"left-double":              IconLeftDouble,
	"linkedin":                 IconLinkedin,
	"list":                     IconList,
	"list-plus":                IconListPlus,
	"loadBalancer":             IconLoadBalancer,
	"lock-closed":              IconLockClosed,
	"lock-open":                IconLockOpen,
	"menu":                     IconMenu,
	"minus":                    IconMinus,
	"mobile":                   IconMobile,
	"mobile-alt":               IconMobileAlt,
	"money":                    IconMoney,
	"money-duotone":            IconMoneyDuotone,
	"monitor-play-duotone":     IconMonitorPlayDuotone,
	"notes":                    IconNotes,
	"pause":                    IconPause,
	"pdf-duotone":              IconPDFDuotone,
	"play":                     IconPlay,
	"plus":                     IconPlus,
	"question-duotone":         IconQuestionDuotone,
	"question-mark-cirlce":     IconQuestionMarkCirlce,
	"quick-responses-duotone":  IconQuickResponsesDuotone,
	"reorder":                  IconReorder,
	"repeat":                   IconRepeat,
	"right":                    IconRight,
	"right-double":             IconRightDouble,
	"save":                     IconSave,
	"save-disk":                IconSaveDisk,
	"search":                   IconSearch,
	"segment-duotone":          IconSegmentDuotone,
	"send":                     IconSend,
	"settings":                 IconSettings,
	"signature-duotone":        IconSignatureDuotone,
	"signout-duotone":          IconSignoutDuotone,
	"stack-duotone":            IconStackDuotone,
	"staff-duotone":            IconStaffDuotone,
	"store":                    IconStore,
	"swatches-duotone":         IconSwatchesDuotone,
	"table-duotone":            IconTableDuotone,
	"tag":                      IconTag,
	"thermometerHigh":          IconThermometerHigh,
	"thermometerLow":           IconThermometerLow,
	"thermometerMed":           IconThermometerMed,
	"timer":                    IconTimer,
	"todo":                     IconTodo,
	"trash":                    IconTrash,
	"twitter":                  IconTwitter,
	"unarchive":                IconUnarchive,
	"user":                     IconUser,
	"user-circle-gear-duotone": IconUserCircleGearDuotone,
	"user-gear-duotone":        IconUserGearDuotone,
	"user-minus-duotone":       IconUserMinusDuotone,
	"user-plus-duotone":        IconUserPlusDuotone,
	"userEdit":                 IconUserEdit,
	"users":                    IconUsers,
	"users-duotone":            IconUsersDuotone,
	"usersCogs":                IconUsersCogs,
	"warning-triangle":         IconWarningTriangle,
	"field-date":               IconFieldDate,
	"field-email":              IconFieldEmail,
	"field-input":              IconFieldInput,
	"field-multiple":           IconFieldMultiple,
	"field-select":             IconFieldSelect,
	"field-text":               IconFieldText,
	"field-yes-no":             IconFieldYesNo,
	"field-signature":          IconFieldSignature,
	"field-radio":              IconFieldRadio,
	"field-image":              IconFieldImage,
	"field-text-area":          IconFieldTextArea,
	"copy":                     IconCopy,
	"zapier":                   IconZapier,
	"cloud-upload":             IconCloudUpload,
	"chart-bar":                IconChartBar
};

export type PossibleIcons = typeof iconNames[number];
