<template>
  <button
      class="vbutton relative inline-block"
      role="button"
      :class="{'disabled': isDisabled}"
      :disabled="isDisabled"
      @click="click">
    <div
        v-if="isDisabled"
        class=" block top-0 left-0 absolute w-full h-full">
            <span
                v-if="disabled"
                class="block w-full h-full bg-white opacity-25" />
      <span
          v-if="loading"
          class="block w-full h-full absolute left-0 top-0">
                <loader class="w-full h-full" />
            </span>
    </div>
    <div
        class="max-w-full"
        :class="{'opacity-0': loading}">
      <slot />
    </div>
  </button>
</template>
<style>
.vbutton.disabled:hover {
  @apply cursor-not-allowed;
}
</style>
<script lang="ts">

import Loader             from "@/components/layout/Loader.vue";
import {computed, toRefs} from "vue";

export default {
  components: {
    Loader
  },

  props: {
    disabled: {
      type:     Boolean,
      required: false,
      default:  () => false
    },
    loading:  {
      type:     Boolean,
      required: false,
      default:  () => false
    }
  },
  emits: ["click"],

  setup(props, context) {
    const p = toRefs(props);

    const isDisabled = computed(() => {
      return p.loading.value || p.disabled.value;
    });

    const click = () => {
      if (!isDisabled.value) {

        context.emit("click");
      }
    };
    return {isDisabled, click};
  }
};

</script>
