<template>
  <div>
    <smart-select-tags
        v-model="selectedValue"
        :items="days"
        :label="'display'"
        class="block w-full"
        focus-on-show
    >
      <template #default="{ item }">
        {{ item }}
      </template>
    </smart-select-tags>
  </div>
</template>
<script lang="ts" setup>

import {computed, toRef, watch} from "vue";
import MultipleChoiceOperator   from "hm-filters/operators/MultipleChoiceOperator";
import SmartSelectTags          from "@/components/layout/Forms/SmartSelectTags.vue";
import {isArray}                from "lodash";

interface Props {
  operator: MultipleChoiceOperator;
  modelValue: any;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:modelValue", "is-valid"]);
const modelValue = toRef(props, "modelValue");
const operator = toRef(props, "operator");

const selectedValue = computed({
                                 get: () => {

                                   if (modelValue.value === null) {
                                     return [];
                                   }

                                   if (!isArray(modelValue.value)) {
                                     return [operator.value.multipleChoicesData.find((day) => day.value == modelValue.value).display];
                                   }
                                   return modelValue.value.map((current) => operator.value.multipleChoicesData.find((day) => day.value == current).display);
                                 },
                                 set: (newVal) => {
                                   if (!newVal) {
                                     emit("update:modelValue", []);
                                     return;
                                   }
                                   emit("update:modelValue",
                                        newVal.map((sel) => operator.value.multipleChoicesData.find((day) => day.display == sel).value)
                                   );
                                 }
                               });

const days = computed(() => {
  return operator.value.multipleChoicesData.map((i) => i.display);
});

const isValidCalculation = () => {
  let valid = modelValue.value !== null && isArray(modelValue.value) && modelValue.value.length > 0;
  if (!valid) {
    emit("is-valid", false);
    return;
  }
  for (let i = 0; i < modelValue.value.length; i++) {
    console.debug([modelValue.value[i], modelValue.value]);
    if (!operator.value.multipleChoicesData.find((choice) => choice.value === modelValue.value[i])) {
      emit("is-valid", false);
      return;
    }
  }
  emit("is-valid", valid);
  return valid;
};

const isValid = computed(() => {
  return isValidCalculation();
});

watch(modelValue, isValidCalculation);

</script>
