import Operator        from "./operator";
import {HasInput}      from "hm-filters/operators/Bases/InputBases";
import {OperatorTypes} from "hm-filters/operators/OperatorTypes";

export default class TextOperator extends Operator implements HasInput {
	hasInput: boolean = true;

	get type(): string {
		return OperatorTypes.Text;
	}
}
