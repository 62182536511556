<template>
  <div>
    <v-button
        :disabled="sending"
        :loading="sending"
        class="p-1 px-2  border rounded-lg border-red-100 bg-red-50 text-red-800 hover:bg-red-500 hover:text-white"
        @click="confirmOpen = true">
      <div class="flex items-center">
        <div class="text-sm pr-1">
          Delete
        </div>
        <div>
          <svg-icon
              class="block w-4 h-4 "
              name="trash" />
        </div>
      </div>
    </v-button>
  </div>

  <confirm-modal
      v-model:open="confirmOpen"
      :loading="sending"
      title="Are you sure?"
      type="warning"
      @clicked="deleteNow">
    <template #text>
      <p>Are you sure you want to delete the API Key <strong>{{ token.name }}</strong>?</p>
      <p>All the Zaps using this API Key will <strong>stop working.</strong></p>
      <p class="italic text-xs mt-4">
        This action is not reversible.
      </p>
    </template>
  </confirm-modal>
</template>
<script lang="ts" setup>
import {QLAccessToken, useDeleteZapierTokenMutation, ZapierTokensDocument} from "@/graphql/queries/ql/composables";
import {ref, useAttrs, useSlots}                                           from "vue";
import ConfirmModal                                                        from "@/components/layout/ConfirmModal.vue";
import $toast                                                              from "@/composables/toast";
import {
  updateCache
}                                                                          from "@/composables/queries/mutations/updateCache";
import VButton                                                             from "@/components/layout/VButton.vue";
import SvgIcon                                                             from "@/components/SvgIcon.vue";

interface Props {
  token: QLAccessToken;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["deleted"]);

const sending = ref(false);
const confirmOpen = ref(false);

const deleteMutation = useDeleteZapierTokenMutation({});

deleteMutation.onDone(() => {
  sending.value = false;
  confirmOpen.value = false;
  $toast({
           type:         "success",
           message:      "Access token deleted",
           linkRouteObj: null,
           linkText:     null
         });
});

deleteMutation.onError(() => {
  sending.value = false;

  $toast({
           type:         "error",
           message:      "There was a problem with your request. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const deleteNow = () => {
  sending.value = true;
  deleteMutation.mutate({
                          id: props.token.id
                        }, {
                          update: (store) => {
                            updateCache.deleteById(props.token.id,
                                                   ZapierTokensDocument,
                                                   "zapier_tokens",
                                                   store);
                          }
                        });
};

</script>
