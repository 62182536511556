<template>
  <filter-group-base-category
      v-model:filter-selected="filterSelected"
      v-model:operator-selected="operatorSelected"
      :category-name="categoryName"
      @step-back="stepBack"
      @added-rule="addedRule($event)"
  >
    <template #title>
      New Filter - Calendar
    </template>
    <template #subtitle>
      The Calendar category of filters deals with a specific day of the week or day of the
      month, and will almost never be used to create a segment, but may be useful for
      controlling trigger points for automations.
    </template>
    <template #side-description>
      <p>
        The calendar filters do not use and client data, and will only be useful for controlling which day of the week,
        or which day of the month continuous automations trigger.
      </p>

      <p class="pt-4">
        Example: Trigger this automation on Tuesdays of each week day of week, or on the 15th of each
        month.
      </p>
    </template>
    <template #filters>
      <slot />
    </template>
  </filter-group-base-category>
</template>
<script lang="ts">
import {singleCategoryComposition} from "@/components/Segment/Edit/Categories/SingleCategories/singleCategoryComposition";

const cat = singleCategoryComposition();
export default {
  components: {
    ...cat.components
  },
  props:      {
    ...cat.props
  },
  emits:      cat.emits,
  setup(props, {emit}) {
    return cat.setup(props, emit);
  }
};
</script>
