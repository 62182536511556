export function phone(number: any) {
	if (!number) {
		return "";
	}
	let p = "";
	if (number.startsWith("1")) {
		p = number.substring(1);
	} else {
		p = number;
	}

	return p.replace(/[^0-9]/g, "")
	        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

export default phone;
