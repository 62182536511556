import CommunicationAction         from "hm-filters/filters/communicationActionFilter";
import CommunicationHistoryFilter  from "hm-filters/filters/CommunicationHistoryFilter";
import CommunicationReceivedFilter from "hm-filters/filters/CommunicationReceivedFilter";
import {CommunicationCountFilter}  from "hm-filters/filters/CommunicationCountFilter";
import CommunicationCategoryFilter from "hm-filters/filters/CommunicationCategoryFilter";

export const CommunicationFilters = [
	new CommunicationAction("Action", {"field": "communication_action"})
		.setDescription(
			"This is a great filter to use to send a more compelling follow up to users who opened or clicked on an email, but still didn’t take the action you desired."),
	new CommunicationHistoryFilter("History", {"field": "communication_history"})
		.setDateFilter()
		.setDescription(
			"Communication History will likely be the second filter you add to any communication related rule. Adding Communication History to a filter group containing Communication Received will narrow down segment by timeframe in which clients received your selected communication(s)."),
	new CommunicationCountFilter("Count", {"field": "communication_count"})
		.setDescription(
			"Communication Count filters by the number of communications a client has triggered that meet all other communication filters contained in that rule."),
	new CommunicationReceivedFilter("Received", {"field": "communication_received"})
		.setDescription(
			"Communication Name will help you target users who did, or did not receive selected communications. Almost all rules for communication filters will begin with this filter, then add on history or action."),
	new CommunicationCategoryFilter("Category", {"field": "communication_category"})
		.setDescription(
			"Communication Category will help you target users who did, or did not receive communications from a specific category of automations. This filter will almost always require adding Communication Count to the same rule")
];
