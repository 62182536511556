import {HasInput}      from "hm-filters/operators/Bases/InputBases";
import TextOperator    from "hm-filters/operators/TextOperator";
import {OperatorTypes} from "hm-filters/operators/OperatorTypes";

export default class MultipleTextOperator extends TextOperator implements HasInput {
	hasInput: boolean = true;

	get type(): string {
		return OperatorTypes.MultipleText;
	}
}
