<template>
  <div>
    <div class="grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-6 grid-flow-row-dense">
      <div class="flex flex-col rounded-lg shadow overflow-hidden">
        <div class="flex items-center grow bg-white p-3 py-5">
          <div class="p-3 mr-4 text-loopspark-500 bg-loopspark-100 rounded-full dark:text-loopspark-100 dark:bg-loopspark-500">
            <svg-icon
                name="check"
                class="w-5 h-5"
                icon="check" />
          </div>
          <div>
            <p class="text-sm tracking-wide text-gray-600 dark:text-gray-400">
              Attended events
            </p>
            <p class="text-lg font-semibold  text-gray-900 dark:text-gray-200">
              {{ client.total_club_counts }}
            </p>
          </div>
        </div>
        <div class="bg-gray-100 px-3 py-2 text-sm ">
          <router-link
              :to="{name: routeList.client.events.index, params:{id: client.id}}"
              class="text-loopspark-500 font-medium">
            View all
          </router-link>
        </div>
      </div>

      <div class="lg:row-span-2 col-span-2 lg:col-span-3 p-4 bg-white rounded-lg shadow">
        <client-event-names-pie-chart :client-id="client.id" />
      </div>

      <div class="flex flex-col rounded-lg shadow  overflow-hidden">
        <div class="flex items-center grow bg-white p-3 py-5">
          <div class="p-3 mr-4 text-loopspark-500 bg-loopspark-100 rounded-full dark:text-loopspark-100 dark:bg-loopspark-500">
            <svg-icon
                name="calendar-small"
                class="w-5 h-5"
                icon="calendar" />
          </div>
          <div>
            <p class="text-sm tracking-wide text-gray-600 dark:text-gray-400">
              Upcoming events
            </p>
            <div class="text-lg font-semibold  text-gray-900 dark:text-gray-200">
              <div
                  v-if="!clientStats"
                  class="w-6 h-6">
                <loader class="h-4 w-4 text-gray-600" />
              </div>

              <span v-else>{{ clientStats.upcoming_events > 0 ? clientStats.upcoming_events : "-" }}</span>
            </div>
          </div>
        </div>
        <div class="bg-gray-100 px-3 py-2 text-sm ">
          <router-link
              :to="{name: routeList.client.events.upcoming, params:{id: client.id}}"
              class="text-loopspark-500 font-medium">
            View all
          </router-link>
        </div>
      </div>

      <div class="p-4 bg-white rounded-lg shadow">
        <div>
          <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
            Last event
          </p>
          <div v-if="client.last_event">
            <p class="text-md font-semibold text-gray-700 dark:text-gray-200">
              <time-ago :timestamp="client.last_event.class.start_time" />
            </p>
            <p class="text-gray-600 text-sm pt-1">
              <router-link
                  :to="{name: routeList.event.show, params:{id: client.last_event.class.id}}"
                  class="text-loopspark-500 font-medium">
                {{ client.last_event.class.name }}
              </router-link>
              <span v-if="client.last_event.class.staff && client.last_event.class.staff.name">with {{
                  client.last_event.class.staff.name
                                                                                               }}</span>
            </p>
          </div>
          <div v-else>
            <p class="text-md font-semibold text-gray-700 dark:text-gray-200">
              -
            </p>
          </div>
        </div>
      </div>

      <div class="lg:row-span-2 col-span-2 lg:col-span-3 p-4 bg-white rounded-lg shadow">
        <client-events-hourly-chart :client-id="client.id" />
      </div>
      <div class="p-4 bg-white rounded-lg shadow">
        <div>
          <p class="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
            Next event
          </p>
          <div v-if="client.next_event">
            <p class="text-md font-semibold text-gray-700 dark:text-gray-200">
              <time-ago :timestamp="client.next_event.class.start_time" />
            </p>
            <p class="text-gray-600 text-sm pt-1">
              <router-link
                  :to="{name: routeList.event.show, params:{id: client.next_event.class.id}}"
                  class="text-loopspark-500 font-medium">
                {{ client.next_event.class.name }}
              </router-link>
              <span v-if="client.next_event.class.staff && client.next_event.class.staff.name">with {{
                  client.next_event.class.staff.name
                                                                                               }}</span>
            </p>
          </div>
          <div v-else>
            <p class="text-md font-semibold text-gray-700 dark:text-gray-200">
              -
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12 mb-3">
      <div class=" flex items-center  space-x-4">
        <router-link
            class="pillbox block"
            :to="{name: routeList.client.events.index, params: {id: client.id}}">
          Past events
        </router-link>
        <router-link class="pillbox block"
                     :to="{name: routeList.client.events.upcoming, params:{id: client.id}}">
          Upcoming events
        </router-link>
      </div>
    </div>
    <router-view :client="client" />
  </div>
</template>
<script lang="ts" setup>
import SvgIcon                         from "@/components/SvgIcon.vue";
import Loader                          from "@/components/layout/Loader.vue";
import TimeAgo                         from "@/components/layout/TimeAgo.vue";
import ClientEventNamesPieChart        from "@/components/client/Events/ClientEventNamesPieChart.vue";
import ClientEventsHourlyChart         from "@/components/client/Events/ClientEventsHourlyChart.vue";
import {QLClient, useClientStatsQuery} from "@/graphql/queries/ql/composables";
import routesList                      from "@/composables/routesList";
import {toRef}                         from "vue";
import {useResult}                     from "@vue/apollo-composable";
import {useRoute}                      from "vue-router";
import {echoListener}                  from "@/composables/echoListener";
import {EventTypes}                    from "@/classes/notifications/EventTypes";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});
const {routeList} = routesList();
const client = toRef(props, "client");

const clientStatsQ = useClientStatsQuery(() => ({id: client.value.id}), {fetchPolicy: "cache-first"});

const clientStats = useResult(clientStatsQ.result, null, data => data.client_stats);

echoListener([EventTypes.VisitWasUpdated], (ev) => {
  if (ev.data.client_id == client.value.id) {
    clientStatsQ.refetch();
  }
}, 5000);

</script>
