<template>
  <div>
    <v-button
        class="block p-1 rounded text-red-300 hover:bg-red-200 hover:text-red-900"
        :loading="deleting"
        :disabled="deleting"
        @click="deleteNow">
      <svg-icon
          name="trash"
          class="w-4 h-4" />
    </v-button>
  </div>
</template>
<script lang="ts" setup>
import SvgIcon                                                   from "@/components/SvgIcon.vue";
import {ref}                                                     from "vue";
import {QLExcludedEventName, useDeleteExcludedEventNameMutation} from "@/graphql/queries/ql/composables";
import $toast                                                    from "@/composables/toast";
import VButton                                                   from "@/components/layout/VButton.vue";

interface Props {
  excludedEvent: QLExcludedEventName;
}

const props = withDefaults(defineProps<Props>(), {});


const emit = defineEmits(["deleted"]);

const deleting = ref(false);

const mutation = useDeleteExcludedEventNameMutation({});

mutation.onDone(() => {
  deleting.value = false;
  $toast({
           type:    "success",
           message: "Deleted!"
         });
  emit("deleted");
});
mutation.onError(() => {
  deleting.value = false;
  $toast({
           type:    "error",
           message: "Error deleting the excluded event. Please try again!"
         });
});

const deleteNow = () => {
  deleting.value = true;
  mutation.mutate({
                    id: props.excludedEvent.id
                  });
};
</script>
