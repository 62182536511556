<template>
  <page-title title="Discussions"/>
  <div class="container mx-auto grow space-y-16">
  <div class="w-full">
      <div class="mb-4 flex flex-row text-sm flex-wrap space-x-2">
        <div class="top-filter">
          <span>Status:</span>

          <inline-select
              class="select"
              v-model="status"
              :items="statusOptions">
            <template #default="{option}">
              <option :value="option.value">
                {{ option.label }}
              </option>
            </template>
          </inline-select>
        </div>

        <div class="top-filter">
          <span>Medium:</span>
          <inline-select
              class="select"
              v-model="medium"
              :items="mediumOptions">
            <template #default="{option}">
              <option :value="option.value">
                {{ option.label }}
              </option>
            </template>
          </inline-select>
        </div>

        <div
            v-if="!automationsLoading"
            class="top-filter">
          <span>Automation:</span>
          <inline-select
              class="select "
              v-model="selectedAutomation"
              :items="automationsToShow">
            <template #default="{option}">
              <option :value="option.id">
                {{ option.name }}
              </option>
            </template>
            <template #empty>
              <option :value="null">All</option>
            </template>
          </inline-select>
        </div>

        <div
            v-if="currentTeam && currentTeam.locations.length > 1"
            class="top-filter ">
          <span>Location:</span>
          <inline-select
              class="select"
              v-model="selectedLocation"
              :items="currentTeam.locations">
            <template #default="{option}">
              <option :value="option.id">
                {{ option.name }}
              </option>
            </template>
            <template #empty>
              <option :value="null">All</option>
            </template>
          </inline-select>
        </div>

        <div class="text-gray-700 md:text-right grow flex flex-col items-end">
          <div class="top-filter text-right">
            <span>Per page:</span>
            <inline-select
                class="select text-right"
                v-model="paginationData.perPage"
                :items="perPageOptions">
              <template #default="{option}">
                <option :value="option.value">
                  {{ option.label }}
                </option>
              </template>
            </inline-select>
          </div>
        </div>
      </div>

      <empty v-if="loadedFirstTime && discussions.length === 0">
        <template #title>
          Nothing to show!
        </template>
        <template #subtitle>
          There aren't any discussions with the current filters
        </template>
      </empty>

      <div
          v-if="!loadedFirstTime"
          class="w-full h-40 px-2 my-2">
        <div class="flex h-full items-center justify-center">
          <div class="w-12 h-12 text-gray-400 opacity-75">
            <loader />
          </div>
        </div>
      </div>

      <div
          v-if="loadedFirstTime && discussions.length > 0"
          class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class=" divide-y divide-gray-200 min-w-full">
                <thead class="bg-gray-50">
                <tr>
                  <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Client
                  </th>
                  <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Type
                  </th>
                  <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-full">
                    Information
                  </th>
                  <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right">
                    Date
                  </th>
                </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                <single-discussion
                    v-for="discussion in discussions"
                    :key="discussion.id"
                    :discussion="discussion" />
                </tbody>
                <tfoot
                    v-if="discussions && paginatorInfo.total > 0&& paginatorInfo.lastPage > 1"
                    class="bg-gray-50">
                <tr>
                  <td
                      colspan="5"
                      class="p-4">
                    <pagination
                        v-if="paginatorInfo.lastPage > 1"
                        :pagination="paginatorInfo"
                        class="flex items-center justify-between text-gray-700 text-sm"
                        @set-page="paginationData.page = $event">
                      <div
                          v-if="loading"
                          class="inline-block w-8 h-8 mr-2 text-gray-400">
                        <loader />
                      </div>
                    </pagination>
                  </td>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<style scoped>
.top-filter {
  @apply text-gray-700 flex items-center space-x-2;
}

.top-filter span {
  @apply whitespace-nowrap;
}

.top-filter .select {
  @apply text-sm font-bold w-full truncate grow;
  max-width:10em;
}

</style>
<script lang="ts" setup>
import PageTitle                                             from "@/components/navbar/PageTitle.vue";
import Empty                                               from "@/components/layout/Empty.vue";
import SingleDiscussion                                    from "@/components/discussions/listing/SingleDiscussion.vue";
import Pagination                                          from "@/components/layout/Pagination.vue";
import Loader                                              from "@/components/layout/Loader.vue";
import {computed, ref, watch}                              from "vue";
import {
  QLAutomation, QLAutomationDeliveryTypeEnum, QLDiscussionsQuery,
  QLDiscussionsQueryVariables, QLSortOrder, useAutomationsQuickListQuery,
  useDiscussionsQuery
}                                                          from "@/graphql/queries/ql/composables";
import currentTeamQuery                                    from "@/composables/queries/currentTeamQuery";
import {createPaginationData, paginationWithAlreadyLoaded} from "@/composables/paginationWithAlreadyLoaded";
import {useResult}                                         from "@vue/apollo-composable";
import InlineSelect                                        from "@/components/layout/Forms/InlineSelect.vue";
import {echoListener}                                      from "@/composables/echoListener";
import {EventTypes}                                        from "@/classes/notifications/EventTypes";

enum StatusOptions {
  "Open", "Closed", "Responded", "All"
}

enum MediumOptions {
  "Any", "Sms", "Email"
}

const status = ref<StatusOptions>(StatusOptions.Open);
const medium = ref<MediumOptions>(MediumOptions.Any);

const statusOptions = [
  {label: "Open", value: StatusOptions.Open},
  {label: "Closed", value: StatusOptions.Closed},
  {label: "All", value: StatusOptions.All}
];
const mediumOptions = [
  {label: "Any", value: MediumOptions.Any},
  {label: "Sms", value: MediumOptions.Sms},
  {label: "Email", value: MediumOptions.Email}
];

const perPageOptions = [
  {label: 10, value: 10},
  {label: 25, value: 25},
  {label: 50, value: 50}
];

const {paginationData} = createPaginationData({
                                                page:     1,
                                                perPage:  10,
                                                perPages: [10, 25, 50]
                                              });

const selectedAutomation = ref(null);
const selectedLocation = ref(null);

function resetPage() {
  return () => {
    paginationData.page = 1;
  };
}

watch(() => status, resetPage());
watch(() => medium, resetPage());
watch(() => selectedAutomation, resetPage());
watch(() => selectedLocation, resetPage());

const discusssionsVariables = computed<QLDiscussionsQueryVariables>(() => {
  let q: QLDiscussionsQueryVariables = {
    page:    paginationData.page,
    first:   paginationData.perPage,
    orderBy: [{column: "last_contacted_at", order: QLSortOrder.DESC}]
  };

  if (status.value === StatusOptions.Open) {
    q.closed = false;
  }
  if (status.value === StatusOptions.Closed) {
    q.closed = true;
  }
  if (status.value === StatusOptions.Responded) {
    q.team_has_responded = true;
  }
  if (medium.value === MediumOptions.Sms) {
    q.sms = true;
  }
  if (medium.value === MediumOptions.Email) {
    q.email = true;
  }
  if (selectedAutomation.value) {
    q.automation_id = selectedAutomation.value;
  }

  if (selectedLocation.value) {
    q.home_location_id = selectedLocation.value;
  }
  return q;
});

const discussionsQ = useDiscussionsQuery(discusssionsVariables);

const {
  paginatorInfo,
  loadedFirstTime,
  results: discussions,
  loading
} = paginationWithAlreadyLoaded<QLDiscussionsQuery["discussions"]["data"]>(discussionsQ);

echoListener([EventTypes.DiscussionNew, EventTypes.DiscussionUpdated], (ev) => {
  discussionsQ.refetch();
}, 5000);

const {result: automationsResults, loading: automationsLoading} = useAutomationsQuickListQuery();
const automationsRes = useResult(automationsResults, []);
const automationsToShow = computed(() => {
  return automationsRes.value.filter((aut: QLAutomation) => aut.delivery_type == QLAutomationDeliveryTypeEnum.EMAIL || aut.delivery_type == QLAutomationDeliveryTypeEnum.SMS);
});

const {currentTeam, loading: currentTeamLoading} = currentTeamQuery();

</script>
