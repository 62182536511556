import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import NumericOperator                from "hm-filters/operators/NumericOperator";
import {BookingGroup}                 from "hm-filters/filters/partials/BookingGroup";

export class BookingCountFilter extends BookingGroup {

	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new OperatorAsFixedValueOperator("never", "never before"),
			new NumericOperator(">", "more than", "bookings").setMinValue(0),
			new NumericOperator("<", "less than", "bookings").setMinValue(0),
			new NumericOperator("=", "exactly", "bookings").setMinValue(0)
		];
	}

	get type(): string {
		return "BookingCount";
	}
}
