<template>
  <TransitionRoot
      as="template"
      :show="openModel">
    <Dialog
        as="div"
        class="fixed inset-0 overflow-hidden z-100"
        @close="close">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
            as="template"
            enter="ease-in-out duration-500"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-500"
            leave-from="opacity-100"
            leave-to="opacity-0">
          <DialogOverlay class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full">
            <div
                class="w-screen"
                :class="{'max-w-lg': wideSize === 0, 'max-w-2xl': wideSize === 1, 'max-w-6xl': wideSize === 2}">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="grow h-0 overflow-y-auto flex flex-col">
                  <div :class="[hasHeader? 'bg-loopspark': 'bg-white','py-6 px-4  sm:px-6']">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        <slot name="header" />
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button
                            type="button"
                            v-if="closable"
                            :class="['rounded-md focus:outline-none focus:ring-2', hasHeader? 'bg-loopspark text-loopspark-200 focus:ring-white hover:text-white': 'bg-white text-gray-400 focus:ring-white hover:text-gray-700'] "
                            @click="close">
                          <span class="sr-only">Close panel</span>
                          <svg-icon
                              name="close"
                              class="h-6 w-6"
                              aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="grow flex flex-col justify-between">
                    <slot />
                  </div>
                </div>
                <div
                    v-if="hasFooter"
                    class="shrink-0 px-4 py-4 flex justify-end">
                  <slot name="footer" />
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
// noinspection ES6UnusedImports
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import SvgIcon                                                               from "@/components/SvgIcon.vue";

import {computed, ref, useAttrs, useSlots} from "vue";

interface Props {
  open: boolean;
  closable?: boolean;
  wide?: boolean;
  ultraWide?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  closable:  true,
  wide:      false,
  ultraWide: false
});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open", "closed"]);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const opened = ref(false);
const close = (arg) => {
  if(props.closable){
    openModel.value = false;
    emit('closed');
  }
};


const hasHeader = computed(() => {
  return !!slots["header"];
});
const hasFooter = computed(() => {
  return !!slots["footer"];
});

const wideSize = computed((): number => {

  if (props.wide) {
    if (props.ultraWide) {
      return 2;
    }
    return 1;
  }

  return 0;
});

defineExpose({
               close
             });

</script>
