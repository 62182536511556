<template>
  <div class="flex-1 flex flex-col bg-loopspark-500">
    <div class="container mx-auto mt-4 flex justify-between items-center">
      <change-team :current-team="currentTeam" :teams="me? me.teams : []"></change-team>
      <navbar-user class="text-white"></navbar-user>
    </div>
    <div class="flex-1 bg-loopspark-500 flex items-center justify-center">
      <div class="text-white">
        <div class="text-center">
          <p class="text-2xl font-semibold">Billing issues</p>
          <p class="pt-1" v-if="currentTeam">It looks like {{ currentTeam.name }} has some billing issues</p>
          <p class="pt-8 pb-2">You can ask your business owner to fix the issues by logging in and following the
                               instructions</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import NavbarUser       from "@/components/navbar/NavbarUser.vue";
import ChangeTeam       from "@/components/navbar/ChangeTeam.vue";
import currentTeamQuery from "@/composables/queries/currentTeamQuery";
import meQuery          from "@/composables/queries/meQuery";
const {currentTeam} = currentTeamQuery();
const {me} = meQuery();
</script>
