<template>
  <div class="bg-white shadow-md rounded-lg">
    <div
        v-if="!loadedFirstTime"
        class="animate-loading">
      <div class="px-4 divide-y divide-gray-200">
        <div
            v-for="i in 5"
            :key="i"
            class="py-4 flex items-center justify-between">
          <div class="grow text-lg items-center inline-flex">
            <span class="text-sm text-gray-600 pr-1">$</span>
            <loading-skeleton
                class-type="inline"
                text="00.00" />
          </div>

          <div class="text-right text-sm">
            <loading-skeleton
                class-type="inline"
                text="4/20/20 9:57 AM" />
            <loading-skeleton
                class="text-gray-600"
                class-type="inline"
                text="10 weeks ago" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="loadedFirstTime">
      <div v-if="salesList.length > 0">
        <div
            v-if="salesList.length > 0"
            class="px-4 divide-y divide-gray-200">
          <div
              v-for="sale in salesList"
              :key="sale.id"
              class="py-4 flex items-center justify-between">
            <div class="grow text-lg items-center inline-flex">
              <span class="text-sm text-gray-600 pr-1">$</span><span class="font-mono w-16 text-right">{{
                sale.price
                                                                                                       }}</span>
            </div>

            <div class="text-right text-sm">
              <timestamp-to-date
                  :timestamp="sale.sold_at"
                  studio
                  has-date
                  has-time />
              <time-ago
                  :timestamp="sale.sold_at"
                  class="text-gray-600 block" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="salesList.length === 0">
        <div class="py-16 px-4 w-full h-full text-center">
          <div class="text-xl text-bold text-gray-600">
            Nothing to show!
          </div>
          <p class="text-gray-600">
            {{ client.first_name }} does not have any sales yet!
          </p>
        </div>
      </div>
      <div
          v-if="loadedFirstTime && paginatorInfo.lastPage > 1"
          class="w-full pt-4 pb-4 text-right bg-gray-100 border-t border-gray-200 px-4 rounded-b-lg">
        <pagination
            v-if="paginatorInfo.lastPage > 1"
            :pagination="paginatorInfo"
            class="flex items-center justify-between text-gray-700 text-sm"
            @setPage="paginationData.page = $event">
          <div
              v-if="loading"
              class="inline-block w-8 h-8 mr-2 text-gray-400">
            <loader />
          </div>
        </pagination>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Loader          from "@/components/layout/Loader.vue";
import Pagination      from "@/components/layout/Pagination.vue";
import TimeAgo         from "@/components/layout/TimeAgo.vue";
import TimestampToDate from "@/components/layout/TimestampToDate.vue";
import LoadingSkeleton from "@/components/layout/LoadingSkeleton.vue";

import {toRef}                                                          from "vue";
import {QLClient, QLClientSalesQuery, QLSortOrder, useClientSalesQuery} from "@/graphql/queries/ql/composables";
import {
  createPaginationData, paginationWithAlreadyLoaded
}                                                                       from "@/composables/paginationWithAlreadyLoaded";
import {echoListener}                                                   from "@/composables/echoListener";
import {EventTypes}                                                     from "@/classes/notifications/EventTypes";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 10
                                              });
const client = toRef(props, "client");

const productsQ = useClientSalesQuery(() => ({
  client_id: client.value.id.toString(),
  page:      paginationData.page,
  first:     paginationData.perPage,
  orderBy:   [
    {
      column: "sold_at",
      order:  QLSortOrder.DESC
    }
  ]
}));

const {
  paginatorInfo,
  loadedFirstTime,
  results: salesList,
  loading
} = paginationWithAlreadyLoaded<QLClientSalesQuery["sales"]["data"]>(productsQ);

echoListener([EventTypes.SaleWasUpdated], (ev) => {
  if (ev.data.client_id == client.value.id) {
    productsQ.refetch();
  }
}, 2000);

</script>
