// Firebase App (the core Firebase SDK) is always required and must be listed first
import {FirebaseApp, initializeApp} from "firebase/app";
import {getAnalytics}               from "firebase/analytics";
import {getMessaging, getToken}     from "firebase/messaging";

export const firebaseConfig = {
	apiKey:            "AIzaSyCpZ4uVdSdKVmtUn8NWMUwPdvUrRYqhRRI",
	authDomain:        "hellomanagers-193822.firebaseapp.com",
	databaseURL:       "https://hellomanagers-193822.firebaseio.com",
	projectId:         "hellomanagers-193822",
	storageBucket:     "hellomanagers-193822.appspot.com",
	messagingSenderId: "527483264164",
	appId:             "1:527483264164:web:42959d178eef76d8ba9a31",
	measurementId:     "G-QW1X8312F8"
};

let firebaseApp: FirebaseApp = null;

export function firebaseInit() {
	firebaseApp = initializeApp(firebaseConfig);
	getAnalytics(firebaseApp);
}

export async function firebaseGetToken() {
	const messagingKey = "BOL5S-eTxImij1KY7XRDO5b27lTQDVJFl3PjOb3bxxlkAmJOanLTODhIXaXFiGk8nKX2a-wtc1oBrfi5VhYzA8w";
	try {
		return await getToken(getMessaging(firebaseApp), {vapidKey: messagingKey});
	} catch (e) {
		console.warn(e);
		return null;
	}
}
