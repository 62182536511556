<template>
  <div
      class="inline-block"
      :style="containerWidth">
    <div class="relative">
      <select
          ref="selectbox"
          v-model="selectedOnSelect"
          class="appearance-none bg-none p-0 block border-0 w-full bg-transparent hover:border-gray-500 pr-6 focus:outline-none focus:ring focus:ring-transparent"
          :class="selectClasses">
        <option
            v-if="withEmpty"
            :value="-1">
          {{ emptyString }}
        </option>
        <option
            v-for="(o, index) in options"
            :key="index"
            :value="index">
          {{ o[label] }}
        </option>
      </select>
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg
            class="fill-current h-4 w-4"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
    <div style="position: absolute;z-index: -100; top:0; left:0">
      <span
          ref="hiddenspan"
          style="visibility: hidden"
          v-text="selectedText" />
    </div>
  </div>
</template>
<script lang="ts">
import {computed, defineComponent, nextTick, onMounted, ref, toRef, watch} from "vue";

export default defineComponent(
    {
      name:  "InlineMutedSelect",
      props: {
        options:       {
          type:     Array,
          required: true,
          default:  () => []
        },
        maxWidth:      {
          type:     Number,
          required: false,
          default:  () => 0
        },
        option:        {
          type:     String,
          required: false,
          default:  () => "value"
        },
        label:         {
          type:     String,
          required: false,
          default:  () => "label"
        },
        withEmpty:     {
          type:     Boolean,
          required: false,
          default:  () => false
        },
        emptyString:   {
          type:     String,
          required: false,
          default:  () => "-"
        },
        selectClasses: {
          type:     String,
          required: false,
          default:  () => "font-bold"
        },
        modelValue:    {
          required: true,
          default:  () => null
        }
      },
      emits: ["update:modelValue"],
      setup(props, {emit}) {
        const selectbox = ref(null);
        const hiddenspan = ref(null);

        const selectedLength = ref(null);
        const selectedText = ref("");

        const maxWidth = toRef(props, "maxWidth");
        const options = toRef(props, "options");
        const option = toRef(props, "option");
        const modelValue = toRef(props, "modelValue");

        const containerWidth = computed(() => {
          let w = (selectedLength.value + 45);
          if (maxWidth.value > 1 && w > maxWidth.value) {
            w = maxWidth.value;
          }
          return {
            "width": w + "px"
          };
        });

        const selectedOnSelect = computed({
                                            get: () => {
                                              if (modelValue.value === null) {
                                                return null;
                                              }
                                              return options.value.findIndex((i) => {
                                                return i[option.value] === modelValue.value;
                                              });
                                            },
                                            set: (newVal) => {
                                              if (newVal === null || (props.withEmpty && newVal === -1)) {
                                                emit("update:modelValue", null);
                                              } else {
                                                emit("update:modelValue", options.value[newVal][option.value]);
                                              }
                                            }
                                          });


        const selectedValue = () => {
          let r = options.value.findIndex((i) => {
            return i[option.value] === modelValue.value;
          });

          if (r == undefined || r === -1) {
            if (props.withEmpty) {
              return props.emptyString;
            }
            return "";
          }
          return options.value[r][props.label];
        };

        const calculateLength = async () => {
          await nextTick();
          selectedText.value = selectedValue();
          await nextTick();
          if (hiddenspan.value) {
            selectedLength.value = (hiddenspan.value as HTMLElement).offsetWidth;
          }
        };

        watch(modelValue, () => {
          calculateLength();
        });
        onMounted(() => calculateLength());


        return {
          selectbox,
          hiddenspan,
          selectedText,
          containerWidth,
          selectedOnSelect,
          selectedValue
        };
      }
    }
);
</script>
