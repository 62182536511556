<template>
  <div>
    <div
        ref="chartdiv"
        class="flowchart w-full h-full min-h-full" />
  </div>
</template>
<script lang="ts" setup>

import {QLClientEngagementStats}               from "@/graphql/queries/ql/composables";
import {computed, onMounted, onUnmounted, ref} from "vue";
import * as am4charts                          from "@amcharts/amcharts4/charts";
import {SlicedChart}                           from "@amcharts/amcharts4/charts";
import * as am4core                            from "@amcharts/amcharts4/core";
import am4themes_Loopspark                     from "@/components/layout/Charts/Am4Theme";

interface Props {
  engagementStats: QLClientEngagementStats;
}

const props = withDefaults(defineProps<Props>(), {});

const chartdiv = ref<HTMLElement>(null);


const generatedData = computed(() => {
  return [
    {name: "Emails Sent", value: props.engagementStats.email.sent},
    {name: "Delivered", value: props.engagementStats.email.delivered},
    {name: "Opened", value: props.engagementStats.email.opened},
    {name: "Clicked", value: props.engagementStats.email.clicked}
  ];
});

let chart: SlicedChart = null;

onMounted(() => {
  am4core.unuseAllThemes();
  am4core.useTheme(am4themes_Loopspark);
  am4core.options.commercialLicense = true;
  chart = am4core.create(chartdiv.value, am4charts.SlicedChart);
  chart.responsive.enabled = true;
  chart.responsive.useDefault = true;

  chart.data = generatedData.value;

  let pieSeries = chart.series.push(new am4charts.FunnelSeries());
  pieSeries.dataFields.value = "value";
  pieSeries.dataFields.category = "name";
  pieSeries.alignLabels = true;
  pieSeries.labelsOpposite = true;
  pieSeries.labels.template.text = "{category}: [bold]{value}[/]";
  pieSeries.slices.template.tooltipText = "{category}: {value.value}";
});

onUnmounted(() => {
  if (chart) {
    chart.dispose();
  }
});
</script>
