import Filter from "../base-filter";

class StaffValues extends Filter {
	smallText = "Staff";
	group = "Staff";
	path = ["Visit", "Class", "Staff"];

	get type(): string {
		return "StaffValues";
	}
}

export default StaffValues;
