import Filter from "../../base-filter";

import config                                             from "./config";
import FixedValueOperator, {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import NumericOperator                                    from "hm-filters/operators/NumericOperator";


class VisitCount extends Filter {
	smallText = config.visits.groups.visit.group;
	group = config.visits.groups.visit.group;
	path = ["Visit", this.group];

	constructor(name: string, options: any) {
		super(config.visits.count, options);

		this.operators = [
			new OperatorAsFixedValueOperator("never", "never before"),
			new NumericOperator(">", "more than", "visits"),
			new NumericOperator("<", "less than", "visits"),
			new NumericOperator("=", "exactly", "visits")
		];
	}

	get type(): string {
		return "VisitCount";
	}
}

class LateCancelCount extends VisitCount {
	smallText = config.visits.groups.lateCancelled.group;
	group = config.visits.groups.lateCancelled.group;
	path = ["Visit", this.group];

	get type(): string {
		return "LateCancelCount";
	}
}

class AbsentCount extends VisitCount {
	smallText = config.visits.groups.absent.group;
	group = config.visits.groups.absent.group;
	path = ["Visit", this.group];

	get type(): string {
		return "AbsentCount";
	}
}

class EarlyCancelCount extends VisitCount {
	smallText = config.visits.groups.early_cancel.group;
	group = config.visits.groups.early_cancel.group;
	path = ["Visit", this.group];

	get type(): string {
		return "EarlyCancelCount";
	}
}

class UpcomingVisitCount extends VisitCount {
	smallText = config.visits.groups.upcomingVisit.group;
	group = config.visits.groups.upcomingVisit.group;
	path = ["Visit", this.group];

	constructor(name: string, options: any) {
		super(config.visits.count, options);

		this.operators = [
			new OperatorAsFixedValueOperator("never", "none"),
			new NumericOperator(">", "more than", "visits"),
			new NumericOperator("<", "less than", "visits"),
			new NumericOperator("=", "exactly", "visits")
		];
	}

	get type(): string {
		return "UpcomingVisitCount";
	}
}

export {
	VisitCount,
	LateCancelCount,
	UpcomingVisitCount,
	AbsentCount,
	EarlyCancelCount
};
