<template>
  <div>
    <div>
      <h1 class="main-page-title">
        Notes for {{ client.first_name }}
      </h1>
    </div>
    <div class="text-gray-700 mr-2 mb-4">
      Status:
      <inline-muted-select
          v-model="status"
          :options="statusOptions" />
    </div>
    <div v-if="!loadedFirstTime">
      <div class="space-y-6 animate-loading">
        <div
            v-for="i in 5"
            :key="i"
            class="bg-white shadow rounded-lg p-4">
          <div class="prose w-full overflow-hidden max-w-full truncate">
            <loading-skeleton
                :max="200"
                :min="60"
                class-type="inline"
                random-text />
            <loading-skeleton
                :max="100"
                :min="60"
                class-type="inline"
                random-text />
            <loading-skeleton
                :max="100"
                :min="60"
                class-type="inline"
                random-text />
            <loading-skeleton
                :max="100"
                :min="60"
                class-type="inline"
                random-text />
          </div>
          <div class="mt-2 md:flex md:justify-between items-end">
            <div class="flex items-center">
              <div class="w-8 h-8 loading-bg rounded-full" />
              <div class="ml-2 text-gray-700">
                <div class="font-medium">
                  <loading-skeleton
                      class-type="inline"
                      random-text />
                </div>
                <div class="text-xs">
                  <loading-skeleton
                      class-type="inline"
                      random-text />
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="block w-16 h-8 loading-bg rounded" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loadedFirstTime">
      <div v-if="notes && notes.length === 0">
        <empty>
          <template #title>Nothing to show!</template>
          <template #subtitle>
            <p
                v-if="status === 'Open'"
                class="text-gray-600">
              {{ client.first_name }} does not have any open notes!
            </p>
            <p
                v-if="status !== 'Open'"
                class="text-gray-600">
              {{ client.first_name }} does not have any notes!
            </p>
            <div class="flex pt-6 space-x-4 justify-center">
              <button
                  class="text-sm text-blue-600 hover:text-blue-800"
                  @click="addNote">
                Create a new note...
              </button>
            </div>
          </template>
        </empty>
      </div>
      <div
          v-if="notes && notes.length > 0"
          class="space-y-6">
        <client-single-note-in-list
            v-for="note in notes"
            :key="note.id"
            :client="client"
            :note="note" />
      </div>

      <div
          v-if="notes && paginatorInfo.total > 0&& paginatorInfo.lastPage > 1"
          class="w-full pt-4 pb-4 text-right bg-gray-100 border-t border-gray-200 px-4 rounded-b">
        <pagination
            v-if="paginatorInfo.lastPage > 1"
            :pagination="paginatorInfo"
            class="flex items-center justify-between text-gray-700 text-sm"
            @setPage="paginationData.page = $event">
          <div
              v-if="loading"
              class="inline-block w-8 h-8 mr-2 text-gray-400">
            <loader />
          </div>
        </pagination>
      </div>
    </div>
  </div>
  <client-add-note
      v-model:open="addNoteModal"
      :client="client" />
</template>
<script lang="ts" setup>

import InlineMutedSelect                                   from "@/components/layout/InlineMutedSelect.vue";
import ClientSingleNoteInList                              from "@/components/client/Notes/ClientSingleNoteInList.vue";
import Pagination                                          from "@/components/layout/Pagination.vue";
import Loader                                              from "@/components/layout/Loader.vue";
import LoadingSkeleton                                     from "@/components/layout/LoadingSkeleton.vue";
import {
  QLClient, QLClientNotesQuery, QLClientNotesQueryVariables, QLSortOrder, useClientNotesQuery
}                                                          from "@/graphql/queries/ql/composables";
import {computed, ref, toRef, watch}                       from "vue";
import {createPaginationData, paginationWithAlreadyLoaded} from "@/composables/paginationWithAlreadyLoaded";
import Empty                                               from "@/components/layout/Empty.vue";
import ClientAddNote                                       from "@/components/client/ClientAddNote.vue";
import {
  echoListener
}                                                          from "@/composables/echoListener";
import {
  EventTypes
}                                                          from "@/classes/notifications/EventTypes";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");

const status = ref<"open" | "archived">("open");
const addNoteModal = ref(false);

const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 10
                                              });

const statusOptions = [
  {label: "Open", value: "open"},
  {label: "Archived", value: "archived"}
];

const notesVariables = computed<QLClientNotesQueryVariables>(() => {
  return {
    client_id: client.value.id,
    page:      paginationData.page,
    first:     paginationData.perPage,
    archived:  status.value !== "open",
    orderBy:   [{column: "created_at", order: QLSortOrder.DESC}]
  };
});

const notesQ = useClientNotesQuery(notesVariables);

const {
  paginatorInfo,
  loadedFirstTime,
  results: notes,
  loading
} = paginationWithAlreadyLoaded<QLClientNotesQuery["notes"]["data"]>(notesQ);

echoListener([EventTypes.NoteWasCreated, EventTypes.NoteWasArchived], (ev) => {
  if (ev.data.note_model_id == client.value.id && ev.data.note_model_type == "App\\Client") {
    notesQ.refetch();
  }
}, 5000);

const addNote = () => addNoteModal.value = true;

watch(status, () => {
  paginationData.page = 1;
});
</script>
