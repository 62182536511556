<template>
  <div>
    <div class="block h-48 flex items-center justify-center" v-if="!me || !currentTeam">
      <loader class="w-8 h-8"></loader>
    </div>
    <div class="block overflow-hidden shadow-md rounded-lg bg-white" v-if="me && currentTeam">
      <div

          class="divide-y">
        <settings-your-teams-list-item
            v-for="team in me.teams"
            :key="team.id"
            :current-team="currentTeam"
            :team="team"
            :me="me" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import SettingsYourTeamsListItem from "@/components/user/settings/SettingsYourTeamsListItem.vue";
import meQuery                   from "@/composables/queries/meQuery";
import currentTeamQuery          from "@/composables/queries/currentTeamQuery";
import Loader                    from "@/components/layout/Loader.vue";

const {me} = meQuery();

const {currentTeam} = currentTeamQuery();

</script>
