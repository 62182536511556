import Echo   from "laravel-echo";
import Pusher from "pusher-js";

// @ts-ignore
window.Pusher = Pusher;

Pusher.Runtime.createXHR = function () {
	let xhr = new XMLHttpRequest();
	xhr.withCredentials = true;
	return xhr;
};

export class MyEcho {
	private static instance: MyEcho;
	private readonly _echo: Echo;

	private constructor() {

		const options = {
			key:               import.meta.env.VITE_APP_PUSHER_KEY,
			broadcaster:       "pusher",
			cluster:           "us",
			wsHost:            import.meta.env.VITE_APP_ECHO_HOST,
			wsPort:            import.meta.env.VITE_APP_ECHO_PORT as unknown as number,
			forceTLS:          false,
			encrypted:         true,
			disableStats:      true,
			enabledTransports: ["ws", "wss"],
			authEndpoint:      import.meta.env.VITE_APP_MAIN_URL + "/broadcasting/auth"
		};

		this._echo = new Echo(options);
	}

	get echo() {
		return this._echo;
	}

	public static getInstance(): MyEcho {
		if (!MyEcho.instance) {
			MyEcho.instance = new MyEcho();
		}

		return MyEcho.instance;
	}

	listenForUser(id: string | number) {
		return this.echo.private("App.User." + id);
	}
}
