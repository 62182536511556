import {QLTagKindEnum, useAllTagsQuery} from "@/graphql/queries/ql/composables";
import {FetchPolicy}                    from "@apollo/client/core";
import {computed}                       from "vue";

export function tagsQuery(fetchPolicy: FetchPolicy = "cache-first") {
	const {result, loading, onResult} = useAllTagsQuery({fetchPolicy});
	const tags = computed(() => result.value?.tags ?? []);
	return {
		result,
		tags,
		loading,
		onResult
	};
}

const filterTags = (fetchPolicy: FetchPolicy = "cache-first", tagType: QLTagKindEnum) => {
	const {result, loading, onResult, tags} = tagsQuery(fetchPolicy);

	const filtered = computed(() => {
		return tags.value.filter((item) => item.kind === tagType).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1: -1);
	});

	return {
		result,
		tags: filtered,
		loading,
		onResult
	};
};

export function tagsClientsQuery(fetchPolicy: FetchPolicy = "cache-first") {
	return filterTags(fetchPolicy, QLTagKindEnum.CLIENT);
}

export function tagsTodosQuery(fetchPolicy: FetchPolicy = "cache-first") {
	return filterTags(fetchPolicy, QLTagKindEnum.TODO);
}
