<template>
  <v-button
      v-if="me && me.policy.export_clients"
      :disabled="sending"
      :loading="sending"
      class="btn text-blue-900 btn-transparent text-xs py-1 px-2 leading-none"
      role="button"
      @click="exportNow">
    <div class="inline-flex items-center justify-center">
      <svg-icon
          name="download"
          class="h-4 w-4" />
      <div class="pl-1">
        Export
      </div>
    </div>
  </v-button>
</template>
<script lang="ts" setup>

import VButton                               from "@/components/layout/VButton.vue";
import {ref, useAttrs, useSlots}             from "vue";
import {QLSegment, useExportClientsMutation} from "@/graphql/queries/ql/composables";
import meQuery                               from "@/composables/queries/meQuery";
import SvgIcon                               from "@/components/SvgIcon.vue";
import $toast                                from "@/composables/toast";
import SegmentDecorator                      from "@/composables/decorators/SegmentDecorator";

interface Props {
  segment: QLSegment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const meQ = meQuery();

const {me} = meQ;
const sending = ref(false);



const mut = useExportClientsMutation({});

mut.onDone(() => {
  sending.value = false;
  $toast({
           type:         "success",
           message:      "The report is being created. You will receive a notification once it's ready to download",
           linkRouteObj: null,
           linkText:     null
         });
});

mut.onError(() => {
  sending.value = false;
  $toast({
           type:         "error",
           message:      "There was a problem with your request. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const exportNow = () => {
  sending.value = true;
  const seg = SegmentDecorator(props.segment);
  mut.mutate({
               segment: seg.computed.toPost
             });
};

</script>
