import {QLFieldTypeEnum} from "@/graphql/queries/ql/composables";
import {PossibleIcons}   from "@/assets/icons/iconNames";

interface AvailableNewField {
	title: string,
	description: string,
	kind: QLFieldTypeEnum,
	icon: PossibleIcons,
	mboMapping: Array<FormMboMapping>
}

export interface FormMboMapping {
	value: string,
	display: string
}

export interface FormMboMappingWithUsage extends FormMboMapping {
	used: boolean;
}

const fieldsData: { [key in QLFieldTypeEnum]?: AvailableNewField } = {
	TEXT_BLOCK: {
		title:       "Text Block (form design)",
		description: "Text Block is used for designing your form, adding textual content such as terms of service, form instructions, and form titles. This is not used for customer data input. You can include as many Text Blocks as you like, and your form will often have multiple Text Blocks included to guide the customer through the form, list all waiver agreements, and add titles to the form.",
		kind:        QLFieldTypeEnum.TEXT_BLOCK,
		icon:        "field-text",
		mboMapping:  []
	},
	TEXT_INPUT: {
		title:       "Short text input",
		description: "Use this field for asking for small bits of text such as names, addresses, values, and more. Customers have one line of text to answer the question.",
		kind:        QLFieldTypeEnum.TEXT_INPUT,
		icon:        "field-input",
		mboMapping:  [
			{value: "Country", display: "Country"},
			{value: "State", display: "State"},
			{value: "AddressLine1", display: "Address line"},
			{value: "City", display: "City"},
			{value: "PostalCode", display: "Postal code"},
			{value: "ReferredBy", display: "Referred by"},
			{value: "EmergencyContactInfoName", display: "Emergency contact info name"},
			{value: "EmergencyContactInfoRelationship", display: "Emergency contact info relationship"}
		]
	},
	SELECT:     {
		title:       "Dropdown selection",
		description: "All of your field options in one dropdown menu. Clients can only select one option from the dropdown menu. This is a useful alternative to “Multiple Choice” where you only want one question response, and a field that is small and compact.",
		kind:        QLFieldTypeEnum.SELECT,
		icon:        "field-select",
		mboMapping:  []
	},
	YES_NO:     {
		title:       "Checkbox",
		description: "Single checkbox providing only one option. If field is marked as required, a customer must check the box in order to complete your form. This is helpful for waivers or anywhere that a customer must agree to your terms of service before submitting a completed form.",
		kind:        QLFieldTypeEnum.YES_NO,
		icon:        "field-yes-no",
		mboMapping:  [
			{
				display: "Liability Release",
				value:   "LiabilityRelease"
			}
		]
	},
	RADIO:      {
		title:       "Radio button",
		description: "Allow clients to choose ONLY ONE of a predefined set of mutually exclusive options. Use radio buttons for limiting clients to only one option like “yes or no,” gender selection, or rating their most recent class experience.",
		kind:        QLFieldTypeEnum.RADIO,
		icon:        "field-radio",
		mboMapping:  []
	},
	MULTIPLE:   {
		title:       "Multiple choice",
		description: "Multiple choice input field lets you list options and have users select one or multiple options. If this field is required, customers will be required to select at least one option.",
		kind:        QLFieldTypeEnum.MULTIPLE,
		icon:        "field-multiple",
		mboMapping:  []
	},
	SIGNATURE:  {
		title:       "Signature field",
		description: "Add a signature field to your form. This is usually added to waivers to confirm the client's signature before submitting. When added to a form, the signature field will default to required unless you mark it as optional.",
		kind:        QLFieldTypeEnum.SIGNATURE,
		icon:        "field-signature",
		mboMapping:  []
	},
	EMAIL:      {
		title:       "Email Address input field",
		description: "Simple text field that validates the customer's response is valid email address formatting.  Customers must input a response that is a valid email format in order to complete this field.",
		kind:        QLFieldTypeEnum.EMAIL,
		icon:        "field-email",
		mboMapping:  [
			{
				value:   "EmergencyContactInfoEmail",
				display: "Emergency contact info email"
			}
		]
	},
	PHONE:      {
		title:       "Phone input field",
		description: "A text field that validates the customer's response is a valid phone number formatting.  Customers must input a response that is a valid phone number format in order to complete this field.",
		kind:        QLFieldTypeEnum.PHONE,
		icon:        "mobile",
		mboMapping:  [
			{
				display: "Mobile phone",
				value:   "MobilePhone"
			},
			{
				display: "Home phone",
				value:   "HomePhone"
			},
			{
				display: "Work phone",
				value:   "WorkPhone"
			},
			{
				display: "Emergency contact info phone",
				value:   "EmergencyContactInfoPhone"
			}
		]
	},
	DATE:       {
		title:       "Date input field",
		description: "Need to ask for a specific date or time, perhaps to schedule an event, capture a birthday, or log an activity? The date field is what you’re looking for.  Different than a short text input field, the date field requires a valid date be entered.",
		kind:        QLFieldTypeEnum.DATE,
		icon:        "field-date",
		mboMapping:  [
			{
				value:   "BirthDate",
				display: "Birth date"
			}
		]
	},
	IMAGE:      {
		title:       "Image",
		description: "Add an image to your form. This can be used to add your business logo to the top of your form, or images throughout your form content.",
		kind:        QLFieldTypeEnum.IMAGE,
		icon:        "field-image",
		mboMapping:  []
	},
	TEXT_AREA:  {
		title:       "Long text input",
		description: "Use this field when requesting long textual answers from customers, such as illness or injury descriptions, current fitness routines, comments and feedback etc. Customers will have a large text area providing multiple lines of text to answer the question.",
		kind:        QLFieldTypeEnum.TEXT_AREA,
		icon:        "field-text-area",
		mboMapping:  [
			{
				value:   "RedAlert",
				display: "Red Alert"
			}, {
				value:   "YellowAlert",
				display: "Yellow Alert"
			}
		]
	},
	STATE:      {
		title:       "US State selection",
		description: "A dropdown list containing all the US states. This field is convenient because it assures that the entered data is uniform.",
		kind:        QLFieldTypeEnum.STATE,
		icon:        "field-select",
		mboMapping:  [
			{
				value:   "State",
				display: "State"
			}
		]
	}
};

export default fieldsData;
