<template>
  <input-field-base
      v-if="fieldModel"
      v-model:field="fieldModel"
      v-model:open="openModel"
      :can-save="canSave"
      :name-already-used="nameAlreadyUsed"
      @cancelled="openModel = false"
      @saved="saved">
    <template #fields>
      <form-check-box
          v-model="fieldModel.optional"
          title="Optional" />
    </template>
  </input-field-base>
</template>
<script lang="ts" setup>
import {computed,  withDefaults} from "vue";
import FormCheckBox
                                                          from "@/components/Forms/Fields/Input/Partials/FormCheckBox.vue";
import InputFieldBase                                     from "@/components/Forms/Fields/Input/InputFieldBase.vue";
import InputFieldsFunctions                               from "@/components/Forms/Fields/Input/InputFieldsFunctions";
import {QLFormField}                                      from "@/graphql/queries/ql/composables";

interface InputFieldProps {
  field: QLFormField;
  open: boolean;
  otherFields: QLFormField[];
  useMappings: boolean;

}

const props = withDefaults(defineProps<InputFieldProps>(), {});
const emit = defineEmits(["deleted", "update:field", "saved", "update:open"]);

const {
  fieldModel,
  openModel,
  otherFields,
  saved,
  toRun,
  nameAlreadyUsed
} = InputFieldsFunctions.bootstrapEditField(emit,
                                            props);
toRun();

const canSave = computed(() => {
  if (!fieldModel.value) {
    return false;
  }
  if (InputFieldsFunctions.nameIsAlreadyUsed(otherFields.value, fieldModel.value)) {
    return false;
  }

  return fieldModel.value.title.trim().length > 0;
});

</script>
