<template>
  <div class="overflow-hidden relative">
    <div class="flex items-center space-x-1 relative">
      <div class="truncate">{{ txt }}</div>
      <div class="shrink-0">
        <svg
            class="fill-current h-4 w-4"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
    <select
        ref="selBox"
        v-model="valueModel"
        class="absolute top-0 opacity-0 z-10 font-medium py-0 pl-0 pr-8 truncate block border-0 bg-transparent hover:border-gray-500 focus:outline-none focus:ring focus:ring-transparent">
      <slot name="empty" />
      <slot
          v-for="option in items"
          :option="option" />
    </select>
  </div>

</template>
<script lang="ts" setup>
interface Props {
  items: any[];
  modelValue: any;
}

const props = withDefaults(defineProps<Props>(), {});

import {ref, useSlots, useAttrs, computed, toRef, onMounted, watch} from "vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:modelValue"]);

const valueModel = computed({
                              get: () => {
                                return props.modelValue;
                              },
                              set: (val) => emit("update:modelValue", val)
                            });

const items = toRef(props, "items");

const selBox = ref<HTMLSelectElement>(null);

const txt = ref(null);

const resetTxt = () => {
  txt.value = selBox.value.options[selBox.value.selectedIndex].text;
};
const modelValue = toRef(props, "modelValue");
watch(modelValue, () => resetTxt());

onMounted(() => {
  resetTxt();
});

</script>
