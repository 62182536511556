<template>
  <div>
    <timeframe-selector
        v-model:date="dateSelected"
        position="left"
        :min-date="automation.created_at"
        class="mb-4" />

    <div class="space-y-3 lg:space-y-6">
      <div class="grid lg:grid-cols-3 gap-3 lg:gap-6 grid-flow-row-dense">
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <automation-stat-box-simple
              :current="automationStats.sent"
              :loading="loadingStats"
              :past="automationStatsPast.sent"
              :show-past="showPastData">
            <template #icon>
              <svg-icon
                  name="send"
                  class="w-5 h-5" />
            </template>
            <template #title>
              SMS Sent
            </template>
          </automation-stat-box-simple>
        </div>
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <automation-stat-box-simple
              :current="automationStats.delivered"
              :loading="loadingStats"
              :past="automationStatsPast.delivered"
              :show-past="showPastData">
            <template #icon>
              <svg-icon
                  name="mobile"
                  class="w-5 h-5" />
            </template>
            <template #title>
              SMS Delivered
            </template>
          </automation-stat-box-simple>
        </div>
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <automation-stat-box-simple
              :current="deliveryRatePercentage"
              :loading="loadingStats"
              :past="pastDeliveryRatePercentage"
              :show-past="showPastData">
            <template #icon>
              <svg-icon
                  name="chartPie"
                  class="w-5 h-5" />
            </template>
            <template #title>
              Delivery Rate
            </template>
            <template #unit>
              %
            </template>
          </automation-stat-box-simple>
        </div>
      </div>

      <div class="grid gap-3 lg:gap-6 grid-flow-row-dense">
        <div class="flex flex-col rounded-lg shadow overflow-hidden">
          <div class="flex items-center grow bg-white p-3 py-5">
            <automation-show-pie-chart
                :loading="loadingStats"
                :stats="smsDeliveryRate"
                chart-label="Opened vs Unread">
              Delivery Rate
            </automation-show-pie-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import TimeframeSelector       from "@/components/layout/TimeframeSelector.vue";
import AutomationStatBoxSimple from "@/components/layout/StatBoxSimple.vue";
import AutomationShowPieChart  from "@/components/automations/Show/partials/AutomationShowPieChart.vue";
import {computed, toRef, useAttrs, useSlots}                                from "vue";
import {QLFragmentFullAutomationFragment, useAutomationStatEmailOrSmsQuery} from "@/graphql/queries/ql/composables";
import automationShowInfoDateSelection                                      from "@/components/automations/Show/Infos/automationShowInfoDateSelection";
import {useResult}                                                          from "@vue/apollo-composable";
import {PieChartData}                                                       from "@/components/layout/Charts/PieChartData";
import SvgIcon                                                              from "@/components/SvgIcon.vue";


interface Props {
  automation: QLFragmentFullAutomationFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const automation = toRef(props, "automation");

const infoDate = automationShowInfoDateSelection(automation);

const {showPastData, dateSelected} = infoDate;

const currentPeriod = useAutomationStatEmailOrSmsQuery(infoDate.queryVariablesCurrent, infoDate.queryOptionsCurrent);
const pastPeriod = useAutomationStatEmailOrSmsQuery(infoDate.queryVariablesPast, infoDate.queryOptionsPast);

const currentPeriodResult = useResult(currentPeriod.result);
const pastPeriodResult = useResult(pastPeriod.result);

const loadingStats = computed(() => {
  return currentPeriod.loading.value || pastPeriod.loading.value;
});

const automationStats = computed(() => {
  if (!currentPeriodResult.value) {
    return {
      sent:      0,
      delivered: 0,
      bounced:   0,
      opened:    0,
      clicked:   0
    };
  }
  return currentPeriodResult.value;
});

const automationStatsPast = computed(() => {
  if (!pastPeriodResult.value) {
    return {
      sent:      0,
      delivered: 0,
      bounced:   0,
      opened:    0,
      clicked:   0
    };
  }
  return pastPeriodResult.value;
});

const smsDeliveryRate = computed((): PieChartData[] => {
  if (loadingStats.value === true) {
    return [];
  }
  return [
    {name: "Delivered", cc: automationStats.value.delivered},
    {
      name: "Not delivered",
      cc:   automationStats.value.sent - automationStats.value.delivered - automationStats.value.bounced
    },
    {name: "Bounced", cc: automationStats.value.bounced}
  ];
});

const deliveryRatePercentage = computed(() => {
  if (!automationStats.value.sent || !automationStats.value.delivered) {
    return 0;
  }

  return Math.round((automationStats.value.delivered / automationStats.value.sent) * 1000) / 10;
});

const pastDeliveryRatePercentage = computed(() => {
  if (!automationStatsPast.value.sent || !automationStatsPast.value.delivered) {
    return 0;
  }

  return Math.round((automationStatsPast.value.delivered / automationStatsPast.value.sent) * 1000) / 10;
});
</script>
