<template>
  <div class="px-4 py-4 h-full">
    <div
        v-if="formData"
        class="h-full space-y-6">
      <div>
        <label
            class="form-label mb-1"
            :class="{'has-error': errors.has('name')}">
          Event name *
        </label>
        <smart-select
            v-model="formDataModel.name"
            :error-message="errors.get('name')"
            :items="eventNamesToList"
            class=""
            placeholder="Choose an event name..." />
      </div>
      <div class="flex items-center">
        <label
            class="form-label mr-4 w-2/5"
            for="day_of_week"
            :class="{'has-error': errors.has('day_of_week')}">
          Day of week
        </label>
        <div>
          <select
              v-model="formDataModel.day_of_week"
              class="form-select block w-full form-input-default"
              name="day_of_week"
              :class="{'has-error': errors.has('day_of_week')}">
            <option
                v-for="day in days"
                :value="day.dayId">
              {{ day.name }}
            </option>
          </select>

          <span
              v-if="errors.has('day_of_week')"
              class="form-error-message">{{ errors.get("day_of_week") }}</span>
        </div>
      </div>
      <div class="flex items-start">
        <div class="mr-4 mt-2 w-2/5">
          <label
              class="form-label"
              for="start_time_choice"
              :class="{'has-error': errors.has('start_time')}">
            Start time
          </label>
        </div>
        <div class="mt-2 space-y-1 w-full">
          <div>
            <div class="inline-flex items-center">
              <input
                  v-model="startTimeAnytime"
                  :value="1"
                  class="form-radio"
                  name="radio"
                  type="radio">
              <label
                  class="ml-2 form-input-default text-gray-800"
                  for="any_time">Any time</label>
            </div>
          </div>
          <div>
            <div class="flex w-full items-center">
              <input
                  v-model="startTimeAnytime"
                  :value="0"
                  class="form-radio"
                  name="radio"
                  type="radio">
              <div class="ml-2 grow">
                <div class="flex items-center">
                  <label
                      class="form-input-default text-gray-800 mr-4"
                      for="specific_time">Specific time</label>
                  <text-input
                      v-if="startTimeAnytime === 0"
                      v-model="selectedTime"
                      class="block w-full form-input-default"
                      type="time" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <text-input
            v-model="formDataModel.virtual_url"
            :error-message="errors.get('virtual_url')"
            class="form-input-default"
            label="Streaming URL *"
            placeholder="ex: https://zoom.com/myvirtualclass"
            required
            type="url" />
      </div>
      <div>
        <text-input
            v-model="formDataModel.virtual_data.virtual_id"
            class="form-input-default"
            label="Streaming ID"
            placeholder="" />
      </div>
      <div>
        <text-input
            v-model="formDataModel.virtual_data.virtual_password"
            class="form-input-default"
            label="Streaming Password"
            placeholder="" />
      </div>
      <div>
        <text-area-input
            v-model="formDataModel.virtual_data.virtual_info"
            :rows="5"
            class="form-input-default"
            label="Streaming Info"
            placeholder="" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import TextInput                                from "@/components/layout/Forms/TextInput.vue";
import TextAreaInput                            from "@/components/layout/Forms/TextAreaInput.vue";
import SmartSelect                              from "@/components/layout/Forms/SmartSelect.vue";
import {DateTime}                               from "luxon";
import {computed, useAttrs, useSlots}           from "vue";
import {QLVirtualClassInfo, useEventNamesQuery} from "@/graphql/queries/ql/composables";
import SparkFormErrors, {SparkErrors}           from "@/classes/utils/SparkFormErrors";
import {useVModel}                              from "@vueuse/core";
import {useResult}                              from "@vue/apollo-composable";

interface Props {
  sending: boolean;
  formData: QLVirtualClassInfo;
  errors: SparkErrors;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:formData", "update:errors"]);

const formDataModel = useVModel(props, "formData", emit);
const errorsData = useVModel(props, "errors", emit);

// const formDataModel = computed({
//                           get: () => props.formData,
//                           set: (val) => {
//                             console.log(val);
//                             emit("update:formData", val)}
//                         });

const days = [
  {dayId: null, name: "Any day"},
  {dayId: 0, name: "Sundays"},
  {dayId: 1, name: "Mondays"},
  {dayId: 2, name: "Tuesdays"},
  {dayId: 3, name: "Wednesdays"},
  {dayId: 4, name: "Thursdays"},
  {dayId: 5, name: "Fridays"},
  {dayId: 6, name: "Saturdays"}
];

const eventNamesQ = useEventNamesQuery({fetchPolicy: "cache-first"});
const {loading} = eventNamesQ;
const eventNames = useResult(eventNamesQ.result, [], data => data.event_names);

const eventNamesToList = computed(() => {
  return eventNames.value.map((item) => item.name);
});

const startTimeAnytime = computed({
                                    get: () => formDataModel.value.start_time !== null ? 0 : 1,
                                    set: (val) => {
                                      if (val) {
                                        formDataModel.value.start_time = null;
                                      } else {
                                        formDataModel.value.start_time = 1;
                                      }
                                    }
                                  });

const selectedTime = computed({
                                get: () => DateTime.utc().startOf("day").plus({minutes: formDataModel.value.start_time})
                                                   .toFormat("HH:mm"),
                                set: (val) => {
                                  let d = DateTime.fromISO("2020-09-14T" + val + ":00.0");
                                  let u = DateTime.fromISO("2020-09-14T00:00:01.0");
                                  formDataModel.value.start_time = Math.round(d.diff(u, "minutes").minutes);
                                }
                              });

const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

</script>
