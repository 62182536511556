import {DateTimePickerRange} from "@/components/layout/Forms/DateTimePickerRange";
import {DateTime}            from "luxon";
import {Timeframes}          from "@/classes/dates/TimeframeParser";

export interface DateTimeRange {
	start: number,
	end: number
}

export interface DateTimeRangeWithPeriod {
	range: DateTimeRange
	timeframe: Timeframes | null;
}

export class DateRangePeriodParser {
	private timeframe: DateTimePickerRange;
	private readonly _calculated: DateTimeRange | null;

	private readonly allTime: number = 1262754000;


	// all time: 1262754000 = Jan 5 2010

	constructor(timeframe: DateTimePickerRange, allTime: number = 1262754000) {
		this.timeframe = timeframe;
		this.allTime = allTime;
		this._calculated = this.calculate();
	}

	get past(): DateTimeRange | null {
		return this._calculated;
	}

	private calculate(): DateTimeRange | null {
		if (this.timeframe.shortcut !== null || this.timeframe.shortcut !== undefined) {
			if (typeof this.timeframe.shortcut === "number") {
				// it's a simple number range
				return this.differencePeriod();
			}
			if (typeof this.timeframe.shortcut === "string") {
				if (this.timeframe.shortcut === "month") {
					// it's current month, so getting the past month
					return {
						start: Math.round(DateTime.local().minus({month: 1}).startOf("month").toSeconds()),
						end:   Math.round(DateTime.local().minus({month: 1}).endOf("month").minus({second: 1})
						                          .toSeconds())
					};
				}
				if (this.timeframe.shortcut === "-month") {
					// it's past month, so getting 2 months ago
					return {
						start: Math.round(DateTime.local().minus({month: 2}).startOf("month").toSeconds()),
						end:   Math.round(DateTime.local().minus({month: 2}).endOf("month").minus({second: 1})
						                          .toSeconds())
					};
				}

				if (this.timeframe.shortcut === "isoWeek") {
					// it's current week, so getting the week before
					return {
						start: Math.round(DateTime.local().minus({week: 1}).startOf("week").toSeconds()),
						end:   Math.round(DateTime.local().minus({week: 1}).endOf("week").minus({second: 1})
						                          .toSeconds())
					};
				}
				if (this.timeframe.shortcut === "-isoWeek") {
					// it's past week, so getting 2 weeks before
					return {
						start: Math.round(DateTime.local().minus({week: 2}).startOf("week").toSeconds()),
						end:   Math.round(DateTime.local().minus({week: 2}).endOf("week").minus({second: 1})
						                          .toSeconds())
					};
				}
			}
		}
		if (this.timeframe.start < this.allTime) {
			return null;
		}

		return this.differencePeriod();
	}

	private differencePeriod(): DateTimeRange {
		const diff = Math.abs(Math.round(DateTime.fromSeconds(this.timeframe.start)
		                                         .diff(DateTime.fromSeconds(this.timeframe.end), "days").days));
		return {
			start: Math.round(DateTime.fromSeconds(this.timeframe.start)
			                          .minus({days: diff})
			                          .toSeconds()),
			end:   Math.round(DateTime.fromSeconds(this.timeframe.end)
			                          .minus({days: diff})
			                          .toSeconds())
		};
	}
}
