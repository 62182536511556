import Operator        from "./operator";
import {OperatorTypes} from "hm-filters/operators/OperatorTypes";

export default class ClientTagOperator extends Operator {
	hasInput = true;
	get type(): string {
		return OperatorTypes.ClientTag;
	}
}

export class MultipleClientTagOperator extends ClientTagOperator {
	hasInput = true;
	get type(): string {
		return OperatorTypes.MultipleClientTag;
	}

	get allowsMultiple(): boolean {
		return true;
	}
}
