<template>
  <div class="shadow rounded-lg overflow-hidden">
    <div class="bg-white p-4">
      <div class=" prose max-w-full ">
        <div v-html="note.note"></div>
      </div>
    </div>
    <div class="bg-gray-50 px-4 border-t">
      <div class="py-2 md:flex md:justify-between items-center bg-gray-50">
        <div class="flex items-center">
          <avatar :image="note.created_by.photo_url" :username="note.created_by.name" class="w-8 h-8"></avatar>
          <div class="ml-2 text-gray-700">
            <div class="font-medium">
              {{ note.created_by.name }}
            </div>
            <div class="text-xs">
              <timestamp-to-date :timestamp="note.created_at" class="inline" has-date has-time natural-date />
              <span class="text-gray-600"> (<time-ago :timestamp="note.created_at" class="inline" />)</span>
            </div>
          </div>
        </div>
        <div class="">
          <div v-if="note.archived_at > 0" class="block">
            <div class="badge badge-green text-xs">
              Archived
            </div>
          </div>
          <div v-if="!note.archived_at" class=" flex space-x-2">
            <v-button :loading="archiving"
                      class="btn border border-red-200 text-red-900 hover:border-transparent hover:bg-red-600 hover:text-white py-1 px-2 text-xs"
                      @click="archiveOpen = true">
              Archive
            </v-button>
            <v-button @click="editPanel = true"
                      class="btn border border-blue-200 text-blue-900 hover:border-transparent hover:bg-blue-600 hover:text-white py-1 px-2 text-xs">
              Edit
            </v-button>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal title="Are you sure?"
                   type="warning"
                   close-text="No"
                   v-model:open="archiveOpen"
                   :loading="archiving"
                   @clicked="archive">
      <template #text>You can archive this note which will remove the badge on the note tab, but retain the note in the
                      client's profile.
      </template>
      <template #button>Yes, archive it</template>
    </confirm-modal>
    <client-edit-note v-model:open="editPanel" :client="client" :note="note"></client-edit-note>
  </div>
</template>
<script lang="ts" setup>

import {QLClient, QLFragmentClientNoteFragment, useUpdateNoteMutation} from "@/graphql/queries/ql/composables";
import Avatar                                                          from "@/components/layout/Avatar.vue";
import TimestampToDate                                                 from "@/components/layout/TimestampToDate.vue";
import TimeAgo                                                         from "@/components/layout/TimeAgo.vue";
import VButton                                                         from "@/components/layout/VButton.vue";
import ClientEditNote                                                  from "@/components/client/Notes/ClientEditNote.vue";
import {ref, toRef}                                                    from "vue";
import ConfirmModal                                                    from "@/components/layout/ConfirmModal.vue";
import $toast                                                          from "@/composables/toast";

interface Props {
  client: QLClient;
  note: QLFragmentClientNoteFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const note = toRef(props, "note");
const client = toRef(props, "client");


const archiving = ref(false);
const archiveOpen = ref(false);
const editPanel = ref(false);

const archiveMutation = useUpdateNoteMutation({});
archiveMutation.onDone(() => {
  archiving.value = false;
  archiveOpen.value = false;
  $toast({
           type:         "success",
           message:      "Note archived",
           linkRouteObj: null,
           linkText:     null
         });
});

archiveMutation.onError(() => {
  archiving.value = false;
  archiveOpen.value = false;
  $toast({
           type:         "error",
           message:      "There was an error while archiving this note. Try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const archive = () => {
  archiving.value = true;
  archiveMutation.mutate({
                           id:       note.value.id,
                           archived: true
                         });
};

</script>
