<template>
  <span v-text="textDiff"/>
</template>
<script lang="ts">

import {DateTime}                                                             from "luxon";
import {defineComponent, nextTick, onMounted, onUnmounted, ref, toRef, watch} from "vue";

export default defineComponent(
    {
      name:  "TimeAgo",
      props: {
        timestamp: {
          type:     Number,
          required: true,
          default:  () => 0
        }
      },
      setup(props) {
        const textDiff = ref("");
        let dt = DateTime.fromSeconds(props.timestamp);
        const interval = ref(null);
        const timestamp = toRef(props, "timestamp");

        const calculateTimeDiff = () => {
          dt = DateTime.fromSeconds(timestamp.value);
          let secondsAgo = dt.diffNow("seconds");

          if (secondsAgo.seconds === 0) {
            textDiff.value = "just now";
          } else {
            textDiff.value = dt.toRelative();
          }
          let intervalTime = 60000;
          if (Math.abs(secondsAgo.seconds) < 60) {
            intervalTime = 1000;
          }


          interval.value = setTimeout(calculateTimeDiff, intervalTime);
        };

        onMounted(() => {
          calculateTimeDiff();
        });

        const turnOffTimer = () => {
          if (interval.value !== null) {
            clearTimeout(interval.value);
          }
        };

        onUnmounted(() => {
          turnOffTimer();
        });

        watch(timestamp, async () => {
          turnOffTimer();
          await nextTick();
          calculateTimeDiff();
        });

        return {
          textDiff
        };

      }
    }
);

</script>
