<template>
  <div class="px-4 py-5 sm:p-6">
    <dt class="text-base font-normal text-gray-900">
      {{ passedProps.title }}
    </dt>
    <dd
        v-if="!loading"
        class="mt-1 flex justify-between items-baseline md:block lg:flex">
      <div class="flex items-baseline text-2xl font-semibold text-indigo-600">
        {{ passedProps.currentValue }}
        <span class="ml-2 text-sm font-medium text-gray-500"> from {{ passedProps.pastValue }} </span>
      </div>

      <div :class="[!isHigher ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800', 'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0']">
        <svg-icon
            v-if="!isHigher"
            name="arrow-down"
            class="-ml-1 mr-0.5 shrink-0 self-center h-3 w-3 text-green-500"
            aria-hidden="true" />
        <svg-icon
            v-else
            name="arrow-up"
            class="-ml-1 mr-0.5 shrink-0 self-center h-3 w-3 text-red-500"
            aria-hidden="true" />
        <span class="sr-only"> {{ isHigher ? "Increased" : "Decreased" }} by </span>
        {{ percentage }}%
      </div>
    </dd>
    <dd
        v-if="loading"
        class="mt-1 flex justify-between items-baseline md:block lg:flex">
      <div class="flex items-baseline text-2xl font-semibold text-indigo-600 animate-loading">
        <span class="loading-bg text-transparent">100</span>
        <span class="ml-2 text-sm font-medium text-transparent loading-bg"> from 200 </span>
      </div>

      <div class="loading-bg text-transparent inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 animate-loading">
        > XX%
      </div>
    </dd>
  </div>
</template>
<script lang="ts" setup>
import SvgIcon                                  from "@/components/SvgIcon.vue";
import {computed, reactive, useAttrs, useSlots} from "vue";

interface Props {
  loading: boolean;
  title: string;
  currentValue: number;
  pastValue: number;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const passedProps = reactive(props);

const isHigher = computed(() => {
  return passedProps.pastValue < passedProps.currentValue;
});

const hasPercentage = computed(() => {
  return passedProps.pastValue > 0 && passedProps.currentValue > 0;
});

const percentage = computed(() => {
  let diff = passedProps.currentValue - passedProps.pastValue;
  if (diff === 0) {
    return 0;
  }
  return Math.round(diff / passedProps.pastValue * 100);
});

</script>
