<template>
  <div class="w-full inline-flex items-center collapse-rounded">
    <select
        v-model="inputs.selectedOperator"
        class="form-select">
      <option
          v-for="firstOp in possibleOperators.firstOperators"
          :value="firstOp">
        {{ firstOp }}
      </option>
    </select>

    <select
        v-if="possibleOperators.secondaryOperators.length > 0"
        v-model="inputs.secondaryOperator"
        class="form-select ">
      <option
          v-for="val in possibleOperators.secondaryOperators"
          :value="val">
        {{ val }}
      </option>
    </select>

    <input
        v-if="inputType === 'number'"
        ref="input"
        v-model="inputs.inputValue"
        autofocus
        class="form-input w-full "
        type="number">

    <date-time-picker
        v-if="inputType === 'date'"
        v-model:model-value="inputs.inputValue"
        :local-timezone="studioTimezone"
        class=" w-full " />

    <select
        v-if="possibleOperators.endingOperator.length > 0"
        v-model="inputs.thirdOperator"
        class="form-select ">
      <option
          v-for="val in possibleOperators.endingOperator"
          :value="val">
        {{ val }}
      </option>
    </select>
  </div>
</template>
<style scoped>
.collapse-rounded > * {
  @apply rounded-r-none;
  @apply rounded-l-none;
}

.collapse-rounded > *:not(:last-child) {
  @apply border-r-0;
}

.collapse-rounded > :first-child {
  @apply rounded-l
}

.collapse-rounded > :last-child {
  @apply rounded-r
}
</style>

<script lang="ts" setup>

import Filter                                               from "hm-filters/base-filter";
import {computed, onMounted, reactive, toRaw, toRef, watch} from "vue";
import currentTeamQuery                                     from "@/composables/queries/currentTeamQuery";
import {GroupedDateFilters}                                 from "hm-filters/GroupedDateFilters";
import DateTimePicker                                       from "@/components/layout/Forms/DateTimePicker.vue";

interface Props {
  filter: Filter,
  modelValue: any;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:modelValue", "is-valid"]);

const {loading, currentTeam} = currentTeamQuery();
const studioTimezone = computed(() => {
  return currentTeam.value ? currentTeam.value.timezone : null;
});


const operators = GroupedDateFilters;
const modelValue = toRef(props, "modelValue");
const inputs = reactive({
                          selectedOperator:  "is",
                          secondaryOperator: null,
                          thirdOperator:     null,
                          inputValue:        null
                        });


const hasOperatorByName = (name): boolean => {
  return props.filter.operators.find((op) => op.value === name) !== undefined;
};

const possibleOperators = reactive({
                                     firstOperators: computed(() => {
                                       let ops = [];
                                       for (let op of operators) {
                                         if (hasOperatorByName(op.operator.value)) {
                                           ops.push(op.firstGroup);
                                         }
                                       }

                                       return Array.from(new Set(ops));
                                     }),

                                     secondaryOperators: computed(() => {
                                       let ops = [];
                                       for (let op of operators) {
                                         if (op.firstGroup === inputs.selectedOperator && hasOperatorByName(op.operator.value) && op.secondGroup !== null) {
                                           ops.push(op.secondGroup);
                                         }
                                       }
                                       return Array.from(new Set(ops));
                                     }),

                                     endingOperator: computed(() => {
                                       let ops = [];
                                       for (let op of operators) {
                                         if (op.thirdGroup !== null && op.firstGroup === inputs.selectedOperator && op.secondGroup === inputs.secondaryOperator && hasOperatorByName(
                                             op.operator.value)) {
                                           ops.push(op.thirdGroup);
                                         }
                                       }
                                       return Array.from(new Set(ops));
                                     })
                                   });

const inputType = computed((): "date" | "number" | false => {
  if (["before", "not before", "after", "not after"].includes(inputs.selectedOperator)) {
    return "date";
  }

  if (["exactly", "exactly not"].includes(inputs.selectedOperator)) {
    return "number";
  }
  if (["in the last", "in the next"].includes(inputs.secondaryOperator)) {
    return "number";
  }
  return false;
});


const realignEnding = () => {
  if (possibleOperators.endingOperator.length > 0) {
    inputs.thirdOperator = possibleOperators.endingOperator[0];
  } else {
    inputs.thirdOperator = null;
  }
};

const realignSecondary = () => {
  if (possibleOperators.secondaryOperators.length > 0) {
    inputs.secondaryOperator = possibleOperators.secondaryOperators[0];
  } else {
    inputs.secondaryOperator = null;
  }
  inputs.inputValue = null;
};


const operatorFromTheSelections = computed(() => {
  return operators.find((op) => op.firstGroup === inputs.selectedOperator && op.secondGroup === inputs.secondaryOperator && op.thirdGroup === inputs.thirdOperator);
});


const selectedValue = computed({
                                 get: () => {
                                   if (modelValue.value === null) {
                                     return [];
                                   }
                                   return modelValue.value;
                                 },
                                 set: (newVal) => emit("update:modelValue",
                                                       newVal !== null ? newVal : null)
                               });

const updateValue = () => {
  let opFound = operatorFromTheSelections.value;
  if (!opFound) {
    emit("update:modelValue", null);
    return true;
  }

  let inputtedValue;
  if (inputType.value === "number" || inputType.value === "date") {
    inputtedValue = parseInt(inputs.inputValue);
  } else {
    inputtedValue = null;
  }

  let options;

  if (opFound.operator.hasInput) {
    options = opFound.operator.exportObject(toRaw(props.filter), inputtedValue);
  } else {
    options = opFound.operator.exportObject(toRaw(props.filter), opFound.operator.value);
  }

  if (JSON.stringify(options) != JSON.stringify(selectedValue.value)) {
    emit("update:modelValue", options);
    return true;
  }
  return false;
};


const calculateValid = () => {
  let opFound = operatorFromTheSelections.value;
  if (!opFound) {
    emit("is-valid", false);
    return false;
  }
  if (opFound.operator.hasInput === false) {
    emit("is-valid", true);
    return true;
  }
  if (!inputs.inputValue || inputs.inputValue == "") {
    emit("is-valid", false);
    return false;
  }
  emit("is-valid", true);
  return true;
};

const isValid = computed(() => {
  return calculateValid();
});

const updateAndValid = () => {
  updateValue();
  if (!calculateValid()) {
    emit("update:modelValue", null);
  }
};


// watch(modelValue, calculateValid);
watch(() => inputs.secondaryOperator, (old, newData) => {
  realignEnding();
  updateAndValid();
});
watch(() => inputs.selectedOperator, (old, newData) => {
  realignSecondary();
  updateAndValid();
});
watch(() => inputs.thirdOperator, (old, newData) => {
  updateAndValid();
});
watch(() => inputs.inputValue, (old, newData) => {
  updateAndValid();
});
onMounted(() => {
  // need to align the first one too
  inputs.selectedOperator = possibleOperators.firstOperators[0];
  realignSecondary();
  realignEnding();
});
</script>
