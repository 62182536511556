<template>
  <div class="shadow bg-white rounded">
    <div class="flex-col">
      <div class="p-4 border-b">
        <div class="pt-2">
          <h1 class="font-medium text-xl leading-tight mb-2">
            {{ form.name }}
          </h1>
          <p class="text-sm text-gray-500">
            Category: {{
              form.category
              ? form.category
              : "Uncategorized"
            }}
          </p>
          <p class="text-xs text-gray-500 truncate hover-all-text">
            {{ form.description }}
          </p>
        </div>
      </div>
    </div>
    <dl class="space-y-8 py-5">
      <form-info-box-line
          v-if="!form.is_archived"
          title-class="text-green-800">
        <template #icon>
          <svg-icon
              name="play"
              class="w-full h-full" />
        </template>
        <template #title>
          <span>Active</span>
        </template>
        <template #smalltext>
          Form is active. Customers can access the form using the following
          <a
              :href="'https://lo.ls/' + form.short_url"
              target="_blank"
              class="text-link">link</a>.

          <form-copy-link :url="'https://lo.ls/' + form.short_url" class="mt-2"/>
        </template>
      </form-info-box-line>
      <form-info-box-line
          v-if="form.is_archived"
          title-class="text-red-800">
        <template #icon>
          <svg-icon
              name="trash"
              class="w-full h-full" />
        </template>
        <template #title>
          Archived
        </template>
        <template #smalltext>
          <div v-if="form.archive_404">Form is archived. Customers cannot access this form. You can add a custom message
                                       in the Edit page.
          </div>
          <div v-if="!form.archive_404">Form is archived. Customers are shown a custom message. You can change it in the
                                        Edit page.
          </div>
        </template>
      </form-info-box-line>

      <!--      Allows leads  -->
      <form-info-box-line v-if="form.allow_leads">
        <template #icon>
          <svg-icon
              name="user-plus-duotone"
              class="w-full h-full" />
        </template>
        <template #title>
          <span>Allows new clients</span>
        </template>
        <template #smalltext>
          This form allows new clients, which will create "lead" accounts in LoopSpark for clients that don't exist in
          your booking software.
        </template>
      </form-info-box-line>
      <form-info-box-line v-if="!form.allow_leads">
        <template #icon>
          <svg-icon
              name="users-duotone"
              class="w-full h-full" />
        </template>
        <template #title>
          <span>Only existing clients</span>
        </template>
        <template #smalltext>
          This form can only be completed by clients that already exist in your booking. Clients will enter their first
          name, last name, and email address to identify their existing client profile.
        </template>
      </form-info-box-line>

      <!--      Legal  -->
      <form-info-box-line v-if="form.is_legal">
        <template #icon>
          <svg-icon
              name="pdf-duotone"
              class="w-full h-full" />
        </template>
        <template #title>
          <span>Creates a PDF copy</span>
        </template>
        <template #smalltext>
          This form will email a completed PDF to the customer, and save a PDF copy in the client's LoopSpark profile.
        </template>
      </form-info-box-line>
      <form-info-box-line v-if="!form.is_legal">
        <template #icon>
          <svg-icon
              name="pdf-duotone"
              class="w-full h-full opacity-25" />
        </template>
        <template #title>
          <span>No saved PDF</span>
        </template>
        <template #smalltext>
          This form does not generate a PDF and will not email a completed copy to the client. Form responses will be
          visible in the client's LoopSpark profile.
        </template>
      </form-info-box-line>

      <!--      Legal  -->
      <form-info-box-line v-if="form.is_legal && form.email_cc">
        <template #icon>
          <svg-icon
              name="email"
              class="w-full h-full" />
        </template>
        <template #title>
          <span>Sends a PDF copy</span>
        </template>
        <template #smalltext>
          Upon completion of the form, the email <strong class="font-semibold">{{ form.email_cc }}</strong> will receive
          a copy of the PDF.
        </template>
      </form-info-box-line>

      <form-info-box-line v-if="form.is_legal && form.send_to_mbo">
        <template #icon>
          <svg-icon
              class="w-full h-full"
              name="cloud-upload" />
        </template>
        <template #title>
          <span>Uploads PDF to MINDBODY</span>
        </template>
        <template #smalltext>
          Upon completion of the form, a copy of the PDF will be uploaded to the client's Documents section on MINDBODY.
        </template>
      </form-info-box-line>
    </dl>
  </div>
</template>
<style scoped>
dl.alternate-bg > div:nth-child(odd) {
  @apply bg-gray-50;
}

dl.alternate-bg > div:nth-child(even) {
  @apply bg-white;
}
</style>

<script lang="ts" setup>
import {toRef, useAttrs, useSlots}  from "vue";
import {QLFragmentFormFullFragment} from "@/graphql/queries/ql/composables";
import SvgIcon                      from "@/components/SvgIcon.vue";
import FormInfoBoxLine              from "@/views/Forms/Components/Show/FormInfoBoxLine.vue";
import FormCopyLink                 from "@/views/Forms/Components/Show/FormCopyLink.vue";

interface Props {
  form: QLFragmentFormFullFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const form = toRef(props, "form");

</script>
