<template>
  <input-field-base
      v-if="fieldModel"
      v-model:field="fieldModel"
      v-model:open="openModel"
      :can-save="canSave"
      :name-already-used="nameAlreadyUsed"
      :title-optional="true"
      @cancelled="openModel = false"
      @saved="saved">
    <template #fields>
      <div class="">
        <div class="text-sm font-medium text-gray-700">
          Content
        </div>
        <div
            class="mt-1 text-sm text-gray-900 h-[64rem] min-h-[64rem]">
          <rich-editor
v-model="fieldModel.content"
class="h-full" />
        </div>
      </div>
    </template>
  </input-field-base>
</template>
<script lang="ts" setup>
import {computed,  withDefaults} from "vue";
import InputFieldBase                                     from "@/components/Forms/Fields/Input/InputFieldBase.vue";
import InputFieldsFunctions                               from "@/components/Forms/Fields/Input/InputFieldsFunctions";
import {QLFormField}                                      from "@/graphql/queries/ql/composables";
import RichEditor                                         from "@/components/layout/RichEditor.vue";

interface InputFieldProps {
  field: QLFormField;
  open: boolean;
  otherFields: QLFormField[];
  useMappings: boolean;
}

const props = withDefaults(defineProps<InputFieldProps>(), {});
const emit = defineEmits(["deleted", "update:field", "saved", "update:open"]);

const {
  fieldModel,
  openModel,
  otherFields,
  saved,
  toRun,
  nameAlreadyUsed
} = InputFieldsFunctions.bootstrapEditField(emit,
                                            props);
toRun();

const canSave = computed(() => {
  if (!props.field) {
    return false;
  }
  if (InputFieldsFunctions.nameIsAlreadyUsed(otherFields.value, fieldModel.value)) {
    return false;
  }
  return fieldModel.value.title.trim().length > 0 && fieldModel.value.content && fieldModel.value.content.trim().length > 0;
});

</script>
