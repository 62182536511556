import Package                        from "./partials/package";
import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import GraphQlOperator                from "hm-filters/operators/GraphQlOperator";
import MultipleTextOperator           from "hm-filters/operators/MultipleTextOperator";
import QLQueriesData                          from "hm-filters/operators/GraphQls/QLQueriesData";
import {OperatorTypes, OperatorTypesFullName} from "hm-filters/operators/OperatorTypes";
import OperatorGenerator                      from "hm-filters/operatorGenerator";

class PackageName extends Package {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new PackageNameQlOperator("=", "at least once")
				.setIsMultipleChoice()
				.allowsCustomInput(),
			new PackageNameQlOperator("only", "only")
				.setIsMultipleChoice()
				.allowsCustomInput()
				.setAloneInFilterGroup(),
			new PackageNameQlOperator("!=", "never").setIsMultipleChoice(),
			...OperatorGenerator.textContainsMultiple(),
			new OperatorAsFixedValueOperator("any", "any package"),
			new OperatorAsFixedValueOperator("never", "no package")
		];
	}

	get type(): string {
		return "PackageNameFilter";
	}
}

export default PackageName;

export class PackageNameQlOperator extends GraphQlOperator {
	graphQLData = QLQueriesData.PackageNameQlOperator;

	get type(): string {
		return OperatorTypes.GraphQlMultiple;
	}
	get fullType(): string{
		return OperatorTypesFullName.PackageNameQlOperator;
	}
}
