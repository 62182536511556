<template>

  <SwitchGroup>
    <div class="flex items-center">
      <Switch
          v-model="enabled"
          :class="enabled ? 'bg-green-500' : 'bg-gray-300'"
          class="relative inline-flex shrink-0 h-6 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
      >
            <span class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200"
                  :class="enabled ? 'translate-x-4' : 'translate-x-0'"></span>
      </Switch>
      <SwitchLabel class="ml-1">
        <slot></slot>
      </SwitchLabel>
    </div>
  </SwitchGroup>

</template>
<script lang="ts">

import {computed, onMounted, ref}         from "vue";
import {Switch, SwitchGroup, SwitchLabel} from "@headlessui/vue";


export default {
  components: {
    Switch, SwitchGroup, SwitchLabel
  },
  props:      {
    modelValue: {
      type:     Boolean,
      required: true
    },
    label:      {
      type:     String,
      required: false,
      default:  () => null
    },
    disabled:   {
      type:     Boolean,
      required: false,
      default:  () => false
    }
  },
  emits:      ["update:modelValue"],
  setup(props, {emit}) {
    const id = ref("");

    onMounted(() => {
      id.value = Math.random().toString();
    });

    const enabled = computed({
                               get: () => {
                                 return props.modelValue;
                               },
                               set: (val) => {
                                 emit("update:modelValue", val);
                               }
                             });

    return {
      id,
      enabled
    };
  }

};
</script>
