<template>
  <side-panel
      v-model:open="openModel"
      :closable="!sending">
    <template #header>
      Edit Virtual Event
    </template>
    <form @submit.prevent="send" ref="form" class="h-full">
      <virtual-event-form
          v-model:form-data="formData"
          :errors="errorsData"
          :sending="sending" />
    </form>
    <template #footer>
      <div
          v-if="!sending"
          class="flex m-2 justify-between w-full">
        <button
            class="btn btn-white"
            @click="openModel = false">
          Cancel
        </button>
        <button
            class="btn btn-green"
            @click="send">
          Edit!
        </button>
      </div>
      <div
          v-if="sending"
          class="flex m-2 justify-center">
        <div class="h-8 w-8">
          <loader />
        </div>
      </div>
    </template>
  </side-panel>
</template>
<script lang="ts" setup>

import SidePanel                                                                                          from "@/components/layout/SidePanel.vue";
import SparkFormErrors                                                                                    from "@/classes/utils/SparkFormErrors";
import Loader                                                                                             from "@/components/layout/Loader.vue";
import VirtualEventForm                                                                                   from "@/components/Events/VirtualEvents/VirtualEventForm.vue";
import {computed, onMounted, ref, toRef, useAttrs, useSlots, watch}                                       from "vue";
import {QLUpdateVirtualClassInfoMutationVariables, QLVirtualClassInfo, useUpdateVirtualClassInfoMutation} from "@/graphql/queries/ql/composables";
import {useVModel}                                                                                        from "@vueuse/core";
import $toast                                                                                             from "@/composables/toast";
import fillValidationErrorsOrToast                                                                        from "@/composables/queries/mutations/fillValidationErrorsOrToast";

interface Props {
  open: boolean;
  virtualEvent: QLVirtualClassInfo;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open", "updated"]);

const openModel = useVModel(props, "open", emit);

const errorsData = ref({});
const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

const formData = ref<QLVirtualClassInfo>({
                                           day_of_week:  0,
                                           id:           "",
                                           name:         "",
                                           start_time:   0,
                                           virtual_data: {},
                                           virtual_url:  ""
                                         });
const form = ref(null);
const sending = ref(false);

const passedVirtualClass = toRef(props, "virtualEvent");

const importData = () => {
  if(!sending.value) {
    let {id, virtual_url, name, day_of_week, start_time} = passedVirtualClass.value;
    formData.value = {
      id, virtual_url, name, day_of_week, start_time, virtual_data: {
        virtual_info:     null,
        virtual_password: null,
        virtual_id:       null,
        ...passedVirtualClass.value.virtual_data
      }
    };
  }
};

onMounted(() => importData());
watch(passedVirtualClass, () => importData());
watch(openModel, () => importData());

const updateVirtualMutation = useUpdateVirtualClassInfoMutation({});

updateVirtualMutation.onDone(() => {
  $toast({
           type:         "success",
           message:      "Virtual event updated",
           linkRouteObj: null,
           linkText:     null
         });
  sending.value = false;
  openModel.value = false;
  emit("updated");
});

updateVirtualMutation.onError((err) => {
  sending.value = false;
  fillValidationErrorsOrToast(err, errorsData, "Error while editing the virtual event. Please try again!");
});

const send = () => {
  errorsData.value = {};
  if (!form.value.reportValidity()) {
    return;
  }
  sending.value = true;

  let {__typename, ...virtData} = formData.value.virtual_data;

  let data: QLUpdateVirtualClassInfoMutationVariables = {
    id:           formData.value.id.toString(),
    virtual_url:  formData.value.virtual_url,
    name:         formData.value.name,
    day_of_week:  formData.value.day_of_week,
    start_time:   formData.value.start_time,
    virtual_data: {
      ...virtData
    }
  };

  updateVirtualMutation.mutate(data);
};

</script>
