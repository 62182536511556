import {computed, reactive, ref}                  from "vue";
import {UseQueryReturn}                           from "@vue/apollo-composable/dist/useQuery";
import {QLPaginatorFragmentFragment, QLSortOrder} from "@/graphql/queries/ql/composables";

export interface paginationOptions {
	page?: number,
	perPage?: number,
	perPages?: number[],
	orderByField?: string,
	orderSort?: QLSortOrder
}

export function createPaginationData(paginationOptions: paginationOptions) {
	const pagination = {
		page:         1,
		perPage:      10,
		perPages:     [10, 25, 50],
		orderByField: "created_at",
		orderSort:    QLSortOrder.DESC,
		...paginationOptions
	};

	const paginationData = reactive(pagination);
	return {
		paginationData
	};
}

export function paginationWithAlreadyLoaded<T>(query: UseQueryReturn<any, any>) {

	// @ts-ignore
	const results = ref<T>([]);
	const loadedFirstTime = ref(false);


	const paginatorInfo = ref<QLPaginatorFragmentFragment>({
		                                                       firstItem:    0,
		                                                       lastItem:     0,
		                                                       count:        0,
		                                                       currentPage:  0,
		                                                       hasMorePages: false,
		                                                       lastPage:     0,
		                                                       perPage:      0,
		                                                       total:        0
	                                                       });

	query.onResult((result) => {
		if (result.loading === false) {
			const queryName = Object.keys(result.data)[0];
			paginatorInfo.value = result.data[queryName].paginatorInfo;
			results.value = result.data[queryName].data;
			loadedFirstTime.value = true;
		}
	});

	const loading = computed(() => {
		return query.loading.value === true;
	});

	return {
		results,
		loadedFirstTime,
		paginatorInfo,
		loading
	};
}
