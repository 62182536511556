import Filter            from "../base-filter";
import OperatorGenerator from "../operatorGenerator";

class ProductHistoryFilter extends Filter {
	smallText = "Product Purchase";
	group = "Product";
	path = ["Product"];

	constructor(name: string, options: any) {
		super(name, options);

		this.setOperators(OperatorGenerator.datesPastOnly(true, true));
	}

	get type(): string {
		return "ProductHistoryFilter";
	}

}

export default ProductHistoryFilter;
