<template>
  <page-title title="Calendar" section="Events" />
  <div class="container mx-auto grow space-y-8">
    <div class="flex justify-end">
      <div
          v-if="currentTeam && currentTeam.locations.length > 1">
        <div class="max-w-xs">
          <label
              class="form-label text-xs mb-1"
              for="locationselect">Location</label>
          <select
              id="locationselect"
              v-model="selectedLocation"
              class="form-select text-xs w-full block"
              name="location">
            <option
                v-for="loc in currentTeam.locations"
                :key="loc.id"
                :value="loc">
              {{ loc.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-6 grid-flow-row-dense">
      <events-visiting-today :location="selectedLocation" />
      <events-next-event
          :location="selectedLocation"
          class="lg:col-span-3" />
    </div>

    <div
        v-if="selectedLocation"
        class="mt-10">
      <events-weekly-calendar :location="selectedLocation" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import PageTitle           from "@/components/navbar/PageTitle.vue";
import EventsWeeklyCalendar
                           from "@/components/Events/Calendar/EventsWeeklyCalendar.vue";
import EventsVisitingToday from "@/components/Events/EventsVisitingToday.vue";
import EventsNextEvent     from "@/components/Events/EventsNextEvent.vue";
import currentTeamQuery    from "@/composables/queries/currentTeamQuery";
import {ref, watch}        from "vue";

const {currentTeam} = currentTeamQuery();

const selectedLocation = ref(null);

if (currentTeam.value) {
  selectedLocation.value = currentTeam.value.locations[0];
}

watch(currentTeam, () => {
  selectedLocation.value = currentTeam.value.locations[0];
});

</script>
