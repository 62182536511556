<template>
  <div class="grow">
    <div class="max-w-2xl flex items-center justify-between mb-4 ">
      <div class="">
        Attendance
      </div>
      <button
          v-if="singleEvent.visits.length > 0 && !contactOpen && showContactButton"
          class="btn-transition rounded hover:bg-blue-700 hover:text-white px-2 py-2 leading-none font-normal text-sm text-blue-800"
          @click="contactOpen = !contactOpen">
            <span class="inline-flex items-center">
                <svg-icon
                    v-if="!contactOpen"
                    name="chats-circle-duotone"
                    class="w-3 h-3" />
              <svg-icon
                  v-if="contactOpen"
                  name="close"
                  class="w-3 h-3" />
              <span
                  class="pl-1"
                  v-text="contactOpen ? 'Close': 'Contact...'" />
            </span>
      </button>
    </div>

    <div class="flex grow flex-col-reverse space-y-8 space-y-reverse lg:space-y-0 lg:space-x-5 lg:flex-row">
      <div class="grow lg:max-w-2xl">
        <div
            v-if="singleEvent.visits.length === 0"
            class="bg-gray-200 border border-gray-300 rounded-lg p-4 py-8 text-center text-gray-600">
          No one signed up for this event yet.
        </div>
        <div
            v-if="singleEvent.visits.length > 0">
          <div class="grow">
            <div class="bg-white shadow-lg rounded-lg divide-y divide-gray-200 grow overflow-hidden">
              <event-attendance-in-list
                  v-for="visit in singleEvent.visits"
                  :key="visit.id"
                  :muted="contactOpen"
                  :single-event="singleEvent"
                  :visit="visit">
                <event-attendance-send-item
                    v-if="contactOpen"
                    :attendance-status="attendanceStatus"
                    :sending-method="sendingMethod"
                    :single-event="singleEvent"
                    :message="message"
                    :subject="subject"
                    :send-now="sendTrigger"
                    :visit="visit"
                    @sent="oneCompleted" />
              </event-attendance-in-list>
            </div>
          </div>
        </div>
      </div>
      <transition
          enter-active-class="transition-all ease-in-out duration-200 overflow-hidden"
          enter-from-class="translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transition-all  origin-right ease-in-out duration-200 overflow-hidden"
          leave-class="translate-x-0"
          leave-to-class="translate-x-full"
          @before-enter="showContactButton = false"
          @after-leave="showContactButton = true">
        <div
            v-show="contactOpen"
            class="lg:mt-0  w-full lg:max-w-sm">
          <div class="block bg-white rounded-lg shadow-lg">
            <div class="pb-4">
              <div class=" px-4 py-5 border-b border-gray-200">
                <div class="-ml-4 -mt-4 flex justify-between items-start flex-wrap sm:flex-nowrap">
                  <div class="ml-4 mt-4">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      Contact
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">
                      Here you can prepare a message to send to the clients attending this class
                    </p>
                  </div>
                  <div class="ml-4 mt-4 shrink-0">
                    <button
                        class="text-gray-400 hover:text-blue-800"
                        @click="contactOpen= false">
                      <svg-icon
                          name="close"
                          class="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>

              <form
                  :disabled="sending"
                  class="pt-4 space-y-4 px-4"
                  @submit.prevent="sendNow">
                <div>
                  <label
                      class="form-label mb-1"
                      for="sendingMethod">Medium</label>
                  <select
                      v-model="sendingMethod"
                      :disabled="sending"
                      class="form-select form-input-default"
                      name="sendingMethod">
                    <option value="all">
                      Any method (SMS, or email if not possible)
                    </option>
                    <option value="prefer_email">
                      Email if possible, SMS fallback
                    </option>
                    <option value="sms">
                      Only SMS
                    </option>
                    <option value="email">
                      Only Email
                    </option>
                  </select>
                </div>
                <div>
                  <label
                      class="form-label mb-1"
                      for="filterClients">Filter</label>
                  <select
                      v-model="attendanceStatus"
                      :disabled="sending"
                      class="form-select form-input-default"
                      name="filterClients">
                    <option value="all">
                      All clients
                    </option>
                    <option value="attending">
                      Only attending/attended clients
                    </option>
                    <option value="cancelled">
                      Only cancelled clients
                    </option>
                  </select>
                </div>
                <div v-if="sendingMethod != 'sms'">
                  <text-input
                      v-model="subject"
                      :disabled="sending"
                      label="Subject"
                      placeholder="Subject of the Email"
                      required />
                </div>
                <div>
                  <div class="flex mb-1 justify-between ">
                    <label class="form-label">Message
                      <span class="text-xs opacity-50 is-required">(required)</span></label>
                    <div>
                      <merge-tags-dropdown @tag="addMergeTag" />
                    </div>
                  </div>
                  <text-area-input
                      v-model="message"
                      :disabled="sending"
                      :max-length="smsData.maxLength"
                      :rows="5"
                      placeholder="Content of your message"
                      replace-utf
                      required >
                    <template #internal>
                      <s-m-s-units-counter
                          :msg="message"
                          :units="3"
                          class="pl-2 text-gray-500 text-sm pb-2" />
                    </template>
                  </text-area-input>
                </div>
                <div>
                  <button
                      :disabled="sending"
                      class="btn btn-loopspark"
                      type="submit">
                    Send to {{ countPeopleToSend }}
                    clients
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script lang="ts" setup>
import EventAttendanceInList                                       from "@/components/Events/Show/EventAttendanceInList.vue";
import TextInput                                                   from "@/components/layout/Forms/TextInput.vue";
import TextAreaInput                                               from "@/components/layout/Forms/TextAreaInput.vue";
import EventAttendanceSendItem                                     from "@/components/Events/Show/EventAttendanceSendItem.vue";
import MergeTagsDropdown                                           from "@/components/Events/Show/MergeTagsDropdown.vue";
import {computed, nextTick, ref, toRef, useAttrs, useSlots, watch} from "vue";
import {QLSingleClassFragmentFragment}                             from "@/graphql/queries/ql/composables";
import SvgIcon                                                     from "@/components/SvgIcon.vue";
import {VisitDecorator}                                            from "@/composables/decorators/VisitDecorator";
import Message                                                     from "@/classes/entities/Message";
import SMSUnitsCounter                                             from "@/components/layout/SMSUnitsCounter.vue";
import {smsLengthInit}                                             from "@/composables/smsLength";


interface Props {
  singleEvent: QLSingleClassFragmentFragment;
}

const props = withDefaults(defineProps<Props>(), {});


const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const singleEvent = toRef(props, "singleEvent");

const contactOpen = ref(false);
const showContactButton = ref(true);
const sendingMethod = ref<"all" | "sms" | "email" | "prefer_email">("all");
const attendanceStatus = ref<"all" | "attending" | "cancelled">("all");
const subject = ref("");
const message = ref("");
const sending = ref(false);
const sendTrigger = ref(false);
const totalSent = ref(0);

const smsData = smsLengthInit(message);

const countPeopleToSend = computed(() => {
  return singleEvent.value.visits.filter((vis) => {
    let decorator = VisitDecorator(vis, singleEvent.value);
    if (attendanceStatus.value === "all") {
      return true;
    }
    if (attendanceStatus.value === "attending") {
      return !decorator.computed.isLateCancelled && !decorator.computed.isEarlyCancelled && !(decorator.computed.isPast && decorator.computed.isAbsent);
    }
    if (attendanceStatus.value === "cancelled") {
      return decorator.computed.isLateCancelled || decorator.computed.isEarlyCancelled || decorator.computed.isAbsent;
    }
    return false;

  }).filter((vis) => {
    if (sendingMethod.value === "sms" && !vis.client.can_contact_by_sms) {
      return false;
    }
    if (sendingMethod.value === "email" && !vis.client.can_contact_by_email) {
      return false;
    }
    return vis.client.can_contact_by_email || vis.client.can_contact_by_sms;
  }).length;
});
//
// watch(message, function(newData) {
//   message.value = Message.replaceInconsequentCharacters(message.value);
// });

const addMergeTag = (tag: string) => {
  message.value = message.value + tag;
};

const oneCompleted = () => {
  totalSent.value += 1;
  if (totalSent.value >= countPeopleToSend.value) {
    sending.value = false;
  }
};

const sendNow = () => {
  if (sending.value) {
    return false;
  }
  sending.value = true;
  totalSent.value = 0;

  sendTrigger.value = true;
  nextTick(() => sendTrigger.value = false);
};

</script>
