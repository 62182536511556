import GraphQlOperator from "hm-filters/operators/GraphQlOperator";
import QLQueriesData                          from "hm-filters/operators/GraphQls/QLQueriesData";
import {OperatorTypes, OperatorTypesFullName} from "hm-filters/operators/OperatorTypes";

export default class StaffNameQlOperator extends GraphQlOperator {
	graphQLData = QLQueriesData.StaffNameQlOperator;

	get type(): string {
		return OperatorTypes.GraphQlMultiple;
	}

	get fullType(): string{
		return OperatorTypesFullName.StaffNameQlOperator;
	}
}
