import ClientGroup        from "hm-filters/filters/partials/client";
import FixedValueOperator from "hm-filters/operators/FixedValueOperator";

export class ClientOptOutFilter extends ClientGroup {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new FixedValueOperator(false, "Subscribed"),
			new FixedValueOperator(true, "Unsubscribed")
		];
	}


	get type(): string {
		return "ClientOptOutFilter";
	}
}
