import {BookingGroup}     from "hm-filters/filters/partials/BookingGroup";
import FixedValueOperator from "hm-filters/operators/FixedValueOperator";

export class BookingStatusFilter extends BookingGroup {

	constructor(name: string, options: any) {
		super(name, options);

		this.clearOperators();
		this.addOperators([
			                  new FixedValueOperator("attended", "attended"),
			                  new FixedValueOperator("upcoming", "upcoming"),
			                  new FixedValueOperator("absent", "absent"),
			                  new FixedValueOperator("late_cancelled", "late cancelled"),
			                  new FixedValueOperator("early_cancelled", "early cancelled")
		                  ]);
	}

	get type(): string {
		return "BookingStatus";
	}

}
