<template>
  <div>
    <div @click="openModel = true">
      <slot />
    </div>
    <confirm-modal
        v-model:open="openModel"
        title="Are you sure?"
        type="warning"
        close-text="No, go back!"
        :loading="sending"
        @close="openModel = false"
        @clicked="deleteSegment">
      <template #text>
        <p>Are you sure you want to delete the segment</p>
        <p class="font-medium">
          {{ segment.name }} <span class="font-normal">?</span>
        </p>

        <p class="italic text-xs mt-2 text-red-700">
          This action is not reversible
        </p>
      </template>
      <template #button>
        Yes, delete it
      </template>
    </confirm-modal>
  </div>
</template>
<script lang="ts" setup>
import {ref, toRef}                          from "vue";
import ConfirmModal                          from "@/components/layout/ConfirmModal.vue";
import {QLSegment, useDeleteSegmentMutation} from "@/graphql/queries/ql/composables";
import $toast                                from "@/composables/toast";

interface Props {
  segment: QLSegment;
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(["update:open", "deleted"]);

const segment = toRef(props, "segment");

const openModel = ref(false);

const sending = ref(false);

const mutation = useDeleteSegmentMutation({});

mutation.onDone(() => {
  sending.value = false;
  $toast({
           type:         "success",
           message:      "Segment deleted successfully",
           linkRouteObj: null,
           linkText:     null
         });
  openModel.value = false;
  emit("deleted");
});

mutation.onError(() => {
  sending.value = false;
  $toast({
           type:         "error",
           message:      "There was a problem with your request. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
  openModel.value = false;
});

const deleteSegment = () => {
  sending.value = true;
  mutation.mutate({
                    id: segment.value.id
                  });
};

</script>
