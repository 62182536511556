<template>
  <div class="px-6 py-4 flex items-center justify-between">
    <div class="">
      <strong class="font-semibold block">{{ team.name }}</strong>
      <span class="text-gray-500 text-sm">Owner: {{ team.owner.name }}</span>
    </div>
    <div v-if="canLeave">
      <v-button
          class="btn btn-red"
          @click="newmodal = true">
        Leave
      </v-button>

      <confirm-modal
          v-model:open="newmodal"
          type="warning"
          :loading="deleting"
          close-text="Nevermind"
          title="Are you sure?"
          @clicked="removeStudio">
        <template #text>
          <div class="my-4">
            <p>
              Remove yourself from a {{ team.name }} if you no longer wish help provide their customers with top-notch
              customer service.
            </p>
            <p>To re-add yourself to the team, ask the business owner to re-invite you.</p>
          </div>
        </template>
        <template #button>
          Yes, leave {{ team.name }}
        </template>
      </confirm-modal>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {computed, ref, toRef}                                                            from "vue";
import {QLFragmentTeamWithOwnerFragment, QLFragmentUserMeFragment, useLeaveTeamMutation} from "@/graphql/queries/ql/composables";
import VButton                                                                           from "@/components/layout/VButton.vue";
import $toast                                                                            from "@/composables/toast";
import ConfirmModal                                                                      from "@/components/layout/ConfirmModal.vue";

import {QLFragmentCurrentTeamFragment} from "@/graphql/queries/ql/composables";

interface Props {
  me: QLFragmentUserMeFragment;
  currentTeam: QLFragmentCurrentTeamFragment;
  team: QLFragmentTeamWithOwnerFragment;
}

const props = withDefaults(defineProps<Props>(), {});
const team = toRef(props, "team");
const currentTeam = toRef(props, "currentTeam");
const me = toRef(props, "me");

const deleting = ref(false);
const newmodal = ref(false);

const canLeave = computed(() => {
  return props.me.id != props.team.owner_id && props.me.current_team_id != props.team.id;
});

const {mutate: leaveTeamMutation, onDone, onError} = useLeaveTeamMutation({});

onDone(() => {
  $toast({
           type:         "success",
           message:      "You left the team " + props.team.name,
           linkRouteObj: null,
           linkText:     null
         });
});
onError((e) => {
  $toast({
           type:         "error",
           message:      "There was an error while leaving the team. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});
const removeStudio = () => {
  deleting.value = true;
  leaveTeamMutation({id: props.team.id}).finally(() => {
    deleting.value = false;
    newmodal.value = false;
  });

};

</script>
