/**
 * Deep copy function for TypeScript.
 * @param T Generic type of target/copied value.
 * @param target Target value to be copied.
 * @see Source project, ts-deepcopy https://github.com/ykdr2017/ts-deepcopy
 * @see Code pen https://codepen.io/erikvullings/pen/ejyBYg
 */
export const deepCopy = <T>(target: T): T => {
	if (target === null) {
		return target;
	}
	if (target instanceof Date) {
		return <any>new Date(target.getTime());
	}
	if (target instanceof Array) {
		const cp = <any[]>[];
		(<any[]>target).forEach((v) => {
			cp.push(v);
		});
		return <any>cp.map((n: any) => deepCopy<any>(n));
	}
	// @ts-ignore
	if ((typeof(target) === 'object') && (target != {})) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const cp = {...(target as { [key: string]: any })} as { [key: string]: any };
		Object.keys(cp).forEach(k => {
			cp[k] = deepCopy<any>(cp[k]);
		});
		return <T>cp;
	}
	return target;
};
