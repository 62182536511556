import {QLPlaceholderFragmentFragment, usePlaceholdersQuery} from "@/graphql/queries/ql/composables";
import {useResult}     from "@vue/apollo-composable";
import {computed, Ref} from "vue";
import {FetchPolicy}   from "@apollo/client/core";

export default function placeholdersQuery(fetchPolicy: FetchPolicy = "cache-first") {
	const {result, loading, onResult} = usePlaceholdersQuery({fetchPolicy});
	const placeholders : Readonly<Ref<QLPlaceholderFragmentFragment[]>> = computed(() => result.value?.placeholders ?? []);
	return {
		placeholders,
		loading,
		onResult
	};
}
