<template>
  <modal
      v-model:open="openModel"
      v-focus
      has-close-button
      quick-close
      tabindex="0"
      wide
      @close="openModel = false">
    <template #default>
      <div class="block w-full">
        <div>
          <h1 class="text-lg">
            Form completion instances
          </h1>
          <p class="text-sm text-gray-500 whitespace-normal">
            Below are the most recent completions of form <strong>{{ formFragment.name }}</strong> by
            {{ client.first_name }}.
          </p>
        </div>

        <div class="mt-8 pt-4 mb-6">
          <div
              v-if="!loadedFirstTime"
              class="w-full h-56 flex items-center justify-center text-gray-500 animate-loading">
            <loader class="w-8 h-8" />
          </div>

          <div
              v-if="loadedFirstTime"
              class="px-1">
            <div class="shadow overflow-hidden sm:rounded-md">
              <ul
                  role="list"
                  class="divide-y divide-gray-200">
                <li
                    v-for="formInstance in forms"
                    :key="formInstance.id">
                  <router-link
                      :to="{ name: routeList.client.forms.show, params: {id: client.id, formInstanceId: formInstance.id}}"
                      class="block hover:bg-gray-50">
                    <div class="px-4 py-4 sm:px-6">
                      <div class="flex items-center justify-between">
                        <p class="text-sm font-medium text-indigo-600 truncate">
                          <timestamp-to-date
                              :timestamp="formInstance.created_at"
                              has-time />
                        </p>
                        <div class="ml-2 flex-shrink-0 flex items-center space-x-2">
                          <div class="text-sm text-gray-500">
                            <time-ago :timestamp="formInstance.created_at" />
                          </div>
                          <div class="text-link">
                            <svg-icon
                                name="right"
                                class=" h-6 w-6" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </li>
                <li
                    v-if="forms && paginatorInfo.total > 0 && paginatorInfo.lastPage > 1"
                    class="bg-gray-50 px-4 sm:px-6 py-2">
                  <pagination
                      v-if="paginatorInfo.lastPage > 1"
                      :pagination="paginatorInfo"
                      class="flex items-center justify-between text-gray-700 text-sm"
                      @set-page="paginationData.page = $event">
                    <div
                        v-if="loading"
                        class="inline-block w-8 h-8 mr-2 text-gray-400">
                      <loader />
                    </div>
                  </pagination>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>
<script lang="ts" setup>
import {
  QLClient, QLFormInstancesClientDetailQuery, QLFormInstancesClientDetailQueryVariables,
  QLFragmentFormWithCountFragment, QLSortOrder, useFormInstancesClientDetailQuery
}                                                          from "@/graphql/queries/ql/composables";
import Modal                                               from "@/components/layout/Modal.vue";
import {computed, toRef, useAttrs, useSlots}               from "vue";
import {createPaginationData, paginationWithAlreadyLoaded} from "@/composables/paginationWithAlreadyLoaded";
import Loader                                              from "@/components/layout/Loader.vue";
import TimestampToDate                                     from "@/components/layout/TimestampToDate.vue";
import TimeAgo                                             from "@/components/layout/TimeAgo.vue";
import SvgIcon                                             from "@/components/SvgIcon.vue";
import Pagination                                          from "@/components/layout/Pagination.vue";
import {useRouter}                                         from "vue-router";
import routesList                                          from "@/composables/routesList";

interface Props {
  formFragment: QLFragmentFormWithCountFragment;
  client: QLClient;
  open: boolean;
}

const props = withDefaults(defineProps<Props>(), {});
const router = useRouter();
const {routeList} = routesList();

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open"]);
const formFragment = toRef(props, "formFragment");
const client = toRef(props, "client");

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 10
                                              });

const formInstancesVariables = computed<QLFormInstancesClientDetailQueryVariables>(() => {
  return {
    client_id: client.value.id,
    form_id:   formFragment.value.id,
    page:      paginationData.page,
    first:     paginationData.perPage,
    orderBy:   [
      {
        column: "created_at",
        order:  QLSortOrder.DESC
      }
    ]
  };
});

const formsQ = useFormInstancesClientDetailQuery(formInstancesVariables, () => ({enabled: openModel.value}));
const {
  paginatorInfo,
  loadedFirstTime,
  results: forms,
  loading
} = paginationWithAlreadyLoaded<QLFormInstancesClientDetailQuery["form_instances_client_detail"]["data"]>(formsQ);
</script>
