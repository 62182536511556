<template>
  <div>
    <div class="mb-4 flex text-sm justify-between">
      <div>
        <slot />
      </div>
      <div class="text-gray-700 mr-2">
        Status:
        <inline-muted-select
            v-model="status"
            :options="statusOptions" />
      </div>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
              <tr>
                <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    scope="col">
                  Client
                </th>
                <th
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    scope="col">
                  Status
                </th>
                <th
                    class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                    scope="col">
                  Date
                </th>
              </tr>
              </thead>
              <tbody
                  v-if="loadedFirstTime && paginatorInfo.total > 0"
                  class="bg-white divide-y divide-gray-200">
              <tr
                  v-for="discussion in automationDiscussions"
                  :key="discussion.id"
                  class="cursor-pointer group hover:bg-blue-50">
                <td class="px-6 py-4 whitespace-nowrap">
                  <router-link
                      :to="{name: routeList.client.discussions.show, params:{id: discussion.client.id, discussionId: discussion.id}}"
                      class="flex items-center hover:text-blue-600 "
                      tag="div">
                    <div class="w-8 h-8 block">
                      <client-avatar
                          :client="discussion.client"
                          class="w-full h-full" />
                    </div>
                    <div class="grow ml-2">
                      <span class="">{{ discussion.client.first_name }} {{ discussion.client.last_name }}</span>
                      <div class="text-sm text-gray-600">
                        <client-status-text :client="discussion.client" />
                      </div>
                    </div>
                  </router-link>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <tag-with-icon
                      :color="discussion.delivered ? 'green': 'gray'"
                      class="mr-2"
                      icon="delivered"
                      :class="{'opacity-50': !discussion.delivered}">
                    Delivered
                  </tag-with-icon>

                  <tag-with-icon
                      v-if="automation.delivery_type === 'EMAIL'"
                      :color="discussion.opened ? 'green': 'gray'"
                      class="mr-2"
                      icon="email"
                      :class="{'opacity-50': !discussion.opened}">
                    Opened
                  </tag-with-icon>

                  <tag-with-icon
                      v-if="automation.delivery_type === 'EMAIL'"
                      :color="discussion.clicked ? 'green': 'gray'"
                      class="mr-2"
                      icon="click"
                      :class="{'opacity-50': !discussion.clicked}">
                    Clicked
                  </tag-with-icon>
                  <tag-with-icon
                      v-if="discussion.bounced"
                      :color="discussion.bounced ? 'red': 'gray'"
                      class="mr-2 mt-1"
                      icon="exclamation">
                    Bounced
                  </tag-with-icon>
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-sm text-right text-gray-500">
                  <timestamp-to-date
                      :timestamp="discussion.created_at"
                      class="text-gray-900"
                      has-time
                      natural-date />
                  <time-ago
                      :timestamp="discussion.created_at"
                      class="block text-gray-500" />
                </td>
              </tr>
              </tbody>
              <tbody
                  v-if="!loadedFirstTime && loading"
                  class="bg-white">
              <tr>
                <td colspan="3">
                  <div class="flex items-center justify-center h-48">
                    <loader class="w-8 h-8 text-gray-500" />
                  </div>
                </td>
              </tr>
              </tbody>
              <tbody
                  v-if="!loading && paginatorInfo.total === 0"
                  class="bg-white">
              <tr>
                <td colspan="3">
                  <div class="flex items-center justify-center h-48">
                    <div class="text-center">
                      <div class="text-xl text-bold text-gray-600">
                        Nothing to show!
                      </div>
                      <p class="text-gray-600">
                        This automation has not sent any
                        <span v-text="automation.delivery_type ==='EMAIL' ? 'emails':'text messages'" /> yet
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
              <tfoot
                  v-if="loadedFirstTime && paginatorInfo.total > 0"
                  class="bg-gray-100">
              <tr>
                <td
                    class="p-4 text-right"
                    colspan="3">
                  <pagination
                      v-if="automationDiscussions "
                      :pagination="paginatorInfo"
                      class="flex items-center justify-between text-gray-700 text-sm"

                      @setPage="paginationData.page = $event">
                    <div
                        v-if="loading"
                        class="inline-block w-8 h-8 mr-2 text-gray-400">
                      <loader />
                    </div>
                  </pagination>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import TagWithIcon                                         from "@/components/layout/TagWithIcon.vue";
import ClientAvatar                                        from "@/components/client/ClientAvatar.vue";
import TimeAgo                                             from "@/components/layout/TimeAgo.vue";
import TimestampToDate                                     from "@/components/layout/TimestampToDate.vue";
import Pagination                                          from "@/components/layout/Pagination.vue";
import Loader                                              from "@/components/layout/Loader.vue";
import InlineMutedSelect                                   from "@/components/layout/InlineMutedSelect.vue";
import ClientStatusText                                    from "@/components/client/ClientStatusText.vue";
import {computed, ref, toRef, useAttrs, useSlots, watch}   from "vue";
import {
  QLAutomationDeliveryTypeEnum, QLAutomationsDiscussionsQueryVariables, QLFragmentFullAutomationFragment, QLSortOrder,
  useAutomationsDiscussionsQuery
}                                                          from "@/graphql/queries/ql/composables";
import routesList                                          from "@/composables/routesList";
import {createPaginationData, paginationWithAlreadyLoaded} from "@/composables/paginationWithAlreadyLoaded";
import {echoListener}                                      from "@/composables/echoListener";
import {EventTypes}                                        from "@/classes/notifications/EventTypes";

interface Props {
  automation: QLFragmentFullAutomationFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const status = ref<"All" | "Delivered" | "Opened" | "Clicked" | "Bounced">("All");
const {paginationData} = createPaginationData({
                                                page:    1,
                                                perPage: 25
                                              });

const automation = toRef(props, "automation");

const statusOptions = computed(() => {
  if (automation.value.delivery_type === QLAutomationDeliveryTypeEnum.EMAIL) {
    return [
      {label: "All", value: "All"},
      {label: "Delivered", value: "Delivered"},
      {label: "Opened", value: "Opened"},
      {label: "Clicked", value: "Clicked"},
      {label: "Bounced", value: "Bounced"}
    ];
  }

  return [
    {label: "All", value: "All"},
    {label: "Delivered", value: "Delivered"},
    {label: "Bounced", value: "Bounced"}
  ];
});

const apolloVariables = computed((): QLAutomationsDiscussionsQueryVariables => {

  let variables: QLAutomationsDiscussionsQueryVariables = {
    page:          paginationData.page,
    first:         paginationData.perPage,
    orderBy:       [{column: "last_contacted_at", order: QLSortOrder.DESC}],
    automation_id: automation.value.id.toString()
  };

  switch (status.value) {
    case "Bounced":
      variables.bounced = true;
      break;
    case "Clicked":
      variables.clicked = true;
      break;
    case "Delivered":
      variables.delivered = true;
      break;
    case "Opened":
      variables.opened = true;
      break;
  }

  return variables;
});

const discussionsQuery = useAutomationsDiscussionsQuery(apolloVariables);

const {
  paginatorInfo,
  loadedFirstTime,
  results: automationDiscussions,
  loading
} = paginationWithAlreadyLoaded(discussionsQuery);

watch(status, () => {
  automationDiscussions.value = [];
  loadedFirstTime.value = false;
  paginationData.page = 1;
});

const {routeList} = routesList();

echoListener([EventTypes.DiscussionNew, EventTypes.DiscussionUpdated], (ev) => {
  if (ev.data.communication_id == automation.value.id) {
    discussionsQuery.refetch();
  }
}, 10000);

</script>
