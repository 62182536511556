import {isRef, ref, Ref}          from "vue";
import {QLFragmentUserMeFragment} from "@/graphql/queries/ql/composables";
import {DecoratorInterface}       from "@/composables/decorators/DecoratorInterface";

export default function userMeDecorator(passed: Ref<QLFragmentUserMeFragment>|QLFragmentUserMeFragment ): DecoratorInterface {

	const entity = isRef(passed) ? passed : ref(passed);

	const hasPermission = (permission: string): boolean => {
		return entity.value.permissions.filter((perm) => {
			return perm.name === permission;
		}).length > 0;
	};

	return {
		methods: {
			hasPermission
		}
	};
}
