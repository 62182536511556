<template>
  <div class="block overflow-hidden shadow-md rounded-lg">
    <div class="bg-white p-4">
      <!--      <span class="font-medium">Period count</span>-->

      <div class=" divide-y ">
        <div class="flex items-center py-4">
          <input
              v-model="periodType"
              :disabled="sending"
              :value="periodTypes.ALL_TIME"
              class="form-radio"
              type="radio">
          <div class="grow ml-4">
            <div class="mb-1">
              Lifetime Club Count
            </div>
            <div class="text-xs text-gray-600">
              Select this option if your business celebrates client Club Counts since
              the client first joined your business (most common).
            </div>
          </div>
        </div>
        <div class="flex items-center py-4">
          <input
              v-model="periodType"
              :disabled="sending"
              :value="periodTypes.DAY_OF_YEAR"
              class="form-radio"
              type="radio">
          <div class="grow ml-4">
            <div class="mb-1">
              Annual reset
            </div>
            <div class="text-xs text-gray-600">
              Day of year Period Count will reset (eg - Period Count resets every
              year on January 1.)
            </div>

            <div
                v-if="periodType === periodTypes.DAY_OF_YEAR"
                class="mt-4">
              <day-of-year-input
                  v-model="periodValue"
                  :disabled="sending"
                  class="text-xs" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-100">
      <div class="flex justify-end p-4 py-3">
        <v-button
            :disabled="!canSend || sending"
            :loading="sending"
            class="btn btn-green text-sm font-normal"
            type="submit"
            @click="sendQuery">
          Update
        </v-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import VButton                                                       from "@/components/layout/VButton.vue";
import DayOfYearInput                                                from "@/components/layout/Forms/DayOfYearInput.vue";
import {computed, ref, watch}                                        from "vue";
import {QLPeriodCountType, useUpdateTeamPeriodCountSettingsMutation} from "@/graphql/queries/ql/composables";
import currentTeamQuery                                              from "@/composables/queries/currentTeamQuery";
import $toast                                                        from "@/composables/toast";

const sending = ref(false);
const periodType = ref<QLPeriodCountType>(QLPeriodCountType.ALL_TIME);
const periodValue = ref(0);

const periodTypes = QLPeriodCountType;
const currentTeamQ = currentTeamQuery();
const {currentTeam} = currentTeamQ;

const canSend = computed(() => {
  if (!currentTeam.value || !currentTeam.value.period_count_data) {
    return false;
  }

  if (periodType.value === currentTeam.value.period_count_data.period && periodValue.value === currentTeam.value.period_count_data.value) {
    return false;
  }
  if (periodType.value === QLPeriodCountType.DAY_OF_YEAR && !periodValue.value) {
    return false;
  }

  return true;
});

watch(currentTeam, () => {
  periodType.value = QLPeriodCountType[currentTeam.value.period_count_data.period];
  periodValue.value = currentTeam.value.period_count_data.value;
}, {immediate: true});

watch(periodType, (val) => {
  if (val === QLPeriodCountType.ALL_TIME) {
    periodValue.value = 0;
  }
});

const updatePeriod = useUpdateTeamPeriodCountSettingsMutation({});

updatePeriod.onDone(() => {
  sending.value = false;
});

updatePeriod.onError(() => {
  sending.value = false;
  $toast({
           type:         "error",
           message:      "There was a problem with your request. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const sendQuery = () => {
  sending.value = true;
  updatePeriod.mutate({
                        period_count_type: periodType.value,
                        period_count_data: {
                          value:  periodValue.value,
                          period: periodType.value
                        }
                      });
};

</script>
