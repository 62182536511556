<template>
  <div>
    <h1 class="main-page-title">
      Forms completed by {{ client.first_name }}
    </h1>



    <div class="space-y-12">
      <ClientFormsWidgets :client="client" />

      <client-forms-recap :client="client" />

      <client-forms-history :client="client" />
    </div>
  </div>
</template>
<script lang="ts" setup>

import ClientFormsRecap   from "@/views/ClientShow/Components/Forms/ClientFormsRecap.vue";
import {QLClient}         from "@/graphql/queries/ql/composables";
import {toRef}            from "vue";
import ClientFormsHistory from "@/views/ClientShow/Components/Forms/ClientFormsHistory.vue";
import ClientFormsWidgets from "@/views/ClientShow/Components/Forms/ClientFormsWidgets.vue";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const client = toRef(props, "client");

</script>
