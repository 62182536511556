import {find, flatten, indexOf, isEmpty, keys, mapKeys, some, toArray} from "lodash";

export interface SparkErrors {
	[index: string]: string[];
}

/**
 * Spark form error collection class.
 */
export default class SparkFormErrors {
	errors: SparkErrors;

	constructor(errors = {}) {
		this.errors = errors;
	}

	/**
	 * Determine if the collection has any errors.
	 */
	get hasErrors(): boolean {
		return !isEmpty(this.errors);
	};

	hasErrorFor(start: string): boolean {
		return find(this.errors, (item, index) => {
			return index.startsWith(start);
		}) !== undefined;
	}

	/**
	 * Determine if the collection has errors for a given field.
	 */
	has(field: string): boolean {
		return indexOf(keys(this.errors), field) > -1;
	};

	hasAnyOf(fields: string[]): boolean {
		return some(this.errors, (value, key) => {
			return indexOf(fields, key) > -1;
		});
	}

	/**
	 * Get all of the raw errors for the collection.
	 */
	all() {
		return this.errors;
	};


	/**
	 * Get all of the errors for the collection in a flat array.
	 */
	flatten(): string[] {
		return flatten(toArray(this.errors));
	};


	/**
	 * Get the first error message for a given field.
	 */
	get(field: string): string | null {
		if (this.has(field)) {
			return this.errors[field][0];
		}
		return null;
	};


	/**
	 * Set the raw errors for the collection.
	 */
	set(errors: object | any) {
		if (typeof errors === "object") {
			this.errors = errors;
		} else {
			this.errors = {"form": ["Something went wrong. Please try again or contact customer support."]};
		}
		return this;
	};


	/**
	 * Remove errors from the collection.
	 */
	forget(field?: string) {
		if (typeof field === "undefined") {
			this.errors = {};
		} else {
			delete this.errors[field];
		}
	};

	// replaceStartOfKey(keyToRemove: string) {
	// 	const errs = mapKeys(this.errors, (value, key) => {
	// 		if (key.indexOf(keyToRemove) === 0) {
	// 			return key.slice(keyToRemove.length);
	// 		}
	// 		return key;
	// 	});
	//
	// 	this.errors = errs;
	// 	return errs;
	// }

	static replaceInputFromMessage(errors, inputToRemove: string = "input.") {
		for (const key in errors) {
			errors[key] = errors[key].map((item) => {
				return item.replace(inputToRemove, "");
			});
		}
		return errors;
	}
}

