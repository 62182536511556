<template>
  <side-panel
      v-model:open="openModel"
      :closable="true">
    <template #header>
      To-Do Preview
    </template>

    <div class="px-4 py-4 h-full">
      <div class="space-y-4">
        <div class="inline-flex items-center text-sm text-gray-700 space-x-2 ">
          <span class="">Assigned to:</span>
          <tag-with-user
              v-if="automation.todo_assigned_to"
              :user="automation.todo_assigned_to"
              color="loopspark" />
          <tag-with-icon
              v-if="assignedToEveryone"
              color="green"
              icon="users">
            Everyone
          </tag-with-icon>
          <tag-with-icon
              v-if="automation.todo_team_group"
              color="loopspark"
              icon="users">
            {{
              automation.todo_team_group.name
            }}
          </tag-with-icon>
        </div>
        <div class="text-sm text-gray-700">
          Subject: <span class="text-gray-900 font-semibold">{{ automation.subject }}</span>
        </div>
        <div class="mb-4">
          <div class="border border-gray-200 py-1 px-2 mb-2">
            <div class="prose">
              <div v-html="automation.content" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </side-panel>
</template>
<script lang="ts" setup>

import SidePanel                          from "@/components/layout/SidePanel.vue";
import TagWithUser                        from "@/components/layout/TagWithUser.vue";
import TagWithIcon                        from "@/components/layout/TagWithIcon.vue";
import {computed}                         from "vue";
import {QLFragmentFullAutomationFragment} from "@/graphql/queries/ql/composables";

interface Props {
  open: boolean;
  automation: QLFragmentFullAutomationFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:open"]);

const assignedToEveryone = computed(() => {
  return !props.automation.todo_assigned_to && !props.automation.todo_team_group;
});

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

</script>
