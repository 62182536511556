import {RouteLocationNormalized, RouteLocationRaw}               from "vue-router";
import Routes                                                    from "@/router/Routes";
import {UserRolePolicy}                                          from "@/classes/policy/UserRolePolicy";
import {QLFragmentCurrentTeamFragment, QLFragmentUserMeFragment} from "@/graphql/queries/ql/composables";
import userMeDecorator                                           from "@/composables/decorators/userMeDecorator";
import {addonEnabled}                                            from "@/classes/billing/addons";

export class RouterGuardExecutor {
	private to: RouteLocationNormalized;
	private from: RouteLocationNormalized;
	private user: QLFragmentUserMeFragment | null;
	private currentTeam: QLFragmentCurrentTeamFragment | null;
	private nextToSend: null | RouteLocationRaw = null;

	constructor(to: RouteLocationNormalized, from: RouteLocationNormalized,
	            user: QLFragmentUserMeFragment | null,
	            currentTeam: QLFragmentCurrentTeamFragment | null) {
		this.to = to;
		this.from = from;
		this.user = user;
		this.currentTeam = currentTeam;
	}

	public static next(to: RouteLocationNormalized, from: RouteLocationNormalized,
	                   user: QLFragmentUserMeFragment | null,
	                   currentTeam: QLFragmentCurrentTeamFragment | null): null | RouteLocationRaw {

		return (new this(to, from, user, currentTeam)).calculate();
	}

	public calculate(): null | RouteLocationRaw {
		const methods = [
			"requiresAuth",
			"missingStudio",
			"isOwner",
			"billingRequired",
			"requiresAddonForms",
			"requiresAddonZapier",
			"canCreateForms",
			"canEditForms",
			"canCreateAutomations",
			"canEditAutomations",
			"permissionRequired",
			"atLeastRole"
		];
		for (const method of methods) {
			if (!this.alreadyReturning()) {
				this[method]();
			}
		}

		return this.nextToSend;
	}

	private alreadyReturning(): boolean {
		return this.nextToSend !== null;
	}

	private requiresAuth() {
		if (!this.user && this.to.matched.some(record => record.meta.requiresAuth)) {
			this.sendNext({name: Routes.login});
		}
	}

	private requiresAddonForms() {
		if (this.to.matched.some(record => record.meta.addonForms)) {
			if (!addonEnabled(this.currentTeam, "forms")) {
				if (this.user.policy.is_owner) {
					this.sendNext({name: Routes.studio.settings.billing});
				} else {
					this.sendNext({name: Routes.dashboard});
				}
			}
		}
	}

	private requiresAddonZapier() {
		if (this.to.matched.some(record => record.meta.addonZapier)) {
			if (!addonEnabled(this.currentTeam, "zapier")) {
				if (this.user.policy.is_owner) {
					this.sendNext({name: Routes.studio.settings.billing});
				} else {
					this.sendNext({name: Routes.dashboard});
				}
			}
		}
	}

	private missingStudio() {
		if (this.to.matched.some(record => record.meta.requiresTeam)) {
			if (!this.currentTeam) {
				this.sendNext({name: Routes.missingStudio});
			}
		}
	}

	private isOwner() {
		if (this.to.matched.some(record => record.meta.owner)) {
			if (this.user && !this.user.policy.is_owner) {
				this.sendNext("/");
			}
		}
	}

	private canCreateAutomations() {
		if (this.to.matched.some(record => record.meta.canCreateAutomations)) {
			if (this.user && !this.user.policy.create_automations) {
				this.sendNext("/");
			}
		}
	}

	private canEditAutomations() {
		if (this.to.matched.some(record => record.meta.canEditAutomations)) {
			if (this.user && !this.user.policy.edit_automations) {
				this.sendNext("/");
			}
		}
	}

	private canCreateForms() {
		if (this.to.matched.some(record => record.meta.canCreateForms)) {
			if (this.user && !this.user.policy.create_forms) {
				this.sendNext("/");
			}
		}
	}

	private canEditForms() {
		if (this.to.matched.some(record => record.meta.canEditForms)) {
			if (this.user && !this.user.policy.edit_forms) {
				this.sendNext("/");
			}
		}
	}

	private billingRequired() {
		if (this.to.matched.some(record => record.meta.billingRequired)) {
			if (this.currentTeam && !this.currentTeam.is_free && !this.currentTeam.subscription_active) {
				if (this.user.policy.is_main_owner) {
					this.sendNext({name: Routes.studio.settings.billing});
				} else {
					this.sendNext({name: Routes.billingIssues});
				}
			}
		}
	}

	private permissionRequired() {
		this.to.matched.map((r) => {
			if (r.meta.permission !== undefined) {
				if (this.user && !this.hasPermission(<string>r.meta?.permission)) {
					this.sendNext("/");
				}
			}
		});
	}

	private sendNext(data: RouteLocationRaw = {}) {
		if (!this.nextToSend) {
			this.nextToSend = data;
		}
	}

	private hasPermission(permission: string): boolean {
		const dec = userMeDecorator(this.user);
		return dec.methods.hasPermission(permission);
	}

	private atLeastRole() {
		const roles = [];
		this.to.matched.map((r) => {
			if (r.meta.atLeastRole !== undefined) {
				roles.push(r.meta.atLeastRole);
			}
		});
		if (roles.length > 0) {
			if (!this.user) {
				this.sendNext({name: Routes.login});
				return;
			}
			roles.map((roleToCheck) => {
				if (!UserRolePolicy.isAtLeast(this.user.policy.role, roleToCheck)) {
					this.sendNext("/");
				}
			});
		}
	}
}
