import {computed, ref, watch} from "vue";
import {ComputedRef}          from "@vue/reactivity";

export default function fakeSimplePagination(items: ComputedRef<any[]>, defaultPerPage: number = 10) {

	const page = ref(1);
	const perPage = ref(defaultPerPage);

	const setPage = (newPage) => {
		page.value = newPage;
	};

	watch(() => perPage, () => {
		page.value = 1;
	});

	const hasMorePages = computed(() => {
		return items.value.length - (perPage.value * (page.value - 1) + perPage.value) > 0;
	});

	const paginatedItems = computed(() => {
		const start = (page.value - 1) * perPage.value;
		return [...items.value].slice(start, start + perPage.value);
	});


	return {
		page,
		perPage,
		setPage,
		hasMorePages,
		paginatedItems
	};
}
