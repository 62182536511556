<template>
  <div class="grid grid-cols-12 gap-4">
    <div
v-for="currentField in fields"
         :key="currentField.id"
         :class="{'col-span-12': currentField.size === 100, 'col-span-9': currentField.size === 75, 'col-span-8': currentField.size === 66, 'col-span-6': currentField.size === 50, 'col-span-4': currentField.size === 33, 'col-span-2': currentField.size === 25}">
      <component
:is="fieldDisplayComponent(currentField)"
:item="currentField" />
    </div>
  </div>
  <empty v-if="fields.length === 0">
    <template #title>
The form is empty
</template>
    <template #subtitle>
Use the form content box
      <span class="md:hidden">above</span><span class="hidden md:inline">on the left</span> to start designing your form by adding a "text block" or "input field."
    </template>
  </empty>
</template>
<script lang="ts" setup>
import {QLFieldTypeEnum, QLFormField} from "@/graphql/queries/ql/composables";
import {toRef, useAttrs, useSlots}    from "vue";
import DisplayTextBlock               from "@/components/Forms/Fields/DisplayTextBlock.vue";
import DisplayTextInput               from "@/components/Forms/Fields/DisplayTextInput.vue";
import DisplayYesNoInput              from "@/components/Forms/Fields/DisplayYesNoInput.vue";
import DisplaySelectInput             from "@/components/Forms/Fields/DisplaySelectInput.vue";
import DisplayMultipleInput           from "@/components/Forms/Fields/DisplayMultipleInput.vue";
import DisplayDateInput               from "@/components/Forms/Fields/DisplayDateInput.vue";
import DisplayEmailInput              from "@/components/Forms/Fields/DisplayEmailInput.vue";
import DisplayPhoneInput              from "@/components/Forms/Fields/DisplayPhoneInput.vue";
import DisplaySignatureInput          from "@/components/Forms/Fields/DisplaySignatureInput.vue";
import DisplayRadioInput              from "@/components/Forms/Fields/DisplayRadioInput.vue";
import DisplayImage                   from "@/components/Forms/Fields/DisplayImage.vue";
import DisplayTextArea                from "@/components/Forms/Fields/DisplayTextArea.vue";
import DisplayStateSelectInput                from "@/components/Forms/Fields/DisplayStateSelectInput.vue";
import Empty                          from "@/components/layout/Empty.vue";

interface Props {
  fields: QLFormField[];
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const fields = toRef(props, "fields");

const fieldDisplayComponent = (field: QLFormField) => {

  if (!field.kind) {
    return DisplayTextInput;
  }

  switch (field.kind) {
    case QLFieldTypeEnum.TEXT_BLOCK:
      return DisplayTextBlock;
    case QLFieldTypeEnum.TEXT_INPUT:
      return DisplayTextInput;
    case QLFieldTypeEnum.YES_NO:
      return DisplayYesNoInput;
    case QLFieldTypeEnum.SELECT:
      return DisplaySelectInput;
    case QLFieldTypeEnum.MULTIPLE:
      return DisplayMultipleInput;
    case QLFieldTypeEnum.DATE:
      return DisplayDateInput;
    case QLFieldTypeEnum.EMAIL:
      return DisplayEmailInput;
      case QLFieldTypeEnum.PHONE:
        return DisplayPhoneInput;
    case QLFieldTypeEnum.FIRST_NAME:
      return DisplayTextInput;
    case QLFieldTypeEnum.LAST_NAME:
      return DisplayTextInput;
    case QLFieldTypeEnum.SIGNATURE:
      return DisplaySignatureInput;
    case QLFieldTypeEnum.RADIO:
      return DisplayRadioInput;
    case QLFieldTypeEnum.IMAGE:
      return DisplayImage;
    case QLFieldTypeEnum.TEXT_AREA:
      return DisplayTextArea;
    case QLFieldTypeEnum.STATE:
      return DisplayStateSelectInput;
  }

  return DisplayTextInput;
};
</script>
