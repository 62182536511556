export const RawTimeZones = [
	{
		"name":               "Pacific/Niue",
		"alternativeName":    "Niue Time",
		"group":              [
			"Pacific/Niue"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Niue",
		"countryCode":        "NU",
		"mainCities":         [
			"Alofi"
		],
		"rawOffsetInMinutes": -660,
		"rawFormat":          "-11:00 Niue Time - Alofi"
	},
	{
		"name":               "Pacific/Midway",
		"alternativeName":    "Samoa Time",
		"group":              [
			"Pacific/Midway"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "United States Minor Outlying Islands",
		"countryCode":        "UM",
		"mainCities":         [
			"Midway"
		],
		"rawOffsetInMinutes": -660,
		"rawFormat":          "-11:00 Samoa Time - Midway"
	},
	{
		"name":               "Pacific/Pago_Pago",
		"alternativeName":    "Samoa Time",
		"group":              [
			"Pacific/Pago_Pago"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "American Samoa",
		"countryCode":        "AS",
		"mainCities":         [
			"Pago Pago"
		],
		"rawOffsetInMinutes": -660,
		"rawFormat":          "-11:00 Samoa Time - Pago Pago"
	},
	{
		"name":               "Pacific/Rarotonga",
		"alternativeName":    "Cook Islands Time",
		"group":              [
			"Pacific/Rarotonga"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Cook Islands",
		"countryCode":        "CK",
		"mainCities":         [
			"Avarua"
		],
		"rawOffsetInMinutes": -600,
		"rawFormat":          "-10:00 Cook Islands Time - Avarua"
	},
	{
		"name":               "America/Adak",
		"alternativeName":    "Hawaii-Aleutian Time",
		"group":              [
			"America/Adak"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "United States",
		"countryCode":        "US",
		"mainCities":         [
			"Adak"
		],
		"rawOffsetInMinutes": -600,
		"rawFormat":          "-10:00 Hawaii-Aleutian Time - Adak"
	},
	{
		"name":               "Pacific/Honolulu",
		"alternativeName":    "Hawaii-Aleutian Time",
		"group":              [
			"Pacific/Honolulu"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "United States",
		"countryCode":        "US",
		"mainCities":         [
			"Honolulu",
			"East Honolulu"
		],
		"rawOffsetInMinutes": -600,
		"rawFormat":          "-10:00 Hawaii-Aleutian Time - Honolulu, East Honolulu"
	},
	{
		"name":               "Pacific/Tahiti",
		"alternativeName":    "Tahiti Time",
		"group":              [
			"Pacific/Tahiti"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "French Polynesia",
		"countryCode":        "PF",
		"mainCities":         [
			"Faaa",
			"Papeete"
		],
		"rawOffsetInMinutes": -600,
		"rawFormat":          "-10:00 Tahiti Time - Faaa, Papeete"
	},
	{
		"name":               "Pacific/Marquesas",
		"alternativeName":    "Marquesas Time",
		"group":              [
			"Pacific/Marquesas"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "French Polynesia",
		"countryCode":        "PF",
		"mainCities":         [
			"Marquesas"
		],
		"rawOffsetInMinutes": -570,
		"rawFormat":          "-09:30 Marquesas Time - Marquesas"
	},
	{
		"name":               "America/Anchorage",
		"alternativeName":    "Alaska Time",
		"group":              [
			"America/Anchorage",
			"America/Juneau",
			"America/Metlakatla",
			"America/Nome",
			"America/Sitka",
			"America/Yakutat"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "United States",
		"countryCode":        "US",
		"mainCities":         [
			"Anchorage",
			"Juneau"
		],
		"rawOffsetInMinutes": -540,
		"rawFormat":          "-09:00 Alaska Time - Anchorage, Juneau"
	},
	{
		"name":               "Pacific/Gambier",
		"alternativeName":    "Gambier Time",
		"group":              [
			"Pacific/Gambier"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "French Polynesia",
		"countryCode":        "PF",
		"mainCities":         [
			"Gambier"
		],
		"rawOffsetInMinutes": -540,
		"rawFormat":          "-09:00 Gambier Time - Gambier"
	},
	{
		"name":               "America/Los_Angeles",
		"alternativeName":    "Pacific Time",
		"group":              [
			"America/Los_Angeles"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "United States",
		"countryCode":        "US",
		"mainCities":         [
			"Los Angeles",
			"San Diego"
		],
		"rawOffsetInMinutes": -480,
		"rawFormat":          "-08:00 Pacific Time - Los Angeles, San Diego"
	},
	{
		"name":               "America/Tijuana",
		"alternativeName":    "Pacific Time",
		"group":              [
			"America/Tijuana"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Mexico",
		"countryCode":        "MX",
		"mainCities":         [
			"Tijuana",
			"Mexicali"
		],
		"rawOffsetInMinutes": -480,
		"rawFormat":          "-08:00 Pacific Time - Tijuana, Mexicali"
	},
	{
		"name":               "America/Vancouver",
		"alternativeName":    "Pacific Time",
		"group":              [
			"America/Vancouver"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Canada",
		"countryCode":        "CA",
		"mainCities":         [
			"Vancouver",
			"Surrey"
		],
		"rawOffsetInMinutes": -480,
		"rawFormat":          "-08:00 Pacific Time - Vancouver, Surrey"
	},
	{
		"name":               "Pacific/Pitcairn",
		"alternativeName":    "Pitcairn Time",
		"group":              [
			"Pacific/Pitcairn"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Pitcairn",
		"countryCode":        "PN",
		"mainCities":         [
			"Adamstown"
		],
		"rawOffsetInMinutes": -480,
		"rawFormat":          "-08:00 Pitcairn Time - Adamstown"
	},
	{
		"name":               "America/Hermosillo",
		"alternativeName":    "Mexican Pacific Time",
		"group":              [
			"America/Hermosillo"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Mexico",
		"countryCode":        "MX",
		"mainCities":         [
			"Hermosillo",
			"Ciudad Obregón"
		],
		"rawOffsetInMinutes": -420,
		"rawFormat":          "-07:00 Mexican Pacific Time - Hermosillo, Ciudad Obregón"
	},
	{
		"name":               "America/Edmonton",
		"alternativeName":    "Mountain Time",
		"group":              [
			"America/Cambridge_Bay",
			"America/Edmonton",
			"America/Inuvik",
			"America/Yellowknife"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Canada",
		"countryCode":        "CA",
		"mainCities":         [
			"Calgary",
			"Edmonton"
		],
		"rawOffsetInMinutes": -420,
		"rawFormat":          "-07:00 Mountain Time - Calgary, Edmonton"
	},
	{
		"name":               "America/Ojinaga",
		"alternativeName":    "Mountain Time",
		"group":              [
			"America/Chihuahua",
			"America/Mazatlan",
			"America/Ojinaga"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Mexico",
		"countryCode":        "MX",
		"mainCities":         [
			"Ciudad Juárez",
			"Chihuahua"
		],
		"rawOffsetInMinutes": -420,
		"rawFormat":          "-07:00 Mountain Time - Ciudad Juárez, Chihuahua"
	},
	{
		"name":               "America/Denver",
		"alternativeName":    "Mountain Time",
		"group":              [
			"America/Boise",
			"America/Denver"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "United States",
		"countryCode":        "US",
		"mainCities":         [
			"Denver",
			"El Paso"
		],
		"rawOffsetInMinutes": -420,
		"rawFormat":          "-07:00 Mountain Time - Denver, El Paso"
	},
	{
		"name":               "America/Dawson_Creek",
		"alternativeName":    "Mountain Time",
		"group":              [
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Canada",
		"countryCode":        "CA",
		"mainCities":         [
			"Fort St. John",
			"Creston"
		],
		"rawOffsetInMinutes": -420,
		"rawFormat":          "-07:00 Mountain Time - Fort St. John, Creston"
	},
	{
		"name":               "America/Phoenix",
		"alternativeName":    "Mountain Time",
		"group":              [
			"America/Phoenix"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "United States",
		"countryCode":        "US",
		"mainCities":         [
			"Phoenix",
			"Tucson"
		],
		"rawOffsetInMinutes": -420,
		"rawFormat":          "-07:00 Mountain Time - Phoenix, Tucson"
	},
	{
		"name":               "America/Whitehorse",
		"alternativeName":    "Pacific Time",
		"group":              [
			"America/Dawson",
			"America/Whitehorse"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Canada",
		"countryCode":        "CA",
		"mainCities":         [
			"Whitehorse",
			"Dawson"
		],
		"rawOffsetInMinutes": -420,
		"rawFormat":          "-07:00 Pacific Time - Whitehorse, Dawson"
	},
	{
		"name":               "America/Belize",
		"alternativeName":    "Central Time",
		"group":              [
			"America/Belize"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Belize",
		"countryCode":        "BZ",
		"mainCities":         [
			"Belize City",
			"San Ignacio"
		],
		"rawOffsetInMinutes": -360,
		"rawFormat":          "-06:00 Central Time - Belize City, San Ignacio"
	},
	{
		"name":               "America/Chicago",
		"alternativeName":    "Central Time",
		"group":              [
			"America/Chicago",
			"America/Indiana/Knox",
			"America/Indiana/Tell_City",
			"America/Menominee",
			"America/North_Dakota/Beulah",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "United States",
		"countryCode":        "US",
		"mainCities":         [
			"Chicago",
			"Houston"
		],
		"rawOffsetInMinutes": -360,
		"rawFormat":          "-06:00 Central Time - Chicago, Houston"
	},
	{
		"name":               "America/Guatemala",
		"alternativeName":    "Central Time",
		"group":              [
			"America/Guatemala"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Guatemala",
		"countryCode":        "GT",
		"mainCities":         [
			"Guatemala City",
			"Mixco"
		],
		"rawOffsetInMinutes": -360,
		"rawFormat":          "-06:00 Central Time - Guatemala City, Mixco"
	},
	{
		"name":               "America/Managua",
		"alternativeName":    "Central Time",
		"group":              [
			"America/Managua"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Nicaragua",
		"countryCode":        "NI",
		"mainCities":         [
			"Managua",
			"León"
		],
		"rawOffsetInMinutes": -360,
		"rawFormat":          "-06:00 Central Time - Managua, León"
	},
	{
		"name":               "America/Mexico_City",
		"alternativeName":    "Central Time",
		"group":              [
			"America/Bahia_Banderas",
			"America/Matamoros",
			"America/Merida",
			"America/Mexico_City",
			"America/Monterrey"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Mexico",
		"countryCode":        "MX",
		"mainCities":         [
			"Mexico City",
			"Iztapalapa"
		],
		"rawOffsetInMinutes": -360,
		"rawFormat":          "-06:00 Central Time - Mexico City, Iztapalapa"
	},
	{
		"name":               "America/Costa_Rica",
		"alternativeName":    "Central Time",
		"group":              [
			"America/Costa_Rica"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Costa Rica",
		"countryCode":        "CR",
		"mainCities":         [
			"San José",
			"Limón"
		],
		"rawOffsetInMinutes": -360,
		"rawFormat":          "-06:00 Central Time - San José, Limón"
	},
	{
		"name":               "America/El_Salvador",
		"alternativeName":    "Central Time",
		"group":              [
			"America/El_Salvador"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "El Salvador",
		"countryCode":        "SV",
		"mainCities":         [
			"San Salvador",
			"Soyapango"
		],
		"rawOffsetInMinutes": -360,
		"rawFormat":          "-06:00 Central Time - San Salvador, Soyapango"
	},
	{
		"name":               "America/Regina",
		"alternativeName":    "Central Time",
		"group":              [
			"America/Regina",
			"America/Swift_Current"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Canada",
		"countryCode":        "CA",
		"mainCities":         [
			"Saskatoon",
			"Regina"
		],
		"rawOffsetInMinutes": -360,
		"rawFormat":          "-06:00 Central Time - Saskatoon, Regina"
	},
	{
		"name":               "America/Tegucigalpa",
		"alternativeName":    "Central Time",
		"group":              [
			"America/Tegucigalpa"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Honduras",
		"countryCode":        "HN",
		"mainCities":         [
			"Tegucigalpa",
			"San Pedro Sula"
		],
		"rawOffsetInMinutes": -360,
		"rawFormat":          "-06:00 Central Time - Tegucigalpa, San Pedro Sula"
	},
	{
		"name":               "America/Winnipeg",
		"alternativeName":    "Central Time",
		"group":              [
			"America/Rainy_River",
			"America/Rankin_Inlet",
			"America/Resolute",
			"America/Winnipeg"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Canada",
		"countryCode":        "CA",
		"mainCities":         [
			"Winnipeg",
			"Brandon"
		],
		"rawOffsetInMinutes": -360,
		"rawFormat":          "-06:00 Central Time - Winnipeg, Brandon"
	},
	{
		"name":               "Pacific/Easter",
		"alternativeName":    "Easter Island Time",
		"group":              [
			"Pacific/Easter"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Chile",
		"countryCode":        "CL",
		"mainCities":         [
			"Easter"
		],
		"rawOffsetInMinutes": -360,
		"rawFormat":          "-06:00 Easter Island Time - Easter"
	},
	{
		"name":               "Pacific/Galapagos",
		"alternativeName":    "Galapagos Time",
		"group":              [
			"Pacific/Galapagos"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Ecuador",
		"countryCode":        "EC",
		"mainCities":         [
			"Galapagos"
		],
		"rawOffsetInMinutes": -360,
		"rawFormat":          "-06:00 Galapagos Time - Galapagos"
	},
	{
		"name":               "America/Rio_Branco",
		"alternativeName":    "Acre Time",
		"group":              [
			"America/Eirunepe",
			"America/Rio_Branco"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Brazil",
		"countryCode":        "BR",
		"mainCities":         [
			"Rio Branco",
			"Cruzeiro do Sul"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Acre Time - Rio Branco, Cruzeiro do Sul"
	},
	{
		"name":               "America/Bogota",
		"alternativeName":    "Colombia Time",
		"group":              [
			"America/Bogota"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Colombia",
		"countryCode":        "CO",
		"mainCities":         [
			"Bogotá",
			"Cali"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Colombia Time - Bogotá, Cali"
	},
	{
		"name":               "America/Havana",
		"alternativeName":    "Cuba Time",
		"group":              [
			"America/Havana"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Cuba",
		"countryCode":        "CU",
		"mainCities":         [
			"Havana",
			"Santiago de Cuba"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Cuba Time - Havana, Santiago de Cuba"
	},
	{
		"name":               "America/Atikokan",
		"alternativeName":    "Eastern Time",
		"group":              [
			"America/Atikokan"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Canada",
		"countryCode":        "CA",
		"mainCities":         [
			"Atikokan"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Eastern Time - Atikokan"
	},
	{
		"name":               "America/Cancun",
		"alternativeName":    "Eastern Time",
		"group":              [
			"America/Cancun"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Mexico",
		"countryCode":        "MX",
		"mainCities":         [
			"Cancún",
			"Chetumal"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Eastern Time - Cancún, Chetumal"
	},
	{
		"name":               "America/Grand_Turk",
		"alternativeName":    "Eastern Time",
		"group":              [
			"America/Grand_Turk"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Turks and Caicos Islands",
		"countryCode":        "TC",
		"mainCities":         [
			"Cockburn Town"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Eastern Time - Cockburn Town"
	},
	{
		"name":               "America/Cayman",
		"alternativeName":    "Eastern Time",
		"group":              [
			"America/Cayman"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Cayman Islands",
		"countryCode":        "KY",
		"mainCities":         [
			"George Town"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Eastern Time - George Town"
	},
	{
		"name":               "America/Jamaica",
		"alternativeName":    "Eastern Time",
		"group":              [
			"America/Jamaica"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Jamaica",
		"countryCode":        "JM",
		"mainCities":         [
			"Kingston",
			"New Kingston"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Eastern Time - Kingston, New Kingston"
	},
	{
		"name":               "America/Nassau",
		"alternativeName":    "Eastern Time",
		"group":              [
			"America/Nassau"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Bahamas",
		"countryCode":        "BS",
		"mainCities":         [
			"Nassau",
			"Lucaya"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Eastern Time - Nassau, Lucaya"
	},
	{
		"name":               "America/New_York",
		"alternativeName":    "Eastern Time",
		"group":              [
			"America/Detroit",
			"America/Indiana/Indianapolis",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/New_York"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "United States",
		"countryCode":        "US",
		"mainCities":         [
			"New York City",
			"Brooklyn"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Eastern Time - New York City, Brooklyn"
	},
	{
		"name":               "America/Panama",
		"alternativeName":    "Eastern Time",
		"group":              [
			"America/Panama"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Panama",
		"countryCode":        "PA",
		"mainCities":         [
			"Panamá",
			"San Miguelito"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Eastern Time - Panamá, San Miguelito"
	},
	{
		"name":               "America/Port-au-Prince",
		"alternativeName":    "Eastern Time",
		"group":              [
			"America/Port-au-Prince"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Haiti",
		"countryCode":        "HT",
		"mainCities":         [
			"Port-au-Prince",
			"Carrefour"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Eastern Time - Port-au-Prince, Carrefour"
	},
	{
		"name":               "America/Toronto",
		"alternativeName":    "Eastern Time",
		"group":              [
			"America/Iqaluit",
			"America/Nipigon",
			"America/Pangnirtung",
			"America/Thunder_Bay",
			"America/Toronto"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Canada",
		"countryCode":        "CA",
		"mainCities":         [
			"Toronto",
			"Montréal"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Eastern Time - Toronto, Montréal"
	},
	{
		"name":               "America/Guayaquil",
		"alternativeName":    "Ecuador Time",
		"group":              [
			"America/Guayaquil"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Ecuador",
		"countryCode":        "EC",
		"mainCities":         [
			"Guayaquil",
			"Quito"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Ecuador Time - Guayaquil, Quito"
	},
	{
		"name":               "America/Lima",
		"alternativeName":    "Peru Time",
		"group":              [
			"America/Lima"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Peru",
		"countryCode":        "PE",
		"mainCities":         [
			"Lima",
			"Arequipa"
		],
		"rawOffsetInMinutes": -300,
		"rawFormat":          "-05:00 Peru Time - Lima, Arequipa"
	},
	{
		"name":               "America/Manaus",
		"alternativeName":    "Amazon Time",
		"group":              [
			"America/Boa_Vista",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Manaus",
			"America/Porto_Velho"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Brazil",
		"countryCode":        "BR",
		"mainCities":         [
			"Manaus",
			"Campo Grande"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Amazon Time - Manaus, Campo Grande"
	},
	{
		"name":               "America/St_Kitts",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/St_Kitts"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Saint Kitts and Nevis",
		"countryCode":        "KN",
		"mainCities":         [
			"Basseterre"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Basseterre"
	},
	{
		"name":               "America/Montserrat",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Montserrat"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Montserrat",
		"countryCode":        "MS",
		"mainCities":         [
			"Brades",
			"Plymouth"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Brades, Plymouth"
	},
	{
		"name":               "America/Barbados",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Barbados"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Barbados",
		"countryCode":        "BB",
		"mainCities":         [
			"Bridgetown"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Bridgetown"
	},
	{
		"name":               "America/St_Lucia",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/St_Lucia"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Saint Lucia",
		"countryCode":        "LC",
		"mainCities":         [
			"Castries"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Castries"
	},
	{
		"name":               "America/Port_of_Spain",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Port_of_Spain"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Trinidad and Tobago",
		"countryCode":        "TT",
		"mainCities":         [
			"Chaguanas",
			"Mon Repos"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Chaguanas, Mon Repos"
	},
	{
		"name":               "America/Martinique",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Martinique"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Martinique",
		"countryCode":        "MQ",
		"mainCities":         [
			"Fort-de-France",
			"Le Lamentin"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Fort-de-France, Le Lamentin"
	},
	{
		"name":               "America/St_Barthelemy",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/St_Barthelemy"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Saint Barthelemy",
		"countryCode":        "BL",
		"mainCities":         [
			"Gustavia"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Gustavia"
	},
	{
		"name":               "America/Halifax",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Glace_Bay",
			"America/Goose_Bay",
			"America/Halifax",
			"America/Moncton"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Canada",
		"countryCode":        "CA",
		"mainCities":         [
			"Halifax",
			"Moncton"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Halifax, Moncton"
	},
	{
		"name":               "Atlantic/Bermuda",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"Atlantic/Bermuda"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Bermuda",
		"countryCode":        "BM",
		"mainCities":         [
			"Hamilton"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Hamilton"
	},
	{
		"name":               "America/St_Vincent",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/St_Vincent"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Saint Vincent and the Grenadines",
		"countryCode":        "VC",
		"mainCities":         [
			"Kingstown",
			"Kingstown Park"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Kingstown, Kingstown Park"
	},
	{
		"name":               "America/Kralendijk",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Kralendijk"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Bonaire, Saint Eustatius and Saba ",
		"countryCode":        "BQ",
		"mainCities":         [
			"Kralendijk"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Kralendijk"
	},
	{
		"name":               "America/Guadeloupe",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Guadeloupe"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Guadeloupe",
		"countryCode":        "GP",
		"mainCities":         [
			"Les Abymes",
			"Baie-Mahault"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Les Abymes, Baie-Mahault"
	},
	{
		"name":               "America/Blanc-Sablon",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Blanc-Sablon"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Canada",
		"countryCode":        "CA",
		"mainCities":         [
			"Lévis"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Lévis"
	},
	{
		"name":               "America/Marigot",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Marigot"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Saint Martin",
		"countryCode":        "MF",
		"mainCities":         [
			"Marigot"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Marigot"
	},
	{
		"name":               "America/Aruba",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Aruba"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Aruba",
		"countryCode":        "AW",
		"mainCities":         [
			"Oranjestad",
			"Tanki Leendert"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Oranjestad, Tanki Leendert"
	},
	{
		"name":               "America/Lower_Princes",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Lower_Princes"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Sint Maarten",
		"countryCode":        "SX",
		"mainCities":         [
			"Philipsburg"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Philipsburg"
	},
	{
		"name":               "America/Tortola",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Tortola"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "British Virgin Islands",
		"countryCode":        "VG",
		"mainCities":         [
			"Road Town"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Road Town"
	},
	{
		"name":               "America/Dominica",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Dominica"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Dominica",
		"countryCode":        "DM",
		"mainCities":         [
			"Roseau"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Roseau"
	},
	{
		"name":               "America/St_Thomas",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/St_Thomas"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "U.S. Virgin Islands",
		"countryCode":        "VI",
		"mainCities":         [
			"Saint Croix",
			"Charlotte Amalie"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Saint Croix, Charlotte Amalie"
	},
	{
		"name":               "America/Grenada",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Grenada"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Grenada",
		"countryCode":        "GD",
		"mainCities":         [
			"Saint George's"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Saint George's"
	},
	{
		"name":               "America/Antigua",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Antigua"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Antigua and Barbuda",
		"countryCode":        "AG",
		"mainCities":         [
			"Saint John’s"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Saint John’s"
	},
	{
		"name":               "America/Puerto_Rico",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Puerto_Rico"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Puerto Rico",
		"countryCode":        "PR",
		"mainCities":         [
			"San Juan",
			"Bayamón"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - San Juan, Bayamón"
	},
	{
		"name":               "America/Santo_Domingo",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Santo_Domingo"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Dominican Republic",
		"countryCode":        "DO",
		"mainCities":         [
			"Santo Domingo",
			"Santiago de los Caballeros"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Santo Domingo, Santiago de los Caballeros"
	},
	{
		"name":               "America/Anguilla",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Anguilla"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Anguilla",
		"countryCode":        "AI",
		"mainCities":         [
			"The Valley"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - The Valley"
	},
	{
		"name":               "America/Thule",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Thule"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Greenland",
		"countryCode":        "GL",
		"mainCities":         [
			"Thule"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Thule"
	},
	{
		"name":               "America/Curacao",
		"alternativeName":    "Atlantic Time",
		"group":              [
			"America/Curacao"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Curacao",
		"countryCode":        "CW",
		"mainCities":         [
			"Willemstad"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Atlantic Time - Willemstad"
	},
	{
		"name":               "America/La_Paz",
		"alternativeName":    "Bolivia Time",
		"group":              [
			"America/La_Paz"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Bolivia",
		"countryCode":        "BO",
		"mainCities":         [
			"Santa Cruz de la Sierra",
			"Cochabamba"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Bolivia Time - Santa Cruz de la Sierra, Cochabamba"
	},
	{
		"name":               "America/Santiago",
		"alternativeName":    "Chile Time",
		"group":              [
			"America/Santiago"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Chile",
		"countryCode":        "CL",
		"mainCities":         [
			"Santiago",
			"Puente Alto"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Chile Time - Santiago, Puente Alto"
	},
	{
		"name":               "America/Guyana",
		"alternativeName":    "Guyana Time",
		"group":              [
			"America/Guyana"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Guyana",
		"countryCode":        "GY",
		"mainCities":         [
			"Georgetown",
			"Linden"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Guyana Time - Georgetown, Linden"
	},
	{
		"name":               "America/Asuncion",
		"alternativeName":    "Paraguay Time",
		"group":              [
			"America/Asuncion"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Paraguay",
		"countryCode":        "PY",
		"mainCities":         [
			"Asunción",
			"Ciudad del Este"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Paraguay Time - Asunción, Ciudad del Este"
	},
	{
		"name":               "America/Caracas",
		"alternativeName":    "Venezuela Time",
		"group":              [
			"America/Caracas"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Venezuela",
		"countryCode":        "VE",
		"mainCities":         [
			"Caracas",
			"Maracaibo"
		],
		"rawOffsetInMinutes": -240,
		"rawFormat":          "-04:00 Venezuela Time - Caracas, Maracaibo"
	},
	{
		"name":               "America/St_Johns",
		"alternativeName":    "Newfoundland Time",
		"group":              [
			"America/St_Johns"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Canada",
		"countryCode":        "CA",
		"mainCities":         [
			"St. John's",
			"Mount Pearl"
		],
		"rawOffsetInMinutes": -210,
		"rawFormat":          "-03:30 Newfoundland Time - St. John's, Mount Pearl"
	},
	{
		"name":               "America/Argentina/Buenos_Aires",
		"alternativeName":    "Argentina Time",
		"group":              [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Catamarca",
			"America/Argentina/Cordoba",
			"America/Argentina/Jujuy",
			"America/Argentina/La_Rioja",
			"America/Argentina/Mendoza",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Salta",
			"America/Argentina/San_Juan",
			"America/Argentina/San_Luis",
			"America/Argentina/Tucuman",
			"America/Argentina/Ushuaia"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Argentina",
		"countryCode":        "AR",
		"mainCities":         [
			"Buenos Aires",
			"Córdoba"
		],
		"rawOffsetInMinutes": -180,
		"rawFormat":          "-03:00 Argentina Time - Buenos Aires, Córdoba"
	},
	{
		"name":               "America/Sao_Paulo",
		"alternativeName":    "Brasilia Time",
		"group":              [
			"America/Araguaina",
			"America/Bahia",
			"America/Belem",
			"America/Fortaleza",
			"America/Maceio",
			"America/Recife",
			"America/Santarem",
			"America/Sao_Paulo"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Brazil",
		"countryCode":        "BR",
		"mainCities":         [
			"São Paulo",
			"Rio de Janeiro"
		],
		"rawOffsetInMinutes": -180,
		"rawFormat":          "-03:00 Brasilia Time - São Paulo, Rio de Janeiro"
	},
	{
		"name":               "Antarctica/Palmer",
		"alternativeName":    "Chile Time",
		"group":              [
			"Antarctica/Palmer",
			"Antarctica/Rothera"
		],
		"continentCode":      "AN",
		"continentName":      "Antarctica",
		"countryName":        "Antarctica",
		"countryCode":        "AQ",
		"mainCities":         [
			"Palmer",
			"Rothera"
		],
		"rawOffsetInMinutes": -180,
		"rawFormat":          "-03:00 Chile Time - Palmer, Rothera"
	},
	{
		"name":               "America/Punta_Arenas",
		"alternativeName":    "Chile Time",
		"group":              [
			"America/Punta_Arenas"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Chile",
		"countryCode":        "CL",
		"mainCities":         [
			"Punta Arenas",
			"Puerto Natales"
		],
		"rawOffsetInMinutes": -180,
		"rawFormat":          "-03:00 Chile Time - Punta Arenas, Puerto Natales"
	},
	{
		"name":               "Atlantic/Stanley",
		"alternativeName":    "Falkland Islands Time",
		"group":              [
			"Atlantic/Stanley"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Falkland Islands",
		"countryCode":        "FK",
		"mainCities":         [
			"Stanley"
		],
		"rawOffsetInMinutes": -180,
		"rawFormat":          "-03:00 Falkland Islands Time - Stanley"
	},
	{
		"name":               "America/Cayenne",
		"alternativeName":    "French Guiana Time",
		"group":              [
			"America/Cayenne"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "French Guiana",
		"countryCode":        "GF",
		"mainCities":         [
			"Cayenne",
			"Matoury"
		],
		"rawOffsetInMinutes": -180,
		"rawFormat":          "-03:00 French Guiana Time - Cayenne, Matoury"
	},
	{
		"name":               "America/Miquelon",
		"alternativeName":    "St. Pierre & Miquelon Time",
		"group":              [
			"America/Miquelon"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Saint Pierre and Miquelon",
		"countryCode":        "PM",
		"mainCities":         [
			"Saint-Pierre"
		],
		"rawOffsetInMinutes": -180,
		"rawFormat":          "-03:00 St. Pierre & Miquelon Time - Saint-Pierre"
	},
	{
		"name":               "America/Paramaribo",
		"alternativeName":    "Suriname Time",
		"group":              [
			"America/Paramaribo"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Suriname",
		"countryCode":        "SR",
		"mainCities":         [
			"Paramaribo",
			"Lelydorp"
		],
		"rawOffsetInMinutes": -180,
		"rawFormat":          "-03:00 Suriname Time - Paramaribo, Lelydorp"
	},
	{
		"name":               "America/Montevideo",
		"alternativeName":    "Uruguay Time",
		"group":              [
			"America/Montevideo"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Uruguay",
		"countryCode":        "UY",
		"mainCities":         [
			"Montevideo",
			"Salto"
		],
		"rawOffsetInMinutes": -180,
		"rawFormat":          "-03:00 Uruguay Time - Montevideo, Salto"
	},
	{
		"name":               "America/Godthab",
		"alternativeName":    "West Greenland Time",
		"group":              [
			"America/Godthab"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Greenland",
		"countryCode":        "GL",
		"mainCities":         [
			"Nuuk"
		],
		"rawOffsetInMinutes": -180,
		"rawFormat":          "-03:00 West Greenland Time - Nuuk"
	},
	{
		"name":               "America/Noronha",
		"alternativeName":    "Fernando de Noronha Time",
		"group":              [
			"America/Noronha"
		],
		"continentCode":      "SA",
		"continentName":      "South America",
		"countryName":        "Brazil",
		"countryCode":        "BR",
		"mainCities":         [
			"Noronha"
		],
		"rawOffsetInMinutes": -120,
		"rawFormat":          "-02:00 Fernando de Noronha Time - Noronha"
	},
	{
		"name":               "Atlantic/South_Georgia",
		"alternativeName":    "South Georgia Time",
		"group":              [
			"Atlantic/South_Georgia"
		],
		"continentCode":      "AN",
		"continentName":      "Antarctica",
		"countryName":        "South Georgia and the South Sandwich Islands",
		"countryCode":        "GS",
		"mainCities":         [
			"Grytviken"
		],
		"rawOffsetInMinutes": -120,
		"rawFormat":          "-02:00 South Georgia Time - Grytviken"
	},
	{
		"name":               "Atlantic/Azores",
		"alternativeName":    "Azores Time",
		"group":              [
			"Atlantic/Azores"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Portugal",
		"countryCode":        "PT",
		"mainCities":         [
			"Ponta Delgada"
		],
		"rawOffsetInMinutes": -60,
		"rawFormat":          "-01:00 Azores Time - Ponta Delgada"
	},
	{
		"name":               "Atlantic/Cape_Verde",
		"alternativeName":    "Cape Verde Time",
		"group":              [
			"Atlantic/Cape_Verde"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Cabo Verde",
		"countryCode":        "CV",
		"mainCities":         [
			"Praia",
			"Mindelo"
		],
		"rawOffsetInMinutes": -60,
		"rawFormat":          "-01:00 Cape Verde Time - Praia, Mindelo"
	},
	{
		"name":               "America/Scoresbysund",
		"alternativeName":    "East Greenland Time",
		"group":              [
			"America/Scoresbysund"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Greenland",
		"countryCode":        "GL",
		"mainCities":         [
			"Scoresbysund"
		],
		"rawOffsetInMinutes": -60,
		"rawFormat":          "-01:00 East Greenland Time - Scoresbysund"
	},
	{
		"name":               "Africa/Abidjan",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Africa/Abidjan"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Ivory Coast",
		"countryCode":        "CI",
		"mainCities":         [
			"Abidjan",
			"Abobo"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Abidjan, Abobo"
	},
	{
		"name":               "Africa/Accra",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Africa/Accra"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Ghana",
		"countryCode":        "GH",
		"mainCities":         [
			"Accra",
			"Kumasi"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Accra, Kumasi"
	},
	{
		"name":               "Africa/Bamako",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Africa/Bamako"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Mali",
		"countryCode":        "ML",
		"mainCities":         [
			"Bamako",
			"Sikasso"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Bamako, Sikasso"
	},
	{
		"name":               "Africa/Bissau",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Africa/Bissau"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Guinea-Bissau",
		"countryCode":        "GW",
		"mainCities":         [
			"Bissau",
			"Bafatá"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Bissau, Bafatá"
	},
	{
		"name":               "Africa/Conakry",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Africa/Conakry"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Guinea",
		"countryCode":        "GN",
		"mainCities":         [
			"Camayenne",
			"Conakry"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Camayenne, Conakry"
	},
	{
		"name":               "Africa/Dakar",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Africa/Dakar"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Senegal",
		"countryCode":        "SN",
		"mainCities":         [
			"Dakar",
			"Pikine"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Dakar, Pikine"
	},
	{
		"name":               "America/Danmarkshavn",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"America/Danmarkshavn"
		],
		"continentCode":      "NA",
		"continentName":      "North America",
		"countryName":        "Greenland",
		"countryCode":        "GL",
		"mainCities":         [
			"Danmarkshavn"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Danmarkshavn"
	},
	{
		"name":               "Europe/Isle_of_Man",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Europe/Isle_of_Man"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Isle of Man",
		"countryCode":        "IM",
		"mainCities":         [
			"Douglas"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Douglas"
	},
	{
		"name":               "Africa/Freetown",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Africa/Freetown"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Sierra Leone",
		"countryCode":        "SL",
		"mainCities":         [
			"Freetown",
			"Bo"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Freetown, Bo"
	},
	{
		"name":               "Atlantic/St_Helena",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Atlantic/St_Helena"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Saint Helena",
		"countryCode":        "SH",
		"mainCities":         [
			"Jamestown"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Jamestown"
	},
	{
		"name":               "Africa/Lome",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Africa/Lome"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Togo",
		"countryCode":        "TG",
		"mainCities":         [
			"Lomé",
			"Sokodé"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Lomé, Sokodé"
	},
	{
		"name":               "Europe/London",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Europe/London"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "United Kingdom",
		"countryCode":        "GB",
		"mainCities":         [
			"London",
			"Birmingham"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - London, Birmingham"
	},
	{
		"name":               "Africa/Monrovia",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Africa/Monrovia"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Liberia",
		"countryCode":        "LR",
		"mainCities":         [
			"Monrovia",
			"Gbarnga"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Monrovia, Gbarnga"
	},
	{
		"name":               "Africa/Nouakchott",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Africa/Nouakchott"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Mauritania",
		"countryCode":        "MR",
		"mainCities":         [
			"Nouakchott",
			"Nouadhibou"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Nouakchott, Nouadhibou"
	},
	{
		"name":               "Africa/Ouagadougou",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Africa/Ouagadougou"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Burkina Faso",
		"countryCode":        "BF",
		"mainCities":         [
			"Ouagadougou",
			"Bobo-Dioulasso"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Ouagadougou, Bobo-Dioulasso"
	},
	{
		"name":               "Atlantic/Reykjavik",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Atlantic/Reykjavik"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Iceland",
		"countryCode":        "IS",
		"mainCities":         [
			"Reykjavík",
			"Kópavogur"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Reykjavík, Kópavogur"
	},
	{
		"name":               "Europe/Jersey",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Europe/Jersey"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Jersey",
		"countryCode":        "JE",
		"mainCities":         [
			"Saint Helier"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Saint Helier"
	},
	{
		"name":               "Europe/Guernsey",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Europe/Guernsey"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Guernsey",
		"countryCode":        "GG",
		"mainCities":         [
			"Saint Peter Port"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Saint Peter Port"
	},
	{
		"name":               "Africa/Banjul",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Africa/Banjul"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Gambia",
		"countryCode":        "GM",
		"mainCities":         [
			"Serekunda",
			"Brikama"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Serekunda, Brikama"
	},
	{
		"name":               "Africa/Sao_Tome",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Africa/Sao_Tome"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Sao Tome and Principe",
		"countryCode":        "ST",
		"mainCities":         [
			"São Tomé"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - São Tomé"
	},
	{
		"name":               "Antarctica/Troll",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Antarctica/Troll"
		],
		"continentCode":      "AN",
		"continentName":      "Antarctica",
		"countryName":        "Antarctica",
		"countryCode":        "AQ",
		"mainCities":         [
			"Troll"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Greenwich Mean Time - Troll"
	},
	{
		"name":               "Atlantic/Canary",
		"alternativeName":    "Western European Time",
		"group":              [
			"Atlantic/Canary"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Spain",
		"countryCode":        "ES",
		"mainCities":         [
			"Las Palmas de Gran Canaria",
			"Santa Cruz de Tenerife"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Western European Time - Las Palmas de Gran Canaria, Santa Cruz de Tenerife"
	},
	{
		"name":               "Europe/Lisbon",
		"alternativeName":    "Western European Time",
		"group":              [
			"Atlantic/Madeira",
			"Europe/Lisbon"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Portugal",
		"countryCode":        "PT",
		"mainCities":         [
			"Lisbon",
			"Porto"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Western European Time - Lisbon, Porto"
	},
	{
		"name":               "Atlantic/Faroe",
		"alternativeName":    "Western European Time",
		"group":              [
			"Atlantic/Faroe"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Faroe Islands",
		"countryCode":        "FO",
		"mainCities":         [
			"Tórshavn"
		],
		"rawOffsetInMinutes": 0,
		"rawFormat":          "+00:00 Western European Time - Tórshavn"
	},
	{
		"name":               "Africa/Algiers",
		"alternativeName":    "Central European Time",
		"group":              [
			"Africa/Algiers"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Algeria",
		"countryCode":        "DZ",
		"mainCities":         [
			"Algiers",
			"Boumerdas"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Algiers, Boumerdas"
	},
	{
		"name":               "Europe/Amsterdam",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Amsterdam"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Netherlands",
		"countryCode":        "NL",
		"mainCities":         [
			"Amsterdam",
			"Rotterdam"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Amsterdam, Rotterdam"
	},
	{
		"name":               "Europe/Andorra",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Andorra"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Andorra",
		"countryCode":        "AD",
		"mainCities":         [
			"Andorra la Vella",
			"les Escaldes"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Andorra la Vella, les Escaldes"
	},
	{
		"name":               "Europe/Belgrade",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Belgrade"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Serbia",
		"countryCode":        "RS",
		"mainCities":         [
			"Belgrade",
			"Niš"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Belgrade, Niš"
	},
	{
		"name":               "Europe/Berlin",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Berlin",
			"Europe/Busingen"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Germany",
		"countryCode":        "DE",
		"mainCities":         [
			"Berlin",
			"Hamburg"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Berlin, Hamburg"
	},
	{
		"name":               "Europe/Malta",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Malta"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Malta",
		"countryCode":        "MT",
		"mainCities":         [
			"Birkirkara",
			"Qormi"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Birkirkara, Qormi"
	},
	{
		"name":               "Europe/Bratislava",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Bratislava"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Slovakia",
		"countryCode":        "SK",
		"mainCities":         [
			"Bratislava",
			"Košice"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Bratislava, Košice"
	},
	{
		"name":               "Europe/Brussels",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Brussels"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Belgium",
		"countryCode":        "BE",
		"mainCities":         [
			"Brussels",
			"Antwerpen"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Brussels, Antwerpen"
	},
	{
		"name":               "Europe/Budapest",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Budapest"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Hungary",
		"countryCode":        "HU",
		"mainCities":         [
			"Budapest",
			"Debrecen"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Budapest, Debrecen"
	},
	{
		"name":               "Europe/Copenhagen",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Copenhagen"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Denmark",
		"countryCode":        "DK",
		"mainCities":         [
			"Copenhagen",
			"Århus"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Copenhagen, Århus"
	},
	{
		"name":               "Europe/Gibraltar",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Gibraltar"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Gibraltar",
		"countryCode":        "GI",
		"mainCities":         [
			"Gibraltar"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Gibraltar"
	},
	{
		"name":               "Europe/Ljubljana",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Ljubljana"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Slovenia",
		"countryCode":        "SI",
		"mainCities":         [
			"Ljubljana",
			"Maribor"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Ljubljana, Maribor"
	},
	{
		"name":               "Arctic/Longyearbyen",
		"alternativeName":    "Central European Time",
		"group":              [
			"Arctic/Longyearbyen"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Svalbard and Jan Mayen",
		"countryCode":        "SJ",
		"mainCities":         [
			"Longyearbyen"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Longyearbyen"
	},
	{
		"name":               "Europe/Luxembourg",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Luxembourg"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Luxembourg",
		"countryCode":        "LU",
		"mainCities":         [
			"Luxembourg",
			"Esch-sur-Alzette"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Luxembourg, Esch-sur-Alzette"
	},
	{
		"name":               "Europe/Madrid",
		"alternativeName":    "Central European Time",
		"group":              [
			"Africa/Ceuta",
			"Europe/Madrid"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Spain",
		"countryCode":        "ES",
		"mainCities":         [
			"Madrid",
			"Barcelona"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Madrid, Barcelona"
	},
	{
		"name":               "Europe/Monaco",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Monaco"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Monaco",
		"countryCode":        "MC",
		"mainCities":         [
			"Monaco",
			"Monte-Carlo"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Monaco, Monte-Carlo"
	},
	{
		"name":               "Europe/Oslo",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Oslo"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Norway",
		"countryCode":        "NO",
		"mainCities":         [
			"Oslo",
			"Bergen"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Oslo, Bergen"
	},
	{
		"name":               "Europe/Paris",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Paris"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "France",
		"countryCode":        "FR",
		"mainCities":         [
			"Paris",
			"Marseille"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Paris, Marseille"
	},
	{
		"name":               "Europe/Podgorica",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Podgorica"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Montenegro",
		"countryCode":        "ME",
		"mainCities":         [
			"Podgorica",
			"Nikšić"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Podgorica, Nikšić"
	},
	{
		"name":               "Europe/Prague",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Prague"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Czechia",
		"countryCode":        "CZ",
		"mainCities":         [
			"Prague",
			"Brno"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Prague, Brno"
	},
	{
		"name":               "Europe/Rome",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Rome"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Italy",
		"countryCode":        "IT",
		"mainCities":         [
			"Rome",
			"Milan"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Rome, Milan"
	},
	{
		"name":               "Europe/San_Marino",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/San_Marino"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "San Marino",
		"countryCode":        "SM",
		"mainCities":         [
			"San Marino"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - San Marino"
	},
	{
		"name":               "Europe/Sarajevo",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Sarajevo"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Bosnia and Herzegovina",
		"countryCode":        "BA",
		"mainCities":         [
			"Sarajevo",
			"Banja Luka"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Sarajevo, Banja Luka"
	},
	{
		"name":               "Europe/Skopje",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Skopje"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "North Macedonia",
		"countryCode":        "MK",
		"mainCities":         [
			"Skopje",
			"Bitola"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Skopje, Bitola"
	},
	{
		"name":               "Europe/Stockholm",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Stockholm"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Sweden",
		"countryCode":        "SE",
		"mainCities":         [
			"Stockholm",
			"Göteborg"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Stockholm, Göteborg"
	},
	{
		"name":               "Europe/Tirane",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Tirane"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Albania",
		"countryCode":        "AL",
		"mainCities":         [
			"Tirana",
			"Durrës"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Tirana, Durrës"
	},
	{
		"name":               "Africa/Tunis",
		"alternativeName":    "Central European Time",
		"group":              [
			"Africa/Tunis"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Tunisia",
		"countryCode":        "TN",
		"mainCities":         [
			"Tunis",
			"Sfax"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Tunis, Sfax"
	},
	{
		"name":               "Europe/Vaduz",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Vaduz"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Liechtenstein",
		"countryCode":        "LI",
		"mainCities":         [
			"Vaduz"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Vaduz"
	},
	{
		"name":               "Europe/Vatican",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Vatican"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Vatican",
		"countryCode":        "VA",
		"mainCities":         [
			"Vatican City"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Vatican City"
	},
	{
		"name":               "Europe/Vienna",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Vienna"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Austria",
		"countryCode":        "AT",
		"mainCities":         [
			"Vienna",
			"Graz"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Vienna, Graz"
	},
	{
		"name":               "Europe/Warsaw",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Warsaw"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Poland",
		"countryCode":        "PL",
		"mainCities":         [
			"Warsaw",
			"Łódź"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Warsaw, Łódź"
	},
	{
		"name":               "Europe/Zagreb",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Zagreb"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Croatia",
		"countryCode":        "HR",
		"mainCities":         [
			"Zagreb",
			"Split"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Zagreb, Split"
	},
	{
		"name":               "Europe/Zurich",
		"alternativeName":    "Central European Time",
		"group":              [
			"Europe/Zurich"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Switzerland",
		"countryCode":        "CH",
		"mainCities":         [
			"Zürich",
			"Genève"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Central European Time - Zürich, Genève"
	},
	{
		"name":               "Europe/Dublin",
		"alternativeName":    "Greenwich Mean Time",
		"group":              [
			"Europe/Dublin"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Ireland",
		"countryCode":        "IE",
		"mainCities":         [
			"Dublin",
			"Cork"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Greenwich Mean Time - Dublin, Cork"
	},
	{
		"name":               "Africa/Bangui",
		"alternativeName":    "West Africa Time",
		"group":              [
			"Africa/Bangui"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Central African Republic",
		"countryCode":        "CF",
		"mainCities":         [
			"Bangui",
			"Bimbo"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 West Africa Time - Bangui, Bimbo"
	},
	{
		"name":               "Africa/Malabo",
		"alternativeName":    "West Africa Time",
		"group":              [
			"Africa/Malabo"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Equatorial Guinea",
		"countryCode":        "GQ",
		"mainCities":         [
			"Bata",
			"Malabo"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 West Africa Time - Bata, Malabo"
	},
	{
		"name":               "Africa/Brazzaville",
		"alternativeName":    "West Africa Time",
		"group":              [
			"Africa/Brazzaville"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Republic of the Congo",
		"countryCode":        "CG",
		"mainCities":         [
			"Brazzaville",
			"Pointe-Noire"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 West Africa Time - Brazzaville, Pointe-Noire"
	},
	{
		"name":               "Africa/Porto-Novo",
		"alternativeName":    "West Africa Time",
		"group":              [
			"Africa/Porto-Novo"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Benin",
		"countryCode":        "BJ",
		"mainCities":         [
			"Cotonou",
			"Abomey-Calavi"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 West Africa Time - Cotonou, Abomey-Calavi"
	},
	{
		"name":               "Africa/Douala",
		"alternativeName":    "West Africa Time",
		"group":              [
			"Africa/Douala"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Cameroon",
		"countryCode":        "CM",
		"mainCities":         [
			"Douala",
			"Yaoundé"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 West Africa Time - Douala, Yaoundé"
	},
	{
		"name":               "Africa/Kinshasa",
		"alternativeName":    "West Africa Time",
		"group":              [
			"Africa/Kinshasa"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Democratic Republic of the Congo",
		"countryCode":        "CD",
		"mainCities":         [
			"Kinshasa",
			"Masina"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 West Africa Time - Kinshasa, Masina"
	},
	{
		"name":               "Africa/Lagos",
		"alternativeName":    "West Africa Time",
		"group":              [
			"Africa/Lagos"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Nigeria",
		"countryCode":        "NG",
		"mainCities":         [
			"Lagos",
			"Kano"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 West Africa Time - Lagos, Kano"
	},
	{
		"name":               "Africa/Libreville",
		"alternativeName":    "West Africa Time",
		"group":              [
			"Africa/Libreville"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Gabon",
		"countryCode":        "GA",
		"mainCities":         [
			"Libreville",
			"Port-Gentil"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 West Africa Time - Libreville, Port-Gentil"
	},
	{
		"name":               "Africa/Luanda",
		"alternativeName":    "West Africa Time",
		"group":              [
			"Africa/Luanda"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Angola",
		"countryCode":        "AO",
		"mainCities":         [
			"Luanda",
			"N’dalatando"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 West Africa Time - Luanda, N’dalatando"
	},
	{
		"name":               "Africa/Ndjamena",
		"alternativeName":    "West Africa Time",
		"group":              [
			"Africa/Ndjamena"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Chad",
		"countryCode":        "TD",
		"mainCities":         [
			"N'Djamena",
			"Moundou"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 West Africa Time - N'Djamena, Moundou"
	},
	{
		"name":               "Africa/Niamey",
		"alternativeName":    "West Africa Time",
		"group":              [
			"Africa/Niamey"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Niger",
		"countryCode":        "NE",
		"mainCities":         [
			"Niamey",
			"Zinder"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 West Africa Time - Niamey, Zinder"
	},
	{
		"name":               "Africa/Casablanca",
		"alternativeName":    "Western European Time",
		"group":              [
			"Africa/Casablanca"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Morocco",
		"countryCode":        "MA",
		"mainCities":         [
			"Casablanca",
			"Rabat"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Western European Time - Casablanca, Rabat"
	},
	{
		"name":               "Africa/El_Aaiun",
		"alternativeName":    "Western European Time",
		"group":              [
			"Africa/El_Aaiun"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Western Sahara",
		"countryCode":        "EH",
		"mainCities":         [
			"Laayoune",
			"Dakhla"
		],
		"rawOffsetInMinutes": 60,
		"rawFormat":          "+01:00 Western European Time - Laayoune, Dakhla"
	},
	{
		"name":               "Africa/Bujumbura",
		"alternativeName":    "Central Africa Time",
		"group":              [
			"Africa/Bujumbura"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Burundi",
		"countryCode":        "BI",
		"mainCities":         [
			"Bujumbura",
			"Muyinga"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Central Africa Time - Bujumbura, Muyinga"
	},
	{
		"name":               "Africa/Gaborone",
		"alternativeName":    "Central Africa Time",
		"group":              [
			"Africa/Gaborone"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Botswana",
		"countryCode":        "BW",
		"mainCities":         [
			"Gaborone",
			"Francistown"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Central Africa Time - Gaborone, Francistown"
	},
	{
		"name":               "Africa/Harare",
		"alternativeName":    "Central Africa Time",
		"group":              [
			"Africa/Harare"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Zimbabwe",
		"countryCode":        "ZW",
		"mainCities":         [
			"Harare",
			"Bulawayo"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Central Africa Time - Harare, Bulawayo"
	},
	{
		"name":               "Africa/Khartoum",
		"alternativeName":    "Central Africa Time",
		"group":              [
			"Africa/Khartoum"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Sudan",
		"countryCode":        "SD",
		"mainCities":         [
			"Khartoum",
			"Omdurman"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Central Africa Time - Khartoum, Omdurman"
	},
	{
		"name":               "Africa/Kigali",
		"alternativeName":    "Central Africa Time",
		"group":              [
			"Africa/Kigali"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Rwanda",
		"countryCode":        "RW",
		"mainCities":         [
			"Kigali",
			"Butare"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Central Africa Time - Kigali, Butare"
	},
	{
		"name":               "Africa/Blantyre",
		"alternativeName":    "Central Africa Time",
		"group":              [
			"Africa/Blantyre"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Malawi",
		"countryCode":        "MW",
		"mainCities":         [
			"Lilongwe",
			"Blantyre"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Central Africa Time - Lilongwe, Blantyre"
	},
	{
		"name":               "Africa/Lubumbashi",
		"alternativeName":    "Central Africa Time",
		"group":              [
			"Africa/Lubumbashi"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Democratic Republic of the Congo",
		"countryCode":        "CD",
		"mainCities":         [
			"Lubumbashi",
			"Mbuji-Mayi"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Central Africa Time - Lubumbashi, Mbuji-Mayi"
	},
	{
		"name":               "Africa/Lusaka",
		"alternativeName":    "Central Africa Time",
		"group":              [
			"Africa/Lusaka"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Zambia",
		"countryCode":        "ZM",
		"mainCities":         [
			"Lusaka",
			"Kitwe"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Central Africa Time - Lusaka, Kitwe"
	},
	{
		"name":               "Africa/Maputo",
		"alternativeName":    "Central Africa Time",
		"group":              [
			"Africa/Maputo"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Mozambique",
		"countryCode":        "MZ",
		"mainCities":         [
			"Maputo",
			"Matola"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Central Africa Time - Maputo, Matola"
	},
	{
		"name":               "Africa/Windhoek",
		"alternativeName":    "Central Africa Time",
		"group":              [
			"Africa/Windhoek"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Namibia",
		"countryCode":        "NA",
		"mainCities":         [
			"Windhoek",
			"Rundu"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Central Africa Time - Windhoek, Rundu"
	},
	{
		"name":               "Asia/Damascus",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Asia/Damascus"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Syria",
		"countryCode":        "SY",
		"mainCities":         [
			"Aleppo",
			"Damascus"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Aleppo, Damascus"
	},
	{
		"name":               "Asia/Amman",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Asia/Amman"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Jordan",
		"countryCode":        "JO",
		"mainCities":         [
			"Amman",
			"Zarqa"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Amman, Zarqa"
	},
	{
		"name":               "Europe/Athens",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Europe/Athens"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Greece",
		"countryCode":        "GR",
		"mainCities":         [
			"Athens",
			"Thessaloníki"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Athens, Thessaloníki"
	},
	{
		"name":               "Asia/Beirut",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Asia/Beirut"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Lebanon",
		"countryCode":        "LB",
		"mainCities":         [
			"Beirut",
			"Ra’s Bayrūt"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Beirut, Ra’s Bayrūt"
	},
	{
		"name":               "Europe/Bucharest",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Europe/Bucharest"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Romania",
		"countryCode":        "RO",
		"mainCities":         [
			"Bucharest",
			"Sector 3"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Bucharest, Sector 3"
	},
	{
		"name":               "Africa/Cairo",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Africa/Cairo"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Egypt",
		"countryCode":        "EG",
		"mainCities":         [
			"Cairo",
			"Alexandria"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Cairo, Alexandria"
	},
	{
		"name":               "Europe/Chisinau",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Europe/Chisinau"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Moldova",
		"countryCode":        "MD",
		"mainCities":         [
			"Chisinau",
			"Tiraspol"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Chisinau, Tiraspol"
	},
	{
		"name":               "Asia/Hebron",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Asia/Gaza",
			"Asia/Hebron"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Palestinian Territory",
		"countryCode":        "PS",
		"mainCities":         [
			"East Jerusalem",
			"Gaza"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - East Jerusalem, Gaza"
	},
	{
		"name":               "Europe/Helsinki",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Europe/Helsinki"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Finland",
		"countryCode":        "FI",
		"mainCities":         [
			"Helsinki",
			"Espoo"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Helsinki, Espoo"
	},
	{
		"name":               "Europe/Kaliningrad",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Europe/Kaliningrad"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Russia",
		"countryCode":        "RU",
		"mainCities":         [
			"Kaliningrad",
			"Chernyakhovsk"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Kaliningrad, Chernyakhovsk"
	},
	{
		"name":               "Europe/Kiev",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Europe/Kiev",
			"Europe/Uzhgorod",
			"Europe/Zaporozhye"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Ukraine",
		"countryCode":        "UA",
		"mainCities":         [
			"Kyiv",
			"Kharkiv"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Kyiv, Kharkiv"
	},
	{
		"name":               "Europe/Mariehamn",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Europe/Mariehamn"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Aland Islands",
		"countryCode":        "AX",
		"mainCities":         [
			"Mariehamn"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Mariehamn"
	},
	{
		"name":               "Asia/Nicosia",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Asia/Famagusta",
			"Asia/Nicosia"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Cyprus",
		"countryCode":        "CY",
		"mainCities":         [
			"Nicosia",
			"Limassol"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Nicosia, Limassol"
	},
	{
		"name":               "Europe/Riga",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Europe/Riga"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Latvia",
		"countryCode":        "LV",
		"mainCities":         [
			"Riga",
			"Daugavpils"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Riga, Daugavpils"
	},
	{
		"name":               "Europe/Sofia",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Europe/Sofia"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Bulgaria",
		"countryCode":        "BG",
		"mainCities":         [
			"Sofia",
			"Plovdiv"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Sofia, Plovdiv"
	},
	{
		"name":               "Europe/Tallinn",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Europe/Tallinn"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Estonia",
		"countryCode":        "EE",
		"mainCities":         [
			"Tallinn",
			"Tartu"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Tallinn, Tartu"
	},
	{
		"name":               "Africa/Tripoli",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Africa/Tripoli"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Libya",
		"countryCode":        "LY",
		"mainCities":         [
			"Tripoli",
			"Benghazi"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Tripoli, Benghazi"
	},
	{
		"name":               "Europe/Vilnius",
		"alternativeName":    "Eastern European Time",
		"group":              [
			"Europe/Vilnius"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Lithuania",
		"countryCode":        "LT",
		"mainCities":         [
			"Vilnius",
			"Kaunas"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Eastern European Time - Vilnius, Kaunas"
	},
	{
		"name":               "Asia/Jerusalem",
		"alternativeName":    "Israel Time",
		"group":              [
			"Asia/Jerusalem"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Israel",
		"countryCode":        "IL",
		"mainCities":         [
			"Jerusalem",
			"Tel Aviv"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 Israel Time - Jerusalem, Tel Aviv"
	},
	{
		"name":               "Africa/Johannesburg",
		"alternativeName":    "South Africa Time",
		"group":              [
			"Africa/Johannesburg"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "South Africa",
		"countryCode":        "ZA",
		"mainCities":         [
			"Cape Town",
			"Durban"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 South Africa Time - Cape Town, Durban"
	},
	{
		"name":               "Africa/Mbabane",
		"alternativeName":    "South Africa Time",
		"group":              [
			"Africa/Mbabane"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Eswatini",
		"countryCode":        "SZ",
		"mainCities":         [
			"Manzini",
			"Mbabane"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 South Africa Time - Manzini, Mbabane"
	},
	{
		"name":               "Africa/Maseru",
		"alternativeName":    "South Africa Time",
		"group":              [
			"Africa/Maseru"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Lesotho",
		"countryCode":        "LS",
		"mainCities":         [
			"Maseru",
			"Mafeteng"
		],
		"rawOffsetInMinutes": 120,
		"rawFormat":          "+02:00 South Africa Time - Maseru, Mafeteng"
	},
	{
		"name":               "Asia/Kuwait",
		"alternativeName":    "Arabian Time",
		"group":              [
			"Asia/Kuwait"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Kuwait",
		"countryCode":        "KW",
		"mainCities":         [
			"Al Aḩmadī",
			"Ḩawallī"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 Arabian Time - Al Aḩmadī, Ḩawallī"
	},
	{
		"name":               "Asia/Baghdad",
		"alternativeName":    "Arabian Time",
		"group":              [
			"Asia/Baghdad"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Iraq",
		"countryCode":        "IQ",
		"mainCities":         [
			"Baghdad",
			"Basrah"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 Arabian Time - Baghdad, Basrah"
	},
	{
		"name":               "Asia/Qatar",
		"alternativeName":    "Arabian Time",
		"group":              [
			"Asia/Qatar"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Qatar",
		"countryCode":        "QA",
		"mainCities":         [
			"Doha",
			"Ar Rayyān"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 Arabian Time - Doha, Ar Rayyān"
	},
	{
		"name":               "Asia/Bahrain",
		"alternativeName":    "Arabian Time",
		"group":              [
			"Asia/Bahrain"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Bahrain",
		"countryCode":        "BH",
		"mainCities":         [
			"Manama",
			"Al Muharraq"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 Arabian Time - Manama, Al Muharraq"
	},
	{
		"name":               "Asia/Riyadh",
		"alternativeName":    "Arabian Time",
		"group":              [
			"Asia/Riyadh"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Saudi Arabia",
		"countryCode":        "SA",
		"mainCities":         [
			"Riyadh",
			"Jeddah"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 Arabian Time - Riyadh, Jeddah"
	},
	{
		"name":               "Asia/Aden",
		"alternativeName":    "Arabian Time",
		"group":              [
			"Asia/Aden"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Yemen",
		"countryCode":        "YE",
		"mainCities":         [
			"Sanaa",
			"Al Ḩudaydah"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 Arabian Time - Sanaa, Al Ḩudaydah"
	},
	{
		"name":               "Africa/Addis_Ababa",
		"alternativeName":    "East Africa Time",
		"group":              [
			"Africa/Addis_Ababa"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Ethiopia",
		"countryCode":        "ET",
		"mainCities":         [
			"Addis Ababa",
			"Dire Dawa"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 East Africa Time - Addis Ababa, Dire Dawa"
	},
	{
		"name":               "Indian/Antananarivo",
		"alternativeName":    "East Africa Time",
		"group":              [
			"Indian/Antananarivo"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Madagascar",
		"countryCode":        "MG",
		"mainCities":         [
			"Antananarivo",
			"Toamasina"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 East Africa Time - Antananarivo, Toamasina"
	},
	{
		"name":               "Africa/Asmara",
		"alternativeName":    "East Africa Time",
		"group":              [
			"Africa/Asmara"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Eritrea",
		"countryCode":        "ER",
		"mainCities":         [
			"Asmara",
			"Keren"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 East Africa Time - Asmara, Keren"
	},
	{
		"name":               "Africa/Dar_es_Salaam",
		"alternativeName":    "East Africa Time",
		"group":              [
			"Africa/Dar_es_Salaam"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Tanzania",
		"countryCode":        "TZ",
		"mainCities":         [
			"Dar es Salaam",
			"Mwanza"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 East Africa Time - Dar es Salaam, Mwanza"
	},
	{
		"name":               "Africa/Djibouti",
		"alternativeName":    "East Africa Time",
		"group":              [
			"Africa/Djibouti"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Djibouti",
		"countryCode":        "DJ",
		"mainCities":         [
			"Djibouti",
			"'Ali Sabieh"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 East Africa Time - Djibouti, 'Ali Sabieh"
	},
	{
		"name":               "Africa/Juba",
		"alternativeName":    "East Africa Time",
		"group":              [
			"Africa/Juba"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "South Sudan",
		"countryCode":        "SS",
		"mainCities":         [
			"Juba",
			"Winejok"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 East Africa Time - Juba, Winejok"
	},
	{
		"name":               "Africa/Kampala",
		"alternativeName":    "East Africa Time",
		"group":              [
			"Africa/Kampala"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Uganda",
		"countryCode":        "UG",
		"mainCities":         [
			"Kampala",
			"Gulu"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 East Africa Time - Kampala, Gulu"
	},
	{
		"name":               "Indian/Mayotte",
		"alternativeName":    "East Africa Time",
		"group":              [
			"Indian/Mayotte"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Mayotte",
		"countryCode":        "YT",
		"mainCities":         [
			"Mamoudzou",
			"Koungou"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 East Africa Time - Mamoudzou, Koungou"
	},
	{
		"name":               "Africa/Mogadishu",
		"alternativeName":    "East Africa Time",
		"group":              [
			"Africa/Mogadishu"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Somalia",
		"countryCode":        "SO",
		"mainCities":         [
			"Mogadishu",
			"Hargeysa"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 East Africa Time - Mogadishu, Hargeysa"
	},
	{
		"name":               "Indian/Comoro",
		"alternativeName":    "East Africa Time",
		"group":              [
			"Indian/Comoro"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Comoros",
		"countryCode":        "KM",
		"mainCities":         [
			"Moroni",
			"Moutsamoudou"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 East Africa Time - Moroni, Moutsamoudou"
	},
	{
		"name":               "Africa/Nairobi",
		"alternativeName":    "East Africa Time",
		"group":              [
			"Africa/Nairobi"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Kenya",
		"countryCode":        "KE",
		"mainCities":         [
			"Nairobi",
			"Mombasa"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 East Africa Time - Nairobi, Mombasa"
	},
	{
		"name":               "Europe/Minsk",
		"alternativeName":    "Moscow Time",
		"group":              [
			"Europe/Minsk"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Belarus",
		"countryCode":        "BY",
		"mainCities":         [
			"Minsk",
			"Homyel'"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 Moscow Time - Minsk, Homyel'"
	},
	{
		"name":               "Europe/Moscow",
		"alternativeName":    "Moscow Time",
		"group":              [
			"Europe/Kirov",
			"Europe/Moscow",
			"Europe/Simferopol"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Russia",
		"countryCode":        "RU",
		"mainCities":         [
			"Moscow",
			"Saint Petersburg"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 Moscow Time - Moscow, Saint Petersburg"
	},
	{
		"name":               "Antarctica/Syowa",
		"alternativeName":    "Syowa Time",
		"group":              [
			"Antarctica/Syowa"
		],
		"continentCode":      "AN",
		"continentName":      "Antarctica",
		"countryName":        "Antarctica",
		"countryCode":        "AQ",
		"mainCities":         [
			"Syowa"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 Syowa Time - Syowa"
	},
	{
		"name":               "Europe/Istanbul",
		"alternativeName":    "Turkey Time",
		"group":              [
			"Europe/Istanbul"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Turkey",
		"countryCode":        "TR",
		"mainCities":         [
			"Istanbul",
			"Ankara"
		],
		"rawOffsetInMinutes": 180,
		"rawFormat":          "+03:00 Turkey Time - Istanbul, Ankara"
	},
	{
		"name":               "Asia/Tehran",
		"alternativeName":    "Iran Time",
		"group":              [
			"Asia/Tehran"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Iran",
		"countryCode":        "IR",
		"mainCities":         [
			"Tehran",
			"Mashhad"
		],
		"rawOffsetInMinutes": 210,
		"rawFormat":          "+03:30 Iran Time - Tehran, Mashhad"
	},
	{
		"name":               "Asia/Yerevan",
		"alternativeName":    "Armenia Time",
		"group":              [
			"Asia/Yerevan"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Armenia",
		"countryCode":        "AM",
		"mainCities":         [
			"Yerevan",
			"Gyumri"
		],
		"rawOffsetInMinutes": 240,
		"rawFormat":          "+04:00 Armenia Time - Yerevan, Gyumri"
	},
	{
		"name":               "Asia/Baku",
		"alternativeName":    "Azerbaijan Time",
		"group":              [
			"Asia/Baku"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Azerbaijan",
		"countryCode":        "AZ",
		"mainCities":         [
			"Baku",
			"Ganja"
		],
		"rawOffsetInMinutes": 240,
		"rawFormat":          "+04:00 Azerbaijan Time - Baku, Ganja"
	},
	{
		"name":               "Asia/Tbilisi",
		"alternativeName":    "Georgia Time",
		"group":              [
			"Asia/Tbilisi"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Georgia",
		"countryCode":        "GE",
		"mainCities":         [
			"Tbilisi",
			"Kutaisi"
		],
		"rawOffsetInMinutes": 240,
		"rawFormat":          "+04:00 Georgia Time - Tbilisi, Kutaisi"
	},
	{
		"name":               "Asia/Dubai",
		"alternativeName":    "Gulf Time",
		"group":              [
			"Asia/Dubai"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "United Arab Emirates",
		"countryCode":        "AE",
		"mainCities":         [
			"Dubai",
			"Sharjah"
		],
		"rawOffsetInMinutes": 240,
		"rawFormat":          "+04:00 Gulf Time - Dubai, Sharjah"
	},
	{
		"name":               "Asia/Muscat",
		"alternativeName":    "Gulf Time",
		"group":              [
			"Asia/Muscat"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Oman",
		"countryCode":        "OM",
		"mainCities":         [
			"Muscat",
			"Seeb"
		],
		"rawOffsetInMinutes": 240,
		"rawFormat":          "+04:00 Gulf Time - Muscat, Seeb"
	},
	{
		"name":               "Indian/Mauritius",
		"alternativeName":    "Mauritius Time",
		"group":              [
			"Indian/Mauritius"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Mauritius",
		"countryCode":        "MU",
		"mainCities":         [
			"Port Louis",
			"Beau Bassin-Rose Hill"
		],
		"rawOffsetInMinutes": 240,
		"rawFormat":          "+04:00 Mauritius Time - Port Louis, Beau Bassin-Rose Hill"
	},
	{
		"name":               "Indian/Reunion",
		"alternativeName":    "Réunion Time",
		"group":              [
			"Indian/Reunion"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Reunion",
		"countryCode":        "RE",
		"mainCities":         [
			"Saint-Denis",
			"Saint-Paul"
		],
		"rawOffsetInMinutes": 240,
		"rawFormat":          "+04:00 Réunion Time - Saint-Denis, Saint-Paul"
	},
	{
		"name":               "Europe/Samara",
		"alternativeName":    "Samara Time",
		"group":              [
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Saratov",
			"Europe/Ulyanovsk",
			"Europe/Volgograd"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Russia",
		"countryCode":        "RU",
		"mainCities":         [
			"Samara",
			"Volgograd"
		],
		"rawOffsetInMinutes": 240,
		"rawFormat":          "+04:00 Samara Time - Samara, Volgograd"
	},
	{
		"name":               "Indian/Mahe",
		"alternativeName":    "Seychelles Time",
		"group":              [
			"Indian/Mahe"
		],
		"continentCode":      "AF",
		"continentName":      "Africa",
		"countryName":        "Seychelles",
		"countryCode":        "SC",
		"mainCities":         [
			"Victoria"
		],
		"rawOffsetInMinutes": 240,
		"rawFormat":          "+04:00 Seychelles Time - Victoria"
	},
	{
		"name":               "Asia/Kabul",
		"alternativeName":    "Afghanistan Time",
		"group":              [
			"Asia/Kabul"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Afghanistan",
		"countryCode":        "AF",
		"mainCities":         [
			"Kabul",
			"Kandahār"
		],
		"rawOffsetInMinutes": 270,
		"rawFormat":          "+04:30 Afghanistan Time - Kabul, Kandahār"
	},
	{
		"name":               "Indian/Kerguelen",
		"alternativeName":    "French Southern & Antarctic Time",
		"group":              [
			"Indian/Kerguelen"
		],
		"continentCode":      "AN",
		"continentName":      "Antarctica",
		"countryName":        "French Southern Territories",
		"countryCode":        "TF",
		"mainCities":         [
			"Port-aux-Français"
		],
		"rawOffsetInMinutes": 300,
		"rawFormat":          "+05:00 French Southern & Antarctic Time - Port-aux-Français"
	},
	{
		"name":               "Indian/Maldives",
		"alternativeName":    "Maldives Time",
		"group":              [
			"Indian/Maldives"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Maldives",
		"countryCode":        "MV",
		"mainCities":         [
			"Male"
		],
		"rawOffsetInMinutes": 300,
		"rawFormat":          "+05:00 Maldives Time - Male"
	},
	{
		"name":               "Antarctica/Mawson",
		"alternativeName":    "Mawson Time",
		"group":              [
			"Antarctica/Mawson"
		],
		"continentCode":      "AN",
		"continentName":      "Antarctica",
		"countryName":        "Antarctica",
		"countryCode":        "AQ",
		"mainCities":         [
			"Mawson"
		],
		"rawOffsetInMinutes": 300,
		"rawFormat":          "+05:00 Mawson Time - Mawson"
	},
	{
		"name":               "Asia/Karachi",
		"alternativeName":    "Pakistan Time",
		"group":              [
			"Asia/Karachi"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Pakistan",
		"countryCode":        "PK",
		"mainCities":         [
			"Karachi",
			"Lahore"
		],
		"rawOffsetInMinutes": 300,
		"rawFormat":          "+05:00 Pakistan Time - Karachi, Lahore"
	},
	{
		"name":               "Asia/Dushanbe",
		"alternativeName":    "Tajikistan Time",
		"group":              [
			"Asia/Dushanbe"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Tajikistan",
		"countryCode":        "TJ",
		"mainCities":         [
			"Dushanbe",
			"Khujand"
		],
		"rawOffsetInMinutes": 300,
		"rawFormat":          "+05:00 Tajikistan Time - Dushanbe, Khujand"
	},
	{
		"name":               "Asia/Ashgabat",
		"alternativeName":    "Turkmenistan Time",
		"group":              [
			"Asia/Ashgabat"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Turkmenistan",
		"countryCode":        "TM",
		"mainCities":         [
			"Ashgabat",
			"Türkmenabat"
		],
		"rawOffsetInMinutes": 300,
		"rawFormat":          "+05:00 Turkmenistan Time - Ashgabat, Türkmenabat"
	},
	{
		"name":               "Asia/Tashkent",
		"alternativeName":    "Uzbekistan Time",
		"group":              [
			"Asia/Samarkand",
			"Asia/Tashkent"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Uzbekistan",
		"countryCode":        "UZ",
		"mainCities":         [
			"Tashkent",
			"Namangan"
		],
		"rawOffsetInMinutes": 300,
		"rawFormat":          "+05:00 Uzbekistan Time - Tashkent, Namangan"
	},
	{
		"name":               "Asia/Qyzylorda",
		"alternativeName":    "West Kazakhstan Time",
		"group":              [
			"Asia/Aqtau",
			"Asia/Aqtobe",
			"Asia/Atyrau",
			"Asia/Oral",
			"Asia/Qyzylorda"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Kazakhstan",
		"countryCode":        "KZ",
		"mainCities":         [
			"Kyzylorda",
			"Aktobe"
		],
		"rawOffsetInMinutes": 300,
		"rawFormat":          "+05:00 West Kazakhstan Time - Kyzylorda, Aktobe"
	},
	{
		"name":               "Asia/Yekaterinburg",
		"alternativeName":    "Yekaterinburg Time",
		"group":              [
			"Asia/Yekaterinburg"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Russia",
		"countryCode":        "RU",
		"mainCities":         [
			"Yekaterinburg",
			"Chelyabinsk"
		],
		"rawOffsetInMinutes": 300,
		"rawFormat":          "+05:00 Yekaterinburg Time - Yekaterinburg, Chelyabinsk"
	},
	{
		"name":               "Asia/Colombo",
		"alternativeName":    "India Time",
		"group":              [
			"Asia/Colombo"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Sri Lanka",
		"countryCode":        "LK",
		"mainCities":         [
			"Colombo",
			"Dehiwala-Mount Lavinia"
		],
		"rawOffsetInMinutes": 330,
		"rawFormat":          "+05:30 India Time - Colombo, Dehiwala-Mount Lavinia"
	},
	{
		"name":               "Asia/Kolkata",
		"alternativeName":    "India Time",
		"group":              [
			"Asia/Kolkata"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "India",
		"countryCode":        "IN",
		"mainCities":         [
			"Mumbai",
			"Delhi"
		],
		"rawOffsetInMinutes": 330,
		"rawFormat":          "+05:30 India Time - Mumbai, Delhi"
	},
	{
		"name":               "Asia/Kathmandu",
		"alternativeName":    "Nepal Time",
		"group":              [
			"Asia/Kathmandu"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Nepal",
		"countryCode":        "NP",
		"mainCities":         [
			"Kathmandu",
			"Pokhara"
		],
		"rawOffsetInMinutes": 345,
		"rawFormat":          "+05:45 Nepal Time - Kathmandu, Pokhara"
	},
	{
		"name":               "Asia/Dhaka",
		"alternativeName":    "Bangladesh Time",
		"group":              [
			"Asia/Dhaka"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Bangladesh",
		"countryCode":        "BD",
		"mainCities":         [
			"Dhaka",
			"Chattogram"
		],
		"rawOffsetInMinutes": 360,
		"rawFormat":          "+06:00 Bangladesh Time - Dhaka, Chattogram"
	},
	{
		"name":               "Asia/Thimphu",
		"alternativeName":    "Bhutan Time",
		"group":              [
			"Asia/Thimphu"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Bhutan",
		"countryCode":        "BT",
		"mainCities":         [
			"Thimphu",
			"Punākha"
		],
		"rawOffsetInMinutes": 360,
		"rawFormat":          "+06:00 Bhutan Time - Thimphu, Punākha"
	},
	{
		"name":               "Asia/Urumqi",
		"alternativeName":    "China Time",
		"group":              [
			"Asia/Urumqi"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "China",
		"countryCode":        "CN",
		"mainCities":         [
			"Zhongshan",
			"Ürümqi"
		],
		"rawOffsetInMinutes": 360,
		"rawFormat":          "+06:00 China Time - Zhongshan, Ürümqi"
	},
	{
		"name":               "Asia/Almaty",
		"alternativeName":    "East Kazakhstan Time",
		"group":              [
			"Asia/Almaty",
			"Asia/Qostanay"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Kazakhstan",
		"countryCode":        "KZ",
		"mainCities":         [
			"Almaty",
			"Karagandy"
		],
		"rawOffsetInMinutes": 360,
		"rawFormat":          "+06:00 East Kazakhstan Time - Almaty, Karagandy"
	},
	{
		"name":               "Indian/Chagos",
		"alternativeName":    "Indian Ocean Time",
		"group":              [
			"Indian/Chagos"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "British Indian Ocean Territory",
		"countryCode":        "IO",
		"mainCities":         [
			"Chagos"
		],
		"rawOffsetInMinutes": 360,
		"rawFormat":          "+06:00 Indian Ocean Time - Chagos"
	},
	{
		"name":               "Asia/Bishkek",
		"alternativeName":    "Kyrgyzstan Time",
		"group":              [
			"Asia/Bishkek"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Kyrgyzstan",
		"countryCode":        "KG",
		"mainCities":         [
			"Bishkek",
			"Osh"
		],
		"rawOffsetInMinutes": 360,
		"rawFormat":          "+06:00 Kyrgyzstan Time - Bishkek, Osh"
	},
	{
		"name":               "Asia/Omsk",
		"alternativeName":    "Omsk Time",
		"group":              [
			"Asia/Omsk"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Russia",
		"countryCode":        "RU",
		"mainCities":         [
			"Omsk",
			"Tara"
		],
		"rawOffsetInMinutes": 360,
		"rawFormat":          "+06:00 Omsk Time - Omsk, Tara"
	},
	{
		"name":               "Antarctica/Vostok",
		"alternativeName":    "Vostok Time",
		"group":              [
			"Antarctica/Vostok"
		],
		"continentCode":      "AN",
		"continentName":      "Antarctica",
		"countryName":        "Antarctica",
		"countryCode":        "AQ",
		"mainCities":         [
			"Vostok"
		],
		"rawOffsetInMinutes": 360,
		"rawFormat":          "+06:00 Vostok Time - Vostok"
	},
	{
		"name":               "Indian/Cocos",
		"alternativeName":    "Cocos Islands Time",
		"group":              [
			"Indian/Cocos"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Cocos Islands",
		"countryCode":        "CC",
		"mainCities":         [
			"West Island"
		],
		"rawOffsetInMinutes": 390,
		"rawFormat":          "+06:30 Cocos Islands Time - West Island"
	},
	{
		"name":               "Asia/Yangon",
		"alternativeName":    "Myanmar Time",
		"group":              [
			"Asia/Yangon"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Myanmar",
		"countryCode":        "MM",
		"mainCities":         [
			"Yangon",
			"Mandalay"
		],
		"rawOffsetInMinutes": 390,
		"rawFormat":          "+06:30 Myanmar Time - Yangon, Mandalay"
	},
	{
		"name":               "Indian/Christmas",
		"alternativeName":    "Christmas Island Time",
		"group":              [
			"Indian/Christmas"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Christmas Island",
		"countryCode":        "CX",
		"mainCities":         [
			"Flying Fish Cove"
		],
		"rawOffsetInMinutes": 420,
		"rawFormat":          "+07:00 Christmas Island Time - Flying Fish Cove"
	},
	{
		"name":               "Antarctica/Davis",
		"alternativeName":    "Davis Time",
		"group":              [
			"Antarctica/Davis"
		],
		"continentCode":      "AN",
		"continentName":      "Antarctica",
		"countryName":        "Antarctica",
		"countryCode":        "AQ",
		"mainCities":         [
			"Davis"
		],
		"rawOffsetInMinutes": 420,
		"rawFormat":          "+07:00 Davis Time - Davis"
	},
	{
		"name":               "Asia/Hovd",
		"alternativeName":    "Hovd Time",
		"group":              [
			"Asia/Hovd"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Mongolia",
		"countryCode":        "MN",
		"mainCities":         [
			"Khovd",
			"Ölgii"
		],
		"rawOffsetInMinutes": 420,
		"rawFormat":          "+07:00 Hovd Time - Khovd, Ölgii"
	},
	{
		"name":               "Asia/Bangkok",
		"alternativeName":    "Indochina Time",
		"group":              [
			"Asia/Bangkok"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Thailand",
		"countryCode":        "TH",
		"mainCities":         [
			"Bangkok",
			"Samut Prakan"
		],
		"rawOffsetInMinutes": 420,
		"rawFormat":          "+07:00 Indochina Time - Bangkok, Samut Prakan"
	},
	{
		"name":               "Asia/Ho_Chi_Minh",
		"alternativeName":    "Indochina Time",
		"group":              [
			"Asia/Ho_Chi_Minh"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Vietnam",
		"countryCode":        "VN",
		"mainCities":         [
			"Ho Chi Minh City",
			"Da Nang"
		],
		"rawOffsetInMinutes": 420,
		"rawFormat":          "+07:00 Indochina Time - Ho Chi Minh City, Da Nang"
	},
	{
		"name":               "Asia/Phnom_Penh",
		"alternativeName":    "Indochina Time",
		"group":              [
			"Asia/Phnom_Penh"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Cambodia",
		"countryCode":        "KH",
		"mainCities":         [
			"Phnom Penh",
			"Takeo"
		],
		"rawOffsetInMinutes": 420,
		"rawFormat":          "+07:00 Indochina Time - Phnom Penh, Takeo"
	},
	{
		"name":               "Asia/Vientiane",
		"alternativeName":    "Indochina Time",
		"group":              [
			"Asia/Vientiane"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Laos",
		"countryCode":        "LA",
		"mainCities":         [
			"Vientiane",
			"Pakse"
		],
		"rawOffsetInMinutes": 420,
		"rawFormat":          "+07:00 Indochina Time - Vientiane, Pakse"
	},
	{
		"name":               "Asia/Novosibirsk",
		"alternativeName":    "Novosibirsk Time",
		"group":              [
			"Asia/Barnaul",
			"Asia/Krasnoyarsk",
			"Asia/Novokuznetsk",
			"Asia/Novosibirsk",
			"Asia/Tomsk"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Russia",
		"countryCode":        "RU",
		"mainCities":         [
			"Novosibirsk",
			"Krasnoyarsk"
		],
		"rawOffsetInMinutes": 420,
		"rawFormat":          "+07:00 Novosibirsk Time - Novosibirsk, Krasnoyarsk"
	},
	{
		"name":               "Asia/Jakarta",
		"alternativeName":    "Western Indonesia Time",
		"group":              [
			"Asia/Jakarta",
			"Asia/Pontianak"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Indonesia",
		"countryCode":        "ID",
		"mainCities":         [
			"Jakarta",
			"Surabaya"
		],
		"rawOffsetInMinutes": 420,
		"rawFormat":          "+07:00 Western Indonesia Time - Jakarta, Surabaya"
	},
	{
		"name":               "Antarctica/Casey",
		"alternativeName":    "Australian Western Time",
		"group":              [
			"Antarctica/Casey"
		],
		"continentCode":      "AN",
		"continentName":      "Antarctica",
		"countryName":        "Antarctica",
		"countryCode":        "AQ",
		"mainCities":         [
			"Casey"
		],
		"rawOffsetInMinutes": 480,
		"rawFormat":          "+08:00 Australian Western Time - Casey"
	},
	{
		"name":               "Australia/Perth",
		"alternativeName":    "Australian Western Time",
		"group":              [
			"Australia/Perth"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Australia",
		"countryCode":        "AU",
		"mainCities":         [
			"Perth",
			"Rockingham"
		],
		"rawOffsetInMinutes": 480,
		"rawFormat":          "+08:00 Australian Western Time - Perth, Rockingham"
	},
	{
		"name":               "Asia/Brunei",
		"alternativeName":    "Brunei Darussalam Time",
		"group":              [
			"Asia/Brunei"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Brunei",
		"countryCode":        "BN",
		"mainCities":         [
			"Bandar Seri Begawan",
			"Kuala Belait"
		],
		"rawOffsetInMinutes": 480,
		"rawFormat":          "+08:00 Brunei Darussalam Time - Bandar Seri Begawan, Kuala Belait"
	},
	{
		"name":               "Asia/Makassar",
		"alternativeName":    "Central Indonesia Time",
		"group":              [
			"Asia/Makassar"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Indonesia",
		"countryCode":        "ID",
		"mainCities":         [
			"Makassar",
			"Denpasar"
		],
		"rawOffsetInMinutes": 480,
		"rawFormat":          "+08:00 Central Indonesia Time - Makassar, Denpasar"
	},
	{
		"name":               "Asia/Macau",
		"alternativeName":    "China Time",
		"group":              [
			"Asia/Macau"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Macao",
		"countryCode":        "MO",
		"mainCities":         [
			"Macau"
		],
		"rawOffsetInMinutes": 480,
		"rawFormat":          "+08:00 China Time - Macau"
	},
	{
		"name":               "Asia/Shanghai",
		"alternativeName":    "China Time",
		"group":              [
			"Asia/Shanghai"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "China",
		"countryCode":        "CN",
		"mainCities":         [
			"Shanghai",
			"Beijing"
		],
		"rawOffsetInMinutes": 480,
		"rawFormat":          "+08:00 China Time - Shanghai, Beijing"
	},
	{
		"name":               "Asia/Hong_Kong",
		"alternativeName":    "Hong Kong Time",
		"group":              [
			"Asia/Hong_Kong"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Hong Kong",
		"countryCode":        "HK",
		"mainCities":         [
			"Hong Kong",
			"Kowloon"
		],
		"rawOffsetInMinutes": 480,
		"rawFormat":          "+08:00 Hong Kong Time - Hong Kong, Kowloon"
	},
	{
		"name":               "Asia/Irkutsk",
		"alternativeName":    "Irkutsk Time",
		"group":              [
			"Asia/Irkutsk"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Russia",
		"countryCode":        "RU",
		"mainCities":         [
			"Irkutsk",
			"Ulan-Ude"
		],
		"rawOffsetInMinutes": 480,
		"rawFormat":          "+08:00 Irkutsk Time - Irkutsk, Ulan-Ude"
	},
	{
		"name":               "Asia/Kuala_Lumpur",
		"alternativeName":    "Malaysia Time",
		"group":              [
			"Asia/Kuala_Lumpur",
			"Asia/Kuching"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Malaysia",
		"countryCode":        "MY",
		"mainCities":         [
			"Kota Bharu",
			"Kuala Lumpur"
		],
		"rawOffsetInMinutes": 480,
		"rawFormat":          "+08:00 Malaysia Time - Kota Bharu, Kuala Lumpur"
	},
	{
		"name":               "Asia/Manila",
		"alternativeName":    "Philippine Time",
		"group":              [
			"Asia/Manila"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Philippines",
		"countryCode":        "PH",
		"mainCities":         [
			"Quezon City",
			"Manila"
		],
		"rawOffsetInMinutes": 480,
		"rawFormat":          "+08:00 Philippine Time - Quezon City, Manila"
	},
	{
		"name":               "Asia/Singapore",
		"alternativeName":    "Singapore Time",
		"group":              [
			"Asia/Singapore"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Singapore",
		"countryCode":        "SG",
		"mainCities":         [
			"Singapore",
			"Woodlands"
		],
		"rawOffsetInMinutes": 480,
		"rawFormat":          "+08:00 Singapore Time - Singapore, Woodlands"
	},
	{
		"name":               "Asia/Taipei",
		"alternativeName":    "Taipei Time",
		"group":              [
			"Asia/Taipei"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Taiwan",
		"countryCode":        "TW",
		"mainCities":         [
			"Taipei",
			"Kaohsiung"
		],
		"rawOffsetInMinutes": 480,
		"rawFormat":          "+08:00 Taipei Time - Taipei, Kaohsiung"
	},
	{
		"name":               "Asia/Ulaanbaatar",
		"alternativeName":    "Ulaanbaatar Time",
		"group":              [
			"Asia/Choibalsan",
			"Asia/Ulaanbaatar"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Mongolia",
		"countryCode":        "MN",
		"mainCities":         [
			"Ulan Bator",
			"Erdenet"
		],
		"rawOffsetInMinutes": 480,
		"rawFormat":          "+08:00 Ulaanbaatar Time - Ulan Bator, Erdenet"
	},
	{
		"name":               "Australia/Eucla",
		"alternativeName":    "Australian Central Western Time",
		"group":              [
			"Australia/Eucla"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Australia",
		"countryCode":        "AU",
		"mainCities":         [
			"Eucla"
		],
		"rawOffsetInMinutes": 525,
		"rawFormat":          "+08:45 Australian Central Western Time - Eucla"
	},
	{
		"name":               "Asia/Dili",
		"alternativeName":    "East Timor Time",
		"group":              [
			"Asia/Dili"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Timor Leste",
		"countryCode":        "TL",
		"mainCities":         [
			"Dili",
			"Maliana"
		],
		"rawOffsetInMinutes": 540,
		"rawFormat":          "+09:00 East Timor Time - Dili, Maliana"
	},
	{
		"name":               "Asia/Jayapura",
		"alternativeName":    "Eastern Indonesia Time",
		"group":              [
			"Asia/Jayapura"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Indonesia",
		"countryCode":        "ID",
		"mainCities":         [
			"Ambon",
			"Jayapura"
		],
		"rawOffsetInMinutes": 540,
		"rawFormat":          "+09:00 Eastern Indonesia Time - Ambon, Jayapura"
	},
	{
		"name":               "Asia/Tokyo",
		"alternativeName":    "Japan Time",
		"group":              [
			"Asia/Tokyo"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "Japan",
		"countryCode":        "JP",
		"mainCities":         [
			"Tokyo",
			"Yokohama"
		],
		"rawOffsetInMinutes": 540,
		"rawFormat":          "+09:00 Japan Time - Tokyo, Yokohama"
	},
	{
		"name":               "Asia/Pyongyang",
		"alternativeName":    "Korean Time",
		"group":              [
			"Asia/Pyongyang"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "North Korea",
		"countryCode":        "KP",
		"mainCities":         [
			"Pyongyang",
			"Hamhŭng"
		],
		"rawOffsetInMinutes": 540,
		"rawFormat":          "+09:00 Korean Time - Pyongyang, Hamhŭng"
	},
	{
		"name":               "Asia/Seoul",
		"alternativeName":    "Korean Time",
		"group":              [
			"Asia/Seoul"
		],
		"continentCode":      "AS",
		"continentName":      "Asia",
		"countryName":        "South Korea",
		"countryCode":        "KR",
		"mainCities":         [
			"Seoul",
			"Busan"
		],
		"rawOffsetInMinutes": 540,
		"rawFormat":          "+09:00 Korean Time - Seoul, Busan"
	},
	{
		"name":               "Pacific/Palau",
		"alternativeName":    "Palau Time",
		"group":              [
			"Pacific/Palau"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Palau",
		"countryCode":        "PW",
		"mainCities":         [
			"Ngerulmud"
		],
		"rawOffsetInMinutes": 540,
		"rawFormat":          "+09:00 Palau Time - Ngerulmud"
	},
	{
		"name":               "Asia/Chita",
		"alternativeName":    "Yakutsk Time",
		"group":              [
			"Asia/Chita",
			"Asia/Khandyga",
			"Asia/Yakutsk"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Russia",
		"countryCode":        "RU",
		"mainCities":         [
			"Chita",
			"Yakutsk"
		],
		"rawOffsetInMinutes": 540,
		"rawFormat":          "+09:00 Yakutsk Time - Chita, Yakutsk"
	},
	{
		"name":               "Australia/Adelaide",
		"alternativeName":    "Australian Central Time",
		"group":              [
			"Australia/Adelaide",
			"Australia/Broken_Hill"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Australia",
		"countryCode":        "AU",
		"mainCities":         [
			"Adelaide",
			"Adelaide Hills"
		],
		"rawOffsetInMinutes": 570,
		"rawFormat":          "+09:30 Australian Central Time - Adelaide, Adelaide Hills"
	},
	{
		"name":               "Australia/Darwin",
		"alternativeName":    "Australian Central Time",
		"group":              [
			"Australia/Darwin"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Australia",
		"countryCode":        "AU",
		"mainCities":         [
			"Darwin",
			"Alice Springs"
		],
		"rawOffsetInMinutes": 570,
		"rawFormat":          "+09:30 Australian Central Time - Darwin, Alice Springs"
	},
	{
		"name":               "Australia/Brisbane",
		"alternativeName":    "Australian Eastern Time",
		"group":              [
			"Australia/Brisbane",
			"Australia/Lindeman"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Australia",
		"countryCode":        "AU",
		"mainCities":         [
			"Brisbane",
			"Gold Coast"
		],
		"rawOffsetInMinutes": 600,
		"rawFormat":          "+10:00 Australian Eastern Time - Brisbane, Gold Coast"
	},
	{
		"name":               "Australia/Sydney",
		"alternativeName":    "Australian Eastern Time",
		"group":              [
			"Australia/Currie",
			"Australia/Hobart",
			"Australia/Melbourne",
			"Australia/Sydney"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Australia",
		"countryCode":        "AU",
		"mainCities":         [
			"Sydney",
			"Melbourne"
		],
		"rawOffsetInMinutes": 600,
		"rawFormat":          "+10:00 Australian Eastern Time - Sydney, Melbourne"
	},
	{
		"name":               "Pacific/Guam",
		"alternativeName":    "Chamorro Time",
		"group":              [
			"Pacific/Guam"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Guam",
		"countryCode":        "GU",
		"mainCities":         [
			"Dededo Village",
			"Yigo Village"
		],
		"rawOffsetInMinutes": 600,
		"rawFormat":          "+10:00 Chamorro Time - Dededo Village, Yigo Village"
	},
	{
		"name":               "Pacific/Saipan",
		"alternativeName":    "Chamorro Time",
		"group":              [
			"Pacific/Saipan"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Northern Mariana Islands",
		"countryCode":        "MP",
		"mainCities":         [
			"Saipan"
		],
		"rawOffsetInMinutes": 600,
		"rawFormat":          "+10:00 Chamorro Time - Saipan"
	},
	{
		"name":               "Pacific/Chuuk",
		"alternativeName":    "Chuuk Time",
		"group":              [
			"Pacific/Chuuk"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Micronesia",
		"countryCode":        "FM",
		"mainCities":         [
			"Chuuk"
		],
		"rawOffsetInMinutes": 600,
		"rawFormat":          "+10:00 Chuuk Time - Chuuk"
	},
	{
		"name":               "Antarctica/DumontDUrville",
		"alternativeName":    "Dumont-d’Urville Time",
		"group":              [
			"Antarctica/DumontDUrville"
		],
		"continentCode":      "AN",
		"continentName":      "Antarctica",
		"countryName":        "Antarctica",
		"countryCode":        "AQ",
		"mainCities":         [
			"DumontDUrville"
		],
		"rawOffsetInMinutes": 600,
		"rawFormat":          "+10:00 Dumont-d’Urville Time - DumontDUrville"
	},
	{
		"name":               "Pacific/Port_Moresby",
		"alternativeName":    "Papua New Guinea Time",
		"group":              [
			"Pacific/Port_Moresby"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Papua New Guinea",
		"countryCode":        "PG",
		"mainCities":         [
			"Port Moresby",
			"Lae"
		],
		"rawOffsetInMinutes": 600,
		"rawFormat":          "+10:00 Papua New Guinea Time - Port Moresby, Lae"
	},
	{
		"name":               "Asia/Vladivostok",
		"alternativeName":    "Vladivostok Time",
		"group":              [
			"Asia/Ust-Nera",
			"Asia/Vladivostok"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Russia",
		"countryCode":        "RU",
		"mainCities":         [
			"Vladivostok",
			"Khabarovsk"
		],
		"rawOffsetInMinutes": 600,
		"rawFormat":          "+10:00 Vladivostok Time - Vladivostok, Khabarovsk"
	},
	{
		"name":               "Australia/Lord_Howe",
		"alternativeName":    "Lord Howe Time",
		"group":              [
			"Australia/Lord_Howe"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Australia",
		"countryCode":        "AU",
		"mainCities":         [
			"Lord Howe"
		],
		"rawOffsetInMinutes": 630,
		"rawFormat":          "+10:30 Lord Howe Time - Lord Howe"
	},
	{
		"name":               "Pacific/Bougainville",
		"alternativeName":    "Bougainville Time",
		"group":              [
			"Pacific/Bougainville"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Papua New Guinea",
		"countryCode":        "PG",
		"mainCities":         [
			"Arawa"
		],
		"rawOffsetInMinutes": 660,
		"rawFormat":          "+11:00 Bougainville Time - Arawa"
	},
	{
		"name":               "Pacific/Kosrae",
		"alternativeName":    "Kosrae Time",
		"group":              [
			"Pacific/Kosrae",
			"Pacific/Pohnpei"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Micronesia",
		"countryCode":        "FM",
		"mainCities":         [
			"Kosrae",
			"Palikir - National Government Center"
		],
		"rawOffsetInMinutes": 660,
		"rawFormat":          "+11:00 Kosrae Time - Kosrae, Palikir - National Government Center"
	},
	{
		"name":               "Antarctica/Macquarie",
		"alternativeName":    "Macquarie Island Time",
		"group":              [
			"Antarctica/Macquarie"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Australia",
		"countryCode":        "AU",
		"mainCities":         [
			"Macquarie"
		],
		"rawOffsetInMinutes": 660,
		"rawFormat":          "+11:00 Macquarie Island Time - Macquarie"
	},
	{
		"name":               "Pacific/Noumea",
		"alternativeName":    "New Caledonia Time",
		"group":              [
			"Pacific/Noumea"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "New Caledonia",
		"countryCode":        "NC",
		"mainCities":         [
			"Nouméa",
			"Mont-Dore"
		],
		"rawOffsetInMinutes": 660,
		"rawFormat":          "+11:00 New Caledonia Time - Nouméa, Mont-Dore"
	},
	{
		"name":               "Pacific/Norfolk",
		"alternativeName":    "Norfolk Island Time",
		"group":              [
			"Pacific/Norfolk"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Norfolk Island",
		"countryCode":        "NF",
		"mainCities":         [
			"Kingston"
		],
		"rawOffsetInMinutes": 660,
		"rawFormat":          "+11:00 Norfolk Island Time - Kingston"
	},
	{
		"name":               "Asia/Sakhalin",
		"alternativeName":    "Sakhalin Time",
		"group":              [
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Russia",
		"countryCode":        "RU",
		"mainCities":         [
			"Yuzhno-Sakhalinsk",
			"Magadan"
		],
		"rawOffsetInMinutes": 660,
		"rawFormat":          "+11:00 Sakhalin Time - Yuzhno-Sakhalinsk, Magadan"
	},
	{
		"name":               "Pacific/Guadalcanal",
		"alternativeName":    "Solomon Islands Time",
		"group":              [
			"Pacific/Guadalcanal"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Solomon Islands",
		"countryCode":        "SB",
		"mainCities":         [
			"Honiara"
		],
		"rawOffsetInMinutes": 660,
		"rawFormat":          "+11:00 Solomon Islands Time - Honiara"
	},
	{
		"name":               "Pacific/Efate",
		"alternativeName":    "Vanuatu Time",
		"group":              [
			"Pacific/Efate"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Vanuatu",
		"countryCode":        "VU",
		"mainCities":         [
			"Port-Vila"
		],
		"rawOffsetInMinutes": 660,
		"rawFormat":          "+11:00 Vanuatu Time - Port-Vila"
	},
	{
		"name":               "Pacific/Fiji",
		"alternativeName":    "Fiji Time",
		"group":              [
			"Pacific/Fiji"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Fiji",
		"countryCode":        "FJ",
		"mainCities":         [
			"Suva",
			"Lautoka"
		],
		"rawOffsetInMinutes": 720,
		"rawFormat":          "+12:00 Fiji Time - Suva, Lautoka"
	},
	{
		"name":               "Pacific/Tarawa",
		"alternativeName":    "Gilbert Islands Time",
		"group":              [
			"Pacific/Tarawa"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Kiribati",
		"countryCode":        "KI",
		"mainCities":         [
			"Tarawa"
		],
		"rawOffsetInMinutes": 720,
		"rawFormat":          "+12:00 Gilbert Islands Time - Tarawa"
	},
	{
		"name":               "Pacific/Majuro",
		"alternativeName":    "Marshall Islands Time",
		"group":              [
			"Pacific/Kwajalein",
			"Pacific/Majuro"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Marshall Islands",
		"countryCode":        "MH",
		"mainCities":         [
			"Majuro",
			"Kwajalein"
		],
		"rawOffsetInMinutes": 720,
		"rawFormat":          "+12:00 Marshall Islands Time - Majuro, Kwajalein"
	},
	{
		"name":               "Pacific/Nauru",
		"alternativeName":    "Nauru Time",
		"group":              [
			"Pacific/Nauru"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Nauru",
		"countryCode":        "NR",
		"mainCities":         [
			"Yaren"
		],
		"rawOffsetInMinutes": 720,
		"rawFormat":          "+12:00 Nauru Time - Yaren"
	},
	{
		"name":               "Pacific/Auckland",
		"alternativeName":    "New Zealand Time",
		"group":              [
			"Pacific/Auckland"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "New Zealand",
		"countryCode":        "NZ",
		"mainCities":         [
			"Auckland",
			"Wellington"
		],
		"rawOffsetInMinutes": 720,
		"rawFormat":          "+12:00 New Zealand Time - Auckland, Wellington"
	},
	{
		"name":               "Antarctica/McMurdo",
		"alternativeName":    "New Zealand Time",
		"group":              [
			"Antarctica/McMurdo"
		],
		"continentCode":      "AN",
		"continentName":      "Antarctica",
		"countryName":        "Antarctica",
		"countryCode":        "AQ",
		"mainCities":         [
			"McMurdo"
		],
		"rawOffsetInMinutes": 720,
		"rawFormat":          "+12:00 New Zealand Time - McMurdo"
	},
	{
		"name":               "Asia/Kamchatka",
		"alternativeName":    "Petropavlovsk-Kamchatski Time",
		"group":              [
			"Asia/Anadyr",
			"Asia/Kamchatka"
		],
		"continentCode":      "EU",
		"continentName":      "Europe",
		"countryName":        "Russia",
		"countryCode":        "RU",
		"mainCities":         [
			"Petropavlovsk-Kamchatsky",
			"Yelizovo"
		],
		"rawOffsetInMinutes": 720,
		"rawFormat":          "+12:00 Petropavlovsk-Kamchatski Time - Petropavlovsk-Kamchatsky, Yelizovo"
	},
	{
		"name":               "Pacific/Funafuti",
		"alternativeName":    "Tuvalu Time",
		"group":              [
			"Pacific/Funafuti"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Tuvalu",
		"countryCode":        "TV",
		"mainCities":         [
			"Funafuti"
		],
		"rawOffsetInMinutes": 720,
		"rawFormat":          "+12:00 Tuvalu Time - Funafuti"
	},
	{
		"name":               "Pacific/Wake",
		"alternativeName":    "Wake Island Time",
		"group":              [
			"Pacific/Wake"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "United States Minor Outlying Islands",
		"countryCode":        "UM",
		"mainCities":         [
			"Wake"
		],
		"rawOffsetInMinutes": 720,
		"rawFormat":          "+12:00 Wake Island Time - Wake"
	},
	{
		"name":               "Pacific/Wallis",
		"alternativeName":    "Wallis & Futuna Time",
		"group":              [
			"Pacific/Wallis"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Wallis and Futuna",
		"countryCode":        "WF",
		"mainCities":         [
			"Mata-Utu"
		],
		"rawOffsetInMinutes": 720,
		"rawFormat":          "+12:00 Wallis & Futuna Time - Mata-Utu"
	},
	{
		"name":               "Pacific/Chatham",
		"alternativeName":    "Chatham Time",
		"group":              [
			"Pacific/Chatham"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "New Zealand",
		"countryCode":        "NZ",
		"mainCities":         [
			"Chatham"
		],
		"rawOffsetInMinutes": 765,
		"rawFormat":          "+12:45 Chatham Time - Chatham"
	},
	{
		"name":               "Pacific/Apia",
		"alternativeName":    "Apia Time",
		"group":              [
			"Pacific/Apia"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Samoa",
		"countryCode":        "WS",
		"mainCities":         [
			"Apia"
		],
		"rawOffsetInMinutes": 780,
		"rawFormat":          "+13:00 Apia Time - Apia"
	},
	{
		"name":               "Pacific/Enderbury",
		"alternativeName":    "Phoenix Islands Time",
		"group":              [
			"Pacific/Enderbury"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Kiribati",
		"countryCode":        "KI",
		"mainCities":         [
			"Enderbury"
		],
		"rawOffsetInMinutes": 780,
		"rawFormat":          "+13:00 Phoenix Islands Time - Enderbury"
	},
	{
		"name":               "Pacific/Fakaofo",
		"alternativeName":    "Tokelau Time",
		"group":              [
			"Pacific/Fakaofo"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Tokelau",
		"countryCode":        "TK",
		"mainCities":         [
			"Fakaofo"
		],
		"rawOffsetInMinutes": 780,
		"rawFormat":          "+13:00 Tokelau Time - Fakaofo"
	},
	{
		"name":               "Pacific/Tongatapu",
		"alternativeName":    "Tonga Time",
		"group":              [
			"Pacific/Tongatapu"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Tonga",
		"countryCode":        "TO",
		"mainCities":         [
			"Nuku‘alofa"
		],
		"rawOffsetInMinutes": 780,
		"rawFormat":          "+13:00 Tonga Time - Nuku‘alofa"
	},
	{
		"name":               "Pacific/Kiritimati",
		"alternativeName":    "Line Islands Time",
		"group":              [
			"Pacific/Kiritimati"
		],
		"continentCode":      "OC",
		"continentName":      "Oceania",
		"countryName":        "Kiribati",
		"countryCode":        "KI",
		"mainCities":         [
			"Kiritimati"
		],
		"rawOffsetInMinutes": 840,
		"rawFormat":          "+14:00 Line Islands Time - Kiritimati"
	}
];
