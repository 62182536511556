import Filter                         from "../base-filter";
import {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import NumericOperator                from "hm-filters/operators/NumericOperator";

export class ProductCountFilter extends Filter {
	smallText = "Product";
	group = "Product";
	path = ["Product"];

	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new OperatorAsFixedValueOperator("never", "never before"),
			new NumericOperator(">", "more than", "products"),
			new NumericOperator("<", "less than", "products"),
			new NumericOperator("=", "exactly", "products")
		];
	}

	get type(): string {
		return "ProductCountFilter";
	}
}
