import {RouteRecordRaw}         from "vue-router";
import AutomationShow        from "@/views/Automations/AutomationShow.vue";
import Routes                from "@/router/Routes";
import AutomationShowInfo    from "@/components/automations/Show/AutomationShowInfo.vue";
import AutomationEdit        from "@/views/Automations/AutomationEdit.vue";
import AutomationShowHistory from "@/components/automations/Show/AutomationShowHistory.vue";

export const AutomationRoutes: Array<RouteRecordRaw> = [
	{
		path:      "/automations/:id",
		component: AutomationShow,
		props:     true,
		meta:{
			title: "Automation"
		},
		children:  [
			{
				path:      "",
				name:      Routes.automations.show.index,
				component: AutomationShowInfo,
				meta:{
					title: "Automation Information"
				}
			},
			{
				path:      "history",
				name:      Routes.automations.show.history,
				component: AutomationShowHistory,
				meta:{
					title: "Automation History"
				}
			}
		]
	},
	{
		path:      "/automations/:id/edit",
		component: AutomationEdit,
		name:      Routes.automations.edit,
		props:     true,
		meta:      {
			requiresAuth:       true,
			canEditAutomations: true,
			meta:{
				title: "Edit Automation"
			}
		}
	}
];
