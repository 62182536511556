<template>
  <tr class="text-sm">
    <td class="td truncate">
      <div class="flex items-center group cursor-pointer">
        <div class="shrink-0 h-6 w-6 md:h-10 md:w-10">
          <client-avatar
              :client="client"
              class="h-6 w-6 md:h-10 md:w-10" />
        </div>
        <div class="ml-2 md:ml-4 grow">
          <router-link
              :to="{name: routeList.client.show, params: {id: client.id}}"
              class="text-sm leading-5 font-medium text-loopspark-500 group-hover:text-loopspark-700">
            {{ client.first_name }} {{ client.last_name }}
          </router-link>
          <div class="text-xs leading-normal">
            <div
                v-if="clientDecorator.isActive"
                class="inline-block  text-green-500">
              Active
            </div>
            <div
                v-if="clientDecorator.isInactive"
                class="inline-block text-red-500">
              Inactive
            </div>
            <div
                v-if="clientDecorator.isProspect"
                class="inline-block text-yellow-500">
              Prospect
            </div>
            <div
                v-if="clientDecorator.isLead"
                class="inline-block text-loopspark-500">
              Lead
            </div>
          </div>
        </div>
      </div>
    </td>
    <td class="td truncate">
      <span
          v-if="!client.current_package"
          class="text-gray-400">-</span>
      <div v-if="client.current_package">
        <div>{{ client.current_package.name }}</div>
        <div
            v-if="packageDecorator.computed.hasExpiration"
            class="text-gray-500 text-xs ">
          Expires:
          <timestamp-to-date
              :timestamp="client.current_package.expires_at"
              has-day-name
              studio
              natural-date />
        </div>
        <div
            v-if="!packageDecorator.computed.hasExpiration"
            class="text-gray-500 text-xs ">
          No expiration date
        </div>
      </div>
    </td>
    <td class="td text-right">
      {{ client.total_club_counts }}
    </td>
    <td
        v-if="periodCount"
        class="td text-right">
      {{ client.total_period_counts }}
    </td>
    <td class="td text-right">
      <span
          v-if="!client.last_attendance_at"
          class="text-gray-400">-</span>
      <div v-if="client.last_attendance_at">
        <div>
          <time-ago :timestamp="client.last_attendance_at" />
        </div>
        <div class="text-gray-500 text-xs">
          <timestamp-to-date
              :timestamp="client.last_attendance_at"
              studio
              has-day-name
              natural-date />
        </div>
      </div>
    </td>
  </tr>
</template>
<script lang="ts" setup>

import ClientAvatar                from "@/components/client/ClientAvatar.vue";
import TimestampToDate             from "@/components/layout/TimestampToDate.vue";
import TimeAgo                     from "@/components/layout/TimeAgo.vue";
import {toRef, useAttrs, useSlots} from "vue";
import {QLClient}                  from "@/graphql/queries/ql/composables";
import {PackageDecorator}          from "@/composables/decorators/PackageDecorator";
import {ClientDecorator}           from "@/composables/decorators/ClientDecorator";
import routesList                  from "@/composables/routesList";

interface Props {
  client: QLClient;
  periodCount: boolean;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const packageDecorator = props.client.current_package ? PackageDecorator(props.client.current_package) : null;

const {computed: clientDecorator} = ClientDecorator(props.client);

const client = toRef(props, "client");

const {routeList} = routesList();

</script>
