<template>
  <div
      v-if="catalogItemModel"
      class="space-y-8">
    <div class="bg-white rounded shadow-md p-6 space-y-4">
      <text-input
          v-model="catalogItemModel.name"
          label="Catalog Name"
          required />
      <text-input
          v-model="catalogItemModel.category"
          label="Category"
          required />
      <text-area-input
          v-model="catalogItemModel.description"
          label="Short description"
          required />
      <div class="flex flex-col">
        <label class="form-label">
          Long Description
        </label>
        <rich-editor v-model="catalogItemModel.long_description" />
      </div>

      <div class="flex">
        <label
            for=""
            class="form-label mr-4">Visible</label>
        <toggle-checkbox
            v-model="catalogItemModel.visible"
            label="Visible" />
      </div>

      <div class="flex flex-col">
        <label class="form-label">
          Provider
        </label>

        <select
            id=""
            v-model="catalogItemModel.provider"
            name="provider"
            class="form-select">
          <option :value="null">
            All
          </option>
          <option
              v-for="provider in providers"
              :key="provider"
              :value="provider">
            {{ provider }}
          </option>
        </select>
      </div>
    </div>
    <div class="bg-gray-50 shadow px-4 rounded-md ">
      <automation-form-settings
          class="py-2"
          v-model:automation="catalogItemModel.automation"
          v-model:errors="errorsModel"
          :timezone="'local'" />
    </div>
    <div class="bg-gray-50 shadow px-4 rounded-md ">
      <div class="py-4">
        <automation-form-sms-message
            v-if="decorated.via_sms"
            v-model:content="catalogItemModel.automation.content"
            v-model:errors="errorsModel"
            timezone="local" />

        <automation-form-email
            v-if="decorated.via_email"
            v-model:emailContent="catalogItemModel.automation.email_content"
            v-model:replyTo="catalogItemModel.automation.reply_to"
            v-model:subject="catalogItemModel.automation.subject"
            v-model:errors="errorsModel"
            timezone="local" />

        <automation-form-todo
            v-if="decorated.via_todo"
            v-model:subject="catalogItemModel.automation.subject"
            v-model:content="catalogItemModel.automation.content"
            v-model:priority="catalogItemModel.automation.todo_priority"
            v-model:overdue-hours="catalogItemModel.automation.todo_overdue_hours"
            v-model:postponed-hours="catalogItemModel.automation.todo_postponed_hours"
            v-model:assigned-to-id="catalogItemModel.automation.todo_assigned_to_id"
            v-model:assigned-team-group-id="catalogItemModel.automation.todo_team_group_id"
            v-model:tags="catalogItemModel.automation.todo_tags"
            v-model:errors="errorsModel"
            timezone="local" />

        <automation-form-tag
            v-if="decorated.via_tag"
            v-model:tags-add="catalogItemModel.automation.tags_add"
            v-model:tags="catalogItemModel.automation.tags"
            v-model:errors="errorsModel"
            timezone="local" />
      </div>
    </div>

    <div class="bg-white shadow p-4 rounded-md">
      <h3 class="font-semibold text-lg mb-2">
        Variables
      </h3>

      <button
          class="btn btn-green"
          @click="addVariableField">
        Add variable
      </button>
      <div class="space-y-8 mt-8">
        <div
            v-for="(catVar, index) in catalogItemModel.variable_fields"
            :key="index">
          <catalog-create-edit-variable
              v-model:variable="catalogItemModel.variable_fields[index]"
              @delete="deleteVar(catVar)" />
        </div>
      </div>
    </div>

    <div class="bg-white shadow p-4 rounded-md">
      <h3 class="font-semibold text-lg mb-2">
        Segment
      </h3>
      <text-input
          v-model="catalogItemModel.automation.segment.name"
          label="Segment Name"
          required />

      <segment-view-edit
          v-model:modelValue="catalogItemModel.automation.segment"
          :editable="true"
          class="p-4"
          :catalog-variables="catalogItemModel.variable_fields" />
    </div>

    <div
        v-if="errorsForm.hasErrors"
        class="bg-red-50 text-red-900 p-4 rounded">
      <ul>
        <li v-for="er in errorsForm.flatten()">
          {{ er }}
        </li>
      </ul>
    </div>

    <div>
      <v-button
          :disabled="saving"
          :loading="saving"
          class="btn btn-green"
          @click="saveCatalog">
        Save catalog
      </v-button>
    </div>
  </div>
</template>
<script lang="ts" setup>

import TextInput                                                                from "@/components/layout/Forms/TextInput.vue";
import TextAreaInput                                                            from "@/components/layout/Forms/TextAreaInput.vue";
import RichEditor                                                               from "@/components/layout/RichEditor.vue";
import ToggleCheckbox                                                           from "@/components/layout/Forms/ToggleCheckbox.vue";
import AutomationFormSettings                                                   from "@/components/automations/Create/AutomationFormSettings.vue";
import AutomationFormSmsMessage                                                 from "@/components/automations/Create/Mediums/AutomationFormSmsMessage.vue";
import AutomationFormEmail                                                      from "@/components/automations/Create/Mediums/AutomationFormEmail.vue";
import AutomationFormTodo                                                       from "@/components/automations/Create/Mediums/AutomationFormTodo.vue";
import AutomationFormTag                                                        from "@/components/automations/Create/Mediums/AutomationFormTag.vue";
import CatalogCreateEditVariable                                                from "@/components/automations/Catalog/CatalogCreateEditVariable.vue";
import SegmentViewEdit                                                          from "@/components/Segment/SegmentViewEdit.vue";
import VButton                                                                  from "@/components/layout/VButton.vue";
import {computed, toRef, useAttrs, useSlots}                                    from "vue";
import {QLCatalogItemInput, QLFragmentSmallAutomationFragment, QLProvidersEnum} from "@/graphql/queries/ql/composables";
import SparkFormErrors, {SparkErrors}                                           from "@/classes/utils/SparkFormErrors";
import AutomationDecorator                                                      from "@/composables/decorators/AutomationDecorator";

interface Props {
  catalogItem: QLCatalogItemInput;
  saving: boolean;
  errors: SparkErrors;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const errors = toRef(props, "errors");

const saving = toRef(props,"saving");

const emit = defineEmits(["update:catalogItem", "update:errors", "save"]);

const providers = QLProvidersEnum;

const errorsModel = computed({
                               get: () => props.errors,
                               set: (val) => emit("update:errors", val)
                             });

const catalogItemModel = computed({
                                    get: () => props.catalogItem,
                                    set: (val) => emit("update:catalogItem", val)
                                  });

const saveCatalog = () => {
  emit("save");
};

const errorsForm = computed(() => {
  return new SparkFormErrors(errors.value);
});

const decorated = AutomationDecorator(catalogItemModel.value.automation as unknown as QLFragmentSmallAutomationFragment).computed;

const addVariableField = () => {
  catalogItemModel.value.variable_fields.push({
                                                id:                Math.random().toString(),
                                                field_type:        null,
                                                field_name:        "",
                                                field_description: ""
                                              });
};

const deleteVar= (catVar ) => {
  catalogItemModel.value.variable_fields.splice(catalogItemModel.value.variable_fields.indexOf(catVar), 1);

}

</script>
