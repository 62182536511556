<template>
  <div class="bg-loopspark-700 text-white mb-8">
    <div class="container mx-auto">
      <div class="flex justify-between items-center">
        <div class="py-8 grow">
          <span class="font-medium font-serif" v-show="!!section">{{ section }}</span>
          <div class="text-2xl  font-light">
            <slot name="title">{{ title }}</slot>
          </div>
          <div class="text-loopspark-200 pt-2 text-sm" v-show="!!subText">{{ subText }}</div>
        </div>
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {computed, useSlots} from "vue";

interface Props {
  section?: string;
  subText?: string;
  title?: string;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();

const hasSubText = computed(() => {
  return !!slots["default"];
});

</script>
