import {AllEventTypes, EventInterface} from "@/classes/notifications/EventTypes";
import {onMounted, onUnmounted}        from "vue";
import {throttleFn} from "@/classes/utils/throttleFn";
import {EventBus}   from "@/classes/notifications/EventBus";

type OnEventFunction = (ev: EventInterface) => void;

type Values<T> = T[keyof T];

type EventsToListen = Values<typeof AllEventTypes>[];

export function echoListener(eventsToListen: EventsToListen, onEvent: OnEventFunction, throttle: number = 0) {
	const fn = throttleFn(onEvent, throttle);
	// const fnEvent = onEvent;

	onMounted(() => {
		for (const eventsToListenKey in eventsToListen) {
			EventBus.getInstance().on(eventsToListen[eventsToListenKey], fn);
		}
	});

	onUnmounted(() => {
		for (const eventsToListenKey in eventsToListen) {
			EventBus.getInstance().off(eventsToListen[eventsToListenKey], fn);
		}
	});


}
