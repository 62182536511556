import Operator                                           from "./operators/operator";
import ExactDateOperator                                  from "./operators/ExactDateOperator";
import NumericOperator                                    from "./operators/NumericOperator";
import TextOperator                                       from "hm-filters/operators/TextOperator";
import FixedValueOperator, {OperatorAsFixedValueOperator} from "hm-filters/operators/FixedValueOperator";
import MultipleTextOperator                               from "hm-filters/operators/MultipleTextOperator";

class OperatorGenerator {

	static numericOperators(appendText?: string): NumericOperator[] {
		return [
			new NumericOperator("=", "equals", appendText),
			new NumericOperator("<", "is less than", appendText),
			new NumericOperator(">", "is more than", appendText),
			new NumericOperator("!=", "does not equal", appendText)
		];
	}

	static textOperators(): TextOperator[] {
		return [
			new TextOperator("=", "equals"),
			new TextOperator("!=", "does not equal")
		];
	}

	static textContains(): MultipleTextOperator[] {
		return [
			new TextOperator("contains", "contains"),
			new TextOperator("does_not_contain", "does not contain")
		];
	}

	static textContainsMultiple(): MultipleTextOperator[] {
		return [
			new MultipleTextOperator("contains", "contains"),
			new MultipleTextOperator("does_not_contain", "does not contain")
		];
	}

	static booleanOperators(
		trueText = "is true",
		falseText = "is false"
	): FixedValueOperator[] {
		return [
			new FixedValueOperator(true, trueText),
			new FixedValueOperator(false, falseText)
		];
	}

	static presenceOperators(hasIt = "has", hasNot = "has not"): FixedValueOperator[] {
		return [
			new OperatorAsFixedValueOperator("has", hasIt),
			new OperatorAsFixedValueOperator("hasnot", hasNot)
		];
	}

	static datesAll(withNot = true, withExactly = true, withMinutes = true, withBefore: boolean = true): Operator[] {
		let ret: Operator[] = [];
		ret = ret.concat(this.dateToday());
		if (withNot) {
			ret = ret.concat(this.dateNotToday());
		}
		ret = ret.concat(this.dateYesterday());
		ret = ret.concat(this.dateTomorrow());
		ret = ret.concat(this.dateInLast(withNot));
		if (withMinutes) {
			ret = ret.concat(this.dateInLastMinutes(withNot));
		}
		ret = ret.concat(this.dateInNext(withNot));
		if (withMinutes) {
			ret = ret.concat(this.dateInNextMinutes(withNot));
		}
		if (withExactly) {
			ret = ret.concat(this.dateExactlyAgo(withNot));
			ret = ret.concat(this.dateExactlyNext(withNot));
			if (withBefore) {
				ret = ret.concat(this.dateBefore(withNot));
				ret = ret.concat(this.dateAfter(withNot));
			}

		}
		return ret;
	}

	static datesPastOnly(
		withNot = true,
		withExactlyNot ?: boolean,
		withMinutes = true
	): Operator[] {
		let ret: Operator[] = [];
		ret = ret.concat(this.dateToday());
		if (withNot) {
			ret = ret.concat(this.dateNotToday());
		}
		ret = ret.concat(this.dateYesterday());
		ret = ret.concat(this.dateInLast(withNot));
		if (withMinutes) {
			ret = ret.concat(this.dateInLastMinutes(withNot));
		}
		let witNotEx = withExactlyNot;
		if (witNotEx === undefined) {
			witNotEx = withNot;
		}
		ret = ret.concat(this.dateExactlyAgo(witNotEx));
		ret = ret.concat(this.dateBefore(witNotEx));
		ret = ret.concat(this.dateAfter(witNotEx));

		return ret;
	}

	static datesFutureOnly(
		withNot = true,
		withExactlyNot ?: boolean,
		withMinutes = true
	): Operator[] {
		let ret: Operator[] = [];
		ret = ret.concat(this.dateToday());
		if (withNot) {
			ret = ret.concat(this.dateNotToday());
		}
		ret = ret.concat(this.dateTomorrow());
		ret = ret.concat(this.dateInNext(withNot));
		if (withMinutes) {
			ret = ret.concat(this.dateInNextMinutes(withNot));
		}
		let witNotEx = withExactlyNot;
		if (witNotEx === undefined) {
			witNotEx = withNot;
		}
		ret = ret.concat(this.dateExactlyNext(witNotEx));
		ret = ret.concat(this.dateBefore(witNotEx));
		ret = ret.concat(this.dateAfter(witNotEx));

		return ret;
	}

	static dateBefore(withNot = true) {
		const ret = [
			new ExactDateOperator("before", "before")
		];
		if (withNot) {
			ret.push(new ExactDateOperator("not_before", "not before"));
		}
		return ret;
	}

	static dateAfter(withNot = true) {
		const ret = [
			new ExactDateOperator("after", "after")
		];
		if (withNot) {
			ret.push(new ExactDateOperator("not_after", "not after"));
		}
		return ret;
	}

	static dateToday() {
		return [new OperatorAsFixedValueOperator("today", "today")];
	}

	static dateNotToday() {
		return [new OperatorAsFixedValueOperator("not_today", "not today")];
	}

	static dateYesterday() {
		return [new OperatorAsFixedValueOperator("yesterday", "yesterday")];
	}

	static dateTomorrow() {
		return [new OperatorAsFixedValueOperator("tomorrow", "tomorrow")];
	}

	static dateInLast(withNot = true) {
		const ret = [
			new NumericOperator("in_the_last", "in the last", "days")
		];
		if (withNot) {
			ret.push(new NumericOperator("not_in_the_last", "not in the last", "days"));
		}

		return ret;
	}

	static dateInLastMinutes(withNot = true) {
		const ret = [
			new NumericOperator("in_the_last_minutes", "in the last", "minutes")
		];
		if (withNot) {
			ret.push(new NumericOperator("not_in_the_last_minutes",
			                             "not in the last",
			                             "minutes"));
		}

		return ret;
	}

	static dateInNext(withNot = true) {
		const ret = [
			new NumericOperator("in_the_next", "in the next", "days")
		];
		if (withNot) {
			ret.push(new NumericOperator("not_in_the_next", "not in the next", "days"));
		}

		return ret;

	}

	static dateInNextMinutes(withNot = true) {
		const ret = [
			new NumericOperator("in_the_next_minutes", "in the next", "minutes")
		];
		if (withNot) {
			ret.push(new NumericOperator("not_in_the_next_minutes",
			                             "not in the next",
			                             "minutes"));
		}

		return ret;

	}

	static dateExactlyAgo(withNot = true) {
		const ret = [
			new NumericOperator("exactly_ago", "exactly", "days ago")
		];
		if (withNot) {
			ret.push(new NumericOperator("not_exactly_ago", "exactly not", "days ago"));
		}

		return ret;

	}

	static dateExactlyNext(withNot = true) {
		const ret = [
			new NumericOperator("exactly_next", "exactly", "days from now")
		];
		if (withNot) {
			ret.push(new NumericOperator("not_exactly_next",
			                             "exactly not",
			                             "days from now"));
		}

		return ret;

	}

	static socialFollowing() {
		return [
			new NumericOperator(">", "more than", "following"),
			new NumericOperator("<", "less than", "following"),
			new NumericOperator("=", "exactly", "following")
		];
	}

	static socialFollowers() {
		return [
			new NumericOperator(">", "more than", "followers"),
			new NumericOperator("<", "less than", "followers"),
			new NumericOperator("=", "exactly", "followers")
		];
	}

}

export default OperatorGenerator;
