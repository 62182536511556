<template>
  <div class="space-y-16">
    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          General information
        </div>
        <div class="mt-1 text-gray-600 text-sm space-y-5">
          <p>General information about the automation to keep your automation list organized.</p>

          <p>
            <strong>Tip:</strong> Use a name that is easy to understand the purpose of the automation and audience
                                  segment (eg - Package is about to expire - Text message).
          </p>

          <p>
            Categorize the automation to group your automation list by similar campaign or objective (example: Welcome,
            Newsletters, Win-back, Intro Offer).
          </p>
        </div>
      </div>

      <div class="block overflow-hidden shadow-md rounded-lg flex-1 bg-white">
        <div class="p-4 space-y-6">
          <text-input
              v-model="automationName"
              :error-message="formErrors.get('name')"
              data-name
              label="Name"
              placeholder="Example: Trial Offer - Expiring in 3 days - SMS"
              required
          >
            <div class="leading-tight form-small-info text-gray-600">
              Use a name that is easy to understand the purpose of the automation and audience segment (eg - Package is
              about to expire - Text message)
            </div>
          </text-input>
          <div>
            <label
                class="form-label"
                :class="{'has-error': formErrors.has('category_name')}"
            >
              Category
            </label>
            <smart-select
                v-model="automationModel.category_name"
                :error-message="formErrors.get('category_name')"
                :items="automationCategories"
                allow-new
                class="mt-1"
                placeholder="Example: Welcome sequence"
            />
            <div class="leading-tight form-small-info text-gray-600">
              Select an existing category or start typing to create a new category.
            </div>
          </div>

          <text-area-input
              v-model="automationModel.description"
              :error-message="formErrors.get('description')"
              :rows="5"
              label="Description"
              placeholder="SMS sent 3 days before intro offer expires, only if client has attended at least 1 class and not already purchased a membership"
              required
          >
            <div class="leading-tight form-small-info text-gray-600">
              Add a short description to help yourself (and staff) understand the purpose of this automation. This will
              also help LoopSpark Support debug your automation if you ask for help.
            </div>
          </text-area-input>
        </div>
      </div>
    </div>

    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Action
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>Select the action you wish to be completed with this automation.</p>

          <div class="text-base pt-4">
            Best practices
          </div>

          <ul class="list-disc list-inside space-y-2 mt-2">
            <li><span class="font-medium">Email</span>: Announcements, marketing, large audiences</li>
            <li><span class="font-medium">SMS</span>: Transactional or very targeted messaging</li>
            <li><span class="font-medium">To-Do</span>: Non client-facing staff reminders</li>
            <li><span class="font-medium">Tag</span>: Additional client data management</li>
          </ul>
        </div>
      </div>

      <div class="block overflow-hidden shadow-md rounded-lg flex-1 bg-white">
        <div class="p-4 grid grid-cols-2 gap-4">
          <div
              class="radio-box"
              role="button"
              :class="{'selected': automationModel.delivery_type === deliveryTypes.EMAIL}"
              @click="changeMedium(deliveryTypes.EMAIL)"
          >
            <svg-icon
                name="email"
                class="w-8 h-8"
            />
            <div class="text-center">
              <div class="mt-2 pb-1 font-medium">
                Send an Email
              </div>
              <p class="text-xs">
                Send an email to all clients in your chosen segment using the email address in their
                client profile
              </p>
            </div>
          </div>
          <div
              class="radio-box"
              role="button"
              :class="{'selected': automationModel.delivery_type === deliveryTypes.SMS}"
              @click="changeMedium(deliveryTypes.SMS)"
          >
            <svg-icon
                name="mobile"
                class="w-8 h-8"
            />
            <div class="text-center">
              <div class="mt-2 pb-1 font-medium">
                Send a SMS
              </div>
              <p class="text-xs">
                Send an SMS text message to all clients in your chosen segment using the phone number
                in their client profile
              </p>
            </div>
          </div>

          <div
              class="radio-box"
              role="button"
              :class="{'selected': automationModel.delivery_type === deliveryTypes.TODO}"
              @click="changeMedium(deliveryTypes.TODO)"
          >
            <svg-icon
                name="todo"
                class="w-8 h-8"
            />
            <div class="text-center">
              <div class="mt-2 pb-1 font-medium">
                Add a To-Do
              </div>
              <p class="text-xs">
                Add a To-Do for all clients in your chosen segment
              </p>
            </div>
          </div>
          <div
              class="radio-box"
              role="button"
              :class="{'selected': automationModel.delivery_type === deliveryTypes.TAG}"
              @click="changeMedium(deliveryTypes.TAG)"
          >
            <svg-icon
                name="tag"
                class="w-8 h-8"
            />
            <div class="text-center">
              <div class="mt-2 pb-1 font-medium">
                Tag
              </div>
              <p class="text-xs">
                Add or remove a Tag for all clients in your chosen segment
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Type
        </div>
        <div class="mt-1 text-gray-600 text-sm space-y-5">
          <p>LoopSpark offers two kinds of automation.</p>

          <div>
            <p>
              <span class="font-medium italic">One time only</span> is intended for newsletters, announcements, or
                                                                    invitations, since they happen one time
                                                                    only. Automation can only be activated one time,
                                                                    and will end after triggering for all clients that
                                                                    currently match your audience segment.
            </p>
          </div>
          <div>
            <p>
              <span class="font-medium italic">Continuous</span> remain active, continuing to trigger as clients enter
                                                                 your audience segment.
            </p>
          </div>
        </div>
      </div>

      <div class="block overflow-hidden shadow-md rounded-lg flex-1 bg-white">
        <div class="p-4">
          <div
              class="border-2 rounded-lg py-4 mb-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
              role="button"
              :class="{'selected-box': automationModel.one_off}"
              @click="automationModel.one_off = true"
          >
            <span class="text-base font-medium block mb-2">One Time Only</span>
            <p class="text-xs">
              Newsletters, announcements, invitations: Automation will be triggered only one time to an
              audience segment that doesn't change (example - Black Friday promotion sent to all
              clients that currently do not have a membership).
            </p>
          </div>
          <div
              class="border-2 rounded-lg py-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
              role="button"
              :class="{'selected-box': !automationModel.one_off}"
              @click="automationModel.one_off = false"
          >
            <span class="text-base font-medium block mb-2">Continuous</span>
            <p class="text-xs">
              Communications will be sent to users whenever their client data matches your chosen
              segment filters. (eg - Send this communication to any client after their first visit.).
              For To-Do items, users will continuously be added to your to-do list as they match the
              segment filters.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Delivery Window
        </div>
        <div class="mt-1 text-gray-600 text-sm space-y-2">
          <p>The delivery window is an important decision that could lead to an improved engagement by your clients.</p>
          <p>
            In most cases, it's preferable and courteous for customers to receive communications only during a daytime
            delivery window.
          </p>
          <p>
            There are cases when a communication can and should be sent as soon as possible, usually following
            immediate client action (after a visit, purchase, booking etc).
          </p>
        </div>
      </div>

      <div class="block overflow-hidden shadow-md rounded-lg flex-1 bg-white">
        <div class="p-4">
          <div
              class="border-2 rounded-lg py-4 mb-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
              role="button"
              :class="{'selected-box': !sendDeliveryWindowSelected}"
              @click="emptyDeliveryWindow()"
          >
            <span class="text-base font-medium block mb-2">Send Anytime</span>
            <p class="text-xs">
              Best for transactional communications that should occur immediately after a client action
              (example: after client attends an event, after account creation, after package
              purchased).
            </p>
          </div>
          <div
              class="border-2 rounded-lg py-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
              role="button"
              :class="{'selected-box': sendDeliveryWindowSelected}"
              @click="sendDeliveryWindowSelected = true"
          >
            <span class="text-base font-medium block mb-2">Set Delivery Window</span>
            <p class="text-xs">
              You can apply a delivery window to ensure emails and SMS communications are not sent too
              early or too late in the day.
            </p>

            <div
                v-if="sendDeliveryWindowSelected"
                class="lg:flex mt-4 items-center lg:space-x-2 text-black"
            >
              <span class="form-label lg:hidden">From</span>
              <minute-of-day-picker v-model="automationModel.send_window_start_minutes" />
              <span class="hidden lg:block"> - </span>
              <span class="mt-2 form-label lg:hidden">To</span>
              <minute-of-day-picker v-model="automationModel.send_window_end_minutes" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="split-view-left">
      <div class="pb-4 md:pb-0 md:w-1/3">
        <div class="text-lg font-medium text-gray-900">
          Start Time
        </div>
        <div class="mt-1 text-gray-600 text-sm">
          <p>
            Set a start time for your automation to become active. One Time Only automations will trigger at this time,
            Continuous automations will begin at this time and continue to trigger as new users enter the audience
            segment, or until the automation is paused.
          </p>
          <div class="text-base pt-4">
            Tips:
          </div>
          <ul class="list-disc list-inside space-y-2 mt-2">
            <li>
              Save your automation with a start time of "off" so you can preview the content, make revisions as
              needed, and manually activate when you're ready.
            </li>
            <li>
              Active Immediately should rarely be chosen. As a best practice, set the start time as "off" so you have
              time to preview, fix typos, and manually set active when you're ready.
            </li>
          </ul>
        </div>
      </div>

      <div class="block overflow-hidden shadow-md rounded-lg flex-1 bg-white">
        <div class="p-4">
          <div
              v-if="importDone && outgoingSet"
              class="border-2 rounded-lg py-4 mb-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
              role="button"
              :class="{'selected-box': automationModel.active}"
              @click="setActive"
          >
            <span class="text-base font-medium block mb-2">Active immediately</span>
            <p class="text-xs">
              The automation will become active immediately after saving. Before selecting, <strong
                class="font-semibold italic text-yellow-900"
            >read start time tips</strong> on the left side.
            </p>
          </div>
          <div
              v-if="importDone && outgoingSet"
              class="border-2 rounded-lg py-4 mb-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
              role="button"
              :class="{'selected-box': !automationModel.active && automationModel.start_at > 0}"
              @click="setStartAt"
          >
            <span class="text-base font-medium block mb-2">After a specific date and time</span>
            <p class="text-xs">
              Automation will become active at a specific date/time (good for scheduling seasonal sales
              promotions).
            </p>

            <div
                v-if="!automationModel.active && automationModel.start_at > 0"
                class="mt-4"
            >
              <span class="form-label mb-1">Start date</span>
              <date-time-picker
                  v-model="automationModel.start_at"
                  :local-timezone="timezone"
                  :min-date="Date.now() / 1000"
              />
            </div>
          </div>
          <div
              class="border-2 rounded-lg py-4 px-4 hover:border-blue-200 hover:bg-blue-100 hover:text-blue-800"
              role="button"
              :class="{'selected-box': !automationModel.active && !automationModel.start_at}"
              @click="setInactive"
          >
            <span class="text-base font-medium block mb-2">Off</span>
            <p class="text-xs">
              Automation will be saved as inactive, providing time to review your automation before
              manually activating.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.radio-box {
  @apply rounded-lg border-4 border-gray-200 flex items-center justify-center flex-col py-8 px-4;
}

.radio-box:hover {
  @apply border-blue-200 bg-blue-100 text-blue-800;
}

.radio-box.selected, .selected-box {
  @apply border-green-200 bg-green-50 text-green-900;
}
</style>
<script lang="ts" setup>

import {QLAutomation, QLAutomationDeliveryTypeEnum, useAutomationCategoriesQuery} from "@/graphql/queries/ql/composables";
import TextInput                                                                  from "@/components/layout/Forms/TextInput.vue";
import TextAreaInput                                                              from "@/components/layout/Forms/TextAreaInput.vue";
import SparkFormErrors, {SparkErrors}                                             from "@/classes/utils/SparkFormErrors";
import MinuteOfDayPicker                                                          from "@/components/layout/Forms/MinuteOfDayPicker.vue";
import DateTimePicker                                                             from "@/components/layout/Forms/DateTimePicker.vue";
import SmartSelect                                                                from "@/components/layout/Forms/SmartSelect.vue";
import SvgIcon                                                                    from "@/components/SvgIcon.vue";
import {computed, onMounted, ref, toRaw, toRef, watch}                            from "vue";
import currentTeamQuery                                                           from "@/composables/queries/currentTeamQuery";
import {useResult}                                                                from "@vue/apollo-composable";


interface Props {
  automation: QLAutomation;
  errors: SparkErrors;
  timezone: string;
}

const props = withDefaults(defineProps<Props>(), {});
const timezone = toRef(props, "timezone");
const automationModel = toRef(props, "automation");

const emit = defineEmits([
                           "update:automation", "update:errors"
                         ]);


const {currentTeam} = currentTeamQuery();
const {result} = useAutomationCategoriesQuery({fetchPolicy: "cache-and-network"});
const automationCategoriesRaw = useResult(result, []);
const automationCategories = computed(() => {
  return automationCategoriesRaw.value.map((cat) => cat.name);
});

const sendDeliveryWindowSelected = ref(false);
onMounted(() => sendDeliveryWindowSelected.value = (automationModel.value.send_window_start_minutes !== null));


const importDone = computed(() => currentTeam.value && currentTeam.value.first_import_done);
const outgoingSet = computed(() => currentTeam.value && currentTeam.value.outgoing_set);


watch(automationModel, (newD, oldD) => {
  emit("update:automation", toRaw(oldD));
}, {deep: true});

const deliveryTypes = QLAutomationDeliveryTypeEnum;

const automationName = computed({
                                  get: () => automationModel.value.name,
                                  set: (val) => {
                                    const err = new SparkFormErrors(props.errors);
                                    err.forget("name");

                                    automationModel.value.name = val;
                                    emit("update:errors", err.all());
                                  }
                                });

const formErrors = computed(() => {
  return new SparkFormErrors(props.errors);
});

const emptyDeliveryWindow = () => {
  sendDeliveryWindowSelected.value = false;
  automationModel.value.send_window_start_minutes = null;
  automationModel.value.send_window_end_minutes = null;
};

const setActive = () => {
  automationModel.value.active = true;
  automationModel.value.start_at = null;
};

const setInactive = () => {
  automationModel.value.active = false;
  automationModel.value.start_at = null;
};

const setStartAt = () => {
  if (automationModel.value.active === true || automationModel.value.start_at === null) {
    automationModel.value.active = false;
    automationModel.value.start_at = Math.round(Date.now() / 1000);
  }
};

const changeMedium = (medium: QLAutomationDeliveryTypeEnum) => {

  automationModel.value.delivery_type = medium;
  if (automationModel.value.delivery_type === QLAutomationDeliveryTypeEnum.TODO || automationModel.value.delivery_type === QLAutomationDeliveryTypeEnum.TAG) {
    automationModel.value.segment.only_deliverable_by_email = false;
    automationModel.value.segment.only_deliverable_by_sms = false;
  }
  if (automationModel.value.delivery_type === QLAutomationDeliveryTypeEnum.SMS) {
    automationModel.value.segment.only_deliverable_by_email = false;
    automationModel.value.segment.only_deliverable_by_sms = true;
  }
  if (automationModel.value.delivery_type === QLAutomationDeliveryTypeEnum.EMAIL) {
    automationModel.value.segment.only_deliverable_by_email = true;
    automationModel.value.segment.only_deliverable_by_sms = false;
  }
};

</script>
