<template>
  <confirm-modal
      v-model:open="openModel"
      :loading="deleting"
      type="warning"
      close-text="No, go back"
      title="Unarchive this form?"
      @close="openModel = false"
      @clicked="performAction">
    <template #button>
      Yes, unarchive it!
    </template>
    <template #text>
      <p>
        Unarchiving this form will result in the form being viewable in
        your forms list and publically visible.
      </p>
    </template>
  </confirm-modal>
</template>
<script lang="ts">

import ConfirmModal           from "@/components/layout/ConfirmModal.vue";
import archiveUnarchiveDialog from "@/views/Forms/Components/Show/archiveUnarchiveDialog";

const dialog = archiveUnarchiveDialog(false,
                                      "The form has been unarchived",
                                      "There was an error while unarchiving. Please try again");

export default {
  components: {
    ConfirmModal
  },
  props:      {
    ...dialog.component.props
  },
  emits:      dialog.component.emits,
  setup(props, {emit}) {
    return dialog.insideSetup(props, emit);
  }

};

</script>
