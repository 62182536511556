// Taken from https://github.com/tserkov/vue-plugin-load-script/blob/master/index.js and made for TS

export const loadScript = (src) => {
	return new Promise(function (resolve, reject) {
		let shouldAppend = false;
		let el = document.querySelector("script[src=\"" + src + "\"]") as HTMLScriptElement;
		if (!el) {
			el = document.createElement("script") as HTMLScriptElement;
			el.type = "text/javascript";
			el["data-added"] = true;
			el.async = true;
			el.src = src;
			shouldAppend = true;
		} else if ((el.hasAttribute("data-added") && el.hasAttribute("data-loaded")) || !el.hasAttribute("data-added")) {
			resolve(el);
			return;
		}

		el.addEventListener("error", reject);
		el.addEventListener("abort", reject);
		el.addEventListener("load", function loadScriptHandler() {
			el.setAttribute("data-loaded", "true");
			resolve(el);
		});

		if (shouldAppend) {
			document.head.appendChild(el);
		}
	});
};

export const unloadScript = (src) => {
	return new Promise(function (resolve, reject) {
		const el = document.querySelector("script[src=\"" + src + "\"]");

		if (!el) {
			reject();
			return;
		}

		document.head.removeChild(el);

		resolve(true);
	});
};
