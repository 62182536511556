<template>
  <side-panel
      v-model:open="openModel"
      :closable="!sending">
    <template #header>
      Send a text message to {{ client.first_name }}
    </template>
    <div class="px-4 py-4 h-full ">
      <form
          ref="form"
          class="h-full flex flex-col"
          @submit.prevent="send">
        <div class=" w-full h-full">
          <text-area-input
              v-model="messageModel"
              :disabled="sending"
              :error-message="errorMessage"
              :max-length="smsData.maxLength"
              class="min-h-full"
              label="Message"
              placeholder="Text message content"
              replace-utf
              required>
            <template #internal>
              <s-m-s-units-counter
                  :msg="messageModel"
                  :units="3"
                  class="pl-2 text-gray-500 text-sm pb-2" />
            </template>
          </text-area-input>
        </div>
      </form>
    </div>

    <template #footer>
      <div
          v-if="!sending && openModel"
          class="flex m-2 justify-between w-full">
        <button
            class="btn btn-white"
            @click="openModel = false">
          Cancel
        </button>
        <button
            class="btn btn-green"
            @click="send">
          Send!
        </button>
      </div>
      <div
          v-if="sending && openModel"
          class="flex m-2 justify-center">
        <div class="h-8 w-8">
          <loader />
        </div>
      </div>
    </template>
  </side-panel>
</template>
<script lang="ts" setup>
import SidePanel                                                    from "@/components/layout/SidePanel.vue";
import TextAreaInput                                                from "@/components/layout/Forms/TextAreaInput.vue";
import Loader                                                       from "@/components/layout/Loader.vue";
import {computed, onMounted, ref, toRef, useAttrs, useSlots, watch} from "vue";
import SparkFormErrors                                              from "@/classes/utils/SparkFormErrors";
import {QLClient, useDiscussionCreateMutation}                      from "@/graphql/queries/ql/composables";
import fillValidationErrorsOrToast
                                                                    from "@/composables/queries/mutations/fillValidationErrorsOrToast";
import $toast                                                       from "@/composables/toast";
import routesList                                                   from "@/composables/routesList";
import {smsLengthInit}                                              from "@/composables/smsLength";
import {smsCleanedContent}                                          from "@/composables/smsCleanedContent";
import {toastIfRateLimitWasHit}                                     from "@/composables/rateLimits";
import SMSUnitsCounter                                              from "@/components/layout/SMSUnitsCounter.vue";

interface Props {
  open: boolean;
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:open", "sent"]);

const openModel = computed({
                             get: () => props.open,
                             set: (val) => emit("update:open", val)
                           });

const form = ref<HTMLFormElement>(null);
const errorsData = ref({});

const message = ref("");
const sending = ref(false);

const client = toRef(props, "client");

const errors = computed(() => {
  return new SparkFormErrors(errorsData.value);
});

const resetForm = () => {
  sending.value = false;
  message.value = "";
  errorsData.value = {};
};

onMounted(() => {
  resetForm();
});

watch(client, () => {
  resetForm();
});

const messageModel = smsCleanedContent().computed(message);

const mutation = useDiscussionCreateMutation({});

mutation.onError((err) => {
  if (!toastIfRateLimitWasHit(err)) {
    fillValidationErrorsOrToast(err, errorsData, "Error sending the text message. Please try again!");
  }
  sending.value = false;
});

mutation.onDone((res) => {
  $toast({
           type:     "success",
           message:  "Text message sent!",
           linkText: "View it now",
           linkRouteObj:
                     {
                       name:   routesList().routeList.client.discussions.show,
                       params: {
                         id:           client.value.id,
                         discussionId: res.data.CreateDiscussion.id
                       }
                     }
         });
  sending.value = false;
  openModel.value = false;
  resetForm();
});

const send = () => {
  errorsData.value = {};
  if (!form.value.reportValidity()) {
    return;
  }

  sending.value = true;
  mutation.mutate({
                    client_id: client.value.id,
                    message:   message.value,
                    is_email:  false
                  });
  emit("sent");
};

const smsData = smsLengthInit(message);

const errorMessage = computed(() => {
  if(errors.value.get('message')){
    return errors.value.get('message');
  }
  if(smsData.errorMessage){
    return smsData.errorMessage;
  }
  return null;
});

</script>
