<template>
  <filter-group-base-category
      v-model:filter-selected="filterSelected"
      v-model:operator-selected="operatorSelected"
      :category-name="categoryName"
      @step-back="stepBack"
      @added-rule="addedRule($event)"
  >
    <template #title>
      New Filter - Product
    </template>
    <template #subtitle>
      The Product filter category contains filters about the a clients products.
    </template>
    <template #side-description>
      <div>
        Product filters handle retail purchases at your location, and help filter clients based on retail items that
        have or have not been purchased, and when those items were purchased.

        <div class="pt-4">
          <p>
            Examples:
          </p>

          <ol class="filter-side-examples pt-2">
            <li>
              <div class="filter-side-examples-filters">
                <span class="fake-filter">Product Purchased</span> +
                <span class="fake-filter">Product History</span>
              </div>
              <div class="filter-side-examples-info">
                Show clients that purchased a specific retail item within a defined
                timeframe
              </div>
            </li>
          </ol>
        </div>
      </div>
    </template>
    <template #filters>
      <slot />
    </template>
  </filter-group-base-category>
</template>
<script lang="ts">
import {singleCategoryComposition} from "@/components/Segment/Edit/Categories/SingleCategories/singleCategoryComposition";

const cat = singleCategoryComposition();
export default {
  components: {
    ...cat.components
  },
  props:      {
    ...cat.props
  },
  emits:      cat.emits,
  setup(props, {emit}) {
    return cat.setup(props, emit);
  }
};
</script>
