<template>
  <div class="border rounded p-2 px-4">
    <div class="space-y-4">
      <text-input label="ID"
                  required
                  v-model="variableModel.id"
                  small-info="Internal unique ID used in the segments. Not user visible"></text-input>
      <text-input label="Name"
                  required
                  v-model="variableModel.field_name"
                  small-info="User visible name of the field"></text-input>
      <text-area-input label="Short description" v-model="variableModel.field_description" required></text-area-input>

      <div class="flex flex-col">
        <label class="form-label">
          Field Type
        </label>

        <select name="provider" id="" v-model="variableModel.field_type" class="form-select">
          <option v-bind:key="op" :value="op" v-for="op in CatalogOperatorTypeEnum">{{ op }}</option>
        </select>
      </div>
      <button class="btn btn-sm btn-red" @click="removeVar">Remove variable</button>
    </div>

  </div>
</template>
<script lang="ts" setup>
import {CatalogOperatorTypeEnum} from "@/components/automations/Catalog/CatalogOperatorTypeEnum";
import TextInput                 from "@/components/layout/Forms/TextInput.vue";
import TextAreaInput             from "@/components/layout/Forms/TextAreaInput.vue";

import {QLCatalogItemVariableFragmentFragment} from "@/graphql/queries/ql/composables";
import {computed, useAttrs, useSlots}          from "vue";

interface Props {
  variable: QLCatalogItemVariableFragmentFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:variable", "delete"]);

const operatorTypes = CatalogOperatorTypeEnum;

const variableModel = computed({
                                 get: () => props.variable,
                                 set: (val) => emit("update:variable", val)
                               });

const removeVar = () => {
  emit("delete");
};

</script>
