import Filter                      from "hm-filters/base-filter";
import {computed, PropType, toRef} from "vue";
import Operator                    from "hm-filters/operators/operator";
import FilterGroupBaseCategory     from "@/components/Segment/Edit/Categories/FilterGroupBaseCategory.vue";

export function singleCategoryComposition() {

	const props = {
		categoryName:     {
			type:     String,
			required: true
		},
		filterSelected:   {
			type:     Object as PropType<Filter>,
			required: false,
			default:  () => null,
		},
		operatorSelected: {
			type:     Object as PropType<Operator>,
			required: false,
			default:  () => null,
		}

	};

	const components = {
		FilterGroupBaseCategory
	};

	const emits = ["stepBack", "addedRule", "update:filterSelected", "update:operatorSelected"];

	const setup = (props, emit) => {
		const categoryName = toRef(props, "categoryName");

		const filterSelected = computed({
			                                get: () => props.filterSelected,
			                                set: (val) => {
				                                emit("update:filterSelected", val);

			                                }
		                                });

		const operatorSelected = computed({
			                                  get: () => props.operatorSelected,
			                                  set: (val) => {
				                                  emit("update:operatorSelected", val);
			                                  }
		                                  });

		const stepBack = () => {
			emit("stepBack");
		};
		const addedRule = (data) => {
			emit("addedRule", data);
		};

		return {
			categoryName,
			filterSelected,
			operatorSelected,
			stepBack,
			addedRule
		};
	};

	return {
		props,
		emits,
		setup,
		components
	};
}
