import Contract           from "./partials/contract";
import FixedValueOperator from "hm-filters/operators/FixedValueOperator";

class ContractStatus extends Contract {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new FixedValueOperator("Active", "is active"),
			new FixedValueOperator("Inactive", "is inactive"),
			new FixedValueOperator("Suspended", "is suspended"),
			new FixedValueOperator("no_active",
			                       "has no active contracts").setAloneInFilterGroup(),
			new FixedValueOperator("no_suspended",
			                       "has no suspended contracts").setAloneInFilterGroup()
		];
	}

	get type(): string {
		return "ContractStatusFilter";
	}
}

export default ContractStatus;
