<template>
  <div class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
    <dt>
      <div
          class="absolute rounded-md p-3"
          :class="[value > 0 ? 'bg-orange-500 animate-pulse' : 'bg-green-500']">
        <svg-icon
            :name="icon"
            class="h-6 w-6 text-white" />
      </div>
      <p class="ml-16 text-sm font-medium text-gray-500 truncate">
        {{ title }}
      </p>
    </dt>
    <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
      <p class="text-2xl font-semibold text-gray-900">
        <span v-if="!loading">{{ value }}</span>
        <span
            v-if="loading"
            class="block animate-loading">
        <loading-skeleton
            class-type="inline"
            text="123" />
      </span>
      </p>

      <div class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
        <div class="text-sm">
          <router-link
              :to="{name: routeName}"
              class="font-medium text-indigo-600 hover:text-indigo-500">
            {{ linkName }}
          </router-link>
        </div>
      </div>
    </dd>
  </div>
</template>
<script lang="ts" setup>
import {PossibleIcons}      from "@/assets/icons/iconNames";
import SvgIcon              from "@/components/SvgIcon.vue";
import LoadingSkeleton      from "@/components/layout/LoadingSkeleton.vue";
import {useAttrs, useSlots} from "vue";

interface Props {
  title: string;
  icon: PossibleIcons;
  loading: boolean;
  linkName: string;
  value: number;
  routeName: string;
}

const props = withDefaults(defineProps<Props>(), {});


const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);
</script>
