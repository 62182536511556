<template>
  <div class="bg-white rounded-xl border-gray-100 border w-full shadow-xl">
    <div class="p-4">
      <div class="flex">
        <div>
          <img
              :alt="invitation.team.name"
              :src="invitation.team.photo_url"
              class="w-12 h-12 rounded-full">
        </div>
        <div class="grow pl-3">
          <div>
            <strong class="pb-1 block">{{ invitation.team.name }}</strong>
            <p class="text-gray-500">
              Wants you to be part of their team
            </p>
          </div>
          <div class="mt-6">
            <div class="flex space-x-4">
              <v-button
                  :disabled="accepting || declining"
                  :loading="accepting"
                  class="btn btn-green"
                  @click="accept">
                Accept
              </v-button>
              <v-button
                  :disabled="accepting || declining"
                  :loading="declining"
                  class="btn btn-white"
                  @click="decline">
                Decline
              </v-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import {
  InvitationsPendingDocument, QLFragmentInvitationSentFragment, useInvitationAcceptMutation, useInvitationRefuseMutation
}                       from "@/graphql/queries/ql/composables";
import {ref, toRef}     from "vue";
import useEmitter       from "@/composables/useEmitter";
import $toast           from "@/composables/toast";
import VButton          from "@/components/layout/VButton.vue";
import {RootEventTypes} from "@/classes/notifications/EventTypes";
import {EventBus}       from "@/classes/notifications/EventBus";

interface Props {
  invitation: QLFragmentInvitationSentFragment;
}

const props = withDefaults(defineProps<Props>(), {});

const accepting = ref(false);
const declining = ref(false);
const emitter = useEmitter();

const invitation = toRef(props, "invitation");

function removeFromPendingInvitations(cache) {
  const data = cache.readQuery({query: InvitationsPendingDocument});
  const invitations_pending = data.invitations_pending.map((i) => i).filter(
      t => {
        return t.id != invitation.value.id;
      });
  cache.writeQuery({query: InvitationsPendingDocument, data: {invitations_pending}});
}

function showToastError() {
  $toast({
           type:         "error",
           message:      "There was an error with the invitation. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
}

const {mutate: acceptMutate, onDone: onDoneAccept, onError: onErrorAccept} = useInvitationAcceptMutation({

                                                                                                           update: (cache) => {
                                                                                                             removeFromPendingInvitations(
                                                                                                                 cache);
                                                                                                           }
                                                                                                         });

const refuseMutation = useInvitationRefuseMutation({
                                                     update: (cache) => {
                                                       removeFromPendingInvitations(cache);
                                                     }
                                                   });

onDoneAccept(() => {
  accepting.value = false;

  EventBus.getInstance().emit(RootEventTypes.AcceptedInvitation, {data: null});
  EventBus.getInstance().emit(RootEventTypes.RefreshMe, {data: null});
  EventBus.getInstance().emit(RootEventTypes.RefreshTeam, {data: null});
});

onErrorAccept(error => {
  showToastError();
});

const accept = () => {
  accepting.value = true;
  acceptMutate({token: invitation.value.token});
};

refuseMutation.onDone(() => {
  declining.value = false;
});

refuseMutation.onError(error => {
  showToastError();
});

function decline() {
  declining.value = true;
  refuseMutation.mutate({token: invitation.value.token});
}

</script>
