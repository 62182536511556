import Filter from "../base-filter";

class AppointmentStaffValues extends Filter {
	smallText = "Staff";
	group = "Staff";
	path = ["Appointments", "Staff"];

	get type(): string {
		return "AppointmentStaffValues";
	}
}

export default AppointmentStaffValues;
