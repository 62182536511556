<template>
  <div>
    <div class="block w-full h-full">
      <avatar
          :image="photo"
          :username="fullName"
          class="w-full h-full" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import Avatar                                from "@/components/layout/Avatar.vue";
import {QLClient}                            from "@/graphql/queries/ql/composables";
import {computed, toRef, useAttrs, useSlots} from "vue";

interface Props {
  client: QLClient;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const client = toRef(props, "client");

const fullName = computed(() => client.value.first_name + " " + client.value.last_name);

const photo = computed(() => {
  if (client.value.photos && client.value.photos.length > 0 && client.value.photos[0] && client.value.photos[0].url) {
    return client.value.photos[0].url;
  }
  return null;
});

</script>
