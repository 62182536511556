<template>
  <div class="split-view-left">
    <div class="pb-4 md:pb-0 md:w-1/3">
      <div class="text-lg font-medium text-gray-900">
        Set your studio APIs
      </div>
      <div class="mt-1 text-gray-600 text-sm">
        Connect your LoopSpark and Mindbody accounts by entering your Mindbody site ID and clicking "continue".
      </div>
    </div>

    <form
        v-if="step === 0"
        class="flex-1"
        @submit.prevent="firstStep">
      <div class="block overflow-hidden shadow-md rounded-lg">
        <div class="space-y-6 bg-white p-4">
          <p class="text-sm">
            Your Mindbody site ID can be found on the bottom right corner of your Mindbody login. For
            help locating your Midbody site ID, please refer to this
            <a
                class="text-link"
                href="https://support.mindbodyonline.com/s/article/206398178-How-do-I-find-my-Client-ID?"
                target="_blank">help article</a>.
          </p>

          <div>
            <text-input
                v-model="siteId"
                label="Your Site ID"
                placeholder="ex 23984"
                required />

            <span
                v-if="step1Errors"
                class="form-error-message">{{ step1Errors }}</span>
          </div>
        </div>
        <div class="bg-gray-100">
          <div class="flex justify-end p-4 py-3">
            <v-button
                :disabled="step1submitting || siteId === null"
                :loading="step1submitting"
                class="btn btn-green text-sm font-normal"
                type="submit">
              Continue...
            </v-button>
          </div>
        </div>
      </div>
    </form>

    <div
        v-if="step === 1"
        class="flex-1">
      <div class="block overflow-hidden shadow-md rounded-lg">
        <div class="space-y-6 bg-white p-4">
          <p>
            Your authorization link is ready. Click on the button below to authorize LoopSpark to
            access your studio information.
          </p>
          <p>
            After you clicked on the authorization link, come back here and follow the instructions to the next
            steps
          </p>
        </div>
        <div class="bg-gray-100">
          <div class="flex justify-end p-4 py-3 space-x-4">
            <a
                v-if="!authorizationClicked"
                :href="authorizationLink"
                class="btn btn-green text-sm font-normal"
                target="_blank"
                @click="clickAuthorization">Authorize LoopSpark...</a>
            <v-button
                v-if="authorizationClicked"
                :disabled="step2submitting || siteId === null"
                :loading="step2submitting"
                class="btn btn-loopspark text-sm font-normal"
                @click="secondStep">
              I authorized LoopSpark, continue...
            </v-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>

import TextInput                                                  from "@/components/layout/Forms/TextInput.vue";
import VButton                                                    from "@/components/layout/VButton.vue";
import {ref}                                                      from "vue";
import {useMindBodyApiPrepareMutation, useMindBodyApiSetMutation} from "@/graphql/queries/ql/composables";
import QlErrorParser                                              from "@/graphql/Errors/QlError";
import ValidationError                                            from "@/graphql/Errors/ValidationError";
import $toast                                                     from "@/composables/toast";

const siteId = ref(null);
const step1submitting = ref(false);
const step2submitting = ref(false);
const step1Errors = ref(null);
const authorizationLink = ref(null);
const step = ref(0);
const authorizationClicked = ref(false);


const apiPrepareMutation = useMindBodyApiPrepareMutation({});
const apiSetMutation = useMindBodyApiSetMutation({});

const firstStep = async () => {
  if (step1submitting.value) {
    return;
  }

  step1submitting.value = true;
  step1Errors.value = null;
  authorizationLink.value = null;

  try {
    const res = await apiPrepareMutation.mutate({site_id: siteId.value});
    authorizationLink.value = res.data.MindBodyApiPrepare.authorization_link;
    step.value = 1;

  } catch (e) {
    const err = QlErrorParser.returnError(e);
    if (err instanceof ValidationError) {
      step1Errors.value = err.errors.get("site_id");
    } else {
      step1Errors.value = "There was an error in the information you entered. Try again";
    }
  }

  step1submitting.value = false;

};

const clickAuthorization = () => {
  authorizationClicked.value = true;
};

const secondStep = async () => {
  if (step2submitting.value) {
    return;
  }

  step2submitting.value = true;

  try {
    let res = await apiSetMutation.mutate({site_id: siteId.value});

    $toast({
             type:         "success",
             message:      "API is set!",
             linkRouteObj: null,
             linkText:     null
           });
  } catch (e) {
    $toast({
             type:         "error",
             message:      "There was an error while completing this step. Please make sure you authorized LoopSpark on MindBody",
             linkRouteObj: null,
             linkText:     null
           });
  }

  step2submitting.value = false;
};

</script>
