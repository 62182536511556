<template>
  <div ref="chartdiv" class="piechart"></div>
</template>
<script lang="ts" setup>

// @ts-nocheck
import {LegendPosition}                               from "@/components/layout/Charts/LegendPosition";
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import * as am4core                                   from "@amcharts/amcharts4/core";
import am4themes_Loopspark                            from "@/components/layout/Charts/Am4Theme";
import * as am4charts                                 from "@amcharts/amcharts4/charts";
import {forEach, slice, sumBy}                        from "lodash";

interface Props {
  items: any;
  label: string;
  itemName?: string;
  itemValue?: string;
  colorPalette?: string;
  legendPosition?: LegendPosition;
  legend?: boolean;
  maxItems?: number;
  cutoutPercentage?: number;
}

const props = withDefaults(defineProps<Props>(), {
  itemName:         "name",
  itemValue:        "cc",
  colorPalette:     "cb-Blues",
  legendPosition:   "right",
  legend:           true,
  maxItems:         5,
  cutoutPercentage: 0
});

let chart = null;

const orderedItems = computed(() => {
  if(!props.items) return [];
  return props.items.map((i) => i).sort((a, b) => (a[props.itemValue] < b[props.itemValue]) ? 1 : -1);
});

function generatedChartData() {
  let chartData = [];
  if(orderedItems.value.length <= props.maxItems){
    return orderedItems.value;
  }

  let toDisplay = slice(orderedItems.value, 0, props.maxItems - 1);
  forEach(toDisplay, (value: object, index: number) => {
    chartData.push({
                     ...value
                   });
  });

  let others = slice(orderedItems.value, props.maxItems - 1);

  if (others.length > 0) {
    let otherCount = sumBy(others, (o) => {
      // @ts-ignore
      return o[props.itemValue];
    });
    let otherData = {};
    otherData[props.itemName] = "Others";
    otherData[props.itemValue] = otherCount;
    chartData.push(otherData);
  }

  return chartData;
}

const updateChart = () => {
  if (chart) {
    chart.data = generatedChartData();
    if (props.legend) {
      chart.legend = new am4charts.Legend();
      chart.legend.position = props.legendPosition;
    } else {
      chart.legend = null;

    }
  }
};

watch(props, () => {
  updateChart();
});

const createTheChart = (chartdiv, chartData) => {
  am4core.unuseAllThemes();
  am4core.useTheme(am4themes_Loopspark);
  am4core.options.commercialLicense = true;

  chart = am4core.create((chartdiv.value as HTMLElement), am4charts.PieChart);
  chart.responsive.enabled = true;
  chart.responsive.useDefault = true;
  chart.data = chartData;

  let pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = props.itemValue;
  pieSeries.dataFields.category = props.itemName;
  pieSeries.slices.template.stroke = am4core.color("#fff");
  pieSeries.slices.template.strokeWidth = 2;
  pieSeries.slices.template.strokeOpacity = 1;

  pieSeries.hiddenState.properties.opacity = 1;
  pieSeries.hiddenState.properties.endAngle = -90;
  pieSeries.hiddenState.properties.startAngle = -90;

  pieSeries.labels.template.disabled = true;
  pieSeries.ticks.template.disabled = true;
  if (props.legend) {
    chart.legend = new am4charts.Legend();
    chart.legend.position = props.legendPosition;
  }
  return {
    chart
  };
};

const chartdiv = ref(null);

onMounted(() => {
  createTheChart(chartdiv, generatedChartData());
});

onUnmounted(() => {
  if (chart) {
    chart.dispose();
  }
});

</script>
