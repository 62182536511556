<template>
  <div>
    <smart-select
        v-model="selectedValue"
        :items="operator.multipleChoicesData"
        :label="'display'"
        always-show-all
        focus-on-show
    >
      <template #default="{ item }">
        {{ item.display }}
      </template>
    </smart-select>
  </div>
</template>
<script lang="ts" setup>

import SmartSelect              from "@/components/layout/Forms/SmartSelect.vue";
import {computed, toRef, watch} from "vue";
import MultipleChoiceOperator   from "hm-filters/operators/MultipleChoiceOperator";


interface Props {
  operator: MultipleChoiceOperator;
  modelValue: any;
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["update:modelValue", "is-valid"]);
const modelValue = toRef(props, "modelValue");
const operator = toRef(props, "operator");

const selectedValue = computed({
                                 get: () => {

                                   if (modelValue.value === null) {
                                     return null;
                                   }
                                   return operator.value.multipleChoicesData.find((choice) => choice.value === modelValue.value);
                                 },
                                 set: (newVal) => emit("update:modelValue",
                                                       newVal !== null ? newVal.value : null)
                               });


const isValidCalculation = () => {
  let valid = modelValue.value !== null && operator.value.multipleChoicesData.find((choice) => choice.value === modelValue.value);
  emit("is-valid", valid);
  return valid;
};

const isValid = computed(() => {
  return isValidCalculation();
});

watch(modelValue, isValidCalculation);

</script>
