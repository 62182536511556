<template>
  <div>
    <span class="font-semibold">{{smsData.units}}</span> SMS unit{{ smsData.units>1 ? 's':'' }}
  </div>
</template>
<script lang="ts" setup>
import {smsLengthInit} from "@/composables/smsLength";

interface Props {
  msg: string;
  units?: number
}

const props = withDefaults(defineProps<Props>(), {
  units: 2
});

import {ref, useSlots, useAttrs, toRef} from "vue";

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits([]);

const msg = toRef(props, "msg");

const smsData = smsLengthInit(msg, props.units);
</script>
