import Communication      from "./partials/communication";
import FixedValueOperator from "hm-filters/operators/FixedValueOperator";

class CommunicationAction extends Communication {
	constructor(name: string, options: any) {
		super(name, options);

		this.addOperators([
			                  new FixedValueOperator("Clicked", "Clicked"),
			                  new FixedValueOperator("No clicks", "No clicks"),
			                  new FixedValueOperator("Opened", "Opened"),
			                  new FixedValueOperator("Did not open", "Did not open"),
			                  new FixedValueOperator("Bounced", "Bounced"),
			                  new FixedValueOperator("Delivered", "Delivered")
		                  ]);
	}

	get type(): string {
		return "CommunicationActionFilter";
	}
}

export default CommunicationAction;
