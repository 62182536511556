export function currency(dollars: number | null) {
	if (dollars === null) {
		return "";
	}

	const formatter = new Intl.NumberFormat("en-US", {
		style:                 "currency",
		currency:              "USD",
		minimumFractionDigits: 0,
		maximumFractionDigits: 2
	});

	return formatter.format(dollars);
}
