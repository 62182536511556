import {computed, isRef, reactive, Ref, ref}                                                                                                                                                           from "vue";
import {QLAutomationDeliveryTypeEnum, QLCatalogItemInput, QLEmailTemplateContent, QLFragmentFullAutomationFragment, QLFragmentSmallAutomationFragment, QLMutationCreateAutomationArgs, QLTodoPriority} from "@/graphql/queries/ql/composables";
import {DateTime}                                                                                                                                                                                      from "luxon";
import SegmentDecorator                                                                                                                                                            from "@/composables/decorators/SegmentDecorator";

export default function AutomationDecorator(passed: Ref<QLFragmentSmallAutomationFragment | QLFragmentFullAutomationFragment> | QLFragmentSmallAutomationFragment | QLFragmentFullAutomationFragment | QLCatalogItemInput["automation"]) {
	const entity = isRef(passed) ? passed : ref(passed);

	const via_email = computed(() => {
		return entity.value.delivery_type === QLAutomationDeliveryTypeEnum.EMAIL;
	});

	const via_sms = computed(() => {
		return entity.value.delivery_type === QLAutomationDeliveryTypeEnum.SMS;
	});

	const via_todo = computed(() => {
		return entity.value.delivery_type === QLAutomationDeliveryTypeEnum.TODO;
	});

	const via_tag = computed(() => {
		return entity.value.delivery_type === QLAutomationDeliveryTypeEnum.TAG;
	});


	const isDelayed = computed(() => {
		if (entity.value.active) {
			return false;
		}

		if (!entity.value.start_at) {
			return false;
		}

		return entity.value.start_at > (Date.now() / 1000);
	});

	const exportable = computed(() => {
		return via_todo.value || via_email.value || via_sms.value;
	});

	const isFullFragment = (object: QLFragmentSmallAutomationFragment | QLFragmentFullAutomationFragment | QLCatalogItemInput["automation"]): object is QLFragmentFullAutomationFragment => {
		return "send_window_start_minutes" in object;
	};

	const isSendWindowImmediate = computed((): boolean => {
		if (!isFullFragment(entity.value)) {
			return false;
		}
		if (entity.value.send_window_start_minutes === null || entity.value.send_window_start_minutes === undefined) {
			return true;
		}
		return entity.value.send_window_end_minutes === null || entity.value.send_window_end_minutes === undefined;

	});

	const sendWindowStr = computed((): string => {
		if (isSendWindowImmediate.value) {
			return "Immediate";
		}
		if (!isFullFragment(entity.value)) {
			return "";
		}

		const start = DateTime.local()
		                      .startOf("day")
		                      .plus({minutes: entity.value.send_window_start_minutes!})
		                      .toFormat("h:mm a");
		const end = DateTime.local()
		                    .startOf("day")
		                    .plus({minutes: entity.value.send_window_end_minutes!})
		                    .toFormat("h:mm a");

		return start + " to " + end;
	});

	const validSettings = computed(() => {
		if (entity.value.delivery_type === undefined) {
			return false;
		}
		if (entity.value.one_off === undefined) {
			return false;
		}
		if (entity.value.name == null || entity.value.name.length === 0) {
			return false;
		}

		if (!isFullFragment(entity.value)) {
			return false;
		}

		if (entity.value.send_window_start_minutes != null) {
			if (entity.value.send_window_end_minutes == null) {
				return false;
			}
			if (entity.value.send_window_start_minutes >= entity.value.send_window_end_minutes) {
				return false;
			}
		}

		return true;
	});

	const validTag = computed(() => {
		if (!isFullFragment(entity.value)) {
			return false;
		}
		if (entity.value.tags === undefined || entity.value.tags === null) {
			entity.value.tags = [];
		}
		return entity.value.tags.length !== 0;
	});

	const validMessage = computed(() => {
		if (!isFullFragment(entity.value)) {
			return false;
		}
		if (!via_sms.value && (entity.value.subject === undefined || entity.value.subject === null || entity.value.subject.length === 0)) {
			return false;
		}
		if (via_email.value as boolean) {
			if (entity.value.email_content == null) {
				return false;
			}
			if (entity.value.email_content.html == null || entity.value.email_content.html.length == 0 || entity.value.email_content.json == null) {
				return false;
			}
			return true;
		}

		if (entity.value.content === undefined || entity.value.content === null || entity.value.content.length === 0) {
			return false;
		}

		return true;
	});

	const validSegment = computed(() => {
		if (!isFullFragment(entity.value)) {
			return false;
		}
		if (!entity.value.segment) {
			return false;
		}
		if (!(SegmentDecorator(entity.value.segment)).computed.isValid) {
			return false;
		}
		return entity.value.segment.name != null && entity.value.segment.name.length > 0;
	});

	const toPost = computed((): QLMutationCreateAutomationArgs["automation"] => {
		if (!isFullFragment(entity.value)) {
			throw new Error("Automation object is incomplete");
		}

		return {
			active:                    entity.value.active,
			archived:                  entity.value.archived,
			name:                      entity.value.name,
			delivery_type:             entity.value.delivery_type,
			one_off:                   entity.value.one_off,
			category_name:             entity.value.category_name === undefined ?
			                           null :
			                           entity.value.category_name,
			description:               entity.value.description === undefined ?
			                           null :
			                           entity.value.description,
			start_at:                  entity.value.start_at,
			send_window_start_minutes: entity.value.send_window_start_minutes === undefined ?
			                           null :
			                           entity.value.send_window_start_minutes,
			send_window_end_minutes:   entity.value.send_window_end_minutes === undefined
			                           ? null
			                           : entity.value.send_window_end_minutes,
			content:                   entity.value.content || null,
			subject:                   entity.value.subject,
			tags:                      entity.value.tags,
			tags_add:                  entity.value.tags_add,
			todo_overdue_hours:        (entity.value.todo_overdue_hours as number) > 0 ?
			                           entity.value.todo_overdue_hours :
			                           0,
			todo_postponed_hours:      (entity.value.todo_postponed_hours as number) > 0 ?
			                           entity.value.todo_postponed_hours :
			                           0,
			todo_priority:             entity.value.todo_priority === null
			                           ? QLTodoPriority.NONE
			                           : entity.value.todo_priority,
			todo_tags:                 entity.value.todo_tags === null ? [] : entity.value.todo_tags,
			todo_assigned_to_id:       parseInt(entity.value.todo_assigned_to_id) > 0 ?
			                           entity.value.todo_assigned_to_id :
			                           null,
			todo_team_group_id:        parseInt(entity.value.todo_team_group_id) > 0 ?
			                           entity.value.todo_team_group_id :
			                           null,
			reply_to:                  entity.value.reply_to == null ? null : entity.value.reply_to,

			segment:       SegmentDecorator(entity.value.segment).computed.asAutomationInputPost,
			email_content: postEmailContent()
		};
	});

	const postEmailContent = (): QLEmailTemplateContent | null => {
		if (!isFullFragment(entity.value)) {
			return null;
		}
		if (!entity.value.email_content) {
			return null;
		}
		return {
			html: entity.value.email_content.html,
			json: JSON.stringify(entity.value.email_content.json)
		};
	};


	return {
		computed: reactive({
			                   via_email,
			                   via_tag,
			                   via_sms,
			                   via_todo,
			                   isDelayed,
			                   exportable,

			                   toPost,
			                   validMessage,
			                   validSegment,
			                   validTag,
			                   validSettings,
			                   sendWindowStr,
			                   isSendWindowImmediate
		                   }),
		methods:  {
			postEmailContent,
			isFullFragment
		}
	};
}
