import ClientGroup     from "hm-filters/filters/partials/client";
import NumericOperator from "hm-filters/operators/NumericOperator";

export default class ClientAgeFilter extends ClientGroup {
	constructor(name: string, options: any) {
		super(name, options);

		this.operators = [
			new NumericOperator("exact", "exactly", "years"),
			new NumericOperator("older_than", "older than", "years"),
			new NumericOperator("younger_than", "younger than", "years")
		];
	}


	get type(): string {
		return "ClientAgeFilter";
	}
}
