<template>
  <div>
    <div class="md:flex items-center py-4">
      <div class="grow leading-loose">
        <div>{{ invitation.email }}</div>
        <div class="text-xs text-gray-500">
          Invited on
          <timestamp-to-date
              :timestamp="invitation.created_at"
              has-time
              natural-date />
        </div>
      </div>
      <div>
        <button
            class="btn btn-sm text-red-800 hover:bg-red-800 hover:text-white"
            @click="rejectOpen = true">
          Retract
          invitation
        </button>
      </div>
    </div>
    <confirm-modal
        v-model:open="rejectOpen"
        type="warning"
        title="Are you sure?"
        :loading="rejecting"
        @clicked="startRescind">
      <template #text>
        <p class="text-sm">
          Are you sure you want to retract the invitation to {{ invitation.email }}?
        </p>
      </template>
      <template #button>
        Yes, retract it!
      </template>
    </confirm-modal>
  </div>
</template>
<script lang="ts" setup>

import TimestampToDate                                                                                                                                               from "@/components/layout/TimestampToDate.vue";
import {ref}                                                                                                                                                         from "vue";
import {QLFragmentCurrentTeamFragment, QLFragmentInvitationSentFragment, QLTeamPendingInvitationsQuery, TeamPendingInvitationsDocument, useInvitationRejectMutation} from "@/graphql/queries/ql/composables";
import $toast                                                                                                                                                        from "@/composables/toast";
import ConfirmModal                                                                                                                                                  from "@/components/layout/ConfirmModal.vue";

interface Props {
  currentTeam: QLFragmentCurrentTeamFragment;
  invitation: QLFragmentInvitationSentFragment;
}

const props = withDefaults(defineProps<Props>(), {});


const emit = defineEmits(["rejected"]);

const rejecting = ref(false);
const rejectOpen = ref(false);

const {mutate: sendMessage, onDone, onError} = useInvitationRejectMutation(
    {
      update: (cache, {data: {invitation_reject}}) => {

        const data: QLTeamPendingInvitationsQuery = cache.readQuery({
                                                                      query:     TeamPendingInvitationsDocument,
                                                                      variables: {
                                                                        team_id: props.currentTeam.id
                                                                      }
                                                                    });
        if (data) {
          const newData = {
            team_pending_invitations: data.team_pending_invitations.filter(
                t => {
                  return t.id != props.invitation.id;
                })
          };
          cache.writeQuery({
                             query:     TeamPendingInvitationsDocument,
                             variables: {
                               team_id: props.currentTeam.id
                             }, data:   newData
                           });
        }
      }
    });

onDone(() => {
  $toast({
           type:         "success",
           message:      "Invitation retracted",
           linkRouteObj: null,
           linkText:     null
         });
  rejectOpen.value = false;
  emit("rejected", props.invitation);
});
onError((error) => {
  $toast({
           type:         "error",
           message:      "There was an error. Please try again",
           linkRouteObj: null,
           linkText:     null
         });
});

const startRescind = () => {
  rejecting.value = true;

  sendMessage({id: props.invitation.id}).finally(() => rejecting.value = false);
};

</script>
