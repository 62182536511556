<template>
  <status-banners-items v-if="me && current_team" :current-team="current_team" :me="me"></status-banners-items>
</template>
<script lang="ts">

import StatusBannersItems from "@/components/StatusBanner/StatusBannersItems.vue";
import meQuery            from "@/composables/queries/meQuery";
import currentTeamQuery   from "@/composables/queries/currentTeamQuery";

export default {
  components: {
    StatusBannersItems
  },
  setup() {
    const {me} = meQuery();
    const {currentTeam} = currentTeamQuery();

    return {
      me,
      current_team: currentTeam
    };
  }
};

</script>
