<template>
  <div>
    <automation-form-email-select-template
        v-if="!hasSelectedEmailTemplate"
        @selected="selectedTemplate" />
    <automation-form-email-content
        v-if="hasSelectedEmailTemplate"
        v-model:subject="subjectModel"
        v-model:email-content="emailContentModel"
        v-model:reply-to="replyToModel"
        :errors="errorsModel" />

  </div>
</template>
<script lang="ts" setup>

import {SparkErrors}                             from "@/classes/utils/SparkFormErrors";
import AutomationFormEmailSelectTemplate         from "@/components/automations/Create/Mediums/partials/AutomationFormEmailSelectTemplate.vue";
import {deepCopy}                                from "@/classes/helpers/DeepCopy";
import AutomationFormEmailContent                from "@/components/automations/Create/Mediums/partials/AutomationFormEmailContent.vue";
import {computed, useAttrs, useSlots}            from "vue";
import {QLEmailTemplate, QLEmailTemplateContent} from "@/graphql/queries/ql/composables";

interface Props {
  emailContent: QLEmailTemplateContent;
  replyTo: string;
  subject: string;
  errors: SparkErrors;
}

const props = withDefaults(defineProps<Props>(), {});

const slots = useSlots();
const attrs = useAttrs();

const emit = defineEmits(["update:emailContent", "update:replyTo", "update:subject", "update:errors"]);

const hasSelectedEmailTemplate = computed(() => {
  return emailContentModel.value && emailContentModel.value.html && emailContentModel.value.html.length > 0;
});

const subjectModel = computed({
                                get: () => props.subject,
                                set: (val) => emit("update:subject", val)
                              });

const replyToModel = computed({
                                get: () => props.replyTo,
                                set: (val) => emit("update:replyTo", val)
                              });

const emailContentModel = computed({
                                     get: () => props.emailContent,
                                     set: (val) => emit("update:emailContent", val)
                                   });

const errorsModel = computed({
                               get: () => props.errors,
                               set: (val) => emit("update:errors", val)
                             });

const selectedTemplate = (template: QLEmailTemplate) => {
  emailContentModel.value = {
    json: deepCopy(template.email.json),
    html: deepCopy(template.email.html)
  };
  subjectModel.value = template.subject;
};

</script>
