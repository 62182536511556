import {computed, reactive, Ref} from "vue";
import {QLFragmentTodoFragment}  from "@/graphql/queries/ql/composables";
import {DateTime}                from "luxon";
import {DecoratorInterface}      from "@/composables/decorators/DecoratorInterface";

export default function todoDecorator(entity: Ref<QLFragmentTodoFragment>): DecoratorInterface {

	const isOverduePast = computed(() => {
		return entity.value.overdue_at > 0 && DateTime.fromSeconds(entity.value.overdue_at) < DateTime.local();
	});

	const isOverdueFuture = computed((): boolean => {
		return entity.value.overdue_at > 0 && DateTime.fromSeconds(entity.value.overdue_at) > DateTime.local();
	});

	const isPostponed = computed((): boolean => {
		return entity.value.postponed_to > 0 && DateTime.fromSeconds(entity.value.postponed_to) > DateTime.local();
	});

	const assignedToEveryone = computed((): boolean => {
		return !entity.value.assigned_to_id && !entity.value.team_group;
	});

	const isOpen = computed((): boolean => {
		return entity.value.is_open || isPostponed.value;
	});

	return {
		computed: reactive({
			                   isOverdueFuture,
			                   isOverduePast,
			                   isPostponed,
			                   assignedToEveryone,
			                   isOpen
		                   })
	};
}
